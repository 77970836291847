import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  TextField,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Select,
  MenuItem,
  ButtonBase,
} from "@material-ui/core";
import theme from "../../../../core/theme.json";
import Title from "../../../../shared/components/title";
import myApp from "../../../../core/infrastructure/firebaseConfig";
import ArrowBackIosTwoToneIcon from "@material-ui/icons/ArrowBackIosTwoTone";
import { useDispatch } from "react-redux";
import { changePage } from "../redux/actions";
import DeleteIcon from "@material-ui/icons/Delete";
import ReactPlayer from "react-player";
import { connect } from "react-redux";
import TvOffIcon from "@material-ui/icons/TvOff";
import EditIcon from "@material-ui/icons/Edit";
import "./style.css";
import SettingsIcon from "@material-ui/icons/Settings";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import { doc, updateDoc, deleteField, arrayRemove } from "firebase/firestore";
import Swal from "sweetalert2";

const QuizVideoModule = (props) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("Carregando...");
  const [dialogs, setDialogs] = useState({
    editQuiz: false,
    newQuestionM: false,
    dialogUpdateQuestion: false,
  });
  const [video, setVideo] = useState({
    name: "",
    url: "",
  });
  const [newQuestionName, setNewQuestionName] = useState("");
  const [alternativeOne, setAlternativeOne] = useState({
    name: "",
    correct: true,
  });
  const [alternativeTwo, setAlternativeTwo] = useState({
    name: "",
    correct: false,
  });
  const [alternativeThree, setAlternativeThree] = useState({
    name: "",
    correct: false,
  });
  const [alternativeFour, setAlternativeFour] = useState({
    name: "",
    correct: false,
  });
  const [videoModuleQuestions, setVideoModuleQuestions] = useState([]);
  const [dialogUpdateQuestion, setDialogUpdateQuestion] = useState(false);
  const [actualIndex, setActualIndex] = useState(0);

  useEffect(() => {
    montingPage();
  }, []);

  const handleCloseDialog = (item) => (value) => {
    setDialogs({
      ...dialogs,
      [item]: false,
    });
  };

  const montingPage = async () => {
    await getModuleVideos();
  };

  const getModuleVideos = async () => {
    let aux = [];
    try {
      let get = await myApp
        .firestore()
        .collection("quizModuleVideo")
        .doc(props.idModuleName)
        .get();
      for (let item of get.data().questions) {
        aux.push({
          name: item.name,
          alternatives: item.alternatives,
        });
      }
      setTitle(
        get.data() === undefined
          ? "Questionário não encontrado"
          : get.data().name
      );
      if (get.data() !== undefined) {
        let getVideo = await myApp
          .firestore()
          .collection("moduleName")
          .doc(get.data().idModule)
          .get();
        setVideo({
          name: getVideo.data().videos[get.data().indexVideo].name,
          url: getVideo.data().videos[get.data().indexVideo].url,
        });
      } else {
      }
    } catch (e) {
      console.error(e);
    }
    setVideoModuleQuestions(aux);

    console.log("ok", videoModuleQuestions);
  };

  const handleConfirmDeleteQuizz = () => {
    let c = window.confirm("Tem certeza?");
    if (c === true) {
      myApp
        .firestore()
        .collection("quizModuleVideo")
        .doc(props.idModuleName)
        .delete()
        .then(() => {
          dispatch(changePage(36));
          setDialogs({
            ...dialogs,
            editQuiz: false,
          }).catch((e) => console.error(e));
        });
    } else {
      setDialogs({
        ...dialogs,
        editQuiz: false,
      });
    }
  };

  const handleAddAlternatives = async () => {
    let newQuestion = {
      name: newQuestionName,
      alternatives: [
        alternativeOne,
        alternativeTwo,
        alternativeThree,
        alternativeFour,
      ],
    };
    let get = await myApp
      .firestore()
      .collection("quizModuleVideo")
      .doc(props.idModuleName)
      .get();
    let q = get.data().questions;
    q.push(newQuestion);
    myApp
      .firestore()
      .collection("quizModuleVideo")
      .doc(props.idModuleName)
      .update({
        questions: q,
      })
      .then(() => {
        getModuleVideos();
        setNewQuestionName("");
        setAlternativeOne({
          name: "",
          correct: true,
        });
        setAlternativeTwo({
          name: "",
          correct: false,
        });
        setAlternativeThree({
          name: "",
          correct: false,
        });
        setAlternativeFour({
          name: "",
          correct: false,
        });

        console.log("teste", get.data());
      })
      .catch((e) => console.error("Erro ao atualizar questões", e));
  };

  const handleSetCorrectAlternatives = (alternative) => {
    switch (alternative) {
      case "one":
        setAlternativeOne({ ...alternativeOne, correct: true });
        setAlternativeTwo({ ...alternativeTwo, correct: false });
        setAlternativeThree({ ...alternativeThree, correct: false });
        setAlternativeFour({ ...alternativeFour, correct: false });
        break;
      case "two":
        setAlternativeOne({ ...alternativeOne, correct: false });
        setAlternativeTwo({ ...alternativeTwo, correct: true });
        setAlternativeThree({ ...alternativeThree, correct: false });
        setAlternativeFour({ ...alternativeFour, correct: false });
        break;
      case "three":
        setAlternativeOne({ ...alternativeOne, correct: false });
        setAlternativeTwo({ ...alternativeTwo, correct: false });
        setAlternativeThree({ ...alternativeThree, correct: true });
        setAlternativeFour({ ...alternativeFour, correct: false });
        break;
      case "four":
        setAlternativeOne({ ...alternativeOne, correct: false });
        setAlternativeTwo({ ...alternativeTwo, correct: false });
        setAlternativeThree({ ...alternativeThree, correct: false });
        setAlternativeFour({ ...alternativeFour, correct: true });
        break;
    }
  };

  const handleDeleteQuestion = async (item, index) => {
    let aux = [];

    const question = videoModuleQuestions;
    videoModuleQuestions.find((module, index) => {
      if (module.name == item.name) {
        Swal.fire({
          icon: "warning",
          title: "Excluindo pergunta",
          showConfirmButton: false,
        });
        console.log(index);
        question.splice(index, 1);

        console.log(question);
        let deletequest = myApp
          .firestore()
          .collection("quizModuleVideo")
          .doc(props.idModuleName)
          .update({
            questions: question,
          })
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "pergunta  excluída",
              showConfirmButton: "ok",
            });
            getModuleVideos();
          });
      }
    });
  };

  const handleUpdateQuestion = (item, index) => {
  
  };

  const updateQuestion = async (item, index) => {
    console.log(actualIndex)
    // console.log(index);
    let newQuestion = {
      name: newQuestionName,
      alternatives: [
        alternativeOne,
        alternativeTwo,
        alternativeThree,
        alternativeFour,
      ],
    };
    let aux = [];
     const question = videoModuleQuestions
      videoModuleQuestions.find((module,index) => {
        if(index == actualIndex){
          setDialogs({
            dialogUpdateQuestion:false
          })
          Swal.fire({
            icon: "warning",
            title: "atualizando pergunta",
            showConfirmButton: false,
          })
          question.splice(index , 1, newQuestion)
          console.log(question)
          let deletequest = myApp.firestore().collection('quizModuleVideo').doc(props.idModuleName).update({
          questions: question
          }).then(()=>{
            Swal.fire({
              icon: "success",
              title: "pergunta  atualizada",
              showConfirmButton: "ok",
            })
            getModuleVideos()
          });
        }
      })
  };

  return (
    <Grid container direction="column">
      <Title>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Button
              variant="text"
              style={{
                fontSize: "15px",
              }}
              onClick={() => {
                dispatch(changePage(35));
              }}
            >
              <ArrowBackIosTwoToneIcon />
            </Button>
          </Grid>
          <Grid item>{title}</Grid>
          <Grid item>
            <Button
              variant="text"
              style={{
                fontSize: "15px",
              }}
              onClick={() => setDialogs({ ...dialogs, editQuiz: true })}
            >
              <SettingsIcon />
            </Button>
          </Grid>
        </Grid>
      </Title>
      <Grid
        container
        item
        direction="row"
        style={{
          padding: 10,
        }}
      >
        <Accordion
          style={{
            width: "100%",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Video</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{
                  height: 400,
                  marginTop: 20,
                }}
              >
                <Grid
                  item
                  xs={6}
                  sm={6}
                  style={{
                    height: "100%",
                  }}
                >
                  <Paper
                    elevation={5}
                    style={{ width: "100%", height: "100%" }}
                  >
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      style={{
                        height: "100%",
                      }}
                    >
                      <Grid
                        item
                        container
                        justifyContent="center"
                        alignItems="center"
                        xs={12}
                        sm={12}
                        style={{
                          marginBottom: 5,
                        }}
                      >
                        <Typography component="div" variant="h5">
                          {video.name}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        justifyContent="center"
                        alignItems="center"
                        xs={12}
                        sm={12}
                        style={{
                          height: "70%",
                        }}
                      >
                        <ReactPlayer
                          url={video.url}
                          width="80%"
                          height="100%"
                          controls
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid
        container
        item
        direction="row"
        style={{
          padding: 10,
        }}
      >
        <Accordion
          style={{
            width: "100%",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Perguntas</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                sm={12}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={4} sm={4}>
                  <Button
                    fullWidth
                    style={{
                      backgroundColor: theme.primary_color,
                      color: theme.secondary_color,
                    }}
                    onClick={() =>
                      setDialogs({ ...dialogs, newQuestionM: true })
                    }
                    // onClick={handleAddAlternatives}
                  >
                    Nova pergunta
                  </Button>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} container spacing={2}>
                {videoModuleQuestions.length > 0 ? (
                  videoModuleQuestions.map((item, index) => {
                    return (
                      <Grid
                        key={index}
                        item
                        xs={6}
                        sm={6}
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Paper
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            component="h1"
                            variant="div"
                            style={{ width: "100%", textAlign: "center" }}
                          >
                            {item.name}
                          </Typography>

                          {item.alternatives.map(
                            (itemAlternative, indexAlternative) => (
                              <Typography
                                key={indexAlternative}
                                component="div"
                                variant="p"
                                style={{
                                  marginTop: 10,
                                  marginLeft: 10,
                                  fontSize: 18,
                                  color: itemAlternative.correct
                                    ? theme.primary_color
                                    : "#000",
                                }}
                              >
                                {itemAlternative.name}
                              </Typography>
                            )
                          )}
                          <Grid
                            container
                            item
                            justifyContent="center"
                            alignItems="center"
                            style={{ paddingLeft: 15, marginTop: 10 }}
                          >
                            <ButtonBase
                              onClick={() => handleDeleteQuestion(item, index)}
                            >
                              <CloseIcon
                                style={{
                                  fontSize: 33,
                                  color: theme.secondary_color,
                                }}
                              />
                            </ButtonBase>
                            <ButtonBase
                              onClick={() => {
                                setDialogs({
                                  ...dialogs,
                                  dialogUpdateQuestion: true,
                                });
                                setActualIndex(index)
                                handleUpdateQuestion(item, index);
                              }}
                              style={{
                                marginLeft: 15,
                              }}
                            >
                              <EditIcon
                                style={{
                                  fontSize: 30,
                                  color: theme.secondary_color,
                                }}
                              />
                            </ButtonBase>
                          </Grid>
                        </Paper>
                      </Grid>
                    );
                  })
                ) : (
                  <h1>Sem perguntas cadastradas</h1>
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Dialog
        fullWidth
        open={dialogs.newQuestionM}
        onClose={handleCloseDialog("newQuestionM")}
      >
        <DialogTitle>Nova pergunta</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{ width: "100%" }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                marginBottom: 20,
              }}
            >
              <TextField
                type="text"
                fullWidth
                label="Questão"
                variant="outlined"
                value={newQuestionName}
                onChange={(event) => setNewQuestionName(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                type="text"
                fullWidth
                label="Alternativa 1"
                variant="outlined"
                value={alternativeOne.name}
                onChange={(event) =>
                  setAlternativeOne({
                    ...alternativeOne,
                    name: event.target.value,
                  })
                }
              />
              <Select
                value={alternativeOne.correct}
                onChange={() => handleSetCorrectAlternatives("one")}
                label="Verdadeira / falsa"
                variant="outlined"
                style={{
                  width: "50%",
                  marginTop: 15,
                }}
              >
                <MenuItem value={true}>Verdadeira</MenuItem>
                <MenuItem value={false}>Falsa</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                type="text"
                fullWidth
                label="Alternativa 2"
                variant="outlined"
                value={alternativeTwo.name}
                onChange={(event) =>
                  setAlternativeTwo({
                    ...alternativeTwo,
                    name: event.target.value,
                  })
                }
              />
              <Select
                value={alternativeTwo.correct}
                onChange={() => handleSetCorrectAlternatives("two")}
                label="Verdadeira / falsa"
                variant="outlined"
                style={{
                  width: "50%",
                  marginTop: 15,
                }}
              >
                <MenuItem value={true}>Verdadeira</MenuItem>
                <MenuItem value={false}>Falsa</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                type="text"
                fullWidth
                label="Alternativa 3"
                variant="outlined"
                value={alternativeThree.name}
                onChange={(event) =>
                  setAlternativeThree({
                    ...alternativeThree,
                    name: event.target.value,
                  })
                }
              />
              <Select
                value={alternativeThree.correct}
                onChange={() => handleSetCorrectAlternatives("three")}
                label="Verdadeira / falsa"
                variant="outlined"
                style={{
                  width: "50%",
                  marginTop: 15,
                }}
              >
                <MenuItem value={true}>Verdadeira</MenuItem>
                <MenuItem value={false}>Falsa</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                type="text"
                fullWidth
                label="Alternativa 4"
                variant="outlined"
                value={alternativeFour.name}
                onChange={(event) =>
                  setAlternativeFour({
                    ...alternativeFour,
                    name: event.target.value,
                  })
                }
              />
              <Select
                value={alternativeFour.correct}
                onChange={() => handleSetCorrectAlternatives("four")}
                label="Verdadeira / falsa"
                variant="outlined"
                style={{
                  width: "50%",
                  marginTop: 15,
                }}
              >
                <MenuItem value={true}>Verdadeira</MenuItem>
                <MenuItem value={false}>Falsa</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Button
                style={{
                  backgroundColor: "#f00",
                  color: "#fff",
                }}
                onClick={handleCloseDialog("newQuestionM")}
                autoFocus
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                style={{
                  backgroundColor: theme.primary_color,
                  color: theme.secondary_color,
                }}
                onClick={handleAddAlternatives}
                autoFocus
              >
                Adicionar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        open={dialogs.dialogUpdateQuestion}
        onClose={handleCloseDialog("dialogUpdateQuestion")}
      >
        <DialogTitle>Editação de pergunta</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{ width: "100%" }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                marginBottom: 20,
              }}
            >
              <TextField
                type="text"
                fullWidth
                label="Questão"
                variant="outlined"
                value={newQuestionName}
                onChange={(event) => setNewQuestionName(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                type="text"
                fullWidth
                label="Alternativa 1"
                variant="outlined"
                value={alternativeOne.name}
                onChange={(event) =>
                  setAlternativeOne({
                    ...alternativeOne,
                    name: event.target.value,
                  })
                }
              />
              <Select
                value={alternativeOne.correct}
                onChange={() => handleSetCorrectAlternatives("one")}
                label="Verdadeira / falsa"
                variant="outlined"
                style={{
                  width: "50%",
                  marginTop: 15,
                }}
              >
                <MenuItem value={true}>Verdadeira</MenuItem>
                <MenuItem value={false}>Falsa</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                type="text"
                fullWidth
                label="Alternativa 2"
                variant="outlined"
                value={alternativeTwo.name}
                onChange={(event) =>
                  setAlternativeTwo({
                    ...alternativeTwo,
                    name: event.target.value,
                  })
                }
              />
              <Select
                value={alternativeTwo.correct}
                onChange={() => handleSetCorrectAlternatives("two")}
                label="Verdadeira / falsa"
                variant="outlined"
                style={{
                  width: "50%",
                  marginTop: 15,
                }}
              >
                <MenuItem value={true}>Verdadeira</MenuItem>
                <MenuItem value={false}>Falsa</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                type="text"
                fullWidth
                label="Alternativa 3"
                variant="outlined"
                value={alternativeThree.name}
                onChange={(event) =>
                  setAlternativeThree({
                    ...alternativeThree,
                    name: event.target.value,
                  })
                }
              />
              <Select
                value={alternativeThree.correct}
                onChange={() => handleSetCorrectAlternatives("three")}
                label="Verdadeira / falsa"
                variant="outlined"
                style={{
                  width: "50%",
                  marginTop: 15,
                }}
              >
                <MenuItem value={true}>Verdadeira</MenuItem>
                <MenuItem value={false}>Falsa</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                type="text"
                fullWidth
                label="Alternativa 4"
                variant="outlined"
                value={alternativeFour.name}
                onChange={(event) =>
                  setAlternativeFour({
                    ...alternativeFour,
                    name: event.target.value,
                  })
                }
              />
              <Select
                value={alternativeFour.correct}
                onChange={() => handleSetCorrectAlternatives("four")}
                label="Verdadeira / falsa"
                variant="outlined"
                style={{
                  width: "50%",
                  marginTop: 15,
                }}
              >
                <MenuItem value={true}>Verdadeira</MenuItem>
                <MenuItem value={false}>Falsa</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Button
                style={{
                  backgroundColor: "#f00",
                  color: "#fff",
                }}
                onClick={handleCloseDialog("dialogUpdateQuestion")}
                autoFocus
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                style={{
                  backgroundColor: theme.primary_color,
                  color: theme.secondary_color,
                }}
                onClick={updateQuestion}
                autoFocus
              >
                Salvar edição
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        open={dialogs.editQuiz}
        onClose={handleCloseDialog("editQuiz")}
      >
        <DialogTitle>ATENÇÃO!</DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{ width: "100%" }}
          >
            <Grid item xs={12} sm={12}>
              <TextField
                type="text"
                fullWidth
                label="Nome do questionário"
                variant="outlined"
                value={title}
                onChange={(event) => setTitle(event.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Button
                style={{
                  backgroundColor: "#f00",
                  color: "#fff",
                }}
                // onClick={handleCloseDialog('editQuiz')}
                onClick={handleConfirmDeleteQuizz}
              >
                Apagar questionário
              </Button>
            </Grid>
            <Grid item>
              <Button
                style={{
                  backgroundColor: "#f00",
                  color: "#fff",
                }}
                onClick={handleCloseDialog("editQuiz")}
                autoFocus
              >
                Cancelar
              </Button>
              <Button
                style={{
                  backgroundColor: theme.primary_color,
                  marginLeft: 10,
                }}
                onClick={() => {
                  myApp
                    .firestore()
                    .collection("quizModuleVideo")
                    .doc(props.idModuleName)
                    .update({
                      name: title,
                    })
                    .then(() => {
                      montingPage();
                      setDialogs({
                        ...dialogs,
                        editQuiz: false,
                      });
                    })
                    .catch((e) => console.error("Erro ao alterar o nome", e));
                }}
                autoFocus
              >
                Alterar nome
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    idModuleName: state.parameterizationReducer.idQuizModule,
  };
};

export default connect(mapStateToProps, {})(QuizVideoModule);
