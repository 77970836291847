import React, { useEffect, useState } from "react";

import myApp from "../../core/infrastructure/firebaseConfig";

import { Forum, Home, RecordVoiceOver } from "@material-ui/icons";
import HomeIcon from "@material-ui/icons/Home";
import SettingsIcon from "@material-ui/icons/Settings";
import ActionPlansIcon from "../../shared/assets/icons/actionPlansIcon";

import Title from "../../shared/components/title";

import PlanList from "../features/home/components/planList";
import AreaList from "../features/home/components/areaList";

import AreaTitle from "../features/areas/components/areaTitle";
import ActiveAreaList from "../features/areas/components/activeAreaList";

import PlansTitle from "../features/plans/components/plansTitle";
import ActivePlanList from "../features/plans/components/activePlanList";
import ProcessDashboard from "../features/process/components/processDashboard";
import QuizDashboard from "../features/quiz/components/quizDashboard";
import NewQuizPage from "../features/quiz/components/newQuizPage";
import EditNewQuiz from "../features/quiz/components/editNewQuiz";
import InsertQuiz from "../features/quiz/components/insertQuiz";
import NewAnswerPage from "../features/quiz/components/newAnwerPage";

import ProcessAreaList from "../features/process/components/processAreaList";
import ProcessList from "../features/process/components/processList";

import ActionPlans from "../features/actionPlans/components/actionPlans";
import Reports from "../features/reports/components/reports";
import ReportsRopa from "../features/reports/components/reportsRopa";

import Incidentes from "../features/incidentes/components/incidentes";
import ViewIncidentsPDF from "../features/incidentes/components/viewIncidentesPDF";

import LIATestingList from "../features/LIA/LIATestingList";
import ReportsLIA from "../features/LIA/ReportsLIA";
import LIATest from "../features/LIA/LIATest";

import PainelDPO from "../../painelDPO/presentation/pages/painelDPO";
import ListBasis from "../../legalBasis/presentation/pages/listLegalBasis.js";
import LegalBasis from "../../legalBasis/presentation/pages/legalBasis.js";
import ComplementaryLegislation from "../../legalBasis/presentation/pages/complementaryLegislation.js";
import PoliciesAndPlans from "../../policiesAndPlans/presentation/pages/policiesAndPlans.js";
import DashboardIcon from "@material-ui/icons/Dashboard";
import CardTravelIcon from "@material-ui/icons/CardTravel";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import HomeTitle from "../features/home/components/homeTitle";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LanguageIcon from "@material-ui/icons/Language";
import GavelIcon from "@material-ui/icons/Gavel";

import GapsRelatorio from "../features/home/components/gapsRelatorio";
import QuestionnaireReport from "../features/quizAnswerReports/components/questionnaireReport";
import ViewQuestionnaireReport from "../features/quizAnswerReports/components/viewQuestionnairePdf";
import List from "../features/parameterization/components/list";
import ParameterHolders from "../features/parameterization/components/parameterHolders";
import ChatBotParameters from "../features/parameterization/components/chatbotParameters.js";
import AssetManagement from "../features/parameterization/components/assetManagement.js";
import FollowUp from "../../solicitationChannel/presentation/pages/followUp.js";
import SolicitationFormNew from "../../solicitationChannel/presentation/pages/solicitationFormNew.js";
import SolicitationFormEdit from "../../solicitationChannel/presentation/pages/solicitationFormEdit.js";

import Discovery from "../features/discovery/components/Discovery";
import DiscoveryDb from "../features/discovery/components/discoveryDb";

import Videos from "../features/videos/components/videosLGPD";
import PersonalVideoIcon from "@material-ui/icons/PersonalVideo";
import FirebaseEpmIntegration from "../features/epmFirebase/components";
import SupplierRegistration from "../features/parameterization/components/supplierRegistration";
import TrainingVideo from "../features/parameterization/components/trainingVideo";
import Movies from "../features/parameterization/components/movies";
import QuizVideoModule from "../features/parameterization/components/quizVideoModule";
import Denuncias from "../features/denuncias/components/denuncias";
import  DenunciaRefatore  from "../features/denuncias/components/denunciasrefatore";
import DenuncHolders from "../features/parameterization/components/denuncHolders";
import DenunceDetails from "../features/denuncias/components/denunciasDetails";
import ViewReportDenunce from "../features/denuncias/components/ViewsReportDenunce";
import { VideoReport } from "../features/videoReports/components/videoReports";
import ViewPdfReportVideo from "../features/videoReports/components/ViewPdfReportVideo";
import { PageNotPermition } from "../../user/components/page-notPermitions";
import { getUserInformation } from "../command/getUserInformation";
import  DetailsRefatore  from "../features/denuncias/components/DetailsRefatore";
import { NewReportRopa } from "../features/reports/components/newReportRopa.jsx";
import { ViewLogs } from "../features/logs/index.jsx";

let permitionsList = [];
let userAdmin = ""

export const getPermitions = async () => {
  const InfoUser = await getUserInformation();

  let profille = await myApp
    .firestore()
    .collection("profiles_company_group")
    .where(
      "fk_company_group",
      "==",
      localStorage.getItem("@blue-legal/companyGroup")
    )
    .get();
 

    let userAdmin = InfoUser.isAdmin ? 'sim' : 'não';
    if(InfoUser?.permitionLevel){
    localStorage.setItem('userAdmin', userAdmin);
      let getUnicProfile = profille?.docs?.find(
        (item) => item.data().name == InfoUser?.permitionLevel?.name
        );
        permitionsList.push(getUnicProfile.data()?.PagePermition);
      }

   
     

  

  let componentList = [
    {
      name: "Home",
      icon: Home,
      showOnSideBar: true,
      fullScreen: false,
      component:
        permitionsList[0]?.find((item) => item.page == "Home") ||
        userAdmin == "sim" ? (
          <div>
            <HomeTitle />
            <PlanList />
            <Title title="Áreas" />
            <AreaList />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Áreas",
      icon: Home,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find((item) => item.page == "Áreas") ||
        userAdmin == "sim" ? (
          <div>
            <AreaTitle />
            <ActiveAreaList />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Overview da empresa",
      icon: Home,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find((item) => item.page == "Overview da empresa") ||
        userAdmin == "sim" ? (
          <div>
            <PlansTitle />
            <ActivePlanList />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Questionário",
      icon: Forum,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find((item) => item.page == "Questionário") ||
        userAdmin == "sim" ? (
          <div style={{ height: "95%" }}>
            <Title title="Questionários" />
            <QuizDashboard />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Inserir questionário",
      icon: Forum,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find((item) => item.page == "Inserir questionário") ||
        userAdmin == "sim" ? (
          <InsertQuiz />
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Processos",
      icon: SettingsIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find((item) => item.page == "Processos") ||
        userAdmin == "sim" ? (
          <div style={{ height: "95%" }}>
            <Title title="Áreas" />
            <ProcessAreaList />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Lista de processos",
      icon: SettingsIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find((item) => item.page == "Lista de processos") ||
        userAdmin == "sim" ? (
          <div style={{ height: "95%" }}>
            {/*<Title title="Processos" />*/}
            <ProcessList />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Dashboard do processos",
      icon: HomeIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find((item) => item.page == "Dashboard do processos") ||
        userAdmin == "sim" ? (
          <div style={{ height: "95%" }}>
            <ProcessDashboard />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Relatório de GAPS",
      icon: LibraryBooksIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find((item) => item.page) == "Relatório de GAPS" ||
        userAdmin == "sim" ? (
          <div style={{ height: "90%" }}>
            <Title title="Relatório de GAPS" />
            <GapsRelatorio />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Relatório de respostas",
      icon: LibraryBooksIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find(
          (item) => item.page == "Relatório de respostas"
        ) || userAdmin == "sim" ? (
          <div style={{ height: "90%" }}>
            <Title title="Respostas" />
            <QuestionnaireReport />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Teste LIA",
      icon: Forum,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find((item) => item.page == "Teste LIA") ||
        userAdmin == "sim" ? (
          <div>
            <Title title="Lista de teste LIA" />
            <LIATestingList />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "LIA teste",
      icon: Forum,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find(
          (item) => item.page == "LIA teste"
        ) || userAdmin == "sim" ? (
          <LIATest />
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Cronograma",
      icon: ActionPlansIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find((item) => item.page == "Cronograma") ||
        userAdmin == "sim" ? (
          <div>
            <Title title="Cronograma adequação LGPD" />
            <ActionPlans />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Painel do DPO",
      icon: DashboardIcon,
      showOnSideBar: true,
      fullScreen: false,
      component:
        permitionsList[0]?.find((item) => item.page == "Painel do DPO") ||
        userAdmin == "sim" ? (
          <div>
            <Title title="Painel do DPO" />
            <PainelDPO />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Bases legais/legislação",
      icon: CardTravelIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find(
          (item) => item.page == "Bases legais/legislação"
        ) || userAdmin == "sim" ? (
          <div style={{ height: "90%" }}>
            <Title title="Bases legais/legislação" />
            {/*<LegalBasis />*/}
            <ListBasis />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Políticas e planos",
      icon: AssignmentIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find((item) => item.page == "Políticas e planos") ||
        userAdmin == "sim" ? (
          <div>
            <Title title="Políticas e Planos" />
            <PoliciesAndPlans />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Canal de solicitações",
      icon: RecordVoiceOver,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find(
          (item) => item.page == "Canal de solicitações"
        ) || userAdmin == "sim" ? (
          <div>
            <Title title="Canal de Solicitações" />
            {/* <SolicitationChannel /> */}
            <FollowUp />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Canal de novas solicitações",
      icon: RecordVoiceOver,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find(
          (item) => item.page == "Canal de novas solicitações"
        ) || userAdmin == "sim" ? (
          <div>
            <Title title="Nova Solicitação" />
            <SolicitationFormNew />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Formulário de solicitações",
      icon: RecordVoiceOver,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find(
          (item) => item.page == "Formulário de solicitações"
        ) || userAdmin == "sim" ? (
          <div>
            <Title title="Editar Solicitação" />
            <SolicitationFormEdit />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Relatórios ROPA",
      icon: LibraryBooksIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find((item) => item.page == "Relatórios ROPA") ||
        userAdmin == "sim" ? (
          <div>
            <Title title="ROPA (Registro das Atividades de Tratamento)" />
            {/* <ReportsRopa /> */}
            <NewReportRopa/>
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Comunicação de incidentes",
      icon: LibraryBooksIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find(
          (item) => item.page == "Comunicação de incidentes"
        ) || userAdmin == "sim" ? (
          <div>
            <Incidentes />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Relatórios DPIA",
      icon: LibraryBooksIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find((item) => item.page == "Relatórios DPIA") ||
        userAdmin == "sim" ? (
          <div>
            <Title title="DPIA (Relatório de Impacto à Proteção de Dados)" />
            <Reports />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Relatórios LIA",
      icon: LibraryBooksIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find((item) => item.page == "Relatórios LIA") ||
        userAdmin == "sim" ? (
          <div>
            <Title title="LIA (Relatório de Legítimo Interesse)" />
            <ReportsLIA />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Discovery - Arquivos",
      icon: LanguageIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find(
          (item) => item.page == "Discovery - Arquivos"
        ) || userAdmin == "sim" ? (
          <div style={{ height: "90%" }}>
            <Title title="Discovery - Arquivos" />
            <Discovery />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Discovery - BD",
      icon: LanguageIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find((item) => item.page == "Discovery - BD") ||
        userAdmin == "sim" ? (
          <div style={{ height: "90%" }}>
            <Title title="Discovery - BD" />
            <DiscoveryDb />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Parametrização",
      icon: SettingsIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find((item) => item.page == "Parametrização") ||
        userAdmin == "sim" ? (
          <div style={{ height: "90%" }}>
            {/*<Title title="Parametrização" />*/}
            <List />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Relatorio de respostas de questionário",
      icon: LibraryBooksIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find(
          (item) => item.page == "Relatorio de respostas de questionário"
        ) || userAdmin == "sim" ? (
          <div style={{ height: "90%" }}>
            {/*<Title title="Respostas" />*/}
            <ViewQuestionnaireReport />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    //27
    {
      name: "Treinamento",
      icon: PersonalVideoIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find((item) => item.page == "Treinamento") ||
        userAdmin == "sim" ? (
          <div style={{ height: "90%" }}>
            <Title title="Treinamento" />
            <Videos />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Canal do titular",
      icon: SettingsIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find(
          (item) => item.page == "Canal do titular"
        ) || userAdmin == "sim" ? (
          <div style={{ height: "90%" }}>
            <ParameterHolders />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Parametrização ChatBot",
      icon: SettingsIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find(
          (item) => item.page == "Parametrização ChatBot"
        ) || userAdmin == "sim" ? (
          <div style={{ height: "90%" }}>
            <ChatBotParameters />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Getão de ativos",
      icon: SettingsIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find((item) => item.page == "Getão de ativos") ||
        userAdmin == "sim" ? (
          <div style={{ height: "90%" }}>
            <AssetManagement />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Bases legais",
      icon: SettingsIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find((item) => item.page == "Bases legais") ||
        userAdmin == "sim" ? (
          <div style={{ height: "90%" }}>
            <LegalBasis />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Legislação",
      icon: SettingsIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find((item) => item.page == "Legislação") ||
        userAdmin == "sim" ? (
          <div style={{ height: "90%" }}>
            <ComplementaryLegislation />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    // {
    //   name: "Importação EPM",
    //   icon: LibraryBooksIcon,
    //   showOnSideBar: Cookies.get("viewBtLaw") === "1" ? true : false,
    //   showOnSideBar: true,
    //   fullScreen: false,
    //   component:
    //     permitionsList[0]?.find((item) => item.page == "Importação EPM") ||
    //     userAdmin == "sim" ? (
    //       <div style={{ height: "90%" }}>
    //         <FirebaseEpmIntegration />
    //       </div>
    //     ) : (
    //       <PageNotPermition />
    //     ),
    // },
    {
      name: "Cadastro de fornecedores",
      icon: LibraryBooksIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find(
          (item) => item.page == "Cadastro de fornecedores"
        ) || userAdmin == "sim" ? (
          <div style={{ height: "90%" }}>
            <SupplierRegistration />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Visualizar relatório de incidente",
      icon: LibraryBooksIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find(
          (item) => item.page == "Visualizar relatório de incidente"
        ) || userAdmin == "sim" ? (
          <div style={{ height: "95%" }}>
            <ViewIncidentsPDF />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Vídeo de treinamento",
      icon: LibraryBooksIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find(
          (item) => item.page == "Vídeo de treinamento"
        ) || userAdmin == "sim" ? (
          <div style={{ height: "95%" }}>
            <TrainingVideo />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Vídeos",
      icon: LibraryBooksIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find((item) => item.page == "Vídeos") ||
        userAdmin == "sim" ? (
          <div style={{ height: "95%" }}>
            <Movies />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Questionário de vídeos",
      icon: LibraryBooksIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find(
          (item) => item.page == "Questionário de vídeos"
        ) || userAdmin == "sim" ? (
          <div style={{ height: "95%" }}>
            <QuizVideoModule />
          </div>
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Novo questinário 2.0",
      icon: LibraryBooksIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find(
          (item) => item.page == "Novo questinário 2.0"
        ) || userAdmin == "sim" ? (
          <NewQuizPage />
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Editar questinário 2.0",
      icon: LibraryBooksIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find(
          (item) => item.page == "Editar questinário 2.0"
        ) || userAdmin == "sim" ? (
          <EditNewQuiz />
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Responder questinário 2.0",
      icon: LibraryBooksIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find(
          (item) => item.page == "Responder questinário 2.0"
        ) || userAdmin == "sim" ? (
          <NewAnswerPage />
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Parametrização do canal de denúncia",
      icon: GavelIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find(
          (item) => item.page == "Parametrização do canal de denúncia"
        ) || userAdmin == "sim" ? (
          <DenuncHolders />
        ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Canal de denúncia",
      icon: GavelIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find((item) => item.page == "Canal de denúncia") ||
        userAdmin == "sim" ? (
          // <Denuncias/>
            <DenunciaRefatore/>
          ) : (
          <PageNotPermition />
        ),
    },
    {
      name: "Detalhes denúncia",
      icon: GavelIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find((item) => item.page == "Detalhes denúncia") ||
        userAdmin == "sim" ? (
          // <DenunceDetails />
          <DetailsRefatore/>
        ) : (
          <PageNotPermition />
        ),
    },

    {
      name: "Relatório em PDF de denuncias",
      icon: GavelIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find(
          (item) => item.page == "Relatório em PDF de denuncias"
        ) || userAdmin == "sim" ? (
          <ViewReportDenunce />
        ) : (
          <PageNotPermition />
        ),
    },

    //numero 46
    {
      name: "Respostas de treinamento",
      icon: GavelIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find(
          (item) => item.page == "Respostas de treinamento"
        ) || userAdmin == "sim" ? (
          <VideoReport />
        ) : (
          <PageNotPermition />
        ),
    },

    {
      name: "Relatório em PDF de respostas treinamento",
      icon: GavelIcon,
      showOnSideBar: false,
      fullScreen: false,
      component:
        permitionsList[0]?.find(
          (item) => item.page == "Relatório em PDF de respostas treinamento"
        ) || userAdmin == "sim" ? (
          <ViewPdfReportVideo />
        ) : (
          <PageNotPermition />
        ),
    },

    {
      name: "Controle de usuários",
      icon: Home,
      showOnSideBar: false,
      fullScreen: false,
    },
    
    {
      name: "Relatório de logs",
      icon: GavelIcon,
      showOnSideBar: false,
      fullScreen: false,
      Title: 'Relatório de logs',
      component:
        permitionsList[0]?.find(
          (item) => item.page == "Relatório de logs"
        ) || userAdmin == "sim" ? (
          <ViewLogs />
        ) : (
          <PageNotPermition />
        ),
    },

  ];

  // console.log('user',componentList )

  return componentList;
};



