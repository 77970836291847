import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import React, { Component } from "react";
import {
  Paper,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TextField,
  CircularProgress
} from "@material-ui/core";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import theme from "../../../../core/theme.json";
import { AddAlarm } from "@material-ui/icons";

const RISCO_ENUM = {
  1: "mínimo",
  2: "pequeno",
  3: "médio",
  4: "grande",
  5: "muito grande",
};
class Reports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dadosDaEmpresa: {},
      database: myApp.firestore(),
      companyId: localStorage.getItem("currentCompany"),
      currentDateCover: this.getCoverFormattedDate(),
      processList: [],
      processViewIndex: 0,
      areaList: [],
      gaps: [],
      customGaps: [],
      areaFilter: "",
      processFilter: "",
      loading: true
    };

    this.filterProcessList = this.filterProcessList.bind(this);
  }

  async componentDidMount() {
    await this.loadCompany();
    await this.getProcessesFromCompany();
    this.setState({
      loading: false,
    });
  }

  getCoverFormattedDate() {
    let months = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];

    let currentDate = new Date();

    return (
      currentDate.getDate().toString().padStart(2, "0") +
      ", " +
      months[currentDate.getMonth()].toString() +
      " " +
      currentDate.getFullYear().toString()
    );
  }

  async loadCompany() {
    let companySnapshot = await this.state.database
      .collection("companies")
      .doc(this.state.companyId)
      .get();
    let company = companySnapshot.data();

    this.setState({
      dadosDaEmpresa: company,
    });
  }

  async getProcessesFromCompany() {
    let areasSnapshot = await this.state.database
      .collection("areas")
      .where("fk_companies", "==", this.state.companyId)
      .where("active", "==", true)
      .get();

    let areas = areasSnapshot.docs;

    let areaList = [];
    let filterAreaList = [];
    let processList = this.state.processList;

    for (const el of areas) {
      let area = el.data();
      area.id = el.id;
      areaList.push(area);
    }

    for (const area of areaList) {
      let processesRef = await this.state.database
        .collection("processes")
        .where("fk_areas", "==", area.id)
        .get();

      let processes = processesRef.docs;

      for (let doc of processes) {
        if (!filterAreaList.some(a => a === area.name)) {
          filterAreaList.push(area.name);
        }

        let process = doc.data();
        process.id = doc.id;

        let probability = 0;
        let impact = 0;
        let points = 0;
        let probabilityCategory = "";
        let impactCategory = "";
        let pointsCategory = "";

        process.probabilityCategory = "não definido";
        process.impactCategory = "não definido";
        process.pointsCategory = "não definido";

        // custom gaps
        if (process.customGaps && process.customGaps.length > 0) {
          for (const gap of process.customGaps) {
            probability += (gap.riskChance + gap.riskGapChance) / 2;
            impact += (gap.riskImpact + gap.riskGapImpact) / 2;
            points += (gap.risk + gap.riskGap) / 2;
          }

          impact /= process.customGaps.length;
          probability /= process.customGaps.length;
          points /= process.customGaps.length;

          if (probability >= 1 && probability <= 8) {
            probabilityCategory = "baixa";
          } else if (probability >= 9 && probability <= 16) {
            probabilityCategory = "média";
          } else if (probability >= 17 && probability <= 25) {
            probabilityCategory = "alta";
          }

          if (impact >= 1 && impact <= 8) {
            impactCategory = "baixa";
          } else if (impact >= 9 && impact <= 16) {
            impactCategory = "média";
          } else if (impact >= 17 && impact <= 25) {
            impactCategory = "alta";
          }

          if (points >= 1 && points <= 8) {
            pointsCategory = "baixa";
          } else if (points >= 9 && points <= 16) {
            pointsCategory = "média";
          } else if (points >= 17 && points <= 25) {
            pointsCategory = "alta";
          }

          process.probabilityCategory = probabilityCategory;
          process.impactCategory = impactCategory;
          process.pointsCategory = pointsCategory;
        }

        process.areaName = area.name;
        process.areaOwner = area.owner;

        if (process.legalBases.length > 0) {
          process.legalBasisName = process.legalBases[0];
          let legalBasisSnapshot = await this.state.database.collection("legal_basis").where("name", "==", process.legalBasisName).get();
          process.legalBasisJustification = legalBasisSnapshot.docs[0].data().legalJustification;
        }

        try {
          if (process.json && process.json !== "") {
            let stages = JSON.parse(process.json);
            let keys = Object.keys(stages);
            process.dadosTitulares = [];
            process.titulares = [];

            let getValues = Object.values(stages);
            let allAssetsStorage = [];
            let allAssetsExternalSharing = [];
            let allExternalSharing = [];

            for (let item of getValues) {
              if (item.type == "armazenamento") {
                let itemAssets = item.assets.slice();
                allAssetsStorage.push(...itemAssets);
              }
              if (item.type == "compartilhamentoExterno") {
                let itemAssets = item.assets.slice();
                allAssetsExternalSharing.push(...itemAssets);
                let itemExternalSharing = item.sharings.slice();
                allExternalSharing.push(...itemExternalSharing);
              }
            }

            process.allAssetsStorage = allAssetsStorage;
            process.allAssetsExternalSharing = allAssetsExternalSharing;
            process.allExternalSharing = allExternalSharing;


            for (const key of keys) {
              if (stages[key].attributes && stages[key].attributes.length > 0) {
                for (const attr of stages[key].attributes) {
                  if (attr.fields && attr.fields.length > 0) {
                    if (!process.titulares.some((t) => t === attr.name)) {
                      process.titulares.push(attr.name);
                    }

                    for (const field of attr.fields) {
                      process.dadosTitulares.push({
                        titular: attr.name,
                        attribute: field.name,
                        sensitive: field.sensitive
                      });
                    }
                  }
                }
              }
            }
          }
        } catch (e) {
          console.log("Erro getting titulares", e);
        }

        console.log(process)
        processList.push(process);
      }
    }

    this.setState({
      processList,
      areaList: filterAreaList.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1),
    });
  }

  getProccessTypes(process = {}) {
    if (!process.json) return [];

    const types = Object.values(process.json).map((task) => task.type);

    return types;
  }

  getGaps(process = {}) {
    if (!process.customGaps)
      return [];

    return process.customGaps;
  }

  getStorageType(process = {}) {
    if (!process.json) return null;

    const withStorage = Object.values(process.json).find(
      (task) => task.type === "armazenamento" && task.storageType
    );

    if (withStorage) return withStorage.storageType;
    return null;
  }

  renderGap(gap) {
    const styles = StyleSheet.create({
      container: {
        margin: "5% 10% 0",
        flexDirection: "column",
      },
      infoContainer: {
        marginTop: "3px",
        flexDirection: "row",
      },
      gapName: {
        fontSize: "15px",
        color: "#222",
      },
      infoTitle: {
        fontSize: "12px",
        color: "#222",
        marginLeft: "3px",
      },
      info: {
        color: "#555",
        fontSize: "11px",
        marginLeft: "3px",
      },
    });

    return (
      <View style={styles.container}>
        <View style={styles.infoContainer}>
          <Text style={styles.gapName}>{gap.name.toUpperCase()}</Text>
        </View>
        <View style={{ marginLeft: "15px" }}>
          <View style={styles.infoContainer}>
            <Text style={styles.info}>Probabilidade:</Text>
            <Text style={styles.info}>{RISCO_ENUM[gap.riskGapChance]}</Text>
          </View>
          <View style={styles.infoContainer}>
            <Text style={styles.info}>Impacto:</Text>
            <Text style={styles.info}>{RISCO_ENUM[gap.riskGapImpact]}</Text>
          </View>
          <View style={styles.infoContainer}>
            <Text style={styles.info}>Pontuação:</Text>
            <Text style={styles.info}>{gap.riskGap}</Text>
          </View>
        </View>
      </View>
    );
  }

  handleProcessViewIndex(index) {
    this.setState({
      processViewIndex: index,
    });
  }

  renderCells = (process, index) => {
    return (
      <TableRow key={process.id} style={index % 2 ? { background: "white" } : { background: "#e3e3e3" }}>
        <TableCell>{process.areaName}</TableCell>
        <TableCell>{process.name}</TableCell>
        <TableCell>
          <Button
            color="primary"
            backgroundColor="black"
            style={{
              background: theme.primary_color,
              color: theme.secondary_color,
            }}
            onClick={() => this.handleProcessViewIndex(index)}
          >
            VISUALIZAR
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  handleFilterAreaChange(event) {
    this.setState({
      areaFilter: event.target.value,
      processViewIndex: 0
    });
  }

  handleFilterProcessChange(event) {
    this.setState({
      processFilter: event.target.value,
      processViewIndex: 0
    });
  }

  filterProcessList(process) {
    if (
      this.state.areaFilter !== "" &&
      this.state.processFilter !== "" &&
      process.areaName === this.state.areaFilter &&
      process.name.toUpperCase().includes(this.state.processFilter.toUpperCase())
    ) {
      return process;
    } else if (
      this.state.areaFilter !== "" &&
      this.state.processFilter === "" &&
      process.areaName === this.state.areaFilter &&
      process.name !== ""
    ) {
      return process;
    } else if (
      this.state.areaFilter === "" &&
      this.state.processFilter !== "" &&
      process.areaName !== "" &&
      process.name.toUpperCase().includes(this.state.processFilter.toUpperCase())
    ) {
      return process;
    } else if (
      this.state.areaFilter === "" &&
      this.state.processFilter === "" &&
      process.areaName !== "" &&
      process.name !== ""
    ) {
      return process;
    }
  }

  renderPDF(process) {
    console.log(process)
    const isExternal =
      this.getProccessTypes(process).indexOf("compartilhamentoExterno") >= 0;

    const gaps = this.getGaps(process);
    const storageType = this.getStorageType(process);

    try {
      let self = this;
      const styles = StyleSheet.create({
        cover: {
          flexDirection: "column",
          backgroundColor: "#000",
          color: "#FFF",
        },
        page: {
          flexDirection: "column",
          backgroundColor: "#FFF",
        },
        section: {
          margin: 10,
          padding: 10,
          flexGrow: 1,
        },
        list: {
          flexDirection: "column",
        },
        hr: {
          marginTop: "2px",
          marginBottom: "2px",
          marginLeft: "10%",
          borderTop: "2px solid #e5e5e5",
          maxWidth: "70%",
        },
        marginTopInt: {
          marginTop: 20
        },
        coverListItem: {
          fontSize: "13px",
          color: "#b2b2b2",
        },
        coverListItemBold: {
          fontSize: "13px",
          fontWeight: "bold",
        },
        coverListItemView: {
          flexDirection: "row",
          marginBottom: "1%",
        },
        sectionContainer: {
          width: "50%",
          margin: "1% 0 0.5% 10%",
        },
        sectionTitle: {
          fontSize: "20px",
        },
        sectionContent: {
          flexDirection: "column",
          margin: "0 0 2px 10%",
          color: "#878787",
        },
        pageListItemView: {
          flexDirection: "row",
          marginBottom: "0.5%",
          fontSize: "12px",
        },
      });

      return (
        <Document>
          {/* Capa */}
          <Page size="A4" style={styles.cover} wrap>
            <View style={{ flexDirection: "row" }}>
              <View style={{ margin: "10% 40% 0 10%" }}>
                <Text style={{ color: "#FAFAFA", fontSize: "13px" }}>
                  {self.state.currentDateCover}
                </Text>
              </View>
              <View style={{ flexDirection: "column", margin: "10% 0 10% 0" }}>
                <View style={{ flexDirection: "row" }}>
                  <Text style={styles.coverListItem}>Situação do RPID: </Text>
                  <Text style={styles.coverListItemBold}>Aprovado</Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={styles.coverListItem}>Por: </Text>
                  <Text style={styles.coverListItemBold}>
                    {this.state.dadosDaEmpresa.dpo}
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={styles.coverListItem}>Versão: </Text>
                  <Text style={styles.coverListItemBold}>1.0</Text>
                </View>
              </View>
            </View>

            <View style={{ margin: "8% 0 0 12%", width: "60%" }}>
              <Text style={{ fontSize: "48px", color: "#FAFAFA" }}>
                RELATÓRIO DE IMPACTO À PROTEÇÃO DE DADOS
              </Text>
            </View>
            <View style={{ margin: "4% 0 20% 12%", width: "68%" }}>
              <Text
                style={{
                  fontSize: "15px",
                  color: "#FAFAFA",
                  letterSpacing: "1.5px",
                }}
              >
                PARA ATENDIMENTO AOS ART. 10, PARÁGRAFO 3º E 38º DA LEI
                13.709/2018
              </Text>
            </View>

            <View style={{ flexDirection: "column", margin: "12% 0 10% 12%" }}>
              <View style={styles.coverListItemView}>
                <Text style={styles.coverListItem}>Nome/Razão Social: </Text>
                <Text style={styles.coverListItemBold}>
                  {this.state.dadosDaEmpresa.nome}
                </Text>
              </View>
              <View style={styles.coverListItemView}>
                <Text style={styles.coverListItem}>Pessoa de Contato: </Text>
                <Text style={{ fontSize: "13px" }}>
                  DPO - {this.state.dadosDaEmpresa.dpo}
                </Text>
              </View>
              <View style={styles.coverListItemView}>
                <Text style={styles.coverListItem}>Cargo/Função: </Text>
                <Text style={styles.coverListItemBold}>
                  Encarregado de Dados
                </Text>
              </View>
              <View style={styles.coverListItemView}>
                <Text style={styles.coverListItem}>Endereço Completo: </Text>
                <Text style={styles.coverListItemBold}>
                  {this.state.dadosDaEmpresa.companyStreet},{" "}
                  {this.state.dadosDaEmpresa.companyNumber}
                </Text>
              </View>
              <View style={styles.coverListItemView}>
                <Text style={styles.coverListItem}>CEP: </Text>
                <Text style={styles.coverListItemBold}>
                  {this.state.dadosDaEmpresa.companyCEP}
                </Text>
              </View>
              <View style={styles.coverListItemView}>
                <Text style={styles.coverListItem}>Cidade: </Text>
                <Text style={styles.coverListItemBold}>
                  {this.state.dadosDaEmpresa.companyCity} - {this.state.dadosDaEmpresa.companyState}
                </Text>
              </View>
              <View style={styles.coverListItemView}>
                <Text style={styles.coverListItem}>Telefone: </Text>
                <Text style={styles.coverListItemBold}>
                  {this.state.dadosDaEmpresa.dpo_phone}
                </Text>
              </View>
            </View>
          </Page>

          {/* Página 1 */}
          <Page size="A4" style={styles.page} wrap>
            <View style={{ width: "35%", margin: "12% 0 3% 10%" }}>
              <Text>Dados do Encarregado de Proteção de Dados:</Text>
            </View>
            <View
              style={{
                flexDirection: "column",
                margin: "0 0 2% 10%",
                color: "#878787",
                fontSize: "8px",
              }}
            >
              <View style={styles.pageListItemView}>
                <Text style={{ fontSize: "13px" }}>
                  DPO - {this.state.dadosDaEmpresa.dpo}
                </Text>
              </View>
              <View style={styles.pageListItemView}>
                <Text style={{ fontSize: "13px" }}>
                  Cargo/Função: Encarregado de Dados{" "}
                </Text>
              </View>
              <View style={styles.pageListItemView}>
                <Text style={styles.coverListItemBold}>
                  {this.state.dadosDaEmpresa.dpo_phone}
                </Text>
              </View>
            </View>
            <View style={styles.hr} />

            <View style={styles.sectionContainer}>
              <Text style={styles.sectionTitle}>Atividade</Text>
            </View>
            <View style={styles.sectionContent}>
              <View style={styles.pageListItemView}>
                <Text>{process.name.toUpperCase()}</Text>
              </View>
              <View style={styles.coverListItemView}>
                <Text style={styles.coverListItem}>
                  CEP: {this.state.dadosDaEmpresa.companyCEP}{" "}
                </Text>
              </View>
              <View style={styles.coverListItemView}>
                <Text style={styles.coverListItem}>
                  Cidade: {this.state.dadosDaEmpresa.companyCity} - {this.state.dadosDaEmpresa.companyState}{" "}
                </Text>
              </View>
              <View style={styles.coverListItemView}>
                <Text style={styles.coverListItem}>
                  Telefone: {this.state.dadosDaEmpresa.dpo_phone}{" "}
                </Text>
              </View>
            </View>

            <View style={styles.sectionContainer}>
              <Text style={styles.sectionTitle}>Departamento Responsável</Text>
            </View>
            <View style={styles.sectionContent}>
              <View style={styles.pageListItemView}>
                <Text>{process.areaName.toUpperCase()}</Text>
              </View>
            </View>

            <View style={styles.sectionContainer}>
              <Text style={styles.sectionTitle}>Pessoa Responsável/Cargo</Text>
            </View>
            <View style={styles.sectionContent}>
              <View style={styles.pageListItemView}>
                <Text>{process.areaOwner.toUpperCase()}</Text>
              </View>
            </View>

            <View style={styles.sectionContainer}>
              <Text style={styles.sectionTitle}>
                Finalidade do Processamento
              </Text>
            </View>
            <View style={styles.sectionContent}>
              <View style={styles.pageListItemView}>
                <Text>{process.goal.toUpperCase()}</Text>
              </View>
            </View>

            <View style={styles.sectionContainer}>
              <Text style={styles.sectionTitle}>Forma de Coleta de Dados</Text>
            </View>
            <View style={styles.sectionContent}>
              <View style={styles.pageListItemView}>
                <Text>PORTAL TERCEIRO</Text>
              </View>
            </View>
            <View style={styles.hr} />

            <View style={{ width: "50%", margin: "4% 0 0.5% 10%" }}>
              <Text style={styles.sectionTitle}>
                Base Legal para Atividade de Processamento
              </Text>
            </View>
            <View style={styles.sectionContent}>
              <View style={styles.pageListItemView}>
                <Text style={{ width: "350px" }}>{process.legalBasisName}</Text>
              </View>
            </View>

            {/* <View style={{ width: "50%", margin: "4% 0 0.5% 10%" }}>
              <Text style={styles.sectionTitle}>
                Justificativa do Uso de Base Legal
              </Text>
            </View>
            <View style={styles.sectionContent}>
              <View style={styles.pageListItemView}>
                <Text
                  style={{
                    maxWidth: "60%",
                    letterSpacing: "1px",
                    lineHeight: "1.3",
                  }}
                >
                  {
                    process.legalBasisJustification !== ""
                      ? process.legalBasisJustification
                      : "Sem justificativa"
                  }
                </Text>
              </View>
            </View> */}
          </Page>

          {
            process.allAssetsStorage.length > 0 ?
              <Page>
                <View style={{ width: "47%", margin: "8% 0 0.5% 10%" }}>
                  <Text style={styles.sectionTitle}>
                    Ativos de Armazenamento
                  </Text>
                  {
                    process.allAssetsStorage.map((item, index) => (
                      <Text key={index} style={{ marginTop: 20, fontSize: "11px" }}>
                        {item.asset}
                      </Text>
                    ))
                  }
                </View>
                <View style={[styles.hr, styles.marginTopInt]} />
              </Page>
              : <></>
          }

          {
            process.allAssetsExternalSharing.length > 0 ?
              <Page>
                <View style={{ width: "47%", margin: "8% 0 0.5% 10%" }}>
                  <Text style={styles.sectionTitle}>
                    Ativos de Compartilhamento <br /> externo
                  </Text>
                  {
                    process.allAssetsExternalSharing.map((item, index) => (
                      <Text key={index} style={{ marginTop: 20, fontSize: "11px" }}>
                        {item.asset}
                      </Text>
                    ))
                  }
                </View>
                <View style={styles.hr} />

                {/* <View style={{ width: "47%", margin: "8% 0 0.5% 10%" }}>
                  <Text style={styles.sectionTitle}>
                    Mapeamento do
                  </Text>
                  <Text style={styles.sectionTitle}>
                    Compartilhamento externo
                  </Text>
                </View> 
                <View style={styles.hr} />
                */}
              </Page>
              :
              <></>
          }

          {
            process.allExternalSharing.length > 0 ?
              <Page>
                <View style={{ width: "47%", margin: "8% 0 0.5% 10%" }}>
                  <Text style={styles.sectionTitle}>
                    Mapeamento do
                  </Text>
                  <Text style={styles.sectionTitle}>
                    Compartilhamento externo
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "column",
                    margin: "5% 0 5% 10%",
                    width: " 450px",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      margin: "0 0 0.5% 0",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text style={{ flex: "1", fontSize: "20px" }}>Origem</Text>
                    <Text style={{ flex: "1", fontSize: "20px" }}>Destino</Text>
                  </View>
                  {process.allExternalSharing.map((item, index) => (
                    <View
                      key={index}
                      style={{
                        flexDirection: "row",
                        margin: "0 0 0.5% 0",
                        justifyContent: "space-between",
                        color: "#878787",
                      }}
                    >
                      <Text style={{ flex: "1", fontSize: "11px" }}>{item.source}</Text>
                      <Text style={{ flex: "1", fontSize: "11px" }}>{item.destiny}</Text>
                    </View>
                  ))}

                  <View
                    style={{
                      flexDirection: "row",
                      margin: "0 0 0.5% 0",
                      justifyContent: "space-between",
                      marginTop: 20
                    }}
                  >
                    <Text style={{ flex: "1", fontSize: "20px" }}>Setor responsável - Origem</Text>
                    <Text style={{ flex: "1", fontSize: "20px" }}>Setor responsável - Destino</Text>
                  </View>
                  {process.allExternalSharing.map((item, index) => (
                    <View
                      key={index}
                      style={{
                        flexDirection: "row",
                        margin: "0 0 0.5% 0",
                        justifyContent: "space-between",
                        color: "#878787",
                      }}
                    >
                      <Text style={{ flex: "1", fontSize: "11px" }}>{item.sourceSector}</Text>
                      <Text style={{ flex: "1", fontSize: "11px" }}>{item.destinySector}</Text>
                    </View>
                  ))}

                  <View
                    style={{
                      flexDirection: "row",
                      margin: "0 0 0.5% 0",
                      justifyContent: "space-between",
                      marginTop: 20
                    }}
                  >
                    <Text style={{ flex: "1", fontSize: "20px" }}>Responsável - Origem</Text>
                    <Text style={{ flex: "1", fontSize: "20px" }}>Responsável - Destino</Text>
                  </View>
                  {process.allExternalSharing.map((item, index) => (
                    <View
                      key={index}
                      style={{
                        flexDirection: "row",
                        margin: "0 0 0.5% 0",
                        justifyContent: "space-between",
                        color: "#878787",
                      }}
                    >
                      <Text style={{ flex: "1", fontSize: "11px" }}>{item.sourceOwner}</Text>
                      <Text style={{ flex: "1", fontSize: "11px" }}>{item.destinyOwner}</Text>
                    </View>
                  ))}

                </View>
                <View style={styles.hr} />

              </Page>
              :
              <></>

          }

          <Page>
            <View style={{ width: "47%", margin: "8% 0 0.5% 10%" }}>
              <Text style={styles.sectionTitle}>
                Categoria de Dados Utilizados na Atividade
              </Text>
            </View>
            <View style={styles.hr} />

            <View
              style={{
                flexDirection: "column",
                margin: "5% 0 5% 10%",
                width: " 450px",
              }}
            >
              {/* Cabeçalho do formulario */}
              <View
                style={{
                  flexDirection: "row",
                  margin: "0 0 0.5% 0",
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ flex: "1", fontSize: "20px" }}>Dado</Text>
                <Text style={{ flex: "1", fontSize: "20px" }}>Titular</Text>
                <Text style={{ flex: "1", fontSize: "20px" }}>Sensível</Text>
              </View>
              {process.dadosTitulares && process.dadosTitulares.map((t) => (
                <View
                  style={{
                    flexDirection: "row",
                    margin: "0 0 0.5% 0",
                    justifyContent: "space-between",
                    color: "#878787",
                  }}
                >
                  <Text style={{ flex: "1", fontSize: "11px" }}>{t.attribute}</Text>
                  <Text style={{ flex: "1", fontSize: "11px" }}>{t.titular}</Text>
                  <Text style={{ flex: "1", fontSize: "11px" }}>{t.sensitive ? "Sim" : "Não"}</Text>
                </View>
              ))}
            </View>
            <View style={styles.hr} />

            <View style={{ width: "44%", margin: "4% 0 0.5% 10%" }}>
              <Text style={styles.sectionTitle}>
                Categoria de Titulares de Dados Atingidos pela Atividade de
                Processamento:
              </Text>
            </View>
            <View>
              <View style={{ flexDirection: "column", margin: "0 0 5% 10%" }}>
                <View style={{ flexDirection: "row", margin: "0 0 0.5% 0" }}>
                  <Text style={{ margin: "0 15% 0.5% 0", fontSize: "11px" }}>
                    Titular
                  </Text>
                </View>
                {process.titulares && process.titulares.map((titular) => (
                  <View
                    style={{
                      flexDirection: "row",
                      margin: "0.5% 0 0.5% 0",
                      color: "#878787",
                    }}
                  >
                    <Text style={{ margin: "0 18% 0.5% 0", fontSize: "11px" }}>
                      {titular}
                    </Text>
                  </View>
                ))}
              </View>
            </View>

            <View style={styles.hr} />

            {/* <View style={{ width: "40%", margin: "8% 0 0.5% 10%" }}>
              <Text style={styles.sectionTitle}>
                Legislação e Sistema de Armazenamento
              </Text>
            </View>
            <View style={{ flexDirection: "row", margin: "2% 0 5% 10%" }}>
              <View
                style={{
                  flexDirection: "column",
                  margin: "0.5% 0 0.5% 0",
                  color: "#222",
                }}
              >
                <Text style={{ margin: "0 18% 0.5% 0", fontSize: "11px" }}>
                  Legislação Complementar Envolvida:
                </Text>
                <Text style={{ margin: "0 16% 0.5% 0", fontSize: "11px" }}>
                  Sistema de Armazenamento:
                </Text>
                <Text style={{ margin: "0 16% 0.5% 0", fontSize: "11px" }}>
                  Compartilhamento Externo:
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "column",
                  margin: "0.5% 0",
                  color: "#777",
                }}
              >
                <Text style={{ margin: "0 18% 0.5% 0", fontSize: "11px" }}>
                  {process.complementaryLegalBase || "-"}
                </Text>
                <Text style={{ margin: "0 16% 0.5% 0", fontSize: "11px" }}>
                  {storageType || "-"}
                </Text>

                <Text style={{ margin: "0 16% 0.5% 0", fontSize: "11px" }}>
                  {isExternal ? "SIM" : "NÃO"}
                </Text>
              </View>
            </View> */}
          </Page>

          <Page>
            {/* <View style={{ width: "40%", margin: "8% 0 0.5% 10%" }}>
              <Text style={styles.sectionTitle}>Transferência para Terceiro Operador</Text>
            </View>
            <View style={styles.hr} />

            <View style={{ margin: "0 0 5% 10%" }}>
              <View style={{ margin: "0.5% 0 0.5% 0", color: "#878787" }}>
                <Text style={{ margin: "2% 18% 0.5% 0", fontSize: "16px", color: "black" }}>Razão Social</Text>
                <Text style={{ margin: "0 16% 0.5% 0", fontSize: "11px" }}>NÃO SE APLICA</Text>
                <Text style={{ margin: "2% 16% 0.5% 0", fontSize: "16px", color: "black" }}>Finalidade de Transferência</Text>
                <Text style={{ margin: "0 16% 0.5% 0", fontSize: "11px" }}>NÃO SE APLICA</Text>
                <Text style={{ margin: "2% 16% 0.5% 0", fontSize: "16px", color: "black" }}>Método de Transferência</Text>
                <Text style={{ margin: "0 16% 0.5% 0", fontSize: "11px" }}>NÃO SE APLICA</Text>
              </View>
            </View>

            <View style={styles.hr} /> */}
            <View style={{ width: "40%", margin: "8% 0 0.5% 10%" }}>
              <Text style={styles.sectionTitle}>Riscos</Text>

              {gaps.length > 0 ? (
                gaps.map(this.renderGap)
              ) : (
                <View style={{ margin: "5% 10% 0" }}>
                  <Text style={{ fontSize: "15px", color: "#222" }}>
                    Nenhum risco registrado
                  </Text>
                </View>
              )}
            </View>
          </Page>
        </Document>
      );
    } catch (e) {
      console.log("erro", e);
    }
  }

  render() {
    return (
      <div>
        <Grid
          component={Paper}
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          rowSpacing={2}
          style={{ width: "100%", height: "7vh", marginTop: 10 }}
        >
          <Grid item xs={2}>
            <Typography variant="h6" component="h6" style={{ textAlign: "center" }}>
              Filtros
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <FormControl size="small" variant="outlined" style={{ width: "70%" }}>
              <InputLabel>Área</InputLabel>
              <Select
                label="Área"
                value={this.state.areaFilter}
                onChange={(event) => { this.handleFilterAreaChange(event) }}
              >
                <MenuItem value={""}>Todos</MenuItem>
                {this.state.areaList && this.state.areaList.map((area) => (
                  <MenuItem value={area}>{area}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <TextField
              size="small"
              label="Processo"
              variant="outlined"
              style={{ width: "70%" }}
              color={theme.secondary_color}
              value={this.state.processFilter}
              onChange={(event) => { this.handleFilterProcessChange(event) }}
            />
          </Grid>
        </Grid>
        {this.state.loading ? (
          <Grid container direction="column" alignItems="center" style={{ width: "100%", height: "100%" }}>
            <Grid item style={{ paddingTop: "10vh" }}>
              <CircularProgress size={70} color={theme.secondary_color} />
            </Grid>
          </Grid>
        ) : (
          <>
            {this.state.dadosDaEmpresa !== null && this.state.processList !== null && this.state.processList.length > 0 ? (
              <Grid container direction="row" justifyContent="flex-start" style={{ width: "100%", marginTop: 10 }}>
                <Grid item xs={4}>
                  <TableContainer component={Paper} style={{ maxHeight: "70vh" }}>
                    <Table stickyHeader>
                      <colgroup>
                        <col style={{ width: "45%" }} />
                        <col style={{ width: "45%" }} />
                        <col style={{ width: "10%" }} />
                      </colgroup>
                      <TableHead>
                        <TableRow>
                          <TableCell>ÁREA</TableCell>
                          <TableCell>PROCESSO</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.processList.filter(this.filterProcessList).map(this.renderCells)}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                {this.state.processList.filter(this.filterProcessList).length > 0 && (
                  <Grid item xs={8}>
                    <PDFViewer width="100%" height="1000px">
                      {this.renderPDF(
                        this.state.processList.filter(this.filterProcessList)[this.state.processViewIndex]
                      )}
                    </PDFViewer>
                  </Grid>
                )}
              </Grid>
            ) : (
              <Typography variant="h5" component="h5" style={{ textAlign: "center", padding: "10px", marginTop: 10 }}>
                Não há processos cadastrados para a empresa
              </Typography>
            )}
          </>
        )}
      </div>
    )
  }
}

export default Reports;
