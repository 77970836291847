/* eslint-disable default-case */
/* eslint-disable import/no-anonymous-default-export */
import { ADD_COMPANY_INFO, ADD_DPO_INFO, ADD_PLANS, ADD_AREAS } from './types';

const INITIAL_STATE = {
  companyNickName: "",
  companyName: "",
  companySize: "",
  companyDocument: "",
  companyStreet: "",
  companyNumber: "",
  companyComplement: "",
  companyCity: "",
  companyNeighborhood: "",
  companyState: "",
  companyCEP: "",
  companyValidDocument: true,

  dpoName: "",
  dpoCnpj: "",
  dpoEmail: "",

  dpoIsCompanyEmployee: true,
  dpoCompanyCnpj: "",
  dpoCompanyName: "",
  dpoPhone: "",
  dpoStreet: "",
  dpoNumber: "",
  dpoComplement: "",
  dpoCity: "",
  dpoNeighborhood: "",
  dpoState: "",
  dpoCEP: "",

  plans: [],

  company: "",

  areas: []
}

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case ADD_COMPANY_INFO:
      return {
        ...state,
        companyNickName: action.data.nickName,
        companyName: action.data.name,
        companySize: action.data.porteEmpresa,
        companyDocument: action.data.document,
        companyValidDocument: action.data.validDocument,
        companyStreet: action.data.street,
        companyNumber: action.data.number,
        companyComplement: action.data.complement,
        companyCity: action.data.city,
        companyNeighborhood: action.data.neighborhood,
        companyState: action.data.state,
        companyCEP: action.data.CEP
      }
    case ADD_DPO_INFO:
      return {
        ...state,
        dpoName: action.data.name,
        dpoCnpj: action.data.dpoCnpj,
        dpoEmail: action.data.dpoEmail,
        dpoPhone: action.data.phone,

        dpoIsCompanyEmployee: action.data.isCompanyEmployee,
        dpoCompanyCnpj: action.data.dpoCompanyCnpj,
        dpoCompanyName: action.data.dpoCompanyName,
        dpoStreet: action.data.dpoStreet,
        dpoNumber: action.data.dpoNumber,
        dpoComplement: action.data.dpoComplement,
        dpoCity: action.data.dpoCity,
        dpoNeighborhood: action.data.dpoNeighborhood,
        dpoState: action.data.dpoState,
        dpoCEP: action.data.dpoCEP
      }
    case ADD_PLANS:
      return {
        ...state,
        plans: action.data,
      }
    case ADD_AREAS:
      return {
        ...state,
        areas: action.data,
      }
  }

  return state;
}