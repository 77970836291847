import { Button, Drawer, Grid, Typography } from '@material-ui/core';
import React, { Component } from 'react';

import theme from '../../../core/theme.json'

class CookiePopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accepted: true,
    }
  }

  componentDidMount(){
    let cookiePolicy = sessionStorage.getItem("cookiePolicy");

    if (window.location.href.includes("/solicitation") || window.location.href.includes("/quiz")) {
      this.setState({
        accepted: true
      });
    } else if (cookiePolicy !== "true") {
      this.setState({
        accepted: false
      });
    } else {
      this.setState({
        accepted: true
      });
    }
  }

  accept() {
    sessionStorage.setItem("cookiePolicy", true);

    this.setState({
      accepted: true,
    })
  }

  render() {
    if (this.state.accepted) {
      return null;
    } else {
      return (
        <Drawer variant="persistent" anchor="bottom" open={!this.state.accepted}>
          <div style={{padding: "16px"}}>
            <Grid container justify="center" alignItems="center" spacing={2}>
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Typography variant="h6" component="h6">
                      Nós usamos cookies
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" component="body1">
                      {"Eles são usados para aprimorar a sua experiência. Ao fechar este banner ou continuar na página, você concorda com o uso de cookies. "}
                    </Typography>
                    <a href="beelegal-krn/#/cookiepolicy" >
                      <Typography variant="body1" component="body1" >
                        Saber mais
                      </Typography>
                    </a>
                  </Grid>
                </Grid>
              </Grid>
              <Grid style={{flexGrow: 1}} />
              <Grid item>
                <Button style={{background: theme.primary_color, color: theme.secondary_color}} onClick={() => {this.accept()}} >
                  Permitir todos os Cookies
                </Button>
              </Grid>
            </Grid>
          </div>
        </Drawer>
      );
    }
  }
}

export default CookiePopup;