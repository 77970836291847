import {
  Container,
  Grid,
  Typography,
  Paper,
  ButtonBase,
} from "@material-ui/core";

import theme from "../../../../../core/theme.json";
import { grey } from "@material-ui/core/colors";
import DenunceTimeLine from "../../../../../shared/components/DenunceTimeline";

export const ZoomForm = ({ denunce }) => {
  return (
    <Grid item style={{ margin: "5% 0" }}>
      <Grid
        container
        direction="column"
        alignItems="stretch"
        justifyContent="center"
      >
        <Grid item>
          <Typography variant="h6" component="h6">
            Histórico
          </Typography>
          <Grid item style={{ padding: "0 10px" }}>
            <Container
              maxWidth="xl"
              style={{
                backgroundColor: grey[100],
                overflow: "scroll",
                maxHeight: "400px",
              }}
            >
              <div >
                <DenunceTimeLine data={denunce} />
              </div>
            </Container>
          </Grid>
        </Grid>

        <Paper variant="outlined" style={{ marginTop: "20px" }}>
          <Grid style={{ marginTop: "20px" }}>
            <Grid container justifyContent="center">
              <Typography variant="h6">Texto da denúncia :</Typography>
            </Grid>

            <Grid>
              <Typography
                variant="h6"
                component="h6"
                style={{ padding: "10px", color: "#808080" }}
              >
                {denunce.additionalInformation}
              </Typography>
            </Grid>
          </Grid>
        </Paper>

        <Grid style={{ margin: "50px 0" }}>
          <Typography variant="h6" component="h6" style={{ margin: "2% 0px " }}>
            Anexos da denúncia :
          </Typography>

          <Grid style={{ margin: "5% 0px " }}>
            {denunce.responsedenunce?.length !== 0 ? (
              denunce.responsedenunce?.map((item) => {
                return (
                  <Paper
                    style={{
                      padding: "8px",
                      margin: "15px 0",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "1rem",
                        color: "#72bcd4",
                      }}
                    >
                      {item.name}
                    </span>

                    <ButtonBase
                      onClick={() => window.open(item.url)}
                      style={{
                        background: theme.primary_color,
                        color: theme.secondary_color,
                        padding: "5px",
                      }}
                    >
                      {" "}
                      vizualizar arquivo
                    </ButtonBase>
                  </Paper>
                );
              })
            ) : (
              <Grid container justifyContent="center">
                <Typography variant="body2">
                  Nenhum arquivo fornecido
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
