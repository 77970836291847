import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Zoom,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  ButtonBase,
  TextField,
} from "@material-ui/core";

import NotLogsImg from '../../../core/assets/notLogsImg.svg'


export const NotLogs = () =>{



    return(

         <Grid 
         style={{height:"50%"}}
         container
         justifyContent="center"
         alignItems="center"
         direction="column"
         >
            <img src={NotLogsImg} width={'30%'} style={{marginBottom:'3%'}} />

             <Typography> Nenhum acesso nessa data , por favor selecione uma data para ver os logs. </Typography>

         </Grid>

    )
}