import { CHANGE_PAGE, PARAMETERIZATION_TYPE, COMPANY_NAME, ID_MODULE_VIDE, ID_QUIZ_MODULE } from "./types";

const INITIAL_STATE = {
  page: 0,
  parameterizationType: false,
  companyName: "",
  idModuleName: "",
  idQuizModule: "",
}

const parameterizationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_PAGE:
      return {
        ...state,
        page: action.data,
      }
    case PARAMETERIZATION_TYPE:
      return {
        ...state,
        parameterizationType: action.data,
      }
    case COMPANY_NAME:
      return {
        ...state,
        companyName: action.data,
      }
    case ID_MODULE_VIDE:
      return {
        ...state,
        idModuleName: action.data,
      }
    case ID_QUIZ_MODULE:
      return {
        ...state,
        idQuizModule: action.data,
      }
    default:
      return state;
  }
}

export default parameterizationReducer;