import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import GroupIcon from '@material-ui/icons/Group';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import SettingsIcon from '@material-ui/icons/Settings';
import GavelIcon from '@material-ui/icons/Gavel';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import AutorenewIcon from '@material-ui/icons/Autorenew';

let areaIconList = [
  {
    value: "business",
    name: "Administrativo",
    icon: BusinessCenterIcon
  },
  {
    value: "finances",
    name: "Financeiro",
    icon: AccountBalanceWalletIcon
  },
  {
    value: "legal",
    name: "Jurídico",
    icon: GavelIcon
  },
  {
    value: "operation",
    name: "Operações",
    icon: SettingsIcon
  },
  {
    value: "other",
    name: "Outros",
    icon: AutorenewIcon
  },
  {
    value: "marketing",
    name: "Marketing",
    icon: WbIncandescentIcon
  },
  {
    value: "rh",
    name: "RH",
    icon: GroupIcon
  }
];

export default areaIconList;