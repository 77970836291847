export function formatDate(date){
  var day  = date.getDate().toString();
  var dayF = (day.length === 1) ? '0' + day : day;
  
  var month  = (date.getMonth() + 1).toString();
  var monthF = (month.length === 1) ? '0' + month : month;
  
  var yearF = date.getFullYear();
  
  return dayF + "/" + monthF + "/" + yearF;
}

export function formatHour(date){
  var d = date;
  var h = addZero(d.getHours());
  var m = addZero(d.getMinutes());
  var s = addZero(d.getSeconds());
  
  return h + ":" + m + ":" + s;
}

function addZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}