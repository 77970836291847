import React, { Component } from "react"
import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import { getQuizById } from "../../quiz/functions/getQuiz";
import {
  Grid,
  Paper,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Divider,
  Tooltip,
  CircularProgress
} from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import dashboardLogo from "../../../../res/images/dashboard_logo.png";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";

import { isDefined } from "../../../../shared/commands/verifyDefinition";

import { saveRopaAnswers } from "../functions/saveRopaAnswers";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#2e2e2e",
    color: "white",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

class AnswerPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questions: [],
      quiz: {},
      typeQuiz: '',
      email: "",
      validEmail: true,
      validatedEmailEmail: false,
      loggedUser: localStorage.getItem("@blue-legal/quizLoggedUser"),
      processId: localStorage.getItem("@blue-legal/processId"),
      processStage: localStorage.getItem("@blue-legal/processStage"),
      quizRegisteredAnswers: {},
      loading: true,
      answerButtonDisabled: false,
      loginLogo: 'https://firebasestorage.googleapis.com/v0/b/bluelegal-lgpd-c7c1f.appspot.com/o/files%2FnewLogo.png?alt=media&token=72666d47-155a-4d7f-9e2a-4fa79e548e8a',
    };
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    let idQuiz = '';

    if (params.quizId.includes('quiz2.0')) {
      idQuiz = params.quizId.split(' ');
      this.setState({
        typeQuiz: '2'
      });
      window.location.href = window.location.origin + '/#/newQuiz/' + idQuiz[1] + '/' + params.processId;
    } else {
      idQuiz = params.quizId;
      this.setState({
        typeQuiz: '1'
      });
    }
    this.getQuizInDatabase(idQuiz);
  }

  async getQuestion(id) {
    let database = myApp.firestore();

    try {
      let data = await database.collection("questions").doc(id).get();
      let question = data.data();

      var questionObject = {
        description: question.text,
        observation: "",
        answers: question.answers,
        answer: -1,
        gap: {},
        id: id,
        typeQuestion: isDefined(question.typeQuestion) ? question.typeQuestion : "",
      };

      return questionObject;
    }
    catch (error) {
      console.error(error);
    }
  }

  async getGap(id, questionType) {
    let database = myApp.firestore();

    if (id !== "") {
      try {
        let data = await database.collection("gaps").doc(id).get();
        let gap = data.data();

        var gapObject = {
          active: true,
          gapId: id,
          actionPlan: gap.actionPlan,
          date: null,
          done: false,
          name: gap.name,
          owner: questionType,
          ownerEmail: "",
          risk: 1,
          riskImpact: 1,
          riskChance: 1,
          riskGap: 1,
          riskGapImpact: 1,
          riskGapChance: 1,
          epmId: gap.epmId ? gap.epmId : 0,
        };

        return gapObject;
      }
      catch (error) {
        console.error(error);
      }
    }
  }

  async getQuizInDatabase(quizId) {
    var quiz = await getQuizById(quizId);
    var points = Object.values(quiz.points);
    var questions = [];
    var firstQuestion = await this.getQuestion(points[1].cardProps.id);
    questions.push(firstQuestion);

    this.setState({ questions, points, quiz });

    await this.getRegisteredAnswers(this.state.processId, this.state.processStage, quizId);
  }

  async getRegisteredAnswers(processId, processStage, quizId) {
    var database = myApp.firestore();
    var qraRef = database.collection("quiz_registered_answers");
    var qra = await qraRef
      .where("process_id", "==", processId)
      .where("process_stage", "==", this.state.processStage)
      .where("quiz_id", "==", quizId).get();

    var qraResult = {};
    var questions = this.state.questions;

    if (!qra.empty) {
      qraResult = qra.docs[0].data();
      questions = [];

      for (var ra of qraResult.registered_answers) {
        var question = await this.getQuestion(ra.question_id);
        question.answer = ra.answer;
        question.gap = ra.gap;
        question.observation = ra.observation;
        questions.push(question);
      }
    }

    this.setState({
      quizRegisteredAnswers: qraResult,
      questions,
      loading: false,
      //answerButtonDisabled: questions.some(question => question.answer === -1)
    });
  }

  async handleQuestionAnswer(questionIndex, answerIndex) {
    var questions = this.state.questions;
    var answer = this.state.questions[questionIndex].answers[answerIndex];
    questions[questionIndex].answer = answerIndex;

    if (answer.leadTo !== "") {
      var newQuestion = await this.getQuestion(answer.leadTo);

      if ((questions.length - 1) === questionIndex) {
        questions.push(newQuestion);
      } else {
        questions.splice((questionIndex + 1), (questions.length - questionIndex - 1));
        questions.push(newQuestion);
      }
    } else {
      questions.splice((questionIndex + 1), (questions.length - questionIndex - 1));
    }

    var newGap = await this.getGap(answer.fk_gaps, questions[questionIndex].typeQuestion);
    questions[questionIndex].gap = {};

    if (newGap) {
      if ((questions.length - 2) === questionIndex) {
        questions[questionIndex].gap = newGap;
      } else {
        questions.splice((questionIndex - 1), (questions.length - questionIndex - 1));
        questions[questionIndex].gap = newGap;
      }
    }

    this.setState({
      questions,
      //answerButtonDisabled: questions.some(question => question.answer === -1)
    });
  }

  async handleQuestionObservation(event, questionIndex) {
    var questions = this.state.questions;
    questions[questionIndex].observation = event.target.value;
    this.setState({ questions });
  }

  handleChangeEmail(value) {
    var email = this.state.email;
    email = value;
    var validEmail = value.includes(".") && value.includes("@") && value;
    this.setState({ email, validEmail });
  }

  validateEmail() {
    this.setState({ validatedEmail: true });
  }

  async validateQuiz() {
    this.setState({ loading: true });
    let database = myApp.firestore();
    var batch = database.batch();
    var usersRef = database.collection("users");
    var user = await usersRef.where("uid", "==", localStorage.getItem("currentUser")).get();
    const { match: { params } } = this.props;

    if (!user.empty) {
      user = user.docs[0].data();
    } else {
      user = { id: null, email: this.state.email };
    }

    let epmUser = {};

    try {
      let obj = {
        username: "admin",
        password: "Blue@2020"
      }

      let response = await fetch('https://apps.blueprojects.com.br/beeprivacy/Security/Login', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(obj)
      });

      let respJson = await response.json();

      if (respJson.data == null) {
        console.log("Erro ao logar EPM");
      } else {
        epmUser = respJson.data;
      }
    } catch (error) {
      console.log(error);
    }

    try {
      var questions = this.state.questions;
      var registered_answers = [];

      for (let question of questions) {
        if (!question.gap) {
          question.gap = {};
        }

        var answer = {
          observation: question.observation,
          answer: question.answer,
          gap: question.gap,
          question_id: question.id
        };

        if (question.gap.hasOwnProperty('epmId')) {
          let gapObject = {
            tid: "VF9CRUVfR0FQUzowNzc3MzU=",
            fid: 170,
            key: {
              id: question.gap.epmId
            },
            data: {
              ID_PROCESSO: this.state.processId,
              NOME_PROCESSO: "",
              ID_ETAPA: this.state.processStage,
              NOME_ETAPA: "",
              ID_GAP: answer.gap.gapId,
              GAP: answer.gap.name,
              PLANO_ACAO: answer.gap.actionPlan
            }
          }

          try {
            let response = await fetch('https://apps.blueprojects.com.br/beeprivacy/Integration/Save', {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'auth': epmUser.auth_ticket
              },
              body: JSON.stringify(gapObject)
            });

            let respJson = await response.json();

            answer.gap.epmId = respJson.data.result.id;
          } catch (error) {
            console.log(error);
          }
        }

        registered_answers.push(answer);
      }

      if (this.state.quiz.name.toUpperCase() === "QUESTIONÁRIO PADRÃO") {
        await saveRopaAnswers(questions, this.state.processId);
      }

      const { match: { params } } = this.props;

      var doc = {
        quiz_id: params.quizId,
        process_id: this.state.processId,
        process_stage: this.state.processStage,
        user_id: user.uid,
        user_email: user.email,
        registered_answers
      };

      let quizRegisteredAnswers = await database
        .collection("quiz_registered_answers")
        .where("process_id", "==", this.state.processId)
        .where("process_stage", "==", this.state.processStage)
        .where("quiz_id", "==", params.quizId)
        .get();

      if (quizRegisteredAnswers.docs.length > 0) {
        var docRef = database.collection("quiz_registered_answers").doc(quizRegisteredAnswers.docs[0].id);
        batch.update(docRef, doc);
        batch.commit();
      } else {
        var docRef = database.collection("quiz_registered_answers").doc();
        batch.set(docRef, doc);
        batch.commit();
      }
    }
    catch (error) {
      console.error(error);
    }

    let testeTeste = await database
      .collection("quiz_registered_answers")
      .where("quiz_id", "==", params.quizId)
      .where("process_id", "==", this.state.processId)
      .get();

    for (let doc of testeTeste.docs) {
      let date = new Date();
      await database.collection("quiz_registered_answers").doc(doc.id).update({
        replyDate: date.getTime()
      });
    }

    this.setState({ validatedQuiz: true, loading: false });
    window.close();
  }

  exitQuiz() {
    window.close();
  }

  render() {
    return (
      <div style={{ width: "100%", height: "100vh", margin: "auto", textAlign: "center", background: "#FAFAFA" }}>
        <Grid container direction="row" alignItems="center" justify="center" style={{ width: "100%", paddingTop: "15px", paddingBottom: "15px" }}>
          <Grid item>
            <img alt="logo" src={this.state.loginLogo} style={{ height: "42px" }} />
          </Grid>
        </Grid>
        <div style={{ display: !this.state.validatedEmail && !this.state.loggedUser ? "inherit" : "none" }}>
          <Paper elevation={10} style={{ borderRadius: "5px", width: "50%", margin: "auto", }}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Typography variant="h6" container="h6" style={{ fontFamily: "Roboto" }}>Para responder o questionário é necessário informar seu e-mail</Typography>
              <TextField
                value={this.state.email}
                onChange={e => this.handleChangeEmail(e.target.value)}
                variant="outlined"
                label="E-Mail"
                error={!this.state.validEmail}
                helperText={!this.state.validEmail ? "E-mail inválido." : ""}
                style={{ margin: "30px 0 0 0", width: "30%" }}
              />
            </Grid>
            <Button
              variant="contained"
              disabled={!this.state.validEmail || this.state.email === ""}
              style={{ background: !this.state.validEmail || this.state.email === "" ? "#D5D5D5" : "#193993", color: "white", width: "20%", margin: "20px 0 10px 0" }}
              onClick={() => { this.validateEmail() }}
            >
              Continuar
            </Button>
          </Paper>
        </div>

        {this.state.loading
          ?
          <Grid container direction="row" alignItems="center" justify="center" style={{ width: "100%", height: "70%" }}>
            <Grid item>
              <CircularProgress size={60} color="#193993" />
            </Grid>
          </Grid>
          :
          <>
            <div style={{ display: this.state.validatedEmail && !this.state.validatedQuiz || (this.state.loggedUser && !this.state.validatedQuiz) ? "inherit" : "none" }}>
              <Paper elevation={10} style={{ borderRadius: "5px", width: "90%", margin: "auto" }}>
                <Grid container direction="column" justify="center" alignItems="center" style={{ width: "100%", height: "100%" }}>
                  <Typography variant="h4" component="h4" style={{ textAlign: "center", fontFamily: "Roboto", paddingTop: "2%" }} >
                    {this.state.quiz.name}
                  </Typography>
                  {this.state.questions.map((question, questionIndex) => (
                    <Grid item style={{ width: "95%" }}>
                      <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                      >
                        <Grid item style={{ height: "16px" }} />
                        <Grid
                          item
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          style={{ width: "100%", textAlign: "start" }}
                        >
                          <Grid item>
                            <Typography
                              variant="h6"
                              component="h6"
                              style={{ opacity: questionIndex > 0 && this.state.questions[questionIndex - 1].answer < 0 ? 0.4 : 1.0 }}
                            >
                              {question.description}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="body1" component="body1">
                              {question.typeQuestion}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item style={{ height: "16px" }} />
                        <Grid item style={{ width: "100%" }}>
                          <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            spacing={1}
                          >
                            <Grid item>
                              <FormControl variant="outlined" style={{ width: "200px" }}>
                                <InputLabel>Resposta</InputLabel>
                                <Select
                                  value={question.answer}
                                  onChange={(event) => { this.handleQuestionAnswer(questionIndex, event.target.value) }}
                                  label="Resposta"
                                  style={{ textAlign: "start" }}
                                  disabled={questionIndex > 0 && this.state.questions[questionIndex - 1].answer < 0}
                                >
                                  {question.answers.map((answer, answerIndex) => (
                                    <MenuItem value={answerIndex}>{answer.text}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid style={{ flexGrow: 1 }} />
                            <Grid item>
                              {question.gap && question.gap.hasOwnProperty("name") && question.gap.active ?
                                <HtmlTooltip
                                  placement="left"
                                  title={
                                    <React.Fragment>
                                      <Typography color="inherit"><b>Gap:</b> {question.gap.name}</Typography>
                                      <Typography color="inherit"><b>Plano de ação:</b> {question.gap.actionPlan}</Typography>
                                    </React.Fragment>
                                  }
                                >
                                  <span>
                                    <Button
                                      startIcon={<ErrorOutlineIcon />}
                                      style={{ color: "#EA1C1C" }}
                                      disabled
                                    >
                                      Gap
                                    </Button>
                                  </span>
                                </HtmlTooltip>
                                :
                                <div />
                              }
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item style={{ height: "16px" }} />
                        <Grid item style={{ width: "100%" }} >
                          <TextField
                            label="Observações"
                            multiline
                            rows={2}
                            variant="outlined"
                            style={{ width: "100%" }}
                            value={question.observation}
                            onChange={(event) => { this.handleQuestionObservation(event, questionIndex) }}
                            disabled={questionIndex > 0 && this.state.questions[questionIndex - 1].answer < 0}
                          />
                        </Grid>
                        <Grid item style={{ height: "16px" }} />
                        <Grid item style={{ width: "100%" }}>
                          <Divider style={{ display: this.state.questions.length === 1 ? "none" : "inherit", width: "100%" }} />
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                  <Grid item style={{ width: "95%" }}>
                    <Button
                      variant="contained"
                      //disabled={this.state.answerButtonDisabled}
                      style={{
                        background: this.state.answerButtonDisabled ? "#D5D5D5" : "#193993",
                        color: "white",
                        width: "20%",
                        margin: "20px 0 20px 0"
                      }}
                      onClick={() => { this.validateQuiz() }}
                    >
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
              <br></br>
              <Grid container direction="row" alignItems="center" justify="flex-start" style={{ width: "90%", margin: "auto" }}>
                <Grid item>
                  <Button
                    variant="contained"
                    style={{
                      background: "#193993",
                      color: "white",
                      width: "100px"
                    }}
                    onClick={() => { this.exitQuiz() }}
                  >
                    Voltar
                  </Button>
                </Grid>
              </Grid>
            </div>

            <div style={{ display: this.state.validatedQuiz ? "inherit" : "none" }}>
              <div style={{ width: "50%", margin: "auto", height: "200px" }}>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="h6" container="h6" style={{ fontFamily: "Roboto", padding: "15px" }}>
                      Suas respostas foram submetidas com sucesso!
                    </Typography>
                  </Grid>
                  <Grid item container direction="row" justify="center" alignItems="center">
                    <Grid item style={{ padding: "15px" }}>
                      <Button
                        variant="contained"
                        style={{
                          background: "#193993",
                          color: "white",
                          width: "150px"
                        }}
                        onClick={() => { this.exitQuiz() }}
                      >
                        Voltar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </>
        }
      </div>
    )
  }
}

export default withRouter(AnswerPage)