import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Typography,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import theme from "../../../../core/theme.json";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Title from "../../../../shared/components/title";
import myApp from '../../../../core/infrastructure/firebaseConfig';
import "firebase/compat/functions";
import ArrowBackIosTwoToneIcon from "@material-ui/icons/ArrowBackIosTwoTone";
import { useDispatch } from "react-redux";
import { changePage, parameterizationType, companyName } from '../redux/actions';
import InputMask from 'react-input-mask';
import Autocomplete from '@material-ui/lab/Autocomplete';
import id from 'date-fns/esm/locale/id/index.js';

const SupplierRegistration = () => {
  let TableCellSpace = 250;
  const dispatch = useDispatch();
  const [fornecedor, setFornecedor] = useState({
    nome: '',
    cnpj: '',
    pais: '',
    cep: '',
    rua: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    estado: '',
    site: '',
    responsavel: '',
    tell: '',
    email: '',
    in_company: localStorage.getItem('currentCompany'),
  });
  const [editFornecedor, setEditFornecedor] = useState({
    nome: '',
    cnpj: '',
    pais: '',
    cep: '',
    rua: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    estado: '',
    site: '',
    responsavel: '',
    tell: '',
    email: '',
    in_company: localStorage.getItem('currentCompany'),
  });
  const [validData, setValidData] = useState({
    Nome: false,
    cnpj: false,
    pais: false,
    responsavel: false,
    tell: false,
    email: false,
  });
  const [dialogs, setDialogs] = useState({
    sendSupplier: false,
    editSupplier: false
  });
  const [countrys, setCountrys] = useState([]);
  const [sending, setSending] = useState(false);
  const [listOfSuppliers, setListOfSuppliers] = useState([]);

  useEffect(() => {
    getCountrys();
    getSuppliers();
  }, []);

  const handleCloseDialog = (item) => (value) => {
    setDialogs({
      ...dialogs,
      [item]: false
    });
  }

  const TextMaskCustomCEP = (props) => {
    const { inputRef, ...other } = props;

    return (
      <InputMask
        {...other}
        ref={inputRef}
        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
        showMask
      />
    );
  }

  const TextMaskCustomCNPJ = (props) => {
    const { inputRef, ...other } = props;

    return (
      <InputMask
        {...other}
        ref={inputRef}
        // 30.857.975/0001-00
        mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
        showMask
      />
    );
  }

  const TextMaskCustomCellPhone = (props) => {
    const { inputRef, ...other } = props;

    return (
      <InputMask
        {...other}
        ref={inputRef}
        mask={['(', /\d/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        showMask
      />
    );
  }

  const handleSetProvider = (item) => (event) => {
    setFornecedor({
      ...fornecedor,
      [item]: event.target.value
    });
  }

  const handleEditProvider = (item) => (event) => {
    setEditFornecedor({
      ...editFornecedor,
      [item]: event.target.value
    });
  }

  const handleGetCep = async (item) => {
    if (item === 'cad') {
      try {
        let get = await fetch(`https://viacep.com.br/ws/${fornecedor.cep}/json/`);
        let resp = await get.json();
        setFornecedor({
          ...fornecedor,
          rua: resp.logradouro,
          bairro: resp.bairro,
          cidade: resp.localidade,
          estado: resp.uf
        });
        console.log(fornecedor)
      } catch (errorGetCep) {
        console.log('Erro ao buscar dados pelo CEP', errorGetCep);
      }
    } else if (item === 'edit') {
      try {
        let get = await fetch(`https://viacep.com.br/ws/${editFornecedor.cep}/json/`);
        let resp = await get.json();
        setEditFornecedor({
          ...editFornecedor,
          rua: resp.logradouro,
          bairro: resp.bairro,
          cidade: resp.localidade,
          estado: resp.uf
        });
        console.log(fornecedor)
      } catch (errorGetCep) {
        console.log('Erro ao buscar dados pelo CEP', errorGetCep);
      }
    }
  }

  const handleValidationItem = (item) => {
    switch (item) {
      case 'nome':
        setValidData({
          ...validData,
          nome: fornecedor.nome.length < 4 ? true : false
        });
        break
      case 'cnpj':
        setValidData({
          ...validData,
          cnpj: fornecedor.cnpj.length < 14 ? true : false
        });
        break
      case 'pais':
        setValidData({
          ...validData,
          pais: fornecedor.pais.length < 4 ? true : false
        });
        break
      case 'responsavel':
        setValidData({
          ...validData,
          responsavel: fornecedor.responsavel.length < 4 ? true : false
        });
        break
      case 'tell':
        setValidData({
          ...validData,
          tell: fornecedor.tell.length < 10 ? true : false
        });
        break
      case 'email':
        setValidData({
          ...validData,
          cnpj: fornecedor.email.includes('@') ? true : false
        });
        break
    }
  }

  const handleValideData = async () => {
    setDialogs({
      ...dialogs,
      sendSupplier: true
    });
    setSending(true);
    try {
      await myApp.firestore().collection('supplier').doc().set(fornecedor);
      setFornecedor({
        nome: '',
        cnpj: '',
        pais: '',
        cep: '',
        rua: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        estado: '',
        site: '',
        responsavel: '',
        tell: '',
        email: '',
        in_company: localStorage.getItem('currentCompany'),
      });
      getSuppliers();
    } catch (errorSaveSupplier) {
      console.error(errorSaveSupplier);
    }
    await delay(1);
    setSending(false);
  }

  const delay = (n) => new Promise(r => setTimeout(r, n * 1000));

  //opções do campo de pais
  const options = countrys.map((option) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  const getCountrys = async () => {
    let countrys = [];
    let querySnapshot = await myApp.firestore().collection("countrys").get();
    for (let doc of querySnapshot.docs) {
      countrys.push({ title: doc.data().title });
    }
    setCountrys(countrys)
  }

  const getSuppliers = async () => {
    let aux = [];
    try {
      let querySnapshot = await myApp.firestore().collection("supplier").where("in_company", "==", localStorage.getItem('currentCompany')).get();
      for (let doc of querySnapshot.docs) {
        aux.push({
          id: doc.id,
          bairro: doc.data().bairro,
          cep: doc.data().cep,
          cidade: doc.data().cidade,
          cnpj: doc.data().cnpj,
          complemento: doc.data().complemento,
          email: doc.data().email,
          in_company: doc.data().in_company,
          nome: doc.data().nome,
          numero: doc.data().numero,
          responsavel: doc.data().responsavel,
          rua: doc.data().rua,
          pais: doc.data().pais,
          site: doc.data().site,
          tell: doc.data().tell,
        });
      }
    } catch (errorGetSuppliers) {
      console.error(errorGetSuppliers)
    }
    setListOfSuppliers(aux);
  }

  // função responsável por preencher o pais
  const handleSetCountry = (c, item) => {
    switch (item) {
      case 'cad':
        if (c) {
          setFornecedor({ ...fornecedor, pais: c.title });
        }
        break
      case 'edit':
        if (c) {
          setEditFornecedor({ ...editFornecedor, pais: c.title });
        }
    }

    console.log(item)
  }

  // editando fornecedor
  const handleEditSupplierDialog = (item) => {
    console.log(item);
    setDialogs({
      ...dialogs,
      editSupplier: true
    });
    setEditFornecedor({
      id: item.id,
      nome: item.nome,
      cnpj: item.cnpj,
      pais: item.pais,
      cep: item.cep,
      rua: item.rua,
      numero: item.numero,
      complemento: item.complemento,
      bairro: item.bairro,
      cidade: item.cidade,
      estado: item.estado,
      site: item.site,
      responsavel: item.responsavel,
      tell: item.tell,
      email: item.email,
      in_company: localStorage.getItem('currentCompany'),
    });
  }

  // deletando fornecedor
  const handleDeleteSupplier = async (item) => {
    try {
      await myApp.firestore().collection("supplier").doc(item.id).delete();
      getSuppliers();
      console.log('Fornecedor excluido')
    } catch (errorDeleteSupplier) {
      console.error(errorDeleteSupplier)
    }
  }

  const handleUpdateSupplier = async () => {
    console.log(editFornecedor);
    try {
      await myApp.firestore().collection("supplier").doc(editFornecedor.id).update(editFornecedor);
    } catch (errorUpdateSupplier) {
      console.error(errorUpdateSupplier)
    }
    setEditFornecedor({
      nome: '',
      cnpj: '',
      pais: '',
      cep: '',
      rua: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      estado: '',
      site: '',
      responsavel: '',
      tell: '',
      email: '',
      in_company: localStorage.getItem('currentCompany'),
    });
    getSuppliers();
    setDialogs({
      ...dialogs,
      editSupplier: false
    });
  }

  return (
    <Grid
      container
      direction='column'
    >
      <Title>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Button
              variant="text"
              style={{
                fontSize: '15px',
              }}
              onClick={() => {
                dispatch(changePage(25));
              }}
            >
              <ArrowBackIosTwoToneIcon />
            </Button>
          </Grid>
          <Grid item>Cadastro de fornecedores</Grid>
        </Grid>
      </Title>

      <Grid
        container
        item
        direction="row"
        style={{
          padding: 10
        }}
      >
        <Accordion
          style={{
            width: '100%',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography >Cadastro de Fornecedores</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  type="text"
                  fullWidth
                  label="Nome"
                  variant="outlined"
                  value={fornecedor.nome}
                  onChange={handleSetProvider('nome')}
                  required
                  onBlur={() => handleValidationItem('nome')}
                  error={validData.nome}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  label="CNPJ"
                  variant="outlined"
                  fullWidth
                  value={fornecedor.cnpj}
                  onChange={handleSetProvider('cnpj')}
                  required
                  onBlur={() => handleValidationItem('cnpj')}
                  error={validData.cnpj}
                // InputProps={{
                //   inputComponent: TextMaskCustomCNPJ
                // }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <Autocomplete
                  options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.title}
                  fullWidth
                  onChange={(e, a) => handleSetCountry(a, 'cad')}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      label="País"
                      variant="outlined"
                      required
                      onBlur={() => handleValidationItem('pais')}
                      error={validData.pais}
                    />
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                hidden={fornecedor.pais.toLocaleLowerCase() !== 'brasil' ? true : false}
              >
                <TextField
                  label="CEP"
                  variant="outlined"
                  fullWidth
                  value={fornecedor.cep}
                  onChange={handleSetProvider('cep')}
                  // InputProps={{
                  //   inputComponent: TextMaskCustomCEP
                  // }}
                  onBlur={() => handleGetCep('cad')}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                hidden={fornecedor.pais.toLocaleLowerCase() !== 'brasil' ? true : false}
              >
                <TextField
                  label="Rua"
                  variant="outlined"
                  fullWidth
                  value={fornecedor.rua}
                  onChange={handleSetProvider('rua')}
                  disabled
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                hidden={fornecedor.pais.toLocaleLowerCase() !== 'brasil' ? true : false}
              >
                <TextField
                  label="Número"
                  variant="outlined"
                  fullWidth
                  value={fornecedor.numero}
                  onChange={handleSetProvider('numero')}
                // InputProps={{
                //   inputComponent: TextMaskCustomCEP
                // }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                hidden={fornecedor.pais.toLocaleLowerCase() !== 'brasil' ? true : false}
              >
                <TextField
                  label="Complemento"
                  variant="outlined"
                  fullWidth
                  value={fornecedor.complemento}
                  onChange={handleSetProvider('complemento')}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                hidden={fornecedor.pais.toLocaleLowerCase() !== 'brasil' ? true : false}
              >
                <TextField
                  label="Bairro"
                  variant="outlined"
                  fullWidth
                  value={fornecedor.bairro}
                  onChange={handleSetProvider('bairro')}
                  disabled
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                hidden={fornecedor.pais.toLocaleLowerCase() !== 'brasil' ? true : false}
              >
                <TextField
                  label="Cidade"
                  variant="outlined"
                  fullWidth
                  value={fornecedor.cidade}
                  onChange={handleSetProvider('cidade')}
                  disabled
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                hidden={fornecedor.pais.toLocaleLowerCase() !== 'brasil' ? true : false}
              >
                <TextField
                  label="Estado"
                  variant="outlined"
                  fullWidth
                  value={fornecedor.estado}
                  onChange={handleSetProvider('estado')}
                  disabled
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  type="text"
                  fullWidth
                  label="Site"
                  variant="outlined"
                  value={fornecedor.site}
                  onChange={handleSetProvider('site')}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  type="text"
                  fullWidth
                  label="Responsável"
                  variant="outlined"
                  value={fornecedor.responsavel}
                  onChange={handleSetProvider('responsavel')}
                  required
                  onBlur={() => handleValidationItem('responsavel')}
                  error={validData.responsavel}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  label="Telefone Responsável"
                  variant="outlined"
                  fullWidth
                  value={fornecedor.tell}
                  onChange={handleSetProvider('tell')}
                  required
                  onBlur={() => handleValidationItem('tell')}
                  error={validData.tell}
                // InputProps={{
                //   inputComponent: TextMaskCustomCellPhone
                // }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  type="email"
                  fullWidth
                  label="Email Responsável"
                  variant="outlined"
                  value={fornecedor.email}
                  onChange={handleSetProvider('email')}
                  required
                  onBlur={() => handleValidationItem('email')}
                  error={validData.email}
                />
              </Grid>
              <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='center'
              >
                <Grid
                  item
                  style={{
                    width: '30%'
                  }}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    style={{
                      backgroundColor: theme.primary_color,
                      color: theme.secondary_color,
                      fontWeight: 'bold',
                    }}
                    onClick={handleValideData}
                  >
                    Cadastrar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid
        container
        item
        direction="row"
        style={{
          padding: 10
        }}
      >
        <Accordion
          style={{
            width: '100%',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography >Lista de Fornecedores</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              item
              style={{
                width: "100%",
                height: "100%"
              }}
            >
              {
                listOfSuppliers.length > 0 ?
                  <TableContainer
                    style={{
                      maxHeight: '80vh'
                    }}
                  >
                    <Table
                      stickyHeader
                      size="medium"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: 'Bold',
                              minWidth: TableCellSpace,
                            }}
                            onClick={
                              () => console.log('')
                            }
                          >
                            NOME
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: 'Bold',
                              minWidth: TableCellSpace,
                            }}
                            onClick={
                              () => console.log('')
                            }
                          >
                            CNPJ
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: 'Bold',
                              minWidth: TableCellSpace,
                            }}
                            onClick={
                              () => console.log('')
                            }
                          >
                            SITE
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: 'Bold',
                              minWidth: TableCellSpace,
                            }}
                            onClick={
                              () => console.log('')
                            }
                          >
                            RESPONSÁVEL
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: 'Bold',
                              minWidth: TableCellSpace,
                            }}
                            onClick={
                              () => console.log('')
                            }
                          >
                            TELEFONE RESPONSÁVEL
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: 'Bold',
                              minWidth: TableCellSpace,
                            }}
                            onClick={
                              () => console.log('')
                            }
                          >
                            EMAIL RESPONSÁVEL
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: 'Bold',
                              minWidth: TableCellSpace,
                            }}
                            onClick={
                              () => console.log('')
                            }
                          >
                            RUA
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: 'Bold',
                              minWidth: TableCellSpace,
                            }}
                            onClick={
                              () => console.log('')
                            }
                          >
                            NUMERO
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: 'Bold',
                              minWidth: TableCellSpace,
                            }}
                            onClick={
                              () => console.log('')
                            }
                          >
                            COMPLEMENTO
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: 'Bold',
                              minWidth: TableCellSpace,
                            }}
                            onClick={
                              () => console.log('')
                            }
                          >
                            BAIRRO
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: 'Bold',
                              minWidth: TableCellSpace,
                            }}
                            onClick={
                              () => console.log('')
                            }
                          >
                            CIDADE
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: 'Bold',
                              minWidth: TableCellSpace,
                            }}
                            onClick={
                              () => console.log('')
                            }
                          >
                            AÇÕES
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          listOfSuppliers.map((item, index) => (
                            <TableRow
                              key={index}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 }, marginTop: 50 }}
                              style={index % 2 ? { background: "white" } : { background: "#e3e3e3" }}
                            >
                              <TableCell
                                align="center"
                                style={{
                                  fontWeight: 'Bold',
                                  minWidth: TableCellSpace,
                                }}
                                onClick={
                                  () => console.log('')
                                }
                              >
                                {item.nome}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontWeight: 'Bold',
                                  minWidth: TableCellSpace,
                                }}
                                onClick={
                                  () => console.log('')
                                }
                              >
                                {item.cnpj}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontWeight: 'Bold',
                                  minWidth: TableCellSpace,
                                }}
                                onClick={
                                  () => console.log('')
                                }
                              >
                                {item.site}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontWeight: 'Bold',
                                  minWidth: TableCellSpace,
                                }}
                                onClick={
                                  () => console.log('')
                                }
                              >
                                {item.responsavel}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontWeight: 'Bold',
                                  minWidth: TableCellSpace,
                                }}
                                onClick={
                                  () => console.log('')
                                }
                              >
                                {item.tell}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontWeight: 'Bold',
                                  minWidth: TableCellSpace,
                                }}
                                onClick={
                                  () => console.log('')
                                }
                              >
                                {item.email}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontWeight: 'Bold',
                                  minWidth: TableCellSpace,
                                }}
                                onClick={
                                  () => console.log('')
                                }
                              >
                                {item.rua}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontWeight: 'Bold',
                                  minWidth: TableCellSpace,
                                }}
                                onClick={
                                  () => console.log('')
                                }
                              >
                                {item.numero}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontWeight: 'Bold',
                                  minWidth: TableCellSpace,
                                }}
                                onClick={
                                  () => console.log('')
                                }
                              >
                                {item.complemento}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontWeight: 'Bold',
                                  minWidth: TableCellSpace,
                                }}
                                onClick={
                                  () => console.log('')
                                }
                              >
                                {item.bairro}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontWeight: 'Bold',
                                  minWidth: TableCellSpace,
                                }}
                                onClick={
                                  () => console.log('')
                                }
                              >
                                {item.cidade}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontWeight: 'Bold'
                                }}
                              >
                                <Button
                                  variant="contained"
                                  style={{
                                    color: '#fff',
                                    backgroundColor: theme.secondary_color,
                                    margin: '10px'
                                  }}
                                  onClick={() => handleEditSupplierDialog(item)}
                                >
                                  EDITAR
                                </Button>
                                -
                                <Button
                                  variant="contained"
                                  style={{
                                    color: '#fff',
                                    backgroundColor: '#F00',
                                    margin: '10px'
                                  }}
                                  onClick={() => handleDeleteSupplier(item)}
                                >
                                  EXCLUIR
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                  :
                  <h1>Sem dados cadastados</h1>
              }
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Dialog
        fullWidth
        open={dialogs.sendSupplier}
        onClose={handleCloseDialog('sendSupplier')}
      >
        <DialogTitle
        >
          {
            sending ? 'Enviando...' : 'Sucesso'
          }
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              textAlign="center"
            >
              {
                sending ?
                  <CircularProgress size={50} color={'rgb(56, 242, 5)'} /> :
                  <Typography
                    variant="h5"
                  >
                    Fornecedor cadastrado com sucesso!
                  </Typography>
              }
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              backgroundColor: 'rgb(56, 242, 5)'
            }}
            onClick={handleCloseDialog('sendSupplier')}
            autoFocus
          >
            Ok!
          </Button>
        </DialogActions>
      </Dialog>

      {/* EDITANDO FORNECEDOR */}
      <Dialog
        fullWidth
        open={dialogs.editSupplier}
        onClose={handleCloseDialog('editSupplier')}
      >
        <DialogTitle
        >
          Editar fornecedor
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              textAlign="center"
            >
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <TextField
                    type="text"
                    fullWidth
                    label="Nome"
                    variant="outlined"
                    value={editFornecedor.nome}
                    onChange={handleEditProvider('nome')}
                    required
                  // onBlur={() => handleValidationItem('nome')}
                  // error={validData.nome}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <TextField
                    label="CNPJ"
                    variant="outlined"
                    fullWidth
                    value={editFornecedor.cnpj}
                    onChange={handleEditProvider('cnpj')}
                    required
                  // onBlur={() => handleValidationItem('cnpj')}
                  // error={validData.cnpj}
                  // InputProps={{
                  //   inputComponent: TextMaskCustomCNPJ
                  // }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Autocomplete
                    options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.title}
                    fullWidth
                    onChange={(e, a) => handleSetCountry(a, 'edit')}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        label="País"
                        variant="outlined"
                        required
                      // onBlur={() => handleValidationItem('pais')}
                      // error={validData.pais}
                      />
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  hidden={editFornecedor.pais.toLocaleLowerCase() !== 'brasil' ? true : false}
                >
                  <TextField
                    label="CEP"
                    variant="outlined"
                    fullWidth
                    value={editFornecedor.cep}
                    onChange={handleEditProvider('cep')}
                    // InputProps={{
                    //   inputComponent: TextMaskCustomCEP
                    // }}
                    onBlur={() => handleGetCep('edit')}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  hidden={editFornecedor.pais.toLocaleLowerCase() !== 'brasil' ? true : false}
                >
                  <TextField
                    label="Rua"
                    variant="outlined"
                    fullWidth
                    value={editFornecedor.rua}
                    onChange={handleEditProvider('rua')}
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  hidden={editFornecedor.pais.toLocaleLowerCase() !== 'brasil' ? true : false}
                >
                  <TextField
                    label="Número"
                    variant="outlined"
                    fullWidth
                    value={editFornecedor.numero}
                    onChange={handleEditProvider('numero')}
                  // InputProps={{
                  //   inputComponent: TextMaskCustomCEP
                  // }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  hidden={editFornecedor.pais.toLocaleLowerCase() !== 'brasil' ? true : false}
                >
                  <TextField
                    label="Complemento"
                    variant="outlined"
                    fullWidth
                    value={editFornecedor.complemento}
                    onChange={handleEditProvider('complemento')}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  hidden={editFornecedor.pais.toLocaleLowerCase() !== 'brasil' ? true : false}
                >
                  <TextField
                    label="Bairro"
                    variant="outlined"
                    fullWidth
                    value={editFornecedor.bairro}
                    onChange={handleEditProvider('bairro')}
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  hidden={editFornecedor.pais.toLocaleLowerCase() !== 'brasil' ? true : false}
                >
                  <TextField
                    label="Cidade"
                    variant="outlined"
                    fullWidth
                    value={editFornecedor.cidade}
                    onChange={handleEditProvider('cidade')}
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  hidden={editFornecedor.pais.toLocaleLowerCase() !== 'brasil' ? true : false}
                >
                  <TextField
                    label="Estado"
                    variant="outlined"
                    fullWidth
                    value={editFornecedor.estado}
                    onChange={handleEditProvider('estado')}
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <TextField
                    type="text"
                    fullWidth
                    label="Site"
                    variant="outlined"
                    value={editFornecedor.site}
                    onChange={handleEditProvider('site')}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <TextField
                    type="text"
                    fullWidth
                    label="Responsável"
                    variant="outlined"
                    value={editFornecedor.responsavel}
                    onChange={handleEditProvider('responsavel')}
                    required
                  // onBlur={() => handleValidationItem('responsavel')}
                  // error={validData.responsavel}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <TextField
                    label="Telefone Responsável"
                    variant="outlined"
                    fullWidth
                    value={editFornecedor.tell}
                    onChange={handleEditProvider('tell')}
                    required
                  // onBlur={() => handleValidationItem('tell')}
                  // error={validData.tell}
                  // InputProps={{
                  //   inputComponent: TextMaskCustomCellPhone
                  // }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <TextField
                    type="email"
                    fullWidth
                    label="Email Responsável"
                    variant="outlined"
                    value={editFornecedor.email}
                    onChange={handleEditProvider('email')}
                    required
                  // onBlur={() => handleValidationItem('email')}
                  // error={validData.email}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              backgroundColor: '#f00',
              color: '#fff',
            }}
            onClick={handleCloseDialog('editSupplier')}
            autoFocus
          >
            Cancelar
          </Button>
          <Button
            style={{
              backgroundColor: theme.primary_color,
              color: '#fff',
            }}
            onClick={handleUpdateSupplier}
            autoFocus
          >
            Editar
          </Button>
        </DialogActions>
      </Dialog>

    </Grid>
  );
};

export default SupplierRegistration;