import React, { useEffect, useLayoutEffect, useState } from "react";
import theme from "../../../../core/theme.json";
import {
  Box,
  Button,
  Card,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Zoom,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  ButtonBase,
} from "@material-ui/core";
import "firebase/compat/firestore";
import myApp from "../../../../core/infrastructure/firebaseConfig";
import { setReportObj } from "../Redux/ViewsPdfReportVideoReducer";
import { useDispatch } from "react-redux";
import { changePage } from "../../../presentation/redux/actions";

import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import jsPDF from "jspdf";
import "jspdf-autotable";
import { Pagination } from "@material-ui/lab";

export const VideoReport = () => {
  const dispatch = useDispatch();

  const [myAnswers, setMyAnswers] = useState([]);
  const [mymodule, setMyModule] = useState([]);
  const [valueFilterDate, setValueFilterDate] = useState("");
  const [valueMedia, setValueMedia] = useState("");
  const [valueFilterOrder, setValueFilterOrder] = useState("");
  const [filterMedia, setFilterMedia] = useState([]);
  const [activepage, setActivepage] = useState(1);
  const [filterOptions, setFilterOptions] = useState({
    Module: false,
    Quest: false,
    User: false,
    Date: false,
    Tentative: false,
    MaxNote: false,
    MinNote: false,
    Media: false,
  });
  const currentCompanyId = localStorage.getItem("currentCompany");

  useEffect(() => {
    getdados();
    // getModule()
  }, []);

  const getdados = async () => {
    try {
      let get = await myApp
        .firestore()
        .collection("AnswerModuloVideo")
        .where("fk_company", "==", currentCompanyId)
        .get();
      let response = get.docs.map((item) => item.data());

      setMyAnswers(response);
    } catch (err) {
      console.log(err);
    }
  };
  console.log(myAnswers);

  const CalcMedia = (item) => {
    const media = item.point?.reduce((a, b) => a + b);

    if (media <= 10) {
      if (media == item.answers.map((item) => item.answers.length)[0]) {
        return "10";
      }
      if (media == item.answers.map((item) => item.answers.length / 2)[0]) {
        return "5";
      } else {
        return (item.point?.reduce((a, b) => a + b) / item.point.length) * 2;
      }
    } else if (media > 10) {
      return Math.round(
        item.point?.reduce((a, b) => a + b) / item.point.length
      );
    }
  };

  const studentData = myAnswers.map((item) => {
    return {
      nameModule: item.nameModule,
      nameOfQuestion: item.nameOfQuestion,
      tentatives: item.tentatives,
      email: item.email,
      date: `${new Intl.DateTimeFormat("pt-BR").format(item.date)}`,
      MaxNote: `${Math.max.apply(Math, item?.point)} / ${
        item.answers?.map((item) => item.answers.length)[0]
      }`,
      MinNote: `${Math.min.apply(Math, item.point)} / ${
        item.answers?.map((item) => item.answers.length)[0]
      }`,
      Media: `${CalcMedia(item)}/10`,
    };
  });

  const columns = [
    { title: "Módulo", field: "nameModule" },
    { title: "Questionário", field: "nameOfQuestion" },
    { title: "Usuário", field: "email", type: "numeric" },
    { title: "Data", field: "date" },
    { title: "Número de tentativas	", field: "tentatives" },
    { title: "Maior nota", field: "MaxNote" },
    { title: "Menor nota", field: "MinNote" },
    { title: "Média", field: "Media" },
  ];

  const TableExportToPdf = () => {
    const doc = new jsPDF("1", "pt", "a2");
    doc.text("Relatório de treinamento", 30, 30);

    doc.autoTable({
      minCellWidth: 100,
      theme: "grid",
      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: studentData,
    });
    doc.save("table.pdf");
  };

  const handleViewPdf = (item) => {
    console.log("item", item);
    let aux = {
      nameModule: item.nameModule,
      nameOfQuestion: item.nameOfQuestion,
      tentatives: item.tentatives,
      email: item.email,
      date: item.date,
      answers: item.answers,
    };
    dispatch(setReportObj(aux));
    dispatch(changePage(46));
  };

  const FilterOptions = (item) => {
    switch (item) {
      case "Modulo":
        setFilterOptions({
          Module: !filterOptions.Module,
          Quest: false,
          User: false,
          Date: false,
          Tentative: false,
          MaxNote: false,
          MinNote: false,
          Media: false,
        });
        filterOptions.Module == true
          ? myAnswers.sort((a, b) =>
              b.nameModule.toLowerCase() > a.nameModule.toLowerCase() ? 1 : -1
            )
          : myAnswers.sort((a, b) =>
              a.nameModule.toLowerCase() > b.nameModule.toLowerCase() ? 1 : -1
            );
        break;

      case "Quest":
        setFilterOptions({
          Quest: !filterOptions.Quest,
          Module: false,
          User: false,
          Date: false,
          Tentative: false,
          MaxNote: false,
          MinNote: false,
          Media: false,
        });
        filterOptions.Quest == true
          ? myAnswers.sort((a, b) =>
              b.nameOfQuestion.toLowerCase() > a.nameOfQuestion.toLowerCase()
                ? 1
                : -1
            )
          : myAnswers.sort((a, b) =>
              a.nameOfQuestion.toLowerCase() > b.nameOfQuestion.toLowerCase()
                ? 1
                : -1
            );
        break;

      case "User":
        setFilterOptions({
          User: !filterOptions.User,
          Module: false,
          Quest: false,
          Date: false,
          Tentative: false,
          MaxNote: false,
          MinNote: false,
          Media: false,
        });
        filterOptions.User == true
          ? myAnswers.sort((a, b) =>
              b.email.toLowerCase() > a.email.toLowerCase() ? 1 : -1
            )
          : myAnswers.sort((a, b) =>
              a.email.toLowerCase() > b.email.toLowerCase() ? 1 : -1
            );
        break;
      case "Date":
        setFilterOptions({
          Date: !filterOptions.Date,
          Module: false,
          Quest: false,
          User: false,
          Tentative: false,
          MaxNote: false,
          MinNote: false,
          Media: false,
        });
        filterOptions.Date == true
          ? myAnswers.sort((a, b) => (b.date > a.date ? 1 : -1))
          : myAnswers.sort((a, b) => (a.date > b.date ? 1 : -1));
        break;

      case "Tentative":
        setFilterOptions({
          Tentative: !filterOptions.Tentative,
          Module: false,
          Quest: false,
          User: false,
          Date: false,
          MaxNote: false,
          MinNote: false,
          Media: false,
        });
        filterOptions.Tentative == true
          ? myAnswers.sort((a, b) => (a.tentatives > b.tentatives ? 1 : -1))
          : myAnswers.sort((a, b) => (b.tentatives > a.tentatives ? 1 : -1));
        break;

      case "MaxNote":
        setFilterOptions({
          MaxNote: !filterOptions.MaxNote,
          Module: false,
          Quest: false,
          User: false,
          Date: false,
          Tentative: false,
          MinNote: false,
          Media: false,
        });
        filterOptions.MaxNote == true
          ? myAnswers.sort(
              (a, b) =>
                Math.max.apply(Math, a.point) - Math.max.apply(Math, b.point)
            )
          : myAnswers.sort(
              (a, b) =>
                Math.max.apply(Math, b.point) - Math.max.apply(Math, a.point)
            );

        break;

      case "MinNote":
        setFilterOptions({
          MinNote: !filterOptions.MinNote,
          Module: false,
          Quest: false,
          User: false,
          Date: false,
          MaxNote: false,
          Tentative: false,
          Media: false,
        });
        filterOptions.MinNote == true
          ? myAnswers.sort(
              (a, b) =>
                Math.min.apply(Math, a.point) - Math.min.apply(Math, b.point)
            )
          : myAnswers.sort(
              (a, b) =>
                Math.min.apply(Math, b.point) - Math.min.apply(Math, a.point)
            );
        break;

      case "Media":
        //  else if (media > 10) {
        //   return Math.round(item.point.reduce((a, b) => a + b) / item.point.length);
        // }

        setFilterOptions({
          Media: !filterOptions.Media,
          Module: false,
          Quest: false,
          User: false,
          Date: false,
          MaxNote: false,
          MinNote: false,
          Tentative: false,
        });
        filterOptions.Media == true
          ? myAnswers.sort((a, b) => CalcMedia(a) - CalcMedia(b))
          : myAnswers.sort((a, b) => CalcMedia(b) - CalcMedia(a));
        break;
    }
  };
  FilterOptions();
  const cleanFilter = (e) => {
    setValueFilterDate("");
    setValueFilterOrder("");
  };

  const filterPageReport = (item, index) => {
    const max = activepage * 5;
    const min = max - 5;

    if (index >= min && index < max) {
      return item;
    }
  };

  const handlePaginationChange = (event, page) => {
    setActivepage(page);
  };

  return (
    <>
      <Card
        style={{
          marginTop: "20px",
          marginBottom: "20px",
          height: "50px",
          padding: 10,
        }}
      >
        <Typography variant="h5">Relatório de treinamento</Typography>
      </Card>

      <Grid container justifyContent="flex-end" style={{ marginTop: "30px" }}>
        <ButtonBase>
          <Button
            variant="outlined"
            onClick={TableExportToPdf}
            style={{
              marginRight: "30px",
              color: theme.secondary_color,
              fontSize: "15px",
              backgroundColor: theme.primary_color,
            }}
          >
            Gerar PDF
          </Button>
        </ButtonBase>
      </Grid>
      <TableContainer>
        <Table style={{ width: "130%" }}>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  cursor: "pointer",
                  fontWeight: "bold",
                  backgroundColor: "#fff",
                  textAlign: "center",
                  fontSize: "14px",
                }}
                onClick={() => {
                  FilterOptions("Modulo");
                }}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  Módulo
                  {filterOptions.Module == false ? (
                    <ArrowDropDownIcon />
                  ) : (
                    <ArrowDropUpIcon />
                  )}
                </Grid>
              </TableCell>

              <TableCell
                style={{
                  cursor: "pointer",
                  fontWeight: "bold",
                  backgroundColor: "#fff",
                  textAlign: "center",
                  fontSize: "14px",
                }}
                onClick={() => {
                  FilterOptions("Quest");
                }}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  Questionário
                  {filterOptions.Quest == false ? (
                    <ArrowDropDownIcon />
                  ) : (
                    <ArrowDropUpIcon />
                  )}
                </Grid>
              </TableCell>

              <TableCell
                style={{
                  cursor: "pointer",
                  fontWeight: "bold",
                  backgroundColor: "#fff",
                  textAlign: "center",
                  fontSize: "14px",
                }}
                onClick={() => {
                  FilterOptions("User");
                }}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  Usuário
                  {filterOptions.User == false ? (
                    <ArrowDropDownIcon />
                  ) : (
                    <ArrowDropUpIcon />
                  )}
                </Grid>
              </TableCell>

              <TableCell
                style={{
                  cursor: "pointer",
                  fontWeight: "bold",
                  backgroundColor: "#fff",
                  textAlign: "center",
                  fontSize: "14px",
                }}
                onClick={() => {
                  FilterOptions("Date");
                }}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  Data
                  {filterOptions.Date == false ? (
                    <ArrowDropDownIcon />
                  ) : (
                    <ArrowDropUpIcon />
                  )}
                </Grid>
              </TableCell>

              <TableCell
                style={{
                  cursor: "pointer",
                  fontWeight: "bold",
                  backgroundColor: "#fff",
                  textAlign: "center",
                  fontSize: "14px",
                }}
                onClick={() => {
                  FilterOptions("Tentative");
                }}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  Número de tentativas
                  {filterOptions.Tentative == false ? (
                    <ArrowDropDownIcon />
                  ) : (
                    <ArrowDropUpIcon />
                  )}
                </Grid>
              </TableCell>

              <TableCell
                style={{
                  cursor: "pointer",
                  fontWeight: "bold",
                  backgroundColor: "#fff",
                  textAlign: "center",
                  fontSize: "14px",
                }}
                onClick={() => {
                  FilterOptions("MaxNote");
                }}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  Maior nota
                  {filterOptions.MaxNote == false ? (
                    <ArrowDropDownIcon />
                  ) : (
                    <ArrowDropUpIcon />
                  )}
                </Grid>
              </TableCell>

              <TableCell
                style={{
                  cursor: "pointer",
                  fontWeight: "bold",
                  backgroundColor: "#fff",
                  textAlign: "center",
                  fontSize: "14px",
                }}
                onClick={() => {
                  FilterOptions("MinNote");
                }}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  Menor nota
                  {filterOptions.MinNote == false ? (
                    <ArrowDropDownIcon />
                  ) : (
                    <ArrowDropUpIcon />
                  )}
                </Grid>
              </TableCell>

              <TableCell
                style={{
                  cursor: "pointer",
                  fontWeight: "bold",
                  backgroundColor: "#fff",
                  textAlign: "center",
                  fontSize: "14px",
                }}
                onClick={() => {
                  FilterOptions("Media");
                }}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  Média
                  {filterOptions.Media == false ? (
                    <ArrowDropDownIcon />
                  ) : (
                    <ArrowDropUpIcon />
                  )}
                </Grid>
              </TableCell>

              <TableCell
                style={{
                  fontWeight: "bold",
                  backgroundColor: "#fff",
                  textAlign: "center",
                  fontSize: "14px",
                }}
              >
                Ação
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {myAnswers.filter(filterPageReport).map((item, k) => {
              return (
                item.answers && (
                  <Zoom in>
                    <TableRow
                      key={k}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                      style={
                        k % 2
                          ? { background: "white" }
                          : { background: "#e3e3e3" }
                      }
                    >
                      <TableCell align="center" style={{ fontWeight: "Bold" }}>
                        {item.nameModule}
                      </TableCell>
                      <TableCell style={{ fontWeight: "Bold" }}>
                        {item.nameOfQuestion}
                      </TableCell>

                      <TableCell style={{ fontWeight: "Bold" }}>
                        {item.email}
                      </TableCell>

                      <TableCell align="center" style={{ fontWeight: "Bold" }}>
                        {new Intl.DateTimeFormat("pt-BR").format(item.date)}
                      </TableCell>
                      <TableCell align="center" style={{ fontWeight: "Bold" }}>
                        {item.tentatives}
                      </TableCell>
                      <TableCell align="center" style={{ fontWeight: "Bold" }}>
                        {Math.max.apply(Math, item.point)} /{" "}
                        {item.answers.map((item) => item.answers.length)[0]}
                      </TableCell>
                      <TableCell align="center" style={{ fontWeight: "Bold" }}>
                        {Math.min.apply(Math, item.point)} /{" "}
                        {item.answers.map((item) => item.answers.length)[0]}
                      </TableCell>
                      <TableCell align="center" style={{ fontWeight: "Bold" }}>
                        {CalcMedia(item)} /10
                      </TableCell>
                      <TableCell align="center" style={{ fontWeight: "Bold" }}>
                        <Button
                          onClick={() => handleViewPdf(item)}
                          style={{
                            background: theme.primary_color,
                            color: theme.secondary_color,
                          }}
                        >
                          PDF
                        </Button>
                      </TableCell>
                    </TableRow>
                  </Zoom>
                )
              );
            })}
            {myAnswers.length == 0 && (
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress />
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ paddingTop: "20px" }}
      >
        <Grid style={{ flexGrow: 1 }} />
        <Grid item>
          {myAnswers.length > 5 ? (
            <Pagination
              count={
                myAnswers.length % 5 === 0
                  ? myAnswers.length / 5
                  : Math.floor(myAnswers.length / 5) + 1
              }
              style={{ color: theme.secondary_color }}
              page={activepage}
              onChange={(event, page) => {
                handlePaginationChange(event, page);
              }}
            />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </>
  );
};
