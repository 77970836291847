import React, { useLayoutEffect, useState } from 'react'

/**
 * Este Hook informa se o elemento de referência possui Overflow de conteúdo.
 * @param {MutableRefObject} ref Valor retornado do uso do Hook React.useRef
 * @param {Function|undefined} cb Callback executado sempre que o layout de {ref} sofre alteração
 * @returns {undefined|boolean} true caso possua Overflow e false caso contrário
 */
const useIsOverflow = (ref, cb = undefined) => {
  const [isOverflow, setIsOverflow] = useState(undefined)

  const checkIfHasOverflow = current => {
    const hasOverflow = current.scrollHeight > current.clientHeight
    setIsOverflow(hasOverflow)
    if (cb) cb(hasOverflow)
  }

  useLayoutEffect(() => {
    const { current } = ref
    if (current)
      checkIfHasOverflow(current)
  }, [cb, ref])

  return isOverflow
}

export default useIsOverflow
