import React, { Component } from "react";

import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";

import {
	Grid,
	Paper,
	Button,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	CircularProgress,
} from "@material-ui/core";

import XLSX from "xlsx-js-style";

import theme from "../../../../core/theme.json";

import { generateData } from "../functions/excelData";
import { generateStyles } from "../functions/excelStyles";
import { ROPA_MERGES } from "../utils/constants";

import ROPADialog from "./ROPAanswers.jsx";

const database = myApp.firestore();

class ReportsRopa extends Component {
	constructor(props) {
		super(props);

		this.state = {
			companyId: localStorage.getItem("currentCompany"),
			company: null,
			processList: [],
			loading: true,
			questions: [],
			processIndex: -1,
			openDialog: false,
		};

		this.handleDialog = this.handleDialog.bind(this);
		this.handleAnswer = this.handleAnswer.bind(this);
		this.saveAnswers = this.saveAnswers.bind(this);
	}

	async componentDidMount() {
		await this.loadCompany();
		await this.loadQuestions();
		await this.getProcessesFromCompany();

		this.setState({
			loading: false,
		});
	}

	async loadCompany() {
		let company = {};

		try {
			let companySnapshot = await database
				.collection("companies")
				.doc(this.state.companyId)
				.get();

			company = companySnapshot.data();
		} catch (error) {
			console.error("Loading company: ", error);
		}

		this.setState({
			company,
		});
	}

	async loadQuestions() {
		let questions = [];

		try {
			let questionsSnapshot = await database
				.collection("questions")
				.where("ROPAreport", "==", true)
				.get();

			for (const doc of questionsSnapshot.docs) {
				questions.push({ ...doc.data() });
			}

			questions.sort((a, b) => {
				if (a.questionOrder > b.questionOrder) {
					return 1;
				}

				if (a.questionOrder < b.questionOrder) {
					return -1;
				}

				return 0;
			});
		} catch (error) {
			console.error("Loading questions: ", error);
		}

		this.setState({
			questions,
		});
	}

	async getProcessesFromCompany() {
		let processList = [];

		try {
			let areasSnapshot = await database
				.collection("areas")
				.where("fk_companies", "==", this.state.companyId)
				.where("active", "==", true)
				.get();

			for (const areaDoc of areasSnapshot.docs) {
				let processesSnapshot = await database
					.collection("processes")
					.where("fk_areas", "==", areaDoc.id)
					.get();

				for (const processDoc of processesSnapshot.docs) {
					let process = processDoc.data();
					process.id = processDoc.id;
					process.areaName = areaDoc.data().name;

					if (process.legalBases.length > 0) {
						process.legalBasisName = process.legalBases[0];
					} else {
						process.legalBasisName = "";
					}

					processList.push(process);
				}
			}
		} catch (error) {
			console.error("Loading processes: ", error);
		}

		this.setState({
			processList: processList.sort((a, b) => a.areaName.toLowerCase() > b.areaName.toLowerCase() ? 1 : -1),
		});
	}

	async generateExcel() {
		try {
			let data = await generateData(this.state.processList);

			let ws = XLSX.utils.aoa_to_sheet(data);
			ws["!merges"] = ROPA_MERGES;
			ws = await generateStyles(ws, this.state.processList.length);

			const wb = XLSX.utils.book_new();

			XLSX.utils.book_append_sheet(wb, ws, "ROPA");

			XLSX.writeFile(wb, "ROPA " + this.state.company.nome + ".xlsx");
		} catch (error) {
			console.error("Generating excel: ", error);
		}
	}

	handleAnswer(event, index) {
		let processList = this.state.processList;

		processList[this.state.processIndex].ROPAanswers[index] = event.target.value;

		this.setState({
			processList,
		});
	}

	handleDialog(index) {
		this.setState({
			openDialog: !this.state.openDialog,
			processIndex: index,
		});
	}

	async saveAnswers() {
		try {
			await database.collection("processes")
				.doc(this.state.processList[this.state.processIndex].id)
				.update({
					ROPAanswers: this.state.processList[this.state.processIndex].ROPAanswers,
				});
		} catch (error) {
			console.error("Saving answers: ", error);
		}

		this.handleDialog(-1);
	}

	render() {
		return (
			<>
				{this.state.loading ? (
					<Grid
						container
						direction="column"
						alignItems="center"
						style={{ width: "100%", marginTop: 10 }}
					>
						<Grid item style={{ paddingTop: "10vh" }}>
							<CircularProgress size={70} color={theme.secondary_color} />
						</Grid>
					</Grid>
				) : (
					<>
						<Grid
							component={Paper}
							
							direction="column"
							alignItems="flex-start"
							style={{ width: "100%", height: "78vh", marginTop: 10 }}
						>
							<Grid item component={Paper} style={{ width: "100%", backgroundColor: theme.secondary_color }}>
								<Button
									onClick={() => this.generateExcel()}
									style={{
										background: theme.primary_color,
										color: theme.secondary_color,
										margin: 10,
									}}
								>
									Download
								</Button>
							</Grid>
							<Grid item style={{ width: "100%" }}>
								<TableContainer style={{ maxHeight: "70vh" }}>
									<Table stickyHeader>
										<colgroup>
											<col style={{ width: "15%" }} />
											<col style={{ width: "15%" }} />
											<col style={{ width: "10%" }} />
											<col style={{ width: "10%" }} />
											<col style={{ width: "10%" }} />
											<col style={{ width: "10%" }} />
											<col style={{ width: "20%" }} />
											<col style={{ width: "10%" }} />
										</colgroup>
										<TableHead>
											<TableRow>
												<TableCell>ÁREA DE NEGÓCIO</TableCell>
												<TableCell>PROCESSO</TableCell>
												<TableCell>RESPONSÁVEL</TableCell>
												<TableCell>CRITICIDADE</TableCell>
												<TableCell>FINALIDADE</TableCell>
												<TableCell>CONTROLADOR OU OPERADOR</TableCell>
												<TableCell>BASE LEGAL</TableCell>
												<TableCell />
											</TableRow>
										</TableHead>
										<TableBody>
											{this.state.processList.map((process, index) => {
												return (
													<TableRow style={index % 2 ? { background: "white" } : { background: "#e3e3e3" }}>
														<TableCell>{process.areaName}</TableCell>
														<TableCell>{process.name}</TableCell>
														<TableCell>{process.owner}</TableCell>
														<TableCell>{process.criticality}</TableCell>
														<TableCell>{process.goal}</TableCell>
														<TableCell>{process.operator}</TableCell>
														<TableCell>{process.legalBasisName}</TableCell>
														<TableCell align="center">
															<Button
																style={{
																	background: theme.primary_color,
																	color: theme.secondary_color,
																}}
																onClick={() => this.handleDialog(index)}
															>
																VISUALIZAR RESPOSTAS
															</Button>
														</TableCell>
													</TableRow>
												);
											})}
										</TableBody>
									</Table>
								</TableContainer>
							</Grid>
						</Grid>

						{this.state.processList.length > 0 ?
							<ROPADialog
								handleDialog={this.handleDialog}
								handleAnswer={this.handleAnswer}
								saveAnswers={() => this.saveAnswers()}
								answers={this.state.processIndex > -1 ? this.state.processList[this.state.processIndex].ROPAanswers : []}
								questions={this.state.questions}
								openDialog={this.state.openDialog}
							/>
							:
							<></>
						}
					</>
				)}
			</>
		);
	}
}

export default ReportsRopa;
