import { SET_AREA, SET_PLAN } from './types';

const INITIAL_STATE = {
  area: "",

  plan: "",
}

export default (state = INITIAL_STATE, action) => {

  switch(action.type) {
    case SET_AREA:
      return {
        ...state,
        area: action.data,
      }
    case SET_PLAN:
      return {
        ...state,
        plan: action.data,
      }
  }

  return state;
}