

import {Grid, Typography} from '@mui/material'
import Lock from '../../../core/assets/lock.png'

export const PageNotPermition = () =>{
 

  return (
    <Grid style={{marginTop:"10%"}} container justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
    <img src={Lock } width={'10%'} />
    <h1> sem permissão</h1>
    <Typography>Parece que você não tem permissão para acessar essa página , verifique com o admnistrador e tente novamente.</Typography>
    </Grid>
  )
}