import React, { useEffect, useState } from "react";

import myApp from "../../core/infrastructure/firebaseConfig";

import { Forum, Home, RecordVoiceOver } from "@material-ui/icons";
import HomeIcon from "@material-ui/icons/Home";
import SettingsIcon from "@material-ui/icons/Settings";
import ActionPlansIcon from "../../shared/assets/icons/actionPlansIcon";

import Title from "../../shared/components/title";

import PlanList from "../features/home/components/planList";
import AreaList from "../features/home/components/areaList";

import AreaTitle from "../features/areas/components/areaTitle";
import ActiveAreaList from "../features/areas/components/activeAreaList";

import PlansTitle from "../features/plans/components/plansTitle";
import ActivePlanList from "../features/plans/components/activePlanList";
import ProcessDashboard from "../features/process/components/processDashboard";
import QuizDashboard from "../features/quiz/components/quizDashboard";
import NewQuizPage from "../features/quiz/components/newQuizPage";
import EditNewQuiz from "../features/quiz/components/editNewQuiz";
import InsertQuiz from "../features/quiz/components/insertQuiz";
import NewAnswerPage from "../features/quiz/components/newAnwerPage";

import ProcessAreaList from "../features/process/components/processAreaList";
import ProcessList from "../features/process/components/processList";

import ActionPlans from "../features/actionPlans/components/actionPlans";
import Reports from "../features/reports/components/reports";
import ReportsRopa from "../features/reports/components/reportsRopa";

import Incidentes from "../features/incidentes/components/incidentes";
import ViewIncidentsPDF from "../features/incidentes/components/viewIncidentesPDF";

import LIATestingList from "../features/LIA/LIATestingList";
import ReportsLIA from "../features/LIA/ReportsLIA";
import LIATest from "../features/LIA/LIATest";

import PainelDPO from "../../painelDPO/presentation/pages/painelDPO";
import ListBasis from "../../legalBasis/presentation/pages/listLegalBasis.js";
import LegalBasis from "../../legalBasis/presentation/pages/legalBasis.js";
import ComplementaryLegislation from "../../legalBasis/presentation/pages/complementaryLegislation.js";
import PoliciesAndPlans from "../../policiesAndPlans/presentation/pages/policiesAndPlans.js";
import DashboardIcon from "@material-ui/icons/Dashboard";
import CardTravelIcon from "@material-ui/icons/CardTravel";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import HomeTitle from "../features/home/components/homeTitle";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LanguageIcon from "@material-ui/icons/Language";
import GavelIcon from "@material-ui/icons/Gavel";

import GapsRelatorio from "../features/home/components/gapsRelatorio";
import QuestionnaireReport from "../features/quizAnswerReports/components/questionnaireReport";
import ViewQuestionnaireReport from "../features/quizAnswerReports/components/viewQuestionnairePdf";
import List from "../features/parameterization/components/list";
import ParameterHolders from "../features/parameterization/components/parameterHolders";
import ChatBotParameters from "../features/parameterization/components/chatbotParameters.js";
import AssetManagement from "../features/parameterization/components/assetManagement.js";
import FollowUp from "../../solicitationChannel/presentation/pages/followUp.js";
import SolicitationFormNew from "../../solicitationChannel/presentation/pages/solicitationFormNew.js";
import SolicitationFormEdit from "../../solicitationChannel/presentation/pages/solicitationFormEdit.js";

import Discovery from "../features/discovery/components/Discovery";
import DiscoveryDb from "../features/discovery/components/discoveryDb";

import Videos from "../features/videos/components/videosLGPD";
import PersonalVideoIcon from "@material-ui/icons/PersonalVideo";
import FirebaseEpmIntegration from "../features/epmFirebase/components";
import SupplierRegistration from "../features/parameterization/components/supplierRegistration";
import TrainingVideo from "../features/parameterization/components/trainingVideo";
import Movies from "../features/parameterization/components/movies";
import QuizVideoModule from "../features/parameterization/components/quizVideoModule";
import Denuncias from "../features/denuncias/components/denuncias";
import DenuncHolders from "../features/parameterization/components/denuncHolders";
import DenunceDetails from "../features/denuncias/components/denunciasDetails";
import ViewReportDenunce from "../features/denuncias/components/ViewsReportDenunce";
import { VideoReport } from "../features/videoReports/components/videoReports";
import ViewPdfReportVideo from "../features/videoReports/components/ViewPdfReportVideo";
import { ViewLogs } from "../features/logs/index.jsx";

let ListDrawer = [
  {
    name: "Home",
    icon: Home,
    showOnSideBar: true,
    fullScreen: false,
    component: (
      <div>
        <HomeTitle />
        <PlanList />
        <Title title="Áreas" />
        <AreaList />
      </div>
    ),
  },
  {
    name: "Áreas",
    icon: Home,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div>
        <AreaTitle />
        <ActiveAreaList />
      </div>
    ),
  },
  {
    name: "Overview da empresa",
    icon: Home,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div>
        <PlansTitle />
        <ActivePlanList />
      </div>
    ),
  },
  {
    name: "Questionário",
    icon: Forum,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div style={{ height: "95%" }}>
        <Title title="Questionários" />
        <QuizDashboard />
      </div>
    ),
  },
  {
    name: "Inserir questionário",
    icon: Forum,
    showOnSideBar: false,
    fullScreen: false,
    component: <InsertQuiz />,
  },
  {
    name: "Processos",
    icon: SettingsIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div style={{ height: "95%" }}>
        <Title title="Áreas" />
        <ProcessAreaList />
      </div>
    ),
  },
  {
    name: "Lista de processos",
    icon: SettingsIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div style={{ height: "95%" }}>
        {/*<Title title="Processos" />*/}
        <ProcessList />
      </div>
    ),
  },
  {
    name: "Dashboard do processos",
    icon: HomeIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div style={{ height: "95%" }}>
        <ProcessDashboard />
      </div>
    ),
  },
  {
    name: "Relatório de GAPS",
    icon: LibraryBooksIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div style={{ height: "90%" }}>
        <Title title="Relatório de GAPS" />
        <GapsRelatorio />
      </div>
    ),
  },
  {
    name: "Relatório de respostas",
    icon: LibraryBooksIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div style={{ height: "90%" }}>
        <Title title="Respostas" />
        <QuestionnaireReport />
      </div>
    ),
  },
  {
    name: "Teste LIA",
    icon: Forum,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div>
        <Title title="Lista de teste LIA" />
        <LIATestingList />
      </div>
    ),
  },
  {
    name: "LIA teste",
    icon: Forum,
    showOnSideBar: false,
    fullScreen: false,
    component: <LIATest />,
  },
  {
    name: "Cronograma",
    icon: ActionPlansIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div>
        <Title title="Cronograma adequação LGPD" />
        <ActionPlans />
      </div>
    ),
  },
  {
    name: "Painel do DPO",
    icon: DashboardIcon,
    showOnSideBar: true,
    fullScreen: false,
    component: (
      <div>
        <Title title="Painel do DPO" />
        <PainelDPO />
      </div>
    ),
  },
  {
    name: "Bases legais/legislação",
    icon: CardTravelIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div style={{ height: "90%" }}>
        <Title title="Bases legais/legislação" />
        {/*<LegalBasis />*/}
        <ListBasis />
      </div>
    ),
  },
  {
    name: "Políticas e planos",
    icon: AssignmentIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div>
        <Title title="Políticas e Planos" />
        <PoliciesAndPlans />
      </div>
    ),
  },
  {
    name: "Canal de solicitações",
    icon: RecordVoiceOver,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div>
        <Title title="Canal de Solicitações" />
        {/* <SolicitationChannel /> */}
        <FollowUp />
      </div>
    ),
  },
  {
    name: "Canal de novas solicitações",
    icon: RecordVoiceOver,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div>
        <Title title="Nova Solicitação" />
        <SolicitationFormNew />
      </div>
    ),
  },
  {
    name: "Formulário de solicitações",
    icon: RecordVoiceOver,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div>
        <Title title="Editar Solicitação" />
        <SolicitationFormEdit />
      </div>
    ),
  },
  {
    name: "Relatórios ROPA",
    icon: LibraryBooksIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div>
        <Title title="ROPA (Registro das Atividades de Tratamento)" />
        <ReportsRopa />
      </div>
    ),
  },
  {
    name: "Comunicação de incidentes",
    icon: LibraryBooksIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div>
        <Incidentes />
      </div>
    ),
  },
  {
    name: "Relatórios DPIA",
    icon: LibraryBooksIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div>
        <Title title="DPIA (Relatório de Impacto à Proteção de Dados)" />
        <Reports />
      </div>
    ),
  },
  {
    name: "Relatórios LIA",
    icon: LibraryBooksIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div>
        <Title title="LIA (Relatório de Legítimo Interesse)" />
        <ReportsLIA />
      </div>
    ),
  },
  {
    name: "Discovery - Arquivos",
    icon: LanguageIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div style={{ height: "90%" }}>
        <Title title="Discovery - Arquivos" />
        <Discovery />
      </div>
    ),
  },
  {
    name: "Discovery - BD",
    icon: LanguageIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div style={{ height: "90%" }}>
        <Title title="Discovery - BD" />
        <DiscoveryDb />
      </div>
    ),
  },
  {
    name: "Parametrização",
    icon: SettingsIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div style={{ height: "90%" }}>
        {/*<Title title="Parametrização" />*/}
        <List />
      </div>
    ),
  },
  {
    name: "Relatorio de respostas de questionário",
    icon: LibraryBooksIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div style={{ height: "90%" }}>
        {/*<Title title="Respostas" />*/}
        <ViewQuestionnaireReport />
      </div>
    ),
  },
  //27
  {
    name: "Treinamento",
    icon: PersonalVideoIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div style={{ height: "90%" }}>
        <Title title="Treinamento" />
        <Videos />
      </div>
    ),
  },
  {
    name: "Gerenciamento de titulares",
    icon: SettingsIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div style={{ height: "90%" }}>
        <ParameterHolders />
      </div>
    ),
  },
  {
    name: "Parametrização ChatBot",
    icon: SettingsIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div style={{ height: "90%" }}>
        <ChatBotParameters />
      </div>
    ),
  },
  {
    name: "Getão de ativos",
    icon: SettingsIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div style={{ height: "90%" }}>
        <AssetManagement />
      </div>
    ),
  },
  {
    name: "Bases legais",
    icon: SettingsIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div style={{ height: "90%" }}>
        <LegalBasis />
      </div>
    ),
  },
  {
    name: "Legislação",
    icon: SettingsIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div style={{ height: "90%" }}>
        <ComplementaryLegislation />
      </div>
    ),
  },
  // {
  //   name: "Importação EPM",
  //   icon: LibraryBooksIcon,
  //   showOnSideBar: Cookies.get("viewBtLaw") === "1" ? true : false,
  //   showOnSideBar: true,
  //   fullScreen: false,
  //   component:
  //     permitionsList[0]?.find((item) => item.page == "Importação EPM") ||
  //     userAdmin == "sim" ? (
  //       <div style={{ height: "90%" }}>
  //         <FirebaseEpmIntegration />
  //       </div>
  //     ) : (
  //       <PageNotPermition />
  //     ),
  // },
  {
    name: "Cadastro de fornecedores",
    icon: LibraryBooksIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div style={{ height: "90%" }}>
        <SupplierRegistration />
      </div>
    ),
  },
  {
    name: "Visualizar relatório de incidente",
    icon: LibraryBooksIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div style={{ height: "95%" }}>
        <ViewIncidentsPDF />
      </div>
    ),
  },
  {
    name: "Vídeo de treinamento",
    icon: LibraryBooksIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div style={{ height: "95%" }}>
        <TrainingVideo />
      </div>
    ),
  },
  {
    name: "Vídeos",
    icon: LibraryBooksIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div style={{ height: "95%" }}>
        <Movies />
      </div>
    ),
  },
  {
    name: "Questionário de vídeos",
    icon: LibraryBooksIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: (
      <div style={{ height: "95%" }}>
        <QuizVideoModule />
      </div>
    ),
  },
  {
    name: "Novo questinário 2.0",
    icon: LibraryBooksIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: <NewQuizPage />,
  },
  {
    name: "Editar questinário 2.0",
    icon: LibraryBooksIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: <EditNewQuiz />,
  },
  {
    name: "Responder questinário 2.0",
    icon: LibraryBooksIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: <NewAnswerPage />,
  },
  {
    name: "Parametrização de denúncia",
    icon: GavelIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: <DenuncHolders />,
  },
  {
    name: "Canal de denúncia",
    icon: GavelIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: <Denuncias />,
  },
  {
    name: "Detalhes denúncia",
    icon: GavelIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: <DenunceDetails />,
  },

  {
    name: "Relatório em PDF de denuncias",
    icon: GavelIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: <ViewReportDenunce />,
  },

  //numero 46
  {
    name: "Respostas de treinamento",
    icon: GavelIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: <VideoReport />,
  },

  {
    name: "Relatório em PDF de respostas treinamento",
    icon: GavelIcon,
    showOnSideBar: false,
    fullScreen: false,
    component: <ViewPdfReportVideo />,
  },

  {
    name: "Controle de usuários",
    icon: Home,
    showOnSideBar: false,
    fullScreen: false,
  },
  {
    name: "Relatório de logs",
    icon: GavelIcon,
    showOnSideBar: false,
    fullScreen: false,
    Title: "Relatório de logs",
    component: <div style={{ height: "90%" }}>
    <Title title="Treinamento" />
    <ViewLogs/>
  </div>
  },
];

export default ListDrawer;
