import { useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Box,
  MenuItem,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Paper,
  ButtonBase,
} from "@material-ui/core";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { DeleteForever, SaveTwoTone } from "@material-ui/icons";
import theme from "../../../../../core/theme.json";
import InputFile from "../../../../../shared/components/InputFile";
import AWSS3UploadAsh from "aws-s3-upload-ash";
import { ApurationForm } from "./ApurationForm";

export const ResponseForm = ({
  denunce,
  list_document_Response,
  setList_document_Response,
  response,
  setHandleResponse,
  formResponseFile,
  setFormResponseFile,
  teste,
  setTeste,
}) => {
  // const [state, setState] = useState({
  //   response: "",
  //   formResponseFile: { type: "", name: "" },
  //   teste: [],
  // });

  const config = {
    bucketName: process.env.REACT_APP_AWS_BUCKET_NAME,
    accessKeyId: process.env.REACT_APP_AWS_ACESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    s3Url: process.env.REACT_APP_AWS_LINK_PRIVACY,
    region: process.env.REACT_APP_AWS_REGION,
  };

  const S3CustomClient = new AWSS3UploadAsh(config);

  const handleFile = async () => {
    let aux = [];

    try {
      let response = await S3CustomClient.uploadFile(
        formResponseFile,
        formResponseFile.type,
        undefined,
        formResponseFile.name,
        "public-read"
      );

      aux.push(
        {
          name: response.key,
          url: response.location,
        },
        ...list_document_Response
      );
    } catch (error) {
      console.log("erro ao subir img", error);
    }
    setList_document_Response(aux);
  };

  const deleteDocument = (index) => {
    let aux = []
    let list = list_document_Response;
    list.splice(index, 1);
    setTeste(aux);
  };

  return (
    <form
    //   onSubmit={(event) => {
    //     event.preventDefault();
    //  saveSolicitation();
    //     return false;
    //   }}
    >
      <Paper variant="outlined">
        <Grid item style={{ margin: "20px 5px" }}>
          <Grid style={{ width: "100%" }} container direction="column">
            {denunce.response == undefined || denunce.response == "" ? (
              <Grid style={{ width: "100%" }}>
                <Grid container justifyContent="center">
                  <Typography variant="h6">Resposta :</Typography>
                </Grid>
                <TextField
                  style={{ padding: "0 10px" }}
                  multiline
                  rows={4}
                  fullWidth
                  variant="outlined"
                  color="primary"
                  InputLabelProps={{ shrink: false }}
                  value={response}
                  onChange={(event) => setHandleResponse(event.target.value)}
                />
              </Grid>
            ) : (
              <Grid>
                <Grid container justifyContent="center">
                  <Typography variant="h6">Resposta:</Typography>
                </Grid>
                <Typography
                  variant="h6"
                  component="h6"
                  style={{
                    width: "100%",
                    paddingTop: "10px",
                    color: "#808080",
                  }}
                >
                  {denunce.response}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>

      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="stretch"
          justifyContent="center"
        >
          <Grid item style={{ marginTop: "5%", paddingBottom: "5% " }}>
            <Typography variant="h6" component="h6" style={{ width: "100%" }}>
              Documento de resposta :
            </Typography>

            {denunce.status !== "Concluído" && (
              <Grid>
                <InputFile
                  denunce
                  onAcceptFiles={(files) => {
                    if (files.length) setFormResponseFile(files[0]);
                  }}
                />

                <ButtonBase
                  onClick={() => handleFile()}
                  style={{
                    background: theme.primary_color,
                    color: theme.quaternary_color,
                    padding: "15px 0",
                    marginBottom: "5px",
                  }}
                >
                  adicionar arquivo
                </ButtonBase>
              </Grid>
            )}

            <Grid style={{ margin: "5px 0px ", padding: "5px 0px " }}>
              {denunce.responsedenunce !== "" ? (
                list_document_Response.map((item, index) => {
                  return (
                    <Paper
                      style={{
                        padding: "8px",
                        margin: "15px 0",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <DeleteForever
                        onClick={() => deleteDocument(index)}
                        style={{ cursor: "pointer" }}
                      />

                      <span
                        style={{
                          fontSize: "1rem",
                          color: "#72bcd4",
                        }}
                      >
                        {item.name}
                      </span>

                      <ButtonBase
                        onClick={() => window.open(item.url)}
                        style={{
                          background: theme.primary_color,
                          color: theme.secondary_color,
                          padding: "5px",
                        }}
                      >
                        {" "}
                        visualizar arquivo
                      </ButtonBase>
                    </Paper>
                  );
                })
              ) : (
                <Grid container justifyContent="center">
                  <Typography variant="body2">
                    Nenhum arquivo fornecido
                  </Typography>
                </Grid>
              )}

              {denunce.files_document_response?.map((item) => {
                return (
                  <Paper
                    style={{
                      padding: "8px",
                      margin: "15px 0",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "1rem",
                        color: "#72bcd4",
                      }}
                    >
                      {item.name}
                    </span>

                    <ButtonBase
                      onClick={() => window.open(item.url)}
                      style={{
                        background: theme.primary_color,
                        color: theme.secondary_color,
                        padding: "5px",
                      }}
                    >
                      {" "}
                      visualizar arquivo
                    </ButtonBase>
                  </Paper>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

     
    </form>
  );
};
