import React, { useState, useEffect } from 'react';
import {
  Grid,
  Paper,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import { connect } from "react-redux";
import theme from "../../../../core/theme.json";
import DeleteIcon from '@material-ui/icons/Delete';
import { saveNewRopaAnswers } from "../functions/saveRopaAnswers";
import Swal from 'sweetalert2'

const NewAnswerPage = (props) => {
  const [quizId, setQuizId] = useState('');
  const [quizName, setQuizName] = useState('');
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [quizAnswers, setQuizAnswers] = useState([]);
  const [quizAnswered, setQuizAnswered] = useState(false);
  const [temporaryIndex, setTemporaryIndex] = useState('');
  const [temporaryValue, setTemporaryValue] = useState('');
  const [checkUser, setCheckUser] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    console.log('quizId', props.match.params.quizId)
    console.log('processId URL', props.match.params.processId)

    let checkUserAux = myApp.auth().currentUser;
    if (checkUserAux == null) {
      setCheckUser(false);
    } else {
      setCheckUser(true);
    }
    document.title = "BeePrivacy - Questionáro";
    setQuizId(props.match.params.quizId)
    handleMountinPage();
  }, []);

  const handleMountinPage = async () => {
    let get = await myApp.firestore().collection('newQuizz').doc(props.match.params.quizId).get();
    if (get.data().answered) {
      let get = await myApp.firestore().collection('newQuizAnswers').where("quizId", "==", props.match.params.quizId).get();
      setQuizAnswered(true);
    } else {

      let getNewQuiz = await myApp.firestore()
        .collection("newQuizAnswers")
        .where("processId", "==", props.match.params.processId)
        .where("fk_company", "==", localStorage.getItem('currentCompany'))
        .get();

      if (getNewQuiz.docs.length > 0) {
        const arr = Object.values(getNewQuiz.docs[0].data().questions);
        let aux = [];
        for (let item of arr) {
          aux.push({
            name: item.name,
            answer: item.answer,
            alternativesList: item.alternativesList
          });
        }
        setQuizAnswers(aux);
        setQuizName(get.data().name);
        setQuizQuestions(get.data().questions);
        setQuizAnswered(true);
      } else {
        const arr = Object.values(get.data().questions);
        let aux = [];
        for (let item of arr) {
          aux.push({
            name: item.name,
            answer: '',
            alternativesList: []
          });
        }
        setQuizAnswers(aux);
        setQuizName(get.data().name);
        setQuizQuestions(get.data().questions);
      }
    }
  }

  const handleFillingInAnswer = (index, event) => {
    let aux = quizAnswers;
    aux[index].answer = event.target.value;
    setQuizAnswers(aux)
  }

  const handleFillingInAnswerAlternative = (index, event) => {
    setTemporaryIndex(index);
    setTemporaryValue(event.target.value);
  }

  const handleAddInAnswerAlternative = () => {
    let separandoValue = temporaryValue.split('~');
    let aux = quizAnswers;
    let list = aux[temporaryIndex].alternativesList;
    list.push({
      name: separandoValue[1] ? separandoValue[1] : '',
      gap: quizQuestions[temporaryIndex].alternatives[separandoValue[0]].gap,
      gapPlanoAcao: quizQuestions[temporaryIndex].alternatives[separandoValue[0]].gapPlanoAcao ? quizQuestions[temporaryIndex].alternatives[separandoValue[0]].gapPlanoAcao : '',
      gapTitle: quizQuestions[temporaryIndex].alternatives[separandoValue[0]].gapTitle ? quizQuestions[temporaryIndex].alternatives[separandoValue[0]].gapTitle : '',
    })
    aux[temporaryIndex].alternativesList = list;
    setQuizAnswers(aux)
    setTemporaryIndex('');
    setTemporaryValue('');
  }

  const handleDeleteInAnswerAlternative = (index, alternativeIndex) => {
    let aux = quizAnswers.slice();
    let list = aux[index].alternativesList.slice();
    list.splice(alternativeIndex, 1);
    aux[index] = { ...aux[index], alternativesList: list };
    setQuizAnswers(aux);
  }

  const handleSaveAnswers = async () => {
    let teste = quizAnswers;
    let processId = props.match.params.processId;
    let d = new Date()

    await myApp.firestore().collection('newQuizAnswers').add({
      name: quizName != '' ? quizName : 'QUESTIONÁRIO PADRÃO',
      quizId: props.match.params.quizId ? props.match.params.quizId : '',
      processId: props.match.params.processId,
      dateReplay: d.getTime(),
      fk_company: localStorage.getItem('currentCompany'),
      questions: quizAnswers
    });

    let getCustongaps = await myApp.firestore().collection('processes').doc(processId).get();
    let auxGaps = getCustongaps.data().customGaps.slice();
    for (let getGaps of quizAnswers) {
      for (let childGaps of getGaps.alternativesList) {
        if (childGaps.gap == true) {
          auxGaps.push({
            name: childGaps.gapTitle ? childGaps.gapTitle : '',
            actionPlan: childGaps.gapPlanoAcao ? childGaps.gapPlanoAcao : '',
            date: null,
            done: false,
            fk_process: processId,
            owner: "automatico",
            ownerEmail: '',
            risk: 1,
            riskImpact: 1,
            riskGap: 1,
            riskGapImpact: 1,
            riskGapChance: 1,
            epmId: 0,
          });
        }
      }
    }

    await myApp.firestore().collection("processes").doc(processId).update({
      customGaps: auxGaps
    });
    console.log(auxGaps)

    if (quizName.toUpperCase() === "QUESTIONÁRIO PADRÃO") {
      await saveNewRopaAnswers(quizAnswers, processId,);
    }

    Swal.fire({
      icon: 'success',
      title: 'Questionario salvo',
      html: '<i>visualize as resposta em Relatório de Respostas</i>',
      showConfirmButton: 'Ok',
      confirmButtonColor: '#38f205'
    }).then(res => {
      if (res.isConfirmed)
        window.close()
    })
  }

  const validateEmail = async () => {
    if (
      email.includes('@') &&
      email.includes('.')
    ) {
      let get = await myApp.firestore().collection("questionnairesSentByEmail")
        .where("email", "==", email)
        .where("processId", "==", props.match.params.processId)
        .where("quizId", "==", props.match.params.quizId)
        .get();

      if (get.docs.length > 0) {
        setCheckUser(true);
      }
      else {
        alert('Você não tem permissão para responder esse questionário \n favor contate seu DPO');
      }
    }
    else {
      alert('Por favor digite um email valido');
    }
  }

  return (
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
    >
      {
        checkUser == true ?
          <Paper elevation={10} style={{ borderRadius: "5px", width: "90%", margin: "auto" }}>
            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
            >
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  paddingBottom: 100
                }}
              >
                <h1>{quizName}</h1>
              </Grid>

              {/* quizAnswered */}
              {
                quizQuestions.map((item, index) => {
                  return (
                    <Grid
                      style={{
                        width: '90%',
                        paddingBottom: 40
                      }}
                      item
                      xs={12}
                      sm={12}
                      container
                      direction='column'
                    >
                      <Grid item xs={12} sm={12} style={{ paddingBottom: 10 }}>
                        <Typography variant='h6' component='h6' >
                          {item.name}
                        </Typography>
                      </Grid>
                      {
                        item.alternatives ?
                          <Grid
                            container
                            direction='row'
                            spacing={2}
                            alignItems='center'
                            style={{
                              paddingBottom: 30
                            }}
                          >
                            <Grid item xs={6} sm={6} style={{ paddingBottom: 10 }}>
                              <FormControl variant="outlined" fullWidth>
                                <InputLabel>Selecione alternativa</InputLabel>
                                <Select
                                  // value={question.answer}
                                  onChange={(event) => handleFillingInAnswerAlternative(index, event)}
                                  label="Selecione alternativa"
                                  style={{ textAlign: "start" }}
                                >
                                  {
                                    item.alternatives.map((itemAlternative, indexAlternative) => (
                                      <MenuItem key={indexAlternative} value={indexAlternative + '~' + itemAlternative.name}>{itemAlternative.name}</MenuItem>
                                    ))
                                  }
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid item xs={2} sm={2} style={{ paddingBottom: 10, display: quizAnswered ? 'none' : 'block' }} >
                              <Button
                                variant="text"
                                style={{
                                  fontSize: '15px',
                                  backgroundColor: theme.primary_color,
                                  color: theme.secondary_color
                                }}
                                onClick={handleAddInAnswerAlternative}
                              >
                                <AddIcon style={{ fontSize: 30 }} />
                              </Button>
                            </Grid>
                          </Grid>
                          :
                          <></>
                      }
                      <Grid item xs={12} sm={12} style={{ paddingBottom: 30 }} >
                        {
                          quizAnswered === true ?
                            <TextField
                              multiline
                              minRows={item.name.toUpperCase() === 'ANOTAÇÕES GERAIS (INTERNAS)' ? 4 : 2}
                              variant="outlined"
                              fullWidth
                              value={quizAnswers[index].answer}
                            />
                            :
                            <TextField
                              multiline
                              minRows={item.name.toUpperCase() === 'ANOTAÇÕES GERAIS (INTERNAS)' ? 4 : 2}
                              variant="outlined"
                              fullWidth
                              onChange={(event) => handleFillingInAnswer(index, event)}
                            />
                        }
                      </Grid>

                      {
                        quizAnswers[index].alternativesList.length > 0 ?
                          quizAnswers[index].alternativesList.map((alternativeItem, alternativeIndex) => {
                            return (
                              <Grid
                                key={alternativeIndex}
                                container
                                direction='row'
                                spacing={2}
                                alignItems='center'
                                style={{
                                  border: alternativeItem.gap ? '1px solid #f00' : '1px solid #ccc',
                                  borderRadius: 5,
                                  padding: 5,
                                  marginBottom: 5,
                                  marginTop: 5
                                }}
                              >
                                <Grid
                                  item
                                  xs={11}
                                  sm={11}
                                >
                                  <Typography
                                    component='h6'
                                    variant='h6'
                                  >
                                    {alternativeItem.name}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={1}
                                  sm={1}
                                  style={{ display: quizAnswered ? 'none' : 'block' }}
                                >
                                  <Button
                                    variant="text"
                                    style={{
                                      fontSize: '15px',
                                      color: theme.secondary_color
                                    }}
                                    onClick={() => handleDeleteInAnswerAlternative(index, alternativeIndex,)}
                                  >
                                    <DeleteIcon style={{ fontSize: 30 }} />
                                  </Button>
                                </Grid>
                              </Grid>
                            )
                          })
                          :
                          <></>
                      }
                    </Grid>
                  )
                }
                )
              }

              <Grid
                item
                sm={12}
                xs={12}
                style={{
                  paddingTop: 50,
                  paddingBottom: 50
                }}
              >
                {
                  quizAnswered === true ?
                    <></>
                    :
                    <Button
                      fullWidth
                      variant="contained"
                      style={{
                        background: "#193993",
                        color: "white"
                      }}
                      onClick={handleSaveAnswers}
                    >
                      Salvar
                    </Button>
                }
              </Grid>
            </Grid>
          </Paper>
          :
          <Paper elevation={10} style={{ borderRadius: "5px", width: "50%", margin: "auto", }}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h6" container="h6" style={{ fontFamily: "Roboto" }}>Para responder o questionário é necessário informar seu e-mail</Typography>
              <TextField
                fullWidth
                value={email}
                onChange={e => setEmail(e.target.value)}
                variant="outlined"
                label="E-Mail"
                style={{ margin: "30px 0 0 0", width: "90%" }}
              />
            </Grid>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              item
              xs={12}
            >
              <Button
                variant="contained"
                disabled={email === ""}
                style={{ background: email === "" ? "#D5D5D5" : "#193993", color: "white", width: "20%", margin: "20px 0 10px 0" }}
                onClick={validateEmail}
              >
                Continuar
              </Button>
            </Grid>
          </Paper>
      }
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    quiz_id: state.quizReducer.quiz_id
  };
}

export default connect(mapStateToProps, {})(NewAnswerPage);