import React, { useState, useEffect } from 'react';

// tela de login
import Login from './login';
import MasterProcessList from './masterProcessList';

//imports mui
import {
  Grid,
  Tooltip,
  Fab,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';

//icones
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import CachedIcon from '@material-ui/icons/Cached';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

//import flowpoint => area de desenho das etapas
import { Flowpoint, Flowspace } from "flowpoints";

//import cores do tema mui
import theme from '../../../../core/theme.json'

//import redux
import { useDispatch, connect, } from 'react-redux'
import { setLogged } from '../redux/newEtapasEPMReducer';

import { useHistory } from "react-router-dom";


const App = (props) => {

  const dispatch = useDispatch();
  const history = useHistory();

  let diagramRef = null;

  const [tokenLogin, setTokenLogin] = useState(props.token);
  const [steps, setSteps] = useState([]);
  const [flowpoints, setFlowpoints] = useState([]);
  const [selected_point, setSelected_point] = useState(null);
  const [selectedLine, setSelectedLine] = useState(null);
  const [doFocus, setDoFocus] = useState(false);
  const [initialBoxPosition, setInitialBoxPosition] = useState({
    x: 0,
    y: 0
  });

  const [open, setOpen] = useState(false);

  const handleClose = async () => {
    setOpen(false);
  };

  const [dialogs, setDialogs] = useState({
    newFlow: false,
  });
  const [newPoint, setNewPoint] = useState({
    USER_ID: props.userId,
    ETAPA_FILHO: '',
    ETAPA_PAI: '',
    ID_ETAPA_FILHO: '',
    ID_ETAPA_PAI: '',
    ID_LIGACAO: '',
    ID_PROCESSO_FILHO: '',
    ID_PROCESSO_MASTER: props.masterProcessId,
    ID_PROCESSO_PAI: '',
    PROCESSO_FILHO: '',
    PROCESSO_MASTER: props.masterProcessName,
    PROCESSO_PAI: '',
    TIPO_TAREFA_FILHO: '',
    TIPO_TAREFA_PAI: '',
    VINCULO: '',
    outputs: {},
    position: { x: 0, y: 0 },
  });

  const handleSetNewPoint = (point) => (event) => {
    setNewPoint({
      ...newPoint,
      [point]: event.target.value
    });
  }

  const handleCloseDialog = (dialogSelected) => (value) => {
    setDialogs({
      ...dialogs,
      [dialogSelected]: false
    })
  }

  const handleOpenDialog = (dialogSelected) => (value) => {
    setDialogs({
      ...dialogs,
      [dialogSelected]: true
    })
  }

  const handleSaveNewPoint = async () => {
    console.log('Novo point', newPoint)
    setOpen(true);
    await addStepsEPM(newPoint);
    setOpen(false);

    // let add = flowpoints;
    // add.push(newPoint)
    // setFlowpoints(add);

    setNewPoint({
      USER_ID: props.userId,
      ETAPA_FILHO: '',
      ETAPA_PAI: '',
      ID_ETAPA_FILHO: '',
      ID_ETAPA_PAI: '',
      ID_LIGACAO: '',
      ID_PROCESSO_FILHO: '',
      ID_PROCESSO_MASTER: props.masterProcessId,
      ID_PROCESSO_PAI: '',
      PROCESSO_FILHO: '',
      PROCESSO_MASTER: props.masterProcessName,
      PROCESSO_PAI: '',
      TIPO_TAREFA_FILHO: '',
      TIPO_TAREFA_PAI: '',
      VINCULO: '',
      outputs: {},
      position: { x: 0, y: 0 },
    });
    setDialogs({
      newFlow: false
    })
  }

  useEffect(() => {
    console.log('Id do usuario', props.userId)

    if (props.Logged) {
      getStepsEPM();
      handleSelected();
      console.log('Token', props.token)
      console.log('masterProcessId', props.masterProcessId)
      console.log('masterProcessName', props.masterProcessName)
    }
  }, [props.Logged]);

  useEffect(() => {
    if (props.userId == 0) {
      history.push("/masterProcessList");
    }
  }, [props.userId]);

  const getStepsEPM = async () => {
    setFlowpoints([]);
    let obj = {
      qid: "PROCESSOS:NEWETAPAS",
      conditions: [
        {
          filterid: "USER_ID",
          values: [props.userId],
        },
        {
          filterid: "ID_PROCESSO_MASTER",
          values: [props.masterProcessId],
        },
      ],
    };

    let stepsAux = [];
    let posX = 0;
    let posY = 0;

    try {
      let getSteps = await fetch('https://apps.beelegal.com.br/epm_rayel/Integration/Query', {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(obj),
      });
      let respSteps = await getSteps.json();

      for (let stepsEPM of respSteps.list) {
        console.log('Teste', stepsEPM)
        // stepsAux.push(stepsEPM);
        // posY += 100;
        // console.log('Posição atual', posY);
        posY += 100;
        stepsAux.push({
          ID: stepsEPM.ID,
          USER_ID: stepsEPM.USER_ID,
          ETAPA_FILHO: stepsEPM.ETAPA_FILHO,
          ETAPA_PAI: stepsEPM.ETAPA_PAI,
          ID_ETAPA_FILHO: stepsEPM.ID_ETAPA_FILHO,
          ID_ETAPA_PAI: stepsEPM.ID_ETAPA_PAI,
          ID_LIGACAO: stepsEPM.ID_LIGACAO,
          ID_PROCESSO_FILHO: stepsEPM.ID_PROCESSO_FILHO,
          ID_PROCESSO_MASTER: stepsEPM.ID_PROCESSO_MASTER,
          ID_PROCESSO_PAI: stepsEPM.ID_PROCESSO_PAI,
          PROCESSO_FILHO: stepsEPM.PROCESSO_FILHO,
          PROCESSO_MASTER: stepsEPM.PROCESSO_MASTER,
          PROCESSO_PAI: stepsEPM.PROCESSO_PAI,
          TIPO_TAREFA_FILHO: stepsEPM.TIPO_TAREFA_FILHO,
          TIPO_TAREFA_PAI: stepsEPM.TIPO_TAREFA_PAI,
          VINCULO: stepsEPM.VINCULO,
          outputs: stepsEPM.position ? JSON.parse(stepsEPM.outputs) : {},
          position: stepsEPM.position ? JSON.parse(stepsEPM.position) : { x: posX, y: posY },
        })
      }

      // for (let point of stepsAux) {
      //   for (let i = 0; i < stepsAux.length; i++) {
      //     if (stepsAux[i].ID_ETAPA_FILHO == point.ID_ETAPA_PAI) {

      //       point.outputs = {
      //         [i]: {
      //           output: 'auto',
      //           input: 'auto',
      //           dash: 0
      //         }
      //       }

      //     }
      //   }
      // }

      console.log('Meus pointd', stepsAux)
      setFlowpoints(stepsAux);

      //setSteps(stepsAux);


    } catch (errorGetStaps) {
      console.log('Error Get Steps', errorGetStaps);
    }
  }

  const addStepsEPM = async (point) => {
    let x = initialBoxPosition.x;
    let y = initialBoxPosition.y;

    console.log('Quem recebe', point)

    let obj = {
      tid: "VF9CRUVMRUdBTF9ORVdfRVRBUEFTOjE2MTA1OQ==",
      fid: 197,
      data: {
        USER_ID: props.userId,
        ETAPA_FILHO: point.ETAPA_FILHO,
        ETAPA_PAI: point.ETAPA_PAI,
        ID_ETAPA_FILHO: point.ID_ETAPA_FILHO,
        ID_ETAPA_PAI: point.ID_ETAPA_PAI,
        ID_LIGACAO: point.ID_LIGACAO,
        ID_PROCESSO_FILHO: point.ID_PROCESSO_FILHO,
        ID_PROCESSO_MASTER: point.ID_PROCESSO_MASTER,
        ID_PROCESSO_PAI: point.ID_PROCESSO_PAI,
        PROCESSO_FILHO: point.PROCESSO_FILHO,
        PROCESSO_MASTER: point.PROCESSO_MASTER,
        PROCESSO_PAI: point.PROCESSO_PAI,
        TIPO_TAREFA_FILHO: point.TIPO_TAREFA_FILHO,
        TIPO_TAREFA_PAI: point.TIPO_TAREFA_PAI,
        VINCULO: point.VINCULO,
        outputs: JSON.stringify(point.outputs),
        // position: JSON.stringify(point.position),
        position: JSON.stringify(point.position),
        position: JSON.stringify({x, y}),
      }
    };

    try {
      let addStep = await fetch('https://apps.beelegal.com.br/epm_rayel/Integration/Save', {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          auth: props.token,
        },
        body: JSON.stringify(obj),
      });

      let resAddStep = await addStep.json();

      if (resAddStep.error) {
        console.log('errorAddStaps', resAddStep.error)
      } else {
        console.log('Resposta da requisição', resAddStep)
      }

    } catch (errorAddStaps) {
      console.log('errorAddStaps', errorAddStaps)
    }

    await saveDataPoints();
    await getStepsEPM();
    setInitialBoxPosition({
      x: x+100,
      y: y+100
    });
  }

  const saveDataPoints = async () => {
    setOpen(true);
    for (let item of flowpoints) {
      await savingData(item)
    }
    setOpen(false);
  }

  const savingData = async (item) => {
    try {
      let obj = {
        tid: "VF9CRUVMRUdBTF9ORVdfRVRBUEFTOjE2MTA1OQ==",
        fid: 197,
        data: {
          USER_ID: props.userId,
          ETAPA_FILHO: item.ETAPA_FILHO,
          ETAPA_PAI: item.ETAPA_PAI,
          ID_ETAPA_FILHO: item.ID_ETAPA_FILHO,
          ID_ETAPA_PAI: item.ID_ETAPA_PAI,
          ID_LIGACAO: item.ID_LIGACAO,
          ID_PROCESSO_FILHO: item.ID_PROCESSO_FILHO,
          ID_PROCESSO_MASTER: item.ID_PROCESSO_MASTER,
          ID_PROCESSO_PAI: item.ID_PROCESSO_PAI,
          PROCESSO_FILHO: item.PROCESSO_FILHO,
          PROCESSO_MASTER: item.PROCESSO_MASTER,
          PROCESSO_PAI: item.PROCESSO_PAI,
          TIPO_TAREFA_FILHO: item.TIPO_TAREFA_FILHO,
          TIPO_TAREFA_PAI: item.TIPO_TAREFA_PAI,
          VINCULO: item.VINCULO,
          outputs: JSON.stringify(item.outputs),
          position: JSON.stringify(item.position),
        },
        key: {
          id: item.ID
        }
      };
      let updateStep = await fetch('https://apps.beelegal.com.br/epm_rayel/Integration/Save', {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          auth: props.token,
        },
        body: JSON.stringify(obj),
      });

      let resUpdateStep = await updateStep.json();

      if (resUpdateStep.error) {
        console.log('errorUpdateStaps', resUpdateStep.error)
      } else {
        console.log('Resposta da requisição', resUpdateStep)
      }
    } catch (errorSavingData) {
      console.log('Erro ao atualizar dados', errorSavingData)
    }
  }



  const handleSelected = async () => {

  }

  useEffect(() => { console.log(selected_point) }, [selected_point]);

  const handleClick = (id, e) => {
    // console.log('Id', id)
    console.log('e', e)
    setDoFocus(true);
    let selected = selected_point;
    let points = flowpoints;

    if (e.shiftKey) {
      if (selected === null) {
        selected = id
      } else {
        if (selected !== id) {
          let p1 = points[selected]
          if (id in p1.outputs) {
            delete p1.outputs[id]
          } else {
            let p1 = points[id]
            p1.outputs = {
              [selected]: {
                output: 'auto',
                input: 'auto',
                dash: 0
              }
            }
          }
        }
      }
    } else {
      selected = (selected === null ? id : (selected === id ? null : id))
    }

    console.log(`ID:${id} Selecionado:${selected} `);


    //this.setState({ selected, points })
    setSelected_point(selected);
    setFlowpoints(points);
    console.log(flowpoints)

    let p1 = points[selected]
    let p2 = points[selected - 1]
    p2.TIPO_TAREFA_FILHO = p1.ETAPA_FILHO
    console.log('Dados para linkar', p1)
    console.log('Dados para linkar 2', p2)
    p1.outputs = {
      [id - 1]: {
        output: 'auto',
        input: 'auto',
        dash: 0
      }
    }

    console.log(p1)
  }

  const handleTouch = (id) => {
    setDoFocus(false);
    let selected = selected_point;
    let points = flowpoints;
    if (selected === null) {
      selected = id
    } else {
      if (selected !== id) {
        let p1 = points[selected]
        if (id in p1.outputs) {
          delete p1.outputs[id]
        } else {
          p1.outputs[id] = {
            output: 'auto',
            input: 'auto',
            dash: 0
          }
        }
      }
    }
    setSelected_point(selected);
    setFlowpoints(points);
  }

  return (
    <Grid
      container
      direction='column'
      spacing={2}
      style={{
        height: '100vh'
      }}
    >
      <Grid
        item
        container
        xs={12}
        direction='row'
        style={{
          height: '100%'
        }}
      >
        <Grid
          container
          item
          xs={1}
          direction='column'
          justifyContent='center'
          alignItems='center'
          style={{
            height: '100%',
          }}
        >
          <Grid
            container
            item
            direction='column'
            justifyContent='center'
            alignItems='center'
          >
            <Paper
              elevation={4}
              style={{
                position: "fixed",
                zIndex: 10,
                padding: "16px",
                borderRadius: "40px",
                marginTop: "16px",
                marginLeft: "4px",
              }}
            >
              <Grid
                item
                onClick={handleOpenDialog('newFlow')}
                style={{
                  marginBottom: 15
                }}
              >
                <Tooltip title="Adicionar novo item" placement="right" arrow>
                  <Fab
                    style={{ background: theme.secondary_color }}
                  >
                    <AddIcon style={{ color: theme.primary_color }} />
                  </Fab>
                </Tooltip>
              </Grid>

              <Grid
                item
                onClick={saveDataPoints}
                style={{
                  marginBottom: 15
                }}
              >
                <Tooltip title="Salvar posições e ligamentos" placement="right" arrow>
                  <Fab
                    style={{ background: theme.secondary_color }}
                  >
                    <SaveIcon style={{ color: theme.primary_color }} />
                  </Fab>
                </Tooltip>
              </Grid>

              <Grid
                item
                onClick={getStepsEPM}
                style={{
                  marginBottom: 15
                }}
              >
                <Tooltip title="Voltar  a lista de processos master" placement="right" arrow>
                  <Fab
                    style={{ background: theme.secondary_color }}
                  >
                    <CachedIcon style={{ color: theme.primary_color }} />
                  </Fab>
                </Tooltip>
              </Grid>

              <Grid
                item
                onClick={() => history.push("/masterProcessList")}
                style={{
                  marginBottom: 15
                }}
              >
                <Tooltip title="Recarregar" placement="right" arrow>
                  <Fab
                    style={{ background: theme.secondary_color }}
                  >
                    <ArrowBackIcon style={{ color: theme.primary_color }} />
                  </Fab>
                </Tooltip>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={11}
        >
          <Grid
            container
            item
            direction='row'
          >
            <Flowspace
              background="white"
              theme="green"
              variant="outlined"
              getDiagramRef={ref => { diagramRef = ref }}
              selected={selected_point}
              selectedLine={selectedLine}
              onLineClick={
                (key_a, key_b) => {
                  setSelectedLine({ a: key_a, b: key_b })
                }
              }
              onClick={e => {
                setSelected_point(null);
                setSelectedLine(null);
              }}
              style={{
                height: '100vh',
                width: '100vw'
              }}
            >
              {
                flowpoints ?
                  flowpoints.map((item, index) => (
                    <Flowpoint
                      key={index}
                      startPosition={item.position}
                      outputs={item.outputs}
                      onClick={(e) => handleClick(index, e)}
                      onTouch={() => handleTouch(index)}
                      onDrag={(position) => {
                        console.log('Chamou')
                        flowpoints[index].position = position
                        setFlowpoints(flowpoints)
                        console.log(position)
                      }}
                      // onHover={(isHovering) => { console.log(isHovering ? 'Hovering' : 'Not hovering') }}
                      style={{
                        width: 350,
                        height: 100,
                        padding: 10
                      }}
                    >
                      <Grid
                        container
                        justifyContent='flex-end'
                        alignItems='center'
                        style={{
                          width: '100%',
                          height: 10,
                        }}
                      >
                        <Grid
                          item
                        >
                          <Button
                            fullWidth
                            onClick={() => alert('Clicou')}
                          >
                            <SettingsIcon
                              fontSize="inherit"
                              style={{ color: theme.primary_color }}
                            />
                          </Button>
                        </Grid>
                      </Grid>
                      <Typography
                        variant="div"
                        component="div"
                        style={{
                          color: theme.secondary_color,
                          fontWeight: "bold",
                          textAlign: 'center',
                          width: '100%',
                          marginBottom: 15
                        }}
                      >
                        {`Processo: ${item.PROCESSO_FILHO}`}
                      </Typography>

                      <Typography
                        variant="div"
                        component="div"
                        style={{
                          color: theme.secondary_color,
                          fontWeight: "bold",
                          width: '100%',
                        }}
                      >
                        {`Etapa: ${item.ETAPA_FILHO}`}
                      </Typography>

                      <Typography
                        variant="div"
                        component="div"
                        style={{
                          color: theme.secondary_color,
                          fontWeight: "bold",
                          width: '100%',
                        }}
                      >
                        {`Tarefa filho: ${item.TIPO_TAREFA_FILHO}`}
                      </Typography>
                    </Flowpoint>
                  ))
                  :
                  <>
                    <h1>Carregando...</h1>
                  </>
              }

            </Flowspace>
          </Grid>
        </Grid>

        {/**Dialog para adicionar novo point */}
        <Dialog
          open={dialogs.newFlow}
          onClose={handleCloseDialog('newFlow')}
        >
          <DialogTitle
            style={{
              color: theme.quaternary_color,
              backgroundColor: theme.secondary_color,
              textAlign: "center",
            }}
          >
            CADASTRO DE NOVO POINT
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                textAlign="center"
              >
                <DialogContentText>

                  <Grid
                    container
                    style={{
                      width: '600px',
                    }}
                  >
                    <Grid
                      item
                      container
                      justifyContent='center'
                      alignItems='center'
                      style={{
                        height: '80px'
                      }}
                    >
                      <TextField
                        label="Nome da etapa"
                        variant="outlined"
                        style={{
                          width: "80%",
                        }}
                        value={newPoint.ETAPA_FILHO}
                        onChange={handleSetNewPoint('ETAPA_FILHO')}
                      />
                    </Grid>

                    {/* <Grid
                      item
                      container
                      justifyContent='center'
                      alignItems='center'
                      style={{
                        height: '80px'
                      }}
                    >
                      <TextField
                        label="Etapa pai"
                        variant="outlined"
                        style={{
                          width: "80%",
                        }}
                        value={newPoint.ETAPA_PAI}
                        onChange={handleSetNewPoint('ETAPA_PAI')}
                      />
                    </Grid>

                    <Grid
                      item
                      container
                      justifyContent='center'
                      alignItems='center'
                      style={{
                        height: '80px'
                      }}
                    >
                      <TextField
                        label="Id etapa filho"
                        variant="outlined"
                        style={{
                          width: "80%",
                        }}
                        value={newPoint.ID_ETAPA_FILHO}
                        onChange={handleSetNewPoint('ID_ETAPA_FILHO')}
                      />
                    </Grid>

                    <Grid
                      item
                      container
                      justifyContent='center'
                      alignItems='center'
                      style={{
                        height: '80px'
                      }}
                    >
                      <TextField
                        label="Id etapa pai"
                        variant="outlined"
                        style={{
                          width: "80%",
                        }}
                        value={newPoint.ID_ETAPA_PAI}
                        onChange={handleSetNewPoint('ID_ETAPA_PAI')}
                      />
                    </Grid>

                    <Grid
                      item
                      container
                      justifyContent='center'
                      alignItems='center'
                      style={{
                        height: '80px'
                      }}
                    >
                      <TextField
                        label="Id ligação"
                        variant="outlined"
                        style={{
                          width: "80%",
                        }}
                        value={newPoint.ID_LIGACAO}
                        onChange={handleSetNewPoint('ID_LIGACAO')}
                      />
                    </Grid>

                    <Grid
                      item
                      container
                      justifyContent='center'
                      alignItems='center'
                      style={{
                        height: '80px'
                      }}
                    >
                      <TextField
                        label="Id processo filho"
                        variant="outlined"
                        style={{
                          width: "80%",
                        }}
                        value={newPoint.ID_PROCESSO_FILHO}
                        onChange={handleSetNewPoint('ID_PROCESSO_FILHO')}
                      />
                    </Grid>

                    <Grid
                      item
                      container
                      justifyContent='center'
                      alignItems='center'
                      style={{
                        height: '80px'
                      }}
                    >
                      <TextField

                        label="Id processo pai"
                        variant="outlined"
                        style={{
                          width: "80%",
                          backgroundColor: '#ccc'
                        }}
                        value={newPoint.ID_PROCESSO_PAI}
                        onChange={handleSetNewPoint('ID_PROCESSO_PAI')}
                      />
                    </Grid>

                    <Grid
                      item
                      container
                      justifyContent='center'
                      alignItems='center'
                      style={{
                        height: '80px'
                      }}
                    >
                      <TextField

                        label="Id processo master"
                        variant="outlined"
                        style={{
                          width: "80%",
                          backgroundColor: '#ccc'
                        }}
                        value={newPoint.ID_PROCESSO_MASTER}
                        onChange={handleSetNewPoint('ID_PROCESSO_MASTER')}
                      />
                    </Grid>

                    <Grid
                      item
                      container
                      justifyContent='center'
                      alignItems='center'
                      style={{
                        height: '80px'
                      }}
                    >
                      <TextField

                        label="Processo filho"
                        variant="outlined"
                        style={{
                          width: "80%",
                          backgroundColor: '#ccc'
                        }}
                        value={newPoint.PROCESSO_FILHO}
                        onChange={handleSetNewPoint('PROCESSO_FILHO')}
                      />
                    </Grid>

                    <Grid
                      item
                      container
                      justifyContent='center'
                      alignItems='center'
                      style={{
                        height: '80px'
                      }}
                    >
                      <TextField

                        label="Processo pai"
                        variant="outlined"
                        style={{
                          width: "80%",
                          backgroundColor: '#ccc'
                        }}
                        value={newPoint.PROCESSO_PAI}
                        onChange={handleSetNewPoint('PROCESSO_PAI')}
                      />
                    </Grid>

                    <Grid
                      item
                      container
                      justifyContent='center'
                      alignItems='center'
                      style={{
                        height: '80px'
                      }}
                    >
                      <TextField

                        label="Processo master"
                        variant="outlined"
                        style={{
                          width: "80%",
                          backgroundColor: '#ccc'
                        }}
                        value={newPoint.PROCESSO_MASTER}
                        onChange={handleSetNewPoint('PROCESSO_MASTER')}
                      />
                    </Grid>

                    <Grid
                      item
                      container
                      justifyContent='center'
                      alignItems='center'
                      style={{
                        height: '80px'
                      }}
                    >
                      <TextField
                        label="Tipo tareda filho"
                        variant="outlined"
                        style={{
                          width: "80%",
                        }}
                        value={newPoint.TIPO_TAREFA_FILHO}
                        onChange={handleSetNewPoint('TIPO_TAREFA_FILHO')}
                      />
                    </Grid>

                    <Grid
                      item
                      container
                      justifyContent='center'
                      alignItems='center'
                      style={{
                        height: '80px'
                      }}
                    >
                      <TextField
                        label="Tipo tareda pai"
                        variant="outlined"
                        style={{
                          width: "80%",
                        }}
                        value={newPoint.TIPO_TAREFA_PAI}
                        onChange={handleSetNewPoint('TIPO_TAREFA_PAI')}
                      />
                    </Grid>

                    <Grid
                      item
                      container
                      justifyContent='center'
                      alignItems='center'
                      style={{
                        height: '80px'
                      }}
                    >
                      <TextField
                        label="Vinculo"
                        variant="outlined"
                        style={{
                          width: "80%",
                        }}
                        value={newPoint.VINCULO}
                        onChange={handleSetNewPoint('VINCULO')}
                      />
                    </Grid> */}

                  </Grid>

                </DialogContentText>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseDialog('newFlow')}
              autoFocus
            >
              Cancelar
            </Button>
            <Button
              onClick={handleSaveNewPoint}
              style={{
                color: '#fff',
                backgroundColor: theme.primary_color,
              }}
            >
              Adicionar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={open} onClose={handleClose} >
          <Backdrop open={open} onClick={handleClose}>
            <Grid
              direction='column'
              justifyContent='center'
              alignItems='center'
            >
              <h1 style={{ color: '#fff' }} >Salvando points</h1>
              <CircularProgress color="primary" />
            </Grid>
          </Backdrop>
        </Dialog>
      </Grid>
    </Grid >
  );
}

const mapStateToProps = (state) => {
  return {
    Logged: state.newEtapasEPMReducer.Logged,
    token: state.newEtapasEPMReducer.token,
    userId: state.newEtapasEPMReducer.userId,
    masterProcessId: state.newEtapasEPMReducer.masterProcessId,
    masterProcessName: state.newEtapasEPMReducer.masterProcessName
  };
}

export default connect(mapStateToProps, {})(App);