import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import React, { Component } from "react";
import {
  Paper,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Grid,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  MenuItem,
  ButtonBase,
  Select,
  InputLabel,
  FormControl,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Snackbar,
  Tooltip,
  Divider,
} from "@material-ui/core";

import Title from "../../../../shared/components/title";
import SettingsIcon from '@material-ui/icons/Settings';



import Alert from '@material-ui/lab/Alert';

import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import GetAppIcon from '@material-ui/icons/GetApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';

import DateFnsUtils from '@date-io/date-fns'; // choose your lib

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  DateTimePicker,
  KeyboardDateTimePicker
} from '@material-ui/pickers'

import { Close, QuestionAnswerRounded } from "@material-ui/icons";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import theme from "../../../../core/theme.json";

import { setObj } from "../redux/incidestesReducer";
import { changePage } from "../../../presentation/redux/actions";
import { connect } from "react-redux";

const database = myApp.firestore();


const RISCO_ENUM = {
  1: "mínimo",
  2: "pequeno",
  3: "médio",
  4: "grande",
  5: "muito grande",
};
class Incidentes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: localStorage.getItem("currentCompany"),
      listaIncidentes: [],
      openDialog: false,
      filterArea: '',
      filterOptionsArea: false,
      filterOptionsNotificate: false,
      filterOptionsUser: false,
      filterOptionsStatus: false,
      companySize: '',
      loading: true,
      companyAreas: [],
      cisAlert: false,
      cisAlertEmail: '',
      cisAlertQtdDIas: '',
      configureAlert: false,
      cisParameterizationId: '',
      cisNotConfigured: false,
      company: {},
      titulares_envolvidos: [],
      titulares: [],
      dados_pessoais_tratados_lista: [],
      dados_pessoais_sensiveis_tratados_tratados_lista: [],
      fontes_origin_dos_dados_lista: [],
      locais_armazenamento_lista: [],
      transferencia_interna_lista: [],
      demais_areas_lista: [],
      transferencia_externa_lista: [],
      medidas_seg_lista: [],
      tomada_decisao_lista: [],
      obs_analise_juridica: 'Os dados mapeados enquadram-se nas ns bases legais da LGPD, que são os requisitos para a execução do tratamento de dados pessoais, conforme previsto no art 7° da Lei 13709/2019',
      doc_solicitados_entrevistado_lista: [],
      noticeUnderMaintenance: false,
      msgNoticeUnderMaintenance: '',
      dataCominicaANPD: null
    };

    this.handleFilterByArea = this.handleFilterByArea.bind(this);
    this.filterOption = this.filterOption.bind(this);
    this.handleGetCep = this.handleGetCep.bind(this);
    this.openFile = this.openFile.bind(this);
  }

  async componentDidMount() {
    await this.getDados()
    
    await this.openDialogConfigureAlert('0');
    //this.generatePDF(115);
    await this.loadCompany();
    // this.loginGuest();
    // var user = myApp.auth().currentUser;
  }

  handleFilterByArea(item) {
    if (this.state.filterArea == '') {
      return item;
    } else {
      if (item.FK_AREA.toLowerCase().includes(this.state.filterArea.toLocaleLowerCase())) {
        return item;
      }
    }
  }

  filterOption(item) {
    let list = this.state.listaIncidentes;
    switch (item) {
      case 'area':
        this.setState({
          filterOptionsArea: !this.state.filterOptionsArea,
          filterOptionsNotificate: false,
          filterOptionsUser: false,
          filterOptionsStatus: false,
          listaIncidentes: this.state.filterOptionsArea ? list.sort((a, b) => a.FK_AREA.toLowerCase() > b.FK_AREA.toLowerCase() ? 1 : -1) : list.sort((a, b) => b.FK_AREA.toLowerCase() > a.FK_AREA.toLowerCase() ? 1 : -1),
        });
        break
      case 'notificate':
        this.setState({
          filterOptionsArea: false,
          filterOptionsNotificate: !this.state.filterOptionsNotificate,
          filterOptionsUser: false,
          filterOptionsStatus: false,
          listaIncidentes: this.state.filterOptionsNotificate ? list.sort((a, b) => a.NOTIFICANTE_NOME.toLowerCase() > b.NOTIFICANTE_NOME.toLowerCase() ? 1 : -1) : list.sort((a, b) => b.NOTIFICANTE_NOME.toLowerCase() > a.NOTIFICANTE_NOME.toLowerCase() ? 1 : -1),
        });
        break
      case 'user':
        this.setState({
          filterOptionsArea: false,
          filterOptionsNotificate: false,
          filterOptionsUser: !this.state.filterOptionsUser,
          filterOptionsStatus: false,
          listaIncidentes: this.state.filterOptionsUser ? list.sort((a, b) => a.USUARIO.toLowerCase() > b.USUARIO.toLowerCase() ? 1 : -1) : list.sort((a, b) => b.USUARIO.toLowerCase() > a.USUARIO.toLowerCase() ? 1 : -1),
        });
        break
      case 'status':
        this.setState({
          filterOptionsArea: false,
          filterOptionsNotificate: false,
          filterOptionsUser: false,
          filterOptionsStatus: !this.state.filterOptionsStatus,
          listaIncidentes: this.state.filterOptionsStatus ? list.sort((a, b) => a.FK_INCIDENTE_STATUS.toLowerCase() > b.FK_INCIDENTE_STATUS.toLowerCase() ? 1 : -1) : list.sort((a, b) => b.FK_INCIDENTE_STATUS.toLowerCase() > a.FK_INCIDENTE_STATUS.toLowerCase() ? 1 : -1),
        });
        break
    }
  }

  loginGuest() {
    fetch("https://disc.beelegal.com.br/beelegal/Security/Login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // username: "admin_mr",
        // password: "1234",
        username: "integracao",
        password: "mudar@123",
        // username: "andre",
        // password: "andre",
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        var token = responseJson.data.auth_ticket;
        this.getDados(token);
        this.setState({ token: token });
      });
  }

  async getDados() {
    this.setState({ loading: true });
    let aux = [];
    try {
      let get = await database.collection('incidentReporting').where('fk_company', "==", localStorage.getItem('currentCompany')).get();
    
      for (let doc of get.docs) {
        aux.push({
          id: doc.id,
          AGENTE_CEP: doc.data().AGENTE_CEP,
          AGENTE_CIDADE: doc.data().AGENTE_CIDADE,
          AGENTE_DOCUMENTO: doc.data().AGENTE_DOCUMENTO,
          AGENTE_EMAIL: doc.data().AGENTE_EMAIL,
          AGENTE_ENDERECO: doc.data().AGENTE_ENDERECO,
          AGENTE_ESTADO: doc.data().AGENTE_ESTADO,
          AGENTE_NOME: doc.data().AGENTE_NOME,
          AGENTE_TELEFONE: doc.data().AGENTE_TELEFONE,
          DATA: doc.data().DATA,
          DATA_CIENCIA_INCIDENTE: doc.data().DATA_CIENCIA_INCIDENTE,
          DATA_INCIDENTE: doc.data().DATA_INCIDENTE,
          EMPRESA: doc.data().EMPRESA,
          ENCARREGADO_EMAIL: doc.data().ENCARREGADO_EMAIL,
          ENCARREGADO_NOME: doc.data().ENCARREGADO_NOME,
          ENCARREGADO_TELEFONE: doc.data().ENCARREGADO_TELEFONE,
          FK_AREA: doc.data().FK_AREA,
          FK_CRITERIO: doc.data().FK_CRITERIO,
          FK_INCIDENTE_STATUS: doc.data().FK_INCIDENTE_STATUS,
          FK_NATUREZA_FINANCEIRO: doc.data().FK_NATUREZA_FINANCEIRO,
          FK_NATUREZA_GENETICO: doc.data().FK_NATUREZA_GENETICO,
          FK_NATUREZA_GEO: doc.data().FK_NATUREZA_GEO,
          FK_NATUREZA_IDENTIDADE: doc.data().FK_NATUREZA_IDENTIDADE,
          FK_NATUREZA_OPERACAO: doc.data().FK_NATUREZA_OPERACAO,
          FK_NATUREZA_ORGANIZACAO: doc.data().FK_NATUREZA_ORGANIZACAO,
          FK_NATUREZA_POLITICA: doc.data().FK_NATUREZA_POLITICA,
          FK_NATUREZA_RACIAL: doc.data().FK_NATUREZA_RACIAL,
          FK_NATUREZA_RELIGIOSA: doc.data().FK_NATUREZA_RELIGIOSA,
          FK_NATUREZA_SAUDE: doc.data().FK_NATUREZA_SAUDE,
          FK_NATUREZA_SINDICATO: doc.data().FK_NATUREZA_SINDICATO,
          FK_NATUREZA_USUARIO: doc.data().FK_NATUREZA_USUARIO,
          FK_TIPO_COMUNICACAO: doc.data().FK_TIPO_COMUNICACAO,
          FK_TIPO_COMUNICACAO_TITULAR: doc.data().FK_TIPO_COMUNICACAO_TITULAR,
          FK_TIPO_NOTIFICANTE: doc.data().FK_TIPO_NOTIFICANTE,
          FK_TIPO_EVENTO_INCIDENTE: doc.data().FK_TIPO_EVENTO_INCIDENTE,
          FK_TIPO_NOTIFICANTE: doc.data().FK_TIPO_NOTIFICANTE,
          FK_TITULAR_CLIENTE: doc.data().FK_TITULAR_CLIENTE,
          FK_TITULAR_CONSUMIDOR: doc.data().FK_TITULAR_CONSUMIDOR,
          FK_TITULAR_CRIANCA: doc.data().FK_TITULAR_CRIANCA,
          FK_TITULAR_FUNCIONARIO: doc.data().FK_TITULAR_FUNCIONARIO,
          FK_TITULAR_PACIENTE: doc.data().FK_TITULAR_PACIENTE,
          FK_TITULAR_PRESTADOR: doc.data().FK_TITULAR_PRESTADOR,
          FK_TITULAR_USUARIO: doc.data().FK_TITULAR_USUARIO,
          NOTIFICANTE_EMAIL: doc.data().NOTIFICANTE_EMAIL,
          NOTIFICANTE_NOME: doc.data().NOTIFICANTE_NOME,
          NOTIFICANTE_TELEFONE: doc.data().NOTIFICANTE_TELEFONE,
          OBS_AGENTE__RELATORIO_IMPACTO: doc.data().OBS_AGENTE__RELATORIO_IMPACTO,
          OBS_COMUNICACAO_CONTROLADOR: doc.data().OBS_COMUNICACAO_CONTROLADOR,
          OBS_COMUNICACAO_FORA_PRAZO: doc.data().OBS_COMUNICACAO_FORA_PRAZO,
          OBS_CONHECIMENTO_INCIDENTE: doc.data().OBS_CONHECIMENTO_INCIDENTE,
          OBS_CONSEQUENCIA_TITULAR: doc.data().OBS_CONSEQUENCIA_TITULAR,
          OBS_INCIDENTE_NAO_IMEDIATO: doc.data().OBS_INCIDENTE_NAO_IMEDIATO,
          OBS_INCIDENTE_SEGUGRANCA: doc.data().OBS_INCIDENTE_SEGUGRANCA,
          OBS_MEDIDAS: doc.data().OBS_MEDIDAS,
          OBS_MEDIDAS_ADOTADAS: doc.data().OBS_MEDIDAS_ADOTADAS,
          OBS_MEDIDAS_CIENCIA: doc.data().OBS_MEDIDAS_CIENCIA,
          OBS_NATUREZA: doc.data().OBS_NATUREZA,
          OBS_SEM_CERTEZA: doc.data().OBS_SEM_CERTEZA,
          OBS_SEM_CONHECIMENTO: doc.data().OBS_SEM_CONHECIMENTO,
          OBS_TITULAR: doc.data().OBS_TITULAR,
          OBS_TITULAR_NAO_INFORMADO: doc.data().OBS_TITULAR_NAO_INFORMADO,
          OBS_TRANSFRONTEIRA: doc.data().OBS_TRANSFRONTEIRA,
          QTD_TITULARES_AFETADOS: doc.data().QTD_TITULARES_AFETADOS,
          USUARIO: doc.data().USUARIO,
          fk_company: doc.data().fk_company,
          companySize: doc.data().companySize ? doc.data().companySize : '',
          qtde_titulares: doc.data().qtde_titulares ? doc.data().qtde_titulares : '',
          possui_encarregado: doc.data().possui_encarregado ? doc.data().possui_encarregado : '',
          proprio_encarregado_lgpd: doc.data().proprio_encarregado_lgpd ? doc.data().proprio_encarregado_lgpd : '',
          obs_notificante: doc.data().obs_notificante ? doc.data().obs_notificante : '',
          quando_comunicou_titu: doc.data().quando_comunicou_titu ? doc.data().quando_comunicou_titu : '',
          jus_comunicacao_anpd: doc.data().jus_comunicacao_anpd ? doc.data().jus_comunicacao_anpd : '',
          jus_quando_e_quais_autoridades: doc.data().jus_quando_e_quais_autoridades ? doc.data().jus_quando_e_quais_autoridades : '',
          comunicacao_aos_titulares_sim: doc.data().comunicacao_aos_titulares_sim ? doc.data().comunicacao_aos_titulares_sim : '',
          comunicacao_aos_titulares_nao_processo_esta_em_andamento: doc.data().comunicacao_aos_titulares_nao_processo_esta_em_andamento ? doc.data().comunicacao_aos_titulares_nao_processo_esta_em_andamento : false,
          comunicacao_aos_titulares_nao_risco_ou_dano: doc.data().comunicacao_aos_titulares_nao_risco_ou_dano ? doc.data().comunicacao_aos_titulares_nao_risco_ou_dano : false,
          comunicacao_aos_titulares_nao_vez_se_c_preliminar: doc.data().comunicacao_aos_titulares_nao_vez_se_c_preliminar ? doc.data().comunicacao_aos_titulares_nao_vez_se_c_preliminar : false,
          dado_sexual: doc.data().dado_sexual ? doc.data().dado_sexual : '',
          jus_nao_comunicacao_aos_titulares: doc.data().jus_nao_comunicacao_aos_titulares ? doc.data().jus_nao_comunicacao_aos_titulares : '',
          que_medidas_foram_adotadas: doc.data().que_medidas_foram_adotadas ? doc.data().que_medidas_foram_adotadas : '',
          confidencialidade: doc.data().confidencialidade ? doc.data().confidencialidade : false,
          integridade: doc.data().integridade ? doc.data().integridade : false,
          disponibilidade: doc.data().disponibilidade ? doc.data().disponibilidade : false,
          outra_categoria: doc.data().outra_categoria ? doc.data().outra_categoria : '',
          squestro_dados: doc.data().squestro_dados ? doc.data().squestro_dados : false,
          exploracao_vunerabolidade: doc.data().exploracao_vunerabolidade ? doc.data().exploracao_vunerabolidade : false,
          roubo_engenharia_social: doc.data().roubo_engenharia_social ? doc.data().roubo_engenharia_social : false,
          publi_n_internacional: doc.data().publi_n_internacional ? doc.data().publi_n_internacional : false,
          envio_dados_incorretos: doc.data().envio_dados_incorretos ? doc.data().envio_dados_incorretos : false,
          neg_ddos: doc.data().neg_ddos ? doc.data().neg_ddos : false,
          perda_roube_docs: doc.data().perda_roube_docs ? doc.data().perda_roube_docs : false,
          falha_eqp: doc.data().falha_eqp ? doc.data().falha_eqp : false,
          outro_tipo_inc_ciber: doc.data().outro_tipo_inc_ciber ? doc.data().outro_tipo_inc_ciber : false,
          seq_dados: doc.data().seq_dados ? doc.data().seq_dados : false,
          virus_pc: doc.data().virus_pc ? doc.data().virus_pc : false,
          violacao_bruteForce: doc.data().violacao_bruteForce ? doc.data().violacao_bruteForce : false,
          divulga_inde_pess: doc.data().divulga_inde_pess ? doc.data().divulga_inde_pess : false,
          aces_indev_sis: doc.data().aces_indev_sis ? doc.data().aces_indev_sis : false,
          alt_exc_dados: doc.data().alt_exc_dados ? doc.data().alt_exc_dados : false,
          des_inc_doc_ele: doc.data().des_inc_doc_ele ? doc.data().des_inc_doc_ele : false,
          falha_sis_inf: doc.data().falha_sis_inf ? doc.data().falha_sis_inf : false,
          falha_inc_cib: doc.data().falha_inc_cib ? doc.data().falha_inc_cib : false,
          des_como_ocorreu: doc.data().des_como_ocorreu ? doc.data().des_como_ocorreu : '',
          exp_porque_ocorreu: doc.data().exp_porque_ocorreu ? doc.data().exp_porque_ocorreu : '',
          qtde_total_titulares_afetados: doc.data().qtde_total_titulares_afetados ? doc.data().qtde_total_titulares_afetados : '',
          total_titulares_afetados_2: doc.data().total_titulares_afetados_2 ? doc.data().total_titulares_afetados_2 : '',
          criancas_e_adolecentes: doc.data().criancas_e_adolecentes ? doc.data().criancas_e_adolecentes : '',
          outros_titu_vul: doc.data().outros_titu_vul ? doc.data().outros_titu_vul : '',
          des_cat_de_titu_vul_afetados: doc.data().des_cat_de_titu_vul_afetados ? doc.data().des_cat_de_titu_vul_afetados : '',
          ckb_funcionarios: doc.data().ckb_funcionarios ? doc.data().ckb_funcionarios : false,
          ckb_clientes_cidadaos: doc.data().ckb_clientes_cidadaos ? doc.data().ckb_clientes_cidadaos : false,
          ckb_paciente_de_servico_saude: doc.data().ckb_paciente_de_servico_saude ? doc.data().ckb_paciente_de_servico_saude : false,
          ckb_prestadores_servico: doc.data().ckb_prestadores_servico ? doc.data().ckb_prestadores_servico : false,
          ckb_usuarios: doc.data().ckb_usuarios ? doc.data().ckb_usuarios : false,
          ckb_ainda_n_identificados: doc.data().ckb_ainda_n_identificados ? doc.data().ckb_ainda_n_identificados : false,
          ckb_estudantes_alunos: doc.data().ckb_estudantes_alunos ? doc.data().ckb_estudantes_alunos : false,
          ckb_inscritos_filiados: doc.data().ckb_inscritos_filiados ? doc.data().ckb_inscritos_filiados : false,
          ckb_outros: doc.data().ckb_outros ? doc.data().ckb_outros : false,
          informe_quantitativo_titu_afetados: doc.data().informe_quantitativo_titu_afetados ? doc.data().informe_quantitativo_titu_afetados : '',
          ckb_dados_morais: doc.data().ckb_dados_morais ? doc.data().ckb_dados_morais : false,
          ckb_discriminacao_social: doc.data().ckb_discriminacao_social ? doc.data().ckb_discriminacao_social : false,
          ckb_eng_social_fraude: doc.data().ckb_eng_social_fraude ? doc.data().ckb_eng_social_fraude : false,
          ckb_rest_direitos: doc.data().ckb_rest_direitos ? doc.data().ckb_rest_direitos : false,
          ckb_danos_materiais: doc.data().ckb_danos_materiais ? doc.data().ckb_danos_materiais : false,
          ckb_danos_reputacionais: doc.data().ckb_danos_reputacionais ? doc.data().ckb_danos_reputacionais : false,
          ckb_limitacao_de_acesso_servico: doc.data().ckb_limitacao_de_acesso_servico ? doc.data().ckb_limitacao_de_acesso_servico : false,
          ckb_perda_de_acesso_a_dados: doc.data().ckb_perda_de_acesso_a_dados ? doc.data().ckb_perda_de_acesso_a_dados : false,
          ckb_violacao_int_fisica: doc.data().ckb_violacao_int_fisica ? doc.data().ckb_violacao_int_fisica : false,
          ckb_roubo_identidade: doc.data().ckb_roubo_identidade ? doc.data().ckb_roubo_identidade : false,
          ckb_exposicao_dados_prof_legal: doc.data().ckb_exposicao_dados_prof_legal ? doc.data().ckb_exposicao_dados_prof_legal : false,
          cbk_outro_dois: doc.data().cbk_outro_dois ? doc.data().cbk_outro_dois : false,
          descreva_prov_conse_inc: doc.data().descreva_prov_conse_inc ? doc.data().descreva_prov_conse_inc : '',
          ckb_podem_sofrer_danos: doc.data().ckb_podem_sofrer_danos ? doc.data().ckb_podem_sofrer_danos : '',
          ckb_podem_sofrer_danos_supraveis: doc.data().ckb_podem_sofrer_danos_supraveis ? doc.data().ckb_podem_sofrer_danos_supraveis : '',
          ckb_podem_sofrer_danos_supraveis_com_dificuldade: doc.data().ckb_podem_sofrer_danos_supraveis_com_dificuldade ? doc.data().ckb_podem_sofrer_danos_supraveis_com_dificuldade : false,
          cbk_podem_sofrer_lesao: doc.data().cbk_podem_sofrer_lesao ? doc.data().cbk_podem_sofrer_lesao : false,
          secabivel_descreva_medidas: doc.data().secabivel_descreva_medidas ? doc.data().secabivel_descreva_medidas : '',
          ckb_sim: doc.data().ckb_sim ? doc.data().ckb_sim : false,
          ckb_nao: doc.data().ckb_nao ? doc.data().ckb_nao : false,
          se_cabivel_indique: doc.data().se_cabivel_indique ? doc.data().se_cabivel_indique : '',
          status_incidente: doc.data().status_incidente ? doc.data().status_incidente : '',
          filiacao: doc.data().filiacao ? doc.data().filiacao : false,
          dataCriacao: doc.data().dataCriacao,
        });
      }
    } catch (errorGetTickets) {
      console.error('errorGetTickets', errorGetTickets);
    }
    this.setState({
      listaIncidentes: aux,
      loading: false
    });
  }

  loadData(id, qq) {
    if (qq == 'complementar') {
      let items = this.state.listaIncidentes;
      this.setState({ openDialog: true, dialogMode: 'new' });
      this.setDataLoad(items[id]);
    } else {
      let items = this.state.listaIncidentes;
      this.setState({ openDialog: true, dialogMode: 'edit' });
      this.setDataLoad(items[id]);
    }
  }

  async generatePDF(id) {
    try {
      const track = await fetch('https://d5932xr5vl.execute-api.us-east-2.amazonaws.com/Prod/?id=' + id);
    }
    catch (err) {

    }
  }

  setDataLoad(data) {
    console.log(data);
    this.setState({
      agente_cep: data.AGENTE_CEP,
      nome_agente: data.AGENTE_NOME,
      agente_cidade: data.AGENTE_CIDADE,
      companySize: data.companySize ? data.companySize : '',
      cpf: data.AGENTE_DOCUMENTO,
      agente_email: data.AGENTE_EMAIL,
      agente_estado: data.AGENTE_ESTADO,
      agente_endereco: data.AGENTE_ENDERECO,
      agente_nome: data.AGENTE_NOME,
      agente_telefone: data.AGENTE_TELEFONE,
      dataCienciaIncidente: new Date(data.DATA_CIENCIA_INCIDENTE.seconds * 1000),
      data_incidente: new Date(data.DATA_INCIDENTE.seconds * 1000),
      dataCominicaANPD: new Date(data.DATA.seconds * 1000),
      encarregado_email: data.ENCARREGADO_EMAIL,
      encarregado_nome: data.ENCARREGADO_NOME,
      encarregado_telefone: data.ENCARREGADO_TELEFONE,
      area: data.FK_AREA,
      criterio_comunicacao: data.FK_CRITERIO,
      dado_financeiro: data.FK_NATUREZA_FINANCEIRO,
      dado_biometrico: data.FK_NATUREZA_GENETICO,
      dado_geo: data.FK_NATUREZA_GEO,
      dado_identidade: data.FK_NATUREZA_IDENTIDADE,
      natureza_operacao: data.FK_NATUREZA_OPERACAO,
      filiacao: false,
      opiniao_politica: data.FK_NATUREZA_POLITICA,
      origem_racial: data.FK_NATUREZA_RACIAL,
      civiccao_religiosa: data.FK_NATUREZA_RELIGIOSA,
      dado_saude: data.FK_NATUREZA_SAUDE,
      sindicato: data.FK_NATUREZA_SINDICATO,
      dado_usuario: data.FK_NATUREZA_USUARIO,
      tipo_comunicao: data.FK_TIPO_COMUNICACAO,
      des_como_ocorreu: data.des_como_ocorreu,
      comunicacao_parcial: data.FK_TIPO_COMUNICACAO_PARCIAL,
      tipo_comunicao_titular: data.FK_TIPO_COMUNICACAO_TITULAR,
      tipo_incidente: data.FK_TIPO_EVENTO_INCIDENTE,
      tipo_agente: data.FK_TIPO_NOTIFICANTE,
      categoria_clientes: data.FK_TITULAR_CLIENTE,
      categoria_consumidores: data.FK_TITULAR_CONSUMIDOR,
      categoria_crianca: data.FK_TITULAR_CRIANCA,
      categoria_funcionarios: data.FK_TITULAR_FUNCIONARIO,
      categoria_paciente: data.FK_TITULAR_PACIENTE,
      categoria_prestador: data.FK_TITULAR_PRESTADOR,
      categoria_usuarios: data.FK_TITULAR_USUARIO,
      notificante_email: data.NOTIFICANTE_EMAIL,
      notificante_nome: data.NOTIFICANTE_NOME,
      notificante_telefone: data.NOTIFICANTE_TELEFONE,
      agente_relatorio_impacto: data.OBS_AGENTE__RELATORIO_IMPACTO,
      comunicacao_controlador: data.OBS_COMUNICACAO_CONTROLADOR,
      motivos_nao_comunicacao: data.OBS_COMUNICACAO_FORA_PRAZO,
      como_ciencia: data.OBS_CONHECIMENTO_INCIDENTE,
      comunicacao_afetados: data.OBS_TITULAR_NAO_INFORMADO,
      descricao_incidente: data.OBS_INCIDENTE_SEGUGRANCA,
      medidas_seguranca: data.OBS_MEDIDAS,
      transfronteira: data.OBS_TRANSFRONTEIRA,
      consequencia_titular: data.OBS_CONSEQUENCIA_TITULAR,
      medidas_adotadas: data.OBS_MEDIDAS_ADOTADAS,
      medidas_ciencia: data.OBS_MEDIDAS_CIENCIA,
      outra_natureza: data.OBS_NATUREZA,
      justificativa_incidente: data.OBS_SEM_CONHECIMENTO,
      comunicacao_titular: data.OBS_TITULAR,
      quantidade: data.QTD_TITULARES_AFETADOS,
      status_incidente: data.FK_INCIDENTE_STATUS,
      showParcial: data.FK_TIPO_COMUNICACAO,
      seiData: data.DATA,
      id_incidente: data.ID,
      fk_company: data.fk_company,
      id: data.id,
      companySize: data.companySize ? data.companySize : '',
      qtde_titulares: data.qtde_titulares ? data.qtde_titulares : '',
      possui_encarregado: data.possui_encarregado ? data.possui_encarregado : '',
      proprio_encarregado_lgpd: data.proprio_encarregado_lgpd ? data.proprio_encarregado_lgpd : '',
      obs_notificante: data.obs_notificante ? data.obs_notificante : '',
      quando_comunicou_titu: data.quando_comunicou_titu ? data.quando_comunicou_titu : '',
      jus_comunicacao_anpd: data.jus_comunicacao_anpd ? data.jus_comunicacao_anpd : '',
      jus_quando_e_quais_autoridades: data.jus_quando_e_quais_autoridades ? data.jus_quando_e_quais_autoridades : '',
      comunicacao_aos_titulares_sim: data.comunicacao_aos_titulares_sim ? data.comunicacao_aos_titulares_sim : '',
      comunicacao_aos_titulares_nao_processo_esta_em_andamento: data.comunicacao_aos_titulares_nao_processo_esta_em_andamento ? data.comunicacao_aos_titulares_nao_processo_esta_em_andamento : false,
      comunicacao_aos_titulares_nao_risco_ou_dano: data.comunicacao_aos_titulares_nao_risco_ou_dano ? data.comunicacao_aos_titulares_nao_risco_ou_dano : false,
      comunicacao_aos_titulares_nao_vez_se_c_preliminar: data.comunicacao_aos_titulares_nao_vez_se_c_preliminar ? data.comunicacao_aos_titulares_nao_vez_se_c_preliminar : false,
      dado_sexual: data.dado_sexual ? data.dado_sexual : '',
      jus_nao_comunicacao_aos_titulares: data.jus_nao_comunicacao_aos_titulares ? data.jus_nao_comunicacao_aos_titulares : '',
      que_medidas_foram_adotadas: data.que_medidas_foram_adotadas ? data.que_medidas_foram_adotadas : '',
      confidencialidade: data.confidencialidade ? data.confidencialidade : false,
      integridade: data.integridade ? data.integridade : false,
      disponibilidade: data.disponibilidade ? data.disponibilidade : false,
      outra_categoria: data.outra_categoria ? data.outra_categoria : '',
      squestro_dados: data.squestro_dados ? data.squestro_dados : false,
      exploracao_vunerabolidade: data.exploracao_vunerabolidade ? data.exploracao_vunerabolidade : false,
      roubo_engenharia_social: data.roubo_engenharia_social ? data.roubo_engenharia_social : false,
      publi_n_internacional: data.publi_n_internacional ? data.publi_n_internacional : false,
      envio_dados_incorretos: data.envio_dados_incorretos ? data.envio_dados_incorretos : false,
      neg_ddos: data.neg_ddos ? data.neg_ddos : false,
      perda_roube_docs: data.perda_roube_docs ? data.perda_roube_docs : false,
      falha_eqp: data.falha_eqp ? data.falha_eqp : false,
      outro_tipo_inc_ciber: data.outro_tipo_inc_ciber ? data.outro_tipo_inc_ciber : false,
      seq_dados: data.seq_dados ? data.seq_dados : false,
      virus_pc: data.virus_pc ? data.virus_pc : false,
      violacao_bruteForce: data.violacao_bruteForce ? data.violacao_bruteForce : false,
      divulga_inde_pess: data.divulga_inde_pess ? data.divulga_inde_pess : false,
      aces_indev_sis: data.aces_indev_sis ? data.aces_indev_sis : false,
      alt_exc_dados: data.alt_exc_dados ? data.alt_exc_dados : false,
      des_inc_doc_ele: data.des_inc_doc_ele ? data.des_inc_doc_ele : false,
      falha_sis_inf: data.falha_sis_inf ? data.falha_sis_inf : false,
      falha_inc_cib: data.falha_inc_cib ? data.falha_inc_cib : false,
      exp_porque_ocorreu: data.exp_porque_ocorreu ? data.exp_porque_ocorreu : '',
      qtde_total_titulares_afetados: data.qtde_total_titulares_afetados ? data.qtde_total_titulares_afetados : '',
      total_titulares_afetados_2: data.total_titulares_afetados_2 ? data.total_titulares_afetados_2 : '',
      criancas_e_adolecentes: data.criancas_e_adolecentes ? data.criancas_e_adolecentes : '',
      outros_titu_vul: data.outros_titu_vul ? data.outros_titu_vul : '',
      des_cat_de_titu_vul_afetados: data.des_cat_de_titu_vul_afetados ? data.des_cat_de_titu_vul_afetados : '',
      ckb_funcionarios: data.ckb_funcionarios ? data.ckb_funcionarios : false,
      ckb_clientes_cidadaos: data.ckb_clientes_cidadaos ? data.ckb_clientes_cidadaos : false,
      ckb_paciente_de_servico_saude: data.ckb_paciente_de_servico_saude ? data.ckb_paciente_de_servico_saude : false,
      ckb_prestadores_servico: data.ckb_prestadores_servico ? data.ckb_prestadores_servico : false,
      ckb_usuarios: data.ckb_usuarios ? data.ckb_usuarios : false,
      ckb_ainda_n_identificados: data.ckb_ainda_n_identificados ? data.ckb_ainda_n_identificados : false,
      ckb_estudantes_alunos: data.ckb_estudantes_alunos ? data.ckb_estudantes_alunos : false,
      ckb_inscritos_filiados: data.ckb_inscritos_filiados ? data.ckb_inscritos_filiados : false,
      ckb_outros: data.ckb_outros ? data.ckb_outros : false,
      informe_quantitativo_titu_afetados: data.informe_quantitativo_titu_afetados ? data.informe_quantitativo_titu_afetados : '',
      ckb_dados_morais: data.ckb_dados_morais ? data.ckb_dados_morais : false,
      ckb_discriminacao_social: data.ckb_discriminacao_social ? data.ckb_discriminacao_social : false,
      ckb_eng_social_fraude: data.ckb_eng_social_fraude ? data.ckb_eng_social_fraude : false,
      ckb_rest_direitos: data.ckb_rest_direitos ? data.ckb_rest_direitos : false,
      ckb_danos_materiais: data.ckb_danos_materiais ? data.ckb_danos_materiais : false,
      ckb_danos_reputacionais: data.ckb_danos_reputacionais ? data.ckb_danos_reputacionais : false,
      ckb_limitacao_de_acesso_servico: data.ckb_limitacao_de_acesso_servico ? data.ckb_limitacao_de_acesso_servico : false,
      ckb_perda_de_acesso_a_dados: data.ckb_perda_de_acesso_a_dados ? data.ckb_perda_de_acesso_a_dados : false,
      ckb_violacao_int_fisica: data.ckb_violacao_int_fisica ? data.ckb_violacao_int_fisica : false,
      ckb_roubo_identidade: data.ckb_roubo_identidade ? data.ckb_roubo_identidade : false,
      ckb_exposicao_dados_prof_legal: data.ckb_exposicao_dados_prof_legal ? data.ckb_exposicao_dados_prof_legal : false,
      cbk_outro_dois: data.cbk_outro_dois ? data.cbk_outro_dois : false,
      descreva_prov_conse_inc: data.descreva_prov_conse_inc ? data.descreva_prov_conse_inc : '',
      ckb_podem_sofrer_danos: data.ckb_podem_sofrer_danos ? data.ckb_podem_sofrer_danos : '',
      ckb_podem_sofrer_danos_supraveis: data.ckb_podem_sofrer_danos_supraveis ? data.ckb_podem_sofrer_danos_supraveis : '',
      ckb_podem_sofrer_danos_supraveis_com_dificuldade: data.ckb_podem_sofrer_danos_supraveis_com_dificuldade ? data.ckb_podem_sofrer_danos_supraveis_com_dificuldade : false,
      cbk_podem_sofrer_lesao: data.cbk_podem_sofrer_lesao ? data.cbk_podem_sofrer_lesao : false,
      secabivel_descreva_medidas: data.secabivel_descreva_medidas ? data.secabivel_descreva_medidas : false,
      ckb_sim: data.ckb_sim ? data.ckb_sim : false,
      ckb_nao: data.ckb_nao ? data.ckb_nao : false,
      se_cabivel_indique: data.se_cabivel_indique ? data.se_cabivel_indique : '',
      status_incidente: data.status_incidente ? data.status_incidente : '',
      filiacao: data.filiacao ? data.filiacao : false
    });

  }

  clearData() {
    this.setState({
      agente_cep: '',
      agente_cidade: '',
      cpf: '',
      agente_email: '',
      agente_estado: '',
      agente_telefone: '',
      dataCienciaIncidente: null,
      data_incidente: null,
      encarregado_email: myApp.auth().currentUser.email,
      encarregado_nome: myApp.auth().currentUser.displayName,
      encarregado_telefone: myApp.auth().currentUser.phoneNumber,
      area: '',
      criterio_comunicacao: null,
      dado_financeiro: false,
      dado_biometrico: false,
      dado_geo: false,
      dado_identidade: false,
      natureza_operacao: false,
      filiacao: false,
      opiniao_politica: false,
      origem_racial: false,
      civiccao_religiosa: false,
      dado_saude: false,
      sindicato: false,
      dado_usuario: false,
      tipo_comunicao: null,
      comunicacao_parcial: null,
      tipo_comunicao_titular: null,
      tipo_incidente: null,
      tipo_agente: null,
      categoria_clientes: false,
      categoria_consumidores: false,
      categoria_crianca: false,
      categoria_funcionarios: false,
      categoria_paciente: false,
      categoria_prestador: false,
      categoria_usuarios: false,
      notificante_email: '',
      notificante_nome: '',
      notificante_telefone: '',
      agente_relatorio_impacto: '',
      comunicacao_controlador: '',
      motivos_nao_comunicacao: '',
      como_ciencia: '',
      comunicacao_afetados: '',
      descricao_incidente: '',
      medidas_seguranca: '',
      transfronteira: '',
      consequencia_titular: '',
      medidas_adotadas: '',
      medidas_ciencia: '',
      outra_natureza: '',
      justificativa_incidente: '',
      comunicacao_afetados: '',
      comunicacao_titular: '',
      quantidade: '',
      status_incidente: null,
      showParcial: false,
      companySize: '',
      qtde_titulares: '',
      possui_encarregado: '',
      proprio_encarregado_lgpd: '',
      obs_notificante: '',
      quando_comunicou_titu: '',
      jus_comunicacao_anpd: '',
      jus_quando_e_quais_autoridades: '',
      comunicacao_aos_titulares_sim: '',
      comunicacao_aos_titulares_nao_processo_esta_em_andamento: false,
      comunicacao_aos_titulares_nao_risco_ou_dano: false,
      comunicacao_aos_titulares_nao_vez_se_c_preliminar: false,
      filiacao: false,
      dado_sexual: false,
      jus_nao_comunicacao_aos_titulares: '',
      que_medidas_foram_adotadas: '',
      confidencialidade: false,
      integridade: false,
      disponibilidade: '',
      outra_categoria: '',
      squestro_dados: false,
      exploracao_vunerabolidade: false,
      roubo_engenharia_social: false,
      publi_n_internacional: false,
      envio_dados_incorretos: false,
      neg_ddos: false,
      perda_roube_docs: false,
      falha_eqp: false,
      outro_tipo_inc_ciber: false,
      seq_dados: false,
      virus_pc: false,
      violacao_bruteForce: false,
      divulga_inde_pess: false,
      aces_indev_sis: false,
      alt_exc_dados: false,
      des_inc_doc_ele: false,
      falha_sis_inf: false,
      falha_inc_cib: false,
      des_como_ocorreu: '',
      exp_porque_ocorreu: '',
      qtde_total_titulares_afetados: '',
      total_titulares_afetados_2: '',
      criancas_e_adolecentes: '',
      outros_titu_vul: '',
      des_cat_de_titu_vul_afetados: '',
      ckb_funcionarios: false,
      ckb_clientes_cidadaos: false,
      ckb_paciente_de_servico_saude: false,
      ckb_prestadores_servico: false,
      ckb_usuarios: false,
      ckb_ainda_n_identificados: false,
      ckb_estudantes_alunos: false,
      ckb_inscritos_filiados: false,
      ckb_outros: false,
      informe_quantitativo_titu_afetados: '',
      ckb_dados_morais: false,
      ckb_discriminacao_social: false,
      ckb_eng_social_fraude: false,
      ckb_rest_direitos: false,
      ckb_danos_materiais: false,
      ckb_danos_reputacionais: false,
      ckb_limitacao_de_acesso_servico: false,
      ckb_perda_de_acesso_a_dados: false,
      ckb_violacao_int_fisica: false,
      ckb_roubo_identidade: false,
      ckb_exposicao_dados_prof_legal: false,
      cbk_outro_dois: false,
      descreva_prov_conse_inc: '',
      ckb_podem_sofrer_danos: '',
      ckb_podem_sofrer_danos_supraveis: false,
      ckb_podem_sofrer_danos_supraveis_com_dificuldade: false,
      cbk_podem_sofrer_lesao: false,
      secabivel_descreva_medidas: false,
      ckb_sim: false,
      ckb_nao: false,
      se_cabivel_indique: '',
      status_incidente: '',
      filiacao: false,
    });
  }

  async saveData() {
    let obj = {
      tid: "VF9CRUVMRUdBTF9JTkNJREVOVEVTOjE1Nzc2OA==",
      fid: 138,
      data: {
        companySize: this.state.companySize ? this.state.companySize : '',
        AGENTE_CEP: this.state.agente_cep ? this.state.agente_cep : '',
        AGENTE_CIDADE: this.state.agente_cidade ? this.state.agente_cidade : '',
        AGENTE_DOCUMENTO: this.state.cpf ? this.state.cpf : '',
        AGENTE_EMAIL: this.state.agente_email ? this.state.agente_email : '',
        AGENTE_ENDERECO: this.state.agente_endereco ? this.state.agente_endereco : '',
        AGENTE_ESTADO: this.state.agente_estado ? this.state.agente_estado : '',
        AGENTE_NOME: this.state.nome_agente ? this.state.nome_agente : '',
        AGENTE_TELEFONE: this.state.agente_telefone ? this.state.agente_telefone : '',
        DATA: new Date(),
        DATA_INCIDENTE: this.state.data_incidente ? this.state.data_incidente : '',
        DATA_CIENCIA_INCIDENTE: this.state.dataCienciaIncidente ? this.state.dataCienciaIncidente : '',
        ENCARREGADO_EMAIL: this.state.encarregado_email ? this.state.encarregado_email : '',
        ENCARREGADO_NOME: this.state.encarregado_nome ? this.state.encarregado_nome : '',
        ENCARREGADO_TELEFONE: this.state.encarregado_telefone ? this.state.encarregado_telefone : '',
        FK_AREA: this.state.area ? this.state.area : '',
        FK_CRITERIO: this.state.criterio_comunicacao ? this.state.criterio_comunicacao : '',
        FK_INCIDENTE_STATUS: '',
        FK_NATUREZA_FINANCEIRO: this.state.dado_financeiro ? this.state.dado_financeiro : '',
        FK_NATUREZA_GENETICO: this.state.dado_biometrico ? this.state.dado_biometrico : '',
        FK_NATUREZA_GEO: this.state.dado_geo ? this.state.dado_geo : '',
        FK_NATUREZA_IDENTIDADE: this.state.dado_identidade ? this.state.dado_identidade : '',
        FK_NATUREZA_OPERACAO: this.state.natureza_operacao ? this.state.natureza_operacao : '',
        FK_NATUREZA_ORGANIZACAO: this.state.filiacao ? this.state.filiacao : '',
        FK_NATUREZA_POLITICA: this.state.opiniao_politica ? this.state.opiniao_politica : '',
        FK_NATUREZA_RACIAL: this.state.origem_racial ? this.state.origem_racial : '',
        FK_NATUREZA_SEXUAL: this.state.dado_sexual ? this.state.dado_sexual : false,
        FK_NATUREZA_RELIGIOSA: this.state.civiccao_religiosa ? this.state.civiccao_religiosa : '',
        FK_NATUREZA_SAUDE: this.state.dado_saude ? this.state.dado_saude : '',
        FK_NATUREZA_SINDICATO: this.state.sindicato ? this.state.sindicato : '',
        FK_NATUREZA_USUARIO: this.state.dado_usuario ? this.state.dado_usuario : '',
        FK_TIPO_COMUNICACAO: this.state.tipo_comunicao ? this.state.tipo_comunicao : '',
        FK_TIPO_COMUNICACAO_TITULAR: this.state.tipo_comunicao_titular ? this.state.tipo_comunicao_titular : '',
        FK_TIPO_EVENTO_INCIDENTE: this.state.tipo_incidente ? this.state.tipo_incidente : '',
        FK_TIPO_NOTIFICANTE: this.state.tipo_agente ? this.state.tipo_agente : '',
        FK_TITULAR_CLIENTE: this.state.categoria_clientes ? this.state.categoria_clientes : '',
        FK_TITULAR_CONSUMIDOR: this.state.categoria_consumidores ? this.state.categoria_consumidores : '',
        FK_TITULAR_CRIANCA: this.state.categoria_crianca ? this.state.categoria_crianca : '',
        FK_TITULAR_OUTROS: this.state.outra_categoria ? this.state.outra_categoria : '',
        FK_TITULAR_FUNCIONARIO: this.state.categoria_funcionarios ? this.state.categoria_funcionarios : '',
        FK_TITULAR_PACIENTE: this.state.categoria_paciente ? this.state.categoria_paciente : '',
        FK_TITULAR_PRESTADOR: this.state.categoria_prestador ? this.state.categoria_prestador : '',
        FK_TITULAR_USUARIO: this.state.categoria_usuarios ? this.state.categoria_usuarios : '',
        NOTIFICANTE_EMAIL: this.state.notificante_email ? this.state.notificante_email : '',
        NOTIFICANTE_NOME: this.state.notificante_nome ? this.state.notificante_nome : '',
        NOTIFICANTE_TELEFONE: this.state.notificante_telefone ? this.state.notificante_telefone : '',
        OBS_AGENTE__RELATORIO_IMPACTO: this.state.agente_relatorio_impacto ? this.state.agente_relatorio_impacto : '',
        OBS_COMUNICACAO_CONTROLADOR: this.state.comunicacao_controlador ? this.state.comunicacao_controlador : '',
        OBS_COMUNICACAO_FORA_PRAZO: this.state.motivos_nao_comunicacao ? this.state.motivos_nao_comunicacao : '',
        OBS_CONHECIMENTO_INCIDENTE: this.state.como_ciencia ? this.state.como_ciencia : '',
        OBS_INCIDENTE_NAO_IMEDIATO: this.state.comunicacao_afetados ? this.state.comunicacao_afetados : '',
        OBS_INCIDENTE_SEGUGRANCA: this.state.descricao_incidente ? this.state.descricao_incidente : '',
        OBS_MEDIDAS: this.state.medidas_seguranca ? this.state.medidas_seguranca : '',
        OBS_TRANSFRONTEIRA: this.state.transfronteira ? this.state.transfronteira : '',
        OBS_CONSEQUENCIA_TITULAR: this.state.consequencia_titular ? this.state.consequencia_titular : '',
        OBS_MEDIDAS_ADOTADAS: this.state.medidas_adotadas ? this.state.medidas_adotadas : '',
        OBS_MEDIDAS_CIENCIA: this.state.medidas_ciencia ? this.state.medidas_ciencia : '',
        OBS_NATUREZA: this.state.outra_natureza ? this.state.outra_natureza : '',
        OBS_SEM_CERTEZA: this.state.justificativa_incidente ? this.state.justificativa_incidente : '',
        OBS_TITULAR_NAO_INFORMADO: this.state.comunicacao_afetados ? this.state.comunicacao_afetados : '',
        OBS_SEM_CONHECIMENTO: this.state.justificativa_incidente ? this.state.justificativa_incidente : '',
        OBS_TITULAR: this.state.comunicacao_titular ? this.state.comunicacao_titular : '',
        QTD_TITULARES_AFETADOS: this.state.quantidade ? this.state.quantidade : '',
        USUARIO: myApp.auth().currentUser.email,
        EMPRESA: this.state.company.cnpj ? this.state.agente_cidade : '',
        fk_company: localStorage.getItem('currentCompany'),
        qtde_titulares: this.state.qtde_titulares ? this.state.qtde_titulares : '',
        possui_encarregado: this.state.possui_encarregado ? this.state.possui_encarregado : '',
        proprio_encarregado_lgpd: this.state.proprio_encarregado_lgpd ? this.state.proprio_encarregado_lgpd : '',
        obs_notificante: this.state.obs_notificante ? this.state.obs_notificante : '',
        quando_comunicou_titu: this.state.quando_comunicou_titu ? this.state.quando_comunicou_titu : '',
        jus_comunicacao_anpd: this.state.jus_comunicacao_anpd ? this.state.jus_comunicacao_anpd : '',
        jus_quando_e_quais_autoridades: this.state.jus_quando_e_quais_autoridades ? this.state.jus_quando_e_quais_autoridades : '',
        comunicacao_aos_titulares_sim: this.state.comunicacao_aos_titulares_sim ? this.state.comunicacao_aos_titulares_sim : '',
        comunicacao_aos_titulares_nao_processo_esta_em_andamento: this.state.comunicacao_aos_titulares_nao_processo_esta_em_andamento ? this.state.comunicacao_aos_titulares_nao_processo_esta_em_andamento : false,
        comunicacao_aos_titulares_nao_risco_ou_dano: this.state.comunicacao_aos_titulares_nao_risco_ou_dano ? this.state.comunicacao_aos_titulares_nao_risco_ou_dano : false,
        comunicacao_aos_titulares_nao_vez_se_c_preliminar: this.state.comunicacao_aos_titulares_nao_vez_se_c_preliminar ? this.state.comunicacao_aos_titulares_nao_vez_se_c_preliminar : false,
        filiacao: this.state.filiacao ? this.state.filiacao : false,
        dado_sexual: this.state.dado_sexual ? this.state.dado_sexual : false,
        jus_nao_comunicacao_aos_titulares: this.state.jus_nao_comunicacao_aos_titulares ? this.state.jus_nao_comunicacao_aos_titulares : '',
        que_medidas_foram_adotadas: this.state.que_medidas_foram_adotadas ? this.state.que_medidas_foram_adotadas : '',
        confidencialidade: this.state.confidencialidade ? this.state.confidencialidade : false,
        integridade: this.state.integridade ? this.state.integridade : false,
        disponibilidade: this.state.disponibilidade ? this.state.disponibilidade : '',
        outra_categoria: this.state.outra_categoria ? this.state.outra_categoria : '',
        squestro_dados: this.state.squestro_dados ? this.state.squestro_dados : false,
        exploracao_vunerabolidade: this.state.exploracao_vunerabolidade ? this.state.exploracao_vunerabolidade : false,
        roubo_engenharia_social: this.state.roubo_engenharia_social ? this.state.roubo_engenharia_social : false,
        publi_n_internacional: this.state.publi_n_internacional ? this.state.publi_n_internacional : false,
        envio_dados_incorretos: this.state.envio_dados_incorretos ? this.state.envio_dados_incorretos : false,
        neg_ddos: this.state.neg_ddos ? this.state.neg_ddos : false,
        perda_roube_docs: this.state.perda_roube_docs ? this.state.perda_roube_docs : false,
        falha_eqp: this.state.falha_eqp ? this.state.falha_eqp : false,
        outro_tipo_inc_ciber: this.state.outro_tipo_inc_ciber ? this.state.outro_tipo_inc_ciber : false,
        seq_dados: this.state.seq_dados ? this.state.seq_dados : false,
        virus_pc: this.state.virus_pc ? this.state.virus_pc : false,
        violacao_bruteForce: this.state.violacao_bruteForce ? this.state.violacao_bruteForce : false,
        divulga_inde_pess: this.state.divulga_inde_pess ? this.state.divulga_inde_pess : false,
        aces_indev_sis: this.state.aces_indev_sis ? this.state.aces_indev_sis : false,
        alt_exc_dados: this.state.alt_exc_dados ? this.state.alt_exc_dados : false,
        des_inc_doc_ele: this.state.des_inc_doc_ele ? this.state.des_inc_doc_ele : false,
        falha_sis_inf: this.state.falha_sis_inf ? this.state.falha_sis_inf : false,
        falha_inc_cib: this.state.falha_inc_cib ? this.state.falha_inc_cib : false,
        des_como_ocorreu: this.state.des_como_ocorreu ? this.state.des_como_ocorreu : '',
        exp_porque_ocorreu: this.state.exp_porque_ocorreu ? this.state.exp_porque_ocorreu : '',
        qtde_total_titulares_afetados: this.state.qtde_total_titulares_afetados ? this.state.qtde_total_titulares_afetados : '',
        total_titulares_afetados_2: this.state.total_titulares_afetados_2 ? this.state.total_titulares_afetados_2 : '',
        criancas_e_adolecentes: this.state.criancas_e_adolecentes ? this.state.criancas_e_adolecentes : '',
        outros_titu_vul: this.state.outros_titu_vul ? this.state.outros_titu_vul : '',
        des_cat_de_titu_vul_afetados: this.state.des_cat_de_titu_vul_afetados ? this.state.des_cat_de_titu_vul_afetados : '',
        ckb_funcionarios: this.state.ckb_funcionarios ? this.state.ckb_funcionarios : false,
        ckb_clientes_cidadaos: this.state.ckb_clientes_cidadaos ? this.state.ckb_clientes_cidadaos : false,
        ckb_paciente_de_servico_saude: this.state.ckb_paciente_de_servico_saude ? this.state.ckb_paciente_de_servico_saude : false,
        ckb_prestadores_servico: this.state.ckb_prestadores_servico ? this.state.ckb_prestadores_servico : false,
        ckb_usuarios: this.state.ckb_usuarios ? this.state.ckb_usuarios : false,
        ckb_ainda_n_identificados: this.state.ckb_ainda_n_identificados ? this.state.ckb_ainda_n_identificados : false,
        ckb_estudantes_alunos: this.state.ckb_estudantes_alunos ? this.state.ckb_estudantes_alunos : false,
        ckb_inscritos_filiados: this.state.ckb_inscritos_filiados ? this.state.ckb_inscritos_filiados : false,
        ckb_outros: this.state.ckb_outros ? this.state.ckb_outros : false,
        informe_quantitativo_titu_afetados: this.state.informe_quantitativo_titu_afetados ? this.state.informe_quantitativo_titu_afetados : '',
        ckb_dados_morais: this.state.ckb_dados_morais ? this.state.ckb_dados_morais : false,
        ckb_discriminacao_social: this.state.ckb_discriminacao_social ? this.state.ckb_discriminacao_social : false,
        ckb_eng_social_fraude: this.state.ckb_eng_social_fraude ? this.state.ckb_eng_social_fraude : false,
        ckb_rest_direitos: this.state.ckb_rest_direitos ? this.state.ckb_rest_direitos : false,
        ckb_danos_materiais: this.state.ckb_danos_materiais ? this.state.ckb_danos_materiais : false,
        ckb_danos_reputacionais: this.state.ckb_danos_reputacionais ? this.state.ckb_danos_reputacionais : false,
        ckb_limitacao_de_acesso_servico: this.state.ckb_limitacao_de_acesso_servico ? this.state.ckb_limitacao_de_acesso_servico : false,
        ckb_perda_de_acesso_a_dados: this.state.ckb_perda_de_acesso_a_dados ? this.state.ckb_perda_de_acesso_a_dados : false,
        ckb_violacao_int_fisica: this.state.ckb_violacao_int_fisica ? this.state.ckb_violacao_int_fisica : false,
        ckb_roubo_identidade: this.state.ckb_roubo_identidade ? this.state.ckb_roubo_identidade : false,
        ckb_exposicao_dados_prof_legal: this.state.ckb_exposicao_dados_prof_legal ? this.state.ckb_exposicao_dados_prof_legal : false,
        cbk_outro_dois: this.state.cbk_outro_dois ? this.state.cbk_outro_dois : false,
        descreva_prov_conse_inc: this.state.descreva_prov_conse_inc ? this.state.descreva_prov_conse_inc : '',
        ckb_podem_sofrer_danos: this.state.ckb_podem_sofrer_danos ? this.state.ckb_podem_sofrer_danos : '',
        ckb_podem_sofrer_danos_supraveis: this.state.ckb_podem_sofrer_danos_supraveis ? this.state.ckb_podem_sofrer_danos_supraveis : false,
        ckb_podem_sofrer_danos_supraveis_com_dificuldade: this.state.ckb_podem_sofrer_danos_supraveis_com_dificuldade ? this.state.ckb_podem_sofrer_danos_supraveis_com_dificuldade : false,
        cbk_podem_sofrer_lesao: this.state.cbk_podem_sofrer_lesao ? this.state.cbk_podem_sofrer_lesao : false,
        secabivel_descreva_medidas: this.state.secabivel_descreva_medidas ? this.state.secabivel_descreva_medidas : false,
        ckb_sim: this.state.ckb_sim ? this.state.ckb_sim : false,
        ckb_nao: this.state.ckb_nao ? this.state.ckb_nao : false,
        se_cabivel_indique: this.state.se_cabivel_indique ? this.state.se_cabivel_indique : '',
        status_incidente: this.state.status_incidente ? this.state.status_incidente : '',
        filiacao: this.state.filiacao ? this.state.filiacao : false,
        dataCriacao: new Date()

      }
    };


    if (this.state.tipo_comunicao == 'Parcial') {
      this.setState({ cisAlert: true });
    }

    const sendEmail = myApp.functions().httpsCallable('sendEmail');
    try {
      this.setState({ openDialog: false });
      let requestObject = {
        from: `${process.env.REACT_APP_FROM_NAME} <${process.env.REACT_APP_FROM_EMAIL}>`,
        email: this.state.cisAlertEmail,
        subject: "COMUNICAÇÃO DE INCIDENTE",
        message:
          `Prezado(a),` +
          ` Informo abertura de incidente na área * ${this.state.area ? this.state.area : 'NÃO INFORMADO'} *. `
        ,
      }

      await sendEmail(requestObject);
    }
    catch (error) {
      console.log(error);
    }

    try {
      await myApp.firestore().collection('incidentReporting').doc().set(obj.data)
        .then(() => {
          this.clearData();
          this.setState({ showSuccess: true });
          this.getDados('');
        });
    } catch (e) { console.log(e) }
  }

  editData() {
    const confirmation = window.confirm('Deseja editar a comunicação de incidente? Esta ação é irreversível.');
    if (confirmation) {
      database.collection('incidentReporting').doc(this.state.id).update({
        DATA: this.state.dataCominicaANPD ? this.state.dataCominicaANPD : null,
        DATA_INCIDENTE: this.state.data_incidente ? this.state.data_incidente : null,
        DATA_CIENCIA_INCIDENTE: this.state.dataCienciaIncidente ? this.state.dataCienciaIncidente : null,
        companySize: this.state.companySize ? this.state.companySize : '',
        AGENTE_CEP: this.state.agente_cep,
        AGENTE_CIDADE: this.state.agente_cidade,
        AGENTE_DOCUMENTO: this.state.cpf,
        AGENTE_EMAIL: this.state.agente_email,
        AGENTE_ENDERECO: this.state.agente_endereco,
        AGENTE_ESTADO: this.state.agente_estado,
        AGENTE_NOME: this.state.nome_agente,
        AGENTE_TELEFONE: this.state.agente_telefone,
        ENCARREGADO_EMAIL: this.state.encarregado_email,
        ENCARREGADO_NOME: this.state.encarregado_nome,
        ENCARREGADO_TELEFONE: this.state.encarregado_telefone,
        FK_AREA: this.state.area,
        FK_CRITERIO: this.state.criterio_comunicacao,
        FK_INCIDENTE_STATUS: 'Pendente',
        FK_NATUREZA_FINANCEIRO: this.state.dado_financeiro,
        FK_NATUREZA_GENETICO: this.state.dado_biometrico,
        FK_NATUREZA_GEO: this.state.dado_geo,
        FK_NATUREZA_IDENTIDADE: this.state.dado_identidade,
        FK_NATUREZA_OPERACAO: this.state.natureza_operacao,
        FK_NATUREZA_ORGANIZACAO: this.state.filiacao,
        FK_NATUREZA_POLITICA: this.state.opiniao_politica,
        FK_NATUREZA_RACIAL: this.state.origem_racial,
        FK_NATUREZA_SEXUAL: this.state.dado_sexual,
        FK_NATUREZA_RELIGIOSA: this.state.civiccao_religiosa,
        FK_NATUREZA_SAUDE: this.state.dado_saude,
        FK_NATUREZA_SINDICATO: this.state.sindicato,
        FK_NATUREZA_USUARIO: this.state.dado_usuario,
        FK_TIPO_COMUNICACAO: this.state.tipo_comunicao,
        FK_TIPO_COMUNICACAO_TITULAR: this.state.tipo_comunicao_titular,
        FK_TIPO_EVENTO_INCIDENTE: this.state.tipo_incidente,
        FK_TIPO_NOTIFICANTE: this.state.tipo_agente,
        FK_TITULAR_CLIENTE: this.state.categoria_clientes,
        FK_TITULAR_CONSUMIDOR: this.state.categoria_consumidores,
        FK_TITULAR_CRIANCA: this.state.categoria_crianca,
        FK_TITULAR_OUTROS: this.state.outra_categoria,
        FK_TITULAR_FUNCIONARIO: this.state.categoria_funcionarios,
        FK_TITULAR_PACIENTE: this.state.categoria_paciente,
        FK_TITULAR_PRESTADOR: this.state.categoria_prestador,
        FK_TITULAR_USUARIO: this.state.categoria_usuarios,
        NOTIFICANTE_EMAIL: this.state.notificante_email,
        NOTIFICANTE_NOME: this.state.notificante_nome,
        NOTIFICANTE_TELEFONE: this.state.notificante_telefone,
        OBS_AGENTE__RELATORIO_IMPACTO: this.state.agente_relatorio_impacto,
        OBS_COMUNICACAO_CONTROLADOR: this.state.comunicacao_controlador,
        OBS_COMUNICACAO_FORA_PRAZO: this.state.motivos_nao_comunicacao,
        OBS_CONHECIMENTO_INCIDENTE: this.state.como_ciencia,
        OBS_INCIDENTE_NAO_IMEDIATO: this.state.comunicacao_afetados,
        OBS_INCIDENTE_SEGUGRANCA: this.state.descricao_incidente,
        OBS_MEDIDAS: this.state.medidas_seguranca,
        OBS_TRANSFRONTEIRA: this.state.transfronteira,
        OBS_CONSEQUENCIA_TITULAR: this.state.consequencia_titular,
        OBS_MEDIDAS_ADOTADAS: this.state.medidas_adotadas,
        OBS_MEDIDAS_CIENCIA: this.state.medidas_ciencia,
        OBS_NATUREZA: this.state.outra_natureza,
        OBS_SEM_CERTEZA: this.state.justificativa_incidente,
        OBS_TITULAR_NAO_INFORMADO: this.state.comunicacao_afetados,
        OBS_SEM_CONHECIMENTO: this.state.justificativa_incidente,
        OBS_TITULAR: this.state.comunicacao_titular,
        QTD_TITULARES_AFETADOS: this.state.quantidade,
        USUARIO: myApp.auth().currentUser.email,
        EMPRESA: this.state.company.cnpj,
        qtde_titulares: this.state.qtde_titulares ? this.state.qtde_titulares : '',
        possui_encarregado: this.state.possui_encarregado ? this.state.possui_encarregado : '',
        proprio_encarregado_lgpd: this.state.proprio_encarregado_lgpd ? this.state.proprio_encarregado_lgpd : '',
        obs_notificante: this.state.obs_notificante ? this.state.obs_notificante : '',
        quando_comunicou_titu: this.state.quando_comunicou_titu ? this.state.quando_comunicou_titu : '',
        jus_comunicacao_anpd: this.state.jus_comunicacao_anpd ? this.state.jus_comunicacao_anpd : '',
        jus_quando_e_quais_autoridades: this.state.jus_quando_e_quais_autoridades ? this.state.jus_quando_e_quais_autoridades : '',
        comunicacao_aos_titulares_sim: this.state.comunicacao_aos_titulares_sim ? this.state.comunicacao_aos_titulares_sim : '',
        comunicacao_aos_titulares_nao_processo_esta_em_andamento: this.state.comunicacao_aos_titulares_nao_processo_esta_em_andamento ? this.state.comunicacao_aos_titulares_nao_processo_esta_em_andamento : false,
        comunicacao_aos_titulares_nao_risco_ou_dano: this.state.comunicacao_aos_titulares_nao_risco_ou_dano ? this.state.comunicacao_aos_titulares_nao_risco_ou_dano : false,
        comunicacao_aos_titulares_nao_vez_se_c_preliminar: this.state.comunicacao_aos_titulares_nao_vez_se_c_preliminar ? this.state.comunicacao_aos_titulares_nao_vez_se_c_preliminar : false,
        filiacao: this.state.filiacao ? this.state.filiacao : false,
        dado_sexual: this.state.dado_sexual ? this.state.dado_sexual : false,
        jus_nao_comunicacao_aos_titulares: this.state.jus_nao_comunicacao_aos_titulares ? this.state.jus_nao_comunicacao_aos_titulares : '',
        que_medidas_foram_adotadas: this.state.que_medidas_foram_adotadas ? this.state.que_medidas_foram_adotadas : '',
        confidencialidade: this.state.confidencialidade ? this.state.confidencialidade : false,
        integridade: this.state.integridade ? this.state.integridade : false,
        disponibilidade: this.state.disponibilidade ? this.state.disponibilidade : '',
        outra_categoria: this.state.outra_categoria ? this.state.outra_categoria : '',
        squestro_dados: this.state.squestro_dados ? this.state.squestro_dados : false,
        exploracao_vunerabolidade: this.state.exploracao_vunerabolidade ? this.state.exploracao_vunerabolidade : false,
        roubo_engenharia_social: this.state.roubo_engenharia_social ? this.state.roubo_engenharia_social : false,
        publi_n_internacional: this.state.publi_n_internacional ? this.state.publi_n_internacional : false,
        envio_dados_incorretos: this.state.envio_dados_incorretos ? this.state.envio_dados_incorretos : false,
        neg_ddos: this.state.neg_ddos ? this.state.neg_ddos : false,
        perda_roube_docs: this.state.perda_roube_docs ? this.state.perda_roube_docs : false,
        falha_eqp: this.state.falha_eqp ? this.state.falha_eqp : false,
        outro_tipo_inc_ciber: this.state.outro_tipo_inc_ciber ? this.state.outro_tipo_inc_ciber : false,
        seq_dados: this.state.seq_dados ? this.state.seq_dados : false,
        virus_pc: this.state.virus_pc ? this.state.virus_pc : false,
        violacao_bruteForce: this.state.violacao_bruteForce ? this.state.violacao_bruteForce : false,
        divulga_inde_pess: this.state.divulga_inde_pess ? this.state.divulga_inde_pess : false,
        aces_indev_sis: this.state.aces_indev_sis ? this.state.aces_indev_sis : false,
        alt_exc_dados: this.state.alt_exc_dados ? this.state.alt_exc_dados : false,
        des_inc_doc_ele: this.state.des_inc_doc_ele ? this.state.des_inc_doc_ele : false,
        falha_sis_inf: this.state.falha_sis_inf ? this.state.falha_sis_inf : false,
        falha_inc_cib: this.state.falha_inc_cib ? this.state.falha_inc_cib : false,
        exp_porque_ocorreu: this.state.exp_porque_ocorreu ? this.state.exp_porque_ocorreu : '',
        qtde_total_titulares_afetados: this.state.qtde_total_titulares_afetados ? this.state.qtde_total_titulares_afetados : '',
        total_titulares_afetados_2: this.state.total_titulares_afetados_2 ? this.state.total_titulares_afetados_2 : '',
        criancas_e_adolecentes: this.state.criancas_e_adolecentes ? this.state.criancas_e_adolecentes : '',
        outros_titu_vul: this.state.outros_titu_vul ? this.state.outros_titu_vul : '',
        des_cat_de_titu_vul_afetados: this.state.des_cat_de_titu_vul_afetados ? this.state.des_cat_de_titu_vul_afetados : '',
        ckb_funcionarios: this.state.ckb_funcionarios ? this.state.ckb_funcionarios : false,
        ckb_clientes_cidadaos: this.state.ckb_clientes_cidadaos ? this.state.ckb_clientes_cidadaos : false,
        ckb_paciente_de_servico_saude: this.state.ckb_paciente_de_servico_saude ? this.state.ckb_paciente_de_servico_saude : false,
        ckb_prestadores_servico: this.state.ckb_prestadores_servico ? this.state.ckb_prestadores_servico : false,
        ckb_usuarios: this.state.ckb_usuarios ? this.state.ckb_usuarios : false,
        ckb_ainda_n_identificados: this.state.ckb_ainda_n_identificados ? this.state.ckb_ainda_n_identificados : false,
        ckb_estudantes_alunos: this.state.ckb_estudantes_alunos ? this.state.ckb_estudantes_alunos : false,
        ckb_inscritos_filiados: this.state.ckb_inscritos_filiados ? this.state.ckb_inscritos_filiados : false,
        ckb_outros: this.state.ckb_outros ? this.state.ckb_outros : false,
        informe_quantitativo_titu_afetados: this.state.informe_quantitativo_titu_afetados ? this.state.informe_quantitativo_titu_afetados : '',
        ckb_dados_morais: this.state.ckb_dados_morais ? this.state.ckb_dados_morais : false,
        ckb_discriminacao_social: this.state.ckb_discriminacao_social ? this.state.ckb_discriminacao_social : false,
        ckb_eng_social_fraude: this.state.ckb_eng_social_fraude ? this.state.ckb_eng_social_fraude : false,
        ckb_rest_direitos: this.state.ckb_rest_direitos ? this.state.ckb_rest_direitos : false,
        ckb_danos_materiais: this.state.ckb_danos_materiais ? this.state.ckb_danos_materiais : false,
        ckb_danos_reputacionais: this.state.ckb_danos_reputacionais ? this.state.ckb_danos_reputacionais : false,
        ckb_limitacao_de_acesso_servico: this.state.ckb_limitacao_de_acesso_servico ? this.state.ckb_limitacao_de_acesso_servico : false,
        ckb_perda_de_acesso_a_dados: this.state.ckb_perda_de_acesso_a_dados ? this.state.ckb_perda_de_acesso_a_dados : false,
        ckb_violacao_int_fisica: this.state.ckb_violacao_int_fisica ? this.state.ckb_violacao_int_fisica : false,
        ckb_roubo_identidade: this.state.ckb_roubo_identidade ? this.state.ckb_roubo_identidade : false,
        ckb_exposicao_dados_prof_legal: this.state.ckb_exposicao_dados_prof_legal ? this.state.ckb_exposicao_dados_prof_legal : false,
        cbk_outro_dois: this.state.cbk_outro_dois ? this.state.cbk_outro_dois : false,
        descreva_prov_conse_inc: this.state.descreva_prov_conse_inc ? this.state.descreva_prov_conse_inc : '',
        ckb_podem_sofrer_danos: this.state.ckb_podem_sofrer_danos ? this.state.ckb_podem_sofrer_danos : '',
        ckb_podem_sofrer_danos_supraveis: this.state.ckb_podem_sofrer_danos_supraveis ? this.state.ckb_podem_sofrer_danos_supraveis : false,
        ckb_podem_sofrer_danos_supraveis_com_dificuldade: this.state.ckb_podem_sofrer_danos_supraveis_com_dificuldade ? this.state.ckb_podem_sofrer_danos_supraveis_com_dificuldade : false,
        cbk_podem_sofrer_lesao: this.state.cbk_podem_sofrer_lesao ? this.state.cbk_podem_sofrer_lesao : false,
        secabivel_descreva_medidas: this.state.secabivel_descreva_medidas ? this.state.secabivel_descreva_medidas : false,
        ckb_sim: this.state.ckb_sim ? this.state.ckb_sim : false,
        ckb_nao: this.state.ckb_nao ? this.state.ckb_nao : false,
        se_cabivel_indique: this.state.se_cabivel_indique ? this.state.se_cabivel_indique : '',
        status_incidente: this.state.status_incidente ? this.state.status_incidente : '',
        filiacao: this.state.filiacao ? this.state.filiacao : false,
      }).then(() => {
        this.clearData();
        this.setState({ showSuccess: true, openDialog: false });
        this.getDados('');
      });
    } else {
      console.log('Ação cancelada.');
    }
  }

  async loadCompany() {
    let company = {};
    let companyAreas = [];
    try {
      let companySnapshot = await database
        .collection("companies")
        .doc(this.state.companyId)
        .get();

      let getCompanyAreas = await database.collection("areas").where("fk_companies", "==", localStorage.getItem("currentCompany")).get();
      for (let item of getCompanyAreas.docs) {
        companyAreas.push(item.data());
      }

      company = companySnapshot.data();
    } catch (error) {
      console.error("Loading company: ", error);
    }

    let titulares = [];

    try {
      let get = await database
        .collection("holders")
        .where("in_company", "==", localStorage.getItem('currentCompany'))
        .get();
      for (let item of get.docs) {
        titulares.push(item.data())
      }
    } catch (e) { console.log(e) }


    this.setState({
      company,
      companyAreas: companyAreas,
      titulares,
    });
  }

  handleClose() {
    this.setState({ openDialog: false });
  }

  handleDialog() {
    this.clearData();
    if (this.state.cisAlertEmail === '' || this.state.cisAlertQtdDIas === '') {
      this.setState({ cisNotConfigured: true });
    } else {
      this.setState({ openDialog: true, dialogMode: 'new' });
    }
  }

  openDialog() {
    this.loadData();
    this.setState({ openDialog: true });
  }

  openFile(item) {
    this.props.setObj(item);
    this.props.changePage(34);
  }

  handleCloseSnack1 = () => {
    this.setState({ showSuccess: false, showError: false });
  };

  handleCloseSnack2 = () => {
    this.setState({ showError: false, });
  };

  async handleGetCep() {
    try {
      let get = await fetch(`https://viacep.com.br/ws/${this.state.agente_cep}/json/`);
      let resp = await get.json();
      this.setState({
        agente_endereco: resp.logradouro,
        agente_cidade: resp.localidade,
        agente_estado: resp.uf
      });
    } catch (errorGetCep) {
      console.error('errorGetCep', errorGetCep);
    }
  }

  async openDialogConfigureAlert(callType) {
    let getInfo = await database.collection("cisParameterization").where("fk_company", "==", localStorage.getItem('currentCompany')).get()
    if (getInfo.docs.length === 0) {
      database.collection("cisParameterization").add({
        fk_company: localStorage.getItem('currentCompany'),
        email: '',
        qtdDias: '',
      }).then(() => {
      })
    } else {
      this.setState({
        cisAlertEmail: getInfo.docs[0].data().email,
        cisAlertQtdDIas: getInfo.docs[0].data().qtdDias,
        cisParameterizationId: getInfo.docs[0].id,
      })
    }
    if (callType === '1') {
      this.setState({ configureAlert: true })
    }
  }

  updateCisParameterization() {
    database.collection("cisParameterization").doc(this.state.cisParameterizationId).update({
      email: this.state.cisAlertEmail,
      qtdDias: this.state.cisAlertQtdDIas
    }).then(() => {
      this.setState({
        configureAlert: false
      });
    });
  }

  handleAddTitularSelecionado() {
    let aux = this.state.titulares_envolvidos;

    aux.push({
      name: this.state.titular_selecionado
    });

    this.setState({
      titulares_envolvidos: aux
    });
  }

  handleDeleteTitularSelecionado(index) {
    let aux = this.state.titulares_envolvidos;
    aux.splice(index, 1);
    this.setState({
      titulares_envolvidos: aux
    });
  }

  handleAddDadosPessoaisTratados() {
    let aux = this.state.dados_pessoais_tratados_lista;

    aux.push({
      name: this.state.dados_pessoais_tratados_item
    });

    this.setState({
      dados_pessoais_tratados_lista: aux
    });
  }

  handleDeleteDadosPessoais(index) {
    let aux = this.state.dados_pessoais_tratados_lista;
    aux.splice(index, 1);
    this.setState({
      dados_pessoais_tratados_lista: aux
    });
  }

  handleAddDadosPessoaisSensiveisTratados() {
    let aux = this.state.dados_pessoais_sensiveis_tratados_tratados_lista;

    aux.push({
      name: this.state.dados_pessoais_sensiveis_tratados_tratados_item
    });

    this.setState({
      dados_pessoais_sensiveis_tratados_tratados_lista: aux
    });
  }

  handleDeleteDadosPessoaisSenviveis(index) {
    let aux = this.state.dados_pessoais_sensiveis_tratados_tratados_lista;
    aux.splice(index, 1);
    this.setState({
      dados_pessoais_sensiveis_tratados_tratados_lista: aux
    });
  }

  handleAddFontesOrigin() {
    let aux = this.state.fontes_origin_dos_dados_lista;

    aux.push({
      name: this.state.fontes_origin_dos_dados_item
    });

    this.setState({
      fontes_origin_dos_dados_lista: aux
    });
  }

  handleDeleteFontesOrigin(index) {
    let aux = this.state.fontes_origin_dos_dados_lista;
    aux.splice(index, 1);
    this.setState({
      fontes_origin_dos_dados_lista: aux
    });
  }

  handleAddLocaisArmazenamento() {
    let aux = this.state.locais_armazenamento_lista;

    aux.push({
      name: this.state.locais_armazenamento_item
    });

    this.setState({
      locais_armazenamento_lista: aux
    });
  }

  handleDeleteLocaisArmazenamento(index) {
    let aux = this.state.locais_armazenamento_lista;
    aux.splice(index, 1);
    this.setState({
      locais_armazenamento_lista: aux
    });
  }

  handleAddTransferenciaInterna() {
    let aux = this.state.transferencia_interna_lista;

    aux.push({
      name: this.state.transferencia_interna_item
    });

    this.setState({
      transferencia_interna_lista: aux
    });
  }

  handleDeleteTransferenciaInterna(index) {
    let aux = this.state.transferencia_interna_lista;
    aux.splice(index, 1);
    this.setState({
      transferencia_interna_lista: aux
    });
  }

  handleAddDemaisAreas() {
    let aux = this.state.demais_areas_lista;

    aux.push({
      name: this.state.demais_areas_item
    });

    this.setState({
      demais_areas_lista: aux
    });
  }

  handleDeleteDemaisAreas(index) {
    let aux = this.state.demais_areas_lista;
    aux.splice(index, 1);
    this.setState({
      demais_areas_lista: aux
    });
  }

  handleAddTransferenciaExterna() {
    let aux = this.state.transferencia_externa_lista;

    aux.push({
      name: this.state.transferencia_externa_item
    });

    this.setState({
      transferencia_externa_lista: aux
    });
  }

  handleDeleteTransferenciaExterna(index) {
    let aux = this.state.transferencia_externa_lista;
    aux.splice(index, 1);
    this.setState({
      transferencia_externa_lista: aux
    });
  }

  handleAddMedidasSeg() {
    let aux = this.state.medidas_seg_lista;

    aux.push({
      name: this.state.medidas_seg_item
    });

    this.setState({
      medidas_seg_lista: aux
    });
  }

  handleDeleteMedidasSeg(index) {
    let aux = this.state.medidas_seg_lista;
    aux.splice(index, 1);
    this.setState({
      medidas_seg_lista: aux
    });
  }

  handleAddTomadaDecisao() {
    let aux = this.state.tomada_decisao_lista;

    aux.push({
      name: this.state.tomada_decisao_item
    });

    this.setState({
      tomada_decisao_lista: aux
    });
  }

  handleDeleteTomadaDecisao(index) {
    let aux = this.state.tomada_decisao_lista;
    aux.splice(index, 1);
    this.setState({
      tomada_decisao_lista: aux
    });
  }

  handleAddDocSolicitado() {
    let aux = this.state.doc_solicitados_entrevistado_lista;

    aux.push({
      name: this.state.doc_solicitados_entrevistado_item
    });

    this.setState({
      tomada_decisao_lista: aux
    });
  }

  handleDeleteDocSolicitado(index) {
    let aux = this.state.doc_solicitados_entrevistado_lista;
    aux.splice(index, 1);
    this.setState({
      doc_solicitados_entrevistado_lista: aux
    });
  }

  handleOpenAlertModal(item) {
    switch (item) {
      case 'v':
        this.setState({
          noticeUnderMaintenance: true,
          msgNoticeUnderMaintenance: 'Visualizar comunicação de incidentes em desenvolvimento: em breve, você poderá acompanhar tudo de forma mais clara e fácil'
        });
        break;
      case 'b':
        this.setState({
          noticeUnderMaintenance: true,
          msgNoticeUnderMaintenance: 'Baixar PDF de comunicação de incidentes em desenvolvimento: em breve, você poderá ter acesso offline a todas as informações necessárias'
        });
        break;
      case 'a':
        this.setState({
          noticeUnderMaintenance: true,
          msgNoticeUnderMaintenance: 'Abrir comunicação de incidentes complementar em desenvolvimento: em breve, você terá uma opção adicional para fornecer detalhes importantes'
        });
        break;
    }
  }

  handleSetColor(dt) {
    const currentDate = new Date();
    const creationDate = new Date(dt.seconds * 1000);
    const diffTime = currentDate.getTime() - creationDate.getTime();
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays >= 25) {
      return '#f00';
    } else if (diffDays >= 15) {
      return '#ff0';
    } else if (diffDays >= 0) {
      return '#0f0';
    }

    return '';
  }




  render() {
    return (
      <div>
        <Title>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Grid item>Comunicação de incidentes</Grid>
            <Grid
              item
            >
              <Button
                variant="text"
                style={{
                  fontSize: '15px',
                }}
                onClick={() => this.openDialogConfigureAlert('1')}
              >
                <SettingsIcon />
              </Button>
            </Grid>
          </Grid>
        </Title>

        <Paper>
          <Grid
            container
            justifyContent="space-between"
            style={{
              width: '100%',
              height: 80,
              marginTop: 10,
            }}
          >
            <Grid
              item
              container
              alignItems='center'
              xs={11}
              md={11}
              style={{
                paddingLeft: 10
              }}
            >
              <Grid
                item
                container
                alignItems='center'
                xs={3}
                md={3}
                style={{
                }}
              >
                <TextField
                  fullWidth
                  label="Busca por área"
                  variant="outlined"
                  value={this.state.filterArea}
                  onChange={(event) => { this.setState({ filterArea: event.target.value }) }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="start"
                        style={{
                          cursor: 'pointer'
                        }}
                        onClick={() => { this.setState({ filterArea: '' }) }}
                      >
                        {this.state.filterArea === '' ? <></> : <CloseIcon />}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={1}
              md={1}
              container
              justifyContent="center"
              alignItems='center'
            >
              <FilterListIcon style={{ fontSize: 35, color: theme.secondary_color }} />
            </Grid>
          </Grid>
        </Paper>
        <Snackbar
          open={this.state.showSuccess}
          autoHideDuration={2000}
          onClose={this.handleCloseSnack1}
        >
          <Alert
            onClose={this.handleCloseSnack1}
            severity="success"
            sx={{ width: "100%" }}
          >
            Registro Salvo com Sucesso!!
          </Alert>
        </Snackbar>
        <Snackbar
          open={this.state.showError}
          autoHideDuration={2000}
          onClose={this.handleCloseSnack2}
        >
          <Alert
            onClose={this.handleCloseSnack2}
            severity="error"
            sx={{ width: "100%" }}
          >
            {this.state.erroMessage}
          </Alert>
        </Snackbar>
        <Dialog
          open={this.state.openDialog}
          maxWidth="xl"
          fullWidth
          onClose={() => this.handleClose()}
        >
          <DialogTitle>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-between"
            >
              <Grid item>
                <Typography>
                  Adicionar Incidente
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={() => this.handleClose()}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <DialogContent>
              <DialogContentText>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="center"
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" component="subtitle1" style={{ color: theme.tertiary_color, textAlign: "center", }}>
                      Informações sobre o incidente
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: 40 }}>
                    <FormControl fullWidth>
                      <InputLabel variant="outlined" >Porte da empresa</InputLabel>
                      <Select
                        value={this.state.companySize}
                        label="Porte da empresa"
                        onChange={(evt) => this.setState({ companySize: evt.target.value })}
                      >
                        <MenuItem value={'Pequeno'}>Pequeno</MenuItem>
                        <MenuItem value={'Médio'}>Médio</MenuItem>
                        <MenuItem value={'Grande'}>Grande</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: 40 }}>
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%" }}
                    >
                      <InputLabel>Área</InputLabel>
                      <Select
                        defaultValue=""
                        value={this.state.area || ""}
                        onChange={(evt) => this.setState({ area: evt.target.value })}
                        label="Área"
                      >
                        <MenuItem value="">Selecione</MenuItem>
                        {
                          this.state.companyAreas.length > 0 ?
                            this.state.companyAreas.map((item, index) => (
                              <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                            ))
                            :
                            <MenuItem value="">Sem áreas cadastradas</MenuItem>
                        }
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} style={{ marginBottom: 40 }}>
                    <TextField
                      label="Informe o número aproximado de titulares cujos dados são tratados por sua organização"
                      placeholder="Informe o número aproximado de titulares cujos dados são tratados por sua organização"
                      variant="outlined"
                      fullWidth
                      value={this.state.qtde_titulares}
                      onChange={(e) => this.setState({ qtde_titulares: e.target.value })}
                      type="number"
                    />
                  </Grid>


                  <Grid item xs={12}>
                    <Typography variant="subtitle1" component="subtitle1" style={{ color: theme.tertiary_color, textAlign: "center", }}>
                      Comunicação
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%" }}
                    >
                      <InputLabel>Tipo de comunicação</InputLabel>
                      <Select
                        defaultValue=""
                        value={this.state.tipo_comunicao || ""}
                        onChange={(evt) => {
                          this.setState({ tipo_comunicao: evt.target.value });
                          if (evt.target.value == 'Completa') {
                            this.setState({ showParcial: false });
                          }
                          else {
                            this.setState({ showParcial: true });
                          }
                        }}
                        label="Tipo de comunicação"
                      >
                        <MenuItem value="">Selecione</MenuItem>
                        <MenuItem value={'Completa'}>{'Completa'}</MenuItem>
                        <MenuItem value={'Parcial'}>{'Parcial'}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%", }}
                    >
                      <InputLabel>Critério para a comunicação</InputLabel>
                      <Select
                        defaultValue=""
                        value={this.state.criterio_comunicacao || ""}
                        onChange={(evt) => this.setState({ criterio_comunicacao: evt.target.value })}
                        label="Critério para a comunicação"
                      >
                        <MenuItem value="">Selecione</MenuItem>
                        <MenuItem value={'O incidente de segurança pode acarretar risco ou dano relevante aos titulares'}>{'O incidente de segurança pode acarretar risco ou dano relevante aos titulares'}</MenuItem>
                        <MenuItem value={'Não tenho certeza sobre o nível de risco do incidente de segurança'}>{'Não tenho certeza sobre o nível de risco do incidente de segurança'}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 60 }}>
                    <Typography variant="subtitle1" component="subtitle1" style={{ color: theme.tertiary_color, textAlign: "center", }}>
                      Agente de Tratamento
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%", }}
                    >
                      <InputLabel>O notificante é</InputLabel>
                      <Select
                        defaultValue=""
                        label="O notificante é"
                        value={this.state.tipo_agente || ""}
                        onChange={(evt) => this.setState({ tipo_agente: evt.target.value })}
                      >
                        <MenuItem value="">Selecione</MenuItem>
                        <MenuItem value={'Controlador'}>{'Controlador'}</MenuItem>
                        <MenuItem value={'Operador'}>{'Operador'}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 60 }}>
                    <Typography variant="subtitle1" component="subtitle1" style={{ color: theme.tertiary_color, textAlign: "center", }}>
                      Dados do agente de tratamento
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Número de CPF ou CNPJ"
                      placeholder="Digite o número de CPF ou CNPJ"
                      variant="outlined"
                      fullWidth
                      value={this.state.cpf || ''}
                      onChange={(evt) => this.setState({ cpf: evt.target.value })}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      label="Nome ou razão social"
                      placeholder="Digite o nome ou razão social"
                      value={this.state.nome_agente || ''}
                      onChange={(evt) => this.setState({ nome_agente: evt.target.value })}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%", }}
                    >
                      <InputLabel>Natureza da organização</InputLabel>
                      <Select
                        defaultValue=""
                        label="Natureza da organização"
                        value={this.state.natureza_operacao || ""}
                        onChange={(evt) => this.setState({ natureza_operacao: evt.target.value })}
                      >
                        <MenuItem value="">Selecione</MenuItem>
                        <MenuItem value={'Público'}>{'Público'}</MenuItem>
                        <MenuItem value={'Privado'}>{'Privado'}</MenuItem>

                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Telefone"
                      placeholder="Digite o telefone"
                      value={this.state.agente_telefone || ''}
                      onChange={(evt) => this.setState({ agente_telefone: evt.target.value })}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="E-mail"
                      placeholder="Digite o E-mail"
                      value={this.state.agente_email || ''}
                      onChange={(evt) => this.setState({ agente_email: evt.target.value })}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      label="CEP"
                      value={this.state.agente_cep || ''}
                      onChange={(evt) => this.setState({ agente_cep: evt.target.value })}
                      onBlur={() => this.handleGetCep()}
                      placeholder="Digite o CEP"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      disabled
                      label="Endereço"
                      value={this.state.agente_endereco}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Cidade"
                      disabled
                      value={this.state.agente_cidade}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      label="Estado"
                      disabled
                      value={this.state.agente_estado}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} style={{ marginBottom: 40 }}>
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%" }}
                    >
                      <InputLabel>Possui um encarregado pela proteção de dados pessoais?"</InputLabel>
                      <Select
                        defaultValue=""
                        value={this.state.possui_encarregado}
                        onChange={(e) => this.setState({ possui_encarregado: e.target.value })}
                        label="Possui um encarregado pela proteção de dados pessoais?"
                      >
                        <MenuItem value=""></MenuItem>
                        <MenuItem value="s">Sim</MenuItem>
                        <MenuItem value="n">Não</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>


                  <Grid item xs={12} style={{ marginTop: 60 }}>
                    <Typography variant="subtitle1" component="subtitle1" style={{ color: theme.tertiary_color, textAlign: "center", }}>
                      Dados do notificante
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Nome"
                      placeholder="Digite o nome"
                      value={this.state.notificante_nome || ''}
                      onChange={(evt) => this.setState({ notificante_nome: evt.target.value })}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="E-mail"
                      value={this.state.notificante_email || ''}
                      onChange={(evt) => this.setState({ notificante_email: evt.target.value })}
                      placeholder="Digite o E-mail"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Telefone"
                      value={this.state.notificante_telefone || ''}
                      onChange={(evt) => this.setState({ notificante_telefone: evt.target.value })}
                      placeholder="Digite o telefone"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} style={{ marginBottom: 40 }}>
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%" }}
                    >
                      <InputLabel>O próprio encarregado pela proteção de dados?</InputLabel>
                      <Select
                        defaultValue=""
                        value={this.state.proprio_encarregado_lgpd}
                        onChange={(e) => this.setState({ proprio_encarregado_lgpd: e.target.value })}
                        label="O próprio encarregado pela proteção de dados?"
                      >
                        <MenuItem value=""></MenuItem>
                        <MenuItem value="s">Sim</MenuItem>
                        <MenuItem value="n">Não</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} style={{ marginBottom: 40 }}>
                    <TextField
                      fullWidth
                      multiline
                      minRows={4}
                      label="Outros (especifique):"
                      value={this.state.obs_notificante}
                      onChange={(e) => this.setState({ obs_notificante: e.target.value })}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: 40 }}>
                    <TextField
                      fullWidth
                      label="Quando comunicou aos titulares"
                      variant="outlined"
                      value={this.state.quando_comunicou_titu}
                      onChange={(e) => this.setState({ quando_comunicou_titu: e.target.value })}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: 40 }}>
                    <TextField
                      fullWidth
                      multiline
                      minRows={4}
                      label="Justifique, se cabível, a não realização da comunicação completa à ANPD e aos titulares de dados afetados no prazo sugerido de 2 (dois) dias úteis após a ciência do incidente"
                      variant="outlined"
                      value={this.state.jus_comunicacao_anpd}
                      onChange={(e) => this.setState({ jus_comunicacao_anpd: e.target.value })}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: 40 }}>
                    <TextField
                      fullWidth
                      multiline
                      minRows={4}
                      label="Se cabível, informe quando e a quais outras autoridades o incidente foi comunicado"
                      variant="outlined"
                      value={this.state.jus_quando_e_quais_autoridades}
                      onChange={(e) => this.setState({ jus_quando_e_quais_autoridades: e.target.value })}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 60 }}>
                    <Typography variant="subtitle1" component="subtitle1" style={{ color: theme.tertiary_color, textAlign: "center", }}>
                      Dados do encarregado
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Nome"
                      placeholder="Digite o nome"
                      value={this.state.encarregado_nome || ''}
                      onChange={(evt) => this.setState({ encarregado_nome: evt.target.value })}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="E-mail"
                      placeholder="Digite o E-mail"
                      value={this.state.encarregado_email || ''}
                      onChange={(evt) => this.setState({ encarregado_email: evt.target.value })}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Telefone"
                      value={this.state.encarregado_telefone || ''}
                      onChange={(evt) => this.setState({ encarregado_telefone: evt.target.value })}
                      placeholder="Digite o telefone"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 60 }}>
                    <Typography variant="subtitle1" component="subtitle1" style={{ color: theme.tertiary_color, textAlign: "center", }}>
                      Incidente de segurança
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Descrição do incidente"
                      placeholder="Descreva de forma resumida o incidente de segurança com dados pessoais que ocorreu"
                      variant="outlined"
                      value={this.state.descricao_incidente || ''}
                      onChange={(evt) => this.setState({ descricao_incidente: evt.target.value })}
                      fullWidth
                      multiline={true}
                      rows={10}
                      maxRows={10}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.seiData}
                              onChange={(event) => {
                                this.setState({
                                  seiData: event.target.checked,
                                });
                              }}
                            />
                          }
                          label={'Sei quando ocorreu o incidente'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  {!!this.state.seiData &&
                    <Grid item xs={12}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ width: '100%' }}>
                        <InputLabel>Quando o incidente ocorreu?</InputLabel>
                        <KeyboardDateTimePicker format="dd/MM/yyyy HH:mm" style={{ width: '400px' }} value={this.state.data_incidente} onChange={(evt) => this.setState({ data_incidente: evt })} />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  }
                  {!this.state.seiData &&
                    <>
                      <Grid item xs={12}>
                        <FormControl
                          variant="outlined"
                          style={{ width: "100%", }}
                        >
                          <InputLabel>Tipo incidente</InputLabel>
                          <Select
                            defaultValue=""
                            label="Tipo do Incidente"
                            value={this.state.tipo_incidente || ""}
                            onChange={(evt) => this.setState({ tipo_incidente: evt.target.value })}
                          >
                            <MenuItem value="">Selecione</MenuItem>
                            <MenuItem value={'Não tenho conhecimento'}>{'Não tenho conhecimento'}</MenuItem>
                            <MenuItem value={'Não tenho certeza'}>{'Não tenho certeza'}</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Justifique"
                          value={this.state.justificativa_incidente || ''}
                          onChange={(evt) => this.setState({ justificativa_incidente: evt.target.value })}
                          placeholder="Justifique"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </>
                  }
                  <Grid item xs={12} style={{ marginTop: 40 }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ width: '100%' }}>
                      <InputLabel>Quando tomou ciência?</InputLabel>
                      <KeyboardDateTimePicker format="dd/MM/yyyy HH:mm" style={{ width: '100%' }} value={this.state.dataCienciaIncidente} onChange={(evt) => this.setState({ dataCienciaIncidente: evt })} />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 40 }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ width: '100%' }}>
                      <InputLabel>Quando comunicou a ANPD?</InputLabel>
                      <KeyboardDateTimePicker format="dd/MM/yyyy HH:mm" style={{ width: '100%' }} value={ this.state.dataCominicaANPD} onChange={(evt) => this.setState({ dataCominicaANPD: evt })} />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Como a organização teve ciência do incidente de segurança?"
                      placeholder="Descreva como a organização teve ciência do incidente de segurança"
                      variant="outlined"
                      value={this.state.como_ciencia || ''}
                      onChange={(evt) => this.setState({ como_ciencia: evt.target.value })}
                      fullWidth
                      multiline={true}
                      rows={10}
                      maxRows={10}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Se a comunicação inicial do incidente não foi comunicada no prazo sugerido de 2 dias úteis após ter tomado ciência do incidiente, justifique os motivos"
                      placeholder="Justificativa"
                      variant="outlined"
                      value={this.state.motivos_nao_comunicacao || ''}
                      onChange={(evt) => this.setState({ motivos_nao_comunicacao: evt.target.value })}
                      fullWidth
                      multiline={true}
                      rows={10}
                      maxRows={10}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} style={{ marginTop: 60 }}>
                    <Typography variant="subtitle1" component="subtitle1" style={{ color: theme.tertiary_color, textAlign: "center", }}>
                      Da comunicação do incidente aos titulares dos dados
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 60 }}>
                    <Typography variant="subtitle1" component="subtitle1" style={{ color: theme.tertiary_color, textAlign: "center", }}>
                      Os titulares dos dados afetados foram comunicados sobre o incidente?
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.comunicacao_aos_titulares_sim)}
                              onChange={(event) => {
                                this.setState({
                                  comunicacao_aos_titulares_sim: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Sim'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.comunicacao_aos_titulares_nao_processo_esta_em_andamento)}
                              onChange={(event) => {
                                this.setState({
                                  comunicacao_aos_titulares_nao_processo_esta_em_andamento: event.target.checked
                                });
                              }}
                            />
                          }
                          label={' Não, mas o processo de comunicação está em andamento.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.comunicacao_aos_titulares_nao_risco_ou_dano)}
                              onChange={(event) => {
                                this.setState({
                                  comunicacao_aos_titulares_nao_risco_ou_dano: event.target.checked
                                });
                              }}
                            />
                          }
                          label={' Não, por não haver risco ou dano relevante a eles.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.comunicacao_aos_titulares_nao_vez_se_c_preliminar)}
                              onChange={(event) => {
                                this.setState({
                                  comunicacao_aos_titulares_nao_vez_se_c_preliminar: event.target.checked
                                });
                              }}
                            />
                          }
                          label={' Não, vez que o risco do incidente ainda está sendo apurado. (comunicação preliminar)'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} style={{ marginTop: 60 }}>
                    <Typography variant="subtitle1" component="subtitle1" style={{ color: theme.tertiary_color, textAlign: "center", }}>
                      Natureza dos dados afetados
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.origem_racial)}
                              onChange={(event) => {
                                this.setState({
                                  origem_racial: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Origem racial ou étnica'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.civiccao_religiosa}
                              onChange={(event) => {
                                this.setState({
                                  civiccao_religiosa: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Convicção Religiosa'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.opiniao_politica}
                              onChange={(event) => {
                                this.setState({
                                  opiniao_politica: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Opinião política'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.sindicato}
                              onChange={(event) => {
                                this.setState({
                                  sindicato: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Filiação a sindicato'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.filiacao}
                              onChange={(event) => {
                                this.setState({
                                  filiacao: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Filiação a organização de caráter religioso, filosófico ou político'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.dado_saude}
                              onChange={(event) => {
                                this.setState({
                                  dado_saude: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Dado referente a saúde'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.dado_sexual}
                              onChange={(event) => {
                                this.setState({
                                  dado_sexual: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Dado referente a vida sexual'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.dado_biometrico}
                              onChange={(event) => {
                                this.setState({
                                  dado_biometrico: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Dado genético ou biomético'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.dado_identidade}
                              onChange={(event) => {
                                this.setState({
                                  dado_identidade: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Dado de comprovação de identidade oficial (por exemplo, número RG, CPF, CNH)'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.dado_financeiro}
                              onChange={(event) => {
                                this.setState({
                                  dado_financeiro: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Dado Financeiro'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.dado_usuario}
                              onChange={(event) => {
                                this.setState({
                                  dado_usuario: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Nomes de usuário ou senhas de sistemas de informação'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.dado_geo}
                              onChange={(event) => {
                                this.setState({
                                  dado_geo: event.target.checked
                                });
                              }}

                            />
                          }
                          label={'Dados de geolocalização'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Outros"
                      placeholder="Preencha caso tenha outra natureza"
                      variant="outlined"
                      value={this.state.outra_natureza || ''}
                      onChange={(evt) => this.setState({ outra_natureza: evt.target.value })}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 40 }}>
                    <TextField
                      label="Quantos titulares foram comunicados individualmente sobre o incidente?"
                      placeholder="Preencha a quantidade"
                      value={this.state.quantidade || ''}
                      onChange={(evt) => this.setState({ quantidade: evt.target.value.replace(/\D/, '') })}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 40 }}>
                    <TextField
                      label="Justifique, se cabível, o que motivou a não realização da comunicação individual aos titulares:"
                      value={this.state.jus_nao_comunicacao_aos_titulares}
                      onChange={(evt) => this.setState({ jus_nao_comunicacao_aos_titulares: evt.target.value })}
                      variant="outlined"
                      fullWidth
                      multiline
                      minRows={4}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 40 }}>
                    <TextField
                      label="Que medidas foram adotadas para corrigir as causas do incidente?"
                      placeholder="Que medidas foram adotadas para corrigir as causas do incidente?"
                      value={this.state.que_medidas_foram_adotadas}
                      onChange={(evt) => this.setState({ que_medidas_foram_adotadas: evt.target.value })}
                      variant="outlined"
                      fullWidth
                      multiline
                      minRows={4}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 60 }}>
                    <Typography variant="subtitle1" component="subtitle1" style={{ color: theme.tertiary_color, textAlign: "center", }}>
                      De que forma o incidente afetou os dados pessoais (admite mais de uma marcação):
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.confidencialidade}
                              onChange={(event) => {
                                this.setState({
                                  confidencialidade: event.target.checked
                                });
                              }}

                            />
                          }
                          label={'Confidencialidade'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.integridade}
                              onChange={(event) => {
                                this.setState({
                                  integridade: event.target.checked
                                });
                              }}

                            />
                          }
                          label={' Integridade'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.disponibilidade}
                              onChange={(event) => {
                                this.setState({
                                  disponibilidade: event.target.checked
                                });
                              }}

                            />
                          }
                          label={'Disponibilidade'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} style={{ marginTop: 60 }}>
                    <Typography variant="subtitle1" component="subtitle1" style={{ color: theme.tertiary_color, textAlign: "center", }}>
                      Qual a categoria dos titulares afetados?
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.categoria_funcionarios}
                              onChange={(event) => {
                                this.setState({
                                  categoria_funcionarios: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Funcionários'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.categoria_prestador}
                              onChange={(event) => {
                                this.setState({
                                  categoria_prestador: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Prestadores de serviço'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.categoria_clientes}
                              onChange={(event) => {
                                this.setState({
                                  categoria_clientes: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Clientes'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.categoria_consumidores}
                              onChange={(event) => {
                                this.setState({
                                  categoria_consumidores: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Consumidores'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.categoria_usuarios}
                              onChange={(event) => {
                                this.setState({
                                  categoria_usuarios: event.target.checked
                                });
                              }}

                            />
                          }
                          label={'Usuários'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.categoria_paciente}
                              onChange={(event) => {
                                this.setState({
                                  categoria_paciente: event.target.checked
                                });
                              }}

                            />
                          }
                          label={'Pacientes de serviço de saúde'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.categoria_crianca}
                              onChange={(event) => {
                                this.setState({
                                  categoria_crianca: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Crianças ou adolescentes'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Outros"
                      value={this.state.outra_categoria}
                      onChange={(evt) => this.setState({ outra_categoria: evt.target.value })}
                      placeholder="Preencha caso tenha outra categoria"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 60 }}>
                    <Typography variant="subtitle1" component="subtitle1" style={{ color: theme.tertiary_color, textAlign: "center", }}>
                      Medidas de segurança utilizadas para proteção de dados
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Quais medidas de segurança, técnicas e administrativas, foram tomadas para prevenir a ocorrência do incidente de segurança?"
                      placeholder="Descreva"
                      value={this.state.medidas_seguranca || ''}
                      onChange={(evt) => this.setState({ medidas_seguranca: evt.target.value })}
                      variant="outlined"
                      fullWidth
                      multiline={true}
                      rows={10}
                      maxRows={10}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Quais medidas de segurança, técnicas e administrativas, foram tomadas após a ciência do incidente de segurança?"
                      placeholder="Descreva"
                      variant="outlined"
                      value={this.state.medidas_ciencia || ''}
                      onChange={(evt) => this.setState({ medidas_ciencia: evt.target.value })}
                      fullWidth
                      multiline={true}
                      rows={10}
                      maxRows={10}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Quais medidas de segurança, técnicas e administrativas, foram ou serão adotadas para reverter ou mitigar os efeitos do prejuízo do incidente de segurança aos titulares dos dados?"
                      placeholder="Descreva"
                      variant="outlined"
                      value={this.state.medidas_adotadas || ''}
                      onChange={(evt) => this.setState({ medidas_adotadas: evt.target.value })}
                      fullWidth
                      multiline={true}
                      rows={10}
                      maxRows={10}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="O agente de tratamento realizou relatório de impacto à proteção de dados pessoais?"
                      placeholder="Descreva"
                      value={this.state.agente_relatorio_impacto || ''}
                      onChange={(evt) => this.setState({ agente_relatorio_impacto: evt.target.value })}
                      variant="outlined"
                      fullWidth
                      multiline={true}
                      rows={2}
                      maxRows={2}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 60 }}>
                    <Typography variant="subtitle1" component="subtitle1" style={{ color: theme.tertiary_color, textAlign: "center", }}>
                      Riscos relacionados ao incidente de segurança
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Quais as prováveis consequências do incidente de segurança para os titulares afetados?"
                      placeholder="Descreva"
                      value={this.state.consequencia_titular || ''}
                      onChange={(evt) => this.setState({ consequencia_titular: evt.target.value })}
                      variant="outlined"
                      fullWidth
                      multiline={true}
                      rows={10}
                      maxRows={10}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Considerando os titulares afetados, na sua avaliação, o incidente pode trazer consequências transfronteiriças?"
                      placeholder="Descreva"
                      value={this.state.transfronteira || ''}
                      onChange={(evt) => this.setState({ transfronteira: evt.target.value })}
                      variant="outlined"
                      fullWidth
                      multiline={true}
                      rows={10}
                      maxRows={10}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 60 }}>
                    <Typography variant="subtitle1" component="subtitle1" style={{ color: theme.tertiary_color, textAlign: "center", }}>
                      Comunicação aos titulares de dados
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%", }}
                    >
                      <InputLabel>Os titulares foram comunicados sobre o incidente de segurança com dados pessoais? </InputLabel>
                      <Select
                        defaultValue=""
                        label="Os titulares foram comunicados sobre o incidente de segurança com dados pessoais?"
                        value={this.state.tipo_comunicao_titular || ""}
                        onChange={(evt) => this.setState({ tipo_comunicao_titular: evt.target.value })}
                      >
                        <MenuItem value="">Selecione</MenuItem>
                        <MenuItem value={'Sim'}>{'Sim'}</MenuItem>
                        <MenuItem value={'Não'}>{'Não'}</MenuItem>
                        <MenuItem value={'Não sei'}>{'Não sei'}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Forneça detalhes"
                      placeholder="Descreva detalhes sobre a comunicação"
                      value={this.state.comunicacao_titular || ''}
                      onChange={(evt) => this.setState({ comunicacao_titular: evt.target.value })}
                      variant="outlined"
                      fullWidth
                      multiline={true}
                      rows={10}
                      maxRows={10}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Caso os titulares afetados não tenham sido informados, quais são os motivos que justificam a não comunicação ou o seu retardo?"
                      placeholder="Descreva"
                      value={this.state.comunicacao_afetados || ''}
                      onChange={(evt) => this.setState({ comunicacao_afetados: evt.target.value })}
                      variant="outlined"
                      fullWidth
                      multiline={true}
                      rows={10}
                      maxRows={10}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} style={{ marginTop: 60 }}>
                    <Typography variant="subtitle1" component="subtitle1" style={{ color: theme.tertiary_color, textAlign: "center", }}>
                      Descrição do Incidente
                    </Typography>
                  </Grid>

                  <Grid item xs={12} style={{ marginTop: 60 }}>
                    <Typography variant="subtitle1" component="subtitle1" style={{ color: theme.tertiary_color, textAlign: "center", }}>
                      <spam style={{ fontWeight: 'bold' }} > Qual o tipo de incidente? (Informe o tipo mais específico)</spam>
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.squestro_dados)}
                              onChange={(event) => {
                                this.setState({
                                  squestro_dados: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Sequestro de dados (ransomware) sem transferência de informações.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.exploracao_vunerabolidade)}
                              onChange={(event) => {
                                this.setState({
                                  exploracao_vunerabolidade: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Exploração de vulnerabilidade em sistemas de informação.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.roubo_engenharia_social)}
                              onChange={(event) => {
                                this.setState({
                                  roubo_engenharia_social: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Roubo de credenciais / Engenharia social.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.publi_n_internacional)}
                              onChange={(event) => {
                                this.setState({
                                  publi_n_internacional: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Publicação não intencional de dados pessoais.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.envio_dados_incorretos)}
                              onChange={(event) => {
                                this.setState({
                                  envio_dados_incorretos: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Envio de dados a destinatário incorreto..'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.neg_ddos)}
                              onChange={(event) => {
                                this.setState({
                                  neg_ddos: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Negação de serviço (DoS).'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.perda_roube_docs)}
                              onChange={(event) => {
                                this.setState({
                                  perda_roube_docs: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Perda/roubo de documentos ou dispositivos eletrônicos.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.falha_eqp)}
                              onChange={(event) => {
                                this.setState({
                                  falha_eqp: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Falha em equipamento (hardware).'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.outro_tipo_inc_ciber)}
                              onChange={(event) => {
                                this.setState({
                                  outro_tipo_inc_ciber: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Outro tipo de incidente cibernético. (especifique abaixo)'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.seq_dados)}
                              onChange={(event) => {
                                this.setState({
                                  seq_dados: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Sequestro de dados (ransomware) com transferência e/ou publicação de informações.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.virus_pc)}
                              onChange={(event) => {
                                this.setState({
                                  virus_pc: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Vírus de computador / malware.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.violacao_bruteForce)}
                              onChange={(event) => {
                                this.setState({
                                  violacao_bruteForce: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Violação de credencial por força bruta.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.divulga_inde_pess)}
                              onChange={(event) => {
                                this.setState({
                                  divulga_inde_pess: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Divulgação indevida de dados pessoais.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.aces_indev_sis)}
                              onChange={(event) => {
                                this.setState({
                                  aces_indev_sis: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Acesso não autorizado a sistemas de informação.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.alt_exc_dados)}
                              onChange={(event) => {
                                this.setState({
                                  alt_exc_dados: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Alteração/exclusão não autorizada de dados.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.des_inc_doc_ele)}
                              onChange={(event) => {
                                this.setState({
                                  des_inc_doc_ele: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Descarte incorreto de documentos ou dispositivos eletrônicos.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.falha_sis_inf)}
                              onChange={(event) => {
                                this.setState({
                                  falha_sis_inf: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Falha em sistema de informação (software).'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.falha_inc_cib)}
                              onChange={(event) => {
                                this.setState({
                                  falha_inc_cib: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Outro tipo de incidente não cibernético. (especifique abaixo)'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Descreva, resumidamente, como ocorreu o incidente:"
                      value={this.state.des_como_ocorreu || ''}
                      onChange={(evt) => this.setState({ des_como_ocorreu: evt.target.value })}
                      variant="outlined"
                      fullWidth
                      multiline={true}
                      minRows={4}
                      margin="normal"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Explique, resumidamente, por que o incidente ocorreu (identifique a causa raiz, se conhecida):"
                      value={this.state.exp_porque_ocorreu}
                      onChange={(evt) => this.setState({ exp_porque_ocorreu: evt.target.value })}
                      variant="outlined"
                      fullWidth
                      multiline={true}
                      minRows={4}
                      margin="normal"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Qual o número total de titulares cujos dados são tratados nas atividades afetadas pelo incidente?"
                      value={this.state.qtde_total_titulares_afetados}
                      onChange={(evt) => this.setState({ qtde_total_titulares_afetados: evt.target.value })}
                      variant="outlined"
                      fullWidth
                      type='number'
                      margin="normal"
                    />
                  </Grid>

                  <Grid item xs={12} style={{ marginTop: 60 }}>
                    <Typography variant="subtitle1" component="subtitle1" style={{ color: theme.tertiary_color, textAlign: "center", }}>
                      <spam style={{ fontWeight: 'bold' }} >Qual a quantidade aproximada de titulares afetados  pelo incidente?</spam>
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Total de titulares afetados"
                      placeholder="Total de titulares afetados"
                      value={this.state.total_titulares_afetados_2}
                      onChange={(evt) => this.setState({ total_titulares_afetados_2: evt.target.value })}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Crianças e/ou adolescentes"
                      placeholder="Crianças e/ou adolescentes"
                      value={this.state.criancas_e_adolecentes}
                      onChange={(evt) => this.setState({ criancas_e_adolecentes: evt.target.value })}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Outros titulares vulneráveis"
                      placeholder="Outros titulares vulneráveis"
                      value={this.state.outros_titu_vul}
                      onChange={(evt) => this.setState({ outros_titu_vul: evt.target.value })}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Se aplicável, descreva as categorias de titulares vulneráveis afetados:"
                      placeholder="Se aplicável, descreva as categorias de titulares vulneráveis afetados:"
                      value={this.state.des_cat_de_titu_vul_afetados}
                      onChange={(evt) => this.setState({ des_cat_de_titu_vul_afetados: evt.target.value })}
                      variant="outlined"
                      fullWidth
                      multiline={true}
                      minRows={4}
                      margin="normal"
                    />
                  </Grid>

                  <Grid item xs={12} style={{ marginTop: 60 }}>
                    <Typography variant="subtitle1" component="subtitle1" style={{ color: theme.tertiary_color, textAlign: "center", }}>
                      <spam style={{ fontWeight: 'bold' }} > Quais a categorias de titulares foram afetadas pelo incidente? (admite mais de uma marcação)</spam>
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.ckb_funcionarios}
                              onChange={(event) => {
                                this.setState({
                                  ckb_funcionarios: event.target.checked,
                                });
                              }}
                            />
                          }
                          label={'Funcionários'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.ckb_clientes_cidadaos}
                              onChange={(event) => {
                                this.setState({
                                  ckb_clientes_cidadaos: event.target.checked,
                                });
                              }}
                            />
                          }
                          label={'Clientes / cidadãos.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.ckb_paciente_de_servico_saude}
                              onChange={(event) => {
                                this.setState({
                                  ckb_paciente_de_servico_saude: event.target.checked,
                                });
                              }}
                            />
                          }
                          label={'Pacientes de serviço de saúde.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.ckb_prestadores_servico}
                              onChange={(event) => {
                                this.setState({
                                  ckb_prestadores_servico: event.target.checked,
                                });
                              }}
                            />
                          }
                          label={'Prestadores de serviços..'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.ckb_usuarios}
                              onChange={(event) => {
                                this.setState({
                                  ckb_usuarios: event.target.checked,
                                });
                              }}
                            />
                          }
                          label={'Usuários.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.ckb_ainda_n_identificados}
                              onChange={(event) => {
                                this.setState({
                                  ckb_ainda_n_identificados: event.target.checked,
                                });
                              }}
                            />
                          }
                          label={'Ainda não identificadas.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.ckb_estudantes_alunos}
                              onChange={(event) => {
                                this.setState({
                                  ckb_estudantes_alunos: event.target.checked,
                                });
                              }}
                            />
                          }
                          label={'Estudantes / alunos.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.ckb_inscritos_filiados}
                              onChange={(event) => {
                                this.setState({
                                  ckb_inscritos_filiados: event.target.checked,
                                });
                              }}
                            />
                          }
                          label={'Inscritos / filiados.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.ckb_outros}
                              onChange={(event) => {
                                this.setState({
                                  ckb_outros: event.target.checked,
                                });
                              }}
                            />
                          }
                          label={'Outros. (especifique abaixo)'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} style={{ marginBottom: 40 }}>
                    <TextField
                      fullWidth
                      multiline
                      minRows={4}
                      label="Informe o quantitativo de titulares afetados, por categoria:"
                      placeholder="Informe o quantitativo de titulares afetados, por categoria:"
                      value={this.state.informe_quantitativo_titu_afetados}
                      onChange={(e) => this.setState({ informe_quantitativo_titu_afetados: e.target.value })}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} style={{ marginTop: 60 }}>
                    <Typography variant="subtitle1" component="subtitle1" style={{ color: theme.tertiary_color, textAlign: "center", }}>
                      <spam style={{ fontWeight: 'bold' }} > Quais as prováveis consequências do incidente para os titulares? (admite mais de uma marcação)</spam>
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.ckb_dados_morais)}
                              onChange={(event) => {
                                this.setState({
                                  ckb_dados_morais: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Danos morais.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.ckb_discriminacao_social)}
                              onChange={(event) => {
                                this.setState({
                                  ckb_discriminacao_social: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Discriminação social.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.ckb_eng_social_fraude)}
                              onChange={(event) => {
                                this.setState({
                                  ckb_eng_social_fraude: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Engenharia social / fraudes.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.ckb_rest_direitos)}
                              onChange={(event) => {
                                this.setState({
                                  ckb_rest_direitos: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Restrições de direitos.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.ckb_danos_materiais)}
                              onChange={(event) => {
                                this.setState({
                                  ckb_danos_materiais: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Danos materiais.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.ckb_danos_reputacionais)}
                              onChange={(event) => {
                                this.setState({
                                  ckb_danos_reputacionais: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Danos reputacionais.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.ckb_limitacao_de_acesso_servico)}
                              onChange={(event) => {
                                this.setState({
                                  ckb_limitacao_de_acesso_servico: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Limitação de acesso a um serviço..'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.ckb_perda_de_acesso_a_dados)}
                              onChange={(event) => {
                                this.setState({
                                  ckb_perda_de_acesso_a_dados: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Perda de acesso a dados pessoais.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.ckb_violacao_int_fisica)}
                              onChange={(event) => {
                                this.setState({
                                  ckb_violacao_int_fisica: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Violação à integridade física'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.ckb_roubo_identidade)}
                              onChange={(event) => {
                                this.setState({
                                  ckb_roubo_identidade: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Roubo de identidade.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.ckb_exposicao_dados_prof_legal)}
                              onChange={(event) => {
                                this.setState({
                                  ckb_exposicao_dados_prof_legal: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Exposição de dados protegidos por sigilo profissional/legal.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.cbk_outro_dois)}
                              onChange={(event) => {
                                this.setState({
                                  cbk_outro_dois: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Outros (especifique abaixo).'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Se cabível, descreva as prováveis consequências do incidente para cada grupo de titulares: "
                      placeholder="Se cabível, descreva as prováveis consequências do incidente para cada grupo de titulares: "
                      variant="outlined"
                      value={this.state.descreva_prov_conse_inc}
                      onChange={(evt) => this.setState({ descreva_prov_conse_inc: evt.target.value })}
                      fullWidth
                      multiline={true}
                      minRows={4}
                      margin="normal"
                    />
                  </Grid>

                  <Grid item xs={12} style={{ marginTop: 60 }}>
                    <Typography variant="subtitle1" component="subtitle1" style={{ color: theme.tertiary_color, textAlign: "center", }}>
                      <spam style={{ fontWeight: 'bold' }} >Qual o provável impacto do incidente sobre os titulares? (admite só uma marcação)</spam>
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.ckb_podem_sofrer_danos)}
                              onChange={(event) => {
                                this.setState({
                                  ckb_podem_sofrer_danos: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Podem não sofrer danos, sofrer danos negligenciáveis ou superáveis sem dificuldade.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.ckb_podem_sofrer_danos_supraveis)}
                              onChange={(event) => {
                                this.setState({
                                  ckb_podem_sofrer_danos_supraveis: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Podem sofrer danos, superáveis com certa dificuldade.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.ckb_podem_sofrer_danos_supraveis_com_dificuldade)}
                              onChange={(event) => {
                                this.setState({
                                  ckb_podem_sofrer_danos_supraveis_com_dificuldade: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Podem sofrer danos importantes, superáveis com muita dificuldade.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.cbk_podem_sofrer_lesao)}
                              onChange={(event) => {
                                this.setState({
                                  cbk_podem_sofrer_lesao: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Podem sofrer lesão ou ofensa a direitos ou interesses difusos, coletivos ou individuais, que, dadas as circunstâncias, ocasionam ou tem potencial para ocasionar dano significativo ou irreversível.'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Se cabível, descreva as medidas de segurança adicionais adotadas após o incidente:"
                      placeholder="Se cabível, descreva as medidas de segurança adicionais adotadas após o incidente:"
                      variant="outlined"
                      value={this.state.secabivel_descreva_medidas}
                      onChange={(evt) => this.setState({ secabivel_descreva_medidas: evt.target.value })}
                      fullWidth
                      multiline={true}
                      minRows={4}
                      margin="normal"
                    />
                  </Grid>

                  <Grid item xs={12} style={{ marginTop: 60 }}>
                    <Typography variant="subtitle1" component="subtitle1" style={{ color: theme.tertiary_color, textAlign: "center", }}>
                      <spam style={{ fontWeight: 'bold' }} >As atividades de tratamento de dados afetadas estão submetidas a regulações de segurança setoriais?</spam>
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.ckb_sim)}
                              onChange={(event) => {
                                this.setState({
                                  ckb_sim: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Sim'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(this.state.ckb_nao)}
                              onChange={(event) => {
                                this.setState({
                                  ckb_nao: event.target.checked
                                });
                              }}
                            />
                          }
                          label={'Não'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Se cabível, indique as regulamentações setoriais de segurança aplicáveis às atividades de tratamento de dados afetadas pelo incidente:"
                      placeholder="Se cabível, indique as regulamentações setoriais de segurança aplicáveis às atividades de tratamento de dados afetadas pelo incidente:"
                      variant="outlined"
                      value={this.state.se_cabivel_indique}
                      onChange={(evt) => this.setState({ se_cabivel_indique: evt.target.value })}
                      fullWidth
                      multiline={true}
                      minRows={4}
                      margin="normal"
                    />
                  </Grid>

                  {/* {!!(this.state.dialogMode == 'edit') &&
                    <Grid item xs={12} >
                      <FormControl
                        variant="outlined"
                        style={{
                          width: "100%",
                        }}
                      >
                        <InputLabel>Status</InputLabel>
                        <Select
                          defaultValue=""
                          label="Status"
                          value={this.state.status_incidente || ""}
                          onChange={(evt) => this.setState({ status_incidente: evt.target.value })}
                        >
                          <MenuItem value="Empresa">Selecione</MenuItem>
                          <MenuItem value={'Pendente'}>{'Pendente'}</MenuItem>
                          <MenuItem value={'Aprovado'}>{'Aprovado'}</MenuItem>

                        </Select>
                      </FormControl>
                    </Grid>
                  } */}
                  {!!(this.state.dialogMode == 'new') &&
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        style={{
                          background: this.state.addQuizButtonDisabled ? theme.opacity : theme.primary_color,
                          color: theme.secondary_color
                        }}
                        disabled={this.state.addQuizButtonDisabled}
                        onClick={() => this.saveData()}
                      >
                        Adicionar
                      </Button>
                    </Grid>
                  }
                  {(this.state.dialogMode == 'edit') &&
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        style={{
                          background: this.state.addQuizButtonDisabled ? theme.opacity : theme.primary_color,
                          color: theme.secondary_color
                        }}
                        disabled={this.state.addQuizButtonDisabled}
                        onClick={() => this.editData()}
                      >
                        Atualizar
                      </Button>
                    </Grid>
                  }
                </Grid>
              </DialogContentText>
            </DialogContent>
          </DialogContent>
        </Dialog>

        <Dialog
          fullWidth
          open={this.state.cisAlert}
          onClose={() => this.setState({ cisAlert: false })}
        >
          <DialogTitle
          >
            <Typography
              variant="h4"
            >
              Atenção!
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography
              variant="pre"
            >
              Você esta abrindo uma comunicação de incidente preliminar do tipo <strong>parcial</strong>,
              você tem 30 dias corridos apartir da data de hoje para abrir uma solicitação
              complementar, você receberá um alerta em {this.state.cisAlertQtdDIas} dias no email: {this.state.cisAlertEmail}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              style={{
                backgroundColor: 'rgb(56, 242, 5)'
              }}
              onClick={() => this.setState({ cisAlert: false })}
              autoFocus
            >
              Ok!
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth
          open={this.state.cisNotConfigured}
          onClose={() => this.setState({ cisNotConfigured: false })}
        >
          <DialogTitle
          >
            <Typography
              variant="h4"
            >
              Atenção!
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography
              variant="pre"
            >
              Antes de iniciar uma comunicação de incidentes, gostaríamos de convidá-lo a parametrizar seu endereço de email para receber alertas sobre essas comunicações.

              Para isso, basta clicar na engrenagem no canto superior direito da pagina
              Insira seu endereço de email e a quantidade de dias que deseja receber o alerta.
              Deste modo, você estará sempre atualizado sobre as comunicações de incidentes.
              Agradecemos sua atenção e colaboração.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              style={{
                backgroundColor: 'rgb(56, 242, 5)'
              }}
              onClick={() => this.setState({ cisNotConfigured: false })}
              autoFocus
            >
              Ok!
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth
          open={this.state.configureAlert}
          onClose={() => this.setState({ configureAlert: false })}
        >
          <DialogTitle
          >
            <Typography
              variant="h5"
            >
              Parametrização comunicação de incidestes
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="column"
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={12}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  xs={3}
                  sm={3}
                >
                  <Typography
                    variant="h5"
                  >
                    E-mail:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={9}
                  sm={9}
                >
                  <TextField
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={this.state.cisAlertEmail}
                    onChange={(event) => this.setState({ cisAlertEmail: event.target.value })}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  xs={6}
                  sm={6}
                >
                  <Typography
                    variant="h5"
                  >
                    Dias para o alerta:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                >
                  <Select
                    value={this.state.cisAlertQtdDIas}
                    onChange={(event) => this.setState({ cisAlertQtdDIas: event.target.value })}
                    variant="outlined"
                    style={{
                      width: '50%',
                      marginTop: 15,
                      width: '100%'
                    }}
                  >
                    <MenuItem value="15">15 dias</MenuItem>
                    <MenuItem value="20">20 dias</MenuItem>
                    <MenuItem value="25">25 dias</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              style={{
                backgroundColor: '#f00',
                color: '#fff'
              }}
              onClick={() => this.setState({ configureAlert: false })}
              autoFocus
            >
              Cancelar
            </Button>
            <Button
              style={{
                backgroundColor: 'rgb(56, 242, 5)',
                color: theme.secondary_color
              }}
              onClick={() => this.updateCisParameterization()}
              autoFocus
            >
              Atualizar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth
          open={this.state.noticeUnderMaintenance}
          onClose={() => this.setState({ noticeUnderMaintenance: false })}
        >
          <DialogTitle
          >
            <Typography
              variant="h5"
            >
              Comunicação de Incidentes
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="column"
              spacing={2}
            >
              <Typography
                variant="h6"
              >
                {this.state.msgNoticeUnderMaintenance}
              </Typography>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              style={{
                backgroundColor: 'rgb(56, 242, 5)',
                color: theme.secondary_color
              }}
              onClick={() => this.setState({ noticeUnderMaintenance: false })}
              autoFocus
            >
              Ok!
            </Button>
          </DialogActions>
        </Dialog>

        <Grid
          component={Paper}
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          rowSpacing={2}
          style={{ width: "100%", marginTop: 10 }}
        >
          <ButtonBase
            onClick={() => { this.handleDialog() }}
            style={{
              background: theme.primary_color,
              color: theme.secondary_color,
              width: '70px',
              height: '40px',
              margin: 10,
              borderRadius: 5
            }}
          >
            <Grid item>
              <AddIcon style={{ fontSize: 30, color: "#6A6A6A" }} />
            </Grid>
          </ButtonBase>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            item
            style={{ width: "100%" }}
          >
            {
              this.state.loading === true && this.state.listaIncidentes.length == 0 ?
                <CircularProgress style={{ color: theme.secondary_color }} />
                :
                <TableContainer style={{ maxHeight: "70vh" }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            this.filterOption('area');
                          }}
                        >
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems='center'
                          >
                            Área
                            {this.state.filterOptionsArea == false ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                          </Grid>
                        </TableCell>
                        <TableCell
                          style={{
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            this.filterOption('notificate');
                          }}
                        >
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems='center'
                          >
                            Notificante
                            {this.state.filterOptionsNotificate == false ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                          </Grid>
                        </TableCell>
                        <TableCell
                          style={{
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            this.filterOption('user');
                          }}
                        >
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems='center'
                          >
                            Usuário
                            {this.state.filterOptionsUser == false ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                          </Grid>
                        </TableCell>
                        <TableCell
                          style={{
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            this.filterOption('status');
                          }}
                        >
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems='center'
                          >
                            Tipo
                            {this.state.filterOptionsStatus == false ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                          </Grid>
                        </TableCell>
                        <Tooltip
                          title="Para cominucação parcial existe um prazo de até 30 dias corridos para abrir
                            uma solicitação complementar
                          "
                        >
                          <TableCell align="center" >Prazo</TableCell>
                        </Tooltip>
                        <TableCell align="center" >Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.listaIncidentes.filter(this.handleFilterByArea).map((process, index) => {
                        return (
                          <TableRow style={index % 2 ? { background: "white" } : { background: "#e3e3e3" }}>
                            <TableCell>{process.FK_AREA}</TableCell>
                            <TableCell>{process.NOTIFICANTE_NOME}</TableCell>
                            <TableCell>{process.USUARIO}</TableCell>
                            {/* FK_TIPO_COMUNICACAO_PARCIAL */}
                            <TableCell>
                              {
                                process.FK_TIPO_COMUNICACAO.toLocaleLowerCase() == 'completa' ?
                                  process.FK_TIPO_COMUNICACAO
                                  : process.FK_TIPO_COMUNICACAO
                              }
                            </TableCell>
                            <Tooltip
                              title={
                                `5 dias restantes cor vermelha` +
                                `15 dias restantes cor amarela` +
                                `30 dias restantes cor verde`
                              }
                            >
                              <TableCell style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                {
                                  process.FK_TIPO_COMUNICACAO.toLocaleLowerCase() == 'completa' ?
                                    <div style={{ borderRadius: '50%', backgroundColor: 'transparent', width: 20, height: 20 }} />
                                    : <div style={{ borderRadius: '50%', backgroundColor: this.handleSetColor(process.dataCriacao), width: 20, height: 20 }} ></div>
                                }
                              </TableCell>
                            </Tooltip>
                            <TableCell >
                              <Grid container >
                                <Grid
                                  item
                                  xs={6}
                                  md={6}
                                  container
                                  justifyContent="center"
                                  alignItems="center"
                                  onClick={() => this.loadData(index, '')}
                                // onClick={() => this.handleOpenAlertModal('v')}
                                >
                                  <Tooltip
                                    title={'Editar comunicação de incidente'}
                                  >
                                    <EditIcon
                                      style={{
                                        color: theme.secondary_color,
                                        cursor: 'pointer'
                                      }}
                                    />
                                  </Tooltip>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  md={6}
                                  container
                                  justifyContent="center"
                                  alignItems="center"
                                  onClick={() => this.openFile(process)}
                                // onClick={() => this.handleOpenAlertModal('b')}
                                >
                                  <GetAppIcon
                                    style={{
                                      color: theme.secondary_color,
                                      cursor: 'pointer'
                                    }}
                                  />
                                </Grid>
                                {/* 
                                  *** BOTÃO ABRIA COMPLEMENTAR!!! ***
                                  *** APENAS DESATIVADO, CADO EM 
                                      BREVE PRECISE NOVAMENTE ***

                                <Grid
                                  item
                                  xs={4}
                                  md={4}
                                  container
                                  justifyContent="center"
                                  alignItems="center" //complementar
                                  onClick={process.FK_TIPO_COMUNICACAO.toLowerCase() !== "completa" ? () => this.loadData(index, 'complementar') : () => { }}
                                >
                                  <AddIcon
                                    style={{
                                      color: process.FK_TIPO_COMUNICACAO.toLowerCase() !== "completa" ? theme.secondary_color : 'transparent',
                                      // color: theme.secondary_color,
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Grid> */}
                              </Grid>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
            }
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default connect(null, { changePage, setObj })(Incidentes);