import React, { Component } from 'react';
import myApp from '../../core/infrastructure/firebaseConfig';
import "firebase/compat/firestore";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import { Close } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';

import {
  Accordion, AccordionDetails, AccordionSummary, Button, Checkbox,
  Dialog, DialogContent, Grid, IconButton, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, TextField, Tooltip, Typography
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";

import { addPlans } from '../redux/actions';
import { connect } from 'react-redux';
import { Skeleton } from '@material-ui/lab';
import theme from '../../core/theme.json'

class PlanObject {
  planName = "";
  isChecked = false;
  fkPlanType = "";
  planActivities = [];
}

class ActivyObject {
  name = "";
  owner = "";
  date = null;
  description = "";
  isChecked = false;
  fkPlanType = "";
  fkInitialActivities = "";
}

class CompanyRegistrationPlan extends Component {
  constructor(props) {
    super(props);

    this.db = myApp.firestore();

    this.state = {
      plans: [],

      gapCheckBox: false,

      topicDialog: false,
      topicIndex: -1,

      planTypeId: "",

      topicName: "",
      topicDescription: "",
      topicDialogError: false,
      planActivityIndex: -1,
      loading: false,
      addTopicDialogButtonDisabled: true,
      saveTopicDialogButtonDisabled: false,
      oldTopicName: ""
    }
  }

  componentDidMount() {
    this.setState({
      loading: true
    });

    if (this.props.plans.length > 0) {
      this.setState({
        plans: this.props.plans,
        loading: false
      });
    }
    else {
      this.createPlans();
    }
  }

  async createPlans() {
    let database = myApp.firestore();

    let plans = [];

    try {
      let dataPlanType = await database.collection('plan_type').get();

      //Caso não existam os tipos de planos na base, adiciona os tipos padrão
      if (dataPlanType.empty) {
        var planTypes =
          [
            { name: "Gap Analysis" },
            { name: "Data Mapping" },
            { name: "Plano de Ação" }
          ]
        planTypes.forEach(async planType => await database.collection('plan_type').add(planType))
        dataPlanType = await database.collection('plan_type').get();
      }

      let planType = dataPlanType.docs;

      let dataInitialActivities = await database.collection('initial_activities').get()

      //Caso não existam as atividades iniciais na base, adiciona as atividades padrão
      if (dataInitialActivities.empty) {
        let fkGapAnalysis = planType[0].id
        let fkDataMapping = planType[1].id
        let fkActionPlans = planType[2].id
        var initialActivities =
          [
            //Gap Analisys
            { name: "Criação Comitê e Definição DPO", fk_plan_type: fkGapAnalysis, description: "", companyActivity: true },
            { name: "Desenvolvimento de Políticas de Privacidade", fk_plan_type: fkGapAnalysis, description: "", companyActivity: true },
            { name: "Manual de Boas Práticas LGPD/FAQ", fk_plan_type: fkGapAnalysis, description: "", companyActivity: true },
            { name: "Desenvolvimento Fluxo DSARS - CANAL TITULARES", fk_plan_type: fkGapAnalysis, description: "", companyActivity: true },
            { name: "Inventário de Processos e Sistemas", fk_plan_type: fkGapAnalysis, description: "", companyActivity: true },
            { name: "Quantificação, Qualificação dos Contratos", fk_plan_type: fkGapAnalysis, description: "", companyActivity: true },
            { name: "Desenvolvimento de Políticas de Consentimento de Cookies", fk_plan_type: fkGapAnalysis, description: "", companyActivity: true },
            { name: "Treinamento de Conscientização - Gestores de Áreas", fk_plan_type: fkGapAnalysis, description: "", companyActivity: true },
            { name: "Treinamento de DSARS - CANAL TITULARES", fk_plan_type: fkGapAnalysis, description: "", companyActivity: true },
            { name: "Desenho e Identificação do Fluxo na Plataforma", fk_plan_type: fkGapAnalysis, description: "", companyActivity: true },
            { name: "Treinamento de Toda a Organização", fk_plan_type: fkGapAnalysis, description: "", companyActivity: true },
            { name: "Entrevistas de Mapeamento Áreas", fk_plan_type: fkGapAnalysis, description: "", companyActivity: true },

            //Planos de Ação
            { name: "Entregar os fluxos de processos", fk_plan_type: fkActionPlans, description: "", companyActivity: true },
            { name: "Treinamento Relatório DPIA", fk_plan_type: fkActionPlans, description: "", companyActivity: true },
            { name: "Entrega do Plano de Ação", fk_plan_type: fkActionPlans, description: "", companyActivity: true },
            { name: "Compromisso de Adequação com clientes", fk_plan_type: fkActionPlans, description: "", companyActivity: true },
            { name: "Desenvolvimento DPIA eRooPA (relatórios exigidos pela Lei)", fk_plan_type: fkActionPlans, description: "", companyActivity: true },

            //Data Mapping
            { name: "Políticas de Proteção de Dados Pessoais", fk_plan_type: fkDataMapping, description: "", companyActivity: true },
            { name: "Treinamentos - todos os colaboradores - com políticas definidas", fk_plan_type: fkDataMapping, description: "", companyActivity: true },
            { name: "Adequações Contratuais Fornecedores - com compromisso", fk_plan_type: fkDataMapping, description: "", companyActivity: true },
            { name: "Política de Segurança da Informação", fk_plan_type: fkDataMapping, description: "", companyActivity: true },
          ]

        initialActivities.forEach(async initialActivity => await database.collection('initial_activities').add(initialActivity))
      }

      for (var i = 0; i < planType.length; i++) {
        try {
          var planObject = new PlanObject();

          planObject.planName = planType[i].data().name;
          planObject.isChecked = false;
          planObject.fkPlanType = planType[i].id;

          let dataInitialActivity = await database.collection('initial_activities').where('fk_plan_type', '==', planType[i].id).get();
          let initialActivy = dataInitialActivity.docs;

          for (var j = 0; j < initialActivy.length; j++) {
            try {
              var activyObject = new ActivyObject();

              activyObject.fkPlanType = planType[i].id;
              activyObject.name = initialActivy[j].data().name;
              activyObject.description = initialActivy[j].data().description;
              activyObject.fkInitialActivities = initialActivy[j].id;

              planObject.planActivities.push(activyObject);
            }
            catch (error) {
              console.error(error);
            }
          }
        }
        catch (error) {
          console.error(error);
        }

        plans.push(planObject);
      }
    }
    catch (error) {
      console.error(error);
    }

    this.setState({
      plans: plans,
      loading: false
    }, () => { this.handleRedux() });
  }

  handleCheckAll(event, index) {
    let _plans = this.state.plans;

    _plans[index].isChecked = event.target.checked;
    _plans[index].planActivities.forEach(activy => activy.isChecked = event.target.checked);

    this.setState({
      plans: _plans,
    }, () => { this.handleRedux() })
  }

  handleCheckbox(event, planIndex, activyIndex) {
    let _plans = this.state.plans;

    _plans[planIndex].isChecked = false;
    _plans[planIndex].planActivities[activyIndex].isChecked = event.target.checked;

    this.setState({
      plans: _plans,
    }, () => { this.handleRedux() })
  }

  handleOwner(event, planIndex, activyIndex) {
    let _plans = this.state.plans;

    _plans[planIndex].planActivities[activyIndex].owner = event.target.value;

    this.setState({
      plans: _plans,
    }, () => { this.handleRedux() })
  }

  handleName(event, planIndex, activyIndex) {
    let _plans = this.state.plans;

    _plans[planIndex].planActivities[activyIndex].name = event.target.value;

    this.setState({
      plans: _plans,
    }, () => { this.handleRedux() })
  }

  handleDate(value, planIndex, activyIndex) {
    let _plans = this.state.plans;

    _plans[planIndex].planActivities[activyIndex].date = value;

    this.setState({
      plans: _plans,
    }, () => { this.handleRedux() })
  }

  handleRedux() {
    this.props.addPlans(this.state.plans);
  }

  handleTopicDialog(index, id, name, description) {
    this.setState({
      topicDialog: !this.state.topicDialog,
      topicIndex: index,
      topicName: name,
      topicDescription: description,
      planTypeId: id,
      addTopicDialogButtonDisabled: true,
      topicDialogError: false
    })
  }

  handleEditTopicDialog(index, planTypeId, planActivityIndex, name, description) {
    if (index > -1 && (name === "" || description === "")) {
      this.setState({
        saveTopicDialogButtonDisabled: true
      });
    }
    else {
      this.setState({
        saveTopicDialogButtonDisabled: false
      });
    }

    if (index > -1 && name !== "") {
      this.setState({
        oldTopicName: name
      });
    }

    this.setState({
      topicEditDialog: !this.state.topicEditDialog,
      topicIndex: index,
      topicName: name,
      topicDescription: description,
      planTypeId: planTypeId,
      planActivityIndex: planActivityIndex,
      topicDialogError: false
    });
  }

  handleTopicName(event) {
    this.setState({
      topicName: event.target.value
    });

    if (event.target.value !== "" && this.state.topicDescription !== "") {
      if (this.state.topicDialog) {
        this.setState({
          addTopicDialogButtonDisabled: false
        });
      }
      else {
        this.setState({
          saveTopicDialogButtonDisabled: false
        });
      }
    }
    else {
      if (this.state.topicDialog) {
        this.setState({
          addTopicDialogButtonDisabled: true
        });
      }
      else {
        this.setState({
          saveTopicDialogButtonDisabled: true
        });
      }
    }
  }

  handleTopicDescription(event) {
    this.setState({
      topicDescription: event.target.value
    });

    if (event.target.value !== "" && this.state.topicName !== "") {
      if (this.state.topicDialog) {
        this.setState({
          addTopicDialogButtonDisabled: false
        });
      }
      else {
        this.setState({
          saveTopicDialogButtonDisabled: false
        });
      }
    }
    else {
      if (this.state.topicDialog) {
        this.setState({
          addTopicDialogButtonDisabled: true
        });
      }
      else {
        this.setState({
          saveTopicDialogButtonDisabled: true
        });
      }
    }
  }

  addTopic() {
    var _plans = this.state.plans;

    var activyObject = new ActivyObject();

    activyObject.name = this.state.topicName;
    activyObject.description = this.state.topicDescription;
    activyObject.fkPlanType = this.state.planTypeId;

    if (!_plans[this.state.topicIndex].planActivities.some(pa => pa.name === this.state.topicName)) {
      _plans[this.state.topicIndex].planActivities.push(activyObject);

      this.setState({
        plans: _plans,
        topicDialog: !this.state.topicDialog,
        topicDialogError: false,
        topicDescription: "",
        topicName: "",
        topicIndex: -1,
        planTypeId: "",
        addTopicDialogButtonDisabled: true
      }, () => { this.handleRedux() });
    }
    else {
      this.setState({
        topicDialogError: true
      })
    }
  }

  updateTopic() {
    var _plans = this.state.plans;

    if (this.state.oldTopicName === this.state.topicName) {
      _plans[this.state.topicIndex].planActivities[this.state.planActivityIndex].name = this.state.topicName;
      _plans[this.state.topicIndex].planActivities[this.state.planActivityIndex].description = this.state.topicDescription;

      this.setState({
        plans: _plans,
        topicDialogError: false,
        oldTopicName: ""
      });

      this.handleEditTopicDialog(-1, "", -1, "", "");
    }
    else if (!_plans[this.state.topicIndex].planActivities.some(pa => pa.name === this.state.topicName)) {
      _plans[this.state.topicIndex].planActivities[this.state.planActivityIndex].name = this.state.topicName;
      _plans[this.state.topicIndex].planActivities[this.state.planActivityIndex].description = this.state.topicDescription;

      this.setState({
        plans: _plans,
        topicDialogError: false,
        oldTopicName: ""
      });

      this.handleEditTopicDialog(-1, "", -1, "", "");
    }
    else {
      this.setState({
        topicDialogError: true
      });
    }
  }

  deleteTopic(activityIndex, planIndex) {
    var plans = this.state.plans;
    plans[planIndex].planActivities.splice(activityIndex, 1);

    this.setState({ 
      plans 
    });
  }

  render() {
    return (
      <div>
        {this.state.loading ?
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item style={{ width: "100%" }}>
              <Skeleton variant="rect" width={"100%"} height={220} />
            </Grid>
          </Grid>
          :
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            {this.state.plans.map((plan, planIndex) => (
              <Grid item style={{ width: "100%" }}>
                <Accordion
                  defaultExpanded
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ fontSize: "48px" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ background: "#D9D9D9", borderRadius: "4px 4px 0px 0px" }}
                  >
                    <Typography >{plan.planName}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer>
                      <Table>
                        <colgroup>
                          <col style={{ width: "5%" }} />
                          <col style={{ width: "30%" }} />
                          <col style={{ width: "20%" }} />
                          <col style={{ width: "20%" }} />
                          <col style={{ width: "5%" }} />
                          <col style={{ width: "5%" }} />
                          <col style={{ width: "5%" }} />
                        </colgroup>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <Checkbox
                                checked={plan.isChecked}
                                onChange={(event) => this.handleCheckAll(event, planIndex)}
                              />
                            </TableCell>
                            <TableCell align="left">
                              Atividade
                              <IconButton onClick={() => { this.handleTopicDialog(planIndex, plan.fkPlanType, "", "") }}>
                                <AddCircleIcon style={{ color: theme.primary_color }} />
                              </IconButton>
                            </TableCell>
                            <TableCell align="center">
                              Responsável
                            </TableCell>
                            <TableCell align="center">
                              Previsão de Entrega
                            </TableCell>
                            <TableCell />
                            <TableCell />
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {plan.planActivities.map((activy, activityIndex) => (
                            <TableRow>
                              <TableCell>
                                <Checkbox
                                  checked={activy.isChecked}
                                  onChange={(event) => this.handleCheckbox(event, planIndex, activityIndex)}
                                />
                              </TableCell>
                              <TableCell align="left" style={{ padding: "1.5% 1.9% 0px 0px" }}>
                                <TextField
                                  value={activy.name}
                                  onChange={(event) => { this.handleName(event, planIndex, activityIndex) }}
                                  style={{ width: "105%" }}
                                />
                              </TableCell>
                              <TableCell align="center" >
                                <TextField
                                  label="Responsável"
                                  value={activy.owner}
                                  onChange={(event) => { this.handleOwner(event, planIndex, activityIndex) }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    invalidDateMessage="Data em formato inválido"
                                    minDateMessage="Data não deve ser retroativa"
                                    minDate={Date.now()}
                                    onChange={(value) => { this.handleDate(value, planIndex, activityIndex) }}
                                    value={activy.date}
                                    autoOk={true}
                                  />
                                </MuiPickersUtilsProvider>
                              </TableCell>
                              <TableCell align="right">
                                <Tooltip placement="top" title={activy.description}>
                                  <IconButton>
                                    <InfoIcon />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                              <TableCell align="center">
                                <Tooltip placement="left" title="Editar atividade">
                                  <IconButton
                                    onClick={() => { this.handleEditTopicDialog(planIndex, plan.fkPlanType, activityIndex, activy.name, activy.description) }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                              <TableCell align="center">
                                <Tooltip placement="top" title="Excluir">
                                  <IconButton onClick={() => this.deleteTopic(activityIndex, planIndex)}>
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))}
          </Grid>
        }

        {/* Nova atividade */}
        <Dialog
          open={this.state.topicDialog}
          fullWidth={true}
          onClose={() => { this.handleTopicDialog(-1, "", "", "") }}
        >
          <DialogContent>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="flex-end"
              spacing={4}
            >
              <Grid item container direction="row">
                <Grid item style={{ width: "100%" }} xs={10}>
                  <Typography variant="h5" component="h5" >
                    Nova Atividade
                  </Typography>
                </Grid>
                <Grid item container xs={2} justify="flex-end">
                  <IconButton onClick={() => this.handleTopicDialog(-1, "", "", "")}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item style={{ width: "100%" }}>
                <TextField
                  label="Nome do Tópico"
                  variant="outlined"
                  style={{ width: "100%" }}
                  color={theme.primary_color}
                  inputProps={{ maxLength: 50 }}
                  value={this.state.topicName}
                  onChange={(event) => { this.handleTopicName(event) }}
                />
              </Grid>
              <Grid item style={{ width: "100%" }}>
                <TextField
                  label="Descrição do Tópico"
                  variant="outlined"
                  style={{ width: "100%" }}
                  color={theme.primary_color}
                  multiline
                  rows={4}
                  value={this.state.topicDescription}
                  onChange={(event) => { this.handleTopicDescription(event) }}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  style={{ background: this.state.addTopicDialogButtonDisabled ? "#91ff79" : theme.primary_color, color: theme.secondary_color, fontSize: theme.font_button, fontWeight: 300 }}
                  onClick={() => { this.addTopic() }}
                  disabled={this.state.addTopicDialogButtonDisabled}
                >
                  Adicionar
                </Button>
              </Grid>
              <Grid item >
                <Typography style={{ color: "red", display: this.state.topicDialogError ? "inherit" : "none" }}
                >
                  Já existe uma atividade com este nome
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        {/* Editar atividade */}
        <Dialog
          open={this.state.topicEditDialog}
          fullWidth={true}
          onClose={() => { this.handleEditTopicDialog(-1, "", -1, "", "") }}
        >
          <DialogContent>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="flex-end"
              spacing={4}
            >
              <Grid item container direction="row">
                <Grid item style={{ width: "100%" }} xs={10}>
                  <Typography variant="h5" component="h5" >
                    Editar Atividade
                  </Typography>
                </Grid>
                <Grid item container xs={2} justify="flex-end">
                  <IconButton onClick={() => this.handleEditTopicDialog(-1, "", -1, "", "")}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item style={{ width: "100%" }}>
                <TextField
                  label="Nome do Tópico"
                  variant="outlined"
                  style={{ width: "100%" }}
                  color={theme.primary_color}
                  value={this.state.topicName}
                  onChange={(event) => { this.handleTopicName(event) }}
                />
              </Grid>
              <Grid item style={{ width: "100%" }}>
                <TextField
                  label="Descrição do Tópico"
                  variant="outlined"
                  style={{ width: "100%" }}
                  color={theme.primary_color}
                  multiline
                  rows={4}
                  value={this.state.topicDescription}
                  onChange={(event) => { this.handleTopicDescription(event) }}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  style={{ background: this.state.saveTopicDialogButtonDisabled ? "#91ff79" : theme.primary_color, color: theme.secondary_color, fontSize: theme.font_button, fontWeight: 300 }}
                  onClick={() => { this.updateTopic() }}
                  disabled={this.state.saveTopicDialogButtonDisabled}
                >
                  Salvar
                </Button>
              </Grid>
              <Grid item >
                <Typography style={{ color: "red", display: this.state.topicDialogError ? "inherit" : "none" }}
                >
                  Já existe uma atividade com este nome
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    plans: state.companyReducer.plans,
  };
}

export default connect(mapStateToProps, { addPlans })(CompanyRegistrationPlan);