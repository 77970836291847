import React, { Component } from 'react';
import myApp from '../../../../core/infrastructure/firebaseConfig';
import "firebase/compat/firestore";

import { Paper, Grid, Typography, ButtonBase, Button } from '@material-ui/core';

import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';

import { changePage } from '../../../presentation/redux/actions';
import { setPlan } from '../redux/actions'
import { connect } from 'react-redux';

import theme from '../../../../core/theme.json';

class PlanItem extends Component {
  constructor(props) {
    super(props);

    this.db = myApp.firestore();

    this.state = {
      data: [
        { value: this.props.doneActivities },
        { value: this.props.totalActivities - this.props.doneActivities },
      ]
    }
  }

  toPlanPage(id) {
    if (id === '934') {
      this.props.changePage(8);
    } else if (id === '935'){
      this.props.changePage(16);
    } else if (id === '936'){
      this.props.changePage(20);
    } else {
      this.props.changePage(2);
      this.props.setPlan(id);
    }
  }

  render() {
    return (
      <Grid container>
        <Grid item style={{ width: "100%" }}>
          <ButtonBase
            onClick={() => { this.toPlanPage(this.props.id) }}
            style={{ width: "100%", height: "100%", borderRadius: "8px" }}
          >
            <Paper style={{ width: "100%", height: "100%", borderRadius: "8px", padding: "16px" }}>
              <Grid
                container
                direction="column"
                style={{ height: "100%" }}
              >
                <Grid item style={{ height: "85%" }}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    style={{ height: "100%" }}
                  >
                    <Grid item>
                      <ResponsiveContainer width={160} height={160}>
                        <PieChart>
                          <Pie
                            data={this.state.data}
                            dataKey="value"
                            innerRadius="85%"
                            outerRadius="100%"
                            fill={theme.secondary_color}
                          >
                            <Cell fill={theme.primary_color} />
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    </Grid>
                    <Grid item style={{ marginLeft: "54px" }}>
                      <Grid
                        container
                        direction="column"
                        alignItems="flex-start"
                      >
                        <Grid item>
                          <Typography
                            variant="h6"
                            component="h6"
                            style={{ marginBottom: "8px", fontWeight: "300" }}
                          >
                            {this.props.name}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="h5" component="h5">
                            {this.props.donePercentage.toFixed(2) + "% Concluído"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </ButtonBase>
        </Grid>
      </Grid>
    );
  }
}

export default connect(null, { changePage, setPlan })(PlanItem);