import React, { Component } from 'react'
import {
  Typography,
  Grid,
  Button,
  Select,
  TextField,
  CssBaseline,
  MenuItem,
  FormControl,
  FormHelperText,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Card,
  CardContent,
  Container,
  CardActions,
  CardHeader,
  Zoom,
  Box,
} from '@material-ui/core'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { CheckCircle, Error } from '@material-ui/icons'
import DateFnsUtils from '@date-io/date-fns'
import { ptBR } from 'date-fns/locale'
import myApp from '../../../core/infrastructure/firebaseConfig'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/storage'
import { withRouter } from 'react-router'
import validateCPF from '../../../shared/commands/validations/validateCPF'
import InputMask from 'react-input-mask'
import dashboardLogo from '../../../res/images/dashboard_logo.png'
import { addDays } from '../../../shared/commands/dateFunctions'
import { formatDate } from '../../../shared/commands/formatDate'
import InputFile from '../../../shared/components/InputFile'
import SendVerificationEmailButton from '../../components/SendVerificationEmailButton'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import { green } from '@material-ui/core/colors'
import Swal from 'sweetalert2'
import Log from '../../../shared/commands/log'

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  )
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
}

const TextMaskCustomCPF = (props) => {
  const { inputRef, ...other } = props

  return (
    <InputMask
      {...other}
      ref={inputRef}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
    />
  )
}

const TextMaskCustomPhone = (props) => {
  const { inputRef, ...other } = props

  return (
    <InputMask
      {...other}
      ref={inputRef}
      mask={[
        '(',
        /\d/,
        /\d/,
        ')',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
    />
  )
}


const sendSoliciationEmails = myApp.functions().httpsCallable('sendSoliciationEmailsCompany')

const styles = (theme) => ({
  headerText: {
    textAlign: 'justify',
  },
  footerText: {
    textAlign: 'center',
  },
})

class SolicitationFormCompany extends Component {
  constructor(props) {
    super(props)

    this.state = {
      database: myApp.firestore(),
      storage: myApp.storage(),
      personType: '',
      solicitationType: '',
      companyId: '',
      name: '',
      lastName: '',
      email: '',
      document: '',
      document_proof_file: null,
      phone: '',
      additionalInformation: '',
      birthDate: null,
      status: '',
      personTypes: [
        {
          id: 'eBDU5z0LRVkbIWRQrT1e',
          type: 'Consumidor',
        },
        {
          id: 'DmQR9TIWT0A4yXPzoV4p',
          type: 'Fornecedores / Parceiros',
        },
        {
          id: 'WjJlveSwVWkslsyTA5Hb',
          type: 'Colaborador',
        }
      ],
      solicitationTypes: [
        {
          id: 'cPwvBj34SKhmL83eR7de',
          type: 'Solicitação de correção',
        },
        {
          id: '8aSVq89q5eSeIuGCpx7Z',
          type: 'Solicitação de revogação',
        },
        {
          id: 'MPGZZlP89KUgAMb9TIlP',
          type: 'Solicitação de informações',
        }
      ],
      companies: [],
      companiName: '',
      sendButtonDisabled: true,
      validDocument: true,
      loading: false,
      loadingForm: true,
      errorPage: false,
      errorMessage: '',
      companyImageUrl: '',
      companyGroupData: {},
      consentAccepted: false,
      solicitationSent: false,
      sendingEmailVerification: false,
      waitToSendEmailVerification: false,
      progress: '',
    }
  }

  async componentDidMount() {
    await this.getPersonTypes()
    await this.getSolicitationTypes()
    await this.loadCompanyGroup()
    await this.loadCompanyImage()

    this.setState({
      loadingForm: false,
    })

    let querySnapshot = myApp.firestore().collection("companies").doc(this.props.match.params.companyGroupIdc);
    querySnapshot.get().then((doc) => {
      this.setState({
        companiName: doc.data().nickname,
        companyId: this.props.match.params.companyGroupIdc
      });
    });
    console.log('Quem é vc', this.state.companyGroupData)
  }

  async getPersonTypes(companyId) {
    let personTypes = this.state.personTypes;

    let personTypesData = await this.state.database
      .collection("personTypes")
      .where("in_company", "==", this.props.match.params.companyGroupIdc)
      .get();

    for (let doc in personTypesData.docs) {
      let personType = {
        id: personTypesData.docs[doc].ref.id,
        type: personTypesData.docs[doc].data().type,
      }

      personTypes.push(personType)
    }

    let personTypesDataHolders = await this.state.database
      .collection("holders")
      .where("in_company", "==", this.props.match.params.companyGroupIdc)
      .get();

    for (let doc of personTypesDataHolders.docs) {
      let personType = {
        id: doc.id,
        type: doc.data().name,
      }

      personTypes.push(personType)
    }

    this.setState({
      personTypes,
    })
  }

  async getSolicitationTypes() {
    let solicitationTypes = this.state.solicitationTypes;
    try {
      let solicitationTypesData = await this.state.database
        .collection('solicitationTypes')
        .where('in_company', '==', this.props.match.params.companyGroupIdc)
        .get()

      for (let doc of solicitationTypesData.docs) {
        let solicitationType = {
          id: doc.id,
          type: doc.data().type,
        }

        solicitationTypes.push(solicitationType)
      }
    } catch (error) {
      console.log('Error loading solicitation types: ' + error)
    }

    this.setState({
      solicitationTypes,
    })
  }

  async loadCompanyGroup() {
    try {
      const companyGroupSolicitationSnapshot = await this.state.database
        .collection('solicitationParameters')
        .where('fk_company_group', '==', this.props.match.params.companyGroupIdc)
        .get()

      const parametersData = companyGroupSolicitationSnapshot.docs
        .shift()
        .data()
      this.setState({
        companyGroupData: parametersData,
      })
    } catch (error) {
      console.error('Error loading company group data: ' + error)
    }
  }

  async loadCompanyImage() {
    try {
      const imageRef = this.state.storage.refFromURL(
        this.state.companyGroupData.logoUrl
      )
      const imageUrl = await imageRef.getDownloadURL()

      this.setState({
        companyImageUrl: imageUrl,
      })
    } catch (error) {
      console.error('Error getting company group logo: ' + error)
    }
  }

  handlePersonType(event) {
    this.setState(
      {
        personType: event.target.value,
      },
      () => {
        this.handleSendButton()
      }
    )
  }

  handleSolicitationType(event) {
    this.setState(
      {
        solicitationType: event.target.value,
      },
      () => {
        this.handleSendButton()
      }
    )
  }

  handleCompany(event) {
    this.setState(
      {
        companyId: event.target.value,
      },
      () => {
        this.handleSendButton()
      }
    )
    this.getPersonTypes(event.target.value);
  }

  handleName(event) {
    this.setState(
      {
        name: event.target.value,
      },
      () => {
        this.handleSendButton()
      }
    )
  }

  handleLastName(event) {
    this.setState(
      {
        lastName: event.target.value,
      },
      () => {
        this.handleSendButton()
      }
    )
  }

  handleEmail(event) {
    this.setState(
      {
        email: event.target.value,
      },
      () => {
        this.handleSendButton()
      }
    )
  }

  handleDocument(event) {
    this.setState(
      {
        document: event.target.value,
        validDocument: true,
      },
      () => {
        this.handleSendButton()
      }
    )

    let doc = event.target.value
    doc = doc.replace(/\D/g, '')

    if (doc && doc !== '' && !validateCPF(event.target.value)) {
      this.setState(
        {
          validDocument: false,
        },
        () => this.handleSendButton()
      )
    }
  }

  handlePhone(event) {
    this.setState(
      {
        phone: event.target.value,
      }
    )
  }

  handleAdditionalInformation(event) {
    this.setState(
      {
        additionalInformation: event.target.value,
      }
    )
  }

  handleDocumentProofFile(file) {
    this.setState({
      document_proof_file: file,
    })
  }

  handleBirthDate(value) {
    this.setState(
      {
        birthDate: value,
      },
      () => {
        this.handleSendButton()
      }
    )
  }

  handleConsent() {
    this.setState(
      {
        consentAccepted: !this.state.consentAccepted,
      },
      () => {
        this.handleSendButton()
      }
    )
  }

  handleSendButton() {
    let doc = this.state.document
    doc = doc.replace(/\D/g, '')

    if (
      this.state.personType !== '' &&
      this.state.solicitationType !== '' &&
      this.state.companyId !== '' &&
      this.state.name !== '' &&
      this.state.lastName !== '' &&
      this.state.email !== '' &&
      //doc !== '' &&
      this.state.birthDate &&
      !isNaN(this.state.birthDate.getTime()) &&
      //this.state.validDocument &&
      this.state.consentAccepted
    ) {
      this.setState({
        sendButtonDisabled: false,
      })
    } else {
      this.setState({
        sendButtonDisabled: true,
      })
    }
  }

  async saveSolicitation() {
    this.setState({
      loading: true,
    })

    let data = new Date(Date.now())
    let year = data.getFullYear().toString()

    let monthInt = data.getMonth() + 1
    let month = monthInt.toString()
    if (month.length === 1) {
      month = '0' + month
    }

    let day = data.getDate().toString()
    if (day.length === 1) {
      day = '0' + day
    }

    let random = Math.floor(Math.random() * 10000000 + 1).toString()

    let protocol = year + month + day + random

    try {
      const solicitation = await this.state.database
        .collection('solicitations')
        .add({
          protocol: protocol,
          fk_personType: this.state.personType,
          fk_solicitationType: this.state.solicitationType,
          fk_company: this.state.companyId,
          name: this.state.name,
          lastName: this.state.lastName,
          email: this.state.email,
          document: this.state.document,
          phone: this.state.phone,
          additionalInformation: this.state.additionalInformation,
          birthDate: this.state.birthDate,
          status: 'Aberto',
          done: false,
          response: '',
          createDate: new Date(Date.now()),
          limitDate: addDays(Date.now(), parseInt(this.state.companyGroupData.expectedDate)),
          conclusionDate: null,
        })

      if (this.state.document_proof_file) {
        this.uploadDocumentProofFile(solicitation.id)
      }

      await sendSoliciationEmails({ solicitation: solicitation.id })

      this.setState({
        loading: false,
        solicitationSent: true,
        errorPage: false,
        errorMessage: '',
        sendingEmailVerification: false,
      })
    } catch (error) {
      this.setState({
        loading: false,
        solicitationSent: false,
        errorPage: true,
        errorMessage: error.message,
        sendingEmailVerification: false,
      })
      await Log().error({
        trace: error.trace,
        message: error.message,
        info: {
          state: this.state,
          component: 'solicitationForm'
        }
      })
    }
  }

  //pegando arquivo selecionado
  uploadDocumentProofFile(solicitationId) {
    //pegando o arquivo
    let file = this.state.document_proof_file

    //pegando norme do arquivo
    let fileName = file.name

    //separando nome do arquivo em nome e extensão
    let fileDotsArray = fileName.split('.')

    //.shift() para pegar o primeiro item do array criado pelo split, que é o nome do arquivo
    let firstName = fileDotsArray.shift()

    //.pop() para pegar o ultimo item do array criado pelo split, que é a extensão do arquivo
    let extension = fileDotsArray.pop()

    //pegando a data em mili segundos
    let dateAdded = file.lastModified

    //criando chave de identificação unica para o arquivo, caso o usuario envie dois arquivos com mesmo nome
    let fileNameDb = myApp.database().ref().push().key + '-' + firstName

    //caminho para o Storage
    let filePath = 'solicitations/' + solicitationId + '/' + fileNameDb

    //criando referencia de arquivo
    let storage = myApp.storage().ref(filePath)

    //enviando arquivo
    let upload = storage.put(file)

    this.uploadProgress(upload).then(() => {
      storage.getDownloadURL().then((downloadUrl) => {
        myApp
          .firestore()
          .collection('solicitations')
          .doc(solicitationId)
          .update({
            documentProofFile: {
              dateAdded: dateAdded,
              extension: extension,
              fileName: firstName,
              filePathName: filePath,
              fileUrl: downloadUrl,
            },
          })
      })
    })
  }

  uploadProgress(upload) {
    return new Promise((resolve, reject) => {
      upload.on(
        'state_changed',
        (snapShot) => {
          this.setState({
            progress: (snapShot.bytesTransferred / snapShot.totalBytes) * 100,
          })
        },
        (error) => {
          console.log(error)
          reject(error)
        },
        () => {
          resolve()
        }
      )
    })
  }

  renderHeaderComponents() {
    const { classes } = this.props

    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={3}
      >
        <Grid item></Grid>
        <Grid item>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={6}
          >
            <Grid item>
              <img
                alt="companyGroupImg"
                src={
                  this.state.companyImageUrl
                    ? this.state.companyImageUrl
                    : dashboardLogo
                }
                style={{ height: '42px' }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.headerText}>
          <Typography variant="body1" align="justify" style={{ width: '100%' }}>
            {/*
              'Somos muito sérios com o cumprimento das leis que protegem sua privacidade. Aqui você, de forma facilitada, pode exercer alguns direitos previstos em nossa '
            */}
            {
              this.state.companyGroupData.introductoryText
            }
            <br />
            <a
              href={this.state.companyGroupData.privacyPolicyUrl}
              target="_blank"
              rel="noreferrer"
            >
              Política de privacidade
            </a>
            {/*, como acesso, correção dos dados ou revogação do consentimento.*/}
          </Typography>
          <p></p>
          {/*<Typography variant="body1" align="justify" style={{ width: '100%' }}>
            Para facilitar a validação da sua identidade, pedimos que preencha o
            formulário abaixo usando os mesmos dados informados no seu cadastro.
          </Typography>*/}
        </Grid>
      </Grid>
    )
  }

  renderBodyComponents() {
    if (this.state.loading) {
      return (
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={3}
        >
          <CircularProgress size={60} color="#193993" />
        </Grid>
      )
    } else if (this.state.sendingEmailVerification) {
      return (
        <Zoom in={this.state.sendingEmailVerification} timeout={500}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={3}
          >
            <Grid item>
              <Card>
                <CardHeader
                  title={
                    <Typography
                      variant="h3"
                      component="h3"
                      align="center"
                      style={{ width: '100%' }}
                    >
                      Verificação de e-mail
                    </Typography>
                  }
                />
                <CardContent>
                  <Typography
                    variant="body1"
                    align="center"
                    style={{ width: '100%', color: 'green' }}
                  >
                    Enviamos um e-mail de verificação para {this.state.email},
                    só após confirmá-lo sua ficha de solicitação será enviada.{' '}
                  </Typography>
                  <br />
                  <Typography
                    variant="body1"
                    align="center"
                    style={{ width: '100%' }}
                  >
                    Não recebeu o e-mail?
                  </Typography>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ width: '100%' }}
                  >
                    <Grid item>
                      <SendVerificationEmailButton
                        sendTo={this.state.email}
                        company={this.props.match.params.companyGroupIdc}
                        onEmailVerified={() => {
                          this.saveSolicitation()
                        }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Typography
                    variant="body1"
                    align="center"
                    style={{
                      width: '100%',
                      marginTop: '20px',
                      display: 'block',
                    }}
                  >
                    Esta janela será automaticamente atualizada após a
                    verificação do e-mail.
                  </Typography>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Zoom>
      )
    } else if (this.state.solicitationSent) {
      return (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={3}
        >
          <Zoom in={this.state.solicitationSent} timeout={500}>
            <Card>
              <CardHeader
                title={
                  <Typography
                    variant="h3"
                    component="h3"
                    align="center"
                    style={{ width: '100%' }}
                  >
                    Solicitação enviada com sucesso!
                  </Typography>
                }
              />
              <CardContent>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item>
                    <Zoom in={this.state.solicitationSent} timeout={800}>
                      <CheckCircle
                        style={{ fontSize: 100, color: green[500] }}
                      />
                    </Zoom>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Typography
                  variant="body1"
                  align="center"
                  style={{ width: '100%' }}
                >
                  Um resumo de sua solicitação foi enviada para{' '}
                  <b>{this.state.email}</b>
                </Typography>
              </CardActions>
            </Card>
          </Zoom>
        </Grid>
      )
    } else if (this.state.errorPage) {
      return (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={3}
        >
          <Card>
            <CardContent>
              <Typography
                variant="h2"
                component="h2"
                align="center"
                style={{ width: '100%' }}
              >
                Erro!
              </Typography>
              <p></p>
              <Error style={{ fontSize: 60, color: '#e60000' }} />
              <p></p>
              <Typography
                variant="subtitle1"
                align="center"
                style={{ width: '100%' }}
              >
                {this.state.errorMessage}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )
    } else {
      return (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={3}
        >
          <Card>
            <CardHeader
              title={
                <Typography
                  variant="h4"
                  component="h4"
                  align="center"
                  style={{ width: '100%' }}
                >
                  Formulário de Solicitação.
                </Typography>
              }
            />
            <CardContent>
              <form
                onSubmit={(evt) => {
                  evt.preventDefault()
                  /*if (this.state.document_proof_file === null) {
                    Swal.fire({
                      icon: 'warning',
                      title: 'Atenção',
                      text: 'Insira o comprovante do CPF',
                    })
                  } else {
                    this.setState({
                      sendingEmailVerification: true,
                    })
                  }*/
                  this.setState({
                    sendingEmailVerification: true,
                  })
                  return false
                }}
              >
                {/* Eu sou */}
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  spacing={3}
                  style={{ width: '100%', padding: '20px' }}
                >
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{ width: '100%' }}
                  >
                    Eu sou
                  </Typography>
                  <FormControl
                    variant="outlined"
                    style={{ width: '100%' }}
                  >
                    <Select
                      value={this.state.personType}
                      required
                      placeholder="Eu sou"
                      onChange={(event) => {
                        this.handlePersonType(event)
                      }}
                    >
                      {this.state.personTypes.map((personType, index) => (
                        <MenuItem key={index} value={personType.id}>
                          {personType.type}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>Obrigatório*</FormHelperText>
                  </FormControl>
                </Grid>
                {/* Companhia */}
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  spacing={3}
                  style={{ width: '100%', padding: '20px' }}
                >
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{ width: '100%' }}
                  >
                    Companhia
                  </Typography>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      value={this.state.companiName}
                      required
                      disabled
                    >
                      <MenuItem value={this.state.companiName}>
                        {this.state.companiName}
                      </MenuItem>
                    </Select>
                    <FormHelperText>Obrigatório*</FormHelperText>
                  </FormControl>
                </Grid>
                {/* Meu pedido é uma */}
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  spacing={3}
                  style={{ width: '100%', padding: '20px' }}
                >
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{ width: '100%' }}
                  >
                    Meu pedido é uma
                  </Typography>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      value={this.state.solicitationType}
                      required
                      onChange={(event) => {
                        this.handleSolicitationType(event)
                      }}
                    >
                      {this.state.solicitationTypes.map(
                        (solicitationType, index) => (
                          <MenuItem key={index} value={solicitationType.id}>
                            {solicitationType.type}
                          </MenuItem>
                        )
                      )}
                    </Select>
                    <FormHelperText>Obrigatório*</FormHelperText>
                  </FormControl>
                </Grid>
                {/* Nome */}
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  spacing={3}
                  style={{ width: '100%', padding: '20px' }}
                >
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{ width: '100%' }}
                  >
                    Nome
                  </Typography>
                  <TextField
                    helperText="Obrigatório*"
                    variant="outlined"
                    fullWidth
                    required
                    value={this.state.name}
                    onChange={(event) => {
                      this.handleName(event)
                    }}
                  />
                </Grid>
                {/* Sobrenome */}
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  spacing={3}
                  style={{ width: '100%', padding: '20px' }}
                >
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{ width: '100%' }}
                  >
                    Sobrenome
                  </Typography>
                  <TextField
                    helperText="Obrigatório*"
                    variant="outlined"
                    required
                    fullWidth
                    value={this.state.lastName}
                    onChange={(event) => {
                      this.handleLastName(event)
                    }}
                  />
                </Grid>
                {/* E-mail */}
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  spacing={3}
                  style={{ width: '100%', padding: '20px' }}
                >
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{ width: '100%' }}
                  >
                    E-mail
                  </Typography>
                  <TextField
                    helperText="Obrigatório*"
                    variant="outlined"
                    required
                    type="email"
                    fullWidth
                    value={this.state.email}
                    onChange={(event) => {
                      this.handleEmail(event)
                    }}
                  />
                </Grid>
                {/* CPF */}
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  spacing={3}
                  style={{ width: '100%', padding: '20px' }}
                >
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{ width: '100%' }}
                  >
                    CPF
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    //required
                    value={this.state.document}
                    error={!this.state.validDocument}
                    /*helperText={
                      !this.state.validDocument
                        ? 'Digite um CPF válido'
                        : 'Obrigatório*'
                    }*/
                    onChange={(event) => {
                      this.handleDocument(event)
                    }}
                    InputProps={{
                      inputComponent: TextMaskCustomCPF,
                    }}
                  />
                </Grid>
                {/* Comprovante CPF */}
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  spacing={3}
                  style={{ width: '100%', padding: '20px' }}
                >
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{ width: '100%' }}
                  >
                    Anexar documento comprobatório (PNG, JPG ou PDF).
                  </Typography>
                  <InputFile
                    onAcceptFiles={(files) => {
                      if (files.length) this.handleDocumentProofFile(files[0])
                    }}
                  />
                </Grid>
                {/* Data de nascimento */}
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  spacing={3}
                  style={{ width: '100%', padding: '20px' }}
                >
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{ width: '100%' }}
                  >
                    Data de nascimento
                  </Typography>
                  <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      margin="normal"
                      fullWidth
                      invalidDateMessage="Data em formato inválido"
                      onChange={(value) => {
                        this.handleBirthDate(value)
                      }}
                      value={this.state.birthDate}
                      autoOk
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                {/* Telefone */}
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  spacing={3}
                  style={{ width: '100%', padding: '20px' }}
                >
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{ width: '100%' }}
                  >
                    Telefone
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={this.state.phone}
                    helperText="Insira um telefone"
                    onChange={(event) => {
                      this.handlePhone(event)
                    }}
                    InputProps={{
                      inputComponent: TextMaskCustomPhone,
                    }}
                  />
                </Grid>

                {/* Informações Complementares */}
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  spacing={3}
                  style={{ width: '100%', padding: '20px' }}
                >
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{ width: '100%' }}
                  >
                    Informações Complementares
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    value={this.state.additionalInformation}
                    helperText="Informações Complementares"
                    onChange={(event) => {
                      this.handleAdditionalInformation(event)
                    }}

                  />
                </Grid>

                {/* Aceite de consentimento */}
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  spacing={3}
                  style={{ width: '100%', padding: '20px' }}
                >
                  <FormControl required component="fieldset">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.consentAccepted}
                            onChange={() => this.handleConsent()}
                            required
                          />
                        }
                        label={this.state.companyGroupData.consentText}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                {/* Enviar solicitação */}
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  style={{ width: '100%', padding: '20px' }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    style={{
                      background: '#193993',
                      color: 'white',
                      fontSize: '22px',
                      fontWeight: 300,
                    }}
                  >
                    Enviar
                  </Button>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      )
    }
  }

  renderFooterComponents() {
    const { classes } = this.props

    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={3}
      >
        {/* <Grid item>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography variant="caption" align="center" noWrap>
                                Powered by
                            </Typography>
                        </Grid>
                        <Grid item>
                            <img alt="logo" src={dashboardLogo} style={{ height: '42px' }} />
                        </Grid>
                    </Grid>
                </Grid> */}
        <Grid item className={classes.footerText}>
          <Typography
            variant="subtitle2"
            align="center"
            style={{ width: '100%' }}
          >
            {
              'Caso você não se enquadre nos públicos indicados acima, ou queira exercer algum outro direito que não tenha relação com a nossa Política de Privacidade, entre em contato com uma de nossas '
            }
            <a
              href={this.state.companyGroupData.callCenter}
              target="_blank"
              rel="noreferrer"
            >
              Centrais de Atendimento
            </a>
            .
          </Typography>
        </Grid>
        <Grid item className={classes.footerText}>
          <Typography
            variant="subtitle2"
            align="center"
            style={{ width: '100%' }}
          >
            {'Não encontrou o que buscava sobre privacidade? Consulte nossa '}
            <a
              href={this.state.companyGroupData.privacyPolicyUrl}
              target="_blank"
              rel="noreferrer"
            >
              Política de privacidade
            </a>
          </Typography>
        </Grid>
      </Grid>
    )
  }

  renderLoading() {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ width: '100%', height: '100%' }}
      >
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          style={{ paddingTop: '40vh' }}
        >
          <CircularProgress size={100} color="primary" />
        </Grid>
      </Grid>
    )
  }

  render() {
    return (
      <Container maxWidth="md">
        <CssBaseline />
        {this.state.loadingForm ? (
          this.renderLoading()
        ) : (
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={4}
          >
            <Grid item />
            <Grid item>{this.renderHeaderComponents()}</Grid>
            <Grid item />
            <Grid item>{this.renderBodyComponents()}</Grid>
            <Grid item />
            <Grid item>{this.renderFooterComponents()}</Grid>
            <Grid item />
          </Grid>
        )}
      </Container>
    )
  }
}

export default withRouter(withStyles(styles)(SolicitationFormCompany))
