import React, { Component } from 'react';
import myApp from '../../../../core/infrastructure/firebaseConfig';
import "firebase/compat/firestore";
import CloseButtonIcon from '../../../../shared/assets/icons/closeButtonIcon';
import { isUndefined } from '../../../../shared/commands/verifyDefinition'
import { ExpandMore, ChevronRight } from '@material-ui/icons';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {
  InputLabel,
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  Select,
  MenuItem,
  Grid,
  Slide,
  Typography,
  Dialog,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  IconButton,
  withStyles,
} from "@material-ui/core";
import Activity from "../components/activity";
import "../../../../shared/assets/styles/hideScrollBar.css";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";

import theme from "../../../../core/theme.json";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let subtaskIndex = 0;

const styles = (theme) => ({
  icon: {
    fill: "#000000",
    width: "50px",
    height: "50px",
    top: "auto",
  },
});

class PhaseTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openDialog: false,
      selectedActivity: null,
      selectedSubtask: null,
      taskListPage: true,
      type: "",
      goal: "",
      trainingArea: "",
      trainingClass: "",
      phase: "",
      newTask: true,
      date: null,
      completed: false,
      prevState: null,
      activities: this.props.activities,
      database: myApp.firestore(),
      saveTaskButtonDisabled: true,
      reasonPending: "",
    };
  }

  componentDidMount() {
    this.setWeekColorsAll();
  }

  async saveActivity(activity) {
    let activityObj = activity;
    delete activityObj.weekColors;
    const activityRef = this.state.database
      .collection("activities")
      .doc(activity.id);
    await activityRef.update(activityObj);
  }

  showSubTasks = () => {
    if (this.state.selectedActivity) {
      let activity = this.props.activities.find(
        (activity) => activity.id === this.state.selectedActivity.id
      );
      if (activity)
        return activity.subtasks.map((subtask) => (
          <Grid item xs={12} style={{ width: "-webkit-fill-available" }}>
            <Button
              onClick={(e) => this.handleEditTask(subtask)}
              style={{
                border: "solid",
                borderRadius: "5px",
                borderWidth: "thin",
                width: "90%",
                margin: "auto",
                display: "flex",
                textTransform: "none",
                padding: "0",
                textAlign: "inherit",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  padding: "10px 10px 10px 15px",
                  width: "100%",
                  opacity: "0.5",
                }}
              >
                {subtask.type}
              </Typography>
              <ChevronRight
                style={{
                  padding: "8px 17px 2px 0px",
                  background: "transparent",
                  alignItems: "center",
                  alignSelf: "center",
                  width: "45",
                  height: "40",
                  verticalAlign: "bottom",
                  color: "black",
                }}
              />
            </Button>
          </Grid>
        ));
    }
  };

  setWeekColors = () => {
    let activityIndex = this.props.activities.findIndex(
      (a) => a.id === this.state.selectedActivity.id
    );
    let activity = this.props.activities[activityIndex];

    if (isUndefined(activity.weekColors)) {
      activity.weekColors = ["#DFDEE4", "#DFDEE4", "#DFDEE4", "#DFDEE4"];
    }

    let weekNewColors = activity.weekColors;

    let today = new Date();
    let tasks = activity.subtasks.sort((t) => t.date);
    let currentMonth = this.props.currentMonth;
    let currentYear = this.props.currentYear;
    let weeks = this.props.weeks;

    let tasksWeek1 = tasks.filter(
      (t) =>
        new Date(t.date) >=
          new Date(currentYear, currentMonth, weeks[0].firstDay) &&
        new Date(t.date) <=
          new Date(currentYear, currentMonth, weeks[0].lastDay)
    );
    let tasksWeek2 = tasks.filter(
      (t) =>
        new Date(t.date) >=
          new Date(currentYear, currentMonth, weeks[1].firstDay) &&
        new Date(t.date) <=
          new Date(currentYear, currentMonth, weeks[1].lastDay)
    );
    let tasksWeek3 = tasks.filter(
      (t) =>
        new Date(t.date) >=
          new Date(currentYear, currentMonth, weeks[2].firstDay) &&
        new Date(t.date) <=
          new Date(currentYear, currentMonth, weeks[2].lastDay)
    );
    let tasksWeek4 = tasks.filter(
      (t) =>
        new Date(t.date) >=
          new Date(currentYear, currentMonth, weeks[3].firstDay) &&
        new Date(t.date) <=
          new Date(currentYear, currentMonth, weeks[3].lastDay)
    );

    weekNewColors[0] = tasksWeek1.some((t) => !t.completed)
      ? today > new Date(currentYear, currentMonth, weeks[0].lastDay)
        ? "#E5541C"
        : "#122148"
      : tasksWeek1.some((t) => t)
      ? "#38B832"
      : "#DFDEE4";

    weekNewColors[1] = tasksWeek2.some((t) => !t.completed)
      ? today > new Date(currentYear, currentMonth, weeks[1].lastDay)
        ? "#E5541C"
        : "#122148"
      : tasksWeek2.some((t) => t)
      ? "#38B832"
      : "#DFDEE4";

    weekNewColors[2] = tasksWeek3.some((t) => !t.completed)
      ? today > new Date(currentYear, currentMonth, weeks[2].lastDay)
        ? "#E5541C"
        : "#122148"
      : tasksWeek3.some((t) => t)
      ? "#38B832"
      : "#DFDEE4";

    weekNewColors[3] = tasksWeek4.some((t) => !t.completed)
      ? today > new Date(currentYear, currentMonth, weeks[3].lastDay)
        ? "#E5541C"
        : "#122148"
      : tasksWeek4.some((t) => t)
      ? "#38B832"
      : "#DFDEE4";

    this.props.activities[activityIndex].weekColors = weekNewColors;

    this.setState({ activities: this.props.activities });
  };

  setWeekColorsAll = () => {
    let activities = this.props.activities;

    for (let activity of activities) {
      if (isUndefined(activity.weekColors)) {
        activity.weekColors = ["#DFDEE4", "#DFDEE4", "#DFDEE4", "#DFDEE4"];
      }

      let weekNewColors = activity.weekColors;

      let today = new Date();
      let tasks = activity.subtasks.sort((t) => t.date);
      let currentMonth = this.props.currentMonth;
      let currentYear = this.props.currentYear;
      let weeks = this.props.weeks;

      let tasksWeek1 = tasks.filter(
        (t) =>
          new Date(t.date) >=
            new Date(currentYear, currentMonth, weeks[0].firstDay) &&
          new Date(t.date) <=
            new Date(currentYear, currentMonth, weeks[0].lastDay)
      );
      let tasksWeek2 = tasks.filter(
        (t) =>
          new Date(t.date) >=
            new Date(currentYear, currentMonth, weeks[1].firstDay) &&
          new Date(t.date) <=
            new Date(currentYear, currentMonth, weeks[1].lastDay)
      );
      let tasksWeek3 = tasks.filter(
        (t) =>
          new Date(t.date) >=
            new Date(currentYear, currentMonth, weeks[2].firstDay) &&
          new Date(t.date) <=
            new Date(currentYear, currentMonth, weeks[2].lastDay)
      );
      let tasksWeek4 = tasks.filter(
        (t) =>
          new Date(t.date) >=
            new Date(currentYear, currentMonth, weeks[3].firstDay) &&
          new Date(t.date) <=
            new Date(currentYear, currentMonth, weeks[3].lastDay)
      );

      weekNewColors[0] = tasksWeek1.some((t) => !t.completed)
        ? today > new Date(currentYear, currentMonth, weeks[0].lastDay)
          ? "#E5541C"
          : "#122148"
        : tasksWeek1.some((t) => t)
        ? "#38B832"
        : "#DFDEE4";

      weekNewColors[1] = tasksWeek2.some((t) => !t.completed)
        ? today > new Date(currentYear, currentMonth, weeks[1].lastDay)
          ? "#E5541C"
          : "#122148"
        : tasksWeek2.some((t) => t)
        ? "#38B832"
        : "#DFDEE4";

      weekNewColors[2] = tasksWeek3.some((t) => !t.completed)
        ? today > new Date(currentYear, currentMonth, weeks[2].lastDay)
          ? "#E5541C"
          : "#122148"
        : tasksWeek3.some((t) => t)
        ? "#38B832"
        : "#DFDEE4";

      weekNewColors[3] = tasksWeek4.some((t) => !t.completed)
        ? today > new Date(currentYear, currentMonth, weeks[3].lastDay)
          ? "#E5541C"
          : "#122148"
        : tasksWeek4.some((t) => t)
        ? "#38B832"
        : "#DFDEE4";

        activities.weekColors = weekNewColors;
    }

    this.setState({
      activities,
    });
  };

  handleOpenDialog = (id) => {
    let activity = this.props.activities.find((activity) => activity.id === id);
    this.setState({
      openDialog: true,
      selectedActivity: activity,
    });
  };

  handleCloseDialog = () => {
    this.setState({
      openDialog: false,
      selectedActivity: null,
      taskListPage: true,
    });
  };

  handleAddTask = () => {
    let activity = this.state.selectedActivity;

    this.setState({
      openDialog: true,
      selectedActivity: activity,
      taskListPage: false,
      newTask: true,
      type: "",
      goal: "",
      trainingArea: "",
      trainingClass: "",
      phase: "",
      date: null,
      completed: false,
      reasonPending: "",
    });
  };

  handleEditTask = (subtask) => {
    let prevState = this.state;
    this.setState({
      taskListPage: false,
      newTask: false,
      type: subtask.type,
      goal: subtask.goal,
      trainingArea: subtask.trainingArea,
      trainingClass: subtask.trainingClass,
      phase: subtask.phase,
      date: subtask.date,
      completed: subtask.completed,
      prevState: prevState,
      selectedSubtask: subtask,
      reasonPending: subtask.reasonPending,
    });
  };

  handleSaveTask = () => {
    let activity = this.props.activities.find(
      (activity) => activity.id === this.state.selectedActivity.id
    );
    if (activity) {
      if (this.state.newTask) {
        activity.subtasks.push({
          type: this.state.type,
          goal: this.state.goal,
          trainingArea: this.state.trainingArea,
          trainingClass: this.state.trainingClass,
          phase: this.state.phase,
          date: this.state.date,
          completed: this.state.completed,
          id: ++subtaskIndex,
          reasonPending: this.state.reasonPending,
        });
      } else {
        let subtaskIndex = activity.subtasks.findIndex(
          (s) => s.id === this.state.selectedSubtask.id
        );
        activity.subtasks[subtaskIndex] = {
          type: this.state.type,
          goal: this.state.goal,
          trainingArea: this.state.trainingArea,
          trainingClass: this.state.trainingClass,
          phase: this.state.phase,
          date: this.state.date,
          completed: this.state.completed,
          id: this.state.selectedSubtask.id,
          reasonPending: this.state.reasonPending,
        };
      }
    }

    this.saveActivity(activity);
    this.setState({
      selectedActivity: activity,
      taskListPage: true,
      saveTaskButtonDisabled: true,
    });
    this.setWeekColors();
  };

  handleTypeChange = (value) => {
    this.setState({ type: value });

    let data = new Date(this.state.date);

    if (
      value !== "" &&
      this.state.goal !== "" &&
      this.state.trainingArea !== "" &&
      this.state.trainingClass !== "" &&
      this.state.phase !== "" &&
      this.state.date !== null &&
      !isNaN(data.getTime())
    ) {
      this.setState({
        saveTaskButtonDisabled: false,
      });
    } else {
      this.setState({
        saveTaskButtonDisabled: true,
      });
    }
  };

  handleGoalChange = (value) => {
    this.setState({ goal: value });

    let data = new Date(this.state.date);

    if (
      value !== "" &&
      this.state.type !== "" &&
      this.state.trainingArea !== "" &&
      this.state.trainingClass !== "" &&
      this.state.phase !== "" &&
      this.state.date !== null &&
      !isNaN(data.getTime())
    ) {
      this.setState({
        saveTaskButtonDisabled: false,
      });
    } else {
      this.setState({
        saveTaskButtonDisabled: true,
      });
    }
  };

  handleTrainingAreaChange = (value) => {
    this.setState({ trainingArea: value });

    let data = new Date(this.state.date);

    if (
      value !== "" &&
      this.state.type !== "" &&
      this.state.goal !== "" &&
      this.state.trainingClass !== "" &&
      this.state.phase !== "" &&
      this.state.date !== null &&
      !isNaN(data.getTime())
    ) {
      this.setState({
        saveTaskButtonDisabled: false,
      });
    } else {
      this.setState({
        saveTaskButtonDisabled: true,
      });
    }
  };

  handleTrainingClassChange = (value) => {
    this.setState({ trainingClass: value });

    let data = new Date(this.state.date);

    if (
      value !== "" &&
      this.state.type !== "" &&
      this.state.goal !== "" &&
      this.state.trainingArea !== "" &&
      this.state.phase !== "" &&
      this.state.date !== null &&
      !isNaN(data.getTime())
    ) {
      this.setState({
        saveTaskButtonDisabled: false,
      });
    } else {
      this.setState({
        saveTaskButtonDisabled: true,
      });
    }
  };

  handlePhaseChange = (value) => {
    this.setState({ phase: value });

    let data = new Date(this.state.date);

    if (
      value !== "" &&
      this.state.type !== "" &&
      this.state.goal !== "" &&
      this.state.trainingArea !== "" &&
      this.state.trainingClass !== "" &&
      this.state.date !== null &&
      !isNaN(data.getTime())
    ) {
      this.setState({
        saveTaskButtonDisabled: false,
      });
    } else {
      this.setState({
        saveTaskButtonDisabled: true,
      });
    }
  };

  handleReasonPending = (value) => {
    this.setState({ reasonPending: value });

    let data = new Date(this.state.date);

    if (
      value !== "" &&
      this.state.type !== "" &&
      this.state.goal !== "" &&
      this.state.trainingArea !== "" &&
      this.state.trainingClass !== "" &&
      this.state.date !== null &&
      !isNaN(data.getTime())
    ) {
      this.setState({
        saveTaskButtonDisabled: false,
      });
    } else {
      this.setState({
        saveTaskButtonDisabled: true,
      });
    }
  };

  handleDateChange = (value) => {
    this.setState({ date: value });

    let data = new Date(value);

    if (
      value !== null &&
      !isNaN(data.getTime()) &&
      this.state.type !== "" &&
      this.state.goal !== "" &&
      this.state.trainingArea !== "" &&
      this.state.trainingClass !== "" &&
      this.state.phase !== ""
    ) {
      this.setState({
        saveTaskButtonDisabled: false,
      });
    } else {
      this.setState({
        saveTaskButtonDisabled: true,
      });
    }
  };

  handleCompletedChange = (event) => {
    this.setState({ completed: event.target.checked });
  };

  handleBack = () => {
    if (!this.state.newTask) this.setState(this.state.prevState);
    else {
      this.setState({
        taskListPage: true,
      });
    }
  };

  render() {
    const styles = {
      tableCellActivity: {
        color: theme.tertiary_color,
        borderBottom: "none",
        padding: "0 0 23px 10px",
        lineHeight: "0",
        textAlign: "left",
      },
      tableCellWeek: {
        color: theme.tertiary_color,
        borderBottom: "none",
        padding: "0 0 23px 10px",
        lineHeight: "0",
        textAlign: "center",
      },
    };

    const { openDialog } = this.state;
    const { classes } = this.props;
    return (
      <div>
        <TableContainer style={{width:"100%"}}> 
          <Table>
            <colgroup>
              <col style={{ width: "33.33%" }} />
             
            </colgroup>
            <TableHead style={{width:"100%"}}>
              <TableRow>
                <TableCell
                  style={{
                    color: theme.secondary_color,
                    borderBottom: "none",
                    padding: "0 0 0 10px",
                  }}
                >
                  <h2>{this.props.title}</h2>
                </TableCell>
           
              </TableRow>
              <TableRow>
                <TableCell style={styles.tableCellActivity}>
                  <h3>Atividade</h3>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.activities.map((activity) => (
                <Activity
                  onClickActivity={this.handleOpenDialog}
                  color={this.props.color}
                  name={activity.name}
                  id={activity.id}
                  weekColors={activity.weekColors}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog
          open={openDialog}
          TransitionComponent={Transition}
          maxWidth="sm"
          maxheight="sm"
          fullWidth
          onClose={() => this.handleCloseDialog()}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <div>
            <div
              style={{
                background: "#101C39",
                width: "100%",
                height: "30%",
                textAlign: "left",
                display: "flex",
              }}
            >
              <Typography
                style={{
                  color: "white",
                  width: "100%",
                  alignSelf: "center",
                  marginLeft: "1%",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                }}
              >
                {this.state.taskListPage
                  ? this.state.selectedActivity?.name
                  : "Adicionar Tarefa"}
              </Typography>
              <IconButton
                onClick={() => this.handleCloseDialog()}
                style={{ padding: "2px 10px 2px 0px" }}
              >
                <CloseButtonIcon
                  iconType="close"
                  style={{ alignSelf: "center", verticalAlign: "bottom" }}
                />
              </IconButton>
            </div>

            <div
              hidden={!this.state.taskListPage}
              style={{ /*overflow: 'hidden auto',*/ maxheight: "500px" }}
            >
              <Grid
                container
                direction="column"
                alignItems="center"
                justify="space-between"
                spacing={2}
                style={{ marginTop: "5%", marginBottom: "5%" }}
              >
                <Grid item xs={12} style={{ width: "-webkit-fill-available" }}>
                  <Button
                    onClick={() => this.handleAddTask()}
                    style={{
                      border: "solid",
                      borderRadius: "5px",
                      borderWidth: "thin",
                      width: "90%",
                      margin: "auto",
                      display: "flex",
                      textTransform: "none",
                      padding: "0",
                      textAlign: "inherit",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontSize: "18px",
                        padding: "10px 10px 10px 10px",
                        width: "100%",
                        opacity: "0.5",
                      }}
                    >
                      Adicionar subitem
                    </Typography>
                    <CloseButtonIcon
                      iconType="plus"
                      color={"black"}
                      width="25px"
                      height="25px"
                      margin="7px 15px 2px 2px"
                      style={{
                        background: "transparent",
                        alignItems: "center",
                        alignSelf: "center",
                        verticalAlign: "bottom",
                      }}
                    />
                  </Button>
                </Grid>

                {this.showSubTasks()}
              </Grid>
            </div>
          </div>

          <div
            hidden={this.state.taskListPage}
            style={
              {
                /*overflow: 'hidden'*/
              }
            }
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="space-between"
              spacing={1}
              style={{ margin: "5% 0% 5% 5%" }}
            >
              <Grid item style={{ width: "100%" }}>
                <FormControl
                  variant="outlined"
                  style={{ width: "90%", alignSelf: "center" }}
                >
                  <TextField
                    label="Tipo"
                    value={this.state.type}
                    variant="outlined"
                    style={{ width: "100%" }}
                    onChange={(event) => {
                      this.handleTypeChange(event.target.value);
                    }}
                  />
                  {/* <InputLabel htmlFor="select-type-label">Tipo</InputLabel>
									<Select
										value={this.state.type}
										label="Tipo"
										onChange={(event) => { this.handleTypeChange(event.target.value) }}
										inputProps={{
											classes: {
												icon: classes.icon,
											},
											id: 'select-type-label'
										}}
										IconComponent={ExpandMore}
									>
										<MenuItem value={'Treinamento'}>Treinamento</MenuItem>
										<MenuItem value={'Entrevista'}>Entrevista</MenuItem>
									</Select> */}
                </FormControl>
              </Grid>
              <div style={{ width: "98.8%" }}>
                <Grid item style={{ width: "100%", marginTop: "2%" }}>
                  <FormControl
                    variant="outlined"
                    style={{ width: "90%", alignSelf: "center" }}
                  >
                    <TextField
                      value={this.state.goal}
                      style={{ width: "100%", alignSelf: "center" }}
                      variant="outlined"
                      label="Finalidade"
                      onChange={(event) => {
                        this.handleGoalChange(event.target.value);
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item style={{ width: "100%", marginTop: "2%" }}>
                  <FormControl
                    variant="outlined"
                    style={{ width: "90%", alignSelf: "center" }}
                  >
                    <TextField
                      value={this.state.trainingArea}
                      style={{ width: "100%", alignSelf: "center" }}
                      variant="outlined"
                      label="Área de treinamento"
                      onChange={(event) => {
                        this.handleTrainingAreaChange(event.target.value);
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item style={{ width: "100%", marginTop: "2%" }}>
                  <FormControl
                    variant="outlined"
                    style={{ width: "90%", alignSelf: "center" }}
                  >
                    <TextField
                      value={this.state.trainingClass}
                      style={{ width: "100%", alignSelf: "center" }}
                      variant="outlined"
                      label="Turma de treinamento"
                      onChange={(event) => {
                        this.handleTrainingClassChange(event.target.value);
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item style={{ width: "100%", marginTop: "2%" }}>
                  <FormControl
                    variant="outlined"
                    style={{ width: "90%", alignSelf: "center" }}
                  >
                    <TextField
                      value={this.state.phase}
                      style={{ width: "100%", alignSelf: "center" }}
                      variant="outlined"
                      label="Fase"
                      onChange={(event) => {
                        this.handlePhaseChange(event.target.value);
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item style={{ width: "100%", marginTop: "2%" }}>
                  <FormControl
                    variant="outlined"
                    style={{ width: "90%", alignSelf: "center" }}
                  >
                    <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        margin="normal"
                        label="Data"
                        invalidDateMessage="Data em formato inválido"
                        value={this.state.date}
                        onChange={(value) => this.handleDateChange(value)}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        autoOk={true}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
                <Grid item style={{ width: "100%", marginTop: "2%" }}>
                  <FormControl
                    variant="outlined"
                    style={{ width: "90%", alignSelf: "center" }}
                  >
                    <TextField
                      label="Motivo pendencia"
                      value={this.state.reasonPending}
                      onChange={(e) => this.handleReasonPending(e.target.value)}
                      variant="outlined"
                      multiline
                      rows={3}
                      style={{ width: "100%" }}
                    />
                  </FormControl>
                </Grid>
                <Grid item style={{ width: "100%", marginTop: "2%" }}>
                  <FormControl
                    variant="outlined"
                    style={{ width: "90%", alignSelf: "center" }}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.completed}
                          onChange={(e) => this.handleCompletedChange(e)}
                          color="primary"
                        />
                      }
                      label="Finalizado"
                    />
                  </FormControl>
                </Grid>
                <Grid item style={{ width: "100%", marginTop: "2%" }}>
                  <FormControl
                    variant="outlined"
                    style={{ width: "90%", alignSelf: "center" }}
                  >
                    <Button
                      onClick={(e) => this.handleSaveTask()}
                      style={{
                        width: "100%",
                        height: "60px",
                        alignSelf: "center",
                        background: this.state.saveTaskButtonDisabled
                          ? "#91ff79"
                          : theme.primary_color,
                        color: theme.secondary_color,
                        textTransform: "none",
                        fontSize: theme.font_button,
                      }}
                      disabled={this.state.saveTaskButtonDisabled}
                    >
                      Salvar
                    </Button>
                  </FormControl>
                </Grid>
                <Grid item style={{ width: "100%", marginTop: "2%" }}>
                  <FormControl
                    variant="outlined"
                    style={{ width: "90%", alignSelf: "center" }}
                  >
                    <Button
                      onClick={(e) => this.handleBack()}
                      style={{
                        width: "100%",
                        height: "60px",
                        alignSelf: "center",
                        background: theme.secondary_color,
                        color: theme.quaternary_color,
                        textTransform: "none",
                        fontSize: theme.font_button,
                      }}
                    >
                      Voltar
                    </Button>
                  </FormControl>
                </Grid>
              </div>
            </Grid>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(PhaseTable);
