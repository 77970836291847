import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  ButtonBase,
  Paper,
  TextField,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import { useDispatch } from "react-redux";
import { changePage } from "../../parameterization/redux/actions";
import { setQuizId } from "../redux/actions";
import Title from "../../../../shared/components/title";
import ArrowBackIosTwoToneIcon from "@material-ui/icons/ArrowBackIosTwoTone";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import AddIcon from "@material-ui/icons/Add";
import { Close, QuestionAnswerRounded } from "@material-ui/icons";
import theme from "../../../../core/theme.json";

import { defaultQuiz } from './newDeafaultQuiz';


const NewQuizPage = () => {
  const dispatch = useDispatch();
  let hasDefault = false;
  const [questionnaires, setQuestionnaires] = useState([]);
  const [dialogs, setDialogs] = useState({
    checkIfItHasDefault: true,
    newQuizMoal: false
  });
  const [newQuizName, setNewQuizName] = useState();

  useEffect(() => {
    handleMountingPage();
  }, []);

  const handleOpenDialog = (item) => (value) => {
    setDialogs({
      ...dialogs,
      [item]: true
    })
  }

  const handleCloseDialog = (item) => (value) => {
    setDialogs({
      ...dialogs,
      [item]: false
    });
  }

  const handleMountingPage = async () => {
    await hadleGetDeafultQuiz();
    await validDefaultQuiz();
  }

  const hadleGetDeafultQuiz = async () => {
    let aux = [];
    let get = await myApp.firestore().collection("newQuizz").where("fk_company", "==", localStorage.getItem('currentCompany')).get();
    if (get.docs.length > 0) {
      for (let item of get.docs) {
        aux.push({
          id: item.id,
          name: item.data().name,
          questions: item.data().questions,
        });
        if (item.data().name.toUpperCase() == 'QUESTIONÁRIO PADRÃO') {
          hasDefault = true;
        }
      }
    }
    setQuestionnaires(aux);
  }

  const validDefaultQuiz = async () => {
    if (hasDefault === false) {
      myApp.firestore().collection('newQuizz').add({
        name: 'Questionário Padrão',
        fk_company: localStorage.getItem('currentCompany'),
        questions: defaultQuiz
      }).then(() => {
        hadleGetDeafultQuiz();
        setDialogs({
          ...dialogs,
          checkIfItHasDefault: false
        });
      });
    } else {
      hadleGetDeafultQuiz();
      setDialogs({
        ...dialogs,
        checkIfItHasDefault: false
      });
    }

  }

  const handleAddNewQuiz = () => {
    myApp.firestore().collection('newQuizz').add({
      name: newQuizName,
      fk_company: localStorage.getItem('currentCompany'),
      questions: []
    }).then(() => {
      hadleGetDeafultQuiz();
      setDialogs({
        ...dialogs,
        newQuizMoal: false
      });
    });
  }

  const handleMoveEditQuiz = (item) => {
    dispatch(setQuizId(item.id));
    dispatch(changePage(39));
    console.log(item)
  }

  return (
    <Grid
      container
      direction="column"
    >
      <Grid
        container
        item
        xs={12}
      >
        <Title>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            {/* <Grid item>
              <Button
                variant="text"
                style={{
                  fontSize: '15px',
                }}
                onClick={() => {
                  dispatch(changePage(25));
                }}
              >
                <ArrowBackIosTwoToneIcon />
              </Button>
            </Grid> */}
            <Grid item>Questionário 2.0</Grid>
          </Grid>
        </Title>
        <Grid
          container
          spacing={2}
          direction="row"
          item
          style={{
            marginTop: 30
          }}
        >
          {
            questionnaires.length > 0 ?
              questionnaires.map((item, index) => (
                <Grid
                  key={index}
                  item
                  sm={3}
                  xs={3}
                >
                  <ButtonBase
                    // disabled={item.name.toUpperCase() == 'QUESTIONÁRIO PADRÃO' ? true : false}
                    onClick={() => handleMoveEditQuiz(item)}
                    style={{
                      width: "100%",
                      height: 250,
                      borderRadius: "8px",
                    }}
                  >
                    <Paper style={{ width: "100%", height: "100%", borderRadius: "8px" }}>
                      <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        style={{
                          height: "100%",
                        }}
                      >
                        <Grid
                          item
                          container
                          justify="center"
                          alignItems="center"
                          style={{ width: "100%", height: "50%" }}
                        >
                          <Grid item>
                            <QuestionAnswerRounded style={{ fontSize: 76, color: theme.tertiary_color }} />
                          </Grid>
                        </Grid>
                        <Grid item container style={{ width: "100%", height: "50%" }}>
                          <Grid
                            item
                            container
                            justifyContent="center"
                            style={{
                              width: "100%",
                              height: "80%",
                              overflowY: "hidden",
                              textOverflow: "ellipsis",
                              margin: "0 10px 0 10px",
                            }}
                          >
                            <Typography
                              variant="h6"
                              component="h6"
                              style={{
                                fontSize: theme.font_button,
                                color: theme.tertiary_color,
                                overflowWrap: "anywhere"
                              }}
                            >
                              {item.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </ButtonBase>
                </Grid>
              ))
              :
              <></>
          }

          <Grid
            item
            sm={3}
            xs={3}
          >
            <ButtonBase
              onClick={handleOpenDialog('newQuizMoal')}
              style={{
                width: "100%",
                height: 250,
                borderRadius: "8px",
              }}
            >
              <Paper style={{ width: "100%", height: "100%", borderRadius: "8px" }}>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    height: "100%",
                  }}
                >
                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ width: "100%", height: "50%" }}
                  >
                    <Grid item>
                      <AddIcon style={{ fontSize: 76, color: theme.tertiary_color }} />
                    </Grid>
                  </Grid>
                  <Grid item container style={{ width: "100%", height: "50%" }}>
                    <Grid
                      item
                      container
                      justifyContent="center"
                      style={{
                        width: "100%",
                        height: "80%",
                        overflowY: "hidden",
                        textOverflow: "ellipsis",
                        margin: "0 10px 0 10px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        component="h6"
                        style={{
                          fontSize: theme.font_button,
                          color: theme.tertiary_color,
                          overflowWrap: "anywhere"
                        }}
                      >
                        Novo
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </ButtonBase>
          </Grid>

        </Grid>
      </Grid>

      <Dialog
        open={dialogs.checkIfItHasDefault}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
          >
            <Grid item>
              <Typography>
                Aguarde enquanto validamos se já existe um Questionário Padrão associado
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <Grid
              item
              sm={12}
              xs={12}
            >
              <CircularProgress size={60} color="#193993" />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={dialogs.newQuizMoal}
        maxWidth="sm"
        fullWidth
        onClose={handleCloseDialog('newQuizMoal')}
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
          >
            <Grid item>
              <Typography>
                Novo questionário
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              sm={2}
              xs={2}
            >
              <Typography>
                Nome:
              </Typography>
            </Grid>
            <Grid
              item
              sm={10}
              xs={10}
            >
              <TextField
                label="Nome do questionário"
                placeholder="Digite o nome do questionário"
                variant="outlined"
                fullWidth
                margin="normal"
                value={newQuizName}
                onChange={(event) => setNewQuizName(event.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              backgroundColor: '#f00',
              color: '#fff'
            }}
            onClick={handleCloseDialog('newQuizMoal')}
            autoFocus
          >
            Cancelar
          </Button>
          <Button
            style={{
              backgroundColor: 'rgb(56, 242, 5)'
            }}
            onClick={handleAddNewQuiz}
            autoFocus
          >
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>


    </Grid>

  );
}

export default NewQuizPage;