import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Zoom,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  ButtonBase,
} from "@material-ui/core";
import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import theme from "../../../../core/theme.json";
import { useDispatch } from "react-redux";
import { changePage } from "../../../presentation/redux/actions";
import {
  setLoadingProcess,
  setProcessArea,
  setProcessId,
} from "../../process/redux/actions";
import {
  setArea,
  setDateToReply,
  setName,
  setProcess,
  setQuestionsAndAnswers,
  setStatus,
  setNewQuiz,
  setNewQuizId,
  setNewQuizQuestions,
} from "../redux/viewQuestionnaireReducer";
import ScrollWatcher from "../../../../shared/components/scrollWatcher";
import { Pagination } from "@material-ui/lab";

const QuestionnaireReport = () => {
  const dispatch = useDispatch();
  const db = myApp.firestore();
  const [auxState, setAxuState] = useState([]);
  const [myQuizes, setMyQuizes] = useState([]);
  const [loadingLinear, setLoadingLinear] = useState(true);
  const [paginationValue, setPaginationValue] = useState(true);
  const [arrAreas, setArrAreas] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [find, setFind] = useState(null);
  const [loading, setLoading] = useState(true);
  const [nonevalue, setNonevalue] = useState(false);
  const [statusValue, setStatusValue] = useState(false);
  const [dialogs, setDialogs] = useState({
    informe: false,
  });
  const [activepage, setActivepage] = useState(1);

  useEffect(() => {
    loadingDb();
  }, []);

  useEffect(() => {
    setIsLoadingMore(true);
    if (myQuizes.length > 0) {
      myQuizes.sort((a, b) => {
        if (
          (a.status == "Concluído" && b.status == "Pendente") ||
          (a.status == "Concluído" && b.status == "") ||
          (a.status == "" && b.status == "Concluído") 
        ) {
          return -1;
        }
      
      });
    }
    setIsLoadingMore(false);
  }, [myQuizes.map((item) => item.status)]);

  // função responsável pela interação com o banco
  const loadingDb = async () => {
    const myCompany = localStorage.getItem("currentCompany");

    let getAreas = await db
      .collection("areas")
      .where("fk_companies", "==", myCompany)
      .where("active", "==", true)
      .get();

    await buscaProcessosPorArea(getAreas.docs);
    setArrAreas(getAreas.docs);

  };

  const loadMore = async () => {
    let copyArea = arrAreas;
    if (copyArea.length) {
      setIsLoadingMore(true);
      await buscaProcessosPorArea(copyArea.shift());
      setArrAreas(copyArea);

    }
  };

  const buscaProcessosPorArea = async (docAreas) => {
    let aux = [];
    setLoading(true);
    //buscando processos por area
    for (let doc of docAreas) {
      let getProcess = await db
        .collection("processes")
        .where("fk_areas", "==", doc.id)
        .get();
        console.log('os docs',getProcess.docs.map(item => item.data()))
      aux.push(getProcess.docs.length !== 0 ? getProcess : null);
      setAxuState(aux);

      if (getProcess.docs.length !== 0) {
        for (let processIdAndData of getProcess.docs) {
         buscaIdAssociadoAoProcesso(processIdAndData, doc);

        }
      }
    }
    if (
      aux.filter((i) => {
        return i;
      }) == ""
    ) {
      setLoading(false);
      setNonevalue(true);
      return;
    }

    myQuizes.sort((a,b) =>{
        return b.replyDate - a.replyDate 
       })
  
       setLoading(false);
  };


  const buscaIdAssociadoAoProcesso = async (processIdAndData, docAreas) => {
    if (processIdAndData.data().quizId == "") {
      setMyQuizes((old) => [
        ...old,
        {
          id: "",
          name: "Processo sem questionário associado",
          area: docAreas.data().name,
          areaId: docAreas.id,
          process: processIdAndData.data().name,
          processId: processIdAndData.id,
          points: 0,
          questionAnswers: [],
          qtdeQuestions: 0,
          replyDate: "/",
          status: processIdAndData.data().replyDate ? "Concluído" : "Pendente",
          newQuiz: true,
        },
      ]);
    }

    setIsLoadingMore(true);

    if (processIdAndData.data().quizId) {
      let questionAnswers = [];
      let getQuiz = await db
        .collection("quiz")
        .doc(processIdAndData.data().quizId)
        .get();

      if (getQuiz.data() == undefined) {
        let getNewQuiz = await db
          .collection("newQuizAnswers")
          .where("processId", "==", processIdAndData.id)
          .where("fk_company", "==", localStorage.getItem("currentCompany"))
          .get();

        if (
          getNewQuiz.docs.length == 0 &&
          processIdAndData.data().quizId != ""
        ) {
          let parts = processIdAndData.data().quizId.split(" ");
          let resultado = parts[1];
          let getInfoQuiz = await db
            .collection("newQuizz")
            .doc(resultado)
            .get();
              setMyQuizes((old) => [
            ...old,
            {
              id: "",
              name:  'Questionário padrão',
              area: docAreas.data().name,
              areaId: docAreas.id,
              process: processIdAndData.data().name,
              processId: processIdAndData.id,
              points: 0,
              questionAnswers: [],
              qtdeQuestions: 0,
              replyDate: "//",
              newQuiz: true,
              status: "Pendente",
            },
          ]);
        }

        for (let item of getNewQuiz.docs) {
          if (item.data() != undefined) {
            setMyQuizes((old) => [
              ...old,
              {
                id: item.id,
                name: item.data().name,
                area: docAreas.data().name,
                areaId: docAreas.id,
                process: processIdAndData.data().name,
                processId: processIdAndData.id,
                points: 0,
                questionAnswers: item.data().questions,
                qtdeQuestions: item.data().questions.length,
                replyDate: item.data().dateReplay
                  ? item.data().dateReplay
                  : "/",
                status: item.data().dateReplay ? "Concluído" : "Pendente",
                newQuiz: true,
              },
            ]);
          }
        }
      } else {
        let getAnswers = await db
          .collection("quiz_registered_answers")
          .where("quiz_id", "==", processIdAndData.data().quizId)
          .where("process_id", "==", processIdAndData.id)
          .where("process_stage", "==", "-1")
          .get();

        let getAnswerss = await db
          .collection("quiz_registered_answers")
          .where("quiz_id", "==", processIdAndData.data().quizId)
          .get();

        if (getAnswers.docs) {
          if (getAnswers.docs[0]) {
            for (let item of getAnswers.docs[0].data().registered_answers) {
              let aaaa = await db
                .collection("questions")
                .doc(item.question_id)
                .get();

              questionAnswers.push({
                processname: processIdAndData.data().name,
                areaName: docAreas.data().name,
                quizName: getQuiz.data().name,
                questionId: item.question_id,
                questionName: aaaa.data().text,
                answerIndex: item.answer,
                answer:
                  item.answer === -1
                    ? ""
                    : aaaa.data().answers[item.answer].text,
                gapName: item.gap.name,
                gapOwner: item.gap.owner,
              });
            }
          }
        }

        setMyQuizes((old) => [
          ...old,
          {
            id: getQuiz.id,
            name: getQuiz.data().name,
            area: docAreas.data().name,
            areaId: docAreas.id,
            process: processIdAndData.data().name,
            processId: processIdAndData.id,
            points: getQuiz.data().points,
            questionAnswers: questionAnswers,
            qtdeQuestions: getAnswerss.docs.length,
            replyDate: getAnswers.docs[0]
              ? getAnswers.docs[0].data().replyDate
                ? getAnswers.docs[0].data().replyDate
                : "/"
              : "/",
            status: getAnswers.docs.map((item) =>
              item.data().replyDate ? "Concluído" : "Pendente"
            ),
          },
        ]);
      }
    } else if (myQuizes.length == 0) {
      setMyQuizes((old) => [
        ...old,
        {
          id: "",
          name: "Sem questionário associado",
          area: docAreas.data().name,
          areaId: docAreas.id,
          process: processIdAndData.data().name,
          processId: processIdAndData.id,
          qtdeQuestions: "",
        },
      ]);
    }
     
   
  };

  // mudando pagina com redux
  const handleChangePage = (item) => {
    dispatch(setLoadingProcess(true));
    dispatch(setProcessArea(item.areaId));
    dispatch(setProcessId(item.processId));
    dispatch(changePage(7));
  };

  //mudando para visualização do PDF
  const handleChangePageViewToPdf = async (item) => {
    dispatch(setName(item.name));
    dispatch(setArea(item.area));
    dispatch(setProcess(item.process));
    dispatch(setStatus(handleSetStatus(item)));
    dispatch(setDateToReply(item.replyDate));
    dispatch(setQuestionsAndAnswers(item.questionAnswers));

    if (item.newQuiz) {
      dispatch(setNewQuizId(item.id));
      dispatch(setNewQuizQuestions(item.questionAnswers));
    }

    dispatch(setNewQuiz(item.newQuiz ? true : false));
    dispatch(changePage(26));
  };

  const handleSetStatus = (item) => {
    if (item.points) {
      if (item.questionAnswers && item.questionAnswers.length > 0) {
        const totalQuestions = item.questionAnswers.length;
        const lastQuestionIndex = totalQuestions - 1;
        const lastQuestionAnswered =
          item.questionAnswers[lastQuestionIndex].answerIndex !== -1;
        if (lastQuestionAnswered) {
          return "Concluído";
        }
        if (item.replyDate == "/") {
          return "Pendente";
        } else {
          return "Pendente";
        }
      }
    } else if (item.replyDate == "/") {
      return "Pendente";
    } else {
      return item.status;
    }
  };

  //aplicando filtro para remover processos sem questionários associados
  const handleProcessWithoutQuestionnaire = (item) => {
    if (item.name !== "Sem questionário associado") {

      if (myQuizes.length > 0) {

        myQuizes.sort((a, b) => {
          if (
            (a.status == "Concluído" && b.status == "Pendente") ||
            (a.status == "Concluído" && b.status == "") ||
            (a.status == "" && b.status == "Concluído") 
          ) {
            return -1;
          }
        
        });
      }
      if (statusValue == "Concluídos" && item.status == "Concluído") {
        
        //     myQuizes.sort((a,b) =>{
        //   return b.replyDate - a.replyDate 
        //  })
        return item;
      }
      if (
        (item.status == "Pendente" && statusValue == "Pendentes") ||
        (statusValue == "Pendentes" && item.status == "")
      ) {
        // myQuizes.sort((a,b) =>{
        //   return b.replyDate - a.replyDate 
        //  })
        return item;
      } else if (statusValue == "") {
        // myQuizes.sort((a,b) =>{
        //   return b.replyDate - a.replyDate 
        //  })
        return item;
        // return console.log(item)
      }
    }
  };

  const handleCloseDialog = (item) => (value) => {
    setDialogs({
      ...dialogs,
      [item]: false,
    });
  };

  const handleSetDateToReplayNewQuiz = (item) => {
    if (item.replyDate == "//") {
      return "Questionário pendente resposta";
    }
    if (item.replyDate != "/") {
      let data = new Date(item.replyDate);
      let dia = String(data.getDate()).padStart(2, "0");
      let mes = String(data.getMonth() + 1).padStart(2, "0");
      let ano = data.getFullYear();
      let dataAtual = dia + "/" + mes + "/" + ano;
      return dataAtual;
    } else {
      return "Processo sem questionário associado";
    }
  };

  const handlePaginationChange = (event, page) => {
    setActivepage(page);
  };

  const filterPageReport = (item, index) => {
    const max = activepage * 5;
    const min = max - 5;

    if (index >= min && index < max) {
      return item;
    }
  };
  return (
    <Grid container>
      {loading == true ? (
        <Grid container style={{ marginTop: "15px" }}>
          <Grid item xs={12} justifyContent="center" align="center">
            <CircularProgress size={50} color={theme.secondary_color} />
          </Grid>
        </Grid>
      ) : (
        myQuizes.length !== 0 && (
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="flex-end"
              style={{ marginTop: "30px" }}
            >
              <ButtonBase>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setStatusValue("");
                    setNonevalue(false);
                    setPaginationValue(true);
                  }}
                  style={{
                    marginRight: "30px",
                    color: theme.secondary_color,
                    fontSize: "15px",
                    backgroundColor: theme.primary_color,
                  }}
                >
                  Limpar
                </Button>
              </ButtonBase>
              <FormControl style={{ width: "20%" }}>
                <InputLabel variant="outlined" id="demo-simple-select-label">
                  Filtrar por status
                </InputLabel>
                <Select
                  variant="outlined"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Filtrar por status"
                  value={statusValue}
                  onChange={(e) => {
                    setStatusValue(e.target.value);
                  }}
                >
                  <MenuItem value={"Concluídos"}>Concluídos</MenuItem>
                  <MenuItem value={"Pendentes"}>Pendentes</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {nonevalue == true ? (
              <Grid
                container
                justifyContent="center"
                style={{ marginTop: "10%" }}
              >
                <Typography variant="h6">
                  Nenhum questionario encontrado
                </Typography>
              </Grid>
            ) : (
              <Card style={{ marginTop: "20px", marginBottom: "20px" }}>
                <ScrollWatcher onScrollBottom={loadMore} populate={loadMore}>
                  <TableContainer style={{ maxHeight: "80vh" }}>
                    <Table stickyHeader size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              fontWeight: "bold",
                              backgroundColor: "#fff",
                              textAlign: "center",
                              fontSize: "18px",
                            }}
                          >
                            Questionário
                          </TableCell>
                          <TableCell
                            style={{
                              fontWeight: "bold",
                              backgroundColor: "#fff",
                              textAlign: "center",
                              fontSize: "18px",
                            }}
                          >
                            Área
                          </TableCell>
                          <TableCell
                            style={{
                              fontWeight: "bold",
                              backgroundColor: "#fff",
                              textAlign: "center",
                              fontSize: "18px",
                            }}
                          >
                            Processo
                          </TableCell>
                          <TableCell
                            style={{
                              fontWeight: "bold",
                              backgroundColor: "#fff",
                              textAlign: "center",
                              fontSize: "18px",
                            }}
                          >
                            Status
                          </TableCell>
                          <TableCell
                            style={{
                              fontWeight: "bold",
                              backgroundColor: "#fff",
                              textAlign: "center",
                              fontSize: "18px",
                            }}
                          >
                            Data de Resposta
                          </TableCell>
                          <TableCell
                            style={{
                              fontWeight: "bold",
                              backgroundColor: "#fff",
                              textAlign: "center",
                              fontSize: "18px",
                            }}
                          >
                            Ações
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {myQuizes
                          .filter(handleProcessWithoutQuestionnaire)
                          .filter(filterPageReport)
                          .map((item, k) => (
                            <Zoom in>
                              <TableRow
                                key={k}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                                style={
                                  k % 2
                                    ? { background: "white" }
                                    : { background: "#e3e3e3" }
                                }
                              >
                                <TableCell
                                  onClick={() => handleChangePage(item)}
                                  align="center"
                                  style={{ fontWeight: "Bold" }}
                                >
                                  {item.name}
                                </TableCell>
                                <TableCell
                                  onClick={() => handleChangePage(item)}
                                  align="center"
                                  style={{ fontWeight: "Bold" }}
                                >
                                  {item.area}
                                </TableCell>
                                <TableCell
                                  onClick={() => handleChangePage(item)}
                                  align="center"
                                  style={{ fontWeight: "Bold" }}
                                >
                                  {item.process}
                                </TableCell>
                                <TableCell
                                  onClick={() => handleChangePage(item)}
                                  align="center"
                                  style={{
                                    fontWeight: "Bold",
                                    color:
                                      item.status == "Concluído"
                                        ? theme.primary_color
                                        : "#f00",
                                  }}
                                >
                                  {item.replyDate == "/"
                                    ? "Pendente"
                                    : item.status}
                                </TableCell>
                                <TableCell
                                  onClick={() => handleChangePage(item)}
                                  align="center"
                                  style={{ fontWeight: "Bold" }}
                                >
                                  {handleSetDateToReplayNewQuiz(item)}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontWeight: "Bold" }}
                                >
                                  <Button
                                    onClick={() =>
                                      handleChangePageViewToPdf(item)
                                    }
                                    disabled={
                                      !item.id || item.replyDate == "/"
                                        ? true
                                        : false
                                    }
                                    style={{
                                      background:
                                        item.status == "Concluído"
                                          ? theme.primary_color
                                          : "#ccc",
                                      color: theme.secondary_color,
                                    }}
                                  >
                                    Visualizar PDF
                                  </Button>
                                </TableCell>
                              </TableRow>
                            </Zoom>
                          ))}

                      </TableBody>
                    </Table>
                  </TableContainer>
                </ScrollWatcher>
              </Card>
            )}
          </Grid>
        )
      )}

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ paddingTop: "20px" }}
      >
        <Grid style={{ flexGrow: 1 }} />
        {paginationValue == true && (
          <Grid item>
            {myQuizes.length > 5 ? (
              <Pagination
                count={
                  myQuizes.length % 5 === 0
                    ? myQuizes.length / 5
                    : Math.floor(myQuizes.length / 5) + 1
                }
                style={{ color: theme.secondary_color }}
                page={activepage}
                onChange={(event, page) => {
                  handlePaginationChange(event, page);
                }}
              />
            ) : (
              <></>
            )}
          </Grid>
        )}
      </Grid>

      <Dialog
        fullWidth
        open={dialogs.informe}
        onClose={handleCloseDialog("informe")}
      >
        <DialogTitle>Aviso de sistema!</DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item textAlign="center">
              <Typography variant="h5">
                Estamos atualizando nosso relatório para melhor atender às suas
                necessidades. Por enquanto, a visualização em PDF desse
                questionário não está disponível. Por favor, tente novamente
                mais tarde ou entre em contato conosco se precisar de mais
                informações. Obrigado pela sua compreensão.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              backgroundColor: "rgb(56, 242, 5)",
            }}
            onClick={handleCloseDialog("informe")}
            autoFocus
          >
            Ok!
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default QuestionnaireReport;
