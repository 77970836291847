import React from 'react';

const actionPlansIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-5 0 40.355 33.049">
            <g id="Group_7" data-name="Group 7" transform="translate(2385 2916.412)">
                <g id="_34d50aad0209c58a3938915d430eae89" data-name="34d50aad0209c58a3938915d430eae89" transform="translate(-2385 -2916.412)">
                <path id="Path_7" data-name="Path 7" d="M98.877,13.541a2.118,2.118,0,0,0-2.2,2.269V40.5a2.228,2.228,0,0,0,2.236,2.306h22.453a2.1,2.1,0,0,0,2.306-2.253V15.777a2.132,2.132,0,0,0-2.269-2.259h-2.226v1.627h2.162s.733-.06.72.576V40.61s.047.626-.505.626H98.821a.518.518,0,0,1-.546-.576V15.817s-.054-.7.6-.686h2.336v-1.6Zm15.491-.023-2.644-2.872a2.269,2.269,0,0,0-1.587-.633,2.2,2.2,0,0,0-1.593.623l-2.574,2.882h-2.554v1.6h13.509v-1.6Zm-6.132,0,1.57-1.838a.434.434,0,0,1,.706-.017l1.516,1.854Z" transform="translate(-96.678 -10.01)" fill="#fff"/>
                </g>
                <path id="Icon_awesome-list-ol" data-name="Icon awesome-list-ol" d="M1.584,11.71l.449-.517a.511.511,0,0,0,.13-.364v-.085a.26.26,0,0,0-.291-.291H.41a.205.205,0,0,0-.205.205v.41a.205.205,0,0,0,.205.205H1a4.035,4.035,0,0,0-.282.316l-.144.179a.341.341,0,0,0-.072.381l.027.049a.312.312,0,0,0,.314.2H.96c.265,0,.409.063.409.233,0,.121-.108.211-.368.211a1.065,1.065,0,0,1-.4-.08c-.166-.1-.3-.09-.4.08l-.143.239a.276.276,0,0,0,.067.409,1.841,1.841,0,0,0,.949.242A1.124,1.124,0,0,0,2.32,12.6.927.927,0,0,0,1.584,11.71ZM12.716,7.172h-8.2a.41.41,0,0,0-.41.41V8.4a.41.41,0,0,0,.41.41h8.2a.41.41,0,0,0,.41-.41v-.82A.41.41,0,0,0,12.716,7.172Zm0-4.1h-8.2a.41.41,0,0,0-.41.41V4.3a.41.41,0,0,0,.41.41h8.2a.41.41,0,0,0,.41-.41v-.82A.41.41,0,0,0,12.716,3.07Zm0,8.2h-8.2a.41.41,0,0,0-.41.41v.82a.41.41,0,0,0,.41.41h8.2a.41.41,0,0,0,.41-.41v-.82A.41.41,0,0,0,12.716,11.274ZM.41,5.532H2.051a.205.205,0,0,0,.205-.205v-.41a.205.205,0,0,0-.205-.205h-.41V2.455a.205.205,0,0,0-.205-.205H.82a.205.205,0,0,0-.183.113l-.205.41a.205.205,0,0,0,.183.3H.82V4.711H.41a.205.205,0,0,0-.205.205v.41a.205.205,0,0,0,.205.205Zm-.1,4.1H2.051a.205.205,0,0,0,.205-.205v-.41a.205.205,0,0,0-.205-.205H1.059C1.144,8.549,2.3,8.334,2.3,7.366a1.038,1.038,0,0,0-1.14-1.014,1.243,1.243,0,0,0-1.037.481.253.253,0,0,0,.072.394l.22.176c.144.117.282.063.413-.063a.345.345,0,0,1,.243-.1.218.218,0,0,1,.238.224C1.307,7.792,0,8.026,0,9.238v.1A.281.281,0,0,0,.31,9.633Z" transform="translate(-2375.126 -2901.235)" fill="#fff"/>
                <g id="process" transform="translate(-2335.281 -2871.45)">
                <path id="Path_4" data-name="Path 4" d="M9.592,4.009H8.677a3.888,3.888,0,0,0-.239-.6l.718-.718a.29.29,0,0,0,0-.41L7.878,1.006a.29.29,0,0,0-.41,0l-.7.7a3.886,3.886,0,0,0-.61-.261V.454a.29.29,0,0,0-.29-.29H4.068a.29.29,0,0,0-.29.29v.966a3.884,3.884,0,0,0-.639.263l-.626-.626a.29.29,0,0,0-.41,0L.826,2.334a.29.29,0,0,0,0,.41l.631.631a3.885,3.885,0,0,0-.258.635H.29A.29.29,0,0,0,0,4.3V6.105a.29.29,0,0,0,.29.29h.941a3.887,3.887,0,0,0,.255.594l-.672.672a.29.29,0,0,0,0,.41L2.091,9.348a.29.29,0,0,0,.41,0l.7-.7a3.889,3.889,0,0,0,.546.222v.874a.29.29,0,0,0,.29.29H5.838a.29.29,0,0,0,.29-.29V8.877a3.886,3.886,0,0,0,.59-.243l.72.72a.29.29,0,0,0,.41,0L9.124,8.077a.29.29,0,0,0,0-.41l-.716-.716A3.893,3.893,0,0,0,8.645,6.4h.947a.29.29,0,0,0,.29-.29V4.3a.29.29,0,0,0-.29-.29ZM9.3,5.816H8.43a.29.29,0,0,0-.28.214,3.31,3.31,0,0,1-.35.825.29.29,0,0,0,.044.353l.665.665-.868.868-.669-.669a.29.29,0,0,0-.352-.045,3.308,3.308,0,0,1-.856.353.29.29,0,0,0-.218.281v.8H4.321v-.8a.29.29,0,0,0-.217-.28,3.31,3.31,0,0,1-.817-.332.29.29,0,0,0-.349.047L2.3,8.734l-.868-.868.624-.624a.29.29,0,0,0,.042-.356,3.31,3.31,0,0,1-.369-.857.29.29,0,0,0-.28-.214H.579V4.589H1.42A.29.29,0,0,0,1.7,4.367a3.306,3.306,0,0,1,.363-.9.29.29,0,0,0-.045-.352l-.579-.579.868-.868.576.575a.29.29,0,0,0,.353.044,3.306,3.306,0,0,1,.9-.37.29.29,0,0,0,.22-.281v-.9H5.585v.913a.29.29,0,0,0,.215.28,3.31,3.31,0,0,1,.872.373.29.29,0,0,0,.356-.042l.647-.647.868.868-.665.665A.29.29,0,0,0,7.83,3.5a3.308,3.308,0,0,1,.344.865.29.29,0,0,0,.282.222H9.3Z" transform="translate(-46 -38.164)" fill="#fff"/>
                </g>
                <path id="Icon_metro-pencil" data-name="Icon metro-pencil" d="M14.074,1.928a2.137,2.137,0,0,1,1.7,3.417l-.852.854-2.982-2.99.852-.854a2.117,2.117,0,0,1,1.278-.427ZM3.423,11.752,2.571,15.6l3.834-.854,7.882-7.9L11.3,3.85ZM12.1,6.781,6.133,12.76,5.4,12.024l5.965-5.98.734.736Z" transform="translate(-2367.131 -2892.573) rotate(-45)" fill="#fff"/>
            </g>
        </svg>
    );
}

export default actionPlansIcon;