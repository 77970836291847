import React, { Component } from "react";
import Icon from "@material-ui/core/Icon";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { connect } from "react-redux";
import { Link } from "react-router-dom"
import myApp from "../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";

import { Grid, Card, Typography, Switch } from "@material-ui/core";

import "./solicitationChannelStyle.css"

const menuStyle = {
	fontWeight: "regular",
	color: "#111C39",
	fontSize: "26px",
	lineSpacing: "34"
};

class CanalSolicitacoes extends Component {
	constructor(props) {
		super(props);

		this.state = {
			database: myApp.firestore(),
			checked: false,
			openBox: true,
			currentUser: localStorage.getItem("currentUser")
		};
	}

	componentDidMount() {
		this.getConsent();
	}

	getConsent() {
		console.log("getConsent()");
		if (this.state.currentUser) {
			console.log("this.state.currentUser");
			this.state.database.collection("data_consent").doc(this.state.currentUser).get().then((doc) => {
				if (doc.exists && (typeof doc.data().consent !== typeof undefined)) {
					this.setState({
						checked: doc.data().consent
					})
				} else {
					console.log("no consent for user " + this.state.currentUser);
				}
			})
		}
	}

	setConsent() {
		this.state.database.collection("data_consent").doc(this.state.currentUser).set({
			consent: !this.state.checked
		})
			.then(() => {
				console.log("User " + this.state.currentUser + " consent changed to " + this.state.checked);
			})
			.catch((error) => {
				console.error("Error writing document: ", error);
			});
	}

	handleChange() {
		this.setConsent();
		this.setState({ checked: !this.state.checked })
	}

	handleOpen(index) {
		switch (index) {
			case "consentimento": this.setState({ openBox: !this.state.openBox })
				break;
			default:
				break;
		}
	}

	handleFollowUpClick() {
		console.log("follow up");
	}

	render() {
		return (
			<>
				<Grid container direction="column" spacing={4}>
					<Grid item >
						<Card className="containerBox">
							<Grid container spacing={3}>
								<Grid item md={6}>
								</Grid>
								<Grid
									container
									direction="row"
									justify="flex-end"
									alignItems="center"
									item md={6}
								>
									<Typography
										variant="h5"
										style={{ color: "white" }}
									>
										O que é e qual o objetivo central LGPD
									</Typography>
									<Typography
										variant="subtitle1"
										style={{ color: "white", textAlign: "end", padding: "10px  0px" }}
									>
										É a sigla para Lei Geral de Proteção de Dados que tem como
										objetivo central regulamentar o uso de dados pessoais e
										proporcionar mais controle aos usuários sobre suas informações
										pessoais e confidenciais.
									</Typography>
								</Grid>
							</Grid>
						</Card>
					</Grid>
				</Grid>
				<Grid
					container
					direction="row"
					justify="center"
					alignItems="center"
				>
					<Link
						variant="contained"
						color="primary"
						className="buttonFullStyle"
						endIcon={<Icon><NavigateNextIcon /></Icon>}
						to="/personal-data-management-and-access"
					>
						<Grid
							container
							direction="row"
							justify="space-between"
							alignItems="center"
						>
							<Typography style={menuStyle}>Acesso e Gerenciamento de Dados Pessoais</Typography>
							<NavigateNextIcon />
						</Grid>
					</Link>
					<Link
						variant="contained"
						color="primary"
						className="buttonFullStyle"
						onClick={() => this.handleOpen("consentimento")}
					>
						<Grid
							container
							direction="row"
							justify="space-between"
							alignItems="center"
						>
							<Typography style={menuStyle}>Consentimento</Typography>
							<NavigateNextIcon />
						</Grid>
					</Link>
					<Grid container direction="column" spacing={4} style={this.state.openBox ? { display: "none" } : { display: "block", marginTop: 5 }}>
						<Grid item >
							<Card className="tittlePage">
								<Grid item md={8}>
									<Typography variant="subtitle1" className="subtittleStyle" >
										Utilizamos seus dados para oferecer as
										melhores opções de produtos e serviços de seu interesse. Caso, você
										não concorde com o compartilhamento de seus dados entre Bradesco e
										empresas ligadas com parceiros, você pode desabilitar a opção abaixo
									</Typography>
									<div style={{ marginTop: 40 }} />
									<Grid container>
										<Grid item md={6}>
											<Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
												Consentimento de dados
											</Typography>
											<Typography variant="subtitle1" className="subtittleStyle" >
												Compartilhamento entre Bradesco e empresas ligadas com parceiros
											</Typography>
										</Grid>
										<Grid item md={6}>
											<Switch
												checked={this.state.checked}
												onChange={() => this.handleChange()}
												color="primary"
												name="checkedB"
												inputProps={{ "aria-label": "primary checkbox" }}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Card>
						</Grid>
					</Grid>
					<Link
						onClick={(event) => { this.handleFollowUpClick(event) }}
						variant="contained"
						color="primary"
						className="buttonFullStyle"
						to="/follow-up"
					>
						<Grid
							container
							direction="row"
							justify="space-between"
							alignItems="center"
						>
							<Typography
								style={menuStyle}>
								Acompanhamento
							</Typography>
							<NavigateNextIcon />
						</Grid>
					</Link>
				</Grid>
				<br></br>
			</>
		);
	}
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(CanalSolicitacoes);