import React, { Component } from "react";
import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";

import {
  Paper,
  Grid,
  Typography,
  ButtonBase,
  LinearProgress,
} from "@material-ui/core";

import { connect } from "react-redux";
import { changePage } from "../../../presentation/redux/actions";
import { setArea } from "../redux/actions";
import areaIconList from "../../../../shared/assets/areaIconList";

import theme from "../../../../core/theme.json";

class AreaItem extends Component {
  constructor(props) {
    super(props);

    this.db = myApp.firestore();

    this.state = {
      percentage: 0,
    };
  }

  componentDidMount() {
    this.getAreaPercentage();
  }

  toAreaPage(id) {
    this.props.changePage(1);
    this.props.setArea(id);
  }

  getIcon() {
    for (var i = 0; i < areaIconList.length; i++) {
      if (this.props.icon === areaIconList[i].value) {
        var areaIcon = areaIconList[i];

        return (
          <areaIcon.icon
            style={{ fontSize: 76, color: theme.tertiary_color }}
          />
        );
      }
    }
  }

  getAreaPercentage() {
    setTimeout(
      () =>
        this.setState({
          percentage: this.props.percentage,
        }),
      750
    );
  }

  render() {
    return (
      <Grid container direction="row" spacing={4} style={{ height: "320px" }}>
        <Grid item style={{ width: "100%", height: "100%" }}>
          <ButtonBase
            onClick={() => {
              this.toAreaPage(this.props.id);
            }}
            style={{ width: "100%", height: "100%", borderRadius: "8px" }}
          >
            <Paper
              style={{ width: "100%", height: "100%", borderRadius: "8px" }}
            >
              <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
                style={{ height: "100%" }}
              >
                <Grid item style={{ height: "15%", width: "100%" }}>
                  <Grid container direction="row" style={{ width: "100%" }}>
                    <Grid item style={{ width: "100%" }}>
                      <LinearProgress
                        variant="determinate"
                        value={this.state.percentage}
                        color="primary"
                        style={{ margin: "24px" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item style={{ height: "85%", width: "100%" }}>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    style={{ height: "100%" }}
                  >
                    <Grid
                      item
                      container
                      justify="center"
                      alignItems="center"
                      style={{ height: "50%" }}
                    >
                      {this.getIcon()}
                    </Grid>
                    <Grid
                      item
                      container
                      justify="center"
                      style={{ width: "100%", height: "50%" }}
                    >
                      <Grid
                        item
                        container
                        justify="center"
                        style={{
                          width: "100%",
                          height: "90%",
                          overflowY: "hidden",
                          textOverflow: "ellipsis",
                          margin: "0 10px 0 10px",
                        }}
                      >
                        <Typography
                          variant="h5"
                          component="h5"
                          style={{ overflowWrap: "anywhere", color: "#6A6A6A" }}
                        >
                          {this.props.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </ButtonBase>
        </Grid>
      </Grid>
    );
  }
}

export default connect(null, { changePage, setArea })(AreaItem);
