import React, { Component } from 'react';
import myApp from '../../core/infrastructure/firebaseConfig';
import "firebase/compat/firestore";

import { addCompanyInfo } from '../redux/actions';

import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import validateCNPJ from '../../shared/commands/validations/validateCNPJ';

import InputMask from 'react-input-mask';

const TextMaskCustomCNPJ = (props) => {
  const { inputRef, ...other } = props;

  return (
    <InputMask
      {...other}
      ref={inputRef}
      mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      showMask
    />
  );
}

const TextMaskCustomCEP = (props) => {
  const { inputRef, ...other } = props;

  return (
    <InputMask
      {...other}
      ref={inputRef}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
      showMask
    />
  );
}

class CompanyObject {
  nickName = "";
  name = "";
  porteEmpresa = "";
  document = "";
  street = "";
  number = "";
  complement = "";
  city = "";
  neighborhood = "";
  state = "";
  CEP = "";
  validDocument = true;
}

class CompanyRegistrationInfo extends Component {
  constructor(props) {
    super(props);

    this.db = myApp.firestore();

    this.state = {
      nickName: "",
      name: "",
      porteEmpresa: "",
      document: "",
      street: "",
      number: "",
      complement: "",
      city: "",
      neighborhood: "",
      state: "",
      CEP: "",
      validDocument: true
    }
  }

  componentDidMount() {
    this.setState({
      nickName: this.props.companyNickName,
      name: this.props.companyName,
      porteEmpresa: this.props.companySize,
      document: this.props.companyDocument,
      street: this.props.companyStreet,
      number: this.props.companyNumber,
      complement: this.props.companyComplement,
      city: this.props.companyCity,
      neighborhood: this.props.companyNeighborhood,
      state: this.props.companyState,
      CEP: this.props.companyCEP
    })
  }

  handlePorteEmpresa(event) {
    console.log(event.target.value);
    this.setState({
      porteEmpresa: event.target.value
    }, () => this.handleRedux());
  }

  handleNickName(event) {
    this.setState({
      nickName: event.target.value
    }, () => this.handleRedux());
  }

  handleName(event) {
    this.setState({
      name: event.target.value
    }, () => this.handleRedux());
  }

  handleStreet(event) {
    this.setState({
      street: event.target.value
    }, () => this.handleRedux());
  }

  handleNumber(event) {
    if (event.target.value && event.target.value !== "" && !Number(event.target.value)) {
      return;
    }

    this.setState({
      number: event.target.value
    }, () => this.handleRedux());
  }

  handleComplement(event) {
    this.setState({
      complement: event.target.value
    }, () => this.handleRedux());
  }

  handleCity(event) {
    this.setState({
      city: event.target.value
    }, () => this.handleRedux());
  }

  handleNeighborhood(event) {
    this.setState({
      neighborhood: event.target.value
    }, () => this.handleRedux());
  }

  handleState(event) {
    this.setState({
      state: event.target.value
    }, () => this.handleRedux());
  }

  handleCEP(event) {
    this.setState({
      CEP: event.target.value
    }, () => this.handleRedux());
  }

  handleDocument(event) {
    this.setState({
      document: event.target.value
    }, () => this.handleRedux());

    let doc = event.target.value;
    doc = doc.replace(/\D/g, "");

    if (doc && doc !== "" && this.validateDocument(event.target.value)) {
      this.setState({
        validDocument: true
      }, () => this.handleRedux());
    } else {
      this.setState({
        validDocument: false
      }, () => this.handleRedux());
    }
  }

  // Validando se o CNPJ já esta cadastrado
  async handleValidationCNPJ(e) {
    let validation = []
    let getCompany = await myApp.firestore().collection("companies").where("cnpj", "==", e.target.value).get();

    for (let doc of getCompany.docs) {
      validation.push(doc.data());
    }

    if (validation.length > 0) {
      alert('Já existe uma empresa com esse CNPJ. Verifique!');
      this.setState({
        document: '',
      });
    }
  }

  // Buscando e preenchando dados ( logradouro, bairro, cidade, estado ) pelo CEP
  async handleValidationCEP(e) {
    if (e.target.value.length > 0) {
      const req = await fetch("https://viacep.com.br/ws/" + e.target.value + "/json/");
      const json = await req.json();

      if (json) {
        this.setState({
          street: json.logradouro,
          city: json.localidade,
          neighborhood: json.bairro,
          state: json.uf
        })
      }
    }
  }

  handleRedux() {
    var object = new CompanyObject();

    object.nickName = this.state.nickName;
    object.name = this.state.name;
    object.porteEmpresa = this.state.porteEmpresa;
    object.document = this.state.document;
    object.street = this.state.street;
    object.number = this.state.number;
    object.complement = this.state.complement;
    object.city = this.state.city;
    object.neighborhood = this.state.neighborhood;
    object.state = this.state.state;
    object.CEP = this.state.CEP;
    object.validDocument = this.state.validDocument;

    this.props.addCompanyInfo(object);
  }

  validateDocument(document) {
    return validateCNPJ(document);
  }

  render() {

    const ERROR_MESSAGE_CNPJ = !this.state.validDocument ? "Digite um CNPJ válido" : "";

    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={4}
      >
        <Grid item style={{ width: "100%" }}>
          <TextField
            inputProps={{
              maxLength: 50
            }}
            label={"Apelido da empresa"}
            variant={"outlined"}
            style={{ width: "100%" }}
            color={"#203673"}
            value={this.state.nickName}
            onChange={(event) => { this.handleNickName(event) }}
          />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <TextField
            inputProps={{
              maxLength: 115
            }}
            label="Razão Social"
            variant="outlined"
            style={{ width: "100%" }}
            color={"#203673"}
            value={this.state.name}
            onChange={(event) => { this.handleName(event) }}
          />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <FormControl fullWidth>
            <InputLabel variant="outlined" id="demo-simple-select-label" >Porte da empresa</InputLabel>
            <Select
              variant="outlined"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.porteEmpresa}
              label="Porte da empresa"
              onChange={(event) => { this.handlePorteEmpresa(event) }}
            >
              <MenuItem value={'Pequeno'}>Pequeno</MenuItem>
              <MenuItem value={'Médio'}>Médio</MenuItem>
              <MenuItem value={'Grande'}>Grande</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <TextField
            label="CNPJ"
            variant="outlined"
            style={{ width: "100%" }}
            color={"#203673"}
            value={this.state.document}
            error={!this.state.validDocument}
            helperText={ERROR_MESSAGE_CNPJ}
            onChange={(event) => { this.handleDocument(event) }}
            onBlur={(e) => this.handleValidationCNPJ(e)}
            InputProps={{
              inputComponent: TextMaskCustomCNPJ
            }}
          />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <TextField
            label="CEP"
            variant="outlined"
            style={{ width: "100%" }}
            color={"#203673"}
            value={this.state.CEP}
            onChange={(event) => { this.handleCEP(event) }}
            onBlur={(e) => this.handleValidationCEP(e)}
            InputProps={{
              inputComponent: TextMaskCustomCEP
            }}
          />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <TextField
            inputProps={{
              maxLength: 115
            }}
            label="Logradouro"
            variant="outlined"
            disabled
            style={{ width: "100%" }}
            color={"#203673"}
            value={this.state.street}
            onChange={(event) => { this.handleStreet(event) }}
          />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <TextField
            inputProps={{
              maxLength: 115
            }}
            label="Número"
            variant="outlined"
            style={{ width: "100%" }}
            color={"#203673"}
            value={this.state.number}
            onChange={(event) => { this.handleNumber(event) }}
          />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <TextField
            inputProps={{
              maxLength: 115
            }}
            label="Complemento"
            variant="outlined"
            style={{ width: "100%" }}
            color={"#203673"}
            value={this.state.complement}
            onChange={(event) => { this.handleComplement(event) }}
          />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <TextField
            inputProps={{
              maxLength: 115
            }}
            label="Bairro"
            variant="outlined"
            disabled
            style={{ width: "100%" }}
            color={"#203673"}
            value={this.state.neighborhood}
            onChange={(event) => { this.handleNeighborhood(event) }}
          />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <TextField
            inputProps={{
              maxLength: 115
            }}
            label="Cidade"
            variant="outlined"
            disabled
            style={{ width: "100%" }}
            color={"#203673"}
            value={this.state.city}
            onChange={(event) => { this.handleCity(event) }}
          />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <TextField
            inputProps={{
              maxLength: 115
            }}
            label="Estado"
            variant="outlined"
            disabled
            style={{ width: "100%" }}
            color={"#203673"}
            value={this.state.state}
            onChange={(event) => { this.handleState(event) }}
          />
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    companyNickName: state.companyReducer.companyNickName,
    companyName: state.companyReducer.companyName,
    companySize: state.companyReducer.companySize,
    companyDocument: state.companyReducer.companyDocument,
    companyStreet: state.companyReducer.companyStreet,
    companyNumber: state.companyReducer.companyNumber,
    companyComplement: state.companyReducer.companyComplement,
    companyCity: state.companyReducer.companyCity,
    companyNeighborhood: state.companyReducer.companyNeighborhood,
    companyState: state.companyReducer.companyState,
    companyCEP: state.companyReducer.companyCEP,
    companyValidDocument: state.companyReducer.companyValidDocument,

  };
}

export default connect(mapStateToProps, { addCompanyInfo })(CompanyRegistrationInfo);