//definindo states iniciais caso não receba
const initialState = {
  obj: [],
};

//definindo os tipos de cada função
const SET_OBJ = "SET_OBJ";
const CHANGE_PAGE = "CHANGE_PAGE";

//função recebe e altera o obj
export const setObj = data => ({
  type: SET_OBJ,
  data
});

//criando o reducer para o combine
const incidentesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OBJ:
      return { ...state, obj: action.data };
  }
  return state;
}

export default incidentesReducer;