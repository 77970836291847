import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import myApp from '../../../core/infrastructure/firebaseConfig'
import 'firebase/compat/firestore'
import { addDays } from '../../../shared/commands/dateFunctions'
import validateCPF from '../../../shared/commands/validations/validateCPF'
import InputMask from 'react-input-mask'
import DateFnsUtils from '@date-io/date-fns'
import { ptBR } from 'date-fns/locale'
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from '@material-ui/pickers'
import { changePage } from '../../../dashboard/presentation/redux/actions'
import theme from '../../../core/theme.json'
import { ArrowBackIosTwoTone } from '@material-ui/icons'
import Title from '../../../shared/components/title'


const TextMaskCustomCPF = (props) => {
  const { inputRef, ...other } = props

  return (
    <InputMask
      {...other}
      ref={inputRef}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
    />
  )
}

const TextMaskCustomPhone = (props) => {
  const { inputRef, ...other } = props

  return (
    <InputMask
      {...other}
      ref={inputRef}
      mask={[
        '(',
        /\d/,
        /\d/,
        ')',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
       '-',
       /\d/,
       /\d/,
       /\d/,
       /\d/,
       /\d/,
      ]}
    />
  )
}


class SolicitationFormNew extends Component {
  constructor (props) {
    super(props)

    this.state = {
      database: myApp.firestore(),
      personType: '',
      solicitationType: '',
      companyId: localStorage.getItem('currentCompany'),
      companyName: '',
      name: '',
      lastName: '',
      email: '',
      document: '',
      phone: '',
      additionalInformation: '',
      birthDate: null,
      personTypes: [
        {
          id: 'eBDU5z0LRVkbIWRQrT1e',
          type: 'Consumidor',
        },
        {
          id: 'DmQR9TIWT0A4yXPzoV4p',
          type: 'Fornecedores / Parceiros',
        },
        {
          id: 'WjJlveSwVWkslsyTA5Hb',
          type: 'Colaborador',
        }
      ],
      solicitationTypes: [
        {
          id: 'cPwvBj34SKhmL83eR7de',
          type: 'Solicitação de correção',
        },
        {
          id: '8aSVq89q5eSeIuGCpx7Z',
          type: 'Solicitação de revogação',
        },
        {
          id: 'MPGZZlP89KUgAMb9TIlP',
          type: 'Solicitação de informações',
        }
      ],
      sendButtonDisabled: true,
      validDocument: true,
      loading: true,
      expectedDate: '',
      userInformation: JSON.parse(
        sessionStorage.getItem('@blue-legal/userInformation')
      ),
    }
  }

  async componentDidMount() {
    await this.getPersonTypes();
    await this.getSolicitationTypes();
    await this.getCompanyName();
    await this.getExpectedDate()
    this.setState({
      loading: false,
    })
  }

  async getExpectedDate(){
    let querySnapshot = await myApp.firestore().collection("solicitationParameters").where("fk_company_group", "==", localStorage.getItem("currentCompany") ).get();
    this.setState({
      expectedDate: querySnapshot ? querySnapshot.docs[0].data().expectedDate : 15
    })
  }

  async getPersonTypes() {
    let personTypes = this.state.personTypes;
    /*try {
      let personTypesData = await this.state.database
      .collection("personTypes")
      .where("in_company", "==", localStorage.getItem("currentCompany"))
      .get();

      for (let doc in personTypesData.docs) {
        let personType = {
          id: personTypesData.docs[doc].ref.id,
          type: personTypesData.docs[doc].data().type,
        }

        personTypes.push(personType)
      }
    } catch (error) {
      console.log(error)
    }*/

    let personTypesData = await this.state.database
      .collection("personTypes")
      .where("in_company", "==", localStorage.getItem("currentCompany"))
      .get();

      for (let doc in personTypesData.docs) {
        let personType = {
          id: personTypesData.docs[doc].ref.id,
          type: personTypesData.docs[doc].data().type,
        }

        personTypes.push(personType)
      }

      let personTypesDataHolders = await this.state.database
      .collection("holders")
      .where("in_company", "==", localStorage.getItem("currentCompany"))
      .get();

      for (let doc of personTypesDataHolders.docs) {
        let personType = {
          id: doc.id,
          type: doc.data().name,
        }

        personTypes.push(personType)
      }

    this.setState({
      personTypes,
    })
  }

  async getSolicitationTypes () {
    let solicitationTypes = this.state.solicitationTypes;
    try {
      let solicitationTypesData = await this.state.database
      .collection("solicitationTypes")
      .where("in_company", "==", localStorage.getItem("currentCompany"))
      .get()

      for (let doc in solicitationTypesData.docs) {
        let solicitationType = {
          id: solicitationTypesData.docs[doc].ref.id,
          type: solicitationTypesData.docs[doc].data().type,
        }

        solicitationTypes.push(solicitationType)
      }
    } catch (error) {
      console.log(error)
    }

    this.setState({
      solicitationTypes,
    })
  }

  async getCompanyName () {
    let companyName = ''
    try {
      let companyData = await this.state.database
        .collection('companies')
        .doc(this.state.companyId)
        .get()

      companyName = companyData.data().nome
    } catch (error) {
      console.log(error)
    }

    this.setState({
      companyName,
    })
  }

  handlePersonType (event) {
    this.setState(
      {
        personType: event.target.value,
      },
      () => {
        this.handleSendButton()
      }
    )
  }

  handleSolicitationType (event) {
    this.setState(
      {
        solicitationType: event.target.value,
      },
      () => {
        this.handleSendButton()
      }
    )
  }

  handleName (event) {
    this.setState(
      {
        name: event.target.value,
      },
      () => {
        this.handleSendButton()
      }
    )
  }

  handleLastName (event) {
    this.setState(
      {
        lastName: event.target.value,
      },
      () => {
        this.handleSendButton()
      }
    )
  }

  handleEmail (event) {
    this.setState(
      {
        email: event.target.value,
      },
      () => {
        this.handleSendButton()
      }
    )
  }

  handleDocument (event) {
    this.setState(
      {
        document: event.target.value,
        validDocument: true,
      },
      () => {
        this.handleSendButton()
      }
    )

    let doc = event.target.value
    doc = doc.replace(/\D/g, '')

    if (doc && doc !== '' && !validateCPF(event.target.value)) {
      this.setState(
        {
          validDocument: false,
        },
        () => this.handleSendButton()
      )
    }
  }

  handlePhone (event) {
    this.setState(
      {
        phone: event.target.value,
      }
    )
  }

  handleAdditionalInformation(event) {
    this.setState(
      {
        additionalInformation: event.target.value,
      }
    )
  }

  handleBirthDate (value) {
    this.setState(
      {
        birthDate: value,
      },
      () => {
        this.handleSendButton()
      }
    )
  }

  handleSendButton () {
    let doc = this.state.document
    doc = doc.replace(/\D/g, '')

    if (
      this.state.personType !== '' &&
      this.state.solicitationType !== '' &&
      this.state.name !== '' &&
      this.state.lastName !== '' &&
      this.state.email !== '' &&
      //doc !== '' &&
      this.state.birthDate &&
      !isNaN(this.state.birthDate.getTime()) &&
      this.state.validDocument
    ) {
      this.setState({
        sendButtonDisabled: false,
      })
    } else {
      this.setState({
        sendButtonDisabled: true,
      })
    }
  }

  async saveSolicitation () {
    this.setState({
      loading: true,
    })

    let data = new Date(Date.now())
    let year = data.getFullYear().toString()

    let monthInt = data.getMonth() + 1
    let month = monthInt.toString()
    if (month.length === 1) {
      month = '0' + month
    }

    let day = data.getDate().toString()
    if (day.length === 1) {
      day = '0' + day
    }

    let random = Math.floor(Math.random() * 10000000 + 1).toString()

    let protocol = year + month + day + random

    try {
      await this.state.database.collection('solicitations').add({
        response: '',
        status: 'Aberto',
        conclusionDate: null,
        done: false,
        fk_personType: this.state.personType,
        fk_solicitationType: this.state.solicitationType,
        fk_company: this.state.companyId,
        name: this.state.name,
        lastName: this.state.lastName,
        email: this.state.email,
        document: this.state.document,
        phone: this.state.phone,
        additionalInformation: this.state.additionalInformation,
        birthDate: this.state.birthDate,
        createDate: new Date(Date.now()),
        limitDate: addDays(Date.now(), parseInt(this.state.expectedDate) ),
        protocol: protocol,
      })
    } catch (err) {
      console.log(err)
    }

    this.props.changePage(16)
  }

  renderLoading () {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{ paddingTop: '50px' }}
      >
        <CircularProgress size={60} color={theme.secondary_color}/>
      </Grid>
    )
  }

  render () {
    return (
      <>
        <Title>
          <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
            <Grid item>
              <Button
                variant="text"
                style={{
                  fontSize: '15px',
                }}
                onClick={() => {
                  this.props.changePage(16)
                }}
              >
                <ArrowBackIosTwoTone/>
              </Button>
            </Grid>
            <Grid item>Nova Solicitação</Grid>
          </Grid>
        </Title>
        {this.state.loading ? (
          this.renderLoading()
        ) : (
          <>
            <Grid
              component={Paper}
              container
              direction="column"
              alignItems="center"
              justify="center"
              elevation={2}
              style={{ padding: '15px', marginTop: 10 }}
            >
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justify="center"
                style={{ width: '80%' }}
              >
                <Grid item>
                  <Typography
                    variant="h4"
                    component="h4"
                    style={{ 
                      width: '100%'
                    }}
                  >
                    Nova Solicitação
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justify="center"
                style={{ width: '80%', marginTop: '15px' }}
              >
                <Grid item container direction="column" spacing={1} xs={4}>
                  <Grid item>
                    <Typography
                      variant="h6"
                      component="h6"
                      style={{ paddingLeft: '30px' }}
                    >
                      Tipo de solicitante
                    </Typography>
                  </Grid>
                  <Grid item>
                    <FormControl
                      variant="outlined"
                      style={{ width: '85%', paddingLeft: '30px' }}
                    >
                      <Select
                        value={this.state.personType}
                        onChange={(event) => {
                          this.handlePersonType(event)
                        }}
                      >
                        {this.state.personTypes.map((personType) => (
                          <MenuItem value={personType.id}>
                            {personType.type}
                          </MenuItem>
                        ))}
                      </Select>
                      {/* <FormHelperText>Obrigatório*</FormHelperText> */}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item container direction="column" spacing={1} xs={4}>
                  <Grid item>
                    <Typography
                      variant="h6"
                      component="h6"
                      style={{ paddingLeft: '30px' }}
                    >
                      Tipo de solicitação
                    </Typography>
                  </Grid>
                  <Grid item>
                    <FormControl
                      variant="outlined"
                      style={{ width: '85%', paddingLeft: '30px' }}
                    >
                      <Select
                        value={this.state.solicitationType}
                        onChange={(event) => {
                          this.handleSolicitationType(event)
                        }}
                      >
                        {this.state.solicitationTypes.map(
                          (solicitationType) => (
                            <MenuItem value={solicitationType.id}>
                              {solicitationType.type}
                            </MenuItem>
                          )
                        )}
                      </Select>
                      {/* <FormHelperText>Obrigatório*</FormHelperText> */}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  spacing={1}
                  xs={4}
                >
                  <Grid item>
                    <Typography
                      variant="h6"
                      component="h6"
                      style={{ paddingLeft: '30px' }}
                    >
                      Empresa
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body1"
                      component="body1"
                      style={{ paddingLeft: '30px' }}
                    >
                      {this.state.companyName}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justify="center"
                style={{ width: '80%', marginTop: '15px' }}
              >
                <Grid item container direction="column" spacing={1} xs={4}>
                  <Grid item>
                    <Typography
                      variant="h6"
                      component="h6"
                      style={{ paddingLeft: '30px' }}
                    >
                      Nome
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      // helperText="Obrigatório*"
                      variant="outlined"
                      style={{ width: '85%', paddingLeft: '30px' }}
                      color={theme.secondary_color}
                      value={this.state.name}
                      onChange={(event) => {
                        this.handleName(event)
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item container direction="column" spacing={1} xs={4}>
                  <Grid item>
                    <Typography
                      variant="h6"
                      component="h6"
                      style={{ paddingLeft: '30px' }}
                    >
                      Sobrenome
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      // helperText="Obrigatório*"
                      variant="outlined"
                      style={{ width: '85%', paddingLeft: '30px' }}
                      color={theme.secondary_color}
                      value={this.state.lastName}
                      onChange={(event) => {
                        this.handleLastName(event)
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item container direction="column" spacing={1} xs={4}>
                  <Grid item>
                    <Typography
                      variant="h6"
                      component="h6"
                      style={{ paddingLeft: '30px' }}
                    >
                      E-mail
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      // helperText="Obrigatório*"
                      variant="outlined"
                      style={{ width: '85%', paddingLeft: '30px' }}
                      color={theme.secondary_color}
                      value={this.state.email}
                      onChange={(event) => {
                        this.handleEmail(event)
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justify="center"
                style={{ width: '80%', marginTop: '15px' }}
              >
                <Grid item container direction="column" spacing={1} xs={4}>
                  <Grid item>
                    <Typography
                      variant="h6"
                      component="h6"
                      style={{ paddingLeft: '30px' }}
                    >
                      CPF
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      variant="outlined"
                      style={{ width: '85%', paddingLeft: '30px' }}
                      color={theme.secondary_color}
                      value={this.state.document}
                      //error={!this.state.validDocument}
                      /*helperText={
                        !this.state.validDocument
                          ? 'Digite um CPF válido'
                          : 'Obrigatório*'
                      }*/
                      onChange={(event) => {
                        this.handleDocument(event)
                      }}
                      InputProps={{
                        inputComponent: TextMaskCustomCPF,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item container direction="column" spacing={1} xs={4}>
                  <Grid item>
                    <Typography
                      variant="h6"
                      component="h6"
                      style={{ paddingLeft: '30px' }}
                    >
                      Data de nascimento
                    </Typography>
                  </Grid>
                  <Grid item>
                    <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="outlined"
                        format="dd/MM/yyyy"
                        margin="normal"
                        style={{ width: '85%', paddingLeft: '30px' }}
                        color={theme.secondary_color}
                        invalidDateMessage="Data em formato inválido"
                        onChange={(value) => {
                          this.handleBirthDate(value)
                        }}
                        value={this.state.birthDate}
                        autoOk
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>

                <Grid item container direction="column" spacing={1} xs={4}>
                  <Grid item>
                    <Typography
                      variant="h6"
                      component="h6"
                      style={{ paddingLeft: '30px' }}
                    >
                      Telefone
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      variant="outlined"
                      style={{ width: '85%', paddingLeft: '30px' }}
                      color={theme.secondary_color}
                      value={this.state.phone}
                      helperText="Insira um telefone"
                      onChange={(event) => {
                        this.handlePhone(event)
                      }}
                      InputProps={{
                        inputComponent: TextMaskCustomPhone,
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid item container direction="column" spacing={1} xs={4}>
                  <Grid item>
                    <Typography
                      variant="h6"
                      component="h6"
                      style={{ paddingLeft: '30px' }}
                    >
                      Informações Complementares
                    </Typography>
                  </Grid>
                  <Grid item>
                  <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    value={this.state.additionalInformation}
                    helperText="Informações Complementares"
                    onChange={(event) => {
                      this.handleAdditionalInformation(event)
                    }}

                  />
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  direction="column"
                  spacing={1}
                  xs={4}
                ></Grid>
              </Grid>
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                justify="center"
                style={{ width: '80%', marginTop: '15px' }}
              >
                <Button
                  variant="contained"
                  style={{
                    // background:
                    //   !this.state.userInformation.permissions.insert ||
                    //   this.state.sendButtonDisabled
                    //     ? theme.opacity
                    //     : theme.primary_color,
                    background:theme.primary_color,
                    color: theme.secondary_color,
                    fontSize: '20px',
                  }}
                  onClick={() => {
                    this.saveSolicitation()
                  }}
                  // disabled={
                  //   !this.state.userInformation.permissions.insert ||
                  //   this.state.sendButtonDisabled
                  // }
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  changePage: (page) => dispatch(changePage(page)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SolicitationFormNew)
