import React, { useEffect } from 'react';
import {
  Paper,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Grid,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogContentText,
  MenuItem,
  ButtonBase,
  Select,
  InputLabel,
  FormControl,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Snackbar
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  DateTimePicker,
  KeyboardDateTimePicker
} from '@material-ui/pickers'
import { Close, QuestionAnswerRounded } from "@material-ui/icons";
import AddIcon from '@material-ui/icons/Add';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import theme from "../../../../core/theme.json";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { changePage } from '../../../presentation/redux/actions';
import { useDispatch, connect } from "react-redux";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

const ViewIncidentsPDF = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(props.item)
  }, []);

  const handleValidData = (time) => {
    if (time == '') {
      return '';
    } else {
      let d = new Date((time.seconds * 1000));
      // let df = format(d, "dd/MM/yyyy");
      let df = format(d, "dd 'de' MMMM 'de' yyyy HH:mm:ss", { locale: ptBR })
      return df;
    }
  }

  const pdfGenerator = () => {
    const styles = StyleSheet.create({
      page: {
        flexDirection: 'column',
      },
      hedDocView: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        backgroundColor: '#5B9BD5',
        marginTop: 10,
        paddingTop: 10,
        paddingBottom: 10
      },
      hedDocText: {
        fontWeight: 'bold',
        fontSize: 18,
        color: '#fff'
      },
      hedDoc: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        paddingRight: 50,
        paddingLeft: 50,
      },
      title: {
        color: '#2e289a',
        fontSize: 16,
        paddingTop: 15,
        fontWeight: 'bold',
      },
      subTitle: {
        color: '#665ef7',
        fontSize: 14,
        fontWeight: 'bold',
        marginTop: 20,
      },
      txt: {
        color: '#000',
        fontSize: 12,
        marginTop: 5,
      },
      cominicacao: {
        flexDirection: 'column',
        paddingRight: 20,
        paddingLeft: 20,
      },
      pulaPagna: {
        marginTop: 90,
      },
    });

    //renderizando pdf
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.hedDoc}>
            <Text style={styles.title} >Formulário de comunicação de incidente de segurança com dados pessoais à Autoridade Nacional de Proteção de Dados (ANPD)</Text>
          </View>
          <View style={styles.hedDocView}>
            <Text style={styles.hedDocText} >Informações sobre o incidente</Text>
          </View>
          <View style={styles.cominicacao} >
            <Text style={styles.subTitle}>Porte da empresa: <Text style={styles.txt}>{props.item.companySize}</Text></Text>
            <Text style={styles.subTitle}>Area: <Text style={styles.txt}>{props.item.FK_AREA}</Text></Text>
            <Text style={styles.subTitle}>Informe o número aproximado de titulares cujos dados são tratados por sua organização: <Text style={styles.txt}>{props.item.qtde_titulares}</Text></Text>
          </View>
          <View style={styles.hedDocView}>
            <Text style={styles.hedDocText} >Comunicação</Text>
          </View>
          <View style={styles.cominicacao} >
            <Text style={styles.subTitle}>Tipo de comunicação: <Text style={styles.txt}>{props.item.FK_TIPO_COMUNICACAO}</Text></Text>
            <Text style={styles.subTitle}>Critério para a comunicação: <Text style={styles.txt}>{props.item.FK_CRITERIO}</Text></Text>
          </View>
          <View style={styles.hedDocView}>
            <Text style={styles.hedDocText} >Agente de Tratamento</Text>
          </View>
          <View style={styles.cominicacao} >
            <Text style={styles.subTitle}>O notificante é: <Text style={styles.txt}>{props.item.FK_TIPO_NOTIFICANTE}</Text></Text>
          </View>
          <View style={styles.hedDocView}>
            <Text style={styles.hedDocText} >Dados do agente de tratamento</Text>
          </View>
          <View style={styles.cominicacao} >
            <Text style={styles.subTitle}>Número de CPF ou CNPJ: <Text style={styles.txt}>{props.item.AGENTE_DOCUMENTO}</Text></Text>
            <Text style={styles.subTitle}>Nome ou razão social: <Text style={styles.txt}>{props.item.AGENTE_NOME}</Text></Text>
            <Text style={styles.subTitle}>Natureza da organização: <Text style={styles.txt}>{props.item.FK_NATUREZA_OPERACAO}</Text></Text>
            <Text style={styles.subTitle}>Telefone: <Text style={styles.txt}>{props.item.AGENTE_TELEFONE}</Text></Text>
            <Text style={styles.subTitle}>E-mail: <Text style={styles.txt}>{props.item.AGENTE_EMAIL}</Text></Text>
            <Text style={styles.subTitle}>CEP: <Text style={styles.txt}>{props.item.AGENTE_CEP}</Text></Text>
            <Text style={styles.subTitle}>Endereço: <Text style={styles.txt}>{props.item.AGENTE_ENDERECO}</Text></Text>
            <Text style={styles.subTitle}>Cidade: <Text style={styles.txt}>{props.item.AGENTE_CIDADE}</Text></Text>
            <Text style={styles.subTitle}>Estado: <Text style={styles.txt}>{props.item.AGENTE_ESTADO}</Text></Text>
            <Text style={styles.subTitle}>Possui um encarregado pela proteção de dados pessoais? <Text style={styles.txt}>{props.item.possui_encarregado === 's' ? 'Sim' : 'Não'}</Text></Text>
          </View>
          <Text style={styles.subTitle}>

          </Text>
          <View style={styles.hedDocView}>
            <Text style={styles.hedDocText} >Dados do notificante</Text>
          </View>
          <View style={styles.cominicacao} >
            <Text style={styles.subTitle}>Nome: <Text style={styles.txt}>{props.item.NOTIFICANTE_NOME}</Text></Text>
            <Text style={styles.subTitle}>E-mail: <Text style={styles.txt}>{props.item.NOTIFICANTE_EMAIL}</Text></Text>
            <Text style={styles.subTitle}>Telefone: <Text style={styles.txt}>{props.item.NOTIFICANTE_TELEFONE}</Text></Text>
            <Text style={styles.subTitle}>O próprio encarregado pela proteção de dados? <Text style={styles.txt}>{props.item.proprio_encarregado_lgpd === 's' ? 'Sim' : 'Não'}</Text></Text>
            <Text style={styles.subTitle}>Outros (especifique): <Text style={styles.txt}>{props.item.obs_notificante}</Text></Text>
            <Text style={styles.subTitle}>Quando comunicou aos titulares: <Text style={styles.txt}>{props.item.quando_comunicou_titu}</Text></Text>
            <Text style={styles.subTitle}>Justifique, se cabível, a não realização da comunicação completa à ANPD e aos titulares de dados afetados no prazo sugerido de 2 (dois) dias úteis após a ciência do incidente:</Text>
            <Text style={styles.txt}>{props.item.jus_comunicacao_anpd}</Text>
            <Text style={styles.subTitle}>Se cabível, informe quando e a quais outras autoridades o incidente foi comunicado: </Text>
            <Text style={styles.txt}>{props.item.jus_quando_e_quais_autoridades}</Text>
          </View>
          <View style={styles.hedDocView}>
            <Text style={styles.hedDocText} >Dados do encarregado</Text>
          </View>
          <View style={styles.cominicacao} >
            <Text style={styles.subTitle}>Nome: <Text style={styles.txt}>{props.item.ENCARREGADO_NOME}</Text></Text>
            <Text style={styles.subTitle}>E-mail: <Text style={styles.txt}>{props.item.ENCARREGADO_EMAIL}</Text></Text>
            <Text style={styles.subTitle}>Telefone: <Text style={styles.txt}>{props.item.ENCARREGADO_TELEFONE}</Text></Text>
          </View>
          <View style={styles.hedDocView}>
            <Text style={styles.hedDocText} >Incidente de segurança</Text>
          </View>
          <View style={styles.cominicacao} >
            <Text style={styles.subTitle}>Descrição do incidente: </Text>
            <Text style={styles.txt}>{props.item.OBS_INCIDENTE_SEGUGRANCA}</Text>
            <Text style={styles.subTitle}>Quando o incidente ocorreu? <Text style={styles.txt}>{handleValidData(props.item.DATA_INCIDENTE)}</Text> </Text>
            <Text style={styles.subTitle}>Quando tomou ciência? <Text style={styles.txt}>{handleValidData(props.item.DATA_CIENCIA_INCIDENTE)}</Text> </Text>
            <Text style={styles.subTitle}>Quando comunicou a ANPD? <Text style={styles.txt}>{handleValidData(props.item.DATA)}</Text> </Text>
            <Text style={styles.subTitle}>Como a organização teve ciência do incidente de segurança? </Text>
            <Text style={styles.txt}>{props.item.OBS_CONHECIMENTO_INCIDENTE}</Text>
            <Text style={styles.subTitle}>

            </Text>
            <Text style={styles.subTitle}>Se a comunicação inicial do incidente não foi comunicada no prazo sugerido de 2 dias úteis após ter tomado ciência do incidiente, justifique os motivos: </Text>
            <Text style={styles.txt}>{props.item.OBS_COMUNICACAO_FORA_PRAZO}</Text>
          </View>
          <View style={styles.hedDocView}>
            <Text style={styles.hedDocText} >Da comunicação do incidente aos titulares dos dados</Text>
          </View>
          <View style={styles.cominicacao} >
            <Text style={styles.subTitle}>
              Os titulares dos dados afetados foram comunicados sobre o incidente?
            </Text>
            {
              props.item.comunicacao_aos_titulares_sim === true ?
                <Text style={styles.txt}>Sim</Text>
                :
                <></>
            }
            {
              props.item.comunicacao_aos_titulares_nao_processo_esta_em_andamento === true ?
                <Text style={styles.txt}>Não, mas o processo de comunicação está em andamento.</Text>
                :
                <></>
            }
            {
              props.item.comunicacao_aos_titulares_nao_risco_ou_dano === true ?
                <Text style={styles.txt}>Não, por não haver risco ou dano relevante a eles.</Text>
                :
                <></>
            }
            {
              props.item.comunicacao_aos_titulares_nao_vez_se_c_preliminar === true ?
                <Text style={styles.txt}>Não, vez que o risco do incidente ainda está sendo apurado. (comunicação preliminar)</Text>
                :
                <></>
            }
          </View>
          <View style={styles.cominicacao} >
            <Text style={styles.subTitle}>
              Natureza dos dados afetados
            </Text>
            {
              props.item.FK_NATUREZA_RACIAL === true ?
                <Text style={styles.txt}>Origem racial ou étnica</Text>
                :
                <></>
            }
            {
              props.item.FK_NATUREZA_RELIGIOSA === true ?
                <Text style={styles.txt}>Convicção Religiosa</Text>
                :
                <></>
            }
            {
              props.item.FK_NATUREZA_POLITICA === true ?
                <Text style={styles.txt}>Opinião política</Text>
                :
                <></>
            }
            {
              props.item.FK_NATUREZA_SINDICATO === true ?
                <Text style={styles.txt}>Filiação a sindicato</Text>
                :
                <></>
            }
            {
              props.item.filiacao === true ?
                <Text style={styles.txt}>Filiação a organização de caráter religioso, filosófico ou político</Text>
                :
                <></>
            }
            {
              props.item.FK_NATUREZA_SAUDE === true ?
                <Text style={styles.txt}>Dado referente a saúde</Text>
                :
                <></>
            }
            {
              props.item.dado_sexual === true ?
                <Text style={styles.txt}>Dado referente a vida sexual</Text>
                :
                <></>
            }
            {
              props.item.FK_NATUREZA_GENETICO === true ?
                <Text style={styles.txt}>Dado genético ou biomético</Text>
                :
                <></>
            }
            {
              props.item.FK_NATUREZA_IDENTIDADE === true ?
                <Text style={styles.txt}>Dado de comprovação de identidade oficial (por exemplo, número RG, CPF, CNH)</Text>
                :
                <></>
            }
            {
              props.item.FK_NATUREZA_FINANCEIRO === true ?
                <Text style={styles.txt}>Dado Financeiro</Text>
                :
                <></>
            }
            {
              props.item.FK_NATUREZA_USUARIO === true ?
                <Text style={styles.txt}>Nomes de usuário ou senhas de sistemas de informação</Text>
                :
                <></>
            }
            {
              props.item.FK_NATUREZA_GEO === true ?
                <Text style={styles.txt}>Dados de geolocalização</Text>
                :
                <></>
            }
            <Text style={styles.subTitle}>Outros: </Text>
            <Text style={styles.txt}>{props.item.OBS_NATUREZA}</Text>
            <Text style={styles.subTitle}>Quantos titulares foram comunicados individualmente sobre o incidente? </Text>
            <Text style={styles.txt}>{props.item.QTD_TITULARES_AFETADOS}</Text>
            <Text style={styles.subTitle}>Justifique, se cabível, o que motivou a não realização da comunicação individual aos titulares:</Text>
            <Text style={styles.txt}>{props.item.jus_nao_comunicacao_aos_titulares}</Text>
            <Text style={styles.subTitle}>Que medidas foram adotadas para corrigir as causas do incidente?</Text>
            <Text style={styles.txt}>{props.item.que_medidas_foram_adotadas}</Text>
          </View>
          <View style={styles.cominicacao} >
            <Text style={styles.subTitle}>
              De que forma o incidente afetou os dados pessoais:
            </Text>
            {
              props.item.confidencialidade === true ?
                <Text style={styles.txt}>Confidencialidade</Text>
                :
                <></>
            }
            {
              props.item.integridade === true ?
                <Text style={styles.txt}>Integridade</Text>
                :
                <></>
            }
            {
              props.item.disponibilidade === true ?
                <Text style={styles.txt}>Disponibilidade</Text>
                :
                <></>
            }
          </View>
          <View style={styles.cominicacao} >
            <Text style={styles.subTitle}>
              Qual a categoria dos titulares afetados?
            </Text>
            {
              props.item.FK_TITULAR_FUNCIONARIO === true ?
                <Text style={styles.txt}>Funcionários</Text>
                :
                <></>
            }
            {
              props.item.FK_TITULAR_PRESTADOR === true ?
                <Text style={styles.txt}>Prestadores de serviço</Text>
                :
                <></>
            }
            {
              props.item.FK_TITULAR_CLIENTE === true ?
                <Text style={styles.txt}>Clientes</Text>
                :
                <></>
            }
            {
              props.item.FK_TITULAR_CONSUMIDOR === true ?
                <Text style={styles.txt}>Consumidores</Text>
                :
                <></>
            }
            {
              props.item.FK_TITULAR_USUARIO === true ?
                <Text style={styles.txt}>Usuários</Text>
                :
                <></>
            }
            {
              props.item.FK_TITULAR_PACIENTE === true ?
                <Text style={styles.txt}>Pacientes de serviço de saúde</Text>
                :
                <></>
            }
            {
              props.item.FK_TITULAR_CRIANCA === true ?
                <Text style={styles.txt}>Crianças ou adolescentes</Text>
                :
                <></>
            }
            <Text style={styles.subTitle}>Outros: </Text>
            <Text style={styles.txt}>{props.item.outra_categoria}</Text>
          </View>
          <View style={styles.hedDocView}>
            <Text style={styles.hedDocText} >Medidas de segurança utilizadas para proteção de dados</Text>
          </View>
          <View style={styles.cominicacao} >
            <Text style={styles.subTitle}>Quais medidas de segurança, técnicas e administrativas, foram tomadas para prevenir a ocorrência do incidente de segurança?</Text>
            <Text style={styles.txt}>{props.item.OBS_MEDIDAS}</Text>
            <Text style={styles.subTitle}>Quais medidas de segurança, técnicas e administrativas, foram tomadas após a ciência do incidente de segurança?</Text>
            <Text style={styles.txt}>{props.item.OBS_MEDIDAS_CIENCIA}</Text>
            <Text style={styles.subTitle}>Quais medidas de segurança, técnicas e administrativas, foram ou serão adotadas para reverter ou mitigar os efeitos do prejuízo do incidente de segurança aos titulares dos dados?</Text>
            <Text style={styles.txt}>{props.item.OBS_MEDIDAS_ADOTADAS}</Text>
            <Text style={styles.subTitle}>O agente de tratamento realizou relatório de impacto à proteção de dados pessoais?</Text>
            <Text style={styles.txt}>{props.item.OBS_AGENTE__RELATORIO_IMPACTO}</Text>
          </View>
          <View style={styles.hedDocView}>
            <Text style={styles.hedDocText} >Riscos relacionados ao incidente de segurança</Text>
          </View>
          <View style={styles.cominicacao} >
            <Text style={styles.subTitle}>Quais as prováveis consequências do incidente de segurança para os titulares afetados?</Text>
            <Text style={styles.txt}>{props.item.OBS_CONSEQUENCIA_TITULAR}</Text>
            <Text style={styles.subTitle}>Considerando os titulares afetados, na sua avaliação, o incidente pode trazer consequências transfronteiriças?</Text>
            <Text style={styles.txt}>{props.item.OBS_TRANSFRONTEIRA}</Text>
          </View>
          <View style={styles.hedDocView}>
            <Text style={styles.hedDocText} >Comunicação aos titulares de dados</Text>
          </View>
          <View style={styles.cominicacao} >
            <Text style={styles.subTitle}>Os titulares foram comunicados sobre o incidente de segurança com dados pessoais?</Text>
            <Text style={styles.txt}>{props.item.FK_TIPO_COMUNICACAO_TITULAR}</Text>
            <Text style={styles.subTitle}>Forneça detalhes</Text>
            <Text style={styles.txt}>{props.item.FK_TIPO_COMUNICACAO_TITULAR}</Text>
            <Text style={styles.subTitle}>Caso os titulares afetados não tenham sido informados, quais são os motivos que justificam a não comunicação ou o seu retardo?</Text>
            <Text style={styles.txt}>{props.item.OBS_TITULAR_NAO_INFORMADO}</Text>
          </View>
          <View style={styles.hedDocView}>
            <Text style={styles.hedDocText} >Descrição do Incidente</Text>
          </View>
          <View style={styles.cominicacao} >
            <Text style={styles.subTitle}>Qual o tipo de incidente?</Text>
            {
              props.item.squestro_dados === true ?
                <Text style={styles.txt}>Sequestro de dados (ransomware) sem transferência de informações.</Text>
                :
                <></>
            }
            {
              props.item.exploracao_vunerabolidade === true ?
                <Text style={styles.txt}>Exploração de vulnerabilidade em sistemas de informação.</Text>
                :
                <></>
            }
            {
              props.item.roubo_engenharia_social === true ?
                <Text style={styles.txt}>Roubo de credenciais / Engenharia social.</Text>
                :
                <></>
            }
            {
              props.item.publi_n_internacional === true ?
                <Text style={styles.txt}>Publicação não intencional de dados pessoais.</Text>
                :
                <></>
            }
            {
              props.item.envio_dados_incorretos === true ?
                <Text style={styles.txt}>Envio de dados a destinatário incorreto.</Text>
                :
                <></>
            }
            {
              props.item.neg_ddos === true ?
                <Text style={styles.txt}>Negação de serviço (DoS).</Text>
                :
                <></>
            }
            {
              props.item.perda_roube_docs === true ?
                <Text style={styles.txt}>Perda/roubo de documentos ou dispositivos eletrônicos.</Text>
                :
                <></>
            }
            {
              props.item.falha_eqp === true ?
                <Text style={styles.txt}>Falha em equipamento (hardware).</Text>
                :
                <></>
            }
            {
              props.item.outro_tipo_inc_ciber === true ?
                <Text style={styles.txt}>Outro tipo de incidente cibernético. (especifique abaixo)</Text>
                :
                <></>
            }
            {
              props.item.seq_dados === true ?
                <Text style={styles.txt}>Sequestro de dados (ransomware) com transferência e/ou publicação de informações.</Text>
                :
                <></>
            }
            {
              props.item.virus_pc === true ?
                <Text style={styles.txt}>Vírus de computador / malware.</Text>
                :
                <></>
            }
            {
              props.item.violacao_bruteForce === true ?
                <Text style={styles.txt}>Violação de credencial por força bruta.</Text>
                :
                <></>
            }
            {
              props.item.divulga_inde_pess === true ?
                <Text style={styles.txt}>Divulgação indevida de dados pessoais.</Text>
                :
                <></>
            }
            {
              props.item.aces_indev_sis === true ?
                <Text style={styles.txt}>Acesso não autorizado a sistemas de informação.</Text>
                :
                <></>
            }
            {
              props.item.alt_exc_dados === true ?
                <Text style={styles.txt}>Alteração/exclusão não autorizada de dados.</Text>
                :
                <></>
            }
            {
              props.item.des_inc_doc_ele === true ?
                <Text style={styles.txt}>Descarte incorreto de documentos ou dispositivos eletrônicos.</Text>
                :
                <></>
            }
            {
              props.item.falha_sis_inf === true ?
                <Text style={styles.txt}>Falha em sistema de informação (software).</Text>
                :
                <></>
            }
            {
              props.item.falha_inc_cib === true ?
                <Text style={styles.txt}>Outro tipo de incidente não cibernético. (especifique abaixo)</Text>
                :
                <></>
            }
            <Text style={styles.subTitle}>Descreva, resumidamente, como ocorreu o incidente:</Text>
            <Text style={styles.txt}>{props.item.des_como_ocorreu}</Text>
            <Text style={styles.subTitle}>Explique, resumidamente, por que o incidente ocorreu:</Text>
            <Text style={styles.txt}>{props.item.exp_porque_ocorreu}</Text>
            <Text style={styles.subTitle}>Qual o número total de titulares cujos dados são tratados nas atividades afetadas pelo incidente?</Text>
            <Text style={styles.txt}>{props.item.qtde_total_titulares_afetados}</Text>
          </View>
          <View style={styles.hedDocView}>
            <Text style={styles.hedDocText} >Qual a quantidade aproximada de titulares afetados pelo incidente?</Text>
          </View>
          <View style={styles.cominicacao} >
            <Text style={styles.subTitle}>Total de titulares afetados:</Text>
            <Text style={styles.txt}>{props.item.total_titulares_afetados_2}</Text>
            <Text style={styles.subTitle}>Crianças e/ou adolescentes:</Text>
            <Text style={styles.txt}>{props.item.criancas_e_adolecentes}</Text>
            <Text style={styles.subTitle}>Outros titulares vulneráveis:</Text>
            <Text style={styles.txt}>{props.item.outros_titu_vul}</Text>
            <Text style={styles.subTitle}>Se aplicável, descreva as categorias de titulares vulneráveis afetados:</Text>
            <Text style={styles.txt}>{props.item.des_cat_de_titu_vul_afetados}</Text>
          </View>
          <View style={styles.cominicacao} >
            <Text style={styles.subTitle}>Quais a categorias de titulares foram afetadas pelo incidente?</Text>
            {
              props.item.ckb_funcionarios === true ?
                <Text style={styles.txt}>Funcionários</Text>
                :
                <></>
            }
            {
              props.item.ckb_clientes_cidadaos === true ?
                <Text style={styles.txt}>Clientes / cidadãos.</Text>
                :
                <></>
            }
            {
              props.item.ckb_paciente_de_servico_saude === true ?
                <Text style={styles.txt}>Pacientes de serviço de saúde.</Text>
                :
                <></>
            }
            {
              props.item.ckb_prestadores_servico === true ?
                <Text style={styles.txt}>Prestadores de serviços.</Text>
                :
                <></>
            }
            {
              props.item.ckb_usuarios === true ?
                <Text style={styles.txt}>Usuários.</Text>
                :
                <></>
            }
            {
              props.item.ckb_ainda_n_identificados === true ?
                <Text style={styles.txt}>Ainda não identificadas.</Text>
                :
                <></>
            }
            {
              props.item.ckb_estudantes_alunos === true ?
                <Text style={styles.txt}>Estudantes / alunos.</Text>
                :
                <></>
            }
            {
              props.item.ckb_inscritos_filiados === true ?
                <Text style={styles.txt}>Inscritos / filiados.</Text>
                :
                <></>
            }
            {
              props.item.ckb_outros === true ?
                <Text style={styles.txt}>Outros.</Text>
                :
                <></>
            }
            <Text style={styles.subTitle}>Informe o quantitativo de titulares afetados, por categoria:</Text>
            <Text style={styles.txt}>{props.item.informe_quantitativo_titu_afetados}</Text>

            <Text style={styles.subTitle}>Quais as prováveis consequências do incidente para os titulares?</Text>
            {
              props.item.ckb_dados_morais === true ?
                <Text style={styles.txt}>Danos morais.</Text>
                :
                <></>
            }
            {
              props.item.ckb_discriminacao_social === true ?
                <Text style={styles.txt}>Discriminação social.</Text>
                :
                <></>
            }
            {
              props.item.ckb_eng_social_fraude === true ?
                <Text style={styles.txt}>Engenharia social / fraudes.</Text>
                :
                <></>
            }
            {
              props.item.ckb_rest_direitos === true ?
                <Text style={styles.txt}>Restrições de direitos.</Text>
                :
                <></>
            }
            {
              props.item.ckb_danos_materiais === true ?
                <Text style={styles.txt}>Danos materiais.</Text>
                :
                <></>
            }
            {
              props.item.ckb_danos_reputacionais === true ?
                <Text style={styles.txt}>Danos reputacionais.</Text>
                :
                <></>
            }
            {
              props.item.ckb_limitacao_de_acesso_servico === true ?
                <Text style={styles.txt}>Limitação de acesso a um serviço.</Text>
                :
                <></>
            }
            {
              props.item.ckb_perda_de_acesso_a_dados === true ?
                <Text style={styles.txt}>Perda de acesso a dados pessoais.</Text>
                :
                <></>
            }
            {
              props.item.ckb_violacao_int_fisica === true ?
                <Text style={styles.txt}>Violação à integridade física</Text>
                :
                <></>
            }
            {
              props.item.ckb_roubo_identidade === true ?
                <Text style={styles.txt}>Roubo de identidade.</Text>
                :
                <></>
            }
            {
              props.item.ckb_exposicao_dados_prof_legal === true ?
                <Text style={styles.txt}>Exposição de dados protegidos por sigilo profissional/legal.</Text>
                :
                <></>
            }
            {
              props.item.cbk_outro_dois === true ?
                <Text style={styles.txt}>Outros (especifique abaixo).</Text>
                :
                <></>
            }
            <Text style={styles.subTitle}>Se cabível, descreva as prováveis consequências do incidente para cada grupo de titulares:</Text>
            <Text style={styles.txt}>{props.item.descreva_prov_conse_inc}</Text>

            <Text style={styles.subTitle}>Qual o provável impacto do incidente sobre os titulares?</Text>
            {
              props.item.ckb_podem_sofrer_danos === true ?
                <Text style={styles.txt}>Podem não sofrer danos, sofrer danos negligenciáveis ou superáveis sem dificuldade.</Text>
                :
                <></>
            }
            {
              props.item.ckb_podem_sofrer_danos_supraveis === true ?
                <Text style={styles.txt}>Podem sofrer danos, superáveis com certa dificuldade.</Text>
                :
                <></>
            }
            {
              props.item.ckb_podem_sofrer_danos_supraveis_com_dificuldade === true ?
                <Text style={styles.txt}>Podem sofrer danos importantes, superáveis com muita dificuldade.</Text>
                :
                <></>
            }
            {
              props.item.cbk_podem_sofrer_lesao === true ?
                <Text style={styles.txt}>Podem sofrer lesão ou ofensa a direitos ou interesses difusos, coletivos ou individuais, que, dadas as circunstâncias, ocasionam ou tem potencial para ocasionar dano significativo ou irreversível.</Text>
                :
                <></>
            }

            <Text style={styles.subTitle}>Se cabível, descreva as medidas de segurança adicionais adotadas após o incidente:</Text>
            <Text style={styles.txt}>{props.item.secabivel_descreva_medidas}</Text>

            <Text style={styles.subTitle}>As atividades de tratamento de dados afetadas estão submetidas a regulações de segurança setoriais?</Text>
            {
              props.item.ckb_sim === true ?
                <Text style={styles.txt}>Sim</Text>
                :
                <></>
            }
            {
              props.item.ckb_nao === true ?
                <Text style={styles.txt}>Não</Text>
                :
                <></>
            }
            <Text style={styles.subTitle}>Se cabível, indique as regulamentações setoriais de segurança aplicáveis às atividades de tratamento de dados afetadas pelo incidente:</Text>
            <Text style={styles.txt}>{props.item.se_cabivel_indique}</Text>

          </View>

        </Page>
      </Document>
    );
  }

  return (
    <Grid container>
      <Grid container style={{ backgroundColor: '#fff', padding: '10px', marginBottom: '10px', borderRadius: '5px' }} >
        <Grid item>
          <Button
            variant="contained"
            style={{ background: 'none', marginRight: '50px' }}
            onClick={() => dispatch(changePage(20))}
          >
            <ArrowBackIcon />
          </Button>
        </Grid>
        <Grid item>
          <Typography variant="h4" component="h4" style={{ fontWeight: "500" }}>
            Download relatório de incidente
          </Typography>
        </Grid>
      </Grid>
      {
        <PDFViewer width="100%" height="800px" >
          {
            pdfGenerator()
          }
        </PDFViewer>
      }
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    item: state.incidentesReducer.obj,
  };
}

export default connect(mapStateToProps, { changePage })(ViewIncidentsPDF);