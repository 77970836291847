import React, { useEffect, useMemo } from 'react'
import { Typography, FormHelperText } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import AttachmentIcon from "@material-ui/icons/Attachment";
import ErrorIcon from "@material-ui/icons/Error";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function validator(file) {
  if (file.name.length > 200) {
    return {
      code: "name-too-large",
      message: `O nome do arquivo é que ${200} caracteres`,
    };
  }

  if (file.size > 5000000) {
    return {
      code: "name-too-large",
      message: `O arquivo excede o tamanho máximo de 2 MB`,
    };
  }

  return null;
}

function fileSizeWithSufix(bytes) {
  if (bytes >= 1048576) {
    return (bytes / 1048576).toFixed(5) + " MB";
  } else if (bytes >= 1024) {
    return (bytes / 1024).toFixed(5) + " KB";
  } else if (bytes > 1) {
    return bytes + " bytes";
  } else if (bytes === 1) {
    return "1 byte";
  }
  return "0 bytes";
}

const InputFile = ({
  maxFiles = 1,
  accept = "image/jpeg, image/png, application/pdf, .mp3 ,video/mp4,video/x-m4v,video/* ,.doc, .docx,", 
  onAcceptFiles,
  denunce
}) => {
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept,
    maxFiles,
    validator,
  });

  useEffect(() => {
    if (acceptedFiles.length && typeof onAcceptFiles === "function") {
      onAcceptFiles(acceptedFiles);
    }
  }, [acceptedFiles])

  const files = acceptedFiles.map((file) => (
    <li key={file.path} style={{ listStyle: "none" }}>
      <span
        style={{ listStyle: "none", display: "flex", alignItems: "center" }}
      >
        <AttachmentIcon style={{ paddingRight: "4px" }} /> {file.path} -{" "}
        {fileSizeWithSufix(file.size)}
      </span>
    </li>
  ));

  const rejectedFiles = fileRejections.map(({ file, errors }) => (
    <li key={file.path} style={{ listStyle: "none" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <AttachmentIcon style={{ paddingRight: "4px" }} /> {file.path} -{" "}
        {fileSizeWithSufix(file.size)}
      </div>
      <ul>
        {errors.map((e) => (
          <li
            key={e.code}
            style={{
              listStyle: "none",
            }}
          >
            <span
              style={{ display: "flex", alignItems: "center", color: "red" }}
            >
              <ErrorIcon style={{ paddingRight: "4px" }} />
              {e.message}
            </span>
          </li>
        ))}
      </ul>
    </li>
  ));

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <section className="container" style={{ padding: "10px", width: "100%" }}>
      <div {...getRootProps({ className: "dropzone", style })}>
        <input {...getInputProps()} />
        <p>
          Arraste e solte o arquivo aqui, ou clique para escolher o arquivo.
        </p>
        <em>
          ({maxFiles} arquivo{maxFiles > 1 ? "s" : ""} é o número máximo que
          você pode soltar aqui)
        </em>
      </div>
   { denunce ?  <FormHelperText>
        Arquivos com até 2 MB nos seguintes formatos: PNG, JPEG , PDF DOC,VÍDEO OU AÚDIO.
      </FormHelperText> :  <FormHelperText>
        Arquivos com até 2 MB nos seguintes formatos: PNG, JPEG ou PDF.
      </FormHelperText>}
      {(files && files.length) || (rejectedFiles && rejectedFiles.length) ? (
        <aside style={{ paddingTop: "4px" }}>
          {files && files.length ? (
            <>
              <Typography variant="subtitle2" component="span">
                Arquivo anexado
              </Typography>
              <ul>{files}</ul>
            </>
          ) : null}

          {rejectedFiles && rejectedFiles.length ? (
            <>
              <Typography variant="subtitle2" component="span">
                Arquivo rejeitados
              </Typography>
              <ul>{rejectedFiles}</ul>
            </>
          ) : null}
        </aside>
      ) : null}
    </section>
  );
};

export default InputFile;
