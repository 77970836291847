import React, { Component } from 'react';
import myApp from '../../../../core/infrastructure/firebaseConfig';
import "firebase/compat/firestore";

import { Grid, Typography, Dialog, DialogContent, Button, TextField, IconButton } from '@material-ui/core';

import { Close } from '@material-ui/icons';

import { connect } from 'react-redux';

import { changePage } from '../../../presentation/redux/actions';

class EditQuiz extends Component {
  constructor(props) {
    super(props);

    this.db = myApp.firestore();

    this.state = {
      dialog: false,
      userInformation: JSON.parse(sessionStorage.getItem('@blue-legal/userInformation')),
      name: "",
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dialog !== this.props.dialog) {
      this.handleDialog();
    }
  }

  componentDidMount() {
    this.setState({
      name: this.props.quizName
    })
  }

  handleDialog() {
    this.setState({
      dialog: !this.state.dialog,
    })
  }

  handleName(event) {
    this.setState({
      name: event.target.value
    })
  }

  async editQuiz() {
    this.props.editQuizName(this.state.name);

    this.handleDialog();
  }

  async deleteQuiz() {
    this.props.deleteQuiz();

    this.props.changePage(3);
    this.handleDialog();
  }

  render() {
    return (
      <Dialog
        open={this.state.dialog}
        fullWidth={true}
        maxWidth={"md"}
        onClose={() => { this.handleDialog() }}
      >
        <DialogContent>
          <Grid
            container
            direction="column"
            justify="center"
          >
            <Grid item container direction="row">
              <Grid item style={{ width: "100%" }} xs={10}>
                <Typography variant="h5" component="h5" >
                  Editar Questionário
                </Typography>
              </Grid>
              <Grid item container xs={2} justify="flex-end">
                <IconButton onClick={() => this.handleDialog()}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
            <Grid style={{ height: "24px" }} />
            <Grid item>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={4}
              >
                <Grid item style={{ width: "100%" }} xs={12}>
                  <TextField
                    inputProps={{
                      maxLength: 50,
                    }}
                    label="Nome do Questionário"
                    variant="outlined"
                    style={{ width: "100%" }}
                    disabled={!this.state.userInformation.permissions.update}
                    color={"#203673"}
                    value={this.state.name}
                    onChange={(event) => { this.handleName(event) }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid style={{ height: "24px" }} />
            <Grid item>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={4}
              >
                <Grid item style={{ width: "100%" }} xs={2}>
                  <Button
                    variant="contained"
                    style={{ background: "red", color: "white", width: "100%", height: "56px" }}
                    onClick={() => { this.deleteQuiz() }}
                    disabled={!this.state.userInformation.permissions.delete}
                  >
                    Remover
                  </Button>
                </Grid>
                <Grid item style={{ flexGrow: 1 }} />
                <Grid item style={{ width: "100%" }} xs={2}>
                  <Button
                    variant="contained"
                    style={{ background: this.state.name.length === 0 ? "#687394" : "#193993", color: "white", width: "100%", height: "56px" }}
                    onClick={() => { this.editQuiz() }}
                    disabled={this.state.name.length === 0 || !this.state.userInformation.permissions.update}
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid style={{ height: "16px" }} />
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, { changePage })(EditQuiz);