import React, { useState, useEffect } from 'react';

//imports mui
import {
  Grid,
  InputLabel,
  InputAdornment,
  FormControl,
  OutlinedInput,
  IconButton,
  Button,
} from '@material-ui/core';

import AccountCircle from '@material-ui/icons/AccountCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

// //import redux
import { useDispatch, connect } from 'react-redux'
import { setLogged, setToken, setUserId } from '../redux/newEtapasEPMReducer';

const Login = () => {

  const dispatch = useDispatch();
  
  const [logo, setLogo] = useState('https://beelegal.com.br/wp-content/uploads/2021/03/logo-beelegal-header-verde-retina.png');
  const [passwordVisibilit, setPasswordVisibilit] = useState(false);
  const [userCredentials, setUserCredentials] = useState({
    login: '',
    password: '',
  });

  const handleSetCredentials = (credential) => (event) => {
    setUserCredentials({
      ...userCredentials,
      [credential]: event.target.value
    })
  }

  useEffect(() => {
    // handleLogin();
  }, []);

  const handleLogin = async () => {
    try {
      let sigIn = await fetch('https://apps.beelegal.com.br/epm_rayel/Security/login', {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: userCredentials.login,
          password: userCredentials.password,
        }),
      });

      let resSigIn = await sigIn.json();

      if (resSigIn.error) {
        alert('Usuario ou senha invalidos');
      } else {
        // console.log('Token', resSigIn.data.auth_ticket)
        console.log(resSigIn)

        dispatch(setToken(resSigIn.data.auth_ticket));
        dispatch(setUserId(resSigIn.data.user_id));
        dispatch(setLogged(true));
      }

    } catch (errorSigin) {
      console.log('errorSigin', errorSigin)
    }
  }

  return (
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
    >
      <Grid
        item
        container
        direction='column'
        style={{
          width: '350px',
          height: '350px',
          backgroundColor: '#eee'
        }}
      >
        <Grid
          item
          container
          justifyContent='center'
          alignItems='center'
          style={{
            backgroundColor: '#202124',
            padding: 20
          }}
        >
          <img src={logo} style={{ width: '70%' }} />
        </Grid>
        <Grid
          item
          container
          justifyContent='center'
          alignItems='center'
        >
          <FormControl style={{ marginTop: 20 }} variant="outlined">
            <InputLabel htmlFor="login">Login</InputLabel>
            <OutlinedInput
              id="login"
              // type={values.showPassword ? 'text' : 'password'}
              value={userCredentials.login}
              onChange={handleSetCredentials('login')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    // onClick={handleClickShowPassword}
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {/* {values.showPassword ? <Visibility /> : <VisibilityOff />} */}
                    <AccountCircle />
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          </FormControl>
          <FormControl style={{ marginTop: 20 }} variant="outlined">
            <InputLabel htmlFor="password">Senha</InputLabel>
            <OutlinedInput
              id="password"
              type={passwordVisibilit ? 'text' : 'password'}
              value={userCredentials.password}
              onChange={handleSetCredentials('password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setPasswordVisibilit(!passwordVisibilit)}
                    onMouseDown={() => setPasswordVisibilit(!passwordVisibilit)}
                    edge="end"
                  >
                    {passwordVisibilit ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          </FormControl>
        </Grid>
        <Grid
          container
          item
          justifyContent='center'
          alignItems='center'
          style={{ marginTop: 20, width: '100%' }}
        >
          <Button
            onClick={handleLogin}
            style={{
              width: '80%',
              backgroundColor: '#38de07'
            }}
            variant="contained"
            color="primary"
          >
            Login
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Login;