import React, { Component } from "react";
import { connect } from "react-redux";
import { setOpenQuiz, setQuiz, setLoadingQuiz } from "../redux/actions";
import { changePage } from "../../../presentation/redux/actions";
import { getQuiz } from "../functions/getQuiz";
import {
  Grid,
  Typography,
  IconButton,
  ButtonBase,
  Paper,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Button
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { Close, QuestionAnswerRounded } from "@material-ui/icons";
import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";

import theme from "../../../../core/theme.json";

// const typesQuiz = [
//   { id: 1, value: "Armazenamento" },
//   { id: 2, value: "Coleta" },
//   { id: 3, value: "Compartilhamento" },
//   { id: 4, value: "Dados Gerais" },
//   { id: 5, value: "Eliminação" },
//   { id: 6, value: "Tratamento" }
// ];

class QuizDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openDialog: false,
      openDialogNewQuiz: true,
      nameQuiz: "",
      // typeQuiz: "",
      quizes: [],
      userInformation: JSON.parse(sessionStorage.getItem("@blue-legal/userInformation")),
      addQuizButtonDisabled: true
    };
  }

  async componentDidMount() {
    await this.getQuizInDatabase();
    this.props.setLoadingQuiz(false);
  }

  async getQuizInDatabase() {
    const quizes = await getQuiz();
    this.setState({
      quizes
    });
  }

  async openSelectedQuiz(quiz) {
    this.props.setLoadingQuiz(true);
    this.props.setQuiz(quiz);
    this.props.setOpenQuiz(true);
    this.props.changePage(4);
  }

  async handleAddQuiz() {
    const { nameQuiz/*, typeQuiz*/ } = this.state;

    this.handleClose();
    this.props.setLoadingQuiz(true);

    const newFlowPoint = {
      cardProps: {
        title: nameQuiz,
        // content: typeQuiz,
        typeCard: "primary",
        disableAddButton: false
      },
      pos: { x: 150, y: 50 },
      outputs: {}
    }

    let points = {};
    points["0"] = newFlowPoint;

    const database = myApp.firestore();

    let responseDoc = await database.collection("quiz").add({
      name: nameQuiz,
      // type: typeQuiz,
      points,
      active: true,
      fk_company: localStorage.getItem("currentCompany")
    });

    await database.collection("quiz").doc(responseDoc.id).update({
      id: responseDoc.id
    });

    this.props.setQuiz({
      id: responseDoc.id,
      name: nameQuiz,
      // type: typeQuiz,
      points,
      active: true,
      fk_company: localStorage.getItem("currentCompany")
    });

    this.props.changePage(4);
  }

  handleOpen() {
    this.setState({
      openDialog: true
    });
  }

  handleClose() {
    this.setState({
      openDialog: false,
      nameQuiz: "",
      // typeQuiz: "",
      addQuizButtonDisabled: true
    });
  }

  handleNameQuiz(event) {
    this.setState({
      nameQuiz: event.target.value,
    });

    if (event.target.value.trim() !== ""/* && this.state.typeQuiz !== ""*/) {
      this.setState({
        addQuizButtonDisabled: false
      });
    } else {
      this.setState({
        addQuizButtonDisabled: true
      });
    }
  }

  // handleTypeQuiz(event) {
  //   this.setState({
  //     typeQuiz: event.target.value
  //   });

  //   if (event.target.value.trim() !== "" && this.state.nameQuiz !== "") {
  //     this.setState({
  //       addQuizButtonDisabled: false
  //     });
  //   } else {
  //     this.setState({
  //       addQuizButtonDisabled: true
  //     });
  //   }
  // }

  render() {
    const { loadingQuiz } = this.props;
    const { quizes } = this.state;

    return (
      <div>
        {loadingQuiz ?
          <Grid container direction="column" alignItems="center" justify="center" style={{ height: "85vh" }}>
            <Grid item>
              <CircularProgress size={60} color="#193993" />
            </Grid>
          </Grid>
          :
          <>
            <Grid container spacing={4}>
              {quizes.map((quiz) => (
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Grid container direction="row" spacing={4} style={{ height: "320px" }}>
                    <Grid item style={{ width: "100%", height: "100%", }}>
                      <ButtonBase
                        disabled={quiz.name.toLowerCase() === 'questionário padrão' ? true : false}
                        onClick={() => { this.openSelectedQuiz(quiz) }}
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "8px",
                        }}
                      >
                        <Paper
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "8px",
                            backgroundColor: quiz.name.toLowerCase() === 'questionário padrão' ? '#ccc' : ''
                          }}
                        >
                          <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            style={{ width: "100%", height: "100%" }}
                          >
                            <Grid item container justify="center" alignItems="center" style={{ width: "100%", height: "50%" }}>
                              <Grid item>
                                <QuestionAnswerRounded style={{ fontSize: 76, color: theme.tertiary_color }} />
                              </Grid>
                            </Grid>
                            <Grid item container justify="center" style={{ width: "100%", height: "50%" }}>
                              <Grid
                                item
                                container
                                justify="center"
                                style={{ width: "100%", height: "90%", overflowY: "hidden", textOverflow: "ellipsis", margin: "0 10px 0 10px" }}
                              >
                                <Typography
                                  variant="h6"
                                  component="h6"
                                  style={{
                                    overflowWrap: "anywhere",
                                    color: theme.tertiary_color,
                                    fontSize: theme.font_button
                                  }}
                                >
                                  {quiz.name}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      </ButtonBase>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Grid
                  container
                  direction="row"
                  spacing={4}
                  style={{ height: "320px" }}
                >
                  {/*
                  this.state.userInformation.permissions.insert ?
                    <Grid item style={{ width: "100%", height: "100%" }}>
                      <ButtonBase
                        onClick={() => { this.handleOpen() }}
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "8px"
                        }}
                      >
                        <Paper style={{ width: "100%", height: "100%", borderRadius: "8px" }}>
                          <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            style={{
                              height: "100%",
                            }}
                          >
                            <Grid
                              item
                              container
                              justify="center"
                              alignItems="center"
                              style={{ width: "100%", height: "50%" }}
                            >
                              <Grid item>
                                <AddIcon style={{ fontSize: 76, color: theme.tertiary_color }} />
                              </Grid>
                            </Grid>
                            <Grid item container justify="center" style={{ width: "100%", height: "50%" }}>
                              <Grid
                                item
                                container
                                justify="center"
                                style={{
                                  width: "100%",
                                  height: "80%",
                                  overflowY: "hidden",
                                  textOverflow: "ellipsis",
                                  margin: "0 10px 0 10px",
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  component="h6"
                                  style={{
                                    fontSize: theme.font_button,
                                    color: theme.tertiary_color,
                                    overflowWrap: "anywhere"
                                  }}
                                >
                                  Adicionar novo Questionário
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      </ButtonBase>
                    </Grid>
                    :
                    <></>
                  */}
                  <Grid item style={{ width: "100%", height: "100%" }}>
                    <ButtonBase
                      onClick={() => { this.props.changePage(38) }}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "8px"
                      }}
                    >
                      <Paper style={{ width: "100%", height: "100%", borderRadius: "8px" }}>
                        <Grid
                          container
                          direction="column"
                          justify="center"
                          alignItems="center"
                          style={{
                            height: "100%",
                          }}
                        >
                          <Grid
                            item
                            container
                            justify="center"
                            alignItems="center"
                            style={{ width: "100%", height: "50%" }}
                          >
                            <Grid item>
                              <ArrowRightAltIcon style={{ fontSize: 76, color: theme.tertiary_color }} />
                            </Grid>
                          </Grid>
                          <Grid item container justify="center" style={{ width: "100%", height: "50%" }}>
                            <Grid
                              item
                              container
                              justify="center"
                              style={{
                                width: "100%",
                                height: "80%",
                                overflowY: "hidden",
                                textOverflow: "ellipsis",
                                margin: "0 10px 0 10px",
                              }}
                            >
                              <Typography
                                variant="h6"
                                component="h6"
                                style={{
                                  fontSize: theme.font_button,
                                  color: theme.tertiary_color,
                                  overflowWrap: "anywhere"
                                }}
                              >
                                Ir para o questionário 2.0
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    </ButtonBase>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Dialog
              open={this.state.openDialog}
              maxWidth="sm"
              fullWidth
              onClose={() => this.handleClose()}
            >
              <DialogTitle id="alert-dialog-slide-title">
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                >
                  <Grid item>
                    <Typography>
                      Adicionar questionário
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={() => this.handleClose()}>
                      <Close />
                    </IconButton>
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                    spacing={2}
                  >
                    <Grid item xs={12}>
                      <TextField
                        label="Nome do questionário"
                        placeholder="Digite o nome do questionário"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        onChange={(event) => this.handleNameQuiz(event)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel>Tipo de questionário</InputLabel>
                        <Select
                          value={this.state.typeQuiz}
                          onChange={(event) => this.handleTypeQuiz(event)}
                          label="Tipo de questionário"
                        >
                          <MenuItem value=""></MenuItem>
                          {typesQuiz.map(typeQuiz => (
                            <MenuItem key={typeQuiz.id} value={typeQuiz.value}>
                              {typeQuiz.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid> */}
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        onClick={() => this.handleAddQuiz()}
                        style={{
                          background: this.state.addQuizButtonDisabled ? theme.opacity : theme.primary_color,
                          color: theme.secondary_color
                        }}
                        disabled={this.state.addQuizButtonDisabled}
                      >
                        Adicionar
                      </Button>
                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>
            </Dialog>

            <Dialog
              open={this.state.openDialogNewQuiz}
              maxWidth="sm"
              fullWidth
              onClose={() => this.setState({ openDialogNewQuiz: false })}
            >
              <DialogTitle id="alert-dialog-slide-title">
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                >
                  <Grid item>
                    <Typography>
                      Alerta novo questionário
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={() => this.setState({ openDialogNewQuiz: false })}>
                      <Close />
                    </IconButton>
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogContent>
                <Typography variant="h6" component="h6">
                  Prezado usuário,

                  Gostaríamos de informar que o esse questionário está desatualizado e não reflete as últimas atualizações em nosso serviço. Para garantir uma experiência de uso satisfatória e resultados precisos, recomendamos que você use o novo Questionário 2.0 atualizado.

                  O Questionário 2.0 apresenta uma série de melhorias e ajustes em relação ao questionário anterior, incluindo novo modelo de perguntas e opções de resposta.

                  Para acessar o Questionário 2.0, por favor clique no botão abaixo.

                  Se tiver alguma dúvida ou precisar de assistência, não hesite em entrar em contato com nossa equipe de suporte ao cliente.
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  style={{
                    backgroundColor: '#f00',
                    color: '#fff'
                  }}
                  onClick={() => this.setState({ openDialogNewQuiz: false })}
                  autoFocus
                >
                  Permanecer aqui.
                </Button>

                <Button
                  style={{
                    backgroundColor: theme.primary_color
                  }}
                  onClick={() => this.props.changePage(38)}
                  autoFocus
                >
                  Ir para o questionário 2.0
                </Button>
              </DialogActions>
            </Dialog>


          </>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  openQuiz: state.quizReducer.openQuiz,
  loadingQuiz: state.quizReducer.loadingQuiz,
});

const mapDispatchToProps = (dispatch) => ({
  changePage: (indexPage) => dispatch(changePage(indexPage)),
  setOpenQuiz: (openQuiz) => dispatch(setOpenQuiz(openQuiz)),
  setQuiz: (points) => dispatch(setQuiz(points)),
  setLoadingQuiz: (loadingQuiz) => dispatch(setLoadingQuiz(loadingQuiz)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuizDashboard);