import React from "react";
import { useEffect, useState } from "react";
import myApp from "../core/infrastructure/firebaseConfig";
import PrivateRoutes from './PrivateRoutes'
import PublicRoutes from './PublicRoutes'
import { getUserInformation } from "../dashboard/command/getUserInformation";
import Cookies from "js-cookie";
import { CircularProgress, Grid } from "@material-ui/core";

const Routes =  () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [teste, setTeste] = useState(false)
 console.log('teste')
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      myApp.auth().onAuthStateChanged((user) => {
        if (user) {
          setLoggedIn(true);
        }
      });
    }

    return function cleanup() {
      mounted = false;
    };

  }, []);

  useEffect( () => {
    getUserPermition()
  }, []);



 const getUserPermition = async () => {
  const InfoUser = await getUserInformation();
  
  let profille = await myApp.firestore()
  .collection("profiles_company_group")
  .where(
    "fk_company_group",
    "==",
    InfoUser.permitionLevel.fk_company_group
  ) .where(
    "fk_company_group",
    "==",
    InfoUser.permitionLevel.fk_company_group
  )
  .get();

  let pageAux = []

  let getUnicProfile = profille.docs.find(item => item.data().name == InfoUser.permitionLevel.name )

  pageAux.push(getUnicProfile.data().PagePermition)

  console.log(getUnicProfile)
       Cookies.set
(
    "permition",
    pageAux?.length != 0
      ? JSON.stringify(pageAux.map(item => item.map(page => page))[0])
      : "nao existe"
      );
      setTeste(!teste)
}


  if (loggedIn) {
    return <PrivateRoutes />;
  } else {
    return <PublicRoutes />;
  }
};

export default Routes;
