import { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import { setSolicitationId } from "../../../../solicitationChannel/redux/actions";
import theme from "../../../../core/theme.json";
import myApp from "../../../../core/infrastructure/firebaseConfig";
import { changePage } from "../../../presentation/redux/actions";
import { Charts } from "./denunce/charts.jsx";
import { setDenunceObj } from "../redux/ViewsPdfDenunceReducer";
import ScrollWatcher from "../../../../shared/components/scrollWatcher.js";

const DenunciaRefatore = () => {
  const [loading, setLoading] = useState(false);
  const [listDenunce, setListDenunce] = useState([]);
  const [recenteData, setRecenteData] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterSensitive, setFilterSensitive] = useState("");
  const [state, setState] = useState({
    statusfilter: [],
    sensitivefilter: [],
  });

  const dispatch = useDispatch();
  
  useEffect(()=>{
    loadMore()
  },[])

  const loadMore = async () => {
    let copyArea = listDenunce;
    if (!copyArea.length) {
   await getDenunces(copyArea.shift());
    }
  };

  const getDenunces = async () => {
    try {
      
      let aux = [];
      let get = await myApp
        .firestore()
        .collection("denunce")
        .where("fk_company", "==", localStorage.getItem("currentCompany"))
        .get();

      for (let doc of get.docs) {
       
        let denunce = {
          id: doc.ref.id,
          protocol: doc.data().protocol,
          date: doc.data().date,
          status: doc.data().status,
          solicitant: doc.data().company_relation,
          relate_Type: doc.data().relate_Type,
          sensitive: doc.data().Sensitive,
          additionalInformation: doc.data().additionalInformation,
          email: doc.data().email,
          name: doc.data().name,
          verificationConclusation: doc.data().verificationConclusation,
          response: doc.data().response,
          responsedenunce: doc.data().responsedenunce,
          company_user_relation: doc.data().company_user_relation,
          responseHistory: doc.data().responseHistory,
          files_document_response: doc.data().files_document_response,
        };
        aux.push(denunce);
        aux.slice(-1, [0]);
        aux.sort((a, b) => b.date - a.date);
        setListDenunce(...listDenunce,aux);
      }

      setState({
        statusfilter: get.docs.map((item) => item.data()),
        sensitivefilter: get.docs.map((item) => item.data()),
      });
    } catch (err) {
      console.log("erro ao filtrar".err);
    }
    setLoading(false);
  };




  const handleEditSoliciation = (id) => {
    dispatch(setSolicitationId(id));
    dispatch(changePage(43));
  };

  const _altFilDTSol = (e) => {
    if (e === true) {
      handleFilterByDateAsc();
    } else {
      handleFilterByDateDesc();
    }
  };
  console.log(state.filterSensitive);
  const handleFilterByDateAsc = () => {
    let aux = listDenunce;
    aux.sort((a, b) => b.date - a.date);

    setListDenunce(aux);
  };
  const handleFilterByDateDesc = () => {
    let aux = listDenunce;
    aux.sort((a, b) => a.date - b.date);
    setListDenunce(aux);
  };

  const cleanFilter = () => {
    setRecenteData("");
    setFilterStatus("");
    setFilterSensitive("");
    getDenunces();
  };

 const handleChangePageViewToPdf = (denunce) => {
    let aux = {
      protocol: denunce.protocol,
      status: denunce.status,
      date: denunce.date,
      additionalInformation: denunce.additionalInformation,
      relate_Type: denunce.relate_Type,
      company_relation: denunce.solicitant,
      Sensitive: denunce.sensitive,
      name: denunce.name,
      verificationConclusation: denunce.verificationConclusation,
      response: denunce.response,
      responsedenunce: denunce.responsedenunce,
      company_user_relation: denunce.company_user_relation,
      responseHistory: denunce.responseHistory,
      files_document_response: denunce.files_document_response,
    };
    dispatch(setDenunceObj(aux)) ;
    dispatch(changePage(44)) 
  }


  const filterStatusMap = (denunce) => {
    if (filterStatus === "" && denunce.status !== "") {
      return denunce;
    } else if (filterStatus !== "" && denunce.status === filterStatus) {
      return denunce;
    }
  };

  const FilterDenunce = (denunce) => {
    if (filterSensitive !== "" && denunce.sensitive === filterSensitive) {
      return denunce;
    }

    if (filterSensitive === "" && denunce.sensitive !== "") {
      return denunce;
    }
  };

  return (
    <>
      {loading ? (
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          style={{ paddingTop: "50px" }}
        >
          <CircularProgress size={60} color={theme.secondary_color} />
        </Grid>
      ) : (
        <>
          <Charts />

          <Grid
            component={Paper}
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            style={{ padding: "10px", marginTop: 20 }}
          >
            <Grid
              item
              container
              alignItems="center"
              justifyContent="flex-start"
              xs={2}
            >
              <Button
                variant="outlined"
                onClick={() => cleanFilter()}
                style={{
                  color: theme.secondary_color,
                  fontSize: "15px",
                  backgroundColor: theme.primary_color,
                }}
              >
                Limpar
              </Button>
            </Grid>

            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              md={2}
              sm={4}
            >
              <FormControl
                size="small"
                variant="outlined"
                style={{ width: "90%" }}
              >
                <InputLabel>Data de denúncia</InputLabel>
                <Select
                  label="Data de Denúncia"
                  value={state.recenteDate}
                  onChange={(e) => {
                    _altFilDTSol(e.target.value);
                    setRecenteData(e.target.value);
                  }}
                >
                  <MenuItem value={true}>Mais recente</MenuItem>
                  <MenuItem value={false}>Menos recente</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              md={2}
              sm={4}
            >
              <FormControl
                size="small"
                variant="outlined"
                style={{ width: "90%" }}
              >
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  value={filterStatus}
                  onChange={(event) => {
                    setFilterStatus(event.target.value);
                  }}
                >
                  <MenuItem value={"Aberto"}>Aberto</MenuItem>
                  <MenuItem value={"Em andamento"}>Em andamento</MenuItem>
                  <MenuItem value={"Concluído"}>Concluído</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              md={2}
              sm={4}
            >
              <FormControl
                size="small"
                variant="outlined"
                style={{ width: "90%" }}
              >
                <InputLabel>Sensibilidade</InputLabel>
                <Select
                  label="Sensibilidade"
                  value={filterSensitive}
                  onChange={(event) => {
                    setFilterSensitive(event.target.value);
                  }}
                >
                  <MenuItem value={"sensível"}>sensível</MenuItem>
                  <MenuItem value={"Muito sensível"}>Muito sensível</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={1}
            style={{ marginTop: 5, paddingLeft: "2%" }}
          >
            <ScrollWatcher populate={loadMore}  >
            <TableContainer style={{ maxHeight: "80vw" }}>
              <Table stickyHeader size="medium">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ fontWeight: "Bold" }}>
                      Protocolo
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontWeight: "Bold" }}
                    ></TableCell>
                    <TableCell align="center" style={{ fontWeight: "Bold" }}>
                      Data de denúncia
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontWeight: "Bold" }}
                    ></TableCell>
                    <TableCell
                      align="center"
                      style={{ fontWeight: "Bold" }}
                    ></TableCell>
                    <TableCell align="center" style={{ fontWeight: "Bold" }}>
                      Status
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: "Bold" }}>
                      Sensibilidade
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: "Bold" }}>
                      Ações
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listDenunce
                    ?.filter(filterStatusMap)
                    .filter(FilterDenunce)
                    .map((denunce, k) => (
                      <TableRow
                        key={k}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        style={
                          k % 2
                            ? { background: "white" }
                            : { background: "#e3e3e3" }
                        }
                      >
                        <TableCell
                          onClick={() => {
                            handleEditSoliciation(denunce.id);
                          }}
                          component="th"
                          scope="row"
                          align="center"
                          style={{ fontWeight: "Bold" }}
                        >
                          {denunce.protocol}
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            handleEditSoliciation(denunce.id);
                          }}
                          component="th"
                          scope="row"
                          align="center"
                          style={{ fontWeight: "Bold" }}
                        ></TableCell>
                        <TableCell
                          onClick={() => {
                            handleEditSoliciation(denunce.id);
                          }}
                          component="th"
                          scope="row"
                          align="center"
                          style={{ fontWeight: "Bold" }}
                        >
                          {Intl.DateTimeFormat("pt-br").format(denunce.date)}
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            handleEditSoliciation(denunce.id);
                          }}
                          component="th"
                          scope="row"
                          align="center"
                          style={{ fontWeight: "Bold" }}
                        ></TableCell>

                        <TableCell
                          onClick={() => {
                            handleEditSoliciation(denunce.id);
                          }}
                          component="th"
                          scope="row"
                          align="center"
                          style={{ fontWeight: "Bold" }}
                        ></TableCell>

                        <TableCell
                          onClick={() => {
                            handleEditSoliciation(denunce.id);
                          }}
                          component="th"
                          scope="row"
                          align="center"
                          style={{ fontWeight: "Bold" }}
                        >
                          {denunce.status}
                        </TableCell>

                        <TableCell
                          onClick={() => {
                            handleEditSoliciation(denunce.id);
                          }}
                          component="th"
                          scope="row"
                          align="center"
                          style={{ fontWeight: "Bold" }}
                        >
                          {denunce.sensitive ? (
                            denunce.sensitive
                          ) : (
                            <span style={{ color: "red" }}>Não Respondido</span>
                          )}
                        </TableCell>

                        {denunce.status == "Concluído" ? (
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={{ fontWeight: "Bold" }}
                          >
                            <Button
                              onClick={() =>handleChangePageViewToPdf(denunce)}
                              disabled={!denunce.id}
                              style={{
                                background: denunce.id
                                  ? theme.primary_color
                                  : "#ccc",
                                color: theme.secondary_color,
                              }}
                            >
                              Visualizar PDF
                            </Button>
                          </TableCell>
                        ) : (
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={{ fontWeight: "Bold" }}
                          >
                            <Button
                              onClick={() =>
                                handleChangePageViewToPdf(denunce)
                              }
                              disabled={true}
                              style={{
                                background: theme.disabled_color,
                                color: "#fff",
                              }}
                            >
                              Vizualizar PDF
                            </Button>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
        </ScrollWatcher>
          </Grid>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changePage: (page) => dispatch(changePage(page)),
  setSolicitationId: (solicitationId) =>
    dispatch(setSolicitationId(solicitationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DenunciaRefatore);
