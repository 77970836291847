import { Button, ButtonBase, TextField } from "@material-ui/core";
import { useState } from "react";
import Grid from "@material-ui/core/Grid";

import {CircularProgress} from '@material-ui/core'
import myApp from "../../core/infrastructure/firebaseConfig";
import { Tune } from "@material-ui/icons";

export const DenunceSelect = () => {

  const [company, setCompany] = useState(localStorage.getItem("currentCompany"));
 
  return (
      
   <Grid
   container
   direction="column"
   justifyContent="center"
   alignItems="center"
 >
 

 <h1>
     Você prefere criar uma nova denúncia ou acompanhar uma denúncia existente
     ?
   </h1>

   <Grid style={{ marginTop: "10%" }}>
     <Button
       style={{ width: "300px", height: "50px" }}
       onClick={() =>
        (window.location.href =`${window.location.origin}/#/denunce/ViewDenunce/${company}`)
      }
       variant="contained"
       color="primary"
     >
       Acompanhar uma Denúncia Existente
     </Button>
   </Grid>

   <Grid>
     <Button
       style={{ width: "300px", height: "50px", marginTop: "10%" }}
       onClick={() =>
         (window.location.href =`${window.location.origin}/#/denunce/client/company/${company}`)
    
       }
       variant="contained"
       color="primary"
     >
       Criar nova Denúncia
     </Button>
   </Grid>
   </Grid> 
  
  

  );
};
