import { StyleSheet } from "@react-pdf/renderer";
import theme from '../../../../core/theme.json'



export const style = StyleSheet.create({

  between:{
    flexDirection: 'row',
   width: '100%',
   justifyContent:"space-between",
   padding:'10px'

  },

  textcenter:{
    textAlign:'center',
  paddingVertical:'15px',
  fontSize: 20
  },

  button:{
    fontSize:'12px',
    backgroundColor: theme.primary_color,
    color: theme.secondary_color,
    padding: "3px",
    borderRadius:'2px',
  }

})