
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import GirlLapTop from "../../../core/assets/grilLaptop.jpg";
import HelpIcon from "@material-ui/icons/Help";
import { ButtonBase, Paper, Tooltip } from "@material-ui/core";
import theme from "../../../core/theme.json";

export const DenunceItem = ({ item, paramer,urlLogo }) => {
  const sensitive = item.map((item) => item.Sensitive);
  const urlChanelService = paramer.map((item) => item.serviceChannelUrl);

  console.log("item", urlLogo)
  return (
    <div>
      <Grid
         container
         direction="row"
         alignItems="center"
         justifyContent="center"
      >
      <img src={urlLogo}/>

      </Grid>
      {item.map((item) => (
        <Grid item>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={3}
            style={{ marginTop: "5% " }}
          >

            <Grid item>
              <Typography
                variant="h4"
                component="h4"
                style={{ width: "100%", marginBottom: "20px" }}
              >
                Nº de Protocolo{" "}
                <span style={{ color: "#4682B4" }}>{item.protocol}</span>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={3}
            style={{ marginTop: "5% " }}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={3}
            >
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <Typography variant="h6" component="h6">
                      Tipo de solicitante
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body1"
                      component="p"
                      color="textSecondary"
                    >
                      {item.company_relation}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <Typography variant="h6" component="h6">
                      Tipo de solicitação
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body1"
                      component="p"
                      color="textSecondary"
                    >
                      {item.relate_Type}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <Typography variant="h6" component="h6">
                      Data de criação
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body1"
                      component="p"
                      color="textSecondary"
                    >
                      {Intl.DateTimeFormat("pt-br").format(item.date)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <Typography variant="h6" component="h6">
                      Data prevista
                    </Typography>
                  </Grid>
                  <Grid item>
                    {sensitive == "Muito sensível"
                      ? paramer.map((item) => (
                          <Typography
                            variant="body1"
                            component="p"
                            color="textSecondary"
                          >
                            {item.expectedDAteSensive
                              ? item.expectedDAteSensive + " Dias"
                              : "60 Dias"}
                          </Typography>
                        ))
                      : paramer.map((item) => (
                          <Typography
                            variant="body1"
                            component="p"
                            color="textSecondary"
                          >
                            {item.expectedDate
                              ? item.expectedDate + " Dias"
                              : "60 Dias"}
                          </Typography>
                        ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {sensitive == "Muito sensível" && (
              <Grid style={{ marginTop: "10%" }}>
                <Typography variant="body1" component="body1">
                  Diante da classificação do Grau de Complexidade, o processo de
                  investigação respeitou o prazo de até{" "}
                  <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                    {paramer.map((item) => item.expectedDAteSensive)}
                  </span>{" "}
                  dias úteis após o recebimento do relato.
                </Typography>
              </Grid>
            )}

            <Grid style={{ marginTop: "10%", display: "flex" }}>
              <Typography
                variant="h4"
                component="h4"
                style={
                  item.response
                    ? { width: "100%", marginBottom: "20px" }
                    : { width: "100%", marginBottom: "20px" }
                }
              >
                O Status atual da sua denúncia é :{" "}
                <span style={{ fontSize: "30px", color: "#4682B4" }}>
                  {item.status}
                </span>
              </Typography>
              <Tooltip
                style={{ fontSize: 20 }}
                placement="top"
                title={
                  item.status == "Em andamento"
                    ? "Em análise"
                    : item.status == "Concluído"
                    ? "Denúncia investigada e finalizada"
                    : "Denúncia enviada"
                }
              >
                <HelpIcon style={{ paddingTop: "1.5%", color: "#c4c4c4" }} />
              </Tooltip>
            </Grid>
{/* 
            <Grid style={{ margin: "10% 0" }}>
              {item.name && (
                <>
                  <Typography
                    variant="h4"
                    component="h4"
                    style={{
                      width: "100%",
                      marginBottom: "20px",
                      padding: "5px",
                    }}
                  >
                    Nome do apurador da denúncia
                  </Typography>

                  <Box style={{ width: "100%" }}>
                    <Typography
                      style={{
                        color: "gray",
                        maxWidth: "90%",
                        padding: "10px",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      {item.name}
                    </Typography>
                  </Box>
                </>
              )}
            </Grid>

            <Grid container style={{ margin: "5% 0" }}>
              {item.response ? (
                <>
                  <Grid style={{ width: "100% ", margin: "5% 0px " }}>
                    <Typography variant="body1" component="p">
                      Anexos do investigador{" "}
                    </Typography>
                    {item.files_document_response !== "" ? (
                      item.files_document_response?.map((item) => {
                        return (
                          <Paper
                            style={{
                              padding: "8px",
                              margin: "15px 0",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "1rem",
                                color: "#72bcd4",
                              }}
                            >
                              {item.name}
                            </span>

                            <ButtonBase
                              onClick={() => window.open(item.url)}
                              style={{
                                background: theme.primary_color,
                                color: theme.secondary_color,
                                padding: "5px",
                              }}
                            >
                              {" "}
                              vizualizar arquivo
                            </ButtonBase>
                          </Paper>
                        );
                      })
                    ) : (
                      <Typography> Nenhum arquivo anexado</Typography>
                    )}
                  </Grid>

                  <Typography
                    variant="h5"
                    component="h5"
                    style={{
                      width: "100%",
                      marginBottom: "20px",
                      padding: "5px",
                    }}
                  >
                    Resposta da sua Solicitação
                  </Typography>

                  <Box
                    style={{ width: "100%" }}
                    border={1}
                    borderRadius="borderRadius"
                  >
                    <Typography
                      style={{
                        color: "gray",
                        maxWidth: "90%",
                        padding: "10px",
                      }}
                    >
                      {" "}
                      {item.response !== ""
                        ? item.response
                        : "Ainda não há nenhuma Resposta"}
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  <div
                    style={{
                      marginLeft: "15%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "-10%",
                    }}
                  >
                    <Typography
                      variant="h5"
                      style={{
                        color: "gray",
                        maxWidth: "60%",
                        padding: "10px",
                      }}
                    >
                      Ainda não há nenhuma resposta para sua denúncia, pedimos
                      para que verifique diáriamente a sua solicitação.
                    </Typography>
                    <img
                      src={GirlLapTop}
                      width="30%"
                      style={{ marginRight: "25%" }}
                    />
                  </div>
                </>
              )}
            </Grid> */}

            <Grid style={{ margin: "10% 0", display: "flex" }}>
              {item.verificationConclusation && (
                <>
                  <Typography
                    variant="h5"
                    component="h5"
                    style={{
                      width: "100%",
                      marginBottom: "20px",
                      padding: "5px",
                    }}
                  >
                    Conclusão da Apuração :{" "}
                    <span style={{ fontSize: "30px", color: "#4682B4" }}>
                      {item.verificationConclusation}
                    </span>
                  </Typography>
                  <Tooltip
                    style={{ fontSize: 20 }}
                    placement="top"
                    title={
                      item.verificationConclusation == "Procedente"
                        ? "Houve irregularidade, a empresa tomará as devidas providências.."
                        : item.verificationConclusation == "Não Procedente"
                        ? "Não houve irregularidade"
                        : "Não foi possível definir se houve ou não alguma irregularidade."
                    }
                  >
                    <HelpIcon
                      style={{ paddingTop: "2.5%", color: "#c4c4c4" }}
                    />
                  </Tooltip>
                </>
              )}
            </Grid>

            <Grid style={{ margin: "10% 0" }}>
              {urlChanelService.map((item) => {
                console.log("tste", item);
                if (item !== "") {
                  return (
                    <>
                      <Typography
                        variant="body1"
                        component="body1"
                        style={{
                          width: "100%",
                          marginBottom: "20px",
                          padding: "5px",
                        }}
                      >
                        Link do canal de antendimento :
                        <span
                          onClick={() =>
                            (window.open( `https://${paramer.map(
                              (item) => item.serviceChannelUrl
                            )}`))
                          }
                          style={{ color: "#4682B4", cursor: "pointer" }}
                        >
                          {paramer.map((item) => item.serviceChannelUrl)}
                        </span>
                      </Typography>
                    </>
                  );
                } else {
                  return;
                }
              })}
            </Grid>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};
