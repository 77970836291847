import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import Typography from '@material-ui/core/Typography'
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox'
import { formatDate, formatHour } from '../commands/formatDate'
import { AttachFileTwoTone, DoneAll, MessageTwoTone, PersonTwoTone } from '@material-ui/icons'
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Zoom
} from '@material-ui/core'
import Link from '@material-ui/core/Link'
import IconButton from '@material-ui/core/IconButton'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}))

function LogDate ({ date }) {
  return formatDate(date) + ' ' + formatHour(date)
}

function HistoryItem ({ item }) {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (

    <>
    
  <Zoom in={true} style={{ transitionDelay: true ? '500ms' : '0ms' }}>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="primary">
            <MessageTwoTone/>
          </TimelineDot>
          <TimelineConnector/>
        </TimelineSeparator>
        <TimelineContent style={{ textAlign: 'left' }}>
          <Card>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe">
                  <PersonTwoTone/>
                </Avatar>
              }
              title={item?.userName ?? 'Atendente'}
            
            /> 
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                {item.response}
                {
                  item?.additionalInformationFile && (
                    <>
                      <br/>
                      <span>Anexo de resposta:{' '}
                        <Link target="_blank" href={item?.responseFile?.fileUrl}>link</Link>
                      </span>
                    </>
                  )
                }
              
              </Typography>
         
            </CardContent>
            {
              item?.internalDocumentsFiles && (
                <>
                  <CardActions disableSpacing>
                    <Typography variant="body2" color="textSecondary" component="span">
                      Documentos internos
                    </Typography>
                    <IconButton
                      className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                      })}
                      onClick={handleExpandClick}
                      size="small"
                      aria-expanded={expanded}
                      aria-label="mostrar mais"
                    >
                      <ExpandMoreIcon/>
                    </IconButton>
                  </CardActions>
                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                      <List component="nav" aria-label="documentos internos">
                        {
                          item?.internalDocumentsFiles.map((document, index) => (
                            <ListItem key={index} button component="a" href={document.fileUrl} target="_blank">
                              <AttachFileTwoTone/>{'  '}<ListItemText primary={document.fileName}/>
                            </ListItem>
                          ))
                        }
                      </List>
                    </CardContent>
                  </Collapse>
                </>
              )
            }
          </Card>
        </TimelineContent>
      </TimelineItem>
    </Zoom>
    </>

  )
}

function DenunceTimeLine ({ data }) {


  return (
    <Timeline align="alternate">
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="grey">
            <MarkunreadMailboxIcon/>
          </TimelineDot>
          <TimelineConnector/>
        </TimelineSeparator>
        <TimelineContent>
          <Card>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe">
                  <PersonTwoTone/>
                </Avatar>
              }
              title={`Solicitante Anônimo`}
            />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">Solicitação criada</Typography>
            </CardContent>
          </Card>
        </TimelineContent>
      </TimelineItem>

      {
        (data?.responseHistory && data?.responseHistory) &&
        data.responseHistory.map((row, index) => <HistoryItem key={index} item={row}/>)
      }

      {
        data.done && (
          <Zoom in={!!data.done} style={{ transitionDelay: !!data.done ? '500ms' : '0ms' }}>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="secondary">
                  <DoneAll/>
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent>
                <Card>
                  <CardHeader
                    title="Denúncia encerrada"
                    subheader={
                      data?.conclusionDate ? (
                        <TimelineOppositeContent>
                          <Typography variant="body2" color="textSecondary">
                            <LogDate date={new Date(data.conclusionDate.seconds * 1000)}/>
                          </Typography>
                        </TimelineOppositeContent>
                      ) : (
                        <></>
                      )
                    }
                  />
                </Card>
              </TimelineContent>
            </TimelineItem>
          </Zoom>
        )
      }
    </Timeline>
  )
}

export default DenunceTimeLine