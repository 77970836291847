import React, { useEffect, useState } from 'react'
import ErrorBoundary from '../../../../shared/components/ErrorBoundary'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { Button, ClickAwayListener, Container, Grid, LinearProgress, Tooltip, Zoom } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import { FileCopyTwoTone } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  markdown: {
    backgroundColor: '#081e00',
    color: '#38f205',
    borderRadius: '5px',
    padding: '10px',
    width: '100%',
    position: "relative",
    paddingRight: "80px",
    lineBreak: "anywhere"
  },
  copyButton: {
    position: "absolute",
    top: 0,
    right: 0,
    height: "100%",
    color: 'white',
    '&:hover': {
      color: '#38f205'
    }
  },
  markdownItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  details: {
    alignItems: 'center',
  },
  saveButtom: {
    backgroundColor: '#38f205'
  },
  formControl: {
    width: '100%'
  },
}))

/**
 *
 * @param {'group'|'company'} type
 * @returns {JSX.Element}
 * @constructor
 */
function UrlConfig(props) {
  const classes = useStyles()

  const [isExpanded, setIsExpanded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [fetchedData, setFetchedData] = useState(false)
  const [openTooltip, setOpenTooltip] = useState(false)

  const [solicitationUrl, setsolicitationUrl] = useState('')
  const fkCompanyGroup = JSON.parse(sessionStorage.getItem('@blue-legal/userInformation')).fk_company_group
  const fkCurrentCompany = localStorage.getItem('currentCompany')
  const [denunceUrl, setDenunceUrl] = useState(`${window.location.origin}/#/denunce/client/selectDenunce/${fkCurrentCompany}`)
  const [userAdmin, setUserAdmin] = useState(localStorage.getItem('userAdmin') === 'sim' ? false : true);

  const handleToggleAccordion = (event, expanded) => {
    setIsExpanded(expanded)
    if (!expanded) {
      setIsLoading(false)
      setFetchedData(false)
    }
  }

  const handleCloseTooltip = () => setOpenTooltip(false)

  const handleSave = event => {

  }

  const updateClipboard = (text) => {
    navigator.clipboard.writeText(text).then(function () {
      console.log('copiado')
      setOpenTooltip(true)
    }, function () {
      console.log('falhou')
    })
  }

  const copyUrlToClipboard = event => {
    event.preventDefault()
    navigator.permissions.query({ name: 'clipboard-write' }).then(result => {
      if (result.state === 'granted' || result.state === 'prompt') {
        updateClipboard(solicitationUrl)
      }
    })
  }

  const copyUrlToClipboardDenunce = event => {
    event.preventDefault()
    navigator.permissions.query({ name: 'clipboard-write' }).then(result => {
      if (result.state === 'granted' || result.state === 'prompt') {
        updateClipboard(denunceUrl)
      }
    })
  }

  const fetchConfigs = async () => {
    /*switch (type) {
      case 'company':
        setsolicitationUrl('https://' +
          window.location.host +
          `/#/solicitation/client/${fkCompanyGroup}/${fkCurrentCompany}`
        )
        break
      case 'group':
      default:
        setsolicitationUrl('https://' + window.location.host + `/#/solicitation/client/${fkCompanyGroup}`)
    }*/

    if (props.type) {
      setsolicitationUrl('https://' +
        window.location.host +
        `/#/solicitation/client/company/${fkCurrentCompany}`
      )
    } else {
      setsolicitationUrl('https://' +
        window.location.host +
        `/#/solicitation/client/${fkCompanyGroup}`
      )
    }

    setFetchedData(true)
    setIsLoading(false)
  }

  useEffect(() => {
    if (!isLoading && isExpanded && !fetchedData) {
      setIsLoading(true)
      fetchConfigs()
    }
  }, [isLoading, isExpanded, fetchedData])

  return (
    <ErrorBoundary>
      <form onSubmit={handleSave}>
        <Accordion
          expanded={isExpanded}
          onChange={handleToggleAccordion}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Configurações do link e incorporação</Typography>
          </AccordionSummary>

          <AccordionDetails className={classes.details}>
            <Grid
              container
              justifyContent="space-around"
              alignItems="center"
              spacing={2}
            >
              <Grid item md={12}>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className={classes.formControl}
                >
                  {
                    isLoading ? (
                      <Grid item>
                        <Grid
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="stretch"
                        >
                          <Grid item>
                            <LinearProgress />
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <>
                        <Grid item md={12}>
                          <Zoom in timeout={500}>
                            <div>
                            {props.urldenunce ? '':  <Typography variant="caption">
                                O link abaixo leva até a{' '}
                                <Link href={solicitationUrl} target="_blank">Ficha de Solicitação</Link>
                                {' '}{props.type ? 'da sua empresa' : 'do seu grupo de empresas'}
                              </Typography>}
                              <Container className={classes.markdown}>
                                <div className={classes.markdownItem}>
                                  <Typography variant="caption">
                                    {props.urldenunce == true ? denunceUrl : solicitationUrl}
                                  </Typography>
                                </div>

                                <ClickAwayListener onClickAway={handleCloseTooltip}>
                                  <Tooltip
                                  
                                    PopperProps={{
                                      disablePortal: true,
                                    }}
                                    open={openTooltip}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title="Copiado!"
                                    placement="top"
                                    onClose={handleCloseTooltip}
                                  >
                                    <Button
                                      className={classes.copyButton}
                                      aria-label="copy"
                                      color="primary"
                                      onClick={props.urldenunce ? copyUrlToClipboardDenunce : copyUrlToClipboard}
                                    >
                                      <Tooltip title="copiar" >
                                      <FileCopyTwoTone />
                                      </Tooltip>
                                    </Button>
                                  </Tooltip>
                                </ClickAwayListener>
                              </Container>
                            </div>
                          </Zoom>
                        </Grid>
                      </>
                    )
                  }
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </form>
    </ErrorBoundary>
  )
}

export default UrlConfig