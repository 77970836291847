import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import Swal from 'sweetalert2'

const database = myApp.firestore();

export const saveNewRopaAnswers = async (questions, processId,  ) => {
  
  try {
    let aux = [
      {
        oq: 'Processo de Negócio',
        dado: questions[1].answer ? questions[1].answer : ''
      },
      {
        oq: 'Diretoria',
        dado: questions[2].answer ? questions[2].answer : ''
      },
      {
        oq: 'Área de Negócio',
        dado: questions[3].answer ? questions[3].answer : ''
      },
      {
        oq: 'Responsável pelo Processo',
        dado: questions[4].answer ? questions[4].answer : ''
      },
      {
        oq: 'Contato ',
        dado: questions[5].answer ? questions[5].answer : '',
        alternativesList: questions[5].alternativesList
      },
      {
        oq: 'Criticidade ao negócio - Manter a mesma orgiem',
        dado: '',
        alternativesList: []
      },
      {
        oq: 'Última Atualização - Manter a mesma orgiem',
        dado: '',
        alternativesList: []
      },
      {
        oq: 'Finalidade de coleta',
        dado: questions[9].answer ? questions[9].answer : '',
        alternativesList: questions[9].alternativesList
      },
      {
        oq: 'Necessidade',
        dado: questions[10].answer ? questions[10].answer : '',
        alternativesList: questions[10].alternativesList
      },
      {
        oq: 'Formas de coleta - Manter a mesma orgiem',
        dado: '',
        alternativesList: []
      },
      {
        oq: 'Dados Pessoais',
        dado: questions[7].answer ?  questions[7].answer : '',
        alternativesList: questions[7].alternativesList
      },
      {
        oq: 'Dados sensíveis',
        dado: questions[8].answer ? questions[8].answer : '',
        alternativesList: questions[8].alternativesList
      },
      {
        oq: 'Controlador ou Operador - Manter a mesma orgiem',
        dado: '',
        alternativesList: []
      },
      {
        oq: 'Nome',
        dado: questions[6].answer ? questions[6].answer : '',
        alternativesList: questions[6].alternativesList
      },
      {
        oq: 'Origem',
        dado: questions[11].answer ? questions[11].answer : '',
        alternativesList: questions[11].alternativesList
      },
      {
        oq: 'Destino',
        dado: questions[14].answer ? questions[14].answer : '',
        alternativesList: questions[14].alternativesList
      },
      {
        oq: 'Compartilhamento interno',
        dado: questions[14].answer ? questions[14].answer : '',
        alternativesList: questions[14].alternativesList
      },
      {
        oq: 'Transmissão - Manter a mesma orgiem',
        dado: '',
        alternativesList: []
      },
      {
        oq: 'Repositório',
        dado: questions[12].answer ? questions[12].answer : '',
        alternativesList: questions[12].alternativesList
      },
      {
        oq: 'Sistemas - Manter a mesma orgiem',
        dado: '',
        alternativesList: []
      },
      {
        oq: 'Compartilhamento externo',
        dado: questions[15].answer ? questions[15].answer : '',
        alternativesList: questions[15].alternativesList
      },
      {
        oq: 'Nome do Terceiro',
        dado: questions[16].answer ? questions[16].answer : '',
        alternativesList: questions[16].alternativesList
      },
      {
        oq: 'Finalidade - Manter a mesma orgiem',
        dado: '',
        alternativesList: []
      },
      {
        oq: 'Transmissão2 - Manter a mesma orgiem',
        dado: '',
        alternativesList: []
      },
      {
        oq: 'Retenção',
        dado: questions[18].answer ? questions[18].answer : '',
        alternativesList: questions[18].alternativesList
      },
      {
        oq: 'Descarte - Manter a mesma orgiem',
        dado: '',
        alternativesList: []
      },
      {
        oq: 'Adequações de tratamento - Manter a mesma orgiem',
        dado: '',
        alternativesList: []
      },
      {
        oq: 'Transparência ao titular - Manter a mesma orgiem',
        dado: '',
        alternativesList: []
      },
      {
        oq: 'Dados necessários',
        dado: questions[10].answer ? questions[10].answer : '',
        alternativesList: questions[10].alternativesList
      },
      {
        oq: 'Direito do titular',
        dado: questions[20].answer ? questions[20].answer : '',
        alternativesList: questions[20].alternativesList
      },
      {
        oq: 'Frequência de Atualização - Manter a mesma orgiem',
        dado: '',
        alternativesList: []
      },
      {
        oq: 'Auditoria Interna - Manter a mesma orgiem',
        dado: '',
        alternativesList: []
      },
      {
        oq: 'Due Dilligence Parceiros - Manter a mesma orgiem',
        dado: '',
        alternativesList: []
      },
      {
        oq: 'Adequações de compliance',
        dado: questions[22].answer ? questions[22].answer : '',
        alternativesList: questions[22].alternativesList
      },
      {
        oq: 'Prazo legal de retenção - Manter a mesma orgiem',
        dado: '',
        alternativesList: []
      },
      {
        oq: 'Base Legal - Manter a mesma orgiem',
        dado: '',
        alternativesList: []
      },
      {
        oq: 'Referência em Lei - Manter a mesma orgiem',
        dado: '',
        alternativesList: []
      },
      {
        oq: 'Sensível ou Não-Sensível - Manter a mesma orgiem',
        dado: '',
        alternativesList: []
      },
      {
        oq: 'Contratos com Parceiros - Manter a mesma orgiem',
        dado: '',
        alternativesList: []
      },
      {
        oq: 'Adequações jurídicas - Manter a mesma orgiem',
        dado: '',
        alternativesList: []
      },
      {
        oq: 'Controle de acessos - Manter a mesma orgiem',
        dado: '',
        alternativesList: []
      },
      {
        oq: 'Autenticação',
        dado: questions[19].answer ? questions[19].answer:'',
        alternativesList: questions[19].alternativesList
      },
      {
        oq: 'Classificação dos dados - Manter a mesma orgiem',
        dado: '',
        alternativesList: []
      },
      {
        oq: 'Medidas Técnicas de Segurança',
        dado: questions[21].answer ? questions[21].answer : '',
        alternativesList: questions[21].alternativesList
      },
      {
        oq: 'Cópia segura - Manter a mesma orgiem',
        dado: '',
        alternativesList: []
      },
      {
        oq: 'Indicar o país?',
        dado: questions[17].answer ? questions[17].answer : '',
        alternativesList: questions[17].alternativesList
      },
      {
        oq: 'Este processo pode causar impacto ao negócio se ficar indisponível? - Manter a mesma orgiem',
        dado: '',
        alternativesList: []
      },
      {
        oq: 'Rara, Baixa, Média, Quase Certa, Alta - Manter a mesma orgiem',
        dado: '',
        alternativesList: []
      },
      {
        oq: 'Insignificante, Pequeno, Médio, Grande, Muito Grande - Manter a mesma orgiem',
        dado: '',
        alternativesList: []
      },
      {
        oq: 'De acordo com os padrões LGPD - Manter a mesma orgiem',
        dado: '',
        alternativesList: []
      },
      {
        oq: 'Indicar a última auditoria - Manter a mesma orgiem',
        dado: '',
        alternativesList: []
      },
      {
        oq: 'Indicar a última auditoria e guardar evidências - Manter a mesma orgiem',
        dado: '',
        alternativesList: []
      },
      {
        oq: 'Documentação comprobatória',
        dado: questions[23].answer ? questions[23].answer : '',
        alternativesList: questions[23].alternativesList
      },
    ]

    await database.collection("processes").doc(processId).update({
      ROPAanswers: aux
    });
    console.log('questions', questions);


  } catch (error) {
    console.error("Error saving ROPA answers: ", error);
    alert('Houve um erro ao salvar as respostas. Por gentileza, entre em contato com o suporte para obter assistência.');
  }
};

export const saveRopaAnswers = async (questions, processId) => {
  console.log('questions', questions);
  try {
    const processDoc = await database.collection("processes").doc(processId).get();
    let ROPAanswers = processDoc.data().ROPAanswers;
    console.log('id', processId);
    console.log('QUestions', questions);
    console.log('Ropa', processDoc.data());
    
    ROPAanswers[0] = questions[0].answers[questions[0].answer].text;
    
    if (questions[1].answers[questions[1].answer].text.toUpperCase().includes("OUTRA")) {
      ROPAanswers[1] = questions[1].observation;
    } else {
      ROPAanswers[1] = questions[1].answers[questions[1].answer].text;
      
    }

    if (questions[2].answers[questions[2].answer].text.toUpperCase().includes("OUTRA")) {
      ROPAanswers[2] = questions[2].observation;
    } else {
      ROPAanswers[2] = questions[2].answers[questions[2].answer].text;
    }

    ROPAanswers[4] = questions[3].answers[questions[3].answer].text;
    ROPAanswers[5] = questions[4].answers[questions[4].answer].text;

    if (questions[4].answers[questions[4].answer].text) {
      if (questions[4].answers[questions[4].answer].text.toUpperCase().includes("SISTEMA")) {
        ROPAanswers[6] = questions[4].observation;
      } else {
        ROPAanswers[6] = "";
      }
    }

    ROPAanswers[7] = questions[5].answers[questions[5].answer].text;

    if (questions[5].answers[questions[5].answer].text) {
      if (questions[5].answers[questions[5].answer].text.toUpperCase().includes("SIM")) {
        ROPAanswers[8] = questions[5].observation;
        ROPAanswers[9] = questions[6].observation;
        ROPAanswers[11] = questions[7].observation;
        ROPAanswers[12] = questions[8].observation;
        ROPAanswers[14] = questions[9].answers[questions[9].answer].text;
        ROPAanswers[15] = questions[11].answers[questions[11].answer].text;
        ROPAanswers[20] = questions[12].observation;
        ROPAanswers[22] = questions[13].answers[questions[13].answer].text;
        ROPAanswers[24] = questions[14].answers[questions[14].answer].text;

        if (questions[15].answers[questions[15].answer].text.toUpperCase().includes("INDICAR")) {
          ROPAanswers[25] = questions[15].observation;
        } else {
          ROPAanswers[25] = questions[15].answers[questions[15].answer].text;
        }

        ROPAanswers[26] = questions[16].answers[questions[16].answer] ? questions[16].answers[questions[16].answer].text : '';
      } else {
        ROPAanswers[11] = questions[6].observation;
        ROPAanswers[12] = questions[7].observation;
        ROPAanswers[14] = questions[8].answers[questions[8].answer].text;
        ROPAanswers[15] = questions[10].answers[questions[10].answer].text;
        ROPAanswers[20] = questions[11].observation;
        ROPAanswers[22] = questions[12].answers[questions[12].answer].text;
        ROPAanswers[24] = questions[13].answers[questions[13].answer].text;

        if (questions[14].answers[questions[14].answer].text) {
          if (questions[14].answers[questions[14].answer].text.toUpperCase().includes("INDICAR")) {
            ROPAanswers[25] = questions[14].observation;
          } else {
            ROPAanswers[25] = questions[14].answers[questions[14].answer].text;
          }
        }

        ROPAanswers[26] = questions[15].answers[questions[15].answer].text;
      }
    }

    await database.collection("processes").doc(processId).update({
      ROPAanswers
    });
  } catch (error) {
    console.error("Error saving ROPA answers: ", error);
  }
};