import React, { Component } from 'react';
import myApp from '../../core/infrastructure/firebaseConfig';
import "firebase/compat/firestore";

import { Grid, TextField, FormControlLabel, Switch } from '@material-ui/core';

import { addDpoInfo } from '../redux/actions';
import { connect } from 'react-redux';

import InputMask from 'react-input-mask';

const TextMaskCustomCEP = (props) => {
  const { inputRef, ...other } = props;

  return (
    <InputMask
      {...other}
      ref={inputRef}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
      showMask
    />
  );
}

class DpoObject {
  name = "";
  dpoCnpj = "";
  dpoEmail = "";
  phone = "";

  isCompanyEmployee = true;
  dpoCompanyCnpj = "";
  dpoCompanyName = "";

  dpoStreet = "";
  dpoNumber = "";
  dpoComplement = "";
  dpoCity = "";
  dpoNeighborhood = "";
  dpoState = "";
  dpoCEP = "";
}

class CompanyRegistrationDPO extends Component {
  constructor(props) {
    super(props);

    this.db = myApp.firestore();

    this.state = {
      name: "",
      dpoCnpj: "",
      dpoEmail: "",
      phone: "",
      isCompanyEmployee: true,
      dpoCompanyCnpj: "",
      dpoCompanyName: "",
      dpoStreet: "",
      dpoNumber: "",
      dpoComplement: "",
      dpoCity: "",
      dpoNeighborhood: "",
      dpoState: "",
      dpoCEP: "",

      validEntries: false
    }
  }

  componentDidMount() {
    this.setState({
      name: this.props.dpoName,
      dpoCnpj: this.props.dpoCnpj,
      dpoEmail: this.props.dpoEmail,
      phone: this.props.dpoPhone,
      isCompanyEmployee: this.props.dpoIsCompanyEmployee,
      dpoCompanyCnpj: this.props.dpoCompanyCnpj,
      dpoCompanyName: this.props.dpoCompanyName,
      dpoStreet: this.props.dpoStreet,
      dpoNumber: this.props.dpoNumber,
      dpoComplement: this.props.dpoComplement,
      dpoCity: this.props.dpoCity,
      dpoNeighborhood: this.props.dpoNeighborhood,
      dpoState: this.props.dpoState,
      dpoCEP: this.props.dpoCEP,

    })
  }

  handleDpoCompanyCnpj(event) {
    this.setState({
      dpoCompanyCnpj: event.target.value
    }, () => this.handleRedux());
  }

  handledpoCompanyName(event) {
    this.setState({
      dpoCompanyName: event.target.value
    }, () => this.handleRedux());
  }

  handleDpoCnpj(event) {
    this.setState({
      dpoCnpj: event.target.value
    }, () => this.handleRedux());
  }

  handleDpoEmail(event) {
    this.setState({
      dpoEmail: event.target.value
    }, () => this.handleRedux());
  }

  handleName(event) {
    this.setState({
      name: event.target.value
    }, () => this.handleRedux());
  }

  handlePhone(event) {
    let phone = event.target.value;

    phone = phone.replace(/\D/g, '');
    phone = phone.replace(/(\d{2})(\d)/, '($1) $2');
    const isMobilePhone = /\(\d{2}\)\s\d{9}/.test(phone);

    if (isMobilePhone) {
      phone = phone.replace(/(\d{5})/, '$1-');
    }
    else {
      phone = phone.replace(/(\d{4})(\d)/, '$1-$2');
    }

    this.setState({
      phone
    }, () => this.handleRedux());
  }

  handleStreet(event) {
    this.setState({
      dpoStreet: event.target.value
    }, () => this.handleRedux());
  }

  handleNumber(event) {
    if (event.target.value && event.target.value !== "" && !Number(event.target.value)) {
      return;
    }

    this.setState({
      dpoNumber: event.target.value
    }, () => this.handleRedux());
  }

  handleComplement(event) {
    this.setState({
      dpoComplement: event.target.value
    }, () => this.handleRedux());
  }

  handleCity(event) {
    this.setState({
      dpoCity: event.target.value
    }, () => this.handleRedux());
  }

  handleNeighborhood(event) {
    this.setState({
      dpoNeighborhood: event.target.value
    }, () => this.handleRedux());
  }

  handleState(event) {
    this.setState({
      dpoState: event.target.value
    }, () => this.handleRedux());
  }

  handleCEP(event) {
    this.setState({
      dpoCEP: event.target.value
    }, () => this.handleRedux());
  }

  handleIsCompanyEmployee(event) {
    this.setState({
      isCompanyEmployee: !this.state.isCompanyEmployee
    }, () => this.handleRedux());
  }

  handleRedux() {
    var object = new DpoObject();

    object.name = this.state.name;
    object.dpoCnpj = this.state.dpoCnpj;
    object.dpoEmail = this.state.dpoEmail;
    object.phone = this.state.phone;

    object.isCompanyEmployee = this.state.isCompanyEmployee;
    object.dpoCompanyCnpj = this.state.dpoCompanyCnpj;
    object.dpoCompanyName = this.state.dpoCompanyName;
    object.dpoStreet = this.state.dpoStreet;
    object.dpoNumber = this.state.dpoNumber;
    object.dpoComplement = this.state.dpoComplement;
    object.dpoCity = this.state.dpoCity;
    object.dpoNeighborhood = this.state.dpoNeighborhood;
    object.dpoState = this.state.dpoState;
    object.dpoCEP = this.state.dpoCEP;


    this.props.addDpoInfo(object);
  }

  validateDocument(document) {
    return this.state.name !== "" && this.state.phone !== ""
  }

  renderDpoAddressFields() {
    if (!this.state.isCompanyEmployee)
      return (
        <React.Fragment>
          <Grid item style={{ width: "100%" }}>
            <TextField
              label="CNPJ da empresa do DPO"
              variant="outlined"
              style={{ width: "100%" }}
              color={"#203673"}
              value={this.state.dpoCompanyCnpj}
              onChange={(event) => this.handleDpoCompanyCnpj(event)}
            // InputProps={{
            //   inputComponent: TextMaskCustomCEP
            // }}
            />
          </Grid>
          <Grid item style={{ width: "100%" }}>
            <TextField
              label="Razão Social do DPO"
              variant="outlined"
              style={{ width: "100%" }}
              color={"#203673"}
              value={this.state.dpoCompanyName}
              onChange={(event) => this.handledpoCompanyName(event)}
            // InputProps={{
            //   inputComponent: TextMaskCustomCEP
            // }}
            />
          </Grid>
          <Grid item style={{ width: "100%" }}>
            <TextField
              label="CEP"
              variant="outlined"
              style={{ width: "100%" }}
              color={"#203673"}
              value={this.state.dpoCEP}
              onChange={(event) => { this.handleCEP(event) }}
              InputProps={{
                inputComponent: TextMaskCustomCEP
              }}
            />
          </Grid>
          <Grid item style={{ width: "100%" }}>
            <TextField
              inputProps={{
                maxLength: 115,
              }}
              label="Logradouro"
              variant="outlined"
              style={{ width: "100%" }}
              color={"#203673"}
              value={this.state.dpoStreet}
              onChange={(event) => { this.handleStreet(event) }}
            />
          </Grid>
          <Grid item style={{ width: "100%" }}>
            <TextField
              inputProps={{
                maxLength: 115,
              }}
              label="Número"
              variant="outlined"
              style={{ width: "100%" }}
              color={"#203673"}
              value={this.state.dpoNumber}
              onChange={(event) => { this.handleNumber(event) }}
            />
          </Grid>
          <Grid item style={{ width: "100%" }}>
            <TextField
              inputProps={{
                maxLength: 115,
              }}
              label="Complemento"
              variant="outlined"
              style={{ width: "100%" }}
              color={"#203673"}
              value={this.state.dpoComplement}
              onChange={(event) => { this.handleComplement(event) }}
            />
          </Grid>
          <Grid item style={{ width: "100%" }}>
            <TextField
              inputProps={{
                maxLength: 115,
              }}
              label="Bairro"
              variant="outlined"
              style={{ width: "100%" }}
              color={"#203673"}
              value={this.state.dpoNeighborhood}
              onChange={(event) => { this.handleNeighborhood(event) }}
            />
          </Grid>
          <Grid item style={{ width: "100%" }}>
            <TextField
              inputProps={{
                maxLength: 115,
              }}
              label="Cidade"
              variant="outlined"
              style={{ width: "100%" }}
              color={"#203673"}
              value={this.state.dpoCity}
              onChange={(event) => { this.handleCity(event) }}
            />
          </Grid>
          <Grid item style={{ width: "100%" }}>
            <TextField
              inputProps={{
                maxLength: 115,
              }}
              label="Estado"
              variant="outlined"
              style={{ width: "100%" }}
              color={"#203673"}
              value={this.state.dpoState}
              onChange={(event) => { this.handleState(event) }}
            />
          </Grid>
        </React.Fragment>)
    else
      return null
  }

  render() {
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={4}
      >
        <Grid item style={{ width: "100%" }}>
          <TextField
            inputProps={{
              maxLength: 115,
            }}
            label="Nome do DPO"
            variant="outlined"
            style={{ width: "100%" }}
            color={"#203673"}
            value={this.state.name}
            onChange={(event) => { this.handleName(event) }}
          />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <TextField
            inputProps={{
              maxLength: 115,
            }}
            label="CNPJ do DPO"
            variant="outlined"
            style={{ width: "100%" }}
            color={"#203673"}
            value={this.state.dpoCnpj}
            onChange={(event) => this.handleDpoCnpj(event)}
          />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <TextField
            inputProps={{
              maxLength: 115,
            }}
            label="E-mail do DPO"
            variant="outlined"
            style={{ width: "100%" }}
            color={"#203673"}
            value={this.state.dpoEmail}
            onChange={(event) => this.handleDpoEmail(event)}
          />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <TextField
            inputProps={{
              maxLength: 15
            }}
            label="Telefone"
            variant="outlined"
            style={{ width: "100%" }}
            color={"#203673"}
            value={this.state.phone}
            onChange={(event) => { this.handlePhone(event) }}
          />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.isCompanyEmployee}
                onChange={(event) => { this.handleIsCompanyEmployee(event) }}
                color="primary"
              />
            }
            label="É funcionário da empresa"
          />
        </Grid>
        {this.renderDpoAddressFields()}
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    dpoName: state.companyReducer.dpoName,
    dpoEmail: state.companyReducer.dpoEmail,
    dpoCnpj: state.companyReducer.dpoCnpj,
    dpoPhone: state.companyReducer.dpoPhone,
    dpoIsCompanyEmployee: state.companyReducer.dpoIsCompanyEmployee,
    dpoCompanyCnpj: state.companyReducer.dpoCompanyCnpj,
    dpoCompanyName: state.companyReducer.dpoCompanyName,
    dpoStreet: state.companyReducer.dpoStreet,
    dpoNumber: state.companyReducer.dpoNumber,
    dpoComplement: state.companyReducer.dpoComplement,
    dpoCity: state.companyReducer.dpoCity,
    dpoNeighborhood: state.companyReducer.dpoNeighborhood,
    dpoState: state.companyReducer.dpoState,
    dpoCEP: state.companyReducer.dpoCEP,
  };
}

export default connect(mapStateToProps, { addDpoInfo })(CompanyRegistrationDPO);