import React, { Component } from 'react';
import myApp from '../../../../core/infrastructure/firebaseConfig';
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/database";

import { Grid, Button, Typography, Card, TextField } from '@material-ui/core';

import AddProcess from './addProcess';
import ProcessItem from './processItem';

import { connect } from 'react-redux';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { changePage } from '../../../presentation/redux/actions';


class ProcessList extends Component {
  constructor(props) {
    super(props);

    this.db = myApp.firestore();

    this.state = {
      processList: [],
      search:'',
      filterItem: []
    }
  }

  componentDidMount() {
    this.addProcessListener();
  }

  async getProcessList() {
    let database = myApp.firestore();
    
    try {
      let data = await database.collection('processes').where('fk_areas', '==', this.props.processArea).get();
      let processList = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));
   
     
      this.setState({
        processList: processList
      })

     
    }
    catch (error) {
      console.error(error);
    }
  }

  firebaseProcessListener (callback) {
    let database = myApp.firestore();
  
    try {
      database.collection('processes').where('fk_areas', '==', this.props.processArea).onSnapshot(
        function (data) {
          return callback(data);
        }
      );
    }
    catch (error) {
      console.error(error);
    }
  }

  addProcessListener() {
    let self = this;

    this.firebaseProcessListener(
      function (data) {
        let processList = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        processList.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }

          if (a.name < b.name) {
            return -1;
          }

          return 0;
        });

        self.setState({
          processList: processList
        });

      
        
        self.setState({
          filterItem: self.state.processList.filter((process) =>
          process.name.toLowerCase().includes(self.state.search)
          ),
        });
      }
    );
  }

  render() {
    return (
      <div>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={4}
          style={{ marginTop: '3px', marginBottom: '10px' }}
          component={Card}
        >
          <Grid item>
            <Button
              variant="contained"
              style={{ background: 'none' }}
              onClick={() => { this.props.changePage(5) }}
            >
              <ArrowBackIcon />
            </Button>
          </Grid>
          <Grid item>
            <Typography variant="h4" component="h4" style={{ fontWeight: "500" }}>
              Lista de processos da área {this.props.processAreaName}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          style={{ margin: "10px 0" }}
        >
          <TextField
            id="outlined-basic"
            label="Filtrar área"
            variant="outlined"
            value={this.state.search}
            onChange={(e) => {
              this.setState({ search: e.target.value.toLowerCase() });
              this.componentDidMount();
            }}
          />
        </Grid>
        <Grid
          container
          direction="row"
          spacing={4}
          style={{ height: "320px" }}
        >
          {this.state.filterItem.map((process) => (
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <ProcessItem  id={process.id} goal={process.name} />
            </Grid>
          ))}
         {this.state.search =='' &&  <Grid item xs={12} sm={6} md={4} lg={2}>
            <AddProcess />
          </Grid>}
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    processArea: state.processReducer.processArea,
    processAreaName: state.processReducer.processAreaName,
  };
}

export default connect(mapStateToProps, { changePage })(ProcessList);