import React, { Component } from 'react';
import myApp from '../../../../core/infrastructure/firebaseConfig';
import "firebase/compat/firestore";

import {
  Grid,
  Typography,
  Dialog,
  DialogContent,
  Button,
  IconButton,
} from "@material-ui/core";

import { Close } from "@material-ui/icons";

import { connect } from "react-redux";

import NewPlansActivitiesList from "./newPlansActivyList";
import theme from "../../../../core/theme.json";

class NewPlanActivy extends Component {
  constructor(props) {
    super(props);

    this.db = myApp.firestore();

    this.state = {
      dialog: false,

      name: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dialog !== this.props.dialog) {
      this.handleDialog();
    }
  }

  componentDidMount() {
    this.getPlanName();
  }

  handleDialog() {
    this.setState({
      dialog: !this.state.dialog,
    });
  }

  addActivities() {
    let database = myApp.firestore();
    let _companyId = localStorage.getItem("currentCompany");

    for (var i = 0; i < this.props.activities.length; i++) {
      for (var j = 0; j < this.props.activities[i].planActivities.length; j++) {
        if (
          this.props.activities[i].planActivities[j].isChecked &&
          !this.props.activities[i].planActivities[j].selected
        ) {
          try {
            database.collection("activities").add({
              name: this.props.activities[i].planActivities[j].name,
              owner: this.props.activities[i].planActivities[j].owner,
              date: this.props.activities[i].planActivities[j].date,
              description:
                this.props.activities[i].planActivities[j].description,
              file_uri: "",
              done: false,
              doneDate: null,
              fk_companies: _companyId,
              fk_plan_type:
                this.props.activities[i].planActivities[j].fkPlanType,
              fk_initial_activities:
                this.props.activities[i].planActivities[j].fkInitialActivities,
              companyActivity: true,
            });
          } catch (error) {
            console.error(error);
          }
        } else if (
          this.props.activities[i].planActivities[j].selected &&
          !this.props.activities[i].planActivities[j].done
        ) {
          try {
            database
              .collection("activities")
              .doc(this.props.activities[i].planActivities[j].id)
              .update({
                name: this.props.activities[i].planActivities[j].name,
                description:
                  this.props.activities[i].planActivities[j].description,
                owner: this.props.activities[i].planActivities[j].owner,
                date: this.props.activities[i].planActivities[j].date,
              });
          } catch (error) {
            console.error(error);
          }
        }
      }
    }
  }

  async getPlanName() {
    let database = myApp.firestore();

    try {
      let data = await database
        .collection("plan_type")
        .doc(this.props.plan)
        .get();
      let plan = data.data();

      this.setState({
        name: plan.name,
      });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <Dialog
        open={this.state.dialog}
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => {
          this.handleDialog();
        }}
      >
        <DialogContent>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item container direction="row">
              <Grid item container justify="flex-end">
                <IconButton onClick={() => this.handleDialog()}>
                  <Close />
                </IconButton>
              </Grid>
              <Grid item container justify="center">
                <Typography
                  variant="h5"
                  component="h5"
                  style={{
                    paddingTop: "8px",
                    marginTop: "16px",
                    color: "#6A6A6A",
                    textAlign: "center",
                  }}
                >
                  {this.state.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid item style={{ height: "20px" }} />
            <Grid item style={{ width: "100%" }} xs={12} sm={6} md={4} lg={2}>
              <div
                style={{
                  height: "8px",
                  width: "100%",
                  background: theme.secondary_color,
                  borderRadius: "4px",
                }}
              />
            </Grid>
            <Grid item style={{ height: "20px" }} />
            <Grid item style={{ width: "100%", padding: "36px" }}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-end"
                spacing={4}
              >
                <Grid item style={{ width: "100%" }}>
                  <NewPlansActivitiesList />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    style={{
                      background: this.state.disabled
                        ? "#91ff79"
                        : theme.primary_color,
                      color: theme.secondary_color,
                      fontSize: theme.font_button,
                      fontWeight: 300,
                    }}
                    onClick={() => {
                      this.addActivities();
                      this.handleDialog();
                    }}
                  >
                    Adicionar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    activities: state.areasReducer.activities,
    plan: state.homeReducer.plan,
  };
}

export default connect(mapStateToProps, {})(NewPlanActivy);
