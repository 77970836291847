import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh'
  },
}))

export default function Status404 () {
  const classes = useStyles()

  return (
    <Grid className={classes.root} container justifyContent="center" alignItems="center">
      <Grid item><Typography variant="h2">404</Typography> <p>Página não encontada</p></Grid>
    </Grid>
  )
}