


const initialState = {
    reportObj:{}
   };
   
   //definindo os tipos de cada função
   
   const SET_REPORT_OBJ = "SET_REPORT_OBJ";
   


//função recebe e altera o nome
export const setReportObj = (data) => ({
    type: SET_REPORT_OBJ,
    data,
  });
  

//criando o reducer para o combine
const ViewsPdfReportVideoReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_REPORT_OBJ:
        return { ...state, reportObj: action.data };
     
    }
    return state;
  };
  
  export default ViewsPdfReportVideoReducer;