import React, { Component } from "react";
import myApp from "../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import { Close } from "@material-ui/icons";
import {
  AppBar, Toolbar, IconButton, List, ListItem, ListItemText, CssBaseline, Avatar,
  CircularProgress, Dialog, DialogTitle, ListItemAvatar, Grid, Typography, Button
} from "@material-ui/core";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import GroupIcon from "@material-ui/icons/Group";
import SettingsIcon from '@material-ui/icons/Settings';
import { loginListener } from "../../../login/commands/loginListener";
import { logout } from "../../../login/commands/logout";
import { getUserInformation } from "../../../dashboard/command/getUserInformation";

import "./company.css";
import { checkUser } from "../../../login/commands/checkUser";
import { isDefined } from "../../../shared/commands/verifyDefinition"
import CompanyItem from "../../components/companyItem";
import AddCompany from "../../components/addCompany";

import dashboardLogo from "../../../res/images/dashboard_logo.png";
import Title from "../../../shared/components/title";

import routerHistory from "../../../core/infrastructure/routerHistory";

class Company extends Component {
  constructor(props) {
    super(props);

    this.db = myApp.firestore();

    this.state = {
      userInformation: null,
      // welcomeCard: null,
      mobileDrawer: false,
      menu: false,
      selectedComponent: 0,
      companyList: [],
      dataLoaded: false,
      components: {}
    }
  }

  componentDidMount() {
    loginListener();
    // this.state.welcomeCard = JSON.parse(localStorage.getItem("welcomeCard"));

    var self = this;

    checkUser(
      function () {
        self._getUserInformation();
        // self._getWelcomeCardVisibility();
      }
    )
  }
 
  


  // _getWelcomeCardVisibility() {
  //   let welcomecard = localStorage.getItem("welcomeCard");
  //   if (welcomecard === null || !isDefined(welcomecard)) {
  //     this.state.welcomeCard = true;
  //   } else {
  //     this.state.welcomeCard = JSON.parse(localStorage.setItem("welcomeCard", false));
  //   }
  // }

  // _setWelcomeCardVisibility() {
  //   this.setState({
  //     welcomeCard: localStorage.setItem("welcomeCard", false)
  //   });
  // }

  async _getUserInformation() {
    this.setState({
      userInformation: await getUserInformation()
    });
  }

  async addCompaniesListener() {
    try {
      let self = this;
      let database = myApp.firestore();
      let _companies = [];
      var user = this.state.userInformation;
      var companiesSnapshot = {};

      if (isDefined(user.fk_profile_permissions)) {
        var companyIdList = user.fk_profile_permissions.map(pp => pp.fk_company);
        var companiesSnapshot = await database.collection("companies").get();

        if (!companiesSnapshot.empty) {
          companiesSnapshot.forEach(doc => {
            if (companyIdList.includes(doc.id)) {
              let company = doc.data();
              company["id"] = doc.id;
              _companies.push(company);
            }
          });
        }
      }
      else if (isDefined(user.fk_company_group)) {
        var companiesData = [];
        companiesSnapshot = await database.collection("companies").where("fk_company_group", "==", user.fk_company_group).get();

        if (!companiesSnapshot.empty) {
          companiesData = companiesSnapshot.docs;
          _companies = companiesData.map(doc => ({ ...doc.data(), id: doc.id }));
        }
      }

      _companies.sort((a, b) => {
        if (a.nome > b.nome) {
          return 1;
        }

        if (a.nome < b.nome) {
          return -1;
        }

        return 0;
      });

      self.setState({
        companyList: _companies,
      });

    }
    catch (error) {
      console.error(error);
    }
  }

  handleDrawer() {
    this.setState({
      mobileDrawer: !this.state.mobileDrawer
    });
  }

  handleMenu() {
    this.setState({
      menu: !this.state.menu
    });
  }

  selectComponent(index) {
    this.setState({
      selectedComponent: index
    }, () => { this.handleDrawer() });
  }

  renderPageComponents() {
    var components = {
      addCompanyButton:
        this.state.userInformation && this.state.userInformation.isAdmin ?
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AddCompany />
          </Grid>
          :
          "",
      userControlListItem:
        this.state.userInformation && this.state.userInformation.isAdmin ?
          <ListItem
            button
            // component="a" 
            // href="/#/usercontrol"
            // target="_blank"
            onClick={() => routerHistory.push("/usercontrol")}
          >
            <ListItemAvatar>
              <Avatar>
                <GroupIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Controle de usuários" />
          </ListItem>
          :
          ""
    }

    this.setState({ components });
  }

  render() {
    if (this.state.userInformation !== null) {
      if (!this.state.dataLoaded) {
        this.setState({ dataLoaded: true });
        this.renderPageComponents();
      }

      this.addCompaniesListener();

      return (
        <div className="root">
          <CssBaseline />
          <AppBar position="absolute" className="appBar" style={{ background: "#111C39", paddingTop: "4px" }} >
            <Toolbar style={{ padding: "0px" }}>
             
             <div style={{paddingLeft:'24px'}}>

             
                {this.state.userInformation
                  ?
                  // <Avatar alt="Remy Sharp" src={this.state.userInformation.photoURL} />
                  <Avatar alt="Remy Sharp" src={localStorage.getItem("userPhoto")} />
                  :
                  <Avatar>
                    <CircularProgress className="avatarLoadingCircle" />
                  </Avatar>
                }
             </div>
              <div className="spacer" />
              
              <div style={window.screen.width <= 1115 ?{marginRight:'3%' }:{width:'12%'}}>
            <ListItem autoFocus button onClick={() => logout()}>
                  <ListItemText primary="Sair" />
                </ListItem>
              </div>
            </Toolbar>
          </AppBar>

          <Dialog
            onClose={() => { this.handleMenu() }}
            open={this.state.menu}
          >
            <DialogTitle>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
              >
                <Grid item>
                  <Typography>
                    Configurações
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => this.handleMenu()}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <List>
              {this.state.components.userControlListItem}
          
            </List>
          </Dialog>

          <div className="content">
            <Grid
              container
              direction="column"
            >
              {/* <Grid style={{ height: "32px" }} />
              <Grid item style={{ width: "100%" }}>
                {this.state.welcomeCard
                  ?
                  <Paper style={{ width: "100%", height: "80%", borderRadius: "8px", background: "linear-gradient(180deg, rgba(32,54,115,1) 0%, rgba(7,27,82,1) 100%)" }}>
                    <IconButton
                      aria-label="delete"
                      style={{ color: "white", edge: "end", position: "relative", top: "3%", left: "97%" }}
                      onClick={() => { this._setWelcomeCardVisibility(); }}
                    >
                      <ClearIcon />
                    </IconButton>
                    <Hidden mdDown>
                      <Grid
                        container
                        spacing={4}
                        style={{ padding: "32px" }}
                        justify="center"
                        alignItems="center"
                      >
                        <Grid item style={{ width: "50%" }}>
                          <Grid
                            container
                            direction="column"
                            spacing={1}
                          >
                            <Grid item>
                              <Typography style={{ color: "white", fontSize: "24px" }}>
                                {"Olá " + this.state.userInformation.displayName}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography style={{ color: "white", fontSize: "22px", fontWeight: "lighter" }}>
                                {"Saiba como registrar e controlar as pendências dos GAP`s identificados nos processos."}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <ReactPlayer
                                url="https://youtu.be/PliibIZzXII"
                                width="80%"
                                height="240px"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid style={{ flexGrow: 1 }} />
                        <Grid item>
                          <img src={welcomeDraw} style={{ maxHeight: "80%", maxWidth: "80%" }} />
                        </Grid>
                      </Grid>
                    </Hidden>
                    <Hidden lgUp>
                      <Grid
                        container
                        direction="column"
                        spacing={4}
                        style={{ padding: "32px" }}
                      >
                        <Grid item>
                          <Typography style={{ color: "white", fontSize: "32px" }}>
                            {"Olá " + this.state.userInformation.displayName}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography style={{ color: "white", fontSize: "28px" }}>
                            {"Saiba como registrar e controlar as pendências dos GAP`s identificados nos processos."}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <ReactPlayer
                            url="https://youtu.be/PliibIZzXII"
                            width="100%"
                          />
                        </Grid>
                      </Grid>
                    </Hidden>
                  </Paper>
                  :
                  <div></div>
                }
              </Grid> */}
              <Grid style={{ height: "32px" }} />
              <Grid>
                <Title title="Suas Empresas" />
              </Grid>
              <Grid style={{ height: "32px" }} />
              <Grid item>
                <Grid
                  container
                  spacing={4}
                >
                  {this.state.companyList.map((company) => (
                    company.active
                      ?
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <CompanyItem id={company.id} nickname={company.nickname} nome={company.nome} cnpj={company.cnpj} />
                      </Grid>
                      :
                      <div />
                  ))}
                  {this.state.components.addCompanyButton}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      );
    }
    else {
      return <div />
    }
  }
}

export default Company;