import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Zoom,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  ButtonBase,
  TextField,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import theme from "../../../core/theme.json";
import myApp from "../../../core/infrastructure/firebaseConfig";
import ExcelJS from "exceljs";
import { Pagination } from "@material-ui/lab";

import { NotLogs } from "./notLogs";

export const ViewLogs = () => {
  const [logsData, setLogsData] = useState([]);
  const [search, setSearch] = useState("");
  const [calendarDate, setCalendarDate] = useState("");
  const [notLogs, setNotLogs] = useState(false);
  const [initialDate, setInitialDate] = useState("");
  const [finalDate, setFinalDate] = useState("");
  const [activepage, setActivepage] = useState(1);

  useEffect(() => {
    getLogsData();
  }, []);

  const getLogsData = async () => {
    let get = await myApp
      .firestore()
      .collection("userLogs")
      .where("fk_company", "==", localStorage.getItem("currentCompany"))
      .get();

    const response = get.docs.map((item) => item.data());
    console.log("response", response);
    response.sort((a, b) => {
      return b.loggedAt - a.loggedAt;
    });
    setLogsData(response);
  };

  const NameFilter = (item) => {
    if (item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
      return item;
    }
  };

  const filterPageReport = (item, index) => {
    const max = activepage * 5;
    const min = max - 5;

    if (index >= min && index < max) {
      return item;
    }
  };

  // console.log( 'date', Intl.DateTimeFormat("pt-br").format(finalDate))
  const newLogs = logsData?.filter((item) => {
    if (initialDate == "") {
      return item;
    }
    console.log(new Date(item.loggedAt).getTime())
    if (
      item.loggedAt >=
      new Date(initialDate).getTime() &&
      item.loggedAt<=
        new Date(finalDate).getTime()
    ) {
      return item;
    } else {
      return console.log("nao return");
    }
  });

  console.log(newLogs);

  useEffect(() => {
    if (!newLogs.length) {
      setNotLogs(true);
    } else {
      setNotLogs(false);
    }
  }, [newLogs]);

  const exportListForExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Logs");
    sheet.properties.defaultRowHeight = 50;

    sheet.getRow(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "004883ff" },
    };

    sheet.getRow(1).font = {
      family: 4,
      size: 12,
      bold: true,
      color: { argb: "ffffffff" },
    };
    sheet.getRow(1).alignment = { vertical: "middle", horizontal: "center" };

    sheet.columns = [
      {
        header: "Nome",
        key: "Nome",
        width: 10,
      },
      {
        header: "Email",
        key: "Email",
        width: 30,
      },
      {
        header: "Data",
        key: "Data",
        width: 20,
      },
      {
        header: "Hora",
        key: "Hora",
        width: 20,
      },
    ];

    newLogs?.map((item) => {
      sheet.addRow({
        Nome: item.name,
        Email: item.email,
        Data: Intl.DateTimeFormat("pt-BR").format(item.loggedAt),
        Hora: item.timeLoggedAt,
      });
    });

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Logs.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const handlePaginationChange = (event, page) => {
    setActivepage(page);
  };

  return (
    <>
      <Card
        style={{
          marginTop: "20px",
          marginBottom: "20px",
          height: "50px",
          padding: 10,
        }}
      >
        <Typography variant="h5">Relatório de logs</Typography>
      </Card>

      <Grid
        style={{ marginTop: "3%", paddingLeft: "3%" }}
        container
        justifyContent="space-between"
      >
        <Button
          onClick={() => exportListForExcel()}
          variant="contained"
          style={{
            background: theme.primary_color,
            color: theme.secondary_color,
            fontSize: "15px",
          }}
        >
          Exportar
        </Button>
      </Grid>

      <Grid
        style={{ marginTop: "3%", paddingLeft: "3%" }}
        container
        justifyContent="space-between"
      >
        <Grid style={{ display: "flex" }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justifyContent="space-around">
              <div>
                <Typography>Data inicial</Typography>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  value={initialDate}
                  onChange={(e) => setInitialDate(e)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </div>
              <div>
                <Typography>Data final</Typography>
                <KeyboardDatePicker
                  style={{ marginLeft: "10px" }}
                  margin="normal"
                  format="dd/MM/yyyy"
                  value={finalDate}
                  onChange={(e) => setFinalDate(e)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </div>
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid>
          <TextField
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            variant="outlined"
            placeholder="Filtrar por nome"
          />
        </Grid>
      </Grid>
      {notLogs == false ? (
        <Grid>
          <TableContainer style={{ marginTop: "3%" }}>
            <Table stickyHeader size="medium">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ fontWeight: "Bold" }}>
                    Nome
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "Bold" }}
                  ></TableCell>
                  <TableCell align="center" style={{ fontWeight: "Bold" }}>
                    Email
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "Bold" }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "Bold" }}
                  ></TableCell>
                  <TableCell align="center" style={{ fontWeight: "Bold" }}>
                    Data de log
                  </TableCell>

                  <TableCell align="center" style={{ fontWeight: "Bold" }}>
                    Hora de log
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {newLogs?.filter(NameFilter).filter(filterPageReport).map((log, k) => (
                  <TableRow
                    key={k}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    style={
                      k % 2
                        ? { background: "white" }
                        : { background: "#e3e3e3" }
                    }
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{ fontWeight: "Bold" }}
                    >
                      {log.name}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{ fontWeight: "Bold" }}
                    ></TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{ fontWeight: "Bold" }}
                    >
                      {log.email}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{ fontWeight: "Bold" }}
                    ></TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{ fontWeight: "Bold" }}
                    ></TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{ fontWeight: "Bold" }}
                    >
                      {" "}
                      {Intl.DateTimeFormat("pt-br").format(log.loggedAt)}
                    </TableCell>

                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{ fontWeight: "Bold" }}
                    >
                      {log.timeLoggedAt ?? ""}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ) : (
        <NotLogs />
      )}

      {newLogs.length > 5 ? (
        <Pagination
          count={
            newLogs.length % 5 === 0
              ? newLogs.length / 5
              : Math.floor(newLogs.length / 5) + 1
          }
          style={{ color: theme.secondary_color }}
          page={activepage}
          onChange={(event, page) => {
            handlePaginationChange(event, page);
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};
