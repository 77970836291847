import React, { Component } from 'react';
import myApp from '../../../../core/infrastructure/firebaseConfig';
import "firebase/compat/firestore";


import { Paper, Grid, Typography, Button, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { connect } from 'react-redux';
import NewPlanActivy from './newPlanActivy';

import theme from '../../../../core/theme.json'

import { changePage } from '../../../presentation/redux/actions';


class PlansTitle extends Component {
  constructor(props) {
    super(props);

    this.db = myApp.firestore();

    this.state = {
      name: "",

      dialog: false,
    }
  }

  handleDialog() {
    this.setState({
      dialog: !this.state.dialog,
    })
  }

  componentDidMount() {
    this.getPlanName();
  }

  async getPlanName() {
    let database = myApp.firestore();

    try {
      let data = await database.collection('plan_type').doc(this.props.plan).get();
      let plan = data.data();

      this.setState({
        name: plan.name
      })
    }
    catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <div>
        <Paper className='paper'>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={4}
          >
            <Grid item>
              <Button
                variant="contained"
                style={{ background: 'none' }}
                onClick={() => { this.props.changePage(0) }}
              >
                <ArrowBackIcon />
              </Button>
            </Grid>
            <Grid item>
              <Typography variant="h4" component="h4" style={{ fontWeight: "500" }}>
                {this.state.name}
              </Typography>
            </Grid>
            <div style={{ flexGrow: 1 }} />
            <Grid item>
              <Button
                variant="contained"
                style={{ background: theme.primary_color, color: theme.secondary_color }}
                onClick={() => { this.handleDialog() }}
              >
                Adicionar Atividade
              </Button>
            </Grid>
          </Grid>
        </Paper>

        <NewPlanActivy dialog={this.state.dialog} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    plan: state.homeReducer.plan,
  };
}

export default connect(mapStateToProps, { changePage })(PlansTitle);
