import React, { Component } from "react";
import myApp from "../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/database";

import xlsx from "json-as-xlsx"
import { format } from "date-fns";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  FormControl,
  Button,
  TableContainer,
  Paper,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip
} from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Pagination from "@material-ui/lab/Pagination";
import PollIcon from '@material-ui/icons/Poll';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import { ptBR } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";

import theme from "../../../core/theme.json";


class PoliciesAndPlans extends Component {
  constructor(props) {
    super(props);

    this.state = {
      database: myApp.firestore(),
      fileContents: "",
      documents: [],
      newDocuments: [],
      name: "",
      dateAdded: "",
      progress: "",
      stateDialog: false,
      stateDialogDelete: false,
      stateDialogDois: false,
      stateDate: '',
      idArquivo: '',
      filterName: true,
      filterDate: true,
      activePage: 1,
      solicitations: [],
      fileId: '',
      filePathName: '',
      storage: '',
      storageRef:''
    };
    this.filterPage = this.filterPage.bind(this);
  }

  async componentDidMount() {
    await this.getDocuments();
  }

  addDocument(file) {
    console.log("adding document", file);
    let documents = this.state.documents;
    let document = file;

    if (this.state.fileSize > 15728640) {
      alert("Arquivo deve ser menor do que 15mb");
    } else {
      this.state.database
        .collection("policies_and_plans")
        .add(file)
        .then((docRef) => {
          console.log("Document written with ID: ", docRef.id);
          document.id = docRef.id;
          documents.push(document);
          this.setState({
            documents,
          });
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    }
  }

  async getDocuments() {
    let documentsRef = await this.state.database
      .collection("policies_and_plans")
      .where("in_company", "==", localStorage.getItem("currentCompany"))
      .get();
    let documents = [];

    for (let doc of documentsRef.docs) {
      let d = doc.data();
      d["id"] = doc.id;
      documents.push(d);
    }

    this.setState({
      documents,
      newDocuments: documents
    });
  }

  handleChangeFile = async (e) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      this.setState({
        fileContents: text,
      });

      this.addDocument({
        fileName: this.state.fileName,
        dateAdded: this.state.dateAdded,
        fileContents: btoa(
          unescape(encodeURIComponent(this.state.fileContents))
        ),
        extension: this.state.extension,
      });
    };
    reader.readAsText(e.target.files[0]);

    let dateAdded = format(new Date(), "dd/MM/yyyy HH:mm:ss");

    const fileName = e.target.files[0].name;
    const fileDotsArray = fileName.split(".");
    const extension = fileDotsArray.pop();

    let fileNameWithoutExtension = "";

    fileDotsArray.forEach((split, index) => {
      if (index === fileDotsArray.length - 1) {
        fileNameWithoutExtension += split;
      } else {
        fileNameWithoutExtension += `${split}.`;
      }
    });

    this.setState({
      fileName: fileNameWithoutExtension,
      extension,
      dateAdded,
      fileSize: e.target.files[0].size,
    });

    setTimeout(() => {
      console.log(this.state);
    }, 1000);
  };

  handleDownloadButtonClick(fileData) {
    let url = fileData.filePath;
    let name = fileData.fileName;
    fetch(url)
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        // the filename you want
        a.download = name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        this.insertDownloadFileLog(fileData)
      })
      .catch(() => alert("Download falhou!"));
  }

  openDeleteDialog(fileData){
    let fileId = fileData.id;
    let filePathName = fileData.filePathName;
    let storage = myApp.storage();
    let storageRef = storage.ref();

    this.setState({
      fileId,
      filePathName,
      storage,
      storageRef,
      stateDialogDelete: true
    });
  }

  async handleDeleteButtonClick(fileData) {
    /*let fileId = fileData.id;
    let filePathName = fileData.filePathName;
    let storage = myApp.storage();
    let storageRef = storage.ref();*/

    if (this.state.filePathName) {
      //excluindo arquivo
      let desertRef = this.state.storageRef.child(this.state.filePathName);
      await desertRef.delete().then(() => {
        myApp.firestore().collection("policies_and_plans").doc(this.state.fileId).delete()
        this.getDocuments();
        this.setState({stateDialogDelete: false})
        console.log('Deletado')
      });
    } else {
      console.log("Sem arquivo para excluir");
    }
  }

  handleInputFileClick = (e) => {
    this.inputElement.click();
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  test() {
    this.anchorElement.click();
  }

  //pegando arquivo selecionado
  handleSelected(e) {
    //pegando o arquivo
    let file = e.target.files[0];

    //pegando norme do arquivo
    let fileName = e.target.files[0].name;

    //separando nome do arquivo em nome e extensão
    let fileDotsArray = fileName.split(".");

    //.shift() para pegar o primeiro item do array criado pelo split, que é o nome do arquivo
    let firstName = fileDotsArray.shift();

    //.pop() para pegar o ultimo item do array criado pelo split, que é a extensão do arquivo
    let extension = fileDotsArray.pop();

    //pegando a data em mili segundos
    let dateAdded = e.target.files[0].lastModified;

    //criando chave de identificação unica para o arquivo, caso o usuario envie dois arquivos com mesmo nome
    let fileNameDb = myApp.database().ref().push().key + "-" + firstName;

    //caminho para o Storage
    let filePath =
      "policiesAndPlans/" +
      localStorage.getItem("currentCompany") +
      "/" +
      fileNameDb;

    let company = localStorage.getItem("currentCompany");

    //criando referencia de arquivo
    let storage = myApp.storage().ref(filePath);

    //enviando arquivo
    let upload = storage.put(file);

    this.trackUpload(upload).then(() => {
      storage.getDownloadURL().then((downloadUrl) => {
        myApp.firestore().collection("policies_and_plans").doc().set({
          dateAdded: dateAdded,
          extension: extension,
          fileName: firstName,
          filePathName: filePath,
          in_company: company,
          filePath: downloadUrl,
        });
        this.getDocuments();
      });
    });
  }

  //criando barra de upload
  trackUpload(upload) {
    return new Promise((resolve, reject) => {
      upload.on(
        "state_changed",
        (snapShot) => {
          console.log(
            (snapShot.bytesTransferred / snapShot.totalBytes) * 100 + "%"
          );
          this.setState({
            progress:
              (snapShot.bytesTransferred / snapShot.totalBytes) * 100,
          });
        },
        (error) => {
          console.log(error);
          reject(error);
        },
        () => {
          console.log("Arquivo enviado com sucesso");
          this.setState({ progress: "" });
          resolve();
        }
      );
    });
  }

  //formatando data para exibição
  handleFormatData(e) {
    let num = parseInt(e);

    return format(new Date(num), "dd/MM/yyyy"); //  "dd/MM/yyyy HH:mm:ss"
  }

  //abrindo dialog e definindo id do doc do arquivo
  handleSetDueDate(item) {
    this.setState({
      stateDialog: true,
      idArquivo: item.id,
      stateDate: item.dueDate ? item.dueDate : ''
    });
  }

  //definido a data por segundos
  handleSetDate(date) {
    let dataFormatada = date.split('/');
    let d = new Date(dataFormatada[2], (dataFormatada[1] - 1), dataFormatada[0]);
    let a = new Date(d.getTime());
    this.setState({
      stateDate: a.getTime()
    });
  }

  //confirmando se a data selecionada é maior que a data atual,
  //caso sim, define a data de vencimento do documento
  async handleDateVerification() {
    let dataAtual = new Date().getTime();
    let virificarData = dataAtual - this.state.stateDate;
    if (virificarData >= 86400) {
      this.setState({ stateDialogDois: true });
    } else {
      myApp.firestore()
        .collection("policies_and_plans")
        .doc(this.state.idArquivo)
        .update({
          dueDate: this.state.stateDate
        });

      this.setState({
        stateDate: '',
        stateDialog: false
      });
    }
    await this.getDocuments();
  }

  //filtrando lista pelo nome e data de vencimento
  handleFilterByNameAndDate(filter) {

    if (filter === 'name') {
      if (this.state.filterName) {
        this.setState({
          newDocuments: this.state.documents.sort((a, b) => a.fileName.toLowerCase() > b.fileName.toLowerCase() ? 1 : -1),
          filterName: false
        })
      } else {
        this.setState({
          newDocuments: this.state.documents.sort((a, b) => a.fileName.toLowerCase() < b.fileName.toLowerCase() ? 1 : -1),
          filterName: true
        })
      }
    } else if (filter === 'date') {
      if (this.state.filterDate) {
        this.setState({
          newDocuments: this.state.documents.sort((a, b) => parseInt(a.dueDate) - parseInt(b.dueDate)),
          filterDate: false
        })
      } else {
        this.setState({
          newDocuments: this.state.documents.sort((a, b) => parseInt(b.dueDate) - parseInt(a.dueDate)),
          filterDate: true
        })
      }
    }

  }

  //filtrando paginas
  filterPage(solicitation, index) {
    let max = this.state.activePage * 8;
    let min = max - 8;

    if (index >= min && index < max) {
      return solicitation;
    }
  }

  //mudando de pagina
  handleChangePage(event, page) {
    this.setState({
      activePage: page,
    });
  }

  //Captura o endereço ip do usuario
  async getIpClient() {
    try {
      const response = await fetch('https://api.ipify.org');
      const ipClient = await response.text()

      return ipClient
    } catch(e) {
      console.log(e)
    }
  }

  //Insere registro de log na colection policies_and_plans_download_log
  async insertDownloadFileLog(docfile) {
    const downloadFileLog = {
      dateAdded: docfile.dateAdded,
      extension: docfile.extension,
      fileName: docfile.fileName,
      filePath: docfile.filePath,
      fileId: docfile.id,
      in_company: docfile.in_company,
      user: myApp.auth().currentUser.email,
      userUid: myApp.auth().currentUser.uid,
      userIp: await this.getIpClient(),
      dateLog: new Date().getTime(),
    }
    
    myApp.firestore().collection("policies_and_plans_download_log")
      .add(downloadFileLog).then().catch(e => console.error(e))
  }

  //Busca registro na tabela de log policies_and_plans_download_log
  async getDownloadLog(doc) {
    let downloadLog = await this.state.database
      .collection("policies_and_plans_download_log")
      .where("fileId", "==", `${doc.id}`)
      .get();
    let logDownload = []

    for (let log of downloadLog.docs) {
      let d = log.data();
      d["id"] = log.id;
      logDownload.push(d);
    }

    return logDownload
  }

  // Gera arquivo CSV com registro de colection policies_and_plans_download_log
  async handleGenerateCsvLog(doc) {
    console.log('ok')
    try {
      const downloadLog = await this.getDownloadLog(doc)

      if (downloadLog.length <= 0) {
        return alert("Não há registro de download para este arquivo.")
      }

      const fileName = `Log-${downloadLog[0].fileName}`
      const formateData = downloadLog.map((log) => {
        return {
          ...log,
          dateAdded: new Date(log.dateAdded).toString(),
          dateLog: new Date(log.dateLog).toString()
        }
      })
      
      const columns = [
        { label: "id", value: "id" },
        { label: "fileId", value: "fileId" },
        { label: "fileName", value: "fileName" },
        { label: "filePath", value: "filePath" },
        { label: "extension", value: "extension" },
        { label: "dateAdded", value: "dateAdded" },
        { label: "userUid", value: "userUid" },
        { label: "in_company", value: "in_company" },
        { label: "user", value: "user" },
        { label: "userIp", value: "userIp" },
        { label: "dateLog", value: "dateLog" },   
      ];

      const xlsxJson = [
        {
          sheet: "Enquete" ,
          columns,
          content: formateData
        }
      ]
      
      let settings = {
        fileName: fileName,
        extraLength: 3,
        writeMode: "writeFile",
        writeOptions: {},
        RTL: false,
      }

      xlsx(xlsxJson, settings)
    } catch(e) { 
      console.error("Generating excel: ", e);
    }
  }


  render() {
    return (
      <>
        <Paper style={{ marginTop: 10 }}>
          <Grid>
            <FormControl
              variant="outlined"
              style={{ width: "30%", marginLeft: "1%" }}
            >
              <input
                type="file"
                style={{ display: "none" }}
                onChange={(e) => this.handleSelected(e)}
                ref={(input) => (this.inputElement = input)}
              />

              <Button
                style={{
                  marginTop: "15px",
                  backgroundColor: theme.secondary_color,
                  color: "white",
                  border: `1px solid ${theme.secondary_color}`,
                }}
                onClick={this.handleInputFileClick}
              >
                Selecionar Arquivo
              </Button>
            </FormControl>
            {this.state.progress != "" ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ width: "100%", height: "40px" }}
              >
                <CircularProgress
                  variant="determinate"
                  value={this.state.progress}
                />
              </Grid>
            ) : (
              <div></div>
            )}
          </Grid>

          <TableContainer style={{ marginTop: "10px" }}>
            <Table>
              <TableHead>
                <TableRow style={{}}>
                  <TableCell
                    align="center"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                    onClick={() => this.handleFilterByNameAndDate('name')}
                  >
                    nome {this.state.filterName ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  </TableCell>
                  <TableCell align="center">Data do arquivo</TableCell>
                  <TableCell align="center">Tipo de Extensão</TableCell>
                  <TableCell
                    align="center"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                    onClick={() => this.handleFilterByNameAndDate('date')}
                  >
                    Vencimento  {this.state.filterDate ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  </TableCell>
                  <TableCell align="center">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  this.state.newDocuments
                    .filter(this.filterPage)
                    .map((doc) => {
                      return (
                        <TableRow>
                          <TableCell align="center">{doc.fileName}</TableCell>
                          <TableCell align="center">
                            {this.handleFormatData(doc.dateAdded)}
                          </TableCell>
                          <TableCell align="center">{doc.extension}</TableCell>
                          <TableCell
                            style={{
                              backgroundColor: 'rgb( 230,230,230 )',
                              textAlign: 'center',
                              border: '1px solid #ccc'
                            }}
                            onClick={() => this.handleSetDueDate(doc)}
                          >
                            {
                              doc.dueDate ? this.handleFormatData(doc.dueDate) : ''
                            }
                          </TableCell>
                          <TableCell align="center">
                          <Tooltip title="Download">
                            <Button
                              onClick={(event) =>
                                this.handleDownloadButtonClick(doc)
                              }
                              style={{
                                backgroundColor: theme.primary_color,
                                color: theme.secondary_color,
                              }}
                            >
                              <GetAppIcon />
                            </Button>
                              </Tooltip>
                              <Tooltip title="Excluir">
                            <Button
                              onClick={(event) => this.openDeleteDialog(doc)}
                              style={{
                                backgroundColor: "#f00",
                                color: "#fff",
                                marginLeft: "15px",
                              }}
                            >
                              <DeleteIcon />
                            </Button>
                              </Tooltip>

                              <Tooltip title="Enquete" >
                            <Button
                              onClick={(e) => this.handleGenerateCsvLog(doc)}
                              style={{
                                color: "#fff",
                                marginLeft: "15px",
                                backgroundColor: "#808080"
                              }}
                            >
                              <PollIcon/>
                            </Button>
                              </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          {
            //  myAnswers.length % 5 === 0
            //  ? myAnswers.length / 5
            //  : Math.floor(myAnswers.length / 5) + 1

            <Grid item>
              {this.state.newDocuments.length >
                5 ? (
                <Pagination
                  count={
                    this.state.newDocuments
                      .length %
                      5 ===
                      0
                      ? this.state.newDocuments.length / 5
                      : Math.floor(
                        this.state.newDocuments.length / 5
                      ) + 1
                  }
                  style={{ color: theme.secondary_color }}
                  page={this.state.activePage}
                  onChange={(event, page) => {
                    this.handleChangePage(event, page);
                  }}
                />
              ) : (
                <></>
              )}
            </Grid>
          }
        </Paper>
        <Dialog
          onClose={() => {
            this.setState({ stateDialog: false });
          }}
          fullWidth
          open={this.state.stateDialog}
        >
          <DialogTitle>
            <Grid
              container
              direction="column"
              alignItems="center"
            >
              <Grid
                item
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item >
                  Selecione uma data para o vencimento
                </Grid>
              </Grid>
            </Grid>
          </DialogTitle>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={4}
          >
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ width: '90%' }}
            >
              <Grid item >
                <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    margin="normal"
                    value={this.state.stateDate}
                    invalidDateMessage="Data em formato inválido"
                    onChange={(e, a) => this.handleSetDate(a)}
                    autoOk
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item >

              </Grid>
            </Grid>
            <Grid item>

            </Grid>
            <Grid
              container
              item
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Button
                variant="contained"
                style={{
                  background: theme.warning_color,
                  color: "white",
                  height: "56px",
                  margin: '10px'
                }}
                onClick={() => {
                  this.setState({ stateDialog: false });
                }}
              >
                Sair
              </Button>
              <Button
                variant="contained"
                style={{
                  background: theme.primary_color,
                  color: "white",
                  height: "56px",
                  margin: '10px'
                }}
                onClick={() => {
                  this.handleDateVerification()
                }}
              >
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Dialog>

        <Dialog
          onClose={() => {
            this.setState({ stateDialogDois: false });
          }}
          fullWidth
          open={this.state.stateDialogDois}
        >
          <DialogTitle>
            <Grid
              container
              direction="column"
              alignItems="center"
            >
              <Grid
                item
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item >
                  A data de vencimento não pode anteceder a data atual
                </Grid>
              </Grid>
            </Grid>
          </DialogTitle>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={4}
          >
            <Grid
              container
              item
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Button
                variant="contained"
                style={{
                  background: theme.warning_color,
                  color: "white",
                  height: "56px",
                  margin: '10px'
                }}
                onClick={() => {
                  this.setState({ stateDialogDois: false });
                }}
              >
                Ok
              </Button>
            </Grid>
          </Grid>
        </Dialog>
        {/* Dialogo de exclusão politicas e planos */}
        <Dialog open={this.state.stateDialogDelete} onClose={ () => this.setState({stateDialogDelete: false}) }>
            <DialogTitle
              style={{
                color: theme.quaternary_color,
                backgroundColor: theme.secondary_color,
                textAlign: "center",
              }}
            >
              ATENÇÃO!
            </DialogTitle>
            <DialogContent>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item textAlign="center">
                  <DialogContentText>
                    VOCÊ TEM CERTEZA QUE DESEJA EXCLUIR ESSE ITEM <br />
                    ESSA AÇÃO É IRREVERSÍVEL!
                  </DialogContentText>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={ () => this.setState({stateDialogDelete: false}) } autoFocus>
                Cancelar
              </Button>
              <Button
                onClick={ () => this.handleDeleteButtonClick() }
                style={{
                  color: theme.secondary_color,
                  backgroundColor: theme.primary_color,
                }}
              >
                Deletar
              </Button>
            </DialogActions>
          </Dialog>
      </>
    );
  }
}

export default PoliciesAndPlans;
