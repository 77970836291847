import { SET_AREA, SET_PLAN } from './types';

export const setArea = data => ({
  type: SET_AREA,
  data
});

export const setPlan = data => ({
  type: SET_PLAN,
  data
});