import React, {Component} from "react";
import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import {Card, CircularProgress, Grid, Paper} from "@material-ui/core";
import {isDefined} from "../../../../shared/commands/verifyDefinition";
import theme from '../../../../core/theme.json';

class Discovery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      database: myApp.firestore(),
      discoveryUrl: "",
      companyData: {},
      loading: true,
    };
  }

  async componentDidMount() {
    await this.getCompanyData();
    await this.getDiscovery();
    this.setState({
      loading: false,
    });
  }

  async getCompanyData() {
    let companyData = {};
    try {
      let companyDoc = await this.state.database
        .collection("companies")
        .doc(localStorage.getItem("currentCompany"))
        .get();

      companyData = {...companyDoc.data(), id: companyDoc.id};
    } catch (err) {
      console.log(err);
    }

    this.setState({
      companyData,
    });
  }

  async getDiscovery() {
    let discoveryUrl = "";
    try {
      if (
        isDefined(this.state.companyData.epm_file_discovery_id) &&
        this.state.companyData.epm_file_discovery_id !== "" &&
        isDefined(this.state.companyData.epm_fb_id) &&
        this.state.companyData.epm_fb_id !== ""
      ) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          fb_id: this.state.companyData.epm_fb_id,
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
          mode: "cors",
        };

        const response = await fetch(
          "https://apps.beeprivacy.com.br/beeprivacy/Security/Login",
          requestOptions
        );

        const result = await response.json();

        console.log("Resultado: ", result)

        discoveryUrl =
          "https://apps.beeprivacy.com.br/beeprivacy/Dashboard/Showpopup?app_id=" +
          this.state.companyData.epm_file_discovery_id +
          "&auth_token=" +
          result.data.auth_ticket;
      } else {
        discoveryUrl = this.state.companyData.url_files;
      }
    } catch (err) {
      console.log(err);
    }

    this.setState({
      discoveryUrl,
    });
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{width: "100%", marginTop: 40}}
          >
            <Grid item>
              <CircularProgress size={50} color={theme.secondary_color}/>
            </Grid>
          </Grid>
        ) : (
          <Grid
            component={Paper}
            container
            alignItems="stretch"
            justifyContent="center"
            style={{marginTop: 10, height: "95%"}}
          >
            <Grid item md={12} style={{width: "100%"}}>
              <iframe
                src={this.state.discoveryUrl}
                frameBorder="0"
                width="100%"
                height="100%"
                allowTransparency
              ></iframe>
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}

export default Discovery;
