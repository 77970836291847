import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Typography,
  Grid,
  FormControl,
  Paper,
  Button,
  AppBar,
  Toolbar,
  ButtonBase,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Box,
} from "@material-ui/core";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import myApp from "../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import Pagination from "@material-ui/lab/Pagination";
import dashboardLogo from "../../../res/images/dashboard_logo.png";
import routerHistory from "../../../core/infrastructure/routerHistory";
import { formatDate, formatHour } from "../../../shared/commands/formatDate";

//import google chart
import Chart from "react-google-charts";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

import theme from "../../../core/theme.json";
import { isDefined } from "../../../shared/commands/verifyDefinition";
import { changePage } from "../../../dashboard/presentation/redux/actions";
import { setSolicitationId } from "../../redux/actions";

//imports table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ScrollWatcher from "../../../shared/components/scrollWatcher";

//const COLORS = ["#0088FE", "#FFBB28", "#FF8042", "#FF8122"];
//const RADIAN = Math.PI / 180;

/*const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};*/

class FollowUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      database: myApp.firestore(),
      solicitations: [],
      solicitationTypeState: [],
      activePage: 1,
      filterPersonType: "",
      filterSolicitationType: "",
      filterStatus: "",
      userInformation: JSON.parse(
        sessionStorage.getItem("@blue-legal/userInformation")
      ),
      loading: true,
      personTypeData: [],
      solicitationTypeData: [],
      statusData: [],
      filterDate: true,
      filterDueDate: null,
      auxFilterDate: [],


      typesOfGraphicRequests: [],
      typesGfGraphicRequesters: [],
      statusChart: [],
    };


    this.filterSolicitations = this.filterSolicitations.bind(this);
    this.filterSolicitationsPage = this.filterSolicitationsPage.bind(this);
    this.handleFilterByDateAsc = this.handleFilterByDateAsc.bind(this);
    this.handleFirstOrderDataAsc = this.handleFirstOrderDataAsc.bind(this);
    this.handleFilterByDateDesc = this.handleFilterByDateDesc.bind(this);
    this.handleGetSoliciationTypes = this.handleGetSoliciationTypes.bind(this);
  }

  componentDidMount() {
    this.getSolicitations();
    this.handleGetSoliciationTypes();
  }

  async getSolicitations() {
    try {
      let companyId = localStorage.getItem("currentCompany");
      let solicitationsRef = await this.state.database
        .collection("solicitations")
        .where("fk_company", "==", companyId)
        .get();
      let solicitations = [];

      for (let doc of solicitationsRef.docs) {
        let birthDate = new Date(doc.data()?.birthDate?.seconds * 1000);
        let createDate = new Date(doc.data().createDate.seconds * 1000);
        let limitDate = new Date(doc.data().limitDate.seconds * 1000);
        let conclusionDate =
          isDefined(doc.data().conclusionDate) &&
            doc.data().conclusionDate !== null
            ? new Date(doc.data().conclusionDate.seconds * 1000)
            : null;

        let solicitation = {
          id: doc.ref.id,
          protocol: doc.data().protocol,
          personTypeId: doc.data().fk_personType,
          solicitationTypeId: doc.data().fk_solicitationType,
          name: doc.data().name,
          lastName: doc.data().lastName,
          email: doc.data().email,
          document: doc.data().document,
          birthDate: formatDate(birthDate) + " " + formatHour(birthDate),
          status: doc.data().status,
          createDate: formatDate(createDate) + " " + formatHour(createDate),
          limitDate: formatDate(limitDate) + " " + formatHour(limitDate),
          conclusionDate:
            conclusionDate !== null
              ? formatDate(conclusionDate) + " " + formatHour(conclusionDate)
              : "",
        };

        solicitations.push(solicitation);
      }

      for (let sol of solicitations) {
        /*try {
          let personTypeData = await this.state.database
            .collection("personTypes")
            .doc(sol.personTypeId)
            .get();
          sol.personType = personTypeData.data().type;
          let solicitationTypeData = await this.state.database
            .collection("solicitationTypes")
            .doc(sol.solicitationTypeId)
            .get();
          sol.solicitationType = solicitationTypeData.data().type;
        } catch (err) {
          console.log(err);
        }*/

        let personTypeData = await this.state.database
          .collection("personTypes")
          .doc(sol.personTypeId)
          .get();
        //sol.personType = personTypeData.data().type;
        if (personTypeData.data() == undefined) {
          let personTypeDataH = await this.state.database
            .collection("holders")
            .doc(sol.personTypeId)
            .get();
          sol.personType = personTypeDataH.data().name
        } else {
          sol.personType = personTypeData.data().type
        }


        let solicitationTypeData = await this.state.database
          .collection("solicitationTypes")
          .doc(sol.solicitationTypeId)
          .get();
        sol.solicitationType = solicitationTypeData.data().type;

      }

      let personTypeData = [
        { name: "Colaborador", value: 0 },
        { name: "Consumidor", value: 0 },
        { name: "Fornecedores / Parceiros", value: 0 },
      ];

      let querySnapshotPersonTypeData = await this.state.database
        .collection("holders")
        .where("in_company", "==", localStorage.getItem("currentCompany"))
        .get();

      for (let doc of querySnapshotPersonTypeData.docs) {
        personTypeData.push({
          name: doc.data().name,
          value: 0
        });
      }
      let objIndexPersonTypeData = 0;
      console.log(personTypeData)

      /**
       * for (let i = 0; i < solicitations.length; i++) {
        if (solicitations[i].personType === "Colaborador") {
          objIndex = personTypeData.findIndex(
            (obj) => obj.name === "Colaborador"
          );
          personTypeData[objIndex].value++;
        }
       */

      for (let i = 0; i < solicitations.length; i++) {
        for (let doc of querySnapshotPersonTypeData.docs) {
          if (solicitations[i].personType === doc.data().name) {
            objIndexPersonTypeData = personTypeData.findIndex(
              (obj) => obj.name === doc.data().name
            );
            personTypeData[objIndexPersonTypeData].value++;
          }
        }
      }

      let solicitationTypeData = [
        { name: "Solicitação de correção", value: 0 },
        { name: "Solicitação de informações", value: 0 },
        { name: "Solicitação de revogação", value: 0 },
      ];

      let querySnapshot = await this.state.database
        .collection("solicitationTypes")
        .where("in_company", "==", localStorage.getItem('currentCompany'))
        .get();

      for (let doc of querySnapshot.docs) {
        solicitationTypeData.push({
          name: doc.data().type,
          value: 0
        });
      }
      let objIndex = 0;

      /*if (solicitations[i].solicitationType === "Solicitação de correção") {
        objIndex = solicitationTypeData.findIndex(
          (obj) => obj.name === "Solicitação de correção"
        );
        solicitationTypeData[objIndex].value++;
      }*/

      for (let i = 0; i < solicitations.length; i++) {
        for (let doc of querySnapshot.docs) {
          if (solicitations[i].solicitationType === doc.data().type) {
            objIndex = solicitationTypeData.findIndex(
              (obj) => obj.name === doc.data().type
            );
            solicitationTypeData[objIndex].value++;
          }
        }
      }

      let statusData = [
        { name: "Aberto", value: 0 },
        { name: "Em andamento", value: 0 },
        { name: "Concluído", value: 0 },
      ];



      for (let i = 0; i < solicitations.length; i++) {
        if (solicitations[i].personType === "Colaborador") {
          objIndex = personTypeData.findIndex(
            (obj) => obj.name === "Colaborador"
          );
          personTypeData[objIndex].value++;
        }

        if (solicitations[i].personType === "Consumidor") {
          objIndex = personTypeData.findIndex(
            (obj) => obj.name === "Consumidor"
          );
          personTypeData[objIndex].value++;
        }

        if (solicitations[i].personType === "Fornecedores / Parceiros") {
          objIndex = personTypeData.findIndex(
            (obj) => obj.name === "Fornecedores / Parceiros"
          );
          personTypeData[objIndex].value++;
        }

        if (solicitations[i].solicitationType === "Solicitação de correção") {
          objIndex = solicitationTypeData.findIndex(
            (obj) => obj.name === "Solicitação de correção"
          );
          solicitationTypeData[objIndex].value++;
        }

        if (
          solicitations[i].solicitationType === "Solicitação de informações"
        ) {
          objIndex = solicitationTypeData.findIndex(
            (obj) => obj.name === "Solicitação de informações"
          );
          solicitationTypeData[objIndex].value++;
        }

        if (solicitations[i].solicitationType === "Solicitação de revogação") {
          objIndex = solicitationTypeData.findIndex(
            (obj) => obj.name === "Solicitação de revogação"
          );
          solicitationTypeData[objIndex].value++;
        }

        if (solicitations[i].status === "Aberto") {
          objIndex = statusData.findIndex((obj) => obj.name === "Aberto");
          statusData[objIndex].value++;
        }

        if (solicitations[i].status === "Em andamento") {
          objIndex = statusData.findIndex((obj) => obj.name === "Em andamento");
          statusData[objIndex].value++;
        }

        if (solicitations[i].status === "Concluído") {
          objIndex = statusData.findIndex((obj) => obj.name === "Concluído");
          statusData[objIndex].value++;
        }
      }

      solicitations.sort(this.handleFirstOrderDataAsc);

      this.setState({
        //auxFilterDate: solicitations,
        // solicitations: solicitations.sort((a, b) => a.createDate.toLowerCase() < b.createDate.toLowerCase() ? 1 : -1),
        solicitations: solicitations,
        loading: false,
        personTypeData,
        solicitationTypeData,
        statusData,
      });

      let listOfRequestTypes = [
        ['Tipo', 'valor']
      ]

      let listOfApplicantTypes = [
        ['Tipo', 'valor']
      ]

      let statusChart = [
        ['Tipo', 'valor']
      ]

      for (let doc of this.state.solicitationTypeData) {
        listOfRequestTypes.push([doc.name, 0])
      }

      for (let i = 0; i < this.state.solicitations.length; i++) {
        if (listOfRequestTypes.some(a => a[0] === this.state.solicitations[i].solicitationType)) {
          for (let t = 0; t < listOfRequestTypes.length; t++) {
            if (listOfRequestTypes[t][0] === this.state.solicitations[i].solicitationType) {
              listOfRequestTypes[t][1] += 1;
            }
          }
        }
      }

      for (let doc of this.state.personTypeData) {
        listOfApplicantTypes.push([doc.name, 0])
      }

      for (let i = 0; i < this.state.solicitations.length; i++) {
        if (listOfApplicantTypes.some(a => a[0] === this.state.solicitations[i].personType)) {
          for (let t = 0; t < listOfApplicantTypes.length; t++) {
            if (listOfApplicantTypes[t][0] === this.state.solicitations[i].personType) {
              listOfApplicantTypes[t][1] += 1;
            }
          }
        }
      }

      for (let doc of this.state.statusData) {
        // statusChart.push([doc.name, 0])
        statusChart.push([doc.name, doc.value - 1])
      }

      for (let i = 0; i < this.state.statusData.length; i++) {
        if (statusChart.some(a => a[0] === this.state.statusData[i].name)) {
          for (let t = 0; t < statusChart.length; t++) {
            if (statusChart[t][0] === this.state.statusData[i].name) {
              statusChart[t][1] += 1;
            }
          }
        }
      }


      this.setState({
        typesOfGraphicRequests: listOfRequestTypes,
        typesGfGraphicRequesters: listOfApplicantTypes,
        statusChart
      })

    } catch (err) {
      console.log(err);
    }
  }

  handleFirstOrderDataAsc(a, b) {
    let getDataA = a.createDate.split(' ');
    let trataDataA = getDataA[0].split('/');
    let da = new Date(`${trataDataA[2]}/${trataDataA[1]}/${trataDataA[0]},${getDataA[1]}`);
    let secA = da.getTime();

    // console.log(new Date(`${trataDataA[2]}/${trataDataA[1]}/${trataDataA[0]},`))
    // console.log(getDataA[1])

    let getDataB = b.createDate.split(' ');
    let trataDataB = getDataB[0].split('/');
    let db = new Date(`${trataDataB[2]}/${trataDataB[1]}/${trataDataB[0]},${getDataB[1]}`);
    let secb = db.getTime();


    
    return secb - secA;
  }

  async handleGetSoliciationTypes() {
    let querySnapshot = await this.state.database
      .collection("solicitationTypes")
      .where("in_company", "==", localStorage.getItem('currentCompany'))
      .get();

    let listOfSolicitationsTypes = []

    for (let doc of querySnapshot.docs) {
      listOfSolicitationsTypes.push(doc.data());
    }

    this.setState({ solicitationTypeState: listOfSolicitationsTypes });
  }

  handlePaginationChange(event, page) {
    this.setState({
      activePage: page,
    });
  }

  filterSolicitations(solicitation) {
    if (
      this.state.filterPersonType !== "" &&
      this.state.filterSolicitationType !== "" &&
      this.state.filterStatus !== "" &&
      solicitation.personType === this.state.filterPersonType &&
      solicitation.solicitationType === this.state.filterSolicitationType &&
      solicitation.status === this.state.filterStatus
    ) {
      return solicitation;
    } else if (
      this.state.filterPersonType !== "" &&
      this.state.filterSolicitationType === "" &&
      this.state.filterStatus === "" &&
      solicitation.personType === this.state.filterPersonType &&
      solicitation.solicitationType !== "" &&
      solicitation.status !== ""
    ) {
      return solicitation;
    } else if (
      this.state.filterPersonType === "" &&
      this.state.filterSolicitationType !== "" &&
      this.state.filterStatus === "" &&
      solicitation.personType !== "" &&
      solicitation.solicitationType === this.state.filterSolicitationType &&
      solicitation.status !== ""
    ) {
      return solicitation;
    } else if (
      this.state.filterPersonType === "" &&
      this.state.filterSolicitationType === "" &&
      this.state.filterStatus !== "" &&
      solicitation.personType !== "" &&
      solicitation.solicitationType !== "" &&
      solicitation.status === this.state.filterStatus
    ) {
      return solicitation;
    } else if (
      this.state.filterPersonType !== "" &&
      this.state.filterSolicitationType !== "" &&
      this.state.filterStatus === "" &&
      solicitation.personType === this.state.filterPersonType &&
      solicitation.solicitationType === this.state.filterSolicitationType &&
      solicitation.status !== ""
    ) {
      return solicitation;
    } else if (
      this.state.filterPersonType === "" &&
      this.state.filterSolicitationType !== "" &&
      this.state.filterStatus !== "" &&
      solicitation.personType !== "" &&
      solicitation.solicitationType === this.state.filterSolicitationType &&
      solicitation.status === this.state.filterStatus
    ) {
      return solicitation;
    } else if (
      this.state.filterPersonType !== "" &&
      this.state.filterSolicitationType === "" &&
      this.state.filterStatus !== "" &&
      solicitation.personType === this.state.filterPersonType &&
      solicitation.solicitationType !== "" &&
      solicitation.status === this.state.filterStatus
    ) {
      return solicitation;
    } else if (
      this.state.filterPersonType === "" &&
      this.state.filterSolicitationType === "" &&
      this.state.filterStatus === "" &&
      solicitation.personType !== "" &&
      solicitation.solicitationType !== "" &&
      solicitation.status !== ""
    ) {
      return solicitation;
    }
  }
 

  handleFilterPersonTypeChange(event) {
    this.setState({
      filterPersonType: event.target.value,
    });
  }

  handleFilterSolicitationTypeChange(event) {
    this.setState({
      filterSolicitationType: event.target.value,
    });
  }

  handleFilterStatusChange(event) {
    this.setState({
      filterStatus: event.target.value,
    });
  }

  handleOrderDataAsc(a, b) {
    let getDataA = a.createDate.split(' ');
    let trataDataA = getDataA[0].split('/');
    let da = new Date(`${trataDataA[2]}/${trataDataA[1]}/${trataDataA[0]}`);
    let secA = da.getTime();

    let getDataB = b.createDate.split(' ');
    let trataDataB = getDataB[0].split('/');
    let db = new Date(`${trataDataB[2]}/${trataDataB[1]}/${trataDataB[0]}`);
    let secb = db.getTime();

    return secb - secA;
  }

  handleOrderDataDes(a, b) {
    let getDataA = a.createDate.split(' ');
    let trataDataA = getDataA[0].split('/');
    let da = new Date(`${trataDataA[2]}/${trataDataA[1]}/${trataDataA[0]}`);
    let secA = da.getTime();

    let getDataB = b.createDate.split(' ');
    let trataDataB = getDataB[0].split('/');
    let db = new Date(`${trataDataB[2]}/${trataDataB[1]}/${trataDataB[0]}`);
    let secb = db.getTime();

    return secA - secb;
  }

  _altFilDTSol(e) {
    let teste = this.state.solicitations;
    for (let i of teste) {
      console.log(i.conclusionDate)
    }
    if (e === true) {
      this.handleFilterByDateAsc();
    } else {
      this.handleFilterByDateDesc();
    }
  }

  handleFilterByDateAsc() {
    let aux = this.state.solicitations;
    aux.sort(this.handleOrderDataAsc);
    this.setState({
      solicitation: aux
    });
  }

  handleFilterByDateDesc() {
    let aux = this.state.solicitations;
    aux.sort(this.handleOrderDataDes);
    this.setState({
      solicitation: aux
    });
  }

  // handleFilterDueDate(event) {

  //   this.setState({
  //     filterDueDate: event.target.value
  //   })
  //   if (this.state.filterDueDate === true) {
  //     this.setState({
  //       solicitations: this.state.auxFilterDate.sort((a, b) => a.limitDate.toLowerCase() > b.limitDate.toLowerCase() ? 1 : -1)
  //     })
  //   } else {
  //     this.setState({
  //       solicitations: this.state.auxFilterDate.sort((a, b) => a.limitDate.toLowerCase() < b.limitDate.toLowerCase() ? 1 : -1)
  //     })
  //   }
  // }

  filterSolicitationsPage(solicitation, index) {
    let max = this.state.activePage * 5;
    let min = max - 5;

    if (index >= min && index < max) {
      return solicitation;
    }
  }

  handleNewSolicitation() {
    this.props.changePage(17);
  }

  handleEditSoliciation(id) {
    this.props.setSolicitationId(id);
    this.props.changePage(18);
  }

  renderLoading() {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{ paddingTop: "50px" }}
      >
        <CircularProgress size={60} color={theme.secondary_color} />
      </Grid>
    );
  }

  render() {
    const cardStyle = {
      padding: "15px",
      width: "100%",
    };

    const protocolTitle = {
      color: theme.secondary_color,
      fontSize: "20px",
    };

    return (
      <>
        {this.state.loading ? (
          this.renderLoading()
        ) : (
          <>
            <Grid
              component={Paper}
              container
              direction="row"
              alignItems="center"
              style={{
                padding: "10px",
                marginTop: 5,
              }}
              spacing={2}
            >
              <Grid
                item
                container
                component={Paper}
                direction="column"
                alignItems="center"
                style={{ width: "45%", margin: 10 }}
              >
                <Grid item>
                  <Typography variant="h6" component="h6" style={{ marginTop: 10 }}>Tipos de solicitantes ativos</Typography>
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <Chart
                    wrap
                    width={'100%'}
                    height={'200px'}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={this.state.typesGfGraphicRequesters}
                    options={{ title: 'Tipos de solicitantes' }}
                    rootProps={{ 'data-testid': '1' }}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                component={Paper}
                direction="column"
                alignItems="center"
                style={{ width: "45%", margin: 10 }}
              >
                <Grid item>
                  <Typography variant="h6" component="h6" style={{ marginTop: 10 }}>Tipos de solicitação ativas</Typography>
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <Chart
                    wrap
                    width={'100%'}
                    height={'200px'}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={this.state.typesOfGraphicRequests}
                    options={{ title: 'Tipos de solicitação' }}
                    rootProps={{ 'data-testid': '1' }}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                component={Paper}
                direction="column"
                alignItems="center"
                style={{ width: "45%", margin: 10 }}
              >
                <Grid item>
                  <Typography variant="h6" component="h6" style={{ marginTop: 10 }}>Status</Typography>
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <Chart
                    wrap
                    width={'100%'}
                    height={'200px'}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={this.state.statusChart}
                    options={{ title: 'status' }}
                    rootProps={{ 'data-testid': '1' }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              component={Paper}
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              style={{ padding: "10px", marginTop: 20 }}
            >
              <Grid
                item
                container
                alignItems="center"
                justifyContent="flex-start"
                xs={2}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    // routerHistory.push("/solicitation/new/");
                    this.handleNewSolicitation();
                  }}
                  style={{
                    color: theme.secondary_color,
                    fontSize: "15px",
                    backgroundColor: theme.primary_color,
                  }}
                >
                  Nova solicitação
                </Button>
              </Grid>

              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                md={2}
                sm={4}
              >
                <FormControl
                  size="small"
                  variant="outlined"
                  style={{ width: "90%" }}
                >
                  <InputLabel>Data de Solicitação</InputLabel>
                  <Select
                    label="Data de Solicitação"
                    onChange={(e) => {
                      this._altFilDTSol(e.target.value);
                    }}
                  >
                    <MenuItem value={true}>Mais recente</MenuItem>
                    <MenuItem value={false}>Menos recente</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                md={2}
                sm={4}
              >
                <FormControl
                  size="small"
                  variant="outlined"
                  style={{ width: "90%" }}
                >
                  <InputLabel>Tipo de Solicitante</InputLabel>
                  <Select
                    label="Tipo de Solicitante"
                    onChange={(event) => {
                      this.handleFilterPersonTypeChange(event);
                    }}
                  >
                    <MenuItem value={""}></MenuItem>
                    {this.state.personTypeData.map((personType) => (
                      <MenuItem value={personType.name}>
                        {personType.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                md={2}
                sm={4}
              >
                <FormControl
                  size="small"
                  variant="outlined"
                  style={{ width: "90%" }}
                >
                  <InputLabel>Tipo de Solicitação</InputLabel>
                  <Select
                    label="Tipo de Solicitação"
                    onChange={(event) => {
                      this.handleFilterSolicitationTypeChange(event);
                    }}
                  >
                    <MenuItem value={""}></MenuItem>
                    <MenuItem value={"Solicitação de correção"}>
                      Solicitação de correção
                    </MenuItem>
                    <MenuItem value={"Solicitação de informações"}>
                      Solicitação de informações
                    </MenuItem>
                    <MenuItem value={"Solicitação de revogação"}>
                      Solicitação de revogação
                    </MenuItem>
                    {this.state.solicitationTypeState.map((item, k) => (
                      <MenuItem key={k} value={item.type}>
                        {item.type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                md={2}
                sm={4}
              >
                <FormControl
                  size="small"
                  variant="outlined"
                  style={{ width: "90%" }}
                >
                  <InputLabel>Status</InputLabel>
                  <Select
                    label="Status"
                    onChange={(event) => {
                      this.handleFilterStatusChange(event);
                    }}
                  >
                    <MenuItem value={""}></MenuItem>
                    <MenuItem value={"Aberto"}>Aberto</MenuItem>
                    <MenuItem value={"Em andamento"}>Em andamento</MenuItem>
                    <MenuItem value={"Concluído"}>Concluído</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              spacing={1}
              style={{ marginTop: 5 }}
            >
              <ScrollWatcher populate={() =>{}}>
              <TableContainer style={{ maxHeight: '80vh' }}>
                <Table stickyHeader size="medium">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" style={{ fontWeight: 'Bold' }} >Protocolo</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'Bold' }}>Data de Solicitação</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'Bold' }}>Data Prevista</TableCell>
                      {/* <TableCell align="center" style={{ fontWeight: 'Bold' }}>Data de Conclusão</TableCell> */}
                      <TableCell align="center" style={{ fontWeight: 'Bold' }}>Tipo de Solicitante</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'Bold' }}>Tipo de Solicitação</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'Bold' }}>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      this.state.solicitations
                        .filter(this.filterSolicitations)
                        .filter(this.filterSolicitationsPage)
                        .map((solicitation, k) => (
                          <TableRow
                            key={k}
                            onClick={() => {
                              // routerHistory.push(
                              //   "/solicitation/edit/" + solicitation.id
                              // );
                              this.handleEditSoliciation(solicitation.id);
                            }}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            style={k % 2 ? { background: "white" } : { background: "#e3e3e3" }}
                          >
                            <TableCell component="th" scope="row" align="center" style={{ fontWeight: 'Bold' }}>
                              {solicitation.protocol}
                            </TableCell>
                            <TableCell component="th" scope="row" align="center" style={{ fontWeight: 'Bold' }}>
                              {solicitation.createDate}
                            </TableCell>
                            <TableCell component="th" scope="row" align="center" style={{ fontWeight: 'Bold' }}>
                              {solicitation.limitDate}
                            </TableCell>
                            {/* <TableCell component="th" scope="row" align="center" style={{ fontWeight: 'Bold' }}>
                              {solicitation.conclusionDate}
                            </TableCell> */}
                            <TableCell component="th" scope="row" align="center" style={{ fontWeight: 'Bold' }}>
                              {solicitation.personType}
                            </TableCell>
                            <TableCell component="th" scope="row" align="center" style={{ fontWeight: 'Bold' }}>
                              {solicitation.solicitationType}
                            </TableCell>
                            <TableCell component="th" scope="row" align="center" style={{ fontWeight: 'Bold' }}>
                              {solicitation.status}
                            </TableCell>
                          </TableRow>
                        ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              </ScrollWatcher>

            </Grid>
          </>
        )}
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ paddingTop: "20px" }}
        >
          {/* <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  routerHistory.push("/");
                }}
                style={{ color: "white", backgroundColor: theme.secondary_color }}
              >
                Voltar
              </Button>
            </Grid> */}
          <Grid style={{ flexGrow: 1 }} />
          <Grid item>
            {this.state.solicitations.filter(this.filterSolicitations).length >
              5 ? (
              <Pagination
                count={
                  this.state.solicitations.filter(this.filterSolicitations)
                    .length %
                    5 ===
                    0
                    ? this.state.solicitations.filter(this.filterSolicitations)
                      .length / 5
                    : Math.floor(
                      this.state.solicitations.filter(
                        this.filterSolicitations
                      ).length / 5
                    ) + 1
                }
                style={{ color: theme.secondary_color }}
                page={this.state.activePage}
                onChange={(event, page) => {
                  this.handlePaginationChange(event, page);
                }}
              />
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
        {/* </div> */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changePage: (page) => dispatch(changePage(page)),
  setSolicitationId: (solicitationId) => dispatch(setSolicitationId(solicitationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowUp);
