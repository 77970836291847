let highlightList = [
  {
    connectionId: "krn",
    fileId: "2922",
    filePath: "http://lgpd.beelegal.com.br/ftp/dalla/Teste_Discovery_OCR/Contrato_Social.pdf",
    fileName: "Contrato_Social.pdf",
    text: `BLUE TECNOLOGIA E PROJETOS LTDA
    CONTRATO SOCIAL
    Pelo presente instrumento, os abaixo assinados:
    A) - BLUE PROJECTS PARTICIPAÇÕES LTDA, Inscrita no CNPJ: 23.633.899/0001-85,
    registro na JUCESP sob nº 35.2.2952163-0, com sede a [Avenida Paulista, nº 2300] – Bela Vista
    CEP: 01310-300- na cidade e estado de [São Paulo], representado pelos sócios Sr° [ANDRÉ]
    [LOCOSELLI GUTIERRES], [brasileiro], [divorciado], nascido na cidade de [São Paulo]/SP [em 20 de Junho de 1.975],
    maior, empresário, portador da cédula de identidade n° [24.937.212-5 SSP]/SP e do
    CPF/MF n° [246.178.598-88], residente e domiciliado à [Rua José Bento, nº 187 – bairro: Cambuci] ·
    CEP: 01523-030, na cidade e estado de [São Paulo] e [RAPHAEL] CAOVILA DOMINGUES,
    [brasileiro], solteiro, nascido em 23 de Outubro de 1.982 na cidade de [São Paulo]/SP, maior, empresário,
    portador da cédula de identidade nº [MG-10.566.348] SSP/MG e CPF/MF nº [221.936.808-41], residente
    e domiciliado á [Avenida Robert Kennedy, nº 1.635] – Casa 107 - bairro: Planalto – CEP: 09895-005,
    na cidade de São Bernardo do Campo, no estado de [São Paulo];
    [LINCOLN] TADEU DO NASCIMENTO SILVA, [brasileiro], solteiro, nascido na cidade
    B)
    Batatais/SP, em [09 de Maio de 1.987], maior, empresário, portador da cédula de identidade n°
    [30.219.956 SSP/SP] e do CPF/MF n° [357.420.058-73], residente e domiciliado a [Rua Biobedas, nº 137]
    - Apartamento 62 – bairro: Parque Imperial – CEP: 04302-010, na cidade de São Paul0. (artigo 997, I
    , CC/2002) de comum acordo resolvem na melhor forma de direito, a constituição de uma sociedade
    empresária limitada, que reger-se-á sob as cláusulas e condições seguintes:
    Cláusula Primeira - A sociedade gira sob a denominação de: BLUE TECNOLOGIA E PROJETOS
    LTDA.
    Cláusula Segunda - A sociedade tem a sua sede à Avenida Macuco, nº 726 – Sala 1307 - Bairro:
    Moema - CEP: 04523-001, na cidade e estado de [São Paulo].
    Cláusula Terceira - O objetivo social é de : “Fábrica de Software", abrangendo.0 desenvolvimento
    de customizáveis na área web, mobile, client server e gerenciamento de projetos, que serão
    desenvolvidos após a demanda de cada cliente, e especificamente para o cliente, individualmente
    considerado (Custom software).
    16° TABELIÃO DE NOTAS
    R. AUGUSTA.1638 - CERQUEIRA CÉSAR
    FÁBIO TADEU BISOGNN - TABELIÃO
    ANLUCE CGELHG ZARA"IN TEIXEIRA . ESOREVENTE
    SPADO
    0 7 MAR, 2018
    UTENTICAÇÃO Autentico esta
    Capa reprogratica conforme o origina
    *4m apresentado, do que dou lé
    00/SOMENTE COM SELO DE AUTENT C!r -
    R$ 3 50
    „AUTE'TICAÇÃO
    1050 A O 013e4 30
    `
  },
  {
    connectionId: "krn",
    fileId: "3043",
    filePath: "http://lgpd.beelegal.com.br/ftp/comprovante_de_endereco743.pdf",
    fileName: "Comprovante de endereço.pdf",
    text: `Acesse sua conta e outros serviços:
    No App Minha Claro
    Na internet - minhaclaro.com.br
    [VITOR SALES CEZARETTO]
    Atendimento Claro 1052, ou *1052# pelo celular.
    Ouvidoria, ligue 08007010180
    Para fatura em braille, ligue 1052
    Para deficiente auditivo, ligue 08000362323
    [R FLORENCIO DA SILVA 580]
    VILA LIBANESA
    [03738 - 070 SAO PAULO SP]
    Veja aqui o que está sendo cobrado:
    R$
    1. Plano Contratado
    53,99
    2. Outros Lançamentos R$
    R$
    56,86
    110,85
    Total
    2ª Via de Fatura
    Período de Uso
    Vencimento
    de 14/05/2021 a 13/06/2021
    05/07/2021
    Valor
    pago na última conta: R$ 55,20
    1.Plano Contratado
    11 99430 4514
    Valor R$
    Oferta Conjunta Claro MIX
    Aplicativos Digitais
    Claro Controle 10GB (163)
    Desconto de relacionamento
    79,99
    -26,00
    Serviços Inclusos no seu Plano
    Ligações ilimitadas
    Pacote de Dados Controle 10GB
    Sub Total - Plano Contratado
    R$ 53,99
    3.Outros Lançamentos
    Débitos Anteriores - Ref 06/2021
    Juros e Multa
    55,22
    1,64
    Sub Total - Outros Lançamentos
    R$ 56,86
    Total a Pagar
    R$ 110,85
    Prezado Cliente,
    O total valor a ser pago refere-se à conta atual e eventuais saldos anteriores.
    Caso tenha efetuado este pagamento, utilize o boleto do Mês, na última página.
    GERTIFICADO
    SERVICO
    Res.
    Pague sua conta nos Bancos e Locais credenciados.Encargos por atraso serão cobrados na próxima conta. Contribuições para o FUST e FUNTTEL (1% e 0,5% do valor dos
    serviços) não repassados ao cliente. Central de Atendimento da Anatel: 1331 - Ao ligar, informe o n° da reclamação registrada na prestadora.
    ANATEL 410
    Autenticação Mecânica:
    Para uso do banco
    Claro-
    Sr. Caixa, receber pagamento em dinheiro.
    Pague sua conta nos bancos credenciados preferencialmente: Itaú, HSBC, Banco do Brasil, Santander e outros.
    Cliente
    Código Débito Automático
    Período de Uso
    Total
    Vencimento
    14/05/21 a 13/06/21
    R$ 110,85
    05/07/21
    VITOR SALES CEZARETTO
    113437705
    Claro SP DDD 11
    84870000001-Z I 10850162202-2_ |_ 10705113437-Z | 70501921122-3
    Autenticação Mecânica solicitamos não rasurar, dobrar ou perfurar
    esta parte da fatura, para não danificar o código de barras.
    Pág.
    1/
    4
    Regras de Suspensões
    (*) Em cumprimento ao artigo 43 §2° da lei n. 8078/1990 e ao artigo 51, § 1° e 3° da Resolução n. 632/2014 da ANATEL, a Claro informa:
    Art. 90 - Transcorrido 15 (quinze) dias da notificação de existência de débito vencido, o Consumidor poderá ter suspenso parcialmente o provimento do serviço.
    Art. 93 - Transcorrido 30 (trinta) dias da suspensão parcial, o Consumidor poderá ter suspenso total o provimento do serviço.
    Art. 97 - Transcorrido 30 (trinta) dias da suspensão Total do serviço, o Contrato de Prestação de Serviço pode ser rescindido.
    Parágrafo Único: Rescindindo o Contrato de Prestação de Serviço, poderá ocorrer a inclusão no Registro do débito nos sistemas de proteção ao crédito.
    Documento Financeiro N°
    075205340 /062021
    Descrição
    Valor ISS (R$)
    Valor Cobrado (R$)
    Aplicativos Digitais - Claro Banca Premium
    Desconto Aplicativos Digitais - Claro Banca Premium
    Aplicativos Digitais - Livros digitais Light - Skeelo
    Desconto Aplicativos Digitais - Livros digitais Light - Skeelo
    Juros e Multa
    13,00
    -4,47
    10,00
    -3,59
    1,64
    Valor Total dos Serviços
    R $ 0,00
    R$
    16,58
    Tributo Federal (PIS e COFINS) - Percentual aproximado = 3,65%
    Nota Fiscal de Serviços de Telecomunicações N° 075714158/062021
    [VITOR SALES CEZARETTO]
    Modelo: 22 Serie B47 Via Única
    Claro S/A
    [R FLORENCIO DA SILVA 580]
    Data de Emissão: 14/06/2021
    [Rua Henri Dunant, 780 Santo Amaro]
    [CEP 04709 - 110 - Sao Paulo – SP]
    [VILA LIBANESA]
    Período: 14/05/2021 à 13/06/2021
    03738 - 070 SAO PAULO SP
    CFOP: 5307
    CNPJ 40.432.544/0001-47
    Inscrição Estadual: 114814878119
    [CPF/CNPJ: 475.581.158-92]
    Atendimento Claro: 1052
    N° da Conta: 113437705
    Reservado ao Fisco:
    www.claro.com.br
    e4a2.09c6.cb49.caae.10bb.3a59.f9c2.65fb
    N° do Cliente: 107028813
    Tributo Estadual
    ICMS
    Base de Cálculo (R$): 39,05
    Aliquota (%): 25,00
    Valor (R$): 9,76
    Isento/Não Tributável (R$):
    Base de Cálculo (R$) ICMS
    Valor ICMS
    Isento/Não Tributável (R$)
    Valor (R$)
    Serviços
    Claro Controle 10GB
    Desconto Claro Controle 1OGB
    56,99
    -17,94
    56,99
    14,25
    -17,94
    -4,49
    Valor Total da Nota Fiscal
    39,05
    9,76
    39,05
    Contribuição para o Fust 1% e Funttel 0,5% sobre os valores dos serviços de telecomunicações - Não repassados ao cliente.
    Tributos Federais (PIS e COFINS) 3,65%
    Autorização para Débito em Conta
    Autorizo o Débito Automático dos valores devidos em razão de serviço móvel pessoal prestado pela Claro SP DDD 11 na conta corrente abaixo especificada. Comprometo-me
    a manter saldo suficiente e disponível para arcar com o débito destes valores na data do seu vencimento. O código de identificação para Débito Automático está impresso abaixo.
    Código Débito Automático: 113437705 Claro SP DDD 11
    Agência:
    Nome do Cliente:
    CPF/CNPJ:
    Banco:
    Data:
    Número da conta Corrente:
    Assinatura:
    84870000001-7 J
    10850162202-2 _ 10705113437-7 I _70501921122-3
    Pág.
    2/ 4
    Prezado Cliente,
    Boleto para pagamento da Conta deste mês. Este boleto não quita débitos de meses anteriores.
    Para pagamento de todos os seus débitos, utilize o boleto da primeira página.
    Número da Conta
    Período de Uso
    Vencimento
    Total a Pagar
    113437705
    de 14/05/2021 a 13/06/2021
    05/07/2021
    R$ 55,63
    Autenticação Mecânica:
    Para uso do banco
    Claró-
    Sr. Caixa, receber pagamento em dinheiro.
    Pague sua conta nos bancos credenciados preferencialmente: Itaú, HSBC, Banco do Brasil, Santander e outros.
    Total
    Ve
    05/07/21
    Cliente
    Código Débito Automático
    Período de Uso
    ento
    [VITOR SALES CEZARETTO]
    113437705
    14/05/21 a 13/06/21
    R$ 55,63
    Claro SP DDD 11
    84860000000-7 1 55630162202-4_ |_ 10705113437-Z I 70503811122-0
    Autenticação Mecânica solicitamos não rasurar, dobrar ou perfurar
    esta parte da fatura, para não danificar o código de barras.
    Pág.
    3/ 4
    Autorizo o Débito Automático dos valores devidos em razão de serviço móvel pessoal prestado pela Claro SP DDD 11 na conta corrente abaixo especificada. Comprometo-me a manter saldo suficiente e disponível para arcar com o débito destes valores na data do seu vencimento. O código de identificação para Débito Automático está impresso abaixo.
    Autorização para Débito em Conta
    Agência: Código Débito Automático: 113437705Claro SP DDD 11 EEEEEEEEEEEEEEEEEEEEE CPF/CNPJ: Nome do Cliente: EEEEEEEEEEEEEEEEEEEE EEEEEEEEEEEEEEEEEEEEE Data: Banco: EEEEEEEEEEEEEEEEEEEEEEEE EEEEEEEEEEEEEEEEEEEEE Assinatura: Número da conta Corrente: EEEEEEEEEEEEEEEE EEEEEEEEEEEEEEEEEEEEE 84870000001-7 | 10850162202-2 | 10705113437-7 | 70501921122-3
    Pág. 4 / 4
    Detalhamento de ligações e serviços do celular (11) 99430 4514
    Claro Controle
    Dur. Oper. LD Efetiva Duração (s)/ Vol.(MB) Débito/ Data Hora Origem-Destino/Crédito/serviço Número Tipo de Ligação Créd. (R$) Saldo(R$) 09/05 00:00:00 SP 87,750 Plano de Internet 0,00 0,00 09/05 00:00:00 SP 88 Plano de Internet 0,00 0,00 10/05 00:00:00 SP 301,370 Plano de Internet 0,00 0,00 12/05 00:00:00 SP 26,020 Plano de Internet 0,00 0,00 14/05 00:00:00 SP 17,170 Plano de Internet 0,00 0,00 14/05 00:00:00 SP 60,780 Plano de Internet 0,00 0,00 15/05 00:00:00 SP 12,560 Plano de Internet 0,00 0,00 19/05 00:00:00 SP 0,050 Plano de Internet 0,00 0,00 19/05 00:00:00 SP 6,180 Plano de Internet 0,00 0,00 20/05 00:00:00 SP 272,840 Plano de Internet 0,00 0,00 23/05 00:00:00 SP 64,020 Plano de Internet 0,00 0,00 26/05 00:00:00 SP 88,690 Plano de Internet 0,00 0,00 29/05 00:00:00 SP 32,930 Plano de Internet 0,00 0,00 30/05 00:00:00 SP 30,330 Plano de Internet 0,00 0,00 31/05 00:00:00 SP 10,920 Plano de Internet 0,00 0,00 31/05 17:05:41 MENSALIDADE CONTROLE/On Line (eletrônica) 0,00 0,00 03/06 00:00:00 SP 0,010 Plano de Internet 0,00 0,00 03/06 00:00:00 SP 1,340 Plano de Internet 0,00 0,00 04/06 00:00:00 SP 125,290 Plano de Internet 0,00 0,00 04/06 20:54:33 Sao Paulo - Capital (Área 11)/SP 1124515466 00:00:30 00:00:20 Local 0,00 1,04 06/06 00:00:00 SP 6,490 Plano de Internet 0,00 0,00 08/06 12:50:54 Sao Paulo - Capital (Área 11)/SP 1135149300 00:04:24 00:04:19 Local 0,00 1,04
    Pág.A 1 / 1
    `
  },
  {
    connectionId: "krn",
    fileId: "",
    filePath: "",
    fileName: "",
    text: ""
  },
  {
    connectionId: "krn",
    fileId: "",
    filePath: "",
    fileName: "",
    text: ""
  },
  {
    connectionId: "krn",
    fileId: "",
    filePath: "",
    fileName: "",
    text: ""
  },
  {
    connectionId: "krn",
    fileId: "",
    filePath: "",
    fileName: "",
    text: ""
  },
  {
    connectionId: "krn",
    fileId: "",
    filePath: "",
    fileName: "",
    text: ""
  },
  {
    connectionId: "krn",
    fileId: "",
    filePath: "",
    fileName: "",
    text: ""
  },
  {
    connectionId: "krn",
    fileId: "",
    filePath: "",
    fileName: "",
    text: ""
  },
  {
    connectionId: "krn",
    fileId: "",
    filePath: "",
    fileName: "",
    text: ""
  },
  {
    connectionId: "krn",
    fileId: "",
    filePath: "",
    fileName: "",
    text: ""
  },
  {
    connectionId: "krn",
    fileId: "",
    filePath: "",
    fileName: "",
    text: ""
  }
];

export default highlightList;