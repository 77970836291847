import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import firebase from 'firebase/compat/app';

const database = myApp.firestore();

export const saveQuestions = async (text, DPIA, ROPA, typeQuestion) => {
  try {
    const data = await database.collection("questions").add({
      text,
      DPIA,
      ROPA,
      typeQuestion,
      answers: [],
    });

    return data.id;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const saveAnswers = async (point, idQuestion, gapId, hasGap) => {
  const newAnswer = {
    fk_gaps: gapId,
    gap: hasGap,
    leadTo: "",
    text: point.cardProps.title
  }

  try {
    await database.collection("questions").doc(idQuestion).update({
      answers: firebase.firestore.FieldValue.arrayUnion(newAnswer)
    });
  } catch (error) {
    console.error(error);
  }
};

export const saveGap = async (name, actionPlan) => {
  var _db = await database.collection("gaps").add({
    name: name,
    actionPlan: actionPlan,
  });

  if (_db !== null) {
    return _db.id;
  }
  else {
    return "";
  }
};