import React, { Component } from 'react';
import myApp from '../../../../core/infrastructure/firebaseConfig';
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/database";
import firebase from 'firebase/compat/app';

import {
  Paper,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { format } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";

import PublishIcon from "@material-ui/icons/Publish";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CreateIcon from "@material-ui/icons/Create";
import GetAppIcon from '@material-ui/icons/GetApp';

import { connect } from "react-redux";

import { isUndefined } from "lodash";

import theme from "../../../../core/theme.json";
import { changePage } from '../../../presentation/redux/actions';
import { Tooltip } from '@mui/material';

class ActivePlanList extends Component {
  constructor(props) {
    super(props);

    this.db = myApp.firestore();

    this.state = {
      activy: [],
      dataBase: myApp.firestore(),
      dialog: false,
      open: false,
      openEdit: false,
      openAddReasonPending: false,
      openDilogFile: false,
      openDilogFileDownload: false,
      openDialodDeleteFile: false,
      stateId: "",
      stateIdFile: '',
      progressTesste: '',
      dadosItemState: [],
      stateType: "",
      stateGoal: "",
      stateTrainingArea: "",
      stateTrainingClass: "",
      statePhase: "",
      stateReasonPending: "",
      stateDate: null,
      disabledialog: true,
      updateName: "",
      updateDescription: "",
      updateDate: null,
      updateOwner: "",
      updateReasonPending: "",
    };

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    this.getItemList();
  }

  async getItemList() {
    let database = myApp.firestore();
    let _companyId = localStorage.getItem("currentCompany");

    let self = this;

    try {
      database
        .collection("activities")
        .where("fk_companies", "==", _companyId)
        .where("fk_plan_type", "==", this.props.plan)
        .onSnapshot(function (activyData) {
          let _activyData = activyData.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          self.setState({
            activy: _activyData,
          });
        });
    } catch (error) {
      console.error(error);
    }
  }

  //função para editar a tarefa
  handleEditTask() {
    this.state.dataBase
      .collection("activities")
      .doc(this.state.stateId)
      .update({
        name: this.state.updateName,
        description: this.state.updateDescription ,
        owner: this.state.updateOwner,
         reasonPending: this.state.updateReasonPending ?? "",
        date: this.state.updateDate
      });
    this.handleClose();
  }

  //função para excluir tarefa
  handleDeleteTask() {
    let db = myApp.firestore();
    db.collection("activities").doc(this.state.stateId).delete();
    this.handleClose();
  }

  //função para adcionar o motivo pendencia
  async handleAddReasonPending() {
    let dadosDb = [];
    let querySnapshot = await this.state.dataBase
      .collection("activities")
      .doc(this.state.stateId)
      .get();
    dadosDb = !querySnapshot.data().subtasks
      ? "Não existe"
      : querySnapshot.data().subtasks.length;
    console.log(dadosDb);

    if (dadosDb === "Não existe") {
      let teste2 = [
        {
          completed: false,
          date: this.state.stateDate,
          goal: this.state.stateGoal,
          id: 1,
          phase: this.state.statePhase,
          reasonPending: this.state.stateReasonPending,
          trainingArea: this.state.stateTrainingArea,
          trainingClass: this.state.stateTrainingClass,
          type: this.state.stateType,
        },
      ];

      this.state.dataBase
        .collection("activities")
        .doc(this.state.stateId)
        .update({
          subtasks: teste2,
        });
    } else {
      let teste2 = [
        ...querySnapshot.data().subtasks,
        {
          completed: false,
          date: this.state.stateDate,
          goal: this.state.stateGoal,
          id: 1,
          phase: this.state.statePhase,
          reasonPending: this.state.stateReasonPending,
          trainingArea: this.state.stateTrainingArea,
          trainingClass: this.state.stateTrainingClass,
          type: this.state.stateType,
        },
      ];

      this.state.dataBase
        .collection("activities")
        .doc(this.state.stateId)
        .update({
          subtasks: teste2,
        });
    }
    this.handleClose();
  }

  //abrir dialog edição
  async handleClickOpenEdit(id) {
    const dadosItem = [];

    let querySnapshot = await this.state.dataBase
      .collection("activities")
      .doc(id)
      .get();
    dadosItem.push(querySnapshot.data());

    for (let item of dadosItem) {
      let updateDate = null;
      if (item.date !== null) {
        if (isUndefined(item.date.seconds)) {
          updateDate = item.date
        } else {
          updateDate = new Date(item.date.seconds * 1000);
        }
      }
      this.setState({
        updateName: item.name,
        updateDescription: item.description,
        updateDate,
        updateOwner: item.owner,
        updateReasonPending: item.reasonPending,
      });
    }

    this.setState({
      openEdit: true,
      stateId: id,
      dadosItemState: dadosItem,
    });
  }

  //abrir dialog exclusão
  handleClickOpen(id) {
    this.setState({
      open: true,
      stateId: id,
    });
  }

  handleClickOpenAddReasonPending(id) {
    this.setState({
      openAddReasonPending: true,
      stateId: id,
    });
  }

  //fechar dialog
  handleClose() {
    this.setState({
      open: false,
      openEdit: false,
      openAddReasonPending: false,
      openDilogFile: false,
      openDialodDeleteFile: false,
    });
  }

  handleVerification(e) {
    this.setState({ stateReasonPending: e.target.value });
    if (
      this.state.stateType !== "" &&
      this.state.stateGoal !== "" &&
      this.state.stateTrainingArea !== "" &&
      this.state.stateTrainingClass !== "" &&
      this.state.statePhase !== "" &&
      this.state.stateDate !== null
    ) {
      this.setState({ disabledialog: false });
    } else {
      this.setState({ disabledialog: true });
    }
  }

  handleSendFile(e) {
    //pegando o arquivo
    let file = e.target.files[0];

    //pegando norme do arquivo
    let fileName = e.target.files[0].name;

    //separando nome do arquivo em nome e extensão 
    let fileDotsArray = fileName.split(".");

    //.shift() para pegar o primeiro item do array criado pelo split, que é o nome do arquivo
    let firstName = fileDotsArray.shift()

    //criando chave de identificação unica para o arquivo, caso o usuario envie dois arquivos com mesmo nome
    let fileNameDb = myApp.database().ref().push().key + '-' + firstName;

    //caminho para o Storage
    let filePath = 'activities/' + localStorage.getItem('currentCompany') + '/' + fileNameDb;

    //criando referencia de arquivo
    let storage = myApp.storage().ref(filePath)

    //enviando arquivo
    let upload = storage.put(file)

    this.trackUpload(upload).then(() => {
      storage.getDownloadURL().then((downloadUrl) => {
        myApp.firestore().collection("activities").doc(this.state.stateIdFile).update({
          fileName: fileName,
          filePathName: filePath,
          filePathDownload: downloadUrl
        });
        this.setState({ stateIdFile: '' })
      })
    })

  }

  //criando barra de upload
  trackUpload(upload) {
    return new Promise((resolve, reject) => {
      upload.on('state_changed',
        (snapShot) => {
          console.log(snapShot.bytesTransferred / snapShot.totalBytes * 100 + '%')
          this.setState({ progressTesste: snapShot.bytesTransferred / snapShot.totalBytes * 100 })
        }, (error) => {
          console.log(error)
          reject(error)
        }, () => {
          console.log('Arquivo enviado com sucesso');
          this.setState({ progressTesste: '' })
          resolve()
        }
      )
    })
  }

  handleInputFileClick = async (e) => {
    //criando referencias para o myApp/firestore
    let db = myApp.firestore();

    //buscando dados
    let querySnapshot = await db.collection('activities').doc(e).get();

    //recebendo o nome do arquivo
    let filePathName = querySnapshot.data().filePathName;

    //criando referencias para o myApp/storage
    let storage = myApp.storage();
    let storageRef = storage.ref();

    //verificando se o item ja possui arquivo anexado, se tiver antes de mandar um novo ele apaga o atual
    if (querySnapshot.data().filePathName) {
      let desertRef = storageRef.child(filePathName)
      desertRef.delete()
    } else {
      console.log('Sem download')
    }

    //selecionando arquivo
    this.inputElement.click();
    this.setState({ stateIdFile: e })
  };

  //função para fazer o downloasd
  handleDownloadFile(activy) {
    //abrindo dialog para avisar o usuario
    this.setState({ openDilogFileDownload: true })

    //processo de download
    let url = activy.filePathDownload;
    let name = activy.fileName;
    fetch(url)
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);

        this.setState({ openDilogFileDownload: false })
      })
      .catch(() => alert('Download falhou!'));
  }

  //abrindo dialog para confirmar a exclusão
  handleDeleteFileAlert(id) {
    console.log('Deletando arquivo do item: ', id)
    this.setState({ stateIdFile: id, openDialodDeleteFile: true })
  }

  //excluindo arquivo
  async handleDeleteFile() {
    let tarefa = this.state.stateIdFile

    //criando referencias para o myApp/firestore
    let db = myApp.firestore();

    //buscando dados
    let querySnapshot = await db.collection('activities').doc(tarefa).get();

    //recebendo o nome do arquivo
    let filePathName = querySnapshot.data().filePathName;

    //criando referencias para o myApp/storage
    let storage = myApp.storage();
    let storageRef = storage.ref();

    if (filePathName) {
      console.log('Excluido')
      //excluindo arquivo
      let desertRef = storageRef.child(filePathName)
      desertRef.delete().then(() => {
        myApp.firestore().collection("activities").doc(tarefa).update({
          fileName: '',
          filePathName: '',
          filePathDownload: ''
        })
        this.setState({ stateIdFile: '', openDialodDeleteFile: false })
      })
    }
    else {
      console.log('Sem arquivo para excluir')
    }
  }

  //avisando o usuario que o item selecionado não tem download
  openDialogNotFile() {
    this.setState({ openDilogFile: true })

  }


  toDateTime(date) {
    if (isUndefined(date.seconds)) {
      return format(date, "dd/MM/yyyy");
    } else {
      return format(new Date(date.seconds * 1000), "dd/MM/yyyy");
    }
  }

  handleTestFormatDate(a) {
    console.log(a)
    let date = a.split('/')
    let testeDate = date[2] + '/' + date[1] + '/' + date[0]
    let formatDate = Date.parse(testeDate)
    this.setState({ updateDate: formatDate })
  }

  renderCells(activy) {
    console.log(activy)
    if (activy.fk_plan_type === this.props.plan) {
      return (
        <TableRow key={activy.id}>
          <TableCell>{activy.name}</TableCell>
          <TableCell>
            {activy.date !== null ? this.toDateTime(activy.date) : ""}
          </TableCell>
          <TableCell>
            {activy.doneDate && activy.doneDate !== null
              ? this.toDateTime(activy.doneDate)
              : ""}
          </TableCell>
          <TableCell>
            {activy.reasonPending ? activy.reasonPending : ""}
          </TableCell>
          <TableCell>
            <Switch
              checked={activy.done}
              onChange={() => {
                this.setComplete(activy.id);
              }}
              color="primary"
            />
          </TableCell>
          <TableCell>{activy.owner}</TableCell>
          <input
            type="file"
            style={{ display: 'none' }}
            onChange={(e) => this.handleSendFile(e)}
            ref={(input) => (this.inputElement = input)}
          />
          <TableCell align="center" >
            {/* Botão para enviar arquivo */}
            <Tooltip title="upload de arquivo">
            <Button
            
              disabled={activy.fileName}
              onClick={() => this.handleInputFileClick(activy.id)}
            >
              <PublishIcon style={{ marginRight: '5px' }} />
            </Button>
               </Tooltip>

            {/* Botão para fazer download */}
            <Tooltip title="download de arquivo">
            <Button
              onClick={() => activy.filePathDownload ? this.handleDownloadFile(activy) : this.openDialogNotFile()}
              disabled={!activy.fileName}
            >
              <GetAppIcon style={{ marginRight: '5px', paddingTop: '3px' }} />
            </Button>
              </Tooltip>

            {/* Botão para excluir arquivo */}
            <Tooltip title="apagar arquivo">

            <Button
              onClick={() => this.handleDeleteFileAlert(activy.id)}
              disabled={!activy.fileName}
              >
              <DeleteIcon />
            </Button>
              </Tooltip>
          </TableCell>

          <TableCell align="center">
          <Tooltip title="editar atividade">
            <CreateIcon
              style={{ margin: "5px" }}
              onClick={() => this.handleClickOpenEdit(activy.id)}
              />
              </Tooltip>
              <Tooltip title="excluir atividade">

            <DeleteIcon
              style={{ margin: "5px" }}
              onClick={() => this.handleClickOpen(activy.id)}
              />
              </Tooltip>
              <Tooltip title="adicionar subtarefa">
            <AddCircleIcon
              style={{ margin: "5px" }}
              onClick={() => this.handleClickOpenAddReasonPending(activy.id)}
              />
              </Tooltip>
          </TableCell>
        </TableRow>
      );
    } else {
      return <div />;
    }
  }

  async setComplete(id) {
    let database = myApp.firestore();

    try {
      let data = await database.collection("activities").doc(id).get();
      let activity = data.data();

      activity.done = !activity.done;

      if (activity.done) {
        activity.doneDate = firebase.firestore.Timestamp.now();
      } else {
        activity.doneDate = null;
      }

      await database.collection("activities").doc(id).update(activity);
    } catch (error) {
      console.error(error);
    }
  }

  handleDialog() {
    this.setState({
      dialog: !this.state.dialog,
    });
  }

  render() {
    return (
      <Paper className="paper">
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="flex-start"
          spacing={2}
          style={{ width: "100%" }}
        >
          <Grid item style={{ width: "100%" }}>
            {
              this.state.progressTesste != '' ?
                <Grid
                  container
                  justifyContent='center'
                  alignItems='center'
                  style={{ width: '100%', height: '40px' }}
                >
                  <CircularProgress variant="determinate" value={this.state.progressTesste} />
                </Grid> : <div></div>
            }
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>TÓPICO</TableCell>
                    <TableCell>DATA PREVISTA</TableCell>
                    <TableCell>DATA DE CONCLUSÃO</TableCell>
                    <TableCell>MOTIVO PENDÊNCIA</TableCell>
                    <TableCell>CONCLUÍDO</TableCell>
                    <TableCell>RESPONSÁVEL</TableCell>
                    <TableCell>OPÇÕES DE ARQUIVO</TableCell>
                    <TableCell align="center">AÇÕES</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.activy.map((activy) => {
                    return this.renderCells(activy);
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {/* Dialogo de edição da atividade */}
          <Dialog
            maxWidth="sm"
            maxheight="sm"
            fullWidth
            open={this.state.openEdit}
            onClose={this.handleClose}
          >
            <DialogTitle
              style={{
                color: theme.quaternary_color,
                backgroundColor: theme.secondary_color,
                textAlign: "center",
              }}
            >
              Editar atividade
            </DialogTitle>
            <DialogContent>
              <Grid
                container
                direction="column"
                alignItems="center"
                spacing={3}
                style={{ margin: 0, width: "100%" }}
              >
                <Grid item style={{ width: "100%" }}>
                  <TextField
                    helperText="Nome do tópico"
                    variant="outlined"
                    style={{ width: "100%" }}
                    value={this.state.updateName}
                    onChange={(e) =>
                      this.setState({ updateName: e.target.value })
                    }
                  />
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <TextField
                    helperText="Descrição do tópico"
                    variant="outlined"
                    style={{ width: "100%" }}
                    value={this.state.updateDescription}
                    onChange={(e) =>
                      this.setState({ updateDescription: e.target.value })
                    }
                  />
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <TextField
                    multiline
                    rows={2}
                    helperText="Motivo pendência"
                    variant="outlined"
                    style={{ width: "100%" }}
                    value={this.state.updateReasonPending}
                    onChange={(e) =>
                      this.setState({ updateReasonPending: e.target.value })
                    }
                  />
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      style={{ width: "100%" }}
                      disableToolbar
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      margin="normal"
                      label="Data"
                      invalidDateMessage="Data em formato inválido"
                      onChange={(e, a) => this.handleTestFormatDate(a)}
                      value={
                        this.state.updateDate === null
                          ? new Date()
                          : new Date(this.state.updateDate).toUTCString()
                      }
                      autoOk
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <TextField
                    helperText="Responsável"
                    variant="outlined"
                    style={{ width: "100%" }}
                    value={this.state.updateOwner}
                    onChange={(e) =>
                      this.setState({ updateOwner: e.target.value })
                    }
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} autoFocus>
                Cancelar
              </Button>
              <Button
                onClick={() => this.handleEditTask()}
                style={{
                  color: theme.secondary_color,
                  backgroundColor: theme.primary_color,
                }}
              >
                Atualizar
              </Button>
            </DialogActions>
          </Dialog>

          {/* Dialogo de exclusão da atividade */}
          <Dialog open={this.state.open} onClose={this.handleClose}>
            <DialogTitle
              style={{
                color: theme.quaternary_color,
                backgroundColor: theme.secondary_color,
                textAlign: "center",
              }}
            >
              ATENÇÃO!
            </DialogTitle>
            <DialogContent>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item textAlign="center">
                  <DialogContentText>
                    VOCÊ TEM CERTEZA QUE DESEJA EXCLUIR ESSA ATIVIDADE? <br />
                    ESSA AÇÃO É IRREVERSÍVEL!
                  </DialogContentText>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} autoFocus>
                Cancelar
              </Button>
              <Button
                onClick={() => this.handleDeleteTask()}
                style={{
                  color: theme.secondary_color,
                  backgroundColor: theme.primary_color,
                }}
              >
                Deletar
              </Button>
            </DialogActions>
          </Dialog>

          {/* Dialogo para adicionar subtarefa */}
          <Dialog
            maxWidth="sm"
            maxheight="sm"
            fullWidth
            open={this.state.openAddReasonPending}
            onClose={this.handleClose}
          >
            <DialogTitle
              style={{
                color: theme.quaternary_color,
                backgroundColor: theme.secondary_color,
                textAlign: "center",
              }}
            >
              Adicionar subtarefa
            </DialogTitle>
            <DialogContent>
              <Grid
                container
                direction="column"
                alignItems="center"
                spacing={3}
                style={{ margin: 0, width: "100%" }}
              >
                <Grid item style={{ width: "100%" }}>
                  <TextField
                    label="Tipo"
                    variant="outlined"
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({ stateType: e.target.value })
                    }
                  />
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <TextField
                    label="Finalidade"
                    variant="outlined"
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({ stateGoal: e.target.value })
                    }
                  />
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <TextField
                    label="Área de treinamento"
                    variant="outlined"
                    onChange={(e) =>
                      this.setState({ stateTrainingArea: e.target.value })
                    }
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <TextField
                    label="Turma de treinamento"
                    onChange={(e) =>
                      this.setState({ stateTrainingClass: e.target.value })
                    }
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <TextField
                    label="Fase"
                    onChange={(e) =>
                      this.setState({ statePhase: e.target.value })
                    }
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      margin="normal"
                      label="Data"
                      style={{ width: "100%" }}
                      value={this.state.stateDate}
                      onChange={(e) => this.setState({ stateDate: e })}
                      invalidDateMessage="Data em formato inválido"
                      autoOk
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <TextField
                    label="Motivo pendência"
                    onChange={(e) => this.handleVerification(e)}
                    variant="outlined"
                    multiline
                    rows={3}
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} autoFocus>
                Cancelar
              </Button>
              <Button
                disabled={this.state.disabledialog}
                style={{
                  color: theme.secondary_color,
                  backgroundColor: this.state.disabledialog
                    ? theme.opacity
                    : theme.primary_color,
                }}
                onClick={() => this.handleAddReasonPending()}
              >
                Cadastrar tarefa
              </Button>
            </DialogActions>
          </Dialog>

          {/* Dialogo para informar que não tem arquivo para download */}
          <Dialog open={this.state.openDilogFile} onClose={this.handleClose}>
            <DialogTitle
              style={{
                color: theme.quaternary_color,
                backgroundColor: theme.secondary_color,
                textAlign: "center",
              }}
            >
              item sem arquivo anexado
            </DialogTitle>
            <DialogActions>
              <Button onClick={this.handleClose} autoFocus>
                Fechar
              </Button>
            </DialogActions>
          </Dialog>

          {/* Dialogo para informar que não tem arquivo para download */}
          <Dialog open={this.state.openDilogFileDownload} onClose={this.handleClose}>
            <DialogTitle
              style={{
                color: theme.quaternary_color,
                backgroundColor: theme.secondary_color,
                textAlign: "center",
              }}
            >
              Download iniciado
            </DialogTitle>
            <DialogActions>
              <Button onClick={this.handleClose} autoFocus>
                Fechar
              </Button>
            </DialogActions>
          </Dialog>

          {/* Dialogo de exclusão do anexo */}
          <Dialog open={this.state.openDialodDeleteFile} onClose={this.handleClose}>
            <DialogTitle
              style={{
                color: theme.quaternary_color,
                backgroundColor: theme.secondary_color,
                textAlign: "center",
              }}
            >
              ATENÇÃO!
            </DialogTitle>
            <DialogContent>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item textAlign="center">
                  <DialogContentText>
                    VOCÊ TEM CERTEZA QUE DESEJA EXCLUIR ESSE ANEXO? <br />
                    ESSA AÇÃO É IRREVERSÍVEL!
                  </DialogContentText>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} autoFocus>
                Cancelar
              </Button>
              <Button
                onClick={() => this.handleDeleteFile()}
                style={{
                  color: theme.secondary_color,
                  backgroundColor: theme.primary_color,
                }}
              >
                Deletar
              </Button>
            </DialogActions>
          </Dialog>

        </Grid>
      </Paper>
    );
  }
}

function mapStateToProps(state) {
  return {
    plan: state.homeReducer.plan,
  };
}

export default connect(mapStateToProps, { changePage })(ActivePlanList);
