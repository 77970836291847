import {
  SET_SOLICITATION_ID,
} from "./types";

const INITIAL_STATE = {
  solicitationId: "",
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SOLICITATION_ID:
      return {
        ...state,
        solicitationId: action.data,
      }
    default:
      return state;
  }
}