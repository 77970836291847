export let defaultQuiz = [
  {
    "name": "1) Nome da empresa.",
    "alternatives": [
      {
        "gapTitle": "",
        "gapPlanoAcao": "",
        "gap": false,
        "name": "Inserir o nome abaixo no campo observação"
      }
    ],
    "gap": false
  },
  {
    "gap": false,
    "alternatives": [
      {
        "gap": false,
        "name": "Inserir o nome do fluxo no campo observação abaixo"
      }
    ],
    "name": "2) Nome da atividade: Fluxo de processamento de dados pessoais* (*Fluxo de processamento de dados é uma rotina que engloba uma série de atividades realizadas que envolvem informações e dados pessoais tanto de forma física como digital)."
  },
  {
    "alternatives": [
      {
        "gap": false,
        "name": "Inserir o nome da diretoria responsável no campo observação abaixo"
      }
    ],
    "name": "3) Diretoria.",
    "gap": false
  },
  {
    "name": "3.1) Área.",
    "gap": false,
    "alternatives": [
      {
        "gap": false,
        "name": "Inserir o nome da área no campo observação abaixo"
      }
    ]
  },
  {
    "name": "3.2) Profissional.",
    "alternatives": [
      {
        "gap": false,
        "name": "Inserir o nome do profissional responsável no campo observação abaixo"
      }
    ],
    "gap": false
  },
  {
    "gap": false,
    "alternatives": [
      {
        "gap": false,
        "name": "Inserir informações para contatar o profissional responsável no campo observação abaixo"
      }
    ],
    "name": "3.3) Contato."
  },
  {
    "gap": false,
    "name": "4) Categoria de titulares dos Dados Pessoais: Quem são as pessoas (titulares) envolvidas no fluxo?",
    "alternatives": [
      {
        "name": "Colaboradores",
        "gap": false
      },
      {
        "gap": false,
        "name": "Dependentes dos colaboradores (exceto crianças e adolescentes)"
      },
      {
        "name": "Clientes",
        "gap": false
      },
      {
        "gapTitle": "Possível violação ao Art. 100, inc. V do ECA ",
        "name": "Crianças e adolescentes",
        "gapPlanoAcao": "Necessário coletar o consentimento dos pais para o tratamento dos dados ",
        "gap": true
      },
      {
        "name": "Candidatos",
        "gap": false
      },
      {
        "name": "Estagiários",
        "gap": false
      },
      {
        "name": "Usuários cadastrados no site/app",
        "gap": false
      },
      {
        "name": "Representantes legais/colaboradores de clientes ",
        "gap": false
      },
      {
        "name": "Prestadores de serviço (pessoa física) ou representantes de prestador de serviço",
        "gap": false
      },
      {
        "gap": false,
        "name": "Representantes legais/colaboradores de fornecedores"
      },
      {
        "gap": false,
        "name": "Visitantes/convidados (eventos/reuniões)"
      },
      {
        "name": "Público geral",
        "gap": false
      },
      {
        "gap": false,
        "name": "Outras categorias"
      },
      {
        "name": "Menor aprendiz",
        "gapTitle": "Possível violação ao ArT. 100, inc. V do ECA ",
        "gapPlanoAcao": "Necessário coletar o consentimento dos pais para o tratamento dos dados ",
        "gap": true
      }
    ]
  },
  {
    "gap": false,
    "alternatives": [
      {
        "name": "Nome",
        "gap": true,
        "gapTitle": "Pode inferir transgeneridade",
        "gapPlanoAcao": "Deixar campo no documento para o caso da pessoa ter nome social."
      },
      {
        "gap": true,
        "name": "CPF",
        "gapTitle": "Possível violação ao Art. 2º, inc I e IV da LGPD ",
        "gapPlanoAcao": "Obter consentimento para o tratamento do dado "
      },
      {
        "name": "Número do RG",
        "gap": true,
        "gapTitle": "Possível violação ao Art. 2º, inc I e IV da LGPD ",
        "gapPlanoAcao": "Obter consentimento para o tratamento do dado "
      },
      {
        "gapTitle": "Possível violação ao Art. 2º, inc I e IV da LGPD ",
        "name": "Número da CTPS (Carteira de Trabalho e Previdência Social)",
        "gap": true,
        "gapPlanoAcao": "Obter consentimento para o tratamento do dado "
      },
      {
        "name": "Número da CNH",
        "gapPlanoAcao": "Obter consentimento para o tratamento do dado ",
        "gapTitle": "Possível violação ao Art. 2º, inc I e IV da LGPD ",
        "gap": true
      },
      {
        "gap": false,
        "name": "CRV  (Certificado de Registro de Veículo)/Placas"
      },
      {
        "gap": true,
        "gapPlanoAcao": "Obter consentimento para o tratamento do dado ",
        "gapTitle": "Possível violação ao Art. 2º, inc I e IV da LGPD ",
        "name": "Número de inscrição em órgão de classe"
      },
      {
        "gapPlanoAcao": "Obter consentimento para o tratamento do dado ",
        "name": "Endereço físico",
        "gap": true,
        "gapTitle": "Possível violação ao Art. 2º, inc I e IV da LGPD "
      },
      {
        "gapTitle": "Possível violação ao Art. 2º, inc I, III e IV da LGPD ",
        "gap": true,
        "gapPlanoAcao": "Obter consentimento para o tratamento do dado ",
        "name": "E-mail"
      },
      {
        "gapTitle": "Possível violação ao artigo 5o, inciso X da Constituição Federal",
        "gapPlanoAcao": "Obter consentimento ao obter o user",
        "name": "User da rede social",
        "gap": true
      },
      {
        "name": "Telefone",
        "gap": false
      },
      {
        "gapPlanoAcao": "Checar políticas de inclusão da empresa",
        "gap": true,
        "name": "Data de Nascimento",
        "gapTitle": "Pode inferir etarismo"
      },
      {
        "gap": true,
        "name": "IP",
        "gapTitle": "O vazamento deste dado pode resultar em um ataque hacker ",
        "gapPlanoAcao": "Proteger este dado em documento com senha"
      },
      {
        "gap": false,
        "name": "PIS"
      },
      {
        "gapPlanoAcao": "Proteger este dado em documento com senha",
        "name": "Dados bancários",
        "gapTitle": "O vazamento deste dado pode resultar em um ataque hacker/golpe ",
        "gap": true
      },
      {
        "gapTitle": "O vazamento deste dado pode resultar em um ataque hacker/ golpe ",
        "name": "Dados de cartão de crédito e débito",
        "gapPlanoAcao": "Proteger este dado em documento com senha",
        "gap": true
      },
      {
        "gapPlanoAcao": "Proteger este dado em documento com senha",
        "name": "Detalhes de aposentadoria e pensões",
        "gapTitle": "O vazamento deste dado pode resultar em um ataque hacker/ golpe ",
        "gap": true
      },
      {
        "gapPlanoAcao": "Mitigar ao máximo o acesso a essa informação",
        "gapTitle": "Possível violação ao artigo 5o, inciso X da Constituição Federal ",
        "name": "Detalhes de férias ",
        "gap": true
      },
      {
        "gapTitle": "Possível violação ao artigo 5o, inc. X e XV da Constituição Federal",
        "name": "Geolocalização em tempo real",
        "gap": true,
        "gapPlanoAcao": "A geolocalização deve se dar somente no horário de serviço do trabalhador."
      },
      {
        "gap": false,
        "name": "Histórico empregatício"
      },
      {
        "name": "Outros tipos de dados",
        "gap": false
      },
      {
        "gapTitle": "Define orientação sexual ",
        "gapPlanoAcao": "Obter consentimento e restringir o acesso.",
        "gap": true,
        "name": "Estado civil"
      }
    ],
    "name": "5) Dados Pessoais tratados: Quais são os dados pessoais* que você tem acesso e/ou utiliza? (*Dado pessoal: Qualquer informação relacionada a pessoa natural/ pessoa física identificada ou identificável)."
  },
  {
    "gap": false,
    "alternatives": [
      {
        "name": "Fotos",
        "gapPlanoAcao": "Obter consentimento, restringir o acesso as fotos e definir politica para uso de imagem ",
        "gap": true,
        "gapTitle": "Possível violação ao artigo 5o, inciso X da Constituição Federal"
      },
      {
        "gapPlanoAcao": "Obter consentimento, restringir o acesso aos dados biométricos e definir politica para uso de dados biométricos",
        "name": "Biometria",
        "gap": true,
        "gapTitle": "Possível violação ao artigo 5o, inciso X da Constituição Federal"
      },
      {
        "gapTitle": "Pode inferir racismo.",
        "name": "Origem racial ou étnica",
        "gap": true,
        "gapPlanoAcao": "Obter consentimento para o tratamento do dado e restringir o acesso ao dado."
      },
      {
        "gapTitle": "Pode inferir  preconceito religioso ",
        "name": "Religião",
        "gap": true,
        "gapPlanoAcao": "Obter consentimento para o tratamento do dado e restringir o acesso ao dado."
      },
      {
        "gapTitle": "Pode inferir distinção sexual",
        "gapPlanoAcao": "Obter consentimento para o tratamento do dado, criar politicas de inclusão  e restringir o acesso ao dado.",
        "gap": true,
        "name": "Sexo"
      },
      {
        "gapTitle": "Possível violação ao artigo 5o, inciso X da Constituição Federal",
        "name": "Filiação à sindicato/organização de caráter religioso/filosófico/político",
        "gap": true,
        "gapPlanoAcao": "Obter consentimento para o tratamento do dado e restringir o acesso."
      },
      {
        "gapPlanoAcao": "Obter consentimento e restringir o acesso ao dado.",
        "gap": true,
        "gapTitle": "Possível violação ao artigo 5o, inciso X da Constituição Federal",
        "name": "Saúde/genético"
      },
      {
        "gapTitle": "Possível violação ao artigo 5o, inciso X da Constituição Federal",
        "gapPlanoAcao": "Obter consentimento, restringir o acesso as fotos e definir politica para uso de imagem ",
        "name": "Imagens de câmeras de segurança",
        "gap": true
      },
      {
        "name": "Imagens de documentos digitalizados",
        "gapPlanoAcao": "Restringir acesso aos documentos.",
        "gapTitle": "Possível vazamento de dados pessoais",
        "gap": true
      },
      {
        "name": "Outros tipos de dados",
        "gap": false
      }
    ],
    "name": "6) Dados Pessoais Sensíveis tratados: Quais são os dados sensíveis* que você tem acesso e/ou utiliza? (*Dado pessoal sensível: origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural/ pessoa física)."
  },
  {
    "name": "7) Finalidade: Para que os dados são utilizados e/ou armazenados?",
    "alternatives": [
      {
        "gap": false,
        "name": "Para fins trabalhistas/relações de trabalho"
      },
      {
        "name": "Para fins tributários/fiscais",
        "gap": false
      },
      {
        "name": "Compliance",
        "gap": false
      },
      {
        "name": "Cumprimento de obrigação legal ou regulatória (descreva qual no campo livre) ",
        "gap": false
      },
      {
        "name": "Cumprimento de uma obrigação contratual (descreva qual no campo livre)",
        "gap": false
      },
      {
        "name": "Utilização em processos judiciais, administrativos e arbitrais",
        "gap": false
      },
      {
        "gap": false,
        "name": "Prospecção/vendas"
      },
      {
        "name": "Marketing/eventos",
        "gap": false
      },
      {
        "gap": false,
        "name": "Pesquisa de satisfação"
      },
      {
        "gap": false,
        "name": "Melhoria de produto/serviço"
      },
      {
        "gap": false,
        "name": "Estudo técnico (pappers)"
      },
      {
        "name": "Processo seletivo/contratação",
        "gap": false
      },
      {
        "gap": false,
        "name": "Proteção à saúde"
      },
      {
        "name": "Realização de pagamentos",
        "gap": false
      },
      {
        "name": "Segurança patrimonial",
        "gap": false
      },
      {
        "name": "Segurança física",
        "gap": false
      },
      {
        "gap": false,
        "name": "Outras finalidades"
      }
    ],
    "gap": false
  },
  {
    "gap": false,
    "name": "8) Necessidade: Todos esses dados listados são necessários para atingir a finalidade da atividade? Algum dado pessoal não é utilizado e pode ser excluído? Se sim, qual/quais?",
    "alternatives": [
      {
        "name": "Imprescindíveis",
        "gap": false
      },
      {
        "name": "Excessivos",
        "gapTitle": "A obtenção de dados não é necessária",
        "gap": true,
        "gapPlanoAcao": "Verificar se a obtenção dos dados é realmente necessária para o andamento do negócio."
      },
      {
        "gap": false,
        "gapPlanoAcao": "",
        "name": "Quais? Descreva abaixo",
        "gapTitle": ""
      }
    ]
  },
  {
    "name": "9) Fontes e origens dos dados: Como os dados chegam até você/sua equipe para esse fluxo? Selecione quantas opções forem necessárias. Se a fonte for um sistema, incluir o nome no campo livre.",
    "gap": false,
    "alternatives": [
      {
        "gapPlanoAcao": "Trafegar documentos pela a rede se possível \n",
        "gap": true,
        "name": "E-mail recebido/enviado",
        "gapTitle": "Possível ataque hacker "
      },
      {
        "name": "Ligação",
        "gap": true,
        "gapPlanoAcao": "Documentar por e-mail os dados informados.\n",
        "gapTitle": "Falta de documentação sobre a informação circulante "
      },
      {
        "name": "Correspondência física",
        "gap": true,
        "gapPlanoAcao": "Enviar documentos com AR\n",
        "gapTitle": "Desvio de documento "
      },
      {
        "gapTitle": "Acesso as pastas com dados sensiveis ",
        "gap": true,
        "gapPlanoAcao": "Restringir acesso as pastas com dados sensíveis \n",
        "name": "Sistemas internos (incluindo drives e rede compartilhada)"
      },
      {
        "gap": false,
        "name": "Sistemas de terceiros"
      },
      {
        "gap": true,
        "name": "Contratos",
        "gapPlanoAcao": "Exigir que o contrato tenha cláusula de LGPD \n",
        "gapTitle": "Contrato sem cláusula de LGPD "
      },
      {
        "name": "Documentos de clientes (que não sejam contratos)",
        "gap": false
      },
      {
        "gap": false,
        "name": "Documentos de parceiros (que não sejam contratos)"
      },
      {
        "gap": true,
        "name": "Aplicativos de comunicação",
        "gapTitle": "Perda do celular/ Roubo/Ataque hacker/ Phising",
        "gapPlanoAcao": "Trafegar documentos apenas por celulares corporativos \n"
      },
      {
        "gapPlanoAcao": "Fazer acesso apenas por drives corporativos\n",
        "gap": true,
        "name": "Redes sociais particulares",
        "gapTitle": "Ataque hacker/ sequestro de senha "
      },
      {
        "gapPlanoAcao": "Definir o usuário que irá gerenciar a rede.\n",
        "name": "Redes sociais corporativas",
        "gapTitle": "Ataque hacker/ sequestro de senha ",
        "gap": true
      },
      {
        "gapPlanoAcao": "Assim que mandar imprimir documento já retira-lo da impressora\n",
        "gapTitle": "Deixar documento com dados sensíveis no scanner",
        "name": "Scanner/digitalização",
        "gap": true
      },
      {
        "gap": true,
        "gapTitle": "Acesso à intimidade de funcionários",
        "gapPlanoAcao": "Restringir acesso ao monitoramento.\n",
        "name": "Monitoramento de atividade online"
      },
      {
        "gap": false,
        "name": "Birô de dados (Serasa, SPC, etc)"
      },
      {
        "name": "Fontes públicas (informar quais fontes públicas você recebe dados no campo livre)",
        "gapPlanoAcao": "",
        "gapTitle": "",
        "gap": false
      },
      {
        "gap": true,
        "name": "Câmera fotográfica",
        "gapPlanoAcao": "Obtenção de autorização do uso de imagem \n",
        "gapTitle": "Fotos sem autorização "
      },
      {
        "name": "Câmera de segurança",
        "gap": true,
        "gapPlanoAcao": "Restringir acesso ao monitoramento.\n",
        "gapTitle": "Coleta de imagem de funcionários indevidamente."
      },
      {
        "gap": false,
        "name": "Outras fontes de dados"
      }
    ]
  },
  {
    "name": "10) Local(is) de armazenamento dos dados: Onde os dados ficam armazenados? Os dados pessoais ficam no servidor ou no computador de cada usuário? Há dados pessoais em documentos impressos? Se sim, os dados físicos são armazenados em qual local (sala, armário, gaveta)? Quais são os sistemas utilizados no tratamento dos dados*? (*Tratamento: toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração).",
    "gap": false,
    "alternatives": [
      {
        "gapTitle": "Acesso indevido à informação",
        "gap": true,
        "gapPlanoAcao": "Não colocar dados sensíveis nesses suportes, ou necessário colocar restringir o acesso \n",
        "name": "Agendas/cadernos/post-its/rascunhos"
      },
      {
        "gap": true,
        "gapPlanoAcao": "Restringir o acesso com chave no armário dos arquivos físicos \n",
        "gapTitle": "Acesso indevido à informação",
        "name": "Arquivos físicos"
      },
      {
        "gapPlanoAcao": "Verificar se é de extrema necessidade o tráfego de informações por este meio.\n",
        "gap": true,
        "gapTitle": "Acesso indevido à informação",
        "name": "Aplicativos de terceiros"
      },
      {
        "gapTitle": "Acesso indevido à informação",
        "gap": true,
        "name": "Computador (C:)",
        "gapPlanoAcao": "Proteger os locais por controle de acesso.\n"
      },
      {
        "name": "HD externo/pen-drive",
        "gapPlanoAcao": "Restringir o uso de tais drives ou definir política para uso.\n",
        "gap": true,
        "gapTitle": "Acesso indevido à informação"
      },
      {
        "gapTitle": "Acesso indevido à informação",
        "name": "Nuvem de acesso particular",
        "gap": true,
        "gapPlanoAcao": "Proibir acesso a nuvem particular, somente a nuvem corporativa.\n"
      },
      {
        "gapPlanoAcao": "Restringir uso para pastas com informação sensível\n",
        "gap": true,
        "name": "Pastas e documentos na nuvem de acesso corporativo",
        "gapTitle": "Acesso indevido à informação"
      },
      {
        "gapPlanoAcao": "Instituir política para trafego de documentos corporativos por celular\n",
        "gap": true,
        "name": "Pastas e documentos no celular",
        "gapTitle": "Acesso indevido à informação"
      },
      {
        "gapTitle": "Acesso indevido à informação",
        "gap": true,
        "name": "Pastas e documentos no e-mail",
        "gapPlanoAcao": "Anonimizar o que for possível no tráfego de informações por e-mail\n"
      },
      {
        "gap": true,
        "gapPlanoAcao": "Limitar o acesso nas pastas da rede compartilhada\n",
        "name": "Pastas na rede compartilhada",
        "gapTitle": "Acesso indevido à informação"
      },
      {
        "gapTitle": "Acesso indevido à informação",
        "gapPlanoAcao": "Não armazenar informações em redes sociais particulares\n",
        "gap": true,
        "name": "Redes sociais particulares"
      },
      {
        "gap": true,
        "gapTitle": "Acesso indevido à informação",
        "name": "Redes sociais corporativas",
        "gapPlanoAcao": "Não armazenar informações em redes sociais corporativas\n"
      },
      {
        "gapPlanoAcao": "O software de gestão deve acompanhar os logs de acesso.\n",
        "name": "Software de gestão (preencha no campo livre o nome dos sistemas que você utiliza)",
        "gap": true,
        "gapTitle": "Acesso indevido à informação"
      },
      {
        "name": "Outras localizações (preencha no campo livre)",
        "gap": false
      }
    ]
  },
  {
    "gap": false,
    "alternatives": [
      {
        "gap": false,
        "name": "Sim"
      },
      {
        "gap": false,
        "name": "Não"
      }
    ],
    "name": "11) Transferência interna: Além de seu departamento, outros departamentos/pessoas/equipes dentro da sua empresa têm acesso a essa informação? Se sim, liste-os incluindo todos no próximo item."
  },
  {
    "name": "11.1) Selecione as áreas com as quais você troca informação pessoal/sensível:",
    "alternatives": [
      {
        "name": "Operações",
        "gap": false
      },
      {
        "name": "Qualidade",
        "gap": false
      },
      {
        "name": "Expansão",
        "gap": false
      },
      {
        "gap": false,
        "name": "Manutenção"
      },
      {
        "gap": false,
        "name": "RH"
      },
      {
        "name": "Marketing",
        "gap": false
      },
      {
        "name": "Engenharia",
        "gap": false
      },
      {
        "gap": false,
        "name": "Pesquisa e Desenvolvimento"
      },
      {
        "gap": false,
        "name": "Industrial"
      },
      {
        "name": "Logística",
        "gap": false
      },
      {
        "name": "Arquitetura",
        "gap": false
      },
      {
        "gap": false,
        "name": "Financeiro"
      },
      {
        "gap": false,
        "name": "TI"
      },
      {
        "name": "FP&A",
        "gap": false
      },
      {
        "name": "Compras",
        "gap": false
      },
      {
        "name": "Compliance",
        "gap": false
      },
      {
        "gap": false,
        "name": "Jurídico"
      },
      {
        "gap": false,
        "name": "RI"
      },
      {
        "name": "Contabilidade e Controladoria",
        "gap": false
      },
      {
        "gap": false,
        "name": "Presidência"
      },
      {
        "name": "Todas as áreas",
        "gap": false
      },
      {
        "gap": false,
        "name": "VP Operações"
      },
      {
        "name": "VP Adm. e Financeiro",
        "gap": false
      }
    ],
    "gap": false
  },
  {
    "name": "12) Transferência externa: Os dados pessoais/sensíveis são transferidos para outras empresas ou organizações externas? Se sim, preencha o item 12.1.",
    "gap": false,
    "alternatives": [
      {
        "gap": false,
        "name": "Sim"
      },
      {
        "name": "Não",
        "gap": false
      }
    ]
  },
  {
    "name": "12.1) Tipos de organizações externas: Fora da sua empresa, você envia os dados deste processo a órgãos da administração pública, prestadores de serviços, clientes, fornecedores etc. Em caso de empresa privada, clientes e prestadores de serviços, especifique no campo livre.",
    "gap": false,
    "alternatives": [
      {
        "gap": false,
        "name": "Receita Federal"
      },
      {
        "gap": false,
        "name": "Tribunais"
      },
      {
        "name": "Empresas privadas (especificar)",
        "gap": false
      },
      {
        "gapTitle": "Vulnerabilidade com os clientes",
        "gapPlanoAcao": "Verificar contratos com os fornecedores\n",
        "name": "Clientes (especificar)",
        "gap": true
      },
      {
        "name": "Redes Sociais",
        "gap": false
      },
      {
        "name": "INSS",
        "gap": false
      },
      {
        "gapTitle": "Vulnerabilidade com os fornecedores",
        "gap": true,
        "name": "Prestador de Serviço (especificar)",
        "gapPlanoAcao": "Verificar contratos com os fornecedores\n"
      },
      {
        "name": "Outros",
        "gap": false
      }
    ]
  },
  {
    "alternatives": [
      {
        "gap": false,
        "name": "Não "
      },
      {
        "name": "Não sei",
        "gap": false
      },
      {
        "gapTitle": "Vulnerabilidade relacionada a documentação do outro país",
        "gapPlanoAcao": "Verificar se existe regulamentação de proteção de dados \n",
        "name": "Se sim, para qual país?",
        "gap": true
      }
    ],
    "name": "13) Transferência internacional: Há transferência de dados pessoais para outros países ou organizações internacionais?",
    "gap": false
  },
  {
    "alternatives": [
      {
        "name": "Não há definição",
        "gapTitle": "Documentos ficarem guardados sem necessidade.",
        "gap": true,
        "gapPlanoAcao": "Definir tempo de guarda dos dados\n"
      },
      {
        "name": "Não sei",
        "gap": false
      },
      {
        "name": "0 – 1 ano",
        "gap": false
      },
      {
        "gap": false,
        "name": "1 – 2 anos"
      },
      {
        "gap": false,
        "name": "2 – 3 anos"
      },
      {
        "name": "3 – 4 anos",
        "gap": false
      },
      {
        "gap": false,
        "name": "Mais de 5 anos"
      },
      {
        "name": "Mais de 10 anos",
        "gap": false
      },
      {
        "gap": false,
        "name": "Mais de 20 anos"
      },
      {
        "gap": false,
        "name": "Tempo diferente das opções disponíveis (especificar quais no campo observação abaixo)"
      }
    ],
    "name": "14) Temporalidade: A empresa tem a definição do período pelo qual os dados podem ser mantidos?",
    "gap": false
  },
  {
    "gap": false,
    "alternatives": [
      {
        "gap": false,
        "name": "Pseudonimização"
      },
      {
        "gap": false,
        "name": "Anonimização "
      },
      {
        "gap": false,
        "name": "Armazenamento criptografado "
      },
      {
        "gap": false,
        "name": "Autenticação de multifatores (MFA) "
      },
      {
        "name": "Backup ou cópia de segurança ",
        "gap": false
      },
      {
        "gap": false,
        "name": "Armários chaveados"
      },
      {
        "gap": false,
        "name": "Cifração "
      },
      {
        "name": "Cofre de senhas (PAM)",
        "gap": false
      },
      {
        "gap": false,
        "name": "DAM"
      },
      {
        "name": "Dupla autenticação ",
        "gap": false
      },
      {
        "name": "Gestão de identidades (IGI)",
        "gap": false
      },
      {
        "name": "Gestão de senhas ",
        "gap": false
      },
      {
        "name": "Firewall ",
        "gap": false
      },
      {
        "name": "Senhas individuais",
        "gap": false
      },
      {
        "name": "VPN (rede privada virtual)",
        "gap": false
      },
      {
        "name": "Blockchain ",
        "gap": false
      },
      {
        "name": "Bloqueio de acesso ",
        "gapPlanoAcao": "Guardar a senha em local seguro \n",
        "gap": true,
        "gapTitle": "Senhas registradas no computador "
      },
      {
        "gap": true,
        "gapTitle": "Senhas registradas no computador ",
        "gapPlanoAcao": "Guardar a senha em local seguro \n",
        "name": "Controle de acesso"
      },
      {
        "gap": false,
        "name": "Barreiras físicas (descreva quais no campo livre) "
      },
      {
        "name": "Não há ",
        "gap": false
      },
      {
        "gap": false,
        "name": "Não sei "
      },
      {
        "gap": false,
        "name": "Outras medidas de segurança (cite-as no campo livre) "
      }
    ],
    "name": "15) Medidas de Segurança: Quais medidas de Segurança da Informação (administrativas e técnicas) são utilizadas?"
  },
  {
    "alternatives": [
      {
        "name": "Sim",
        "gap": false
      },
      {
        "gapTitle": "Falta de adequação a LGPD",
        "name": "Não",
        "gap": true,
        "gapPlanoAcao": " Incluir canal do titular\n"
      },
      {
        "name": "Em construção",
        "gap": false
      },
      {
        "gap": false,
        "name": "Não sei"
      },
      {
        "gap": false,
        "name": "Descreva no campo observação abaixo"
      }
    ],
    "gap": false,
    "name": "16) Direito dos Titulares: Caso o titular* queira exercer os seus direitos, a empresa tem condições de atendê-los rapidamente? (*Tratamento: toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração)."
  },
  {
    "alternatives": [
      {
        "gap": false,
        "name": "Não"
      },
      {
        "gap": false,
        "name": "Não sei"
      },
      {
        "gapPlanoAcao": "Informar na política ou declaração específica.\n",
        "gap": true,
        "gapTitle": "Tomada de decisão automática fora dos padrões da LGPD",
        "name": "Se sim, descreva a ferramenta no campo observação abaixo"
      }
    ],
    "gap": false,
    "name": "17) Tomada de decisão automatizada: Existem tomadas de decisões automatizadas*? (Decisões automatizadas são aquelas que definem o perfil pessoal, profissional, de consumo ou de crédito ou os aspectos de personalidade dos titulares, através do mecanismo de automatização (softwares, programas))."
  },
  {
    "name": "18) Observações para análise jurídica:",
    "alternatives": [
      {
        "gap": false,
        "name": "inserir informações no campo observação abaixo"
      },
      {
        "gap": false,
        "name": "Não há observações"
      },
      {
        "name": "Não sei",
        "gap": false
      }
    ],
    "gap": false
  },
  {
    "alternatives": [
      {
        "gap": false,
        "name": "Descrever os documentos no campo observação abaixo"
      },
      {
        "name": "Não há documentos a serem analisados",
        "gapPlanoAcao": "",
        "gap": false,
        "gapTitle": ""
      },
      {
        "name": "Não sei ",
        "gap": false
      }
    ],
    "name": "Documentos solicitados ao entrevistado ",
    "gap": false
  }
];