import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addQuestion, addAnswer } from "../redux/actions";
import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";

import {
  Drawer,
  Grid,
  Typography,
  IconButton,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  FormControl,
  Select,
  InputLabel,
  MenuItem
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { withStyles } from "@material-ui/core/styles";
import { deleteGap } from "../functions/deletePoint";
import { updateGap } from "../functions/updatePoints";
import { saveGap } from "../functions/savePoint";

import theme from "../../../../core/theme.json";

const styles = theme => ({
  drawer: {
    "& .MuiPaper-root": {
      display: "flex",
      width: "370px",
      padding: "20px",
    }
  },
  drawerHeader: {
    borderBottom: "1px solid #eee",
  },
  drawerBody: {
    marginTop: "30px",
  },
  drawerFooter: {

  },
  drawerButtonSave: {
    color: "white",
    padding: "10px",
  },
  drawerButtonCancel: {
    padding: "10px",
    color: "#707070",
    border: "1px solid #707070",
    marginTop: "10px",
  },
  drawerButtonDelete: {
    padding: "10px",
    border: "1px solid",
    marginTop: "10px",
  }
});

const typesQuestion = [
  { id: 1, value: "Armazenamento" },
  { id: 2, value: "Coleta" },
  { id: 3, value: "Compartilhamento" },
  { id: 4, value: "Dados Gerais" },
  { id: 5, value: "Eliminação" },
  { id: 6, value: "Tratamento" }
];

class DrawerQuiz extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userInformation: JSON.parse(sessionStorage.getItem("@blue-legal/userInformation")),
    };
  }

  async updateQuestionInDatabase() {
    const { point } = this.props;
    const database = myApp.firestore();
    try {
      await database.collection("questions").doc(point.cardProps.id).update({
        text: this.props.question,
        DPIA: this.props.DPIA,
        ROPA: this.props.ROPA,
        typeQuestion: this.props.typeQuestion
      });
    } catch (error) {
      console.error("Error update question!", error);
    }
  }

  async updateAnswerInDatabase() {
    const { point } = this.props;
    const database = myApp.firestore();

    try {
      const questionDoc = await database.collection("questions").doc(point.cardProps.idQuestion).get();
      const questionData = questionDoc.data();
      const { answers } = questionData;

      for (let answer of answers) {
        if (answer.text === this.props.answer) {
          answer.text = this.props.answer;
          answer.gap = this.props.gap;

          if (this.props.gap && answer.fk_gaps !== "") {
            const gap = {
              actionPlan: this.props.actionPlan,
              name: this.props.gapText
            }

            await updateGap(answer.fk_gaps, gap, this.props.quizId);
          } else if (this.props.gap && answer.fk_gaps === "") {
            const gapId = await saveGap(this.props.gapText, this.props.actionPlan);
            answer.fk_gaps = gapId;
          } else if (!this.props.gap && answer.fk_gaps !== "") {
            await deleteGap(answer.fk_gaps, this.props.quizId);
            answer.fk_gaps = "";
          }
        }
      }

      await database.collection("questions").doc(point.cardProps.idQuestion).update({
        answers: answers
      });
    } catch (error) {
      console.error("Error updating answer", error);
    }
  }

  async updateAction() {
    const { point, updatePointQuestion, updatePointAnswer } = this.props;

    if (point.cardProps.typeCard === "question") {
      await this.updateQuestionInDatabase();
      updatePointQuestion();
    } else {
      await this.updateAnswerInDatabase();
      updatePointAnswer();
    }
  }

  addAction() {
    const { saveQuestion, addQuestion, addAnswer, point } = this.props;

    if (point.cardProps.typeCard === "question") {
      if (this.props.answer.trim()) {
        addAnswer({
          value: this.props.answer,
          observation: this.props.observation,
          gap: this.props.gap,
          gapText: this.props.gapText,
          actionPlan: this.props.actionPlan,
          risk: 1,
          riskChance: 1,
          riskGap: 1,
          riskGapChance: 1,
          riskGapImpact: 1,
          riskImpact: 1
        });

        saveQuestion();
      } else {
        this.props.handleErrorAnswer();
      }
    } else {
      if (this.props.question.trim()) {
        var questionData = {
          question: this.props.question,
          DPIA: this.props.DPIA,
          ROPA: this.props.ROPA,
          typeQuestion: this.props.typeQuestion
        };

        addQuestion(questionData);
        saveQuestion();
      } else {
        this.props.handleErrorQuestion();
      }
    }
  }

  saveAction() {
    const { isOption } = this.props;

    if (isOption) {
      this.updateAction();
    } else {
      this.addAction();
    }
  }

  renderDrawerHeader() {
    const { classes, closeDrawer, point, isOption } = this.props;

    let headerName = "";

    if (isOption) {
      if (point.cardProps.typeCard !== "question") {
        headerName = "Resposta";
      }
      else {
        headerName = "Perguntas";
      }
    }
    else {
      if (point.cardProps.typeCard === "question") {
        headerName = "Resposta";
      }
      else {
        headerName = "Perguntas";
      }
    }

    return (
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        justify="space-between"
        className={classes.drawerHeader}
      >
        <Grid item>
          <Typography className={classes.titleDrawer} style={{ fontSize: theme.p }}>
            {headerName}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={() => closeDrawer()}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
    );
  }

  drawerBodyQuestion() {
    const { classes } = this.props;
    return (
      <Grid
        item
        container
        direction="column"
        className={classes.drawerBody}
      >
        <Grid item>
          <TextField
            label="Digite sua Pergunta"
            variant="outlined"
            fullWidth
            value={this.props.question}
            error={this.props.errorQuestion}
            helperText={this.props.errorQuestion && "Insira uma pergunta"}
            margin="normal"
            disabled={!this.state.userInformation.permissions.update}
            onChange={(event) => this.props.handleQuestion(event)}
          />
        </Grid>
        <Grid item>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Tipo de pergunta</InputLabel>
            <Select
              value={this.props.typeQuestion}
              onChange={(event) => this.props.handleTypeQuestion(event)}
              label="Tipo de pergunta"
              disabled={!this.state.userInformation.permissions.update}
            >
              <MenuItem value=""></MenuItem>
              {typesQuestion.map(type => (
                <MenuItem value={type.value}>{type.value}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          {/* <FormControlLabel
            control={
              <Switch
                checked={this.props.DPIA}
                onChange={(event) => { this.props.handleDPIA(event) }}
                color="primary"
              />
            }
            label="DPIA"
          /> */}
        </Grid>
        <Grid item >
          {/* <FormControlLabel
            control={
              <Switch
                checked={this.props.ROPA}
                onChange={(event) => { this.props.handleROPA(event) }}
                color="primary"
              />
            }
            label="ROPA"
          /> */}
          <Typography variant="subtitle2" component="div" style={{ marginTop: 10 }}>
            As informações constarão no relatório ROPA
          </Typography>
        </Grid>
      </Grid>
    );
  }

  drawerBodyAnswer() {
    const { classes } = this.props;

    return (
      <Grid
        item
        container
        direction="column"
        className={classes.drawerBody}
      >
        <Grid item>
          <TextField
            label="Digite uma Resposta"
            variant="outlined"
            fullWidth
            value={this.props.answer}
            error={this.props.errorAnswer}
            helperText={this.props.errorAnswer && "Insira uma resposta"}
            margin="normal"
            disabled={!this.state.userInformation.permissions.update}
            onChange={(event) => this.props.handleAnswer(event)}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Digite uma Observação (Opcional)"
            variant="outlined"
            multiline
            fullWidth
            value={this.props.observation}
            margin="normal"
            disabled={!this.state.userInformation.permissions.update}
            onChange={(event) => this.props.handleObservation(event)}
          />
          <Grid style={{ height: "16px" }} />
          <Grid item>
            <Grid container direction="row">
              <Grid item>
                <Typography>
                  Gap
                </Typography>
              </Grid>
              <Grid style={{ flexGrow: 1 }} />
              <Grid item>
                <Switch
                  checked={this.props.gap}
                  disabled={!this.state.userInformation.permissions.update}
                  onChange={(event) => { this.props.handleGap(event) }}
                  color="primary"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <TextField
              label="Gap"
              variant="outlined"
              fullWidth
              value={this.props.gapText}
              error={this.props.gapError}
              helperText={this.props.gapError && "Insira um Gap"}
              margin="normal"
              multiline
              rows={3}
              disabled={!this.props.gap || !this.state.userInformation.permissions.update}
              onChange={(event) => this.props.handleGapText(event)}
            />
          </Grid>
          <Grid>
            <TextField
              label="Plano de Ação"
              variant="outlined"
              fullWidth
              value={this.props.actionPlan}
              error={this.props.actionPlanError}
              helperText={this.props.actionPlanError && "Insira um Plano de Ação"}
              margin="normal"
              multiline
              rows={3}
              disabled={!this.props.gap || !this.state.userInformation.permissions.update}
              onChange={(event) => this.props.handleActionPlan(event)}
            />
          </Grid>
        </Grid>
      </Grid>
    )
  }

  renderDrawerBody() {
    const { point, isOption } = this.props;

    if (isOption) {
      if (point.cardProps.typeCard !== "question") {
        return this.drawerBodyAnswer();
      }
      return this.drawerBodyQuestion();
    } else {
      if (point.cardProps.typeCard === "question") {
        return this.drawerBodyAnswer();
      }
      return this.drawerBodyQuestion();
    }
  }

  renderDrawerFooter() {
    const { classes, isOption, closeDrawer, deletePoint } = this.props;

    return (
      <Grid item container direction="column" className={classes.drawerFooter}>
        <Grid item>
          <Button
            fullWidth
            className={classes.drawerButtonSave}
            disabled={
              !this.state.userInformation.permissions.update ||
              this.props.errorQuestion ||
              this.props.errorAnswer ||
              (this.props.gap && (!this.props.gapText || this.props.gapText.trim() === ""))
            }
            onClick={() => this.saveAction()}
            style={{ background: theme.primary_color }}
          >
            <Typography>
              Salvar
            </Typography>
          </Button>
        </Grid>
        <Grid item>
          {isOption ? (
            <Button
              disabled={!this.state.userInformation.permissions.delete}
              onClick={() => deletePoint()} fullWidth
              className={classes.drawerButtonDelete}
              style={{ color: theme.primary_color, borderColor: theme.primary_color }}>
              <Typography>
                Deletar
              </Typography>
            </Button>
          ) : (
            <Button onClick={() => closeDrawer()} fullWidth className={classes.drawerButtonCancel} >
              <Typography>
                Cancelar
              </Typography>
            </Button>
          )}
        </Grid>
      </Grid>
    );
  }

  render() {
    const { classes, openDrawer } = this.props;

    return (
      <Drawer
        variant="persistent"
        anchor="right"
        open={openDrawer}
        className={classes.drawer}
      >
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
          className={classes.drawerGrid}
        >
          <Grid item container direction="column">
            {this.renderDrawerHeader()}
            {this.renderDrawerBody()}
          </Grid>
          <div style={{ height: "16px" }} />
          {this.renderDrawerFooter()}
        </Grid>
      </Drawer>
    );
  }
}

DrawerQuiz.propTypes = {
  classes: PropTypes.object.isRequired,
  openDrawer: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  saveQuestion: PropTypes.func.isRequired,
  point: PropTypes.object.isRequired,
  isOption: PropTypes.bool.isRequired,
  deletePoint: PropTypes.func,
  quizId: PropTypes.string.isRequired,

  handleQuestion: PropTypes.func,
  handleDPIA: PropTypes.func,
  handleROPA: PropTypes.func,
  handleAnswer: PropTypes.func,
  handleObservation: PropTypes.func,
  handleGap: PropTypes.func,
  handleGapText: PropTypes.func,
  handleActionPlan: PropTypes.func,
  handleTypeQuestion: PropTypes.func,
  updatePointQuestion: PropTypes.func,
  updatePointAnswer: PropTypes.func,

  question: PropTypes.string.isRequired,
  DPIA: PropTypes.bool.isRequired,
  ROPA: PropTypes.bool.isRequired,
  answer: PropTypes.string.isRequired,
  observation: PropTypes.string.isRequired,
  gap: PropTypes.bool.isRequired,
  gapText: PropTypes.string.isRequired,
  actionPlan: PropTypes.string.isRequired,
  typeQuestion: PropTypes.string.isRequired,

  errorAnswer: PropTypes.bool.isRequired,
  errorQuestion: PropTypes.bool.isRequired,
  gapError: PropTypes.bool.isRequired,
  actionPlanError: PropTypes.bool.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  addQuestion: (question) => dispatch(addQuestion(question)),
  addAnswer: (answer) => dispatch(addAnswer(answer)),
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(DrawerQuiz));