import React, { Component } from "react";
import { TableRow, TableCell, Box, Typography, Grid } from "@material-ui/core";

import myApp from "../../../../core/infrastructure/firebaseConfig";
import { Dialog } from "@mui/material";

class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dadosList: [],
    };
  }
 
  render() {
    const styles = {
      cardActivity: {
        width: "90%",
        height: "53px",
        background: "#DFDEE4",
        overflow: "hidden",
        display: "flex",
      },
      cardActivityLine: {
        height: "100%",
        width: "2.5%",
        background: this.props.color,
      },
      cardActivityLabel: {
        marginTop: "auto",
        marginBottom: "auto",
        marginLeft: "3%",
        fontFamily: "Roboto",
        fontSize: "0.7vw",
      },
      cardWeek: {
        width: "100%",
        height: "53px",
        background: "#DFDEE4",
        overflow: "hidden",
      },
      tableCellStyle: {
        color: "#122148",
        borderBottom: "none",
        padding: "0 0 15px 10px",
        lineHeight: "0",
        cursor: "pointer",
      },
    };
    const openDialog = this.props.onClickActivity;




    return (
      <TableRow
     style={{height:"auto"}}
      >
        <TableCell style={styles.tableCellStyle}>
          <Box
            onClick={(_) => openDialog(this.props.id)}
            boxShadow="2"
            style={styles.cardActivity}
          >
            <div style={styles.cardActivityLine} />
            <p style={styles.cardActivityLabel}>{this.props.name.length > 35
     ? this.props.name.substring(0, 30) + "..." 
     : this.props.name}</p>
          </Box>
        </TableCell>
        {/* <TableCell>
          <Box onClick={_ => openDialog(this.props.id)} boxShadow="2" style={styles.cardActivity}>
						<div style={styles.cardActivityLine} />
						<p style={styles.cardActivityLabel}>{this.props.name}</p>
					</Box>
        
        </TableCell> */}
      </TableRow>
    );
  }
}

export default Activity;
