import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Container, CssBaseline } from '@material-ui/core'
import myApp from '../../core/infrastructure/firebaseConfig'

// const log = myApp.functions().httpsCallable('log');

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false, info: null, error: null }
  }

  async componentDidCatch (error, info) {
    this.setState({
      hasError: true,
      error: error,
      info: info,
    })
    // let user = myApp.auth().currentUser ?? null
    // await log({ trace: error.stack, message: error.message, user, level: 'error', info })
  }

  render () {
    if (this.state.hasError) {
      return (
        <Container maxWidth="md" style={{ height: '100vh' }}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            style={{ height: '100vh' }}
          >
            <CssBaseline/>
            <Grid item>
              <h2>OOPS! algo deu errado.</h2>
              <details style={{ whiteSpace: 'pre-wrap' }}>
                {this.state.error && this.state.error.toString()}
                <br/>
                {this.state.info.componentStack}
              </details>
            </Grid>
          </Grid>
        </Container>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary