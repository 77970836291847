import myApp from "../../core/infrastructure/firebaseConfig";
import "firebase/compat/auth";
import routerHistory from "../../core/infrastructure/routerHistory";


export function loginListener() {


  myApp.auth().onAuthStateChanged(async (user) => {
    var _company = localStorage.getItem("currentCompany");

    if (user) {
      if (_company !== null && _company !== "") {
        if (window.location.href.includes("/process")) {
          routerHistory.push("/process");

        } else {
          routerHistory.push("/");

        }
      } else {
        routerHistory.push("/company");
      }
    } else {
      routerHistory.push("/login");
    }
  });
}
