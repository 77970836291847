import React, { useEffect, useState } from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { changePage } from "../../../presentation/redux/actions";
import { useDispatch } from "react-redux";

import {
  Page,
  Text,
  View,
  Document,
  PDFViewer,
  Link,
} from "@react-pdf/renderer";

import { style } from "./PdfCss";
//import redux
import { connect } from "react-redux";
import "firebase/compat/firestore";

const handleFormatDate = (date) => {
  if (date) {
    let data = new Date(date);
    let dia = String(data.getDate()).padStart(2, "0");
    let mes = String(data.getMonth() + 1).padStart(2, "0");
    let ano = data.getFullYear();
    let dataAtual = dia + "/" + mes + "/" + ano;
    return dataAtual;
  } else {
    return "";
  }
};

const ViewReportDenunce = (props) => {
  //variaveis auxiliares
  const dispatch = useDispatch();

  //gerando pdf
  const pdfGenerator = () => {
    return (
      <Document>
        <Page size="A4">
          <View>
            <View>
              <Text style={style.textcenter}>
                Protocolo da denúncia:{" "}
                <span style={{ color: "#4682B4" }}>
                  {props.denunceObj.protocol}
                </span>{" "}
              </Text>

              <View style={style.between}>
                <Text> Denunciante</Text>
                <Text>{props.denunceObj.company_relation}</Text>
              </View>

              <View style={style.between}>
                <Text> Tipo de denúncia </Text>
                <Text>{props.denunceObj.relate_Type}</Text>
              </View>

              <View style={style.between}>
                <Text> Grau de sensibilidade </Text>
                <Text>{props.denunceObj.Sensitive}</Text>
              </View>

              <View style={style.between}>
                <Text> Data da denúncia </Text>
                <Text>
                  {Intl.DateTimeFormat("pt-br").format(props.denunceObj.date)}
                </Text>
              </View>

              <View style={style.between}>
                <Text> Apuração da denúncia </Text>
                <Text>{props.denunceObj.verificationConclusation}</Text>
              </View>

              <View style={style.between}>
                <Text> Status </Text>
                <Text>{props.denunceObj.status}</Text>
              </View>
            </View>

            <Text style={style.textcenter}>Detalhes do relator</Text>
            <Text style={{ padding: 15, fontSize: 20 }}>
              Relato da denúncia:
            </Text>

            <View>
              <Text style={{ color: "black", opacity: 0.8, padding: "10px" }}>
                {props.denunceObj.additionalInformation}
              </Text>
            </View>
            <Text style={{ padding: 15, fontSize: 20 }}>
              Arquivos fornecidos pelo relator
            </Text>
            {props.denunceObj.responsedenunce?.length != 0 ? (
              props.denunceObj.responsedenunce?.map((item) => (
                <View style={style.between}>
                  <Link
                    src={item.url}
                    style={{
                      color: "#4682B4",
                      opacity: 0.8,
                      fontSize: "12px",
                      textDecoration: "none",
                    }}
                  >
                    {item.name}
                  </Link>

                  <Link src={item.url}  style={style.button}>
                    Visualizar
                  </Link>
                </View>
              ))
            ) : (
              <Text style={[style.textcenter, { opacity: 0.5 }]}>
                Nenhum arquivo fornecido
              </Text>
            )}
            <Text style={style.textcenter}>Detalhes do analista</Text>
            <Text style={{ padding: 15, fontSize: 20 }}>Dados do analista</Text>
            <View style={style.between}>
              <Text style={{ color: "black", opacity: 0.8, padding: "5px" }}>
                Nome:
              </Text>
              <Text style={{ color: "black", opacity: 0.8, padding: "5px" }}>
                {props.denunceObj.name}
              </Text>
            </View>

            <View style={style.between}>
              <Text style={{ color: "black", opacity: 0.8, padding: "5px" }}>
                Relação:
              </Text>
              <Text style={{ color: "black", opacity: 0.8, padding: "5px" }}>
                {props.denunceObj.company_user_relation}
              </Text>
            </View>

            <Text style={{ padding: 15, fontSize: 20 }}>Resposta</Text>
            <Text style={{ color: "black", opacity: 0.8, padding: "10px" }}>
              {props.denunceObj.responseHistory?.map((item) => item.response)}
            </Text>

            <Text style={{ padding: 15, fontSize: 20 }}>
              Arquivos fornecidos pelo analista
            </Text>
            {props.denunceObj.files_document_response?.length != 0 ? (
              props.denunceObj.files_document_response?.map((item) => (
                <View style={style.between}>
                  <Link
                    src={item.url}
                    style={{
                      color: "#4682B4",
                      opacity: 0.8,
                      fontSize: "12px",
                      textDecoration: "none",
                    }}
                  >
                    {item.name}
                  </Link>

                  <Link src={item.url} style={style.button}>
                    Visualizar
                  </Link>
                </View>
              ))
            ) : (
              <Text style={[style.textcenter, { opacity: 0.5 }]}>
                Nenhum arquivo fornecido
              </Text>
            )}
          </View>
        </Page>
      </Document>
    );
  };

  // mudando pagina com redux
  const handleChangePage = () => {
    dispatch(changePage(42));
  };

  return (
    <Grid container>
      <Grid
        container
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "5px",
        }}
      >
        <Grid item>
          <Button
            variant="contained"
            style={{ background: "none", marginRight: "50px" }}
            onClick={() => handleChangePage()}
          >
            <ArrowBackIcon />
          </Button>
        </Grid>
        <Grid item>
          <Typography variant="h4" component="h4" style={{ fontWeight: "500" }}>
            Visualizar / baixar em PDF
          </Typography>
        </Grid>
      </Grid>
      {
        <PDFViewer width="100%" height="800px">
          {pdfGenerator()}
        </PDFViewer>
      }
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    denunceObj: state.ViewsPdfDenunceReducer.denunceObj,
  };
};

export default connect(mapStateToProps, { changePage })(ViewReportDenunce);
