import React, { Component } from 'react';
import {
	Typography, Grid, Card, Breadcrumbs, FormLabel, Slide,
	Button, Switch, Dialog, DialogTitle, DialogContentText, DialogContent
} from '@material-ui/core';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { connect } from 'react-redux';
import { Tooltip } from 'recharts';
import './personalDataManagementAndAccessStyle.css';
import myApp from '../../../core/infrastructure/firebaseConfig';
import "firebase/compat/firestore";
import { Link } from 'react-router-dom';

import theme from '../../../core/theme.json';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class PersonalDataManagementAndAccess extends Component {

	constructor(props) {
		super(props);
		this.state = {
			database: myApp.firestore(),
			value: 'declaration',
			descriptionWidth: "60%",
			token: localStorage.getItem('@blue-legal/token'),
			protocols: [],
			personal: false,
			exclusion: false,
			numberOfProtocols: 0,
			permissions: {
				capitalizacao: false,
				creditoPessoal: false,
				creditoConsignado: false,
				financiamento: false,
				creditoImobiliario: false,
				cartaoCreditoBradesco: false,
				cartaoCreditoParceiros: false,
				cartaoPrePago: false,
				contaCorrente: false,
				planoDental: false,
				fundosDeInvestimento: false,
				contaPoupanca: false,
				consorcioBensMoveis: false,
				consorcioDeImoveis: false,
				previdenciaPrivada: false
			},
			openDialog: false
		}
	}

	componentDidMount() {
		let self = this;
		this.getProtocols().then(
			self.getPermissions()
		)
	}

	async getPermissions() {
		let token = this.state.token;
		if (!token) {
			token = this.makeId(20);
			localStorage.setItem('@blue-legal/token', token);
		}
		console.log('token', token);

		let permissionsRef = await this.state.database.collection('permissions').doc(token);
		let permissions = this.state.permissions;

		permissionsRef.get().then((doc) => {
			permissions = doc.data();
			permissions["id"] = doc.id;

			console.log('setStatepermissions');
			this.setState({
				permissions,
			})
		})

	}

	async updatePermissions() {
		let token = this.state.token;
		if (!token) {
			token = this.makeId(20);
			localStorage.setItem('@blue-legal/token', token);
		}

		this.state.database.collection("permissions").doc(token).set(this.state.permissions)
			.then(() => {
				console.log("Document successfully written!");
			})
			.catch((error) => {
				console.error("Error writing document: ", error);
			});
	}

	async getProtocols() {
		let token = this.state.token;
		if (!token) {
			token = this.makeId(20);
			localStorage.setItem('@blue-legal/token', token);
		}
		if (token) {
			let protocolsRef = await this.state.database.collection('protocols').where('token', '==', token).get()
			let protocols = [];

			if (Array.isArray(protocolsRef.docs)) {
				protocolsRef.docs.forEach(doc => {
					let protocol = doc.data();
					protocol["id"] = doc.id;
					protocols.push(protocol);

					let personal = false;
					let exclusion = false;

					for (const p of this.state.protocols) {
						if (p.type === "personal") {
							personal = true;
						}

						if (p.type === "exclusion") {
							exclusion = true;
						}
					}

					this.setState({
						protocols,
						personal,
						exclusion
					})
				})
			}
		}
	}

	handleChange(value) {
		let permissions = this.state.permissions;
		permissions[value] = !permissions[value];

		this.setState({
			permissions
		})

		this.updatePermissions();
		console.log(this.state);
	}

	changeBodyColor() {
		document.body.style = "background-color: #EBEBEB"
	}

	async handleApplication(type) {
		let date = new Date();

		let d = {
			day: date.getDate() > 10 ? date.getDate() : '0' + date.getDate(),
			month: date.getMonth() > 10 ? date.getMonth() : '0' + date.getMonth(),
			year: date.getFullYear(),
			hours: date.getHours() > 10 ? date.getHours() : '0' + date.getHours(),
			minutes: date.getMinutes() > 10 ? date.getMinutes() : '0' + date.getMinutes(),
			seconds: date.getSeconds() > 10 ? date.getSeconds() : '0' + date.getSeconds(),
		};

		let dateAdded = d.day + '/' + d.month + '/' + d.year + ' ' + d.hours + ':' + d.minutes + ':' + d.seconds;

		let number = d.year + d.month + d.day;


		try {

			let protocolsRef = await this.state.database.collection('protocols').get();
			let numberOfProtocols = protocolsRef.docs.length + '';
			number += numberOfProtocols.padStart(11, '0');

			if ((!this.state.personal && (type === 'personal')) || (!this.state.exclusion && (type === 'exclusion'))) {
				try {
					this.state.database.collection("protocols").add({
						token: this.state.token,
						dateAdded,
						number,
						type,
						status: 'pending'
					})
						.then((docRef) => {
							console.log("Document written with ID: ", docRef.id);
							if (type === 'personal') {
								this.setState({
									personal: true
								})
							} else {
								this.setState({
									exclusion: true
								})
							}

							this.setState({
								openDialog: true,
								number
							})


						})
						.catch((error) => {
							console.error("Error adding document: ", error);
						});
				} catch (error) {
					console.error('Error creating protocol!', error);
				}
			} else {
				if (type === 'personal') {
					alert('Protocolo de portabilidade ja foi registrado');
				} else {
					alert('Protocolo de exclusao ja foi registrado');
				}
			}
		} catch (error) {
			console.log('error', error);
		}


	}

	makeId(length) {
		var result = [];
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result.push(characters.charAt(Math.floor(Math.random() *
				charactersLength)));
		}
		return result.join('');
	}

	handleClose() {
		this.setState({
			openDialog: false
		});
	}

	handleRedirectFollowUp() {
		this.handleClose();

	}

	render() {
		const { openDialog, number } = this.state;
		return (
			<>
				{this.changeBodyColor()}
				<Typography style={{ fontSize: "32px", fontWeight: "550", color: "#111C39", textAlign: "center" }}>Acesso e Gerenciamento de Dados Pessoais</Typography>

				<Grid container direction="column" spacing={4} alignItems={"center"} style={{ justify: "center" }}>
					<Grid item alignItems={"center"} style={{ width: "85%", justify: "center" }}>
						<Card className="tittlePage">
							<Breadcrumbs aria-label="breadcrumb">
							</Breadcrumbs>
							<Typography style={{ fontSize: "22px", fontWeight: "550", color: "#111C39" }}>Compartilhamento de Dados</Typography>
							<FormLabel component="legend" style={{ width: this.state.descriptionWidth }}>Para você fazer o compartilhamento (portabilidade) de seus dados pessoais com outros fornecedores de produtos e serviços</FormLabel>
							<Tooltip>teste</Tooltip>
							<hr></hr>
							<FormLabel component="legend" style={{ width: this.state.descriptionWidth }}>Ao solicitar o compartilhamento (portabilidade) de dados, você poderá visualizar o relatório, assim você terá facilidade em compartilhá-lo com outros fornecedores de produtos e serviços</FormLabel>
							<Button
								onClick={(event) => { this.handleApplication('personal') }}
								style={{ backgroundColor: theme.primary_color, color: theme.secondary_color, marginTop: "10px" }}>Solicitar Compartilhamento de Dados</Button>
						</Card>
					</Grid>

					<Grid item alignItems={"center"} style={{ width: "85%", justify: "center" }}>
						<Grid item>
							<Card className="tittlePage">
								<Breadcrumbs aria-label="breadcrumb">
								</Breadcrumbs>
								<Typography style={{ fontSize: "22px", fontWeight: "550", color: "#111C39" }}>Eliminação de Dados</Typography>
								<FormLabel component="legend" style={{ width: this.state.descriptionWidth }}>Você tem o direito de solicitar a eliminação total dos dados tratados pela Organização</FormLabel>
								<hr></hr>
								<FormLabel component="legend" style={{ width: this.state.descriptionWidth }}>Você sabia que pode solicitar a exclusão total de seus dados?</FormLabel>
								<br></br><br></br>
								<FormLabel component="legend" style={{ width: this.state.descriptionWidth }}>Para excluir totalmente seus dado, solicite a eliminação de dados no botão abaixo.</FormLabel>
								<br></br><br></br>
								<Grid container direction="row" justify="flex-start" alignItems="flex-start">
									<Grid item xs="4">
										<Grid container direction="row" alignItems="stretch" justify="flex-start" style={{ backgroundColor: "#F4F0F0", padding: "10px" }}>
											<Grid item xs="2" >
												<ReportProblemOutlinedIcon style={{ color: "orange", fontSize: "40px" }} />
											</Grid>
											<Grid item xs="10">
												<small style={{ color: "#90939F", fontFamily: "Roboto" }}>Caso você tenha um ou mais produtos contratados não será possível a exclusão dos dados, pois essas informações são necessárias para manter
													os produtos ativos
												</small>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs="3" style={{ marginLeft: "10px" }}>
										<Button
											onClick={(event) => { this.handleApplication('exclusion') }}
											style={{ backgroundColor: theme.primary_color, color: theme.secondary_color, marginTop: "10px" }}>Solicitar Eliminação de Dados</Button>
									</Grid>
								</Grid>
							</Card>
						</Grid>

					</Grid>

					<Grid item alignItems={"center"} style={{ width: "85%", justify: "center" }}>
						<Grid container>
							<Grid item>
								<Card className="tittlePage">
									<Grid container>
										<Grid item>
											<Breadcrumbs aria-label="breadcrumb">
											</Breadcrumbs>
											<Typography style={{ fontSize: "22px", fontWeight: "550", color: "#111C39" }}>Permissões (oposição ao tratamento de dados pessoais)</Typography>
											<hr></hr>
											<FormLabel component="legend" style={{ width: this.state.descriptionWidth }}>Com base nos seus dados pessoais, oferecemos a você produtos e serviços que podem ser do seu interesse. Se Preferir,
												Deixaremos de tratar seus dados para enviar essas ofertas por e-mail, SMS, telefone e correspondência</FormLabel>
										</Grid>

										<Grid item style={{ marginTop: "20px" }}>
											<Grid container direction="row" justify="center" alignItems="center">
												<Grid item xs="4">
													<Grid container direction="row" justify="flex-start" alignItems="flex-start">
														<Grid item>
															<Switch
																checked={this.state.permissions.creditoPessoal}
																onChange={() => this.handleChange('creditoPessoal')}
																name="checkedB"
																inputProps={{ 'aria-label': 'primary checkbox' }}
																color="primary"
															/>
														</Grid>
														<Grid item>
															<Typography>Crédito pessoal</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs="4">
													<Grid container direction="row" justify="flex-start" alignItems="center">
														<Grid item>
															<Switch
																checked={this.state.permissions.creditoConsignado}
																onChange={() => this.handleChange('creditoConsignado')}
																color="primary"
																name="checkedB"
																inputProps={{ 'aria-label': 'primary checkbox' }}
															/>
														</Grid>
														<Grid item>
															<Typography>Crédito consignado (desconto em folha de pagamento)</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs="4">
													<Grid container direction="row" justify="flex-start" alignItems="center">
														<Grid item>
															<Switch
																checked={this.state.permissions.financiamento}
																onChange={() => this.handleChange('financiamento')}
																color="primary"
																name="checkedB"
																inputProps={{ 'aria-label': 'primary checkbox' }}
															/>
														</Grid>
														<Grid item>
															<Typography>Financiamento de bens e veículos</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs="4">
													<Grid container direction="row" justify="flex-start" alignItems="center">
														<Grid item>
															<Switch
																checked={this.state.permissions.creditoImobiliario}
																onChange={() => this.handleChange('creditoImobiliario')}
																color="primary"
																name="checkedB"
																inputProps={{ 'aria-label': 'primary checkbox' }}
															/>
														</Grid>
														<Grid item>
															<Typography>Crédito imobiliário</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs="4">
													<Grid container direction="row" justify="flex-start" alignItems="center">
														<Grid item>
															<Switch
																checked={this.state.permissions.capitalizacao}
																onChange={() => this.handleChange('capitalizacao')}
																color="primary"
																name="checkedB"
																inputProps={{ 'aria-label': 'primary checkbox' }}
															/>
														</Grid>
														<Grid item>
															<Typography>Capitalização</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs="4">
													<Grid container direction="row" justify="flex-start" alignItems="center">
														<Grid item>
															<Switch
																checked={this.state.permissions.cartaoCreditoBradesco}
																onChange={() => this.handleChange('cartaoCreditoBradesco')}
																color="primary"
																name="checkedB"
																inputProps={{ 'aria-label': 'primary checkbox' }}
															/>
														</Grid>
														<Grid item>
															<Typography>Cartão de crédito Bradesco</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs="4">
													<Grid container direction="row" justify="flex-start" alignItems="center">
														<Grid item>
															<Switch
																checked={this.state.permissions.cartaoCreditoParceiros}
																onChange={() => this.handleChange('cartaoCreditoParceiros')}
																color="primary"
																name="checkedB"
																inputProps={{ 'aria-label': 'primary checkbox' }}
															/>
														</Grid>
														<Grid item>
															<Typography>Cartão de crédito de parceiros (Bradescard)</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs="4">
													<Grid container direction="row" justify="flex-start" alignItems="center">
														<Grid item>
															<Switch
																checked={this.state.permissions.cartaoPrePago}
																onChange={() => this.handleChange('cartaoPrePago')}
																color="primary"
																name="checkedB"
																inputProps={{ 'aria-label': 'primary checkbox' }}
															/>
														</Grid>
														<Grid item>
															<Typography>Cartão pré-pago</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs="4">
													<Grid container direction="row" justify="flex-start" alignItems="center">
														<Grid item>
															<Switch
																checked={this.state.permissions.contaCorrente}
																onChange={() => this.handleChange('contaCorrente')}
																color="primary"
																name="checkedB"
																inputProps={{ 'aria-label': 'primary checkbox' }}
															/>
														</Grid>
														<Grid item>
															<Typography>Conta Corrente</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs="4">
													<Grid container direction="row" justify="flex-start" alignItems="center">
														<Grid item>
															<Switch
																checked={this.state.permissions.planoDental}
																onChange={() => this.handleChange('planoDental')}
																color="primary"
																name="checkedB"
																inputProps={{ 'aria-label': 'primary checkbox' }}
															/>
														</Grid>
														<Grid item>
															<Typography>Plano Dental</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs="4">
													<Grid container direction="row" justify="flex-start" alignItems="center">
														<Grid item>
															<Switch
																checked={this.state.permissions.fundosDeInvestimento}
																onChange={() => this.handleChange('fundosDeInvestimento')}
																color="primary"
																name="checkedB"
																inputProps={{ 'aria-label': 'primary checkbox' }}
															/>
														</Grid>
														<Grid item>
															<Typography>Fundos de Investimento</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs="4">
													<Grid container direction="row" justify="flex-start" alignItems="center">
														<Grid item>
															<Switch
																checked={this.state.permissions.contaPoupanca}
																onChange={() => this.handleChange('contaPoupanca')}
																color="primary"
																name="checkedB"
																inputProps={{ 'aria-label': 'primary checkbox' }}
															/>
														</Grid>
														<Grid item>
															<Typography>Conta-poupança</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs="4">
													<Grid container direction="row" justify="flex-start" alignItems="center">
														<Grid item>
															<Switch
																checked={this.state.permissions.consorcioBensMoveis}
																onChange={() => this.handleChange('consorcioBensMoveis')}
																color="primary"
																name="checkedB"
																inputProps={{ 'aria-label': 'primary checkbox' }}
															/>
														</Grid>
														<Grid item>
															<Typography>Consórcio de bens móveis</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs="4">
													<Grid container direction="row" justify="flex-start" alignItems="center">
														<Grid item>
															<Switch
																checked={this.state.permissions.consorcioDeImoveis}
																onChange={() => this.handleChange('consorcioDeImoveis')}
																color="primary"
																name="checkedB"
																inputProps={{ 'aria-label': 'primary checkbox' }}
															/>
														</Grid>
														<Grid item>
															<Typography>Consórcio de imóveis</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs="4">
													<Grid container direction="row" justify="flex-start" alignItems="center">
														<Grid item>
															<Switch
																checked={this.state.permissions.previdenciaPrivada}
																onChange={() => this.handleChange('previdenciaPrivada')}
																color="primary"
																name="checkedB"
																inputProps={{ 'aria-label': 'primary checkbox' }}
															/>
														</Grid>
														<Grid item>
															<Typography>Previdência Privada</Typography>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Card>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Dialog
					open={openDialog}
					TransitionComponent={Transition}
					maxWidth="sm"
					fullWidth
					onClose={() => this.handleClose()}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
					contentStyle={{
						width: '70%',
						maxWidth: 'none',
					}}
				>
					<DialogTitle id="alert-dialog-slide-title" style={{ height: "20px" }} >
						<Typography variant="h6" style={{ fontFamily: "Roboto", color: "#90939F", textAlign: "center" }}>Solicitação feita com sucesso</Typography>
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-slide-description">
							<Grid
								container
								direction="column"
								alignItems="center"
								justify="center"
								spacing={2}
							>
								<Grid item>
									<Typography>Em até 2 dias úteis, você poderá visualizar o relatório solicitado acessando a área de Acompanhamento</Typography>
								</Grid>

								<Grid item>
									<Typography style={{ color: "#111C39", fontWeight: "500" }}>Protocolo do atendimento</Typography>
								</Grid>
								<Grid item>
									<Typography style={{ color: "#111C39", fontWeight: "500" }}>{number}</Typography>
								</Grid>

								<Grid item xs={12}>
									<Link
										variant="contained"
										color="primary"
										className="buttonFullStyle"
										to="/follow-up"
									>
										<Button
											variant="outlined"
											fullWidth
											onClick={() => this.handleRedirectFollowUp()}
											style={{ color: "white", backgroundColor: theme.primary_color }}
										>
											Acompanhar solicitação
										</Button>

									</Link>

								</Grid>
								<Grid item xs={12}>
									<Button
										variant="outlined"
										fullWidth
										onClick={() => this.handleClose()}
										style={{ color: "#122148", backgroundColor: "white", borderColor: "#122148" }}
									>
										Voltar para Termos e Condições
									</Button>
								</Grid>
							</Grid>
						</DialogContentText>
					</DialogContent>
				</Dialog>

			</>
		)
	}

}


const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDataManagementAndAccess);