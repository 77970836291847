import React, { cloneElement, Component } from "react";
import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";

import {
  Paper,
  Grid,
  Typography,
  ButtonBase,
  Menu,
  MenuItem,
  Popover,
  Dialog,
  DialogTitle,
  Button,
  Tooltip,
  IconButton,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";

import SettingsIcon from "@material-ui/icons/Settings";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { connect } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import { changePage } from "../../../presentation/redux/actions";
import { setProcessId, setLoadingProcess } from "../redux/actions";
import "./process.css";
import theme from "../../../../core/theme.json";
import { GridCheckCircleIcon } from "@material-ui/data-grid";

class ProcessItem extends Component {
  constructor(props) {
    super(props);
    // console.log(props)
    this.db = myApp.firestore();

    this.state = {
      processList: [],
      clone: false,
      dialogClone: false,
      dialogRemove: false,
      dialogCloneSuceful:false,
      openOption:false,
      data: [],
      targetValue:'',
      responseId:'',
      current_companie: localStorage.getItem("currentCompany")
    };
    this.clone = this.clone.bind(this);

    this.removeProcess = this.removeProcess.bind(this);
  }

  goToDashboard() {
    this.props.setLoadingProcess(true);
    this.props.setProcessId(this.props.id);
    this.props.changePage(7);
  }



  async clone() {
    let get = await myApp
      .firestore()
      .collection("processes")
      .doc(this.props.id)
      .get();

    let create = await myApp
      .firestore()
      .collection("processes")
      .add({
        ...get.data(),
        name: this.props.goal + "(copia)",
      });

    this.setState({
      clone: true,
    });
  }

  handleCloneDialog() {
    this.setState({
      clone: !this.state.clone,
    });
  }
  handleOpenDialogClone() {
    this.setState({
      dialogClone: !this.state.dialogClone,
      openOption:false
    });
  }
  handleOpenDialogRemove() {
    this.setState({
      dialogRemove: !this.state.dialogRemove,
      
    });
  }

  async removeProcess() {
    console.log(this.props.id);
    myApp
      .firestore()
      .collection("processes")
      .doc(this.props.id)
      .delete()
      .then(() => {
        this.setState({
          dialogRemove: !this.state.dialogRemove,
        });
      })
      .catch((e) => console.error("Erro ao excluir processo", e));
  }

selectProcessId() {
   this.setState({
    openOption:!this.state.openOption
   })
}

async handleProcess() {

  let aux= []
  
  try{

    let get = await myApp
    .firestore()
    .collection("areas")
    .where("fk_companies", "==",this.state.current_companie)
    .get();
    
   for (let item of get.docs){
    aux.push({
      id:item.id,
      name: item.data().name
    })
   }
   
  }
  catch(err){
    console.log('erro get company',err)
  }
  
  
  this.setState({
    data: aux
  })

}

async cloneForArea(){


  let getProcess = await myApp
  .firestore()
  .collection("processes")
  .doc(this.props.id)
  .get();

  let createCloneForProcess = await myApp
  .firestore()
  .collection("processes")
  .add({
    ...getProcess.data(),
    fk_areas: this.state.targetValue,
   name: this.props.goal + "(copia)",
    
  })
  .then(()=>{
    console.log('tudo certo' , getProcess.data())
    this.setState({
      dialogClone:false,
      dialogCloneSuceful:true
    })
    
  })

  
  
}

  render() {
    return (
      <div>
        <Grid container direction="row" spacing={4} style={{ height: "320px" }}>
          <Grid item style={{ width: "100%", height: "100%" }}>
            <ButtonBase
              onClick={() => {
                this.goToDashboard();
              }}
              style={{ width: "100%", height: "100%", borderRadius: "8px" }}
            >
              <Paper
                style={{ width: "100%", height: "100%", borderRadius: "8px" }}
              >
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                  style={{ height: "100%" }}
                >
                  <Grid
                    item
                    container
                    justify="center"
                    alignItems="center"
                    style={{ width: "100%", height: "50%" }}
                  >
                    <Grid item>
                      <SettingsIcon
                        style={{
                          fontSize: theme.icon_size,
                          color: theme.secondary_color,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    justify="center"
                    style={{ width: "100%", height: "50%" }}
                  >
                    <Grid
                      item
                      container
                      justify="center"
                      style={{
                        width: "100%",
                        height: "80%",
                        overflowY: "hidden",
                        textOverflow: "ellipsis",
                        margin: "0 10px 0 10px",
                      }}
                    >
                      <ButtonBase>
                        <Typography
                          variant="h6"
                          component="h6"
                          style={{
                            color: theme.tertiary_color,
                            overflowWrap: "anywhere",
                          }}
                        >
                          {this.props.goal}
                        </Typography>
                      </ButtonBase>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </ButtonBase>

            <ButtonBase
              style={{
                marginTop: "-31%",
                marginLeft: "60%",
                width: "40%",
                height: "15%",
              }}
              onClick={() => this.handleOpenDialogClone()}
            >
              <Tooltip title="Clonar">
                <IconButton aria-label="Clonar">
                  <FileCopyIcon style={{ color: "#a0a0a0" }} />
                </IconButton>
              </Tooltip>
            </ButtonBase>

            <ButtonBase
              style={{
                marginTop: "-28%",
                marginLeft: "-95%",
                width: "10%",
                height: "15%",
              }}
              onClick={() => this.handleOpenDialogRemove()}
            >
              <Tooltip title="Excluir">
              <DeleteIcon style={{ color: "#a0a0a0" }} />
              </Tooltip>
            </ButtonBase>
          </Grid>
        </Grid>

        <Dialog
          open={this.state.clone}
          PaperProps={{
            style: {
              width: "auto",
              padding: "16px",
            },
          }}
        >
          <DialogTitle>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item>Cópia criada com sucesso!</Grid>
            </Grid>
          </DialogTitle>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={4}
          >
            <Grid item>
              <GridCheckCircleIcon
                style={{ color: "#45ad18", fontSize: "80px" }}
              />
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <Button
                variant="contained"
                style={{
                  background: "#45ad18",
                  color: "white",
                  width: "100%",
                  height: "56px",
                }}
                onClick={() => {
                  this.handleCloneDialog();
                }}
              >
                Ok
              </Button>
            </Grid>
          </Grid>
        </Dialog>

        <Dialog
          open={this.state.dialogCloneSuceful}
          PaperProps={{
            style: {
              width: "auto",
              padding: "16px",
            },
          }}
        >
          <DialogTitle>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item>Cópia criada com sucesso!</Grid>
            </Grid>
          </DialogTitle>
          <Grid>
          <Typography
                          variant="body1"
                          component="h6"
                          style={{
                            color: theme.tertiary_color,
                            overflowWrap: "anywhere",
                          }}
                        >
                          Navegue até a Área em que copiou o processo
                        </Typography>
          </Grid>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={4}
          >
            <Grid item>
              <GridCheckCircleIcon
                style={{ color: "#45ad18", fontSize: "80px" }}
              />
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <Button
                variant="contained"
                style={{
                  background: "#45ad18",
                  color: "white",
                  width: "100%",
                  height: "56px",
                }}
                onClick={() => {
                  this.setState({
                    dialogCloneSuceful:false
                  });
                }}
              >
                Ok
              </Button>
            </Grid>
          </Grid>
        </Dialog>

        <Dialog
          onClose={() => {
            this.handleOpenDialogClone();
            this.setState({
              targetValue:''
            })
          }}
          open={this.state.dialogClone}
          PaperProps={{
            style: {
              width: "auto",
              padding: "16px",
            },
          }}
        >
          <DialogTitle>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item>Para qual Àrea você deseja copiar o projeto ?</Grid>
            </Grid>
          </DialogTitle>
          <Grid spacing={4}>
            <Grid item style={{ marginTop: "5%" }}>
              <Button
                onClick={() => {
                  this.clone();
                  this.setState({
                    dialogClone: false,
                  });
                }}
                variant="contained"
                style={{
                  background: "white",
                  borderWidth: "#a0a0a0",
                  width: "100%",
                  height: "56px",
                }}
              >
                Para esta Área
              </Button>
            </Grid>
            <Grid item style={{ marginTop: "5%" }}>
              <Button
              onClick={()=>{this.selectProcessId();this.handleProcess()}}
                variant="contained"
                style={{
                  background: "white",
                  borderWidth: "#a0a0a0",
                  width: "100%",
                  height: "56px",
                }}
              >
                Para outra Área
              </Button>
            </Grid>
          </Grid>
          <Grid className={this.state.openOption ?"openOptionAreas" :"closeoptionAreas"} spacing={4}>
            <DialogTitle>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  Ecolha a Àrea que você deseja copiar o projeto.
                </Grid>
              </Grid>
            </DialogTitle>
            <Grid item style={{ marginTop: "5%" }}>
            <FormControl variant="outlined" fullWidth>
                            <InputLabel>Selecione alternativa</InputLabel>
                            <Select
                             onChange={(e)=> this.setState({targetValue:e.target.value })}
                              label="Selecione alternativa"
                              style={{ textAlign: "start" }}
                            >
                              
                              {
                                this.state.data.map((item, index) => (
                                  <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                ))
                              }
                               
                              
                            </Select>
                          </FormControl>
            </Grid>

            <Grid style={{ marginTop: "5%" }}>
          <Button
           disabled={this.state.targetValue == '' ? true : false}
              onClick={this.state.targetValue !=='' ? ()=> this.cloneForArea():''}
              color="primary"
                variant="contained"
                className='buttonCloneProcess'
              >
                Copiar processo
              </Button>
          </Grid>

          </Grid>

         
        </Dialog>

        <Dialog
          open={this.state.dialogRemove}
          onClose={() => this.handleOpenDialogRemove()}
          PaperProps={{
            style: {
              width: "auto",
              padding: "16px",
            },
          }}
        >
          <DialogTitle>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                Você tem certeza que deseja excluir esse processo?
              </Grid>
            </Grid>
          </DialogTitle>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={4}
          >
            <Grid item xs={5}>
              <Button
                onClick={() => this.removeProcess()}
                variant="contained"
                style={{
                  background: theme.secondary_color,
                  color: "white",
                  width: "100%",
                  height: "56px",
                }}
              >
                Sim
              </Button>
            </Grid>
            <Grid item xs={5}>
              <Button
                onClick={() => this.handleOpenDialogRemove()}
                variant="contained"
                style={{
                  background: "#687394",
                  color: "white",
                  width: "100%",
                  height: "56px",
                }}
              >
                Não
              </Button>
            </Grid>
          </Grid>
        </Dialog>
      </div>
    );
  }
}

export default connect(null, { changePage, setProcessId, setLoadingProcess })(
  ProcessItem
);
