async function generatePrimaryHeader() {
  let data = [];
  for (let i = 0; i < 53; i++) {
    switch (i) {
      case 0:
        data.push("PARTE I - IDENTIFICAÇÃO DO PROCESSO DE NEGÓCIO\nControle de Responsabilidade pelo Processo de Negócio");
        break;
      case 7:
        data.push("PARTE II - ORIGEM DOS DADOS\nProcesso de Coleta");
        break;
      case 16:
        data.push("PARTE III - TRATAMENTO DOS DADOS PESSOAIS");
        break;
      case 27:
        data.push("PARTE IV - ANÁLISE DE COMPLIANCE");
        break;
      case 34:
        data.push("PARTE V - ANÁLISE JURÍDICA");
        break;
      case 49:
        data.push("PARTE VI - ANÁLISE DE SEGURANÇA DA INFORMAÇÃO / TECNOLOGIA");
        break;
      case 53:
        data.push("CONTROLES DPO");
        break;
      default:
        data.push("");
        break;
    }
  }

  return data;
}

async function generateSecondaryHeader() {
  let data = [];
  data.push("Elencar pessoa-chave para detalhar cada processo de negócio");
  data.push("");
  data.push("");
  data.push("");
  data.push("");
  data.push("Nível de impacto ao negócio e data do registro");
  data.push("");
  data.push("Para que se coleta?");
  data.push("Os dados coletados são imprescindíveis?");
  data.push("Como são coletados? (origem/entrada da coleta)");
  data.push("Quais dados são coletados?");
  data.push("Quais dados sensíveis são coletados");
  data.push("Qual atuação?");
  data.push("Quem é o titular?");
  data.push("História do Dado");
  data.push("História do Dado");
  data.push("Os dados são compartilhados internamente?");
  data.push("Como os dados são transmitidos?");
  data.push("Onde os dados são armazenados?");
  data.push("Quais sistemas/aplicações envolvidos?");
  data.push("Os dados são compartilhados com terceiros?");
  data.push("Quais terceiros?");
  data.push("Qual a finalidade do compartilhamento?");
  data.push("Como os dados são compartilhados com os terceiros?");
  data.push("Prazo de armazenamento dos dados?");
  data.push("Forma de descarte dos dados?");
  data.push("Descrição de adequações propostas");
  data.push("Aviso específico em contrato e/ou consentimento?");
  data.push("Coletando apenas o essencial?");
  data.push("Caso o titular queira exercer os seus direitos, a empresa tem condições de atendê-los rapidamente?");
  data.push("Quando foi a última alteração deste inventário?");
  data.push("Outro pessoa valida a veracidade do inventário?");
  data.push("Anexar evidências de auditoria nos Parceiros (Processadores envolvidos)?");
  data.push("Descrição de adequações propostas");
  data.push("Por quanto o dado deve ser armazenado?");
  data.push("");
  data.push("O tratamento dos dados pode ou não ser feito de acordo com a base legal?");
  data.push("Categoria do Dado?");
  data.push("Aditivos DPA. SLA, Multas e Resposta a incidentes alinhados com os parceiros processadores?");
  data.push("Descrição de adequações propostas");
  data.push("O acesso é limitado somente aqueles que tem nível e permissão suficiente?");
  data.push("Qual o controle de acesso a informação? Exige senha forte? Autenticação de 2 fatores? Login individual e intransferível?");
  data.push("Identificar a categoria de manuseio dos dados (interno/confidencial/restrito/público)");
  data.push("Existem tomadas de decisões automatizadas? Aquelas que definem o perfil pessoal, profissional, de consumo ou de crédito ou os aspectos de personalidade dos titulares, através do mecanismo de automatização (softwares, programas).");
  data.push("Qual a rotina de backup");
  data.push("Transferência Internacional");
  data.push("Continuidade");
  data.push("Probabilidade / Vulnerabilidade do Risco");
  data.push("Impacto do Risco");
  data.push("Indicar o novo tempo de retenção do dado");
  data.push("Auditoria Interna Descarte");
  data.push("Due Dilligence - Processos e Parceiros");
  data.push("Documentos solicitados ao entrevistado");

  return data;
}

async function generateTertiaryHeader() {
  let data = [];
  data.push("Processo de Negócio");
  data.push("Diretoria");
  data.push("Área de Negócio");
  data.push("Responsável pelo Processo");
  data.push("Contato");
  data.push("Criticidade ao negócio");
  data.push("Última Atualização");
  data.push("Finalidade de coleta");
  data.push("Necessidade");
  data.push("Formas de coleta");
  data.push("Dados Pessoais");
  data.push("Dados sensíveis");
  data.push("Controlador ou Operador");
  data.push("Nome");
  data.push("Origem");
  data.push("Destino");
  data.push("Compartilhamento interno");
  data.push("Transmissão");
  data.push("Repositório");
  data.push("Sistemas");
  data.push("Compartilhamento externo");
  data.push("Nome do Terceiro");
  data.push("Finalidade");
  data.push("Transmissão2");
  data.push("Retenção");
  data.push("Descarte");
  data.push("Adequações de tratamento");
  data.push("Transparência ao titular");
  data.push("Dados necessários");
  data.push("Direito do titular");
  data.push("Frequência de Atualização");
  data.push("Auditoria Interna");
  data.push("Due Dilligence Parceiros");
  data.push("Adequações de compliance");
  data.push("Prazo legal de retenção");
  data.push("Base Legal");
  data.push("Referência em Lei");
  data.push("Sensível ou Não-Sensível");
  data.push("Contratos com Parceiros");
  data.push("Adequações jurídicas");
  data.push("Controle de acessos");
  data.push("Autenticação");
  data.push("Classificação dos dados");
  data.push("Medidas Técnicas de Segurança");
  data.push("Cópia segura");
  data.push("Indicar o país?");
  data.push("Este processo pode causar impacto ao negócio se ficar indisponível");
  data.push("Rara, Baixa, Média, Quase Certa, Alta");
  data.push("Insignificante, Pequeno, Médio, Grande, Muito Grande");
  data.push("De acordo com os padrões LGPD");
  data.push("Indicar a última auditoria");
  data.push("Indicar a última auditoria e guardar evidências");
  data.push("Documentação comprobatória");

  return data;
}

export async function generateData(processList) {
  let data = [];

  data.push(await generatePrimaryHeader());
  data.push(await generateSecondaryHeader());
  data.push(await generateTertiaryHeader());

  for (const process of processList) {
    let row = [];
    let stages = {};
    let internalShare = {
      answers: false,
      objInternalShare: {
        destiny: '',
        destinyOwner: '',
        destinySector: '',
        source: '',
        sourceOwner: '',
        sourceSector: ''
      }
    }
    let auxOne = {
      boolValueAux: false,
      valueAux: ''
    };

    let obj = process.json != "" ? Object.values(JSON.parse(process.json)) : false;
    if (obj) {
      for (let item of obj) {
        if (item.type === 'compartilhamentoInterno') {
          for (let outroItem of item.sharings) {
            auxOne.boolValueAux = true;
            auxOne.valueAux += 'Origem: '+outroItem.source+
              ' - Destino: '+outroItem.destiny+
              ' Setor responsável origem: '+
              outroItem.sourceSector+
              ' - Setor responsável destino: '+
              outroItem.destinySector+
              ' Responsável origem: '+
              outroItem.sourceOwner+
              ' - Responsável destino: '+
              outroItem.destinyOwner+
            ' //';
          }
        }
      }
    }


    if (process.json && process.json !== "") {
      stages = JSON.parse(process.json);
    }

    const keys = Object.keys(stages);

    row.push(process.ROPAanswers[0].dado ? process.ROPAanswers[0].dado : ''); // Processo de Negócio
    row.push(process.ROPAanswers[1].dado ? process.ROPAanswers[1].dado : ''); // Diretoria
    row.push(process.ROPAanswers[2].dado ? process.ROPAanswers[2].dado : ''); // Área de Negócio
    row.push(process.ROPAanswers[3].dado ? process.ROPAanswers[3].dado : ''); // Responsável pelo Processo
    row.push(process.ROPAanswers[4].dado ? process.ROPAanswers[4].dado : ''); // contato
    row.push(process.criticality); // Criticidade ao negócio
    row.push(""); // Última Atualização
    row.push(process.ROPAanswers[7].dado ? process.ROPAanswers[7].dado : ''); // Finalidade de coleta
    row.push(process.ROPAanswers[8].dado ? process.ROPAanswers[8].dado : ''); // Necessidade
    row.push(process.ROPAanswers[7].dado ? process.ROPAanswers[7].dado : ''); // Formas de coleta
    row.push(process.ROPAanswers[10].dado ? process.ROPAanswers[10].dado : ''); // dados pessoais
    row.push(process.ROPAanswers[11].dado ? process.ROPAanswers[11].dado : ''); // dados sensiveis
    row.push(process.operator); // Controlador ou Operador
    row.push(process.ROPAanswers[13].dado ? process.ROPAanswers[13].dado : ''); // nome
    row.push(process.ROPAanswers[14].dado ? process.ROPAanswers[14].dado : ''); // origem
    row.push(process.ROPAanswers[15].dado ? process.ROPAanswers[15].dado : ''); // destino
    row.push(process.ROPAanswers[16].dado ? process.ROPAanswers[16].dado : ''); // compartilhamento interno
    row.push(""); // transmissão
    row.push(process.ROPAanswers[18].dado ? process.ROPAanswers[18].dado : ''); // Repositorio
    row.push(""); // sistemas
    row.push(process.ROPAanswers[20].dado ? process.ROPAanswers[20].dado : ''); // compartilhamento externo
    row.push(process.ROPAanswers[21].dado ? process.ROPAanswers[21].dado : ''); // nome do terceiro
    row.push(""); // finalidade
    row.push(""); // transmissão2
    row.push(process.ROPAanswers[24].dado ? process.ROPAanswers[24].dado : ''); // retenção
    row.push(""); // descarte
    row.push(""); // Adequações de tratamento
    row.push(""); // Transparência ao titular
    row.push(process.ROPAanswers[8].dado ? process.ROPAanswers[8].dado : ''); // Dados necessários
    row.push(process.ROPAanswers[29].dado ? process.ROPAanswers[29].dado : ''); // Direito do titular
    row.push(""); // Frequência de Atualização
    row.push(""); // Auditoria Interna
    row.push(""); // Due Dilligence Parceiros
    row.push(process.ROPAanswers[33].dado ? process.ROPAanswers[33].dado : ''); // Adequações de compliance
    row.push(""); // Prazo legal de retenção
    row.push(""); // Base Legal
    row.push(""); // Referência em Lei
    row.push(""); // Sensível ou Não-Sensível
    row.push(""); // Contratos com Parceiros
    row.push(""); // Adequações jurídicas
    row.push(""); // Controle de acessos
    row.push(process.ROPAanswers[41] && process.ROPAanswers[41].dado ? process.ROPAanswers[41].dado : ''); // Autenticação
    row.push(""); // Classificação dos dados
    row.push(process.ROPAanswers[43] && process.ROPAanswers[43].dado ? process.ROPAanswers[43].dado : ''); // Medidas Técnicas de Segurança
    row.push(""); // Cópia segura
    row.push(process.ROPAanswers[45] && process.ROPAanswers[45].dado ? process.ROPAanswers[45].dado : ''); // Indicar o país?
    row.push(""); // Este processo pode causar impacto ao negócio se ficar indisponível
    row.push(""); // Rara, Baixa, Média, Quase Certa, Alta
    row.push(""); // Insignificante, Pequeno, Médio, Grande, Muito Grande
    row.push(""); // De acordo com os padrões LGPD
    row.push(""); // Indicar a última auditoria
    row.push(""); // Indicar a última auditoria e guardar evidências
    row.push(process.ROPAanswers[52] && process.ROPAanswers[52].dado ? process.ROPAanswers[52].dado : ''); // Indicar o país?

    data.push(row);

  }
  console.log(data)
  return data;
}