import myApp from "../../core/infrastructure/firebaseConfig";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

export async function passwordIsCorrect(login, pass) {
  try {
    const secondaryApp = firebase.initializeApp(config, "Secondary");
    const user = await secondaryApp.auth().signInWithEmailAndPassword(login, pass);
		return true;
  } catch (e) {
    if (e.code === "auth/wrong-password") {
      return false;
    }
  }
}
