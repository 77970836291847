import myApp from '../../core/infrastructure/firebaseConfig'

const log = myApp.functions().httpsCallable('log');

const Log = () => {
  let user = myApp.auth().currentUser ?? null

  return {
    info: async ({ trace, message, info = null }) => await log({ trace, message, user, level: 'info', info }),
    warning: async ({ trace, message, info = null }) => await log({ trace, message, user, level: 'warning', info }),
    debug: async ({ trace, message, info = null }) => await log({ trace, message, user, level: 'debug', info }),
    error: async ({ trace, message, info = null }) => await log({ trace, message, user, level: 'error', info }),
  }
}

export default Log