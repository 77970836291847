import React, { Component } from "react";
import myApp from "../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";

import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  CssBaseline,
  Avatar,
  CircularProgress,
  Dialog,
  DialogTitle,
  ListItemAvatar,
  Hidden,
  Select,
  MenuItem,
  FormControl,
  Grid,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { loginListener } from "../../../login/commands/loginListener";
import { logout } from "../../../login/commands/logout";
import { getUserInformation } from "../../command/getUserInformation";

import "./dashboard.css";
import { checkUser } from "../../../login/commands/checkUser";
import routerHistory from "../../../core/infrastructure/routerHistory";
import { connect } from "react-redux";
import { changePage } from "../redux/actions";

import dashboardLogo from "../../../res/images/dashboard_logo.png";
import { getPermitions } from "../componentList";

class DashboardProcess extends Component {
  constructor(props) {
    super(props);

    this.db = myApp.firestore();

    this.state = {
      userInformation: null,
      mobileDrawer: false,
      menu: false,
      selectedComponent: 5,
      PageList:[],
      companies: [],
      currentCompany: "",
    };
  }

  componentDidMount() {
    loginListener();

    var self = this;
this.handlePermitions()
    checkUser(function () {
      self._getUserInformation();
    });

    this.getCompanies();
  }

  async handlePermitions(){
    const Permitions = await getPermitions()

    this.setState({
      PageList:Permitions
    })
   

  }


  componentDidUpdate(prevProps) {
    if (prevProps.page !== this.props.page) {
      this.selectComponent(this.props.page);
    }
  }

  async _getUserInformation() {
    this.setState({
      userInformation: await getUserInformation(),
    });
  }

  handleDrawer() {
    this.setState({
      mobileDrawer: !this.state.mobileDrawer,
    });
  }

  handleMenu() {
    this.setState({
      menu: !this.state.menu,
    });
  }

  selectComponent(index) {
    this.setState(
      {
        selectedComponent: index,
      },
      () => {
        this.handleReducer();
        this.handleDrawer();
      }
    );
  }

  handleReducer() {
    this.props.changePage(this.state.selectedComponent);
  }

  async getCompanies() {
    let database = myApp.firestore();
    let companyGroup = localStorage.getItem("@blue-legal/companyGroup");

    var _company = localStorage.getItem("currentCompany");

    let self = this;

    try {
      let data = await database
        .collection("companies")
        .where("active", "==", true)
        .where("fk_company_group", "==", companyGroup)
        .get();
      let _companies = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

      self.setState({
        companies: _companies,
        currentCompany: _company,
      });
    } catch (error) {
      console.error(error);
    }
  }

  handleCompany(event) {
    this.setState({
      currentCompany: event.target.value,
    });

    localStorage.setItem("currentCompany", event.target.value);

    routerHistory.push("/company");
  }

  render() {
    if (this.state.userInformation !== null) {
      sessionStorage.setItem(
        "@blue-legal/userInformation",
        JSON.stringify(this.state.userInformation)
      );
      if (!this.state.PageList[this.state.selectedComponent].fullScreen) {
        return (
          <div className="root">
            <CssBaseline />
            <AppBar
              position="fixed"
              className="appBar"
              style={{ background: "#111C39" }}
              elevation={0}
            >
              <Toolbar style={{ padding: "0px" }}>
                <Hidden lgUp>
                  <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    edge="start"
                    onClick={() => this.handleDrawer()}
                    className="menuButton"
                    style={{ color: "#F26B36", paddingLeft: "24px" }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <div className="spacer" />
                  <div style={{ paddingRight: "16px" }}>
                    <img
                      alt="logo"
                      src={dashboardLogo}
                      style={{ height: "42px", paddingTop: "4px" }}
                    />
                  </div>
                </Hidden>
                <Hidden mdDown>
                  <div
                    style={{
                      width: "264px",
                      background: "#F26B36",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        edge="start"
                        className="menuButton"
                        onClick={() => {
                          this.handleMenu();
                        }}
                      >
                        {this.state.userInformation ? (
                          // <Avatar
                          //   alt="Remy Sharp"
                          //   src={this.state.userInformation.photoURL}
                          // />
                          <Avatar
                            alt="Remy Sharp"
                            src={localStorage.getItem("userPhoto")}
                          />
                        ) : (
                          <Avatar>
                            <CircularProgress className="avatarLoadingCircle" />
                            
                          </Avatar>
                          
                        )}
                      </IconButton>
                      <FormControl
                        style={{ display: "contents", alignItems: "center" }}
                      >
                        <Select
                          style={{ color: "white", width: "184px" }}
                          value={this.state.currentCompany}
                          onChange={(event) => {
                            this.handleCompany(event);
                          }}
                        >
                          {this.state.companies.map((company) => (
                            <MenuItem
                              style={{ color: "#111C39" }}
                              value={company.id}
                            >
                              {company.nickname}
                           
                            </MenuItem>
                          ))}
                          <div
                            style={{
                              height: "2px",
                              listStylePosition: "inside",
                              borderTop: "1px dashed #F26B36",
                              margin: "4px",
                            }}
                          />
                          <MenuItem style={{ color: "#111C39" }} value={""}>
                            Voltar
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="spacer" />
                  <div style={{ paddingRight: "16px" }}>
                    <img
                      alt="logo"
                      src={dashboardLogo}
                      style={{ height: "42px", paddingTop: "4px" }}
                    />
                  </div>
                </Hidden>
              </Toolbar>
            </AppBar>

            <Dialog
              onClose={() => {
                this.handleMenu();
              }}
              open={this.state.menu}
            >
              <DialogTitle>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                >
                  <Grid item>
                    <Typography>Configurações</Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={() => this.handleMenu()}>
                      <Close />
                    </IconButton>
                  </Grid>
                </Grid>
              </DialogTitle>
              <List>
                <ListItem autoFocus button onClick={() => logout()}>
                  <ListItemAvatar>
                    <Avatar>
                      <ExitToAppIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Sair" />
                </ListItem>
              </List>
            </Dialog>

            <nav className="drawer">
              <Hidden lgUp>
                <Drawer
                  variant="temporary"
                  open={this.state.mobileDrawer}
                  onClose={() => this.handleDrawer()}
                  ModalProps={{
                    keepMounted: true,
                  }}
                  classes={{
                    paper: "drawerPaper",
                  }}
                >
                  <div
                    style={{
                      width: "264px",
                      background: "#F26B36",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        edge="start"
                        className="menuButton"
                        onClick={() => {
                          this.handleMenu();
                        }}
                      >
                        {this.state.userInformation ? (
                          // <Avatar
                          //   alt="Remy Sharp"
                          //   src={this.state.userInformation.photoURL}
                          // />
                          <Avatar
                            alt="Remy Sharp"
                            src={localStorage.getItem("userPhoto")}
                          />
                        ) : (
                          <Avatar>
                            <CircularProgress className="avatarLoadingCircle" />
                          </Avatar>
                        )}
                      </IconButton>
                      <FormControl
                        style={{ display: "contents", alignItems: "center" }}
                      >
                        <Select
                          style={{ color: "white", width: "184px" }}
                          value={this.state.currentCompany}
                          onChange={(event) => {
                            this.handleCompany(event);
                          }}
                        >
                          {this.state.companies.map((company) => (
                            <MenuItem
                              style={{ color: "#111C39" }}
                              value={company.id}
                            >
                              {company.nickname}
                            </MenuItem>
                          ))}
                          <div
                            style={{
                              height: "2px",
                              listStylePosition: "inside",
                              borderTop: "1px dashed #F26B36",
                              margin: "4px",
                            }}
                          />
                          <MenuItem style={{ color: "#111C39" }} value={""}>
                            Voltar
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <List>
                    {this.state.PageList?.map((component, index) =>
                      component.showOnSideBar ||
                      (component.name === "Treinamento" &&
                        this.state.currentCompany ===
                          "DfrkIyi0Jrrn9XFDDIrh") ? (
                        <ListItem
                          button
                          onClick={() => this.selectComponent(index)}
                        >
                          <component.icon
                            style={
                              this.state.selectedComponent === index
                                ? { color: "#F26B36" }
                                : { color: "white" }
                            }
                          />
                          <div style={{ width: "10px" }} />
                          <ListItemText
                            primary={component.name}
                            style={
                              this.state.selectedComponent === index
                                ? { color: "#F26B36" }
                                : { color: "white" }
                            }
                          />
                        </ListItem>
                      ) : (
                        <div />
                      )
                    )}
                  </List>
                </Drawer>
              </Hidden>
              <Hidden mdDown>
                <Drawer
                  className="drawer"
                  variant="permanent"
                  classes={{
                    paper: "drawerPaper",
                  }}
                >
                  <Toolbar />
                  <div className="drawerContainer">
                    <List>
                      {this.state.PageList?.map((component, index) =>
                        component.showOnSideBar ||
                        (component.name === "Treinamento" &&
                          this.state.currentCompany ===
                            "DfrkIyi0Jrrn9XFDDIrh") ? (
                          <ListItem
                            button
                            onClick={() => this.selectComponent(index)}
                          >
                            {
                              <component.icon
                                style={
                                  this.state.selectedComponent === index
                                    ? { color: "#F26B36" }
                                    : { color: "white" }
                                }
                              />
                            }
                            <div style={{ width: "10px" }} />
                            <ListItemText
                              primary={component.name}
                              style={
                                this.state.selectedComponent === index
                                  ? { color: "#F26B36" }
                                  : { color: "white" }
                              }
                            />
                          </ListItem>
                        ) : (
                          <div />
                        )
                      )}
                    </List>
                  </div>
                </Drawer>
              </Hidden>
            </nav>

            <Hidden lgUp>
              <div className="content" style={{ height: "100%" }}>
                {this.state.PageList[this.state.selectedComponent].component}
              </div>
            </Hidden>
            <Hidden mdDown>
              <div
                className="content contentPadding"
                style={{ height: "100%" }}
              >
                {this.state.PageList[this.state.selectedComponent].component}
              </div>
            </Hidden>
          </div>
        );
      } else {
        return (
          <div>{this.state.PageList[this.state.selectedComponent].component}</div>
        );
      }
    } else {
      return <div />;
    }
  }
}

function mapStateToProps(state) {
  return {
    page: state.dashboardReducer.page,
  };
}

export default connect(mapStateToProps, { changePage })(DashboardProcess);
