import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import myApp from "../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Button,
  Typography,
} from "@material-ui/core";

import {
  getLIAProcesses,
  getLIAQuestions,
  getProcessReportIsAvailable,
} from "./functions";
import theme from "../../../core/theme.json";
import { changePage } from "../../presentation/redux/actions";
import { setProcessId } from "../process/redux/actions";

const LIATesting = () => {
  const dispatch = useDispatch();

  const database = useMemo(() => myApp.firestore(), []);
  const [questionsAmount, setQuestionsAmount] = useState(0);
  const [answeredArt7processes, setAnsweredArt7processes] = useState([]);
  const [unansweredArt7processes, setUnansweredArt7processes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadLIAProcesses = async () => {
      setLoading(true);
      try {
        const filteredArt7Processes = await getLIAProcesses();

        const answeredArt7processes = filteredArt7Processes.filter(
          (process) => process.answers?.length === questionsAmount
        );
        const unansweredArt7processes = filteredArt7Processes.filter(
          (process) => process.answers?.length !== questionsAmount
        );
        setAnsweredArt7processes(answeredArt7processes);
        setUnansweredArt7processes(unansweredArt7processes);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    loadLIAProcesses();
  }, [database, questionsAmount]);

  useEffect(() => {
    const loadLIAQuestions = async () => {
      setLoading(true);
      try {
        const LIAQuestions = await getLIAQuestions();
        setQuestionsAmount(LIAQuestions.length);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    loadLIAQuestions();
  }, [database]);

  const handlePerformTestClick = (processId) => {
    dispatch(setProcessId(processId));
    dispatch(changePage(11));
  };

  function renderCells(process, index, isAnswered = false) {
    const questionsAnsweredAmount = process.answers?.length || 0;
    return (
      <TableRow key={process.id} style={index % 2 ? { background: "white" } : { background: "#e3e3e3" }}>
        <TableCell>{process.areaName}</TableCell>
        <TableCell>{process.name}</TableCell>
        <TableCell>{process.description}</TableCell>
        <TableCell>{process.goal}</TableCell>
        <TableCell>{process.operator}</TableCell>
        <TableCell>{process.owner}</TableCell>
        <TableCell>
          {questionsAnsweredAmount === questionsAmount
            ? "Respondido"
            : "Pendente"}
        </TableCell>
        <TableCell>
          <Button
            color="primary"
            backgroundColor="black"
            style={{
              background: theme.primary_color,
              color: theme.secondary_color,
            }}
            onClick={() => handlePerformTestClick(process.id)}
          >
            ABRIR TESTE
          </Button>
        </TableCell>
        {isAnswered === true && (
          <TableCell>
            {getProcessReportIsAvailable(process, questionsAmount)
              ? "SIM"
              : "NÃO"}
          </TableCell>
        )}
      </TableRow>
    );
  }

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "50px",
          }}
        >
          <CircularProgress color={theme.secondary_color} />
        </div>
      ) : (
        <div>
          <Typography style={{ margin: "20px 5px" }} variant="h5">
            Pendentes
          </Typography>
          <TableContainer component={Paper} style={{ maxHeight: "70vh" }}>
            <Table stickyHeader>
              <colgroup>
                <col style={{ width: "15%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell>ÁREA</TableCell>
                  <TableCell>PROCESSO</TableCell>
                  <TableCell>DESCRIÇÃO DO PROCESSO</TableCell>
                  <TableCell>FINALIDADE</TableCell>
                  <TableCell>ATUAÇÃO</TableCell>
                  <TableCell>RESPONSÁVEL</TableCell>
                  <TableCell>STATUS</TableCell>
                  <TableCell>RESPONDER</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {unansweredArt7processes.map((process, index) =>
                  renderCells(process, index)
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography style={{ margin: "20px 5px" }} variant="h5">
            Respondidos
          </Typography>
          <TableContainer component={Paper} style={{ maxHeight: "70vh" }}>
            <Table stickyHeader>
              <colgroup>
                <col style={{ width: "15%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "5%" }} />
                <col style={{ width: "5%" }} />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell>ÁREA</TableCell>
                  <TableCell>PROCESSO</TableCell>
                  <TableCell>DESCRIÇÃO DO PROCESSO</TableCell>
                  <TableCell>FINALIDADE</TableCell>
                  <TableCell>ATUAÇÃO</TableCell>
                  <TableCell>RESPONSÁVEL</TableCell>
                  <TableCell>STATUS</TableCell>
                  <TableCell>RESPONDER</TableCell>
                  <TableCell>APROVADO</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {answeredArt7processes.map((process, index) =>
                  renderCells(process, index, true)
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </>
  );
};

export default LIATesting;
