import React, { useEffect, useState } from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { changePage } from "../../../presentation/redux/actions";
import { useDispatch } from "react-redux";

import {
  Page,
  Text,
  View,
  Document,
  PDFViewer,
  Link,
} from "@react-pdf/renderer";

import { style } from "../../denuncias/components/PdfCss";
//import redux
import { connect } from "react-redux";
import "firebase/compat/firestore";

// const handleFormatDate = (date) => {
//   if (date) {
//     let data = new Date(date);
//     let dia = String(data.getDate()).padStart(2, "0");
//     let mes = String(data.getMonth() + 1).padStart(2, "0");
//     let ano = data.getFullYear();
//     let dataAtual = dia + "/" + mes + "/" + ano;
//     return dataAtual;
//   } else {
//     return "";
//   }
// };

const ViewPdfReportVideo = (props) => {
  //variaveis auxiliares
  const dispatch = useDispatch();
  console.log("oq vem no obj de props", props.reportObj);

  //gerando pdf
  const pdfGenerator = () => {
    return (
      <Document>
        <Page size="A4">
          <View>
            <Text style={[style.textcenter, { fontSize: 32 }]}>
              Relatório do Teste
            </Text>
            <Text
              style={[style.textcenter, { fontSize: 36, marginTop: "-5%" }]}
            >
              {" "}
              {props.reportObj.nameOfQuestion}
            </Text>

            <View style={{ marginTop: "10%", marginHorizontal: "4%" }}>
              <View style={style.between}>
                <Text> E-mail do usuário :</Text>
                <Text>{props.reportObj.email}</Text>
              </View>

              <View style={style.between}>
                <Text> Data :</Text>
                <Text>
                  {new Intl.DateTimeFormat("pt-BR").format(
                    props.reportObj.date
                  )}
                </Text>
              </View>

              <View style={style.between}>
                <Text> Módulo :</Text>
                <Text>{props.reportObj.nameModule}</Text>
              </View>

              <View style={style.between}>
                <Text> Questionário :</Text>
                <Text>{props.reportObj.nameOfQuestion}</Text>
              </View>

              <View style={style.between}>
                <Text> Tentativas :</Text>
                <Text>{props.reportObj.tentatives}</Text>
              </View>
            </View>

            <View style={{ marginTop: "10%", marginHorizontal: "4%" }}>
              {props.reportObj.answers.map((item, index) => {
                return (
                  <>
                     <View>
                    <Text   style={[style.textcenter, { fontSize: 32, marginBottom: "5%" }]}>Tentativa {index + 1}</Text>
                          </View>

                    {item.answers.map((answers, index) => {
                      return (
                        <>
                          <Text>
                            Questão {index + 1} ) : {answers.question} ?
                          </Text>
                          <View
                            style={{
                              marginTop: "5%",
                              display: "flex",
                              flexDirection: "row",
                              marginBottom: "5%",
                            }}
                          >
                            <Text style={{ opacity: 0.5 }}> Resposta : </Text>
                            <Text
                              style={
                                answers.correct == true
                                  ? { color: "#32CD32" }
                                  : { color: "#B22222" }
                              }
                            >
                              {answers.answer}
                            </Text>
                          </View>
                        </>
                      );
                    })}
                  </>
                );
              })}
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  // mudando pagina com redux
  const handleChangePage = () => {
    dispatch(changePage(45));
  };

  return (
    <Grid container>
      <Grid
        container
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "5px",
        }}
      >
        <Grid item>
          <Button
            variant="contained"
            style={{ background: "none", marginRight: "50px" }}
            onClick={() => handleChangePage()}
          >
            <ArrowBackIcon />
          </Button>
        </Grid>
        <Grid item>
          <Typography variant="h4" component="h4" style={{ fontWeight: "500" }}>
            Visualizar / baixar em PDF
          </Typography>
        </Grid>
      </Grid>
      {
        <PDFViewer width="100%" height="800px">
          {pdfGenerator()}
        </PDFViewer>
      }
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    reportObj: state.ViewsPdfReportVideoReducer.reportObj,
  };
};

export default connect(mapStateToProps, { changePage })(ViewPdfReportVideo);
