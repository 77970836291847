import React, { Component } from "react";
import myApp from "../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Paper, Grid, Typography, ButtonBase, Dialog, DialogContent, Stepper, Step, StepLabel, Button, IconButton, Card, CardActionArea } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";

import CompanyRegistrationDPO from "./companyRegistrationDPO";
import CompanyRegistrationPlan from "./companyRegistrationPlan";
import CompanyRegistrationInfo from "./companyRegistrationInfo";
import CompanyRegistrationArea from "./companyRegistrationArea";

import { addCompanyInfo, addDpoInfo } from "../redux/actions";

import theme from "../../core/theme.json";

const styles = () => ({
  previousButton: {
    fontSize: theme.font_button,
    fontWeight: 300,
    marginRight: 16,
  }
});

class CompanyObject {
  nickName = "";
  name = "";
  porteEmpresa = "";
  document = "";
  validDocument = true;
  companyStreet = "";
  companyNumber = "";
  companyComplement = "";
  companyCity = "";
  companyNeighborhood = "";
  companyState = "";
  companyCEP = "";
}

class DpoObject {
  name = "";
  dpoCnpj = "";
  dpoEmail = "";
  phone = "";
  isCompanyEmployee = true;
  dpoCompanyCnpj = "";
  dpoCompanyName = "";
  dpoStreet = "";
  dpoNumber = "";
  dpoComplement = "";
  dpoCity = "";
  dpoNeighborhood = "";
  dpoState = "";
  dpoCEP = "";
}

class AddCompany extends Component {
  constructor(props) {
    super(props);

    this.db = myApp.firestore();

    this.state = {
      dialog: false,
      step: 0,
      disabled: true
    }
  }

  static getDerivedStateFromProps(props, state) {
    console.log("props", props);
    console.log("state", state);
    // console.log("props.dpoNickName", props.dpoNickName);
    // console.log("props.companySizy", props.companySize);
    // console.log("props.dpoName", props.dpoName);
    // console.log("props.dpoDocument", props.dpoDocument);
    // console.log("props.dpoValidDocument", props.dpoValidDocument);
    // console.log("props.dpoPhone", props.dpoPhone);
    // console.log("props.dpoName", props.dpoName);

    if (state.step === 0) {
      if (props.companyName !== "" && props.companyNickName !== ""
        && props.companyDocument !== "" && props.companyDocument !== "__.___.___/____-__" && props.companyValidDocument) {
        return {
          disabled: false
        }
      }
      else {
        return {
          disabled: true
        }
      }
    }
    else {
      if (state.step === 1) {
        if (props.dpoPhone !== "" && props.dpoName !== "") {
          return {
            disabled: false
          }
        }
        else {
          return {
            disabled: true
          }
        }
      }
    }
  }

  handleDialog() {
    this.setState({
      dialog: !this.state.dialog,
      step: 0,
    })

    if (!this.state.dialog) {
      this.handleRedux();
    }
  }

  handleRedux() {
    var object = new DpoObject();

    object.name = "";
    object.dpoCnpj = "";
    object.dpoEmail = "";
    object.phone = "";
    object.isCompanyEmployee = true;
    object.dpoCompanyCnpj = "";
    object.dpoCompanyName = "";
    object.dpoStreet = "";
    object.dpoNumber = "";
    object.dpoComplement = "";
    object.dpoCity = "";
    object.dpoNeighborhood = "";
    object.dpoState = "";
    object.dpoCEP = "";

    this.props.addDpoInfo(object);

    var objectC = new CompanyObject();

    objectC.nickName = "";
    objectC.name = "";
    objectC.porteEmpresa = "";
    objectC.document = "";
    objectC.validDocument = "";
    objectC.street = "";
    objectC.number = "";
    objectC.complement = "";
    objectC.city = "";
    objectC.neighborhood = "";
    objectC.state = "";
    objectC.CEP = "";

    this.props.addCompanyInfo(objectC);
  }

  getStepContent() {
    switch (this.state.step) {
      case 0:
        return <CompanyRegistrationInfo />;
      case 1:
        return <CompanyRegistrationDPO />;
      case 2:
        return <CompanyRegistrationPlan />;
      case 3:
        return <CompanyRegistrationArea />;
      default:
        return <div>"Unknown step"</div>;
    }
  }

  previousStep() {
    const { step } = this.state;

    if (step > 0) {
      this.setState({
        step: step - 1,
        disabled: false
      });
    }
  }

  nextStep() {
    if (this.state.step > 2) {
      this.addCompany();

      this.setState({
        dialog: false,
        step: 0
      });
    }
    else if (this.state.step > 0) {
      this.setState({
        step: this.state.step + 1
      });
    }
    else {
      this.setState({
        step: this.state.step + 1,
        disabled: true
      });
    }
  }

  async insertInFirebaseCollection(collection, obj) {
    let database = myApp.firestore();

    try {
      var _db = await database.collection(collection).add(obj);

    }
    catch (error) {
      console.error(error);
    }
  }

  async addCompany() {
    let database = myApp.firestore();
    var _userId = localStorage.getItem("currentUser");
    var companyGroup = localStorage.getItem("@blue-legal/companyGroup");

    try {
      var _db = await database.collection("companies").add(
        {
          nome: this.props.companyName,
          cnpj: this.props.companyDocument,
          nickname: this.props.companyNickName,
          companySize: this.props.companySize,
          companyStreet: this.props.companyStreet,
          companyNumber: this.props.companyNumber,
          companyComplement: this.props.companyComplement,
          companyCity: this.props.companyCity,
          companyNeighborhood: this.props.companyNeighborhood,
          companyState: this.props.companyState,
          companyCEP: this.props.companyCEP,
          dpo: this.props.dpoName,
          dpo_cnpj: this.props.dpoCnpj,
          dpo_email: this.props.dpoEmail,
          dpo_phone: this.props.dpoPhone,
          dpo_isCompanyEmployee: this.props.dpoIsCompanyEmployee,
          dpo_dpoCompanyCnpj: this.props.dpoCompanyCnpj,
          dpo_dpoCompanyName: this.props.dpoCompanyName,
          dpo_street: this.props.dpoStreet,
          dpo_number: this.props.dpoNumber,
          dpo_complement: this.props.dpoComplement,
          dpo_city: this.props.dpoCity,
          dpo_neighborhood: this.props.dpoNeighborhood,
          dpo_state: this.props.dpoState,
          dpo_cep: this.props.dpoCEP,
          fk_user_id: _userId,
          fk_company_group: companyGroup,
          active: true,
          epm_db_discovery_id: "",
          epm_fb_id: "",
          epm_file_discovery_id: "",
          url_files: "",
          url_db: "",
        }
      );

      this.addAreas(_db.id);
      this.addActivities(_db.id);
      // this.addDefaultLegalBasisList(_db.id)
    }
    catch (error) {
      console.error(error);
    }
  }

  addAreas(companyId) {
    let database = myApp.firestore();

    this.props.areas.map((area) => {
      try {
        database.collection("areas").add(
          {
            name: area.name,
            owner: area.owner,
            icon: area.icon,
            fk_companies: companyId,
            active: true
          }
        );
      }
      catch (error) {
        console.error(error);
      }
    });
  }

  addActivities(companyId) {
    let database = myApp.firestore();

    for (var i = 0; i < this.props.plans.length; i++) {
      for (var j = 0; j < this.props.plans[i].planActivities.length; j++) {
        if (this.props.plans[i].planActivities[j].isChecked) {
          try {
            database.collection("activities").add(
              {
                name: this.props.plans[i].planActivities[j].name,
                owner: this.props.plans[i].planActivities[j].owner,
                date: this.props.plans[i].planActivities[j].date,
                description: this.props.plans[i].planActivities[j].description,
                file_uri: "",
                done: false,
                fk_companies: companyId,
                fk_plan_type: this.props.plans[i].planActivities[j].fkPlanType,
                fk_initial_activities: this.props.plans[i].planActivities[j].fkInitialActivities,
                companyActivity: true
              }
            );
          }
          catch (error) {
            console.error(error);
          }
        }
      }
    }
  }

  async addDefaultLegalBasisList(id) {
    let database = myApp.firestore();

    try {
      let legalBasisData = await database.collection("default_legal_basis").get();
      let legalBasisList = [];
      legalBasisData.docs.forEach(doc => {
        let lb = doc.data()
        lb["id"] = doc.id;
        legalBasisList.push(lb)
      })

      if (legalBasisList.length > 0) {
        legalBasisList.map((legal_basis) => {
          try {
            database.collection("legal_basis").add(
              {
                name: legal_basis.name,
                description: legal_basis.description,
                legalJustification: legal_basis.legalJustification,
                fk_company: id
              }
            );
          }
          catch (error) {
            console.error(error);
          }
        });
      }
    } catch (e) {
      console.log("Error: " + e);
    }
  }

  render() {
    return (
      <div>
        <Grid
          container
          direction="row"
          spacing={4}
          style={{ height: "180px" }}
        >
          <Grid item style={{ width: "100%", height: "100%" }}>
            <ButtonBase onClick={() => { this.handleDialog() }} style={{ width: "100%", height: "100%", borderRadius: "8px" }}>
              <Card style={{
                width: "100%", height: "100%", borderRadius: "8px", boxShadow: "0 8px 10px -6px rgba(0,0,0,0.3)",
                "&:hover": {
                  boxShadow: "0 6px 20px -6.125px rgba(0,0,0,0.3)"
                },
              }}>
                <CardActionArea style={{ height: '100%', width: '100%' }}>
                  <div style={{ position: 'absolute', height: '100%', width: '10px', backgroundColor: '#c1c1c1' }}></div>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    justifyContent="center"
                    alignItems="center"
                    style={{ width: "100%", height: "100%" }}
                  >
                    <Grid item container justify="center" alignItems="center" style={{ width: "100%", height: "50%" }}>
                      <Grid item>
                        <AddIcon style={{ fontSize: 76, color: theme.secondary_color }} />
                      </Grid>
                    </Grid>
                    <Grid item container justify="center" style={{ width: "100%", height: "50%" }}>
                      <Grid
                        item
                        container
                        justify="center"
                        style={{ width: "100%", height: "90%", overflowY: "hidden", textOverflow: "ellipsis", margin: "0 10px 0 10px" }}
                      >
                        <Typography variant="h6" component="h6" style={{ overflowWrap: "anywhere", color: theme.secondary_color }}>
                          Adicionar nova empresa
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardActionArea>
              </Card>
            </ButtonBase>
          </Grid>
        </Grid>

        <Dialog
          open={this.state.dialog}
          fullWidth={true}
          maxWidth={"lg"}
          onClose={() => { this.handleDialog() }}
        >
          <DialogContent>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item container justify="flex-end">
                <IconButton onClick={() => this.handleDialog()}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item style={{ flexWrap: "wrap" }}>
                <Typography variant="h5" component="h5" style={{ paddingTop: "8px", marginTop: "16px", color: theme.tertiary_color, textAlign: "center", overflowWrap: "anywhere" }}>
                  {this.state.step > 0 ? this.props.companyNickName : "Adicionar nova empresa"}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" component="h6" style={{ padding: "8px", color: theme.tertiary_color, textAlign: "center", overflowWrap: "anywhere" }}>
                  {this.state.step > 1 ? this.props.dpoNickName : ""}
                </Typography>
              </Grid>
              <Grid item style={{ width: "100%" }} xs={12} sm={6} md={4} lg={2}>
                <div style={{ height: "8px", width: "100%", background: theme.secondary_color, borderRadius: "4px" }} />
              </Grid>
              <Grid item style={{ height: "20px" }} />
              <Grid item>
                <Typography variant="subtitle1" component="subtitle1" style={{ color: theme.tertiary_color, textAlign: "center", }}>
                  Preencha as informações referentes à empresa
                </Typography>
              </Grid>
              <Grid item style={{ width: "80%" }}>
                <Stepper activeStep={this.state.step}>
                  <Step key={0}>
                    <StepLabel StepIconComponent={"empty"}>
                      <div style={{ background: theme.primary_color, color: "white", padding: "8px", borderRadius: "4px" }}>
                        <Typography variant="body1" component="body1" style={{ color: theme.secondary_color, textAlign: "center", }}>
                          1.Informações da Empresa
                        </Typography>
                      </div>
                    </StepLabel>
                  </Step>
                  <Step key={1}>
                    <StepLabel StepIconComponent={"empty"}>
                      <div style={{ background: this.state.step > 0 ? theme.primary_color : "#91ff79", padding: "8px", borderRadius: "4px" }}>
                        <Typography variant="body1" component="body1" style={{ color: this.state.step > 0 ? theme.secondary_color : theme.tertiary_color, textAlign: "center", }}>
                          2.Informações DPO
                        </Typography>
                      </div>
                    </StepLabel>
                  </Step>
                  <Step key={2}>
                    <StepLabel StepIconComponent={"empty"}>
                      <div style={{ background: this.state.step > 1 ? theme.primary_color : "#91ff79", padding: "8px", borderRadius: "4px" }}>
                        <Typography variant="body1" component="body1" style={{ color: this.state.step > 1 ? theme.secondary_color : theme.tertiary_color, textAlign: "center", }}>
                          3.Plano de Atuação/Cronograma
                        </Typography>
                      </div>
                    </StepLabel>
                  </Step>
                  <Step key={3}>
                    <StepLabel StepIconComponent={"empty"}>
                      <div style={{ background: this.state.step > 2 ? theme.primary_color : "#91ff79", padding: "8px", borderRadius: "4px" }}>
                        <Typography variant="body1" component="body1" style={{ color: this.state.step > 2 ? theme.secondary_color : theme.tertiary_color, textAlign: "center", }}>
                          4.Áreas
                        </Typography>
                      </div>
                    </StepLabel>
                  </Step>
                </Stepper>
              </Grid>
              <Grid item style={{ width: "100%", padding: "36px" }}>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="flex-end"
                  spacing={4}
                >
                  <Grid item style={{ width: "100%" }}>
                    {this.getStepContent()}
                  </Grid>
                  <Grid item>
                    {this.state.step > 0 && (
                      <Button
                        variant="contained"
                        classes={{ root: this.props.classes.previousButton }}
                        onClick={() => { this.previousStep() }}
                        style={
                          {
                            borderColor: theme.primary_color,
                            color: theme.secondary_color,
                            fontSize: theme.font_button,
                            fontWeight: 300,
                          }
                        }
                      >
                        {"Voltar"}
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      style={{ background: this.state.disabled ? "#91ff79" : theme.primary_color, color: theme.secondary_color, fontSize: theme.font_button, fontWeight: 300 }}
                      onClick={() => { this.nextStep() }}
                      disabled={this.state.disabled}
                    >
                      {this.state.step > 2 ? "Finalizar" : "Próximo"}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    companyNickName: state.companyReducer.companyNickName,
    companyName: state.companyReducer.companyName,
    companySize: state.companyReducer.companySize,
    companyDocument: state.companyReducer.companyDocument,
    companyStreet: state.companyReducer.companyStreet,
    companyNumber: state.companyReducer.companyNumber,
    companyComplement: state.companyReducer.companyComplement,
    companyCity: state.companyReducer.companyCity,
    companyNeighborhood: state.companyReducer.companyNeighborhood,
    companyState: state.companyReducer.companyState,
    companyCEP: state.companyReducer.companyCEP,
    companyValidDocument: state.companyReducer.companyValidDocument,

    dpoName: state.companyReducer.dpoName,
    dpoEmail: state.companyReducer.dpoEmail,
    dpoCnpj: state.companyReducer.dpoCnpj,
    dpoPhone: state.companyReducer.dpoPhone,

    dpoIsCompanyEmployee: state.companyReducer.dpoIsCompanyEmployee,
    dpoCompanyName: state.companyReducer.dpoCompanyName,
    dpoCompanyCnpj: state.companyReducer.dpoCompanyCnpj,
    dpoStreet: state.companyReducer.dpoStreet,
    dpoNumber: state.companyReducer.dpoNumber,
    dpoComplement: state.companyReducer.dpoComplement,
    dpoCity: state.companyReducer.dpoCity,
    dpoNeighborhood: state.companyReducer.dpoNeighborhood,
    dpoState: state.companyReducer.dpoState,
    dpoCEP: state.companyReducer.dpoCEP,

    plans: state.companyReducer.plans,
    areas: state.companyReducer.areas,
  };
}

AddCompany.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { addCompanyInfo, addDpoInfo })(withStyles(styles)(AddCompany));