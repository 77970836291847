import { 
  ADD_QUIZ,
  SAVE_QUIZ,
  ADD_QUESTION,
  ADD_ANSWER,
  QUIZ_DATABASE_ID,
  OPEN_QUIZ,
  SET_QUIZ,
  IDS_QUESTIONS_INSERTED,
  SET_LOADING_QUIZ,
  QUIZ_ID
} from "./types";

export const addQuiz = data => ({
  type: ADD_QUIZ,
  data
});

export const saveQuiz = data => ({
  type: SAVE_QUIZ,
  data
});

export const addQuestion = data => ({
  type: ADD_QUESTION,
  data
})

export const addAnswer = data => ({
  type: ADD_ANSWER,
  data
});

export const getQuestionDatabaseId = data => ({
  type: QUIZ_DATABASE_ID,
  data
});

export const setOpenQuiz = data => ({
  type: OPEN_QUIZ,
  data
});

export const setQuiz = data => ({
  type: SET_QUIZ,
  data
});

export const setIdQuestionsInserted = data => ({
  type: IDS_QUESTIONS_INSERTED,
  data
});

export const setLoadingQuiz = data => ({
  type: SET_LOADING_QUIZ,
  data
});
export const setQuizId = data => ({
  type: QUIZ_ID,
  data
});