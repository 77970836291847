import myApp from "../../core/infrastructure/firebaseConfig";
import "firebase/compat/functions";

const sendEmail = myApp.functions().httpsCallable('sendEmail');

export async function sendResetPasswordMail(user) {
	try {
		let requestObject = {
			from: `${process.env.REACT_APP_FROM_NAME} <${process.env.REACT_APP_FROM_EMAIL}>`,
			email: user.email,
			name: "",
			subject: "Redefinição de senha usuário Beeprivacy",
			message:
				`Olá!<br><br>` +
				`Você solicitou uma redefinição de senha para a Beeprivacy.<br><br>` +
				`Acesse https://beeprivacy.com.br${process.env.PUBLIC_URL}/#/reset-password/${user.id} para completar sua requisição.<br><br>` +
				`<b>Não responda a esta mensagem. E-mails enviados para este endereço não podem ser respondidos.</b>`,
		}

		await sendEmail(requestObject);
	} catch (error) {
		console.error("Error sending mail to new user:", error);
	}
}