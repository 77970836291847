import { ADD_COMPANY_INFO, ADD_DPO_INFO, ADD_PLANS, ADD_AREAS } from './types';

export const addCompanyInfo = data => ({
  type: ADD_COMPANY_INFO,
  data
});

export const addDpoInfo = data => ({
  type: ADD_DPO_INFO,
  data
});

export const addPlans = data => ({
  type: ADD_PLANS,
  data
});

export const addAreas = data => ({
  type: ADD_AREAS,
  data
});