import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import { isDefined } from "../../../../shared/commands/verifyDefinition";

const database = myApp.firestore();

export const deleteQuestion = async (point) => {
  try {
    await database.collection("questions").doc(point.cardProps.id).delete();
  } catch (error) {
    console.error("Error delete question!", error);
  }
};

export const deleteAnswer = async (idQuestion, answers) => {
  if (idQuestion) {
    try {
      await database.collection("questions").doc(idQuestion).update({
        answers
      });
    } catch (error) {
      console.error("Error delete answer!", error);
    }
  }
};

export const deleteGap = async (idGap, quizId) => {
  if (idGap) {
    try {
      let registeredAnswersSnapshot = await database.collection("quiz_registered_answers")
        .where("quiz_id", "==", quizId)
        .get();

      for (let registeredAnswersDoc of registeredAnswersSnapshot.docs) {
        let quizRegisteredAnswer = registeredAnswersDoc.data();

        for (let registeredAnswer of quizRegisteredAnswer.registered_answers) {
          if (isDefined(registeredAnswer.gap) && registeredAnswer.gap !== null && registeredAnswer.gap.gapId === idGap) {
            registeredAnswer.gap = {};
          }
        }

        await database.collection("quiz_registered_answers").doc(registeredAnswersDoc.id).update({
          registered_answers: quizRegisteredAnswer.registered_answers
        });
      }

      await database.collection("gaps").doc(idGap).delete();
    } catch (error) {
      console.error("Error delete gap!", error);
    }
  }
};
