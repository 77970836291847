import React, { Component } from "react";
import myApp from "../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/functions";
import firebase from "firebase/compat/app";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import logo from "../../res/images/dashboard_logo.png";
import theme from "../../core/theme.json";
import { passwordIsCorrect } from "../functions/verifyPassword";

import routerHistory from "../../core/infrastructure/routerHistory";

const encryptPassword = myApp.functions().httpsCallable("encryptPassword");
const decryptPassword = myApp.functions().httpsCallable("decryptPassword");

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: this,
      user: null,
      oldPass: "",
      newPass: "",
      confirmNewPass: "",
      submitButtonDisabled: true,
      errorMsg: "",
      loading: true,
      error: false,
      errorOldPass: false,
      errorNewPass: false,
      errorConfirmNewPass: false,
      errorLabel: "",
      resetSuccess: false,
    };
  }

  async componentDidMount() {
    await this.getUser();

    this.setState({
      loading: false,
    });
  }

  async getUser() {
    const database = myApp.firestore();
    let user = {};
    try {
      const userDoc = await database
        .collection("users")
        .doc(this.props.match.params.userId)
        .get();

      if (userDoc.exists) {
        user = { ...userDoc.data(), id: userDoc.id };

        if (!user.resetPassword) {
          this.setState({
            error: true,
            errorMsg:
              "Senha já foi alterada anteriormente. Verifique com seu administrador.",
          });
        }
      } else {
        this.setState({
          error: true,
          errorMsg: "Usuário não encontrado. Verifique com seu administrador.",
        });
      }
    } catch (e) {
      console.error("Error loading user: ", e);
      this.setState({
        error: true,
        errorMsg: "Erro ao carregar usuário. Verifique com seu administrador.",
      });
    }

    this.setState({
      user,
    });
  }

  handleOldPass(event) {
    this.setState({
      oldPass: event.target.value,
      errorOldPass: false,
      errorLabel: "",
    });

    if (
      event.target.value !== "" &&
      this.state.newPass !== "" &&
      this.state.confirmNewPass !== ""
    ) {
      this.setState({
        submitButtonDisabled: false,
      });
    } else {
      this.setState({
        submitButtonDisabled: true,
      });
    }
  }

  handleNewPass(event) {
    this.setState({
      newPass: event.target.value,
      errorNewPass: false,
      errorConfirmNewPass: false,
      errorLabel: "",
    });

    if (
      event.target.value !== "" &&
      ((this.state.user.requireOldPassword && this.state.oldPass !== "") ||
        !this.state.user.requireOldPassword) &&
      this.state.confirmNewPass !== ""
    ) {
      this.setState({
        submitButtonDisabled: false,
      });
    } else {
      this.setState({
        submitButtonDisabled: true,
      });
    }
  }

  handleConfirmNewPass(event) {
    this.setState({
      confirmNewPass: event.target.value,
      errorNewPass: false,
      errorConfirmNewPass: false,
      errorLabel: "",
    });

    if (
      event.target.value !== "" &&
      ((this.state.user.requireOldPassword && this.state.oldPass !== "") ||
        !this.state.user.requireOldPassword) &&
      this.state.newPass !== ""
    ) {
      this.setState({
        submitButtonDisabled: false,
      });
    } else {
      this.setState({
        submitButtonDisabled: true,
      });
    }
  }

  async savePassword() {
    const database = myApp.firestore();
    this.setState({
      loading: true,
    });

    try {
      let isCorrect = true;

      if (this.state.user.requireOldPassword) {
        isCorrect = await passwordIsCorrect(
          this.state.user.email,
          this.state.oldPass
        );
      }

      if (this.state.user.requireOldPassword && !isCorrect) {
        this.setState({
          errorOldPass: true,
          errorLabel: "Senha atual incorreta",
          loading: false,
        });
      } else if (this.state.newPass !== this.state.confirmNewPass) {
        this.setState({
          errorNewPass: true,
          errorConfirmNewPass: true,
          errorLabel: "Senhas estão diferentes",
          loading: false,
        });
      } else {
        const secondaryApp = firebase.initializeApp(config, "Secondary");

        if (this.state.user.requireOldPassword) {
          const userCredential = await secondaryApp
            .auth()
            .signInWithEmailAndPassword(
              this.state.user.email,
              this.state.oldPass
            );
          await userCredential.user.updatePassword(this.state.newPass);
        } else {
          const decryptedResponse = await decryptPassword(
            this.state.user.password
          );

          const userCredential = await secondaryApp
            .auth()
            .signInWithEmailAndPassword(
              this.state.user.email,
              decryptedResponse.data.decryptedPass
            );

          await userCredential.user.updatePassword(this.state.newPass);
        }

        const encryptResponse = await encryptPassword(this.state.newPass);

        await database
          .collection("users")
          .doc(this.props.match.params.userId)
          .update({
            resetPassword: false,
            requireOldPassword: false,
            password: encryptResponse.data.encryptedPass,
          });

        this.setState({
          loading: false,
          resetSuccess: true,
          error: false,
          errorMsg: "",
        });

        secondaryApp.auth().signOut();
      }
    } catch (error) {
      console.error("Error reseting password: ", error);
      this.setState({
        loading: false,
        error: true,
        errorMsg: "Erro ao atualizar senha: " + error.message,
      });
    }
  }

  async goToHome() {
    await myApp.auth().signInWithEmailAndPassword(this.state.user.email, this.state.newPass);
    routerHistory.push("/company");
  }

  goToLogin() {
    routerHistory.push("/login");
  }

  render() {
    return (
      <>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <AppBar
              position="fixed"
              className="appBar"
              style={{ background: theme.secondary_color }}
              elevation={0}
            >
              <Toolbar style={{ padding: "0px" }}>
                <Grid container alignItems="center" justify="center">
                  <Grid item>
                    <img
                      alt="logo"
                      src={logo}
                      style={{ height: "42px", paddingTop: "4px" }}
                    />
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ height: "90%", width: "90%", margin: "20px" }}
          >
            {this.state.loading ? (
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                style={{ marginTop: "10vh" }}
              >
                <Grid item>
                  <CircularProgress size={70} color={theme.secondary_color} />
                </Grid>
              </Grid>
            ) : (
              <>
                {this.state.error ? (
                  <Grid
                    item
                    component={Paper}
                    container
                    direction="column"
                    alignItems="center"
                    spacing={4}
                    elevation={4}
                    style={{ width: "40%", marginTop: "10vh", padding: 20 }}
                  >
                    <Grid item>
                      <HighlightOffIcon
                        style={{ color: theme.warning_color, fontSize: "80px" }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="h5" component="h5">
                        {this.state.errorMsg}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        onClick={() => this.goToLogin()}
                        style={{
                          background: theme.primary_color,
                          color: theme.secondary_color,
                          fontSize: theme.font_button,
                          borderColor: theme.secondary_color,
                        }}
                      >
                        Login
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    {this.state.resetSuccess ? (
                      <Grid
                        item
                        component={Paper}
                        container
                        direction="column"
                        alignItems="center"
                        spacing={4}
                        elevation={4}
                        style={{ width: "40%", marginTop: "10vh", padding: 20 }}
                      >
                        <Grid item>
                          <CheckCircleIcon
                            style={{
                              color: theme.primary_color,
                              fontSize: "80px",
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="h5" component="h5">
                            Senha atualizada com sucesso!
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="outlined"
                            onClick={() => this.goToHome()}
                            style={{
                              background: theme.primary_color,
                              color: theme.secondary_color,
                              fontSize: theme.font_button,
                              borderColor: theme.secondary_color,
                            }}
                          >
                            Ir para home
                          </Button>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid
                        item
                        component={Paper}
                        container
                        direction="column"
                        alignItems="center"
                        spacing={3}
                        elevation={4}
                        style={{ width: "40%", marginTop: "10vh" }}
                      >
                        <Grid
                          item
                          component={Paper}
                          elevation={0}
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            width: "100%",
                            backgroundColor: theme.secondary_color,
                          }}
                        >
                          <Grid item>
                            <Typography
                              variant="h6"
                              component="h6"
                              style={{ color: "white" }}
                            >
                              Redefina sua senha
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item style={{ width: "90%" }}>
                          <TextField
                            variant="outlined"
                            label="E-mail"
                            disabled
                            value={this.state.user.email}
                            style={{ width: "100%", marginTop: 10 }}
                          />
                        </Grid>
                        {this.state.user.requireOldPassword ? (
                          <Grid item style={{ width: "90%" }}>
                            <TextField
                              variant="outlined"
                              label="Senha atual"
                              type="password"
                              value={this.state.oldPass}
                              onChange={(e) => this.handleOldPass(e)}
                              error={this.state.errorOldPass}
                              style={{ width: "100%" }}
                            />
                          </Grid>
                        ) : (
                          <></>
                        )}
                        <Grid item style={{ width: "90%" }}>
                          <TextField
                            variant="outlined"
                            label="Nova senha"
                            type="password"
                            value={this.state.newPass}
                            onChange={(e) => this.handleNewPass(e)}
                            error={this.state.errorNewPass}
                            style={{ width: "100%" }}
                          />
                        </Grid>
                        <Grid item style={{ width: "90%" }}>
                          <TextField
                            variant="outlined"
                            label="Confirmar nova senha"
                            type="password"
                            value={this.state.confirmNewPass}
                            onChange={(e) => this.handleConfirmNewPass(e)}
                            error={this.state.errorConfirmNewPass}
                            style={{ width: "100%" }}
                          />
                        </Grid>
                        <Grid
                          item
                          style={{
                            width: "90%",
                            marginBottom:
                              this.state.errorOldPass ||
                              this.state.errorNewPass ||
                              this.state.errorConfirmNewPass
                                ? 0
                                : 10,
                          }}
                        >
                          <Button
                            variant="outlined"
                            fullWidth
                            disabled={this.state.submitButtonDisabled}
                            onClick={() => this.savePassword()}
                            style={{
                              background: this.state.submitButtonDisabled
                                ? theme.opacity
                                : theme.primary_color,
                              color: theme.secondary_color,
                              fontSize: theme.font_button,
                              borderColor: theme.secondary_color,
                            }}
                          >
                            Salvar
                          </Button>
                        </Grid>
                        {this.state.errorOldPass ||
                        this.state.errorNewPass ||
                        this.state.errorConfirmNewPass ? (
                          <Grid item style={{ width: "90%", marginBottom: 10 }}>
                            <Typography
                              variant="body1"
                              component="body1"
                              style={{ color: theme.warning_color }}
                            >
                              {this.state.errorLabel}
                            </Typography>
                          </Grid>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    )}
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </>
    );
  }
}

export default ResetPassword;
