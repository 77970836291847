import { useEffect, useState } from "react";
import myApp from "../../../core/infrastructure/firebaseConfig";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stepper,
  Step,
  StepLabel,
  Dialog,
  DialogContentText,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import theme from "../../../core/theme.json";
import { VerificationEmail } from "./components/VerificationEmail";
import { toNumber } from "lodash";
import Swal from "sweetalert2";
import { Conclusation } from "./components/conclusation";

export const QuestVideo = () => {
  const { id } = useParams();
  const { indexVideo } = useParams();
  const { companyGroupId } = useParams();
  const [quiz, setQuiz] = useState([]);
  const [selected, setSelected] = useState("");
  const [infoSelecet, setInfoSelect] = useState({});
  const [userData, setUserData] = useState({});
  const [index, setIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [point, setPoint] = useState(0);
  const [noneValue, setNoneValue] = useState(false);
  const [questData, setQuestData] = useState({});
  const [activeStepIndicator, setActiveStepIndicator] = useState(0);
  const [verificationEmail, setVerificationEmail] = useState(true);
  const [dialogConfirm, setDialogConfirm] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [conclusation, setConclusation] = useState(false);

  const currentCompanyId = localStorage.getItem("currentCompany");
  const CurrentQuest = quiz[index];
  useEffect(() => {
    getuserData();
    getQuestData();
  }, [localStorage.getItem("verificationEmail")]);

  const getQuestData = async () => {
    let aux = {};
    let quest = [];
    setLoading(true);
    try {
      let get = await myApp
        .firestore()
        .collection("quizModuleVideo")
        .where("fk_company", "==", companyGroupId)
        .where("idModule", "==", id)
        .where("videoId", "==", indexVideo)
        .get();

      console.log("teste");

      if (get.docs.length !== 0) {
        for (let item of get.docs) {
          aux = {
            id: item.data().idModule,
            name: item.data().name,
            nameModule: item.data().nameModule,
            questions: item.data().questions,
          };
          quest.push(aux.questions.map((item) => item));
          setQuiz(quest[0]);
        }
      } else {
        setNoneValue(true);
      }
      console.log(noneValue);
    } catch (err) {
      console.log("erro ao pegar quiz", err);
    }
    setQuestData(aux);
    setLoading(false);
  };
  const steps = ["Etapa de e-mail", "Etapa de questionário"];

  const getuserData = async () => {
    let aux = {};
    let userData = await myApp
      .firestore()
      .collection("AnswerModuloVideo")
      .where("fk_company", "==", companyGroupId)
      .where("idModule", "==", id)
      .where("email", "==", localStorage.getItem("verificationEmail"))
      .get();
    for (let item of userData.docs) {
      if (item.data().email == localStorage.getItem("verificationEmail")) {
        aux = {
          id: item.id,
          email: item.data().email,
          answers: item.data().answers,
          date: item.data().date,
          fk_company: item.data().fk_company,
          idModule: item.data().idModule,
          nameModule: item.data().nameModule,
          nameOfQuestion: item.data().nameOfQuestion,
          point: item.data().point,
        };

        setUserData(aux);
      }
    }
    console.log(
      "jda",
      userData.docs.map((item) => item.data())
    );
  };
  const NextIndex = () => {
    if (index == quiz.length - 1) {
      alert("impossivel continuar");
    } else {
      setIndex(index + 1);
    }
  };
  const SendAnswer = async () => {
    console.log("aaaa", answers);
    let aux = [];
    let auxPoint = [];
    let answersAux = [];

    if (userData.answers) {
      auxPoint.push(...userData.point, point);
      answersAux.push(...userData.answers);
      aux.push(...answersAux, { answers: answers });
    } else {
      aux.push({ answers: answers });
      auxPoint.push(point);
      console.log("aux", aux);
    }
    let create = await myApp
      .firestore()
      .collection("AnswerModuloVideo")
      .doc(userData.id)
      .update({
        idModule: questData.id,
        nameOfQuestion: questData.name,
        nameModule: questData.nameModule,
        fk_company: companyGroupId,
        point: auxPoint,
        answers: aux,
      })
      .then(() => {
         setConclusation(true)
      
      });
  };

  const saveAnswer = () => {
    let aux = [];
    if (selected == "") {
      Swal.fire({
        icon: "error",
        title: "Ecolha uma resposta primeiro",
        showConfirmButton: "Ok",
        confirmButtonColor: "#38f205",
      });
      return;
    }
    if (infoSelecet?.correct == true) {
      setPoint(point + 1);
    }
    aux.push(...answers, {
      question: CurrentQuest?.name,
      answer: selected,
      correct: infoSelecet?.correct,
    });

    setAnswers(aux);

    if (index != quiz.length - 1) {
      NextIndex();
      setSelected("");
    }
  };

  const handleConfirm = () => {
    saveAnswer();
  };

  return (
    <>
      {conclusation == true ?
      <Conclusation questData={questData}/>
      : loading == true ? (
        <Grid
          container
          alignContent="center"
          justifyContent="center"
          direction="column"
          style={{ marginTop: "15%", width: "100%" }}
        >
          <CircularProgress size={100} />
        </Grid>
      ) : (
        <>
          <Stepper activeStep={activeStepIndicator} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <Grid
            container
            alignContent="center"
            justifyContent="center"
            direction="column"
            style={{ marginTop: "5%", width: "80%" }}
          >
            {verificationEmail ? (
              <VerificationEmail
                noneValue={noneValue}
                questData={questData}
                setVerificationEmail={() => setVerificationEmail()}
                setActiveStepIndicator={(number) =>
                  setActiveStepIndicator(number)
                }
              />
            ) : (
              <>
                <Grid>
                  <Typography variant="h5">
                    Respondendo : {questData.name}
                  </Typography>
                </Grid>
                <Grid
                  style={{
                    marginTop: "5%",
                    display: "flex",
                    marginRight: "10%",
                  }}
                >
                  <Typography variant="h5">{CurrentQuest?.name}?</Typography>
                  <Typography
                    variant="h6"
                    style={{
                      color: theme.disabled_color,
                      position: "absolute",
                      right: "10%",
                      top: "10%",
                    }}
                  >
                    {index + 1} de {quiz.length}
                  </Typography>
                </Grid>

                <FormControl>
                  <RadioGroup
                    style={{ marginTop: "5%" }}
                    name="radiu-name-quiz"
                    value={selected}
                    onChange={(e) => setSelected(e.target.value)}
                  >
                    {CurrentQuest?.alternatives.map((alternatives, index) => {
                      return (
                        <>
                          <Button
                            key={index}
                            onClick={() => {
                              setInfoSelect({
                                answer: alternatives.name,
                                correct: alternatives.correct,
                              });
                              console.log(alternatives);
                            }}
                            style={{
                              border: "1px solid gray",
                              width: "150%",
                              height: "50px",
                              display: "flex",
                              alignItems: "center",
                              marginBottom: 10,
                              cursor: "pointer",
                              borderRadius: "5px",
                            }}
                          >
                            <FormControlLabel
                              style={{ width: "100%" }}
                              key={index + 1}
                              control={<Radio color="success" />}
                              label={alternatives.name}
                              value={alternatives.name}
                            />
                          </Button>
                        </>
                      );
                    })}
                  </RadioGroup>
                </FormControl>

                <Grid
                  style={{
                    marginTop: "5%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid></Grid>
                  {index !== questData?.questions?.length - 1 ? (
                    <Grid style={{ marginLeft: "100%" }}>
                      <Button
                        onClick={() => saveAnswer()}
                        style={{
                          cursor: "pointer",
                          width: "300px",
                          height: "40px",
                          background: theme.primary_color,
                          color: theme.secondary_color,
                        }}
                      >
                        Próximo
                      </Button>
                    </Grid>
                  ) : (
                    <Grid style={{ marginLeft: "100%" }}>
                      <Button
                        onClick={() => {
                          confirm == true
                            ? SendAnswer()
                            : setDialogConfirm(true);
                        }}
                        style={{
                          cursor: "pointer",
                          width: "300px",
                          height: "40px",
                          background: theme.primary_color,
                          color: theme.secondary_color,
                        }}
                      >
                        {confirm == true ? "Salvar" : "Confirmar"}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </>
            )}

            <Dialog open={dialogConfirm}>
              <DialogTitle
                style={{
                  color: theme.quaternary_color,
                  backgroundColor: theme.secondary_color,
                  textAlign: "center",
                }}
              >
                Aviso!
              </DialogTitle>
              <DialogContent>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid
                    item
                    textAlign="center"
                    direction="row"
                    container
                    justifyContent="space-between"
                  >
                    <DialogContentText>
                      <Typography variant="h5">
                        Tem certeza que deseja confirmar sua resposta ?
                      </Typography>
                    </DialogContentText>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button
                  onClick={() => {
                    saveAnswer();
                    setConfirm(true);
                    setDialogConfirm(false);
                  }}
                  style={{
                    backgroundColor: theme.primary_color,
                  }}
                >
                  Aceitar
                </Button>

                <Button
                  onClick={() => setDialogConfirm(false)}
                  style={{
                    backgroundColor: theme.warning_color,
                  }}
                >
                  Cancelar
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </>
      )}
    </>
  );
};
