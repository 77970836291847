import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Login from "../login/presentation/pages/login";
import SolicitationForm from "../solicitationChannel/presentation/pages/solicitationForm";
import solicitationFormCompany from "../solicitationChannel/presentation/pages/solicitationFormCompany";
import ResetPassword from "../user/pages/resetPassword";
import EmailVerified from "../solicitationChannel/presentation/pages/emailVerified";
import EpmSteps from "../dashboard/features/epmSteps/components/epmSteps";
import MasterProcessList from "../dashboard/features/epmSteps/components/masterProcessList";
import Status404 from "../error/presentation/404";
import AnswerPage from "../dashboard/features/quiz/components/answerPage";
import NewAnswerPage from "../dashboard/features/quiz/components/newAnwerPage";
import AnswerPagrModuleVideo from "../dashboard/features/quiz/components/answerPageModuleVideo";
import DenunceFormCompany from "../solicitationChannel/presentation/pages/denunceFormCompany";
import { DenunceSelect } from "../solicitationChannel/presentation/denunceSelect";
import { DenunceView } from "../solicitationChannel/presentation/denunceView";
import { QuestVideo } from "../dashboard/features/videoReports/QuestVideo";




function PublicRoutes() {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/login" />
      </Route>
      <Route path="/login" component={Login} />
      <Route path="/quiz/:quizId/:processId" component={AnswerPage} />
      <Route path="/newQuiz/:quizId/:processId" component={NewAnswerPage} />
      <Route
        path="/quizModuleVideo/:quizModuleVideoId"
        component={AnswerPagrModuleVideo}
      />
      <Route
        path="/solicitation/client/:companyGroupId"
        exact
        component={SolicitationForm}
      />
      <Route
        path="/solicitation/client/company/:companyGroupIdc"
        exact
        component={solicitationFormCompany}
      />
      <Route
        path="/denunce/client/selectDenunce/:companyGroupIdc"
        component={DenunceSelect}
      />
      <Route
        path="/denunce/ViewDenunce/:companyGroupIdc"
        component={DenunceView}
      />
      <Route
        path="/denunce/client/company/:companyGroupIdc"
        component={DenunceFormCompany}
      />

        <Route
        path="/video/Quest/:id/:indexVideo/:companyGroupId"
        exact
        component={QuestVideo}
      />

      <Route path="/reset-password/:userId" exact component={ResetPassword} />
      <Route
        path="/email/verification/:token"
        exact
        component={EmailVerified}
      />
      <Route path="/new-epm-etapas" exact component={EpmSteps} />
      <Route path="/masterProcessList" exact component={MasterProcessList} />
      {/* <Route path="*" exact component={Status404} /> */}
    </Switch>
  );
}

export default PublicRoutes;
