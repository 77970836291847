import React, { useState, useEffect } from "react";
import {
  Grid,
  ButtonBase,
  Paper,
  Switch
} from "@material-ui/core";
import SettingsIcon from '@material-ui/icons/Settings';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import { useDispatch } from "react-redux";
import { changePage, parameterizationType, companyName } from '../redux/actions';
import Title from "../../../../shared/components/title";
import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import "./style.css";


const List = () => {
  //states
  const [type, setType] = useState(localStorage.getItem('typeParametrization') === 't' ? false : true);
  //const [type, setType] = useState(false)
  const [company, setCompany] = useState(localStorage.getItem('currentCompany'));
  const [companyNameState, setCompanyNameState] = useState('');
  const [userAdmin, setUserAdmin] = useState(localStorage.getItem('userAdmin') === 'sim' ? false : true);

  //variaveis auxiliares
  const dispatch = useDispatch();

  //funções 
  useEffect(() => {
    let querySnapshot = myApp.firestore().collection("companies").doc(company);
    querySnapshot.get().then((doc) => {
      setCompanyNameState(doc.data().nickname)
    });
  }, []);

  //função responsável por mudar a pagina para Titular
  const handleChangePageTitular = () => {
    dispatch(changePage(28))
    dispatch(parameterizationType(type))
    dispatch(companyName(companyNameState))
  }

  const handleChangePageDenuncia = () => {
    dispatch(changePage(41))
    dispatch(parameterizationType(type))
    dispatch(companyName(companyNameState))
  }

  

  const handleSetTypeParametrization = () => {
    setType(!type)
    localStorage.setItem('typeParametrization', type === true ? 't' : 'f');
    console.log(localStorage.getItem('typeParametrization'));
  }

  // Funcao responsavel por mudar a pagina para o ChatBot
  const handleChangePageChatBot = () => {
    dispatch(changePage(29));
  }

  //função responsável por alterar pagina para gestão de ativos
  const handleChangePageAssetManagement = () => {
    dispatch(changePage(30));
  }

  //função responsável por alterar pagina para cadastro de fornecedores
  const handleChangePageSupplierRegistration = () => {
    dispatch(changePage(33));
  }

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Title>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Switch
              checked={type}
              color="primary"
              //onChange={() => setType(!type)}
              onChange={handleSetTypeParametrization}
            />
          </Grid>
          <Grid item> Parametrização {type ? companyNameState : 'Grupo'} </Grid>
        </Grid>
      </Title>
      <Grid
        container
        item
        spacing={4}
        style={{ marginTop: '10px' }}
      >
        <Grid
          item
          alignItems="flex-start"
          direction="column"
          xs={12} sm={12} md={6} lg={6} xl={3}
        >
          <ButtonBase
            onClick={handleChangePageTitular}
            style={{ margin: '10px', width: '100%', height: '250px' }}
          >
            <Paper
              elevation={5}
              style={{ width: '100%', height: '100%' }}
            >
              <Grid
                container
                item
                direction="column"
                justify="center"
                alignItems="center"
                style={{
                  height: '100%',
                }}
              >
                <SettingsIcon style={{ fontSize: 75, color: 'rgb(80, 93, 109)' }} />
                <span
                  style={{
                    fontSize: '22px',
                    color: '#909090',
                    margin: '10px'
                  }}
                >
                  Canal do titular
                </span>
              </Grid>
            </Paper>
          </ButtonBase>
        </Grid>

        <Grid
          item
          alignItems="flex-start"
          direction="column"
          xs={12} sm={12} md={6} lg={6} xl={3}
          className={type ? '' : ''}
        >
          <ButtonBase
            onClick={handleChangePageDenuncia}
            style={{ margin: '10px', width: '100%', height: '250px' }}
          >
            <Paper
              elevation={5}
              style={{ width: '100%', height: '100%', backgroundColor: '#fff' }}
            >
              <Grid
                container
                item
                direction="column"
                justify="center"
                alignItems="center"
                style={{
                  height: '100%',
                }}
              >
                <SettingsIcon style={{ fontSize: 75, color: 'rgb(80, 93, 109)' }} />
                <span
                  style={{
                    fontSize: '22px',
                    color: '#909090',
                    margin: '10px'
                  }}
                >
                  Canal de denúncia
                </span>
              </Grid>
            </Paper>
          </ButtonBase>
        </Grid>
        <Grid
          item
          alignItems="flex-start"
          direction="column"
          xs={12} sm={12} md={6} lg={6} xl={3}
          className={type ? '' : 'chatBot'}
        >
          <ButtonBase
            onClick={handleChangePageChatBot}
            style={{ margin: '10px', width: '100%', height: '250px' }}
          >
            <Paper
              elevation={5}
              style={{ width: '100%', height: '100%', backgroundColor: '#fff' }}
            >
              <Grid
                container
                item
                direction="column"
                justify="center"
                alignItems="center"
                style={{
                  height: '100%',
                }}
              >
                <SettingsIcon style={{ fontSize: 75, color: 'rgb(80, 93, 109)' }} />
                <span
                  style={{
                    fontSize: '22px',
                    color: '#909090',
                    margin: '10px'
                  }}
                >
                  ChatBot
                </span>
              </Grid>
            </Paper>
          </ButtonBase>
        </Grid>
        <Grid
          item
          alignItems="flex-start"
          direction="column"
          xs={12} sm={12} md={6} lg={6} xl={3}
          className={type ? '' : 'chatBot'}
        >
          <ButtonBase
            onClick={handleChangePageAssetManagement}
           
            style={{ margin: '10px', width: '100%', height: '250px' }}
          >
            <Paper
              elevation={5}
              style={{ width: '100%', height: '100%', backgroundColor: '#fff' }}
            >
              <Grid
                container
                item
                direction="column"
                justify="center"
                alignItems="center"
                style={{
                  height: '100%',
                }}
              >
                <SettingsIcon style={{ fontSize: 75, color: 'rgb(80, 93, 109)' }} />
                <span
                  style={{
                    fontSize: '22px',
                    color: '#909090',
                    margin: '10px'
                  }}
                >
                  Gestão de ativos
                </span>
              </Grid>
            </Paper>
          </ButtonBase>
        </Grid>
        <Grid
          item
          alignItems="flex-start"
          direction="column"
          xs={12} sm={12} md={6} lg={6} xl={3}
          className={type ? '' : 'chatBot'}
        >
          <ButtonBase
            onClick={handleChangePageSupplierRegistration}
           
            style={{ margin: '10px', width: '100%', height: '250px' }}
          >
            <Paper
              elevation={5}
              style={{ width: '100%', height: '100%', backgroundColor: '#fff' }}
            >
              <Grid
                container
                item
                direction="column"
                justify="center"
                alignItems="center"
                style={{
                  height: '100%',
                }}
              >
                <SettingsIcon style={{ fontSize: 75, color: 'rgb(80, 93, 109)' }} />
                <span
                  style={{
                    fontSize: '22px',
                    color: '#909090',
                    margin: '10px'
                  }}
                >
                  Cadastro de fornecedores
                </span>
              </Grid>
            </Paper>
          </ButtonBase>
        </Grid>
        
      </Grid>
    </Grid>

  );
}

export default List;