import React, { Component } from 'react';

import { AppBar, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@material-ui/core';

import dashboardLogo from '../../../res/images/dashboard_logo.png'
import cookieList from '../../cookieList';
import routerHistory from '../../../core/infrastructure/routerHistory';

class CookiePolicy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      
    }
  }

  render() {
    return (
      <div>
        <AppBar position="fixed" className='appBar' style={{background: "#111C39"}} elevation={0}>
          <Toolbar style={{padding: "0px"}}>
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <img alt='logo' src={dashboardLogo} style={{height: "42px", paddingTop: "4px"}} />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <div style={{paddingTop: "80px", paddingRight: "24px", paddingLeft: "24px"}}>
          <Grid container direction="column">
            <Grid item>
              <Button onClick={() => {routerHistory.push("/")}} >
                Voltar
              </Button>
            </Grid>
            <Grid style={{height: "24px"}} />
            <Grid item>
              <Typography variant="h3" component="h3">
                Política de Cookies
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" component="body1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Typography>
            </Grid>
            <Grid style={{height: "24px"}} />
            <Grid item>
              <Typography variant="h4" component="h4">
                Lista de Cookies
              </Typography>
            </Grid>
            <Grid style={{height: "8px"}} />
            <Grid item>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Tipo</TableCell>
                      <TableCell>Nome</TableCell>
                      <TableCell>Descrição</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cookieList.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell>{row.type}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.description}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default CookiePolicy;