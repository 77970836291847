import React, { Component } from "react";
import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";

import {
  Paper,
  Grid,
  Typography,
  ButtonBase,
  Dialog,
  DialogContent,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";

import { connect } from "react-redux";

import { changePage } from "../../../presentation/redux/actions";
import { setProcessId, setLoadingProcess } from "../redux/actions";
import { Autocomplete } from "@material-ui/lab";

import theme from "../../../../core/theme.json";

class AddProcess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      goal: "",
      criticality: "",
      operator: "",
      owner: "",
      description: "",
      baseName: "",
      legalBases: [],
      defaultLegalBases: [],
      complementaryLegalBase: "",
      quizId: "",
      quizes: [],
      newQuizes: [],
      userInformation: JSON.parse(
        sessionStorage.getItem("@blue-legal/userInformation")
      ),
      components: {},
      dataLoaded: false,
      addProcessButtonDisabled: true,
    };
  }

  componentDidMount() {
    this.getLegalBases();
    this.getNewQuizes();
    this.getQuizes();
  }

  async getLegalBases() {
    let database = myApp.firestore();

    try {
      let data = await database.collection("legal_basis").orderBy("name", "asc").get();
      let defaultLegalBases = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));

      this.setState({
        defaultLegalBases: defaultLegalBases
      });
    } catch (error) {
      console.error(error);
    }
  }

  async getQuizes() {
    let database = myApp.firestore();

    try {
      let data = await database.collection("quiz")
        .where("active", "==", true)
        .where("fk_company", "==", localStorage.getItem("currentCompany"))
        .get();
      let quizes = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));

      quizes.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }

        if (a.name < b.name) {
          return -1;
        }

        return 0;
      });

      this.setState({
        quizes
      });
    } catch (error) {
      console.error(error);
    }
  }

  async getNewQuizes() {
    let database = myApp.firestore();
    try {
      let data = await database.collection("newQuizz")
        .where("fk_company", "==", localStorage.getItem("currentCompany"))
        .get();
      let newQuizes = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));

      newQuizes.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }

        if (a.name < b.name) {
          return -1;
        }

        return 0;
      });

      this.setState({
        newQuizes
      });
    } catch (error) {
      console.error(error);
    }
  }

  handleDialog(open) {
    this.setState({
      dialog: !this.state.dialog,
    });

    if (!open) {
      this.setState({
        name: "",
        goal: "",
        criticality: "",
        operator: "",
        owner: "",
        description: "",
        baseName: "",
        legalBases: [],
      });
    }
  }

  handleName(event) {
    this.setState({
      name: event.target.value,
    });

    if (
      event.target.value !== "" &&
      this.state.owner !== "" &&
      this.state.goal !== "" &&
      this.state.criticality !== "" &&
      this.state.operator !== "" &&
      this.state.baseName !== ""
    ) {
      this.setState({
        addProcessButtonDisabled: false,
      });
    } else {
      this.setState({
        addProcessButtonDisabled: true,
      });
    }
  }

  handleOwner(event) {
    this.setState({
      owner: event.target.value,
    });

    if (
      event.target.value !== "" &&
      this.state.name &&
      this.state.goal !== "" &&
      this.state.criticality !== "" &&
      this.state.operator !== "" &&
      this.state.baseName !== ""
    ) {
      this.setState({
        addProcessButtonDisabled: false,
      });
    } else {
      this.setState({
        addProcessButtonDisabled: true,
      });
    }
  }

  handleGoal(event) {
    this.setState({
      goal: event.target.value,
    });

    if (
      event.target.value !== "" &&
      this.state.name &&
      this.state.owner !== "" &&
      this.state.criticality !== "" &&
      this.state.operator !== "" &&
      this.state.baseName !== ""
    ) {
      this.setState({
        addProcessButtonDisabled: false,
      });
    } else {
      this.setState({
        addProcessButtonDisabled: true,
      });
    }
  }

  handleCriticality(event) {
    this.setState({
      criticality: event.target.value,
    });

    if (
      event.target.value !== "" &&
      this.state.name &&
      this.state.goal !== "" &&
      this.state.owner !== "" &&
      this.state.operator !== "" &&
      this.state.baseName !== ""
    ) {
      this.setState({
        addProcessButtonDisabled: false,
      });
    } else {
      this.setState({
        addProcessButtonDisabled: true,
      });
    }
  }

  handleOperator(event) {
    this.setState({
      operator: event.target.value,
    });

    if (
      event.target.value !== "" &&
      this.state.name &&
      this.state.goal !== "" &&
      this.state.criticality !== "" &&
      this.state.owner !== "" &&
      this.state.baseName !== ""
    ) {
      this.setState({
        addProcessButtonDisabled: false,
      });
    } else {
      this.setState({
        addProcessButtonDisabled: true,
      });
    }
  }

  handleDescription(event) {
    this.setState({
      description: event.target.value,
    });
  }

  handleBaseName(value) {
    this.setState({
      baseName: value,
    });

    if (
      value !== "" &&
      this.state.name &&
      this.state.goal !== "" &&
      this.state.criticality !== "" &&
      this.state.operator !== "" &&
      this.state.owner !== ""
    ) {
      this.setState({
        addProcessButtonDisabled: false,
      });
    } else {
      this.setState({
        addProcessButtonDisabled: true,
      });
    }
  }

  handleQuiz(event) {
    console.log(event.target.value)
    this.setState({
      quizId: event.target.value
    });
  }

  addBase() {
    var legalBases = this.state.legalBases;

    legalBases.push(this.state.baseName);

    this.setState({
      // baseName: "",
      legalBases: legalBases,
    });
  }

  removeBase(index) {
    var legalBases = this.state.legalBases;

    legalBases.splice(index, 1);

    this.setState({
      legalBases: legalBases,
    });
  }

  handleComplementaryLegalBase(event) {
    this.setState({
      complementaryLegalBase: event.target.value,
    });
  }

  async addProcess() {
    this.props.setLoadingProcess(true);

    this.addBase();

    let emptyROPA = await this.generateEmptyROPAanswers();

    let database = myApp.firestore();

    try {
      var process = await database.collection("processes").add({
        name: this.state.name,
        goal: this.state.goal,
        owner: this.state.owner,
        criticality: this.state.criticality,
        fk_areas: this.props.processArea,
        operator: this.state.operator,
        description: this.state.description,
        json: "",
        legalBases: this.state.legalBases,
        quizId: this.state.quizId,
        complementaryLegalBase: this.state.complementaryLegalBase,
        customGaps: [],
        gaps: [],
        ROPAanswers: emptyROPA,
      });

      this.props.setProcessId(process.id);
      this.props.changePage(7);

    } catch (error) {
      console.error(error);
    }
  }

  async generateEmptyROPAanswers() {
    let data = [];

    for (let i = 0; i < 36; i++) {
      data.push("");
    }

    return data;
  }

  renderPageComponents() {
    var components = {
      addProcessButton: this.state.userInformation.permissions.insert ? (
        <Grid item style={{ width: "100%" }}>
          <ButtonBase
            onClick={() => {
              this.handleDialog(true);
            }}
            style={{ width: "100%", height: "100%", borderRadius: "8px" }}
          >
            <Paper
              style={{ width: "100%", height: "100%", borderRadius: "8px" }}
            >
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                style={{ width: "100%", height: "100%" }}
              >
                <Grid
                  item
                  container
                  justify="center"
                  alignItems="center"
                  style={{ width: "100%", height: "50%" }}
                >
                  <Grid item>
                    <AddIcon
                      style={{ fontSize: 76, color: theme.tertiary_color }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  justify="center"
                  style={{ width: "100%", height: "50%" }}
                >
                  <Grid
                    item
                    container
                    justify="center"
                    style={{
                      width: "100%",
                      height: "90%",
                      overflowY: "hidden",
                      textOverflow: "ellipsis",
                      margin: "0 10px 0 10px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      component="h6"
                      style={{
                        color: theme.tertiary_color,
                        overflowWrap: "anywhere",
                      }}
                    >
                      Adicionar novo Processo
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </ButtonBase>
        </Grid>
      ) : (
        ""
      ),
    };

    this.setState({ components });
  }

  render() {
    if (this.state.userInformation !== null) {
      if (!this.state.dataLoaded) {
        this.setState({ dataLoaded: true });
        this.renderPageComponents();
      }
    }

    return (
      <div>
        <Grid container direction="row" spacing={4} style={{ height: "320px" }}>
          {this.state.components.addProcessButton}
        </Grid>

        <Dialog
          open={this.state.dialog}
          onClose={() => {
            this.handleDialog(false);
          }}
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogContent>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="flex-start"
            >
              <Grid item container direction="row">
                <Grid item style={{ width: "100%" }} xs={10}>
                  <Typography variant="h5" component="h5">
                    Novo Processo
                  </Typography>
                </Grid>
                <Grid item container xs={2} justify="flex-end">
                  <IconButton onClick={() => this.handleDialog(false)}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item style={{ height: "16px" }} />
              <Grid item style={{ width: "100%" }}>
                <TextField
                  label="Nome"
                  helperText="Obrigatório*"
                  variant="outlined"
                  value={this.state.name}
                  onChange={(event) => {
                    this.handleName(event);
                  }}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item style={{ height: "16px" }} />
              <Grid item style={{ width: "100%" }}>
                <TextField
                  label="Responsável"
                  helperText="Obrigatório*"
                  variant="outlined"
                  value={this.state.owner}
                  onChange={(event) => {
                    this.handleOwner(event);
                  }}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item style={{ height: "16px" }} />
              <Grid item style={{ width: "100%" }}>
                <TextField
                  label="Finalidade"
                  helperText="Obrigatório*"
                  variant="outlined"
                  value={this.state.goal}
                  onChange={(event) => {
                    this.handleGoal(event);
                  }}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item style={{ height: "16px" }} />
              <Grid item style={{ width: "100%" }}>
                <TextField
                  multiline
                  rows={3}
                  label="Detalhe"
                  variant="outlined"
                  value={this.state.description}
                  onChange={(event) => {
                    this.handleDescription(event);
                  }}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item style={{ height: "16px" }} />
              <Grid item style={{ width: "100%" }}>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel>Criticidade</InputLabel>
                  <Select
                    value={this.state.criticality}
                    onChange={(event) => {
                      this.handleCriticality(event);
                    }}
                    label="Criticidade"
                    helperText="Obrigatório*"
                  >
                    <MenuItem value={""}></MenuItem>
                    <MenuItem value={"alta"}>Alta</MenuItem>
                    <MenuItem value={"media"}>Média</MenuItem>
                    <MenuItem value={"baixa"}>Baixa</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item style={{ height: "16px" }} />
              <Grid item style={{ width: "100%" }}>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel>Atuação</InputLabel>
                  <Select
                    value={this.state.operator}
                    onChange={(event) => {
                      this.handleOperator(event);
                    }}
                    label="Atuação"
                    helperText="Obrigatório*"
                  >
                    <MenuItem value={""}></MenuItem>
                    <MenuItem value={"controlador"}>Controlador</MenuItem>
                    <MenuItem value={"Operador"}>Operador</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item style={{ height: "16px" }} />
              <Grid item style={{ width: "100%" }}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item style={{ width: "100%" }}>
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <Autocomplete
                        options={this.state.defaultLegalBases.map(
                          (lb) => lb.name
                        )}
                        value={this.state.baseName}
                        onChange={(event, newValue) => {
                          this.handleBaseName(newValue);
                        }}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Base Legal"
                            variant="outlined"
                            helperText="Obrigatório*"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{ height: "16px" }} />
              <Grid item style={{ width: "100%" }}>
                <TextField
                  label="Legislação Complementar Envolvida"
                  variant="outlined"
                  value={this.state.complementaryLegalBase}
                  onChange={(event) => {
                    this.handleComplementaryLegalBase(event);
                  }}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item style={{ height: "16px" }} />
              <Grid item style={{ width: "100%" }}>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel>Questionário</InputLabel>
                  <Select
                    value={this.state.quizId}
                    onChange={(event) => { this.handleQuiz(event) }}
                    label="Questionário"
                  >
                    <MenuItem value={""}>2.0</MenuItem>
                    {this.state.newQuizes.map((quiz) => (
                      <MenuItem value={'quiz2.0 ' + quiz.id}>{quiz.name}</MenuItem>
                    ))}
                    {/* <MenuItem value={""}>1.0</MenuItem>
                    {this.state.quizes.map((quiz) => (
                      <MenuItem value={quiz.id}>{quiz.name}</MenuItem>
                    ))} */}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item style={{ height: "16px" }} />
              <Grid item>
                <Typography variant="subtitle2" component="div">
                  As informações constarão no relatório DPIA
                </Typography>
              </Grid>
              <Grid item style={{ height: "16px" }} />
              <Grid item style={{ width: "100%" }}>
                <Button
                  variant="contained"
                  style={{
                    background: this.state.addProcessButtonDisabled
                      ? "#91ff79"
                      : theme.primary_color,
                    color: theme.secondary_color,
                    fontSize: theme.font_button,
                    fontWeight: 300,
                  }}
                  onClick={() => {
                    this.addProcess();
                  }}
                  disabled={this.state.addProcessButtonDisabled}
                >
                  {"Criar"}
                </Button>
              </Grid>
              <Grid item style={{ height: "10px" }} />
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    processArea: state.processReducer.processArea,
  };
}

export default connect(mapStateToProps, {
  changePage,
  setProcessId,
  setLoadingProcess,
})(AddProcess);
