import React, { Component } from 'react';
import myApp from '../../../../core/infrastructure/firebaseConfig';
import "firebase/compat/firestore";

import { Grid } from '@material-ui/core';
import PlanItem from './planItem';

class PlanObject {
  id = "";
  name = "";
  totalActivities = 0;
  doneActivities = 0;
  donePercentage = 0;
}

class PlanList extends Component {
  constructor(props) {
    super(props);

    this.db = myApp.firestore();

    this.state = {
      plans: [],
      gapsConcluidos: 0,
      totalGaps: 0,
      percentGaps: 0.0,
      loadingGap: true,
      loadingSolicitationReport: true,
      listOfSolicitation: 0,
      listOfCompletedSolicitation: 0,
      percentSolicitations: 0.0,
      loadingIncidentes: true,
      totalIncidentes: 0,
      totalIncidentesConcluidos: 0,
      percentIncidentes: 0.0
    }

    this.hendleGetStatus = this.hendleGetStatus.bind(this)
  }

  async componentDidMount() {
    await this.getPlanList();
    await this.buscaDados();
    await this.hendleGetStatus();
    await this.getIncidentes();
  }

  async getPlanList() {
    let database = myApp.firestore();
    let _companyId = localStorage.getItem('currentCompany');
    var planList = [];

    try {
      let data = await database.collection('plan_type').get();
      let plans = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));

      for (var i = 0; i < plans.length; i++) {
        try {
          let data = await database
            .collection('activities')
            .where('fk_plan_type', '==', plans[i].id)
            .where('fk_companies', '==', _companyId)
            .get();

          let plansActivities = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));

          var planObject = new PlanObject();

          var total = 0;
          var done = 0;

          for (let activy of plansActivities) {
            total++;

            if (activy.done) {
              done++;
            }
          }

          planObject.id = plans[i].id;
          planObject.name = plans[i].name;
          planObject.doneActivities = done;

          if (total > 0) {
            planObject.donePercentage = (done * 100) / total;
            planObject.totalActivities = total;
          }
          else {
            planObject.donePercentage = 0;
            planObject.totalActivities = 1;
          }

          planList.push(planObject);
        }
        catch (error) {
          console.error(error);
        }
      }
    }
    catch (error) {
      console.error(error);
    }

    this.setState({
      plans: planList
    });
  }

  async buscaDados() {
    const db = myApp.firestore();
    let minhaLista = [];
    let meusProcessos = [];
    let listaGaps = [];
    let listaGapsConcluidos = 0;
    let percentGapsAux = 0;

    let areasSnapshot = await db
      .collection("areas")
      .where("fk_companies", "==", localStorage.getItem('currentCompany'))
      .where("active", "==", true)
      .get();

    for (let doc of areasSnapshot.docs) {
      minhaLista.push({
        dados: doc.data(),
        id: doc.id
      });
    }

    for (let area of minhaLista) {
      let querySnapshot = await db.collection("processes").where("fk_areas", "==", area.id).get();

      for (let doc of querySnapshot.docs) {
        meusProcessos.push({
          ...doc.data(),
          id: doc.id,
          areaName: area.dados.name,
        });
      }
    }

    for (let processo of meusProcessos) {
      for (let customGap of processo.customGaps) {
        listaGaps.push({
          concluido: customGap.done ? customGap.done : false,
        });

        if (customGap.done) {
          listaGapsConcluidos++;
        }
      }

      for (let gap of processo.gaps) {
        listaGaps.push({
          concluido: gap.done ? gap.done : false,
        });

        if (gap.done) {
          listaGapsConcluidos++;
        }
      }
    }

    percentGapsAux = (listaGapsConcluidos * 100) / listaGaps.length;

    this.setState({
      gapsConcluidos: listaGapsConcluidos,
      totalGaps: listaGaps.length,
      percentGaps: percentGapsAux,
      loadingGap: false
    });
  }

  async hendleGetStatus() {
    const db = myApp.firestore();
    let listOfCompletedSolicitation = 0;
    let percentSolicitationAux = 0.0;

    let querySnapshot = await db
      .collection("solicitations")
      .where("fk_company", "==", localStorage.getItem('currentCompany'))
      .get();

    if (querySnapshot.docs.length > 0) {
      for (let doc of querySnapshot.docs) {
        if (doc.data().done === true) {
          listOfCompletedSolicitation++;
        }
      }

      percentSolicitationAux = (listOfCompletedSolicitation * 100) / querySnapshot.docs.length;
    }

    this.setState({
      listOfSolicitation: querySnapshot.docs.length > 0 ? querySnapshot.docs.length : 1,
      listOfCompletedSolicitation,
      percentSolicitations: percentSolicitationAux,
      loadingSolicitationReport: false
    });
  }

  async getIncidentes() {
    const db = myApp.firestore();
    let token = "";
    let companyData = {};
    let cnpj = "";
    let total = 0;
    let concluidos = 0;
    let porcentagem = 0.0;

    try {
      const response = await fetch("https://disc.beelegal.com.br/beelegal/Security/Login", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fb_id: process.env.REACT_APP_EPM_BEELEGAL_INTEGRACAO_FB_ID
        }),
        mode: "cors",
      });

      const responseJson = await response.json();

      token = responseJson.data.auth_ticket;
    } catch (error) {
      console.log("Erro ao gerar autenticação: " + error);
      token = "";
    }

    try {
      companyData = await db.collection('companies').doc(localStorage.getItem('currentCompany')).get();
      cnpj = companyData.data().cnpj;
    } catch (error) {
      console.log("Erro ao buscar CNPJ: " + error);
      companyData = {};
      cnpj = "";
    }
    
    try {
      var obj = {
        qid: "BEEPRIVACY_HOME_INCIDENTES:HOME_INCIDENTES",
        conditions: [
          {
            filterid: "EMPRESA",
            values: [cnpj],
          },
        ],
      };

      const response = await fetch("https://disc.beelegal.com.br/beelegal/Integration/Query", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          auth: token,
        },
        body: JSON.stringify(obj),
      });
      console.log('teste')

      const responseJson = await response.json();

      if (responseJson.list[0].TOTAL > 0) {
        total = responseJson.list[0].TOTAL;
        concluidos = responseJson.list[1].CONCLUIDOS;
        porcentagem = (concluidos * 100) / total;
      } else {
        total = 1;
        concluidos = 0;
        porcentagem = 0.0;
      }
    } catch (error) {
      console.log("Erro ao buscar incidentes: " + error);
      total = 0;
      concluidos = 0;
      porcentagem = 0.0;
    }

    this.setState({
      totalIncidentes: total,
      totalIncidentesConcluidos: concluidos,
      percentIncidentes: porcentagem,
      loadingIncidentes: false
    });
  }

  render() {
    return (
      <Grid
        container
        spacing={4}
      >
        {this.state.plans.map((plan) => (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
            <PlanItem
              id={plan.id}
              name={plan.name}
              totalActivities={plan.totalActivities}
              doneActivities={plan.doneActivities}
              donePercentage={plan.donePercentage}
            />
          </Grid>
        ))}
        {!this.state.loadingGap ?
          <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
            <PlanItem
              id='934'
              name='Relatório de GAPS'
              totalActivities={this.state.totalGaps}
              doneActivities={this.state.gapsConcluidos}
              donePercentage={this.state.percentGaps}
            />
          </Grid>
          :
          <div>
          </div>
        }

        {!this.state.loadingSolicitationReport ?
          <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
            <PlanItem
              id='935'
              name='Canal de Solicitações'
              totalActivities={this.state.listOfSolicitation}
              doneActivities={this.state.listOfCompletedSolicitation}
              donePercentage={this.state.percentSolicitations}
            />
          </Grid>
          :
          <div>
          </div>
        }

        {!this.state.loadingIncidentes ?
          <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
            <PlanItem
              id='936'
              name='Registros de Incidentes'
              totalActivities={this.state.totalIncidentes}
              doneActivities={this.state.totalIncidentesConcluidos}
              donePercentage={this.state.percentIncidentes}
            />
          </Grid>
          :
          <div>
          </div>
        }
      </Grid>
    );
  }
}

export default PlanList;