import React, { Component } from 'react';

import { Grid, Typography, Card } from '@material-ui/core';

class Title extends Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  render() {
    return (
      <Grid 
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={4}
      >
        <Grid item style={{width: "100%"}}>
          <Card style={{padding: "16px"}}>
            <Typography variant="h5" component="h5">
              {this.props.title ?? this.props.children}
            </Typography>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default Title;