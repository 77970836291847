import { 
  SET_PROCESS_AREA, 
  SET_PROCESS_AREA_NAME, 
  SET_PROCESS_ID, 
  SET_LOADING_PROCESS 
} from "./types";

const INITIAL_STATE = {
  processArea: "",
  processAreaName: "",
  processId: "",
  loadingProcess: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PROCESS_AREA:
      return {
        ...state,
        processArea: action.data,
      };
    case SET_PROCESS_AREA_NAME:
      return {
        ...state,
        processAreaName: action.data,
      };
    case SET_PROCESS_ID:
      return {
        ...state,
        processId: action.data,
      };
    case SET_LOADING_PROCESS:
      return {
        ...state,
        loadingProcess: action.data,
      };
  }

  return state;
};
