import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Typography,
  Grid,
  FormControl,
  Paper,
  Button,
  AppBar,
  Toolbar,
  ButtonBase,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Box,
} from "@material-ui/core";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import Pagination from "@material-ui/lab/Pagination";
import dashboardLogo from "../../../../res/images/dashboard_logo.png";
import routerHistory from "../../../../core/infrastructure/routerHistory";
import { formatDate, formatHour } from "../../../../shared/commands/formatDate";
import { setDenunceObj } from "../redux/ViewsPdfDenunceReducer";

//import google chart
import Chart from "react-google-charts";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

import theme from "../../../../core/theme.json";
import { isDefined } from "../../../../shared/commands/verifyDefinition";
import { changePage } from "../../../../dashboard/presentation/redux/actions";
import { setSolicitationId } from "../../../../solicitationChannel/redux/actions";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

//imports table
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Swal from "sweetalert2";

//const COLORS = ["#0088FE", "#FFBB28", "#FF8042", "#FF8122"];
//const RADIAN = Math.PI / 180;

/*const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};*/

class Denuncias extends Component {
  constructor(props) {
    super(props);

    this.state = {
      database: myApp.firestore(),
      solicitations: [],
      solicitationTypeState: [],
      activePage: 1,
      filterPersonType: "",
      filterSolicitationType: "",
      filterStatus: "",
      userInformation: JSON.parse(
        sessionStorage.getItem("@blue-legal/userInformation")
      ),
      userPermition: [],
      campany: localStorage.getItem("currentCompany"),
      loading: true,
      personTypeData: [],
      dateRecente: "",
      SolicitantData: [],
      statusfilter: [],
      statusData: [],
      filterDate: true,
      filterDueDate: null,
      TypeDenunce: [],
      listaDenuncias: [],
      TypeDenunceChart: [],
      SolicitationsChart: [],
      statusChart: [],
      filterSensitive: "",
    };

    this.filterSolicitations = this.filterSolicitations.bind(this);
    this.SensitiveMap = this.SensitiveMap.bind(this);
    this.filterSolicitationsPage = this.filterSolicitationsPage.bind(this);
    this.handleFilterByDateAsc = this.handleFilterByDateAsc.bind(this);
    this.handleFilterByDateDesc = this.handleFilterByDateDesc.bind(this);
    this.handleGetSoliciationTypes = this.handleGetSoliciationTypes.bind(this);
  }

  componentDidMount() {
    this.getSolicitations();
    this.handleGetSoliciationTypes();
    this.getDados();
    this.getUserPermition();
  }

  async getSolicitations() {
    try {
      let querySnapshot = await this.state.database
        .collection("denunce")
        .where("fk_company", "==", localStorage.getItem("currentCompany"))
        .get();

      this.setState({
        statusfilter: querySnapshot.docs.map((item) => item.data()),
        sensitivefilter: querySnapshot.docs.map((item) => item.data()),
      });

    } catch (err) {
      console.log("erro ao filtrar".err);
    }
  }
  async getUserPermition() {
    try {
      let aux = [];
      let userPermition = await this.state.database
        .collection("profiles_company_group")
        .where("fk_company_group", "==", this.state.campany)
        .get();
      aux.push(userPermition.docs.map((item) => item.data().permissions));
      this.setState({
        userPermition: aux,
      });
    } catch (err) {
      console.log("deu erro", err);
    }
  }
  async handleGetSoliciationTypes() {
    let querySnapshot = await this.state.database
      .collection("solicitationTypes")
      .where("in_company", "==", localStorage.getItem("currentCompany"))
      .get();

    let listOfSolicitationsTypes = [];

    for (let doc of querySnapshot.docs) {
      listOfSolicitationsTypes.push(doc.data());
    }

    this.setState({ solicitationTypeState: listOfSolicitationsTypes });
  }

  handleChangePageViewToPdf(denunce) {
    let aux = {
      protocol: denunce.protocol,
      status: denunce.status,
      date: denunce.date,
      additionalInformation: denunce.additionalInformation,
      relate_Type: denunce.relate_Type,
      company_relation: denunce.solicitant,
      Sensitive: denunce.sensitive,
      name: denunce.name,
      verificationConclusation: denunce.verificationConclusation,
      response: denunce.response,
      responsedenunce: denunce.responsedenunce,
      company_user_relation: denunce.company_user_relation,
      responseHistory: denunce.responseHistory,
      files_document_response: denunce.files_document_response,
    };
    console.log(aux);
    this.props.setDenunceObj(aux);
    this.props.changePage(44);
  }

  handlePaginationChange(event, page) {
    this.setState({
      activePage: page,
    });
  }

  filterSolicitations(denunce) {
    if (
      this.state.statusfilter !== "" &&
      denunce.status === this.state.statusfilter.map((item) => item.status)
    ) {
      return denunce;
    } else if (this.state.dateRecente == "Mais recente") {
      console.log("ok");
    } else if (this.state.filterStatus === "" && denunce.status !== "") {
      return denunce;
    } else if (this.state.filterStatus === "" && denunce.status !== "") {
      return denunce;
    } else if (
      this.state.filterStatus !== "" &&
      denunce.status === this.state.filterStatus
    ) {
      return denunce;
    } else if (this.state.filterStatus === "" && denunce.status !== "") {
      return denunce;
    } else if (
      this.state.filterStatus !== "" &&
      denunce.status === this.state.filterStatus
    ) {
      return denunce;
    } else if (
      this.state.filterStatus !== "" &&
      denunce.status === this.state.filterStatus
    ) {
      return denunce;
    } else if (this.state.filterStatus === "" && denunce.status !== "") {
      return denunce;
    }
  }

  SensitiveMap(denunce) {
    if (
      this.state.sensitivefilter !== "" &&
      denunce.sensitive ===
        this.state.sensitivefilter.map((item) => item.Sensitive)
    ) {
      return denunce;
    } else if (this.state.filterSensitive === "" && denunce.sensitive !== "") {
      return denunce;
    } else if (this.state.filterSensitive === "" && denunce.sensitive !== "") {
      return denunce;
    } else if (
      this.state.filterSensitive !== "" &&
      denunce.sensitive === this.state.filterSensitive
    ) {
      return denunce;
    } else if (this.state.filterSensitive === "" && denunce.sensitive !== "") {
      return denunce;
    } else if (
      this.state.filterSensitive !== "" &&
      denunce.sensitive === this.state.filterSensitive
    ) {
      return denunce;
    } else if (
      this.state.filterSensitive !== "" &&
      denunce.sensitive === this.state.filterSensitive
    ) {
      return denunce;
    } else if (this.state.filterSensitive === "" && denunce.sensitive !== "") {
      return denunce;
    }
  }

  handleFilterPersonTypeChange(event) {
    // this.setState({
    //   filterPersonType: event.target.value,
    // });
  }

  handleFilterSolicitationTypeChange(event) {
    // this.setState({
    //   filterSolicitationType: event.target.value,
    // });
  }

  handleOrderDataAsc(a, b) {
    return b.date - a.date;
  }

  handleOrderDataDes(a, b) {
    return a.date - b.date;
  }

  _altFilDTSol(e) {
    if (e === true) {
      this.handleFilterByDateAsc();
    } else {
      this.handleFilterByDateDesc();
    }
  }

  handleFilterByDateAsc() {
    let aux = this.state.listaDenuncias;
    aux.sort(this.handleOrderDataAsc);
    this.setState({
      listaDenuncias: aux,
    });
  }
  handleFilterByDateDesc() {
    let aux = this.state.listaDenuncias;
    aux.sort(this.handleOrderDataDes);
    console.log(this.state.userInformation.isAdmin );

    this.setState({
      listaDenuncias: aux,
    });
  }

  handleFilterDueDate(event) {}

  filterSolicitationsPage(denunce, index) {
    let max = this.state.activePage * 5;
    let min = max - 5;

    if (index >= min && index < max) {
      return denunce;
    }
  }

  cleanFilter() {
    this.setState({
      filterStatus: "",
      dateRecente: null,
      filterSensitive: "",
    });
    this.getDados();
  }

  handleEditSoliciation(id) {

        this.props.setSolicitationId(id);
        this.props.changePage(43);
    
 }
  renderLoading() {
    
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{ paddingTop: "50px" }}
      >
        <CircularProgress size={60} color={theme.secondary_color} />
      </Grid>
    );
  }

  async getDados() {
    let aux = [];
    try {
      let get = await myApp
        .firestore()
        .collection("denunce")
        .where("fk_company", "==", localStorage.getItem("currentCompany"))
        .get();

      // console.log('achou', get.docs.map(item=> item.data()))
      for (let doc of get.docs) {
        let denunce = {
          id: doc.ref.id,
          protocol: doc.data().protocol,
          date: doc.data().date,
          status: doc.data().status,
          solicitant: doc.data().company_relation,
          relate_Type: doc.data().relate_Type,
          sensitive: doc.data().Sensitive,
          additionalInformation: doc.data().additionalInformation,
          email: doc.data().email,
          name: doc.data().name,
          verificationConclusation: doc.data().verificationConclusation,
          response: doc.data().response,
          responsedenunce: doc.data().responsedenunce,
          company_user_relation: doc.data().company_user_relation,
          responseHistory: doc.data().responseHistory,
          files_document_response: doc.data().files_document_response,
        };

        aux.push(denunce);
        aux.slice(-1, [0]);
        console.log('achoiu',aux)
      }
      let statusData = [
        { name: "Aberto", value: 0 },
        { name: "Em andamento", value: 0 },
        { name: "Concluído", value: 0 },
      ];

      let SolicitantData = [
        { name: "Cliente", value: 0 },
        { name: "Colaborador", value: 0 },
        { name: "Fornecedor", value: 0 },
        { name: "Parceiros", value: 0 },
        { name: "Outros", value: 0 },
      ];
      let TypeDenunce = [
        { name: "Assédio", value: 0 },
        { name: "Fraude", value: 0 },
        { name: "Agressão", value: 0 },
        { name: "Discriminação", value: 0 },
        { name: "Violação de lei trabalhista", value: 0 },
        { name: "Violação de Lei ambiental", value: 0 },
        { name: "Violação de Lei Penal", value: 0 },
        { name: "Uso indevido de informações", value: 0 },
        { name: "Corrupção ou outros", value: 0 },
      ];

      let statusChart = [["Tipo", "valor"]];
      let SolicitationsChart = [["Tipo", "valor"]];
      let TypeDenunceChart = [["Tipo", "valor"]];

      let objIndex = 0;

      for (let i = 0; i < aux.length; i++) {
        if (aux[i].status === "Aberto") {
          objIndex = statusData.findIndex((obj) => obj.name === "Aberto");
          statusData[objIndex].value++;
        }

        if (aux[i].status === "Em andamento") {
          objIndex = statusData.findIndex((obj) => obj.name === "Em andamento");
          statusData[objIndex].value++;
        }

        if (aux[i].status === "Concluído") {
          objIndex = statusData.findIndex((obj) => obj.name === "Concluído");
          statusData[objIndex].value++;
        }
        if (aux[i].solicitant === "Cliente") {
          objIndex = SolicitantData.findIndex((obj) => obj.name === "Cliente");
          SolicitantData[objIndex].value++;
        }
        if (aux[i].solicitant === "Colaborador") {
          objIndex = SolicitantData.findIndex(
            (obj) => obj.name === "Colaborador"
          );
          SolicitantData[objIndex].value++;
        }
        if (aux[i].solicitant === "Fornecedor") {
          objIndex = SolicitantData.findIndex(
            (obj) => obj.name === "Fornecedor"
          );
          SolicitantData[objIndex].value++;
        }
        if (aux[i].solicitant === "Parceiros") {
          objIndex = SolicitantData.findIndex(
            (obj) => obj.name === "Parceiros"
          );
          SolicitantData[objIndex].value++;
        }
        if (aux[i].solicitant === "Outros") {
          objIndex = SolicitantData.findIndex((obj) => obj.name === "Outros");
          SolicitantData[objIndex].value++;
        }

        if (aux[i].relate_Type === "Assédio") {
          objIndex = TypeDenunce.findIndex((obj) => obj.name === "Assédio");
          TypeDenunce[objIndex].value++;
        }

        if (aux[i].relate_Type === "Discriminação") {
          objIndex = TypeDenunce.findIndex(
            (obj) => obj.name === "Discriminação"
          );
          TypeDenunce[objIndex].value++;
        }
        if (aux[i].relate_Type === "Fraude") {
          objIndex = TypeDenunce.findIndex((obj) => obj.name === "Fraude");
          TypeDenunce[objIndex].value++;
        }
        if (aux[i].relate_Type === "Violação de lei trabalhista") {
          objIndex = TypeDenunce.findIndex(
            (obj) => obj.name === "Violação de lei trabalhista"
          );
          TypeDenunce[objIndex].value++;
        }
        if (aux[i].relate_Type === "Violação de Lei ambiental") {
          objIndex = TypeDenunce.findIndex(
            (obj) => obj.name === "Violação de Lei ambiental"
          );
          TypeDenunce[objIndex].value++;
        }
        if (aux[i].relate_Type === "Violação de Lei Penal") {
          objIndex = TypeDenunce.findIndex(
            (obj) => obj.name === "Violação de Lei Penal"
          );
          TypeDenunce[objIndex].value++;
        }
        if (aux[i].relate_Type === "Uso indevido de informações") {
          objIndex = TypeDenunce.findIndex(
            (obj) => obj.name === "Uso indevido de informações"
          );
          TypeDenunce[objIndex].value++;
        }
        if (aux[i].relate_Type === "Corrupção ou outros") {
          objIndex = TypeDenunce.findIndex(
            (obj) => obj.name === "Corrupção ou outros"
          );
          TypeDenunce[objIndex].value++;
        }
      }

      this.setState({
        SolicitantData,
        statusData,
        TypeDenunce,
      });

      for (let doc of this.state.statusData) {
        statusChart.push([doc.name, doc.value - 1]);
      }

      for (let i = 0; i < this.state.statusData.length; i++) {
        if (statusChart.some((a) => a[0] === this.state.statusData[i].name)) {
          for (let t = 0; t < statusChart.length; t++) {
            if (statusChart[t][0] === this.state.statusData[i].name) {
              statusChart[t][1] += 1;
            }
          }
        }
      }

      for (let doc of this.state.SolicitantData) {
        SolicitationsChart.push([doc.name, 0]);
      }

      for (let i = 0; i < aux.length; i++) {
        if (SolicitationsChart.some((a) => a[0] === aux[i].solicitant)) {
          for (let t = 0; t < SolicitationsChart.length; t++) {
            if (SolicitationsChart[t][0] === aux[i].solicitant) {
              SolicitationsChart[t][1] += 1;
            }
          }
        }
      }

      for (let doc of this.state.TypeDenunce) {
        TypeDenunceChart.push([doc.name, 0]);
      }

      for (let i = 0; i < aux.length; i++) {
        if (TypeDenunceChart.some((a) => a[0] === aux[i].relate_Type)) {
          for (let t = 0; t < TypeDenunceChart.length; t++) {
            if (TypeDenunceChart[t][0] === aux[i].relate_Type) {
              TypeDenunceChart[t][1] += 1;
            }
          }
        }
      }

      this.setState({
        statusChart,
        SolicitationsChart,
        TypeDenunceChart,
      });
    } catch (errorGetTickets) {
      console.error("ao gerar graphics", errorGetTickets);
    }
    this.setState({
      listaDenuncias: aux,
      loading: false,
    });

    this.handleFilterByDateAsc();
  }

  render() {
    const cardStyle = {
      padding: "15px",
      width: "100%",
    };

    const protocolTitle = {
      color: theme.secondary_color,
      fontSize: "20px",
    };

    return (
      <>
        {this.state.loading ? (
          this.renderLoading()
        ) : (
          <>
            <Grid
              component={Paper}
              container
              direction="row"
              alignItems="center"
              style={{
                padding: "10px",
                marginTop: 5,
              }}
              spacing={2}
            >
              <Grid
                item
                container
                component={Paper}
                direction="column"
                alignItems="center"
                style={{ width: "45%", margin: 10 }}
              >
                <Grid item>
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{ marginTop: 10 }}
                  >
                    Tipos de denunciantes
                  </Typography>
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <Chart
                    wrap
                    width={"100%"}
                    height={"200px"}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={this.state.SolicitationsChart}
                    options={{ title: "Tipos de denunciantes" }}
                    rootProps={{ "data-testid": "1" }}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                component={Paper}
                direction="column"
                alignItems="center"
                style={{ width: "45%", margin: 10 }}
              >
                <Grid item>
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{ marginTop: 10 }}
                  >
                    Tipos de denúncias
                  </Typography>
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <Chart
                    wrap
                    width={"100%"}
                    height={"200px"}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={this.state.TypeDenunceChart}
                    options={{ title: "Tipos de denúncias" }}
                    rootProps={{ "data-testid": "1" }}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                component={Paper}
                direction="column"
                alignItems="center"
                style={{ width: "45%", margin: 10 }}
              >
                <Grid item>
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{ marginTop: 10 }}
                  >
                    Status
                  </Typography>
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <Chart
                    wrap
                    width={"100%"}
                    height={"200px"}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={this.state.statusChart}
                    options={{ title: "status" }}
                    rootProps={{ "data-testid": "1" }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              component={Paper}
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              style={{ padding: "10px", marginTop: 20 }}
            >
              <Grid
                item
                container
                alignItems="center"
                justifyContent="flex-start"
                xs={2}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    // routerHistory.push("/solicitation/new/");
                    this.cleanFilter();
                  }}
                  style={{
                    color: theme.secondary_color,
                    fontSize: "15px",
                    backgroundColor: !this.state.userInformation.permissions
                      .insert
                      ? theme.opacity
                      : theme.primary_color,
                  }}
                  disabled={!this.state.userInformation.permissions.insert}
                >
                  Limpar
                </Button>
              </Grid>

              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                md={2}
                sm={4}
              >
                <FormControl
                  size="small"
                  variant="outlined"
                  style={{ width: "90%" }}
                >
                  <InputLabel>Data de denúncia</InputLabel>
                  <Select
                    label="Data de Denúncia"
                    value={this.state.dateRecente}
                    onChange={(e) => {
                      this._altFilDTSol(e.target.value);
                      this.setState({ dateRecente: e.target.value });
                    }}
                  >
                    <MenuItem value={true}>Mais recente</MenuItem>
                    <MenuItem value={false}>Menos recente</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                md={2}
                sm={4}
              >
                <FormControl
                  size="small"
                  variant="outlined"
                  style={{ width: "90%" }}
                >
                  <InputLabel>Status</InputLabel>
                  <Select
                    label="Status"
                    value={this.state.filterStatus}
                    onChange={(event) => {
                      this.setState({
                        filterStatus: event.target.value,
                      });
                    }}
                  >
                    <MenuItem value={""}></MenuItem>
                    <MenuItem value={"Aberto"}>Aberto</MenuItem>
                    <MenuItem value={"Em andamento"}>Em andamento</MenuItem>
                    <MenuItem value={"Concluído"}>Concluído</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                md={2}
                sm={4}
              >
                <FormControl
                  size="small"
                  variant="outlined"
                  style={{ width: "90%" }}
                >
                  <InputLabel>Sensibilidade</InputLabel>
                  <Select
                    label="Sensibilidade"
                    value={this.state.filterSensitive}
                    onChange={(event) => {
                      this.setState({ filterSensitive: event.target.value });
                    }}
                  >
                    <MenuItem value={"sensível"}>sensível</MenuItem>
                    <MenuItem value={"Muito sensível"}>Muito sensível</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              spacing={1}
              style={{ marginTop: 5 , paddingLeft:"2%" }}
            >
              <TableContainer style={{ maxHeight: "80vw" }}>
                <Table stickyHeader size="medium">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" style={{ fontWeight: "Bold" }}>
                        Protocolo
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontWeight: "Bold" }}
                      ></TableCell>
                      <TableCell align="center" style={{ fontWeight: "Bold" }}>
                        Data de denúncia
                      </TableCell>
                      {/* <TableCell align="center" style={{ fontWeight: 'Bold' }}>Data de Conclusão</TableCell> */}
                      <TableCell
                        align="center"
                        style={{ fontWeight: "Bold" }}
                      ></TableCell>
                      <TableCell
                        align="center"
                        style={{ fontWeight: "Bold" }}
                      ></TableCell>
                      <TableCell align="center" style={{ fontWeight: "Bold" }}>
                        Status
                      </TableCell>
                      <TableCell align="center" style={{ fontWeight: "Bold" }}>
                        Sensibilidade
                      </TableCell>
                      <TableCell align="center" style={{ fontWeight: "Bold" }}>
                        Ações
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state?.listaDenuncias
                      .filter(this.filterSolicitations)
                      .filter(this.filterSolicitationsPage)
                      .filter(this.SensitiveMap)
                      .map((denunce, k) => (
                        <TableRow
                          key={k}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          style={
                            k % 2
                              ? { background: "white" }
                              : { background: "#e3e3e3" }
                          }
                        >
                          <TableCell
                            onClick={() => {
                              this.handleEditSoliciation(denunce.id);
                            }}
                            component="th"
                            scope="row"
                            align="center"
                            style={{ fontWeight: "Bold" }}
                          >
                            {denunce.protocol}
                          </TableCell>
                          <TableCell
                            onClick={() => {
                              this.handleEditSoliciation(denunce.id);
                            }}
                            component="th"
                            scope="row"
                            align="center"
                            style={{ fontWeight: "Bold" }}
                          ></TableCell>
                          <TableCell
                            onClick={() => {
                              this.handleEditSoliciation(denunce.id);
                            }}
                            component="th"
                            scope="row"
                            align="center"
                            style={{ fontWeight: "Bold" }}
                          >
                            {Intl.DateTimeFormat("pt-br").format(denunce.date)}
                          </TableCell>
                          <TableCell
                            onClick={() => {
                              this.handleEditSoliciation(denunce.id);
                            }}
                            component="th"
                            scope="row"
                            align="center"
                            style={{ fontWeight: "Bold" }}
                          ></TableCell>

                          <TableCell
                            onClick={() => {
                              this.handleEditSoliciation(denunce.id);
                            }}
                            component="th"
                            scope="row"
                            align="center"
                            style={{ fontWeight: "Bold" }}
                          ></TableCell>

                          <TableCell
                            onClick={() => {
                              this.handleEditSoliciation(denunce.id);
                            }}
                            component="th"
                            scope="row"
                            align="center"
                            style={{ fontWeight: "Bold" }}
                          >
                            {denunce.status}
                          </TableCell>

                          <TableCell
                            onClick={() => {
                              this.handleEditSoliciation(denunce.id);
                            }}
                            component="th"
                            scope="row"
                            align="center"
                            style={{ fontWeight: "Bold" }}
                          >
                            {denunce.sensitive ? (
                              denunce.sensitive
                            ) : (
                              <span style={{ color: "red" }}>
                                Não Respondido
                              </span>
                            )}
                          </TableCell>

                          {denunce.status == "Concluído" ? (
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                              style={{ fontWeight: "Bold" }}
                            >
                              <Button
                                onClick={() =>
                                  this.handleChangePageViewToPdf(denunce)
                                }
                                disabled={!denunce.id}
                                style={{
                                  background: denunce.id
                                    ? theme.primary_color
                                    : "#ccc",
                                  color: theme.secondary_color,
                                }}
                              >
                                Visualizar PDF
                              </Button>
                            </TableCell>
                          ):
                          <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ fontWeight: "Bold" }}
                        >
                          <Button
                            onClick={() =>
                              this.handleChangePageViewToPdf(denunce)
                            }
                            disabled={true}
                            style={{
                              background: theme.disabled_color,
                              color: '#fff',
                            }}
                          >
                            Vizualizar PDF
                          </Button>
                        </TableCell>
                          }
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </>
        )}
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ paddingTop: "20px" }}
        >
          {/* <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  routerHistory.push("/");
                }}
                style={{ color: "white", backgroundColor: theme.secondary_color }}
              >
                Voltar
              </Button>
            </Grid> */}
          <Grid style={{ flexGrow: 1 }} />
          <Grid item>
            {this.state.listaDenuncias.filter(this.filterSolicitations).length >
            5 ? (
              <Pagination
                count={
                  this.state.listaDenuncias.filter(this.filterSolicitations)
                    .length %
                    5 ===
                  0
                    ? this.state.listaDenuncias.filter(this.filterSolicitations)
                        .length / 5
                    : Math.floor(
                        this.state.listaDenuncias.filter(
                          this.filterSolicitations
                        ).length / 5
                      ) + 1
                }
                style={{ color: theme.secondary_color }}
                page={this.state.activePage}
                onChange={(event, page) => {
                  this.handlePaginationChange(event, page);
                }}
              />
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
        {/* </div> */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changePage: (page) => dispatch(changePage(page)),
  setSolicitationId: (solicitationId) =>
    dispatch(setSolicitationId(solicitationId)),
  setDenunceObj: (props) => dispatch(setDenunceObj(props)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Denuncias);
