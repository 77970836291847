//imports react
import React, { useState, useEffect } from 'react';

//imports redux
import { useDispatch } from "react-redux";
import { changePage } from "../redux/actions";

//imports material
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Grid,
  TextField,
  Button,
  IconButton,
  TableContainer,
  Card,
  CircularProgress,

} from '@material-ui/core'; import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowBackIosTwoToneIcon from "@material-ui/icons/ArrowBackIosTwoTone";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from "@material-ui/lab/Pagination";

//imports firebase
import myApp from '../../../core/infrastructure/firebaseConfig';
import "firebase/compat/firestore";

//import do tema
import theme from '../../../core/theme.json'


const ComplementaryLegislation = () => {
  const [company, setCompany] = useState(localStorage.getItem('currentCompany'));
  const [legislation, setLegislation] = useState({
    Law: '',
    Identification: '',
    Content: '',
    in_company: company
  });
  const [dialogs, setDialogs] = useState({
    register: false,
    delete: false,
    edit: false
  });
  const [legislationsDb, setLegislationsDb] = useState([]);
  const [legislationsDbId, setLegislationsDbId] = useState([]);
  const [legislationsDbEdit, setLegislationsDbEdit] = useState([]);
  const [activePage, setActivePage] = useState(1);

  //dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    getLegislations();
  }, []);

  const getLegislations = async () => {
    let legislationsAux = []
    let querySnapshot = await myApp.firestore().collection("complementaryLegislation").where("in_company", "==", company).get();
    for (let doc of querySnapshot.docs) {
      legislationsAux.push({
        id: doc.id,
        Law: doc.data().Law,
        Identification: doc.data().Identification,
        Content: doc.data().Content
      })
    }
    setLegislationsDb(legislationsAux);
  }

  //função responsável por preencher states da legislação
  const handleChange = (prop) => (event) => {
    setLegislation({ ...legislation, [prop]: event.target.value });
  };
  //função responsável por preencher states da legislação
  const handleChangeEdit = (prop) => (event) => {
    setLegislationsDbEdit({ ...legislationsDbEdit, [prop]: event.target.value });
  };

  //função responsável por cadastar nova lei
  const handleRegistrationOfLegislation = async () => {
    await myApp.firestore().collection("complementaryLegislation").add({
      Law: legislation.Law,
      Identification: legislation.Identification,
      Content: legislation.Content,
      in_company: company
    }).then(() => {
      setDialogs({
        ...dialogs,
        register: true,
      })
      setLegislation({
        ...legislation,
        Law: '',
        Identification: '',
        Content: ''
      })
      getLegislations()
    });
  }

  //função responsável por editar a lei
  const handleEditLegislation = async () => {
    await myApp.firestore().collection("complementaryLegislation").doc(legislationsDbEdit.id).update({
      Law: legislationsDbEdit.Law,
      Identification: legislationsDbEdit.Identification,
      Content: legislationsDbEdit.Content,
    }).then(() => {
      getLegislations()
      setDialogs({
        ...dialogs,
        edit: false
      })
    });
  }

  //função responsável por fechar os dialogos
  const handleCloseDialogs = (type) => {
    switch (type) {
      case 'register':
        setDialogs({
          ...dialogs,
          register: false
        })
        break;
      case 'delete':
        setDialogs({
          ...dialogs,
          delete: false
        })
        break;
      case 'edit':
        setDialogs({
          ...dialogs,
          edit: false
        })
        break;
    }
  };

  //função para editar/excluir legislação
  const handleAcion = async (type, item) => {
    switch (type) {
      case 'D':
        setLegislationsDbId(item.id)
        setDialogs({
          ...dialogs,
          delete: true
        })
        break;
      case 'E':
        setLegislationsDbEdit(item)
        setDialogs({
          ...dialogs,
          edit: true
        })
        break;
    }
  }

  //função para deletar o ativo
  const handleDelete = async () => {
    await myApp.firestore().collection("complementaryLegislation").doc(legislationsDbId).delete().then(() => {
      getLegislations()
      setDialogs({
        ...dialogs,
        delete: false
      })
    });
  }

  //filtrando paginas
  const filterPage = (solicitation, index) => {
    let max = activePage * 4;
    let min = max - 4;

    if (index >= min && index < max) {
      return solicitation;
    }
  }

  //mudando de pagina
  const handleChangePage = (event, page) => {
    setActivePage(page)
  }

  return (
    <Grid
      container
    >
      <Grid
        item
        style={{
          width: "100%"
        }}
      >
        <Card
          style={{
            padding: "16px"
          }}
        >
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid
              item
              style={{
                width: "5%"
              }}
            >
              <IconButton
                size="small"
                style={{
                  marginLeft: "10px"
                }}
                onClick={() => dispatch(changePage(14))}
              >
                <ArrowBackIosTwoToneIcon />
              </IconButton>
            </Grid>
            <Grid
              item
              style={{
                width: "95%"
              }}
            >
              <Typography
                variant="h5"
                component="h5"
              >
                Legislação
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      {/*Cadastro */}
      <Grid
        container
        item
        direction="row"
        style={{
          padding: '10px'
        }}
      >
        <Accordion
          style={{
            width: '100%'
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography
              variant="h6"
              component="h6"
            >
              Cadastro
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              spacing={2}
              direction="column"
            >
              <Grid
                item
                container
                direction="row"
                spacing={2}
              >
                <Grid
                  item
                  style={{
                    width: '50%'
                  }}
                >
                  <TextField
                    label="Lei"
                    value={legislation.Law}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange('Law')}
                  />
                </Grid>

                <Grid
                  item
                  style={{
                    width: '50%'
                  }}
                >
                  <TextField
                    label="Identificação"
                    value={legislation.Identification}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange('Identification')}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
              >
                <Grid
                  item
                  style={{
                    width: '100%'
                  }}
                >
                  <TextField
                    label="Teor"
                    value={legislation.Content}
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={6}
                    onChange={handleChange('Content')}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="center"
                alignContent="center"
              >
                <Grid
                  item
                  container
                  style={{
                    width: '30%',
                  }}
                >
                  <Button
                    onClick={handleRegistrationOfLegislation}
                    fullWidth
                    style={{
                      color: '#192a40',
                      backgroundColor: '#38f205',
                    }}
                  >
                    <Typography
                    >
                      Cadastrar
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/*Lisa de leis cadastradas */}
      <Grid
        container
        item
        direction="row"
        style={{
          padding: '10px'
        }}
      >
        <Accordion
          style={{
            width: '100%'
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography
              variant="h6"
              component="h6"
            >
              Lista de leis cadastradas
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
            >
              {
                legislationsDb.length === 0 ?
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    style={{ width: "100%", marginTop: 40 }}
                  >
                    <Grid item>
                      <CircularProgress size={50} color={theme.secondary_color} />
                    </Grid>
                  </Grid>
                  :
                  <Grid
                    item
                    container
                    direction="column"
                    style={{ height: 600, width: '100%' }}
                  >
                    <TableContainer style={{ maxHeight: '50vh' }}>
                      <Table stickyHeader size="large">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                              Lei
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                              Identificação
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                              Teor
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                              Ações
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            legislationsDb
                              .filter(filterPage)
                              .map((item, k) => (
                                <TableRow
                                  key={k}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  style={k % 2 ? { background: "white" } : { background: "#e3e3e3" }}
                                >
                                  <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                    {item.Law}
                                  </TableCell>
                                  <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                    {item.Identification}
                                  </TableCell>
                                  <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                    {item.Content}
                                  </TableCell>
                                  <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="center"
                                    >
                                      <Tooltip title="Editar" onClick={() => handleAcion('E', item)}>
                                        <IconButton aria-label="Editar">
                                          <EditIcon style={{ color: '#38f205' }} />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Apagar" onClick={() => handleAcion('D', item)}>
                                        <IconButton aria-label="Apagar">
                                          <DeleteIcon style={{ color: '#f00' }} />
                                        </IconButton>
                                      </Tooltip>
                                    </Grid>
                                  </TableCell>
                                </TableRow>
                              ))
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {
                      <Grid
                        item
                        container
                        direction="column"
                      >
                        {legislationsDb.length >
                          4 ? (
                          <Pagination
                            color="primary"
                            count={
                              legislationsDb
                                .length %
                                4 ===
                                0
                                ? legislationsDb.length / 4
                                : Math.floor(
                                  legislationsDb.length / 4
                                ) + 1
                            }
                            page={activePage}
                            onChange={(event, page) => {
                              handleChangePage(event, page);
                            }}
                            showFirstButton
                            showLastButton
                          />
                        ) : (
                          <></>
                        )}
                      </Grid>
                    }
                  </Grid>
              }
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Dialogo para informar que 
					ativo foi editado com sucesso
				*/}
      <Dialog open={dialogs.register} onClose={() => handleCloseDialogs('register')}>
        <DialogTitle
          style={{
            color: theme.quaternary_color,
            backgroundColor: theme.secondary_color,
            textAlign: "center",
          }}
        >
          ATENÇÃO!
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              textAlign="center"
            >
              <DialogContentText>
                <Typography
                  variant="h6"
                  component="h6"
                >
                  Legislação cadastrada com sucesso
                </Typography>
              </DialogContentText>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseDialogs('register')}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      {/* Informa o usuario que esta proximo de deletar a legislação
				*/}
      <Dialog open={dialogs.delete} onClose={() => handleCloseDialogs('delete')}>
        <DialogTitle
          style={{
            color: theme.quaternary_color,
            backgroundColor: theme.secondary_color,
            textAlign: "center",
          }}
        >
          ATENÇÃO!
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              textAlign="center"
            >
              <DialogContentText>
                <Typography
                  variant="h6"
                  component="h6"
                >
                  VOCÊ ESTA PRESTES A EXCLUIR UMA LEGISLAÇÃO CADASTRADA!
                  <br />
                  TEM CERTEZA DESTA AÇÃO?
                </Typography>
              </DialogContentText>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDelete}
            style={{
              color: '#192a40',
              backgroundColor: theme.warning_color,
            }}
          >
            Deletar
          </Button>
          <Button
            onClick={() => handleCloseDialogs('delete')}
            autoFocus
            style={{
              color: '#fff',
              backgroundColor: '#38f205',
            }}
          >
            cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo para editar i ativo
				*/}
      <Dialog open={dialogs.edit} onClose={() => handleCloseDialogs('edit')}>
        <DialogTitle
          style={{
            color: theme.quaternary_color,
            backgroundColor: theme.secondary_color,
            textAlign: "center",
          }}
        >
          ATENÇÃO!
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
          >
            <Grid
              container
              item
              direction="row"
              style={{
                padding: '10px'
              }}
            >
              <Grid
                container
                spacing={2}
                direction="column"
              >
                <Grid
                  item
                  container
                  direction="row"
                  spacing={2}
                >
                  <Grid
                    item
                    style={{
                      width: '50%'
                    }}
                  >
                    <TextField
                      label="Lei"
                      value={legislationsDbEdit.Law}
                      variant="outlined"
                      fullWidth
                      onChange={handleChangeEdit('Law')}
                    />
                  </Grid>

                  <Grid
                    item
                    style={{
                      width: '50%'
                    }}
                  >
                    <TextField
                      label="Identificação"
                      value={legislationsDbEdit.Identification}
                      variant="outlined"
                      fullWidth
                      onChange={handleChangeEdit('Identification')}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                >
                  <Grid
                    item
                    style={{
                      width: '100%'
                    }}
                  >
                    <TextField
                      label="Teor"
                      value={legislationsDbEdit.Content}
                      variant="outlined"
                      fullWidth
                      multiline
                      minRows={6}
                      onChange={handleChangeEdit('Content')}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="center"
                  alignContent="center"
                >
                  <Grid
                    item
                    container
                    style={{
                      width: '30%',
                    }}
                  >
                    <Button
                      onClick={handleEditLegislation}
                      fullWidth
                      style={{
                        color: '#192a40',
                        backgroundColor: '#38f205',
                      }}
                    >
                      <Typography
                      >
                        Editar
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseDialogs('edit')}
            autoFocus
            style={{
              color: '#192a40',
              backgroundColor: theme.warning_color,
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid >
  );
}

export default ComplementaryLegislation;