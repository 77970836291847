import React, { Component } from "react";
import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import firebase from 'firebase/compat/app';

import {
  Button,
  FormControl,
  Paper,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  CircularProgress, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { format } from "date-fns";
import { connect } from "react-redux";

import PublishIcon from "@material-ui/icons/Publish";
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from "@material-ui/icons/DeleteForever";

import theme from "../../../../core/theme.json";

import { isUndefined } from "lodash";

class ActiveAreaList extends Component {
  constructor(props) {
    super(props);
    this.handleInputFileClick = this.handleInputFileClick.bind(this);

    this.db = myApp.firestore();

    this.state = {
      activy: [],
      planType: [],
      fileContents: "",
      fileName: "",
      extension: "",
      dateAdded: "",
      fileSize: 0,
      selectedId: null,
      dialog: false,
      stateIdFile: '',
      progressTesste: '',
      openDialodDeleteFile: false,
    };
  }

  async componentDidMount() {
    await this.getItemList();
    this.getPlanTypeList();
  }

  async getPlanTypeList() {
    let database = myApp.firestore();

    try {
      let data = await database.collection("plan_type").get();
      let _planType = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

      this.setState({
        planType: _planType,
      });
    } catch (error) {
      console.error(error);
    }
  }

  handleInputFileClick = async (e) => {
    //criando referencias para o myApp/firestore
    let db = myApp.firestore();

    //buscando dados
    let querySnapshot = await db.collection('activities').doc(e).get();

    //recebendo o nome do arquivo
    let filePathName = querySnapshot.data().filePathName;

    //criando referencias para o myApp/storage
    let storage = myApp.storage();
    let storageRef = storage.ref();

    //verificando se o item ja possui arquivo anexado, se tiver antes de mandar um novo ele apaga o atual
    if (querySnapshot.data().filePathName) {
      let desertRef = storageRef.child(filePathName)
      desertRef.delete()
    } else {
      console.log('Sem download')
    }

    //selecionando arquivo
    this.inputElement.click();
    this.setState({ stateIdFile: e })
  };

  handleChangeFile(e) {

    //pegando o arquivo
    let file = e.target.files[0];

    //pegando norme do arquivo
    let fileName = e.target.files[0].name;

    //separando nome do arquivo em nome e extensão 
    let fileDotsArray = fileName.split(".");

    //.shift() para pegar o primeiro item do array criado pelo split, que é o nome do arquivo
    let firstName = fileDotsArray.shift()

    //criando chave de identificação unica para o arquivo, caso o usuario envie dois arquivos com mesmo nome
    let fileNameDb = myApp.database().ref().push().key + '-' + firstName;

    //caminho para o Storage
    let filePath = 'activities/' + localStorage.getItem('currentCompany') + '/' + fileNameDb;

    //criando referencia de arquivo
    let storage = myApp.storage().ref(filePath)

    //enviando arquivo
    let upload = storage.put(file)

    this.trackUpload(upload).then(() => {
      storage.getDownloadURL().then((downloadUrl) => {
        myApp.firestore().collection("activities").doc(this.state.stateIdFile).update({
          fileName: fileName,
          filePathName: filePath,
          filePathDownload: downloadUrl
        });
        this.setState({ stateIdFile: '' })
      })
    })
  }

  //criando barra de upload
  trackUpload(upload) {
    return new Promise((resolve, reject) => {
      upload.on('state_changed',
        (snapShot) => {
          console.log(snapShot.bytesTransferred / snapShot.totalBytes * 100 + '%')
          this.setState({ progressTesste: snapShot.bytesTransferred / snapShot.totalBytes * 100 })
        }, (error) => {
          console.log(error)
          reject(error)
        }, () => {
          console.log('Arquivo enviado com sucesso');
          this.setState({ progressTesste: '' })
          resolve()
        }
      )
    })
  }

  async addDocument(file) {
    const { selectedId } = this.state;
    const docRef = this.db.collection("activities").doc(selectedId);
    await docRef.update({ file }).catch((error) => {
      alert("Erro ao anexar arquivo");
      console.log("- Erro de anexo -", error);
    });
  }

  async handleDownloadButton(activy) {

    //processo de download
    let url = activy.filePathDownload;
    let name = activy.fileName;
    fetch(url)
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);

        this.setState({ openDilogFileDownload: false })
      })
      .catch(() => alert('Download falhou!'));
  }

  async handleDeleteButton(activyId) {
    //this.db.collection("activities").doc(activyId).update({ file: null });
    let tarefa = this.state.stateIdFile

    //criando referencias para o myApp/firestore
    let db = myApp.firestore();

    //buscando dados
    let querySnapshot = await db.collection('activities').doc(tarefa).get();

    //recebendo o nome do arquivo
    let filePathName = querySnapshot.data().filePathName;

    //criando referencias para o myApp/storage
    let storage = myApp.storage();
    let storageRef = storage.ref();

    if (filePathName) {
      console.log('Excluido')
      //excluindo arquivo
      let desertRef = storageRef.child(filePathName)
      desertRef.delete()
    }
    else {
      console.log('Sem arquivo para excluir')
    }

    myApp.firestore().collection("activities").doc(tarefa).update({
      fileName: '',
      filePathName: '',
      filePathDownload: ''
    })
    this.setState({ stateIdFile: '', openDialodDeleteFile: false })
  }

  async getItemList() {
    let database = myApp.firestore();
    let _companyId = localStorage.getItem("currentCompany");

    let self = this;

    try {
      await database
        .collection("activities")
        .where("fk_companies", "==", _companyId)
        .where("fk_areas", "==", this.props.area)
        .onSnapshot(function (activyData) {
          let _activyData = activyData.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          self.setState({
            activy: _activyData,
          });
        });
    } catch (error) {
      console.error(error);
    }
  }

  toDateTime(date) {
    let verify = typeof date;
    if (verify === 'object') {
      if (isUndefined(date.seconds)) {
        return format(date, "dd/MM/yyyy");
      } else {
        return format(new Date(date.seconds * 1000), "dd/MM/yyyy");
      }
    } else {
      let d = new Date(date)
      return d.toLocaleDateString('pt-BR', { timeZone: 'UTC' })
    }
  }

  renderTable(planType) {
    var count = 0;

    for (var i = 0; i < this.state.activy.length; i++) {
      if (this.state.activy[i].fk_plan_type === planType.id) {
        count++;

        break;
      }
    }

    if (count > 0) {
      return (
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="flex-start"
          spacing={2}
          style={{ width: "100%" }}
        >
          <Grid item>
            <Typography variant="h6" component="h6">
              {planType.name}
            </Typography>
          </Grid>
          <Grid item style={{ width: "100%" }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>TÓPICO</TableCell>
                    <TableCell>DATA PREVISTA</TableCell>
                    <TableCell>DATA DE CONCLUSÃO</TableCell>
                    <TableCell>CONCLUÍDO</TableCell>
                    <TableCell>RESPONSÁVEL</TableCell>
                    <TableCell>ANEXAR ARQUIVO</TableCell>
                    <TableCell>BAIXAR ARQUIVO</TableCell>
                    <TableCell>EXCLUIR ARQUIVO</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.activy.map((activy) => {
                    return this.renderCells(activy, planType);
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item style={{ height: "20px" }} />
        </Grid>
      );
    } else {
      return <div />;
    }
  }

  //abrindo dialog para confirmar a exclusão
  handleDeleteFileAlert(id) {
    console.log('Deletando arquivo do item: ', id)
    this.setState({ stateIdFile: id, openDialodDeleteFile: true })
  }

  renderCells(activy, planType) {
    if (activy.fk_plan_type === planType.id) {
      return (
        <TableRow key={activy.id}>
          <TableCell onClick={() => console.log(activy.id)} >{activy.name}</TableCell>
          <TableCell>
            {activy.date !== null ? this.toDateTime(activy.date) : ""}
          </TableCell>
          <TableCell>
            {activy.doneDate !== null
              ? this.toDateTime(activy.doneDate)
              : ""}
          </TableCell>
          <TableCell>
            <Switch
              checked={activy.done}
              onChange={() => {
                this.setComplete(activy.id);
              }}
              color="primary"
            />
          </TableCell>
          <TableCell>{activy.owner}</TableCell>
          <TableCell>
            <Button
              onClick={() => this.handleInputFileClick(activy.id)}
              disabled={activy.fileName}
            >
              <PublishIcon />
            </Button>
            <FormControl variant="outlined">
              <input
                id="test"
                type="file"
                style={{ display: "none" }}
                onChange={(event) => this.handleChangeFile(event)}
                ref={(input) => (this.inputElement = input)}
              />
              <a
                href="data:application/base64,"
                style={{ display: "none" }}
                ref={(a) => (this.anchorElement = a)}
                target="_blank"
              ></a>
            </FormControl>
          </TableCell>
          <TableCell>
            <Button
              onClick={() => this.handleDownloadButton(activy)}
              disabled={!activy.fileName}
            >
              <GetAppIcon />
            </Button>
          </TableCell>
          <TableCell>
            <Button
              onClick={() => this.handleDeleteFileAlert(activy.id)}
              disabled={!activy.fileName}
            >
              <DeleteIcon />
            </Button>
          </TableCell>
        </TableRow>
      );
    } else {
      return <div />;
    }
  }

  async setComplete(id) {
    let database = myApp.firestore();

    try {
      let data = await database.collection("activities").doc(id).get();
      let activity = data.data();

      activity.done = !activity.done;

      if (activity.done) {
        activity.doneDate = firebase.firestore.Timestamp.now();
      } else {
        activity.doneDate = null;
      }

      await database.collection("activities").doc(id).update(activity);
    } catch (error) {
      console.error(error);
    }
  }

  handleDialog() {
    this.setState({
      dialog: !this.state.dialog,
    });
  }

  handleClose() {
    this.setState({
      openDialodDeleteFile: false
    })
  }

  render() {
    return (
      <Paper className="paper">
        <Grid container direction="column" justify="center" alignItems="center">
          {
            this.state.progressTesste != '' ?
              <Grid
                container
                justifyContent='center'
                alignItems='center'
                style={{ width: '100%', height: '40px' }}
              >
                <CircularProgress variant="determinate" value={this.state.progressTesste} />
              </Grid> : <div></div>
          }
          {this.state.planType.map((planType) => (
            <Grid item style={{ width: "100%" }}>
              {this.renderTable(planType)}
            </Grid>
          ))}

          {/* Dialogo de exclusão do anexo */}
          <Dialog open={this.state.openDialodDeleteFile} onClose={this.handleClose}>
            <DialogTitle
              style={{
                color: theme.quaternary_color,
                backgroundColor: theme.secondary_color,
                textAlign: "center",
              }}
            >
              ATENÇÃO!
            </DialogTitle>
            <DialogContent>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item textAlign="center">
                  <DialogContentText>
                    VOCÊ TEM CERTEZA QUE DESEJA EXCLUIR ESSE ANEXO? <br />
                    ESSA AÇÃO É IRREVERSÍVEL!
                  </DialogContentText>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} autoFocus>
                Cancelar
              </Button>
              <Button
                onClick={() => this.handleDeleteButton()}
                style={{
                  color: theme.secondary_color,
                  backgroundColor: theme.primary_color,
                }}
              >
                Deletar
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Paper>
    );
  }
}

function mapStateToProps(state) {
  return {
    area: state.homeReducer.area,
  };
}

export default connect(mapStateToProps, {})(ActiveAreaList);
