import { ADD_ACTIVITIES } from "./types";

const INITIAL_STATE = {
  activities: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_ACTIVITIES:
      return {
        ...state,
        activities: action.data,
      }
    default:
      return state;
  }
}