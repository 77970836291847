import React, { Component } from 'react';
import myApp from '../../core/infrastructure/firebaseConfig';
import "firebase/compat/firestore";

import DeleteIcon from '@material-ui/icons/Delete';

import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel, 
  MenuItem,
  Select,
  TextField,
  IconButton
} from '@material-ui/core';

import { connect } from 'react-redux';
import { addAreas } from '../redux/actions';
import areaIconList from '../../shared/assets/areaIconList';

import theme from '../../core/theme.json'

class AreaObject {
  name = "";
  owner = "";
  icon = "";
}

class CompanyRegistrationArea extends Component {
  constructor(props) {
    super(props);

    this.db = myApp.firestore();

    this.state = {
      name: "",
      icon: "",
      owner: "",

      areas: []
    }
  }

  componentDidMount() {
    this.setState({
      areas: this.props.areas
    })
  }

  handleName(event) {
    this.setState({
      name: event.target.value
    })
  }

  handleEditAreaName(index, value) {
    var areas = this.state.areas;
    areas[index].name = value;

    this.setState({
      areas
    })
  }

  handleEditAreaOwner(index, value) {
    var areas = this.state.areas;
    areas[index].owner = value;

    this.setState({
      areas
    })
  }

  handleOwner(event) {
    this.setState({
      owner: event.target.value
    })
  }

  handleIcon(event) {
    this.setState({
      icon: event.target.value
    })
  }

  addArea() {
    var _area = new AreaObject();

    var _areas = this.state.areas;

    _area.name = this.state.name;
    _area.owner = this.state.owner;
    _area.icon = this.state.icon;

    _areas.push(_area);

    this.setState({
      areas: _areas,
      name: "",
      owner: "",
      icon: ""
    }, () => {this.handleRedux()})
  }

  renderIcon(iconName) {
    for (var i = 0; i < areaIconList.length; i++) {
      if (iconName === areaIconList[i].value) {
        var areaIcon = areaIconList[i];

        return <areaIcon.icon style={{fontSize: "36px"}} />
      }
    }
  }

  handleRedux() {
    this.props.addAreas(this.state.areas);
  }

  handleDeleteArea(index) {
    let { areas } = this.state;

    areas.splice(index, 1);

    this.setState({
      areas
    });
  }

  render() {
    return (
      <Grid 
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={4}
      >
        <Grid item style={{width: "100%"}}>
          <Grid 
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={4}
          >
            <Grid item style={{width: "50%"}} xs={4}>
              <TextField
                inputProps={{
                  maxLength: 50,
                }}
                label="Nome da Área"
                variant="outlined"
                style={{ width: "100%" }}
                color={theme.secondary_color}
                value={this.state.name}
                onChange={(event) => {this.handleName(event)}}
              />
            </Grid>
            <Grid item style={{width: "50%"}} xs={4}>
              <TextField
                inputProps={{
                  maxLength: 50,
                }}
                label="Responsável"
                variant="outlined"
                style={{ width: "100%" }}
                color={theme.secondary_color}
                value={this.state.owner}
                onChange={(event) => {this.handleOwner(event)}}
              />
            </Grid>
            <Grid item style={{width: "100%"}} xs={2}>
              <FormControl variant="outlined" style={{width: "100%"}}>
                <InputLabel>
                  Ícone
                </InputLabel>
                <Select
                  value={this.state.icon}
                  onChange={(event) => {this.handleIcon(event)}}
                  label="Ícone"
                >
                  {areaIconList.map((icon) => (
                    <MenuItem value={icon.value}>
                      <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        spacing={2}
                        style={{marginLeft: "4px"}}
                      >
                        <Grid item style={{padding: "0px"}}>
                          <icon.icon />
                        </Grid>
                        <Grid item>
                          {icon.name}
                        </Grid>
                      </Grid>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item style={{width: "100%"}} xs={2}>
              <Button 
                variant="contained"
                style={{background: this.state.name.length === 0 ? theme.tertiary_color : theme.secondary_color, color: "white", width: "100%", height: "56px"}}
                onClick={() => {this.addArea()}}
                disabled={this.state.name.length === 0}
              >
                Adicionar
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{width: "100%"}}>
          <Divider />
        </Grid>

        <Grid component="ul" item style={{width: "100%"}}>
          {this.state.areas.map((area, index) => (
            
            <Grid 
              container
              component="li"
              direction="row"
              alignItems="center"
              spacing={4}
            >
              
                <Grid 
                  item xs={1}
                  style={{
                    background: theme.primary_color,
                    textAlign: "center",
                    color: "white",
                    fontWeight: "700",
                    borderRadius: "8px",
                    fontSize: "22px"
                  }}
                >
                  {index + 1}
                </Grid>
                <Grid item xs={5} style={{fontSize: "22px", overflowWrap:"anywhere"}} >
                  <TextField value={area.name} onChange={e => this.handleEditAreaName(index, e.target.value)} multiline style={{width: "100%"}}/>
                </Grid>
                <Grid item xs={4} style={{fontSize: "22px", overflowWrap:"anywhere"}} >
                  <TextField value={area.owner} onChange={e => this.handleEditAreaOwner(index, e.target.value)} multiline style={{width: "100%"}}/>
                </Grid>
                <Grid item xs={1}>
                  {this.renderIcon(area.icon)}
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={() => this.handleDeleteArea(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>

            </Grid>
            
          ))}
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state){
  return{
    areas: state.companyReducer.areas
  };
}

export default connect(mapStateToProps, { addAreas })(CompanyRegistrationArea);