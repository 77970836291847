import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  ButtonBase,
  Paper,
  TextField,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Switch,
  Tooltip,

} from "@material-ui/core";
import Title from "../../../../shared/components/title";
import ArrowBackIosTwoToneIcon from "@material-ui/icons/ArrowBackIosTwoTone";
import { QuestionAnswerRounded } from "@material-ui/icons";
import CreateIcon from '@material-ui/icons/Create';
import AddIcon from '@material-ui/icons/Add';
import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { changePage } from "../../parameterization/redux/actions";
import theme from "../../../../core/theme.json";
import DeleteIcon from '@material-ui/icons/Delete';


const EditNewQuiz = (props) => {
  const dispatch = useDispatch();
  const [quizName, setQuizName] = useState('Carregando...');
  const [questions, setQuestions] = useState([]);
  const [newQuestions, setNewQuestions] = useState({
    name: '',
    gap: false,
    alternatives: [],
  });
  const [newAlternativeModal, setNewAlternativeModal] = useState({
    name: '',
    gap: false,
    gapTitle: '',
    gapPlanoAcao: ''
  });
  const [indexQuestion, setIndexQuestion] = useState(0);
  const [editQuestionModal, setEditQuestionModal] = useState('');
  const [dialogs, setDialogs] = useState({
    newQuizodal: false,
    editNewQuiz: false,
    editQuestion: false,
    newAlternative: false,
    editQuestionName: false,
    editAlternative: false
  });
  const [editQuizz, setEditQuizz] = useState({
    title: '',
    id: '',
  });

  const [editQuestionName, setEditQuestionName] = useState('');

  const [editAlternativeState, setEditAlternativeState] = useState({
    index: '',
    name: '',
    gap: false,
    gapTitle: '',
    gapPlanoAcao: ''
  });

  const handleOpenDialog = (item) => (value) => {
    setDialogs({
      ...dialogs,
      [item]: true
    })
  }

  const handleCloseDialog = (item) => (value) => {
    setDialogs({
      ...dialogs,
      [item]: false
    });
  }

  useEffect(() => {
    handleMountingPage();
  }, []);

  const handleMountingPage = async () => {
    let get = await myApp.firestore().collection('newQuizz').doc(props.quiz_id).get();
    setQuizName(get.data().name);
    let aux = get.data().questions;
    setQuestions(aux);
  }

  const handleAddNewQuestion = () => {
    let aux = [];
    aux = questions;
    aux.push(newQuestions)
    myApp.firestore().collection('newQuizz').doc(props.quiz_id).update({
      questions: aux
    }).then(() => {
      console.log('Adicionou');
      handleMountingPage();
      setDialogs({
        ...dialogs,
        newQuizodal: false
      });
      setNewQuestions({
        name: '',
        gap: false,
        alternatives: [],
      });
    });
  }

  const handleEditQuestion = (index) => {
    console.log(questions[index]);
    setIndexQuestion(index);
    setEditQuestionModal(questions[index]);
    setDialogs({
      ...dialogs,
      editQuestion: true
    });
  }

  const handleAddNewAlternative = () => {
    console.log(questions[indexQuestion]);

    let aux = [];
    aux = questions[indexQuestion].alternatives;
    aux.push(newAlternativeModal);
    questions[indexQuestion].alternatives = aux;
    setDialogs({
      ...dialogs,
      newAlternative: false
    });
    setNewAlternativeModal({
      name: '',
      gap: false
    });
  }

  const handleUpdateAlternatives = () => {
    let aux = [];
    aux = questions;
    myApp.firestore().collection('newQuizz').doc(props.quiz_id).update({
      questions: aux
    }).then(() => {
      console.log('Adicionou');
      handleMountingPage();
      setDialogs({
        ...dialogs,
        newAlternative: false,
        editQuestion: false
      });
      setNewAlternativeModal({
        name: '',
        gap: false
      });
    });
  }

  const handleAlterChecked = (event, indexQuestion, index) => {
    let aux = questions.slice();
    aux[indexQuestion].alternatives[index].gap = event.target.checked;
    setQuestions(aux);
  }

  const handleDeleteAlternativeChecked = (indexQuestion, index) => {
    let aux = questions.slice();
    console.log(aux[indexQuestion].alternatives[index]);
    aux[indexQuestion].alternatives.splice(index, 1);
    console.log(aux);
    setQuestions(aux);
  }

  const handleEditQuiz = () => {
    setEditQuizz({
      id: props.quiz_id,
      title: quizName
    });
    setDialogs({ ...dialogs, editNewQuiz: true })
  }

  const handleUpdateNewQuiz = () => {
    myApp.firestore().collection('newQuizz').doc(props.quiz_id).update({
      name: editQuizz.title
    }).then(() => {
      console.log('Atualizado com sucesso');
      handleMountingPage();
      setDialogs({ ...dialogs, editNewQuiz: false });
    });
  }

  const handleDeleteQuiz = () => {
    let text = "Você esta prestes a deleter um questionário. \n Deseja continuar?";
    if (window.confirm(text) == true) {
      myApp.firestore().collection('newQuizz').doc(props.quiz_id).delete().then(() => {
        dispatch(changePage(39));
      });
    } else {
      setDialogs({ ...dialogs, editNewQuiz: false });
    }
  }

  const handleEditQuestionModal = () => {
    let aux = questions[indexQuestion].name
    setEditQuestionName(aux);
    setDialogs({
      ...dialogs,
      editQuestionName: true
    });
  }

  const handleDeleteQuestionModal = () => {
    //deleteQuestion
    setDialogs({
      ...dialogs,
      deleteQuestion: true
    });
  }

  const handleEditQuestionName = () => {
    let aux = questions.slice();
    aux[indexQuestion].name = editQuestionName;
    myApp.firestore().collection('newQuizz').doc(props.quiz_id).update({
      questions: aux
    }).then(() => {
      setDialogs({
        ...dialogs,
        editNewQuiz: false,
        editQuestion: false,
        editQuestionName: false,
        newAlternative: false,
        newQuizodal: false,
      });
      handleMountingPage();
    });
  }

  const handleEditAlternative = (index) => {
    console.log(questions[indexQuestion].alternatives[index]);
    setEditAlternativeState({
      index,
      name: questions[indexQuestion].alternatives[index].name,
      gap: questions[indexQuestion].alternatives[index].gap,
      gapTitle: questions[indexQuestion].alternatives[index].gapTitle,
      gapPlanoAcao: questions[indexQuestion].alternatives[index].gapPlanoAcao,
    });
    setDialogs({
      ...dialogs,
      editAlternative: true
    });
  }

  const handleSaveEditAlternative = () => {
    let aux = questions.slice();
    aux[indexQuestion].alternatives[editAlternativeState.index] = {
      name: editAlternativeState.name,
      gap: editAlternativeState.gap,
      gapTitle: editAlternativeState.gapTitle,
      gapPlanoAcao: editAlternativeState.gapPlanoAcao
    }
    setQuestions(aux);
    setDialogs({
      ...dialogs,
      editAlternative: false
    });
  }

  return (
    <Grid
      container
      item
      xs={12}
    >
      <Title>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={1}>
            <Button
              variant="text"
              style={{
                fontSize: '15px',
              }}
              onClick={() => {
                dispatch(changePage(38));
              }}
            >
              <ArrowBackIosTwoToneIcon />
            </Button>
          </Grid>
          <Grid item xs={9} >Criando questionário: {quizName}</Grid>
          <Grid item xs={1}>
            <Tooltip title="adicionar pergunta">
            <Button
              variant="text"
              style={{
                fontSize: '15px',
                backgroundColor: theme.primary_color,
                color: theme.secondary_color
              }}
              onClick={() => setDialogs({ ...dialogs, newQuizodal: true })}
            >
              <AddIcon style={{ fontSize: 30 }} />
            </Button>
            </Tooltip>

          </Grid>
          <Grid item xs={1}>
            <Tooltip title="Editar questionário">
            <Button
              variant="text"
              // onClick={() => setDialogs({ ...dialogs, editNewQuiz: true })}
              onClick={handleEditQuiz}
            >
              <CreateIcon style={{ fontSize: 30 }} />
            </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Title>
      <Grid
        item
        container
        direction="row"
        spacing={2}
      >
        {
          questions.length > 0 ?
            questions.map((item, index) => (
              <Grid
                item
                alignItems="flex-start"
                direction="column"
                xs={3} sm={3}
              >
                <ButtonBase
                  style={{ margin: '10px', width: '100%', height: '250px', }}
                  onClick={() => handleEditQuestion(index)}
                >
                  <Paper
                    elevation={5}
                    style={{ width: '100%', height: '100%', }}
                  >
                    <Grid
                      container
                      item
                      direction="column"
                      justify="center"
                      alignItems="center"
                      style={{
                        height: '100%',
                      }}
                    >
                      <QuestionAnswerRounded style={{ fontSize: 75, color: theme.secondary_color }} />
                      <span
                        style={{
                          fontSize: '18px',
                          lineHeight: '1.2',
                          color: theme.secondary_color,
                          margin: '10px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '90%'
                        }}
                      >
                        {item.name}
                      </span>
                    </Grid>
                  </Paper>
                </ButtonBase>
              </Grid>
            ))
            :
            <Grid
              item
              alignItems="flex-start"
              direction="column"
              xs={12} sm={12} md={3} lg={3} xl={3}
            >
              <ButtonBase
                style={{ margin: '10px', width: '100%', height: '250px', }}
                disabled={true}
              >
                <Paper
                  elevation={5}
                  style={{ width: '100%', height: '100%', backgroundColor: '#ccc' }}
                >
                  <Grid
                    container
                    item
                    direction="column"
                    justify="center"
                    alignItems="center"
                    style={{
                      height: '100%',
                    }}
                  >
                    <QuestionAnswerRounded style={{ fontSize: 75, color: theme.secondary_color }} />
                    <span
                      style={{
                        fontSize: '22px',
                        color: theme.secondary_color,
                        margin: '10px'
                      }}
                    >
                      Sem questões cadastradas
                    </span>
                  </Grid>
                </Paper>
              </ButtonBase>
            </Grid>
        }
      </Grid>

      <Dialog
        open={dialogs.newQuizodal}
        fullWidth
        onClose={handleCloseDialog('newQuizodal')}
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Grid
            container
            direction="row"
            alignItems="center"
          >
            <Grid item>
              <Typography>
                Adicionando perguntas
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                sm={2}
                xs={2}
              >
                <Typography>
                  Titulo:
                </Typography>
              </Grid>
              <Grid
                item
                sm={10}
                xs={10}
              >
                <TextField
                  label="Titulo"
                  placeholder="Titulo"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={newQuestions.name}
                  onChange={(event) => setNewQuestions({ ...newQuestions, name: event.target.value })}
                />
              </Grid>
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              backgroundColor: '#f00',
              color: '#fff'
            }}
            onClick={handleCloseDialog('newQuizodal')}
            autoFocus
          >
            Cancelar
          </Button>
          <Button
            style={{
              backgroundColor: 'rgb(56, 242, 5)'
            }}
            onClick={handleAddNewQuestion}
            autoFocus
          >
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogs.editNewQuiz}
        fullWidth
        onClose={handleCloseDialog('editNewQuiz')}
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={11} sm={11} >
              <Typography>
                Editando questionário
              </Typography>
            </Grid>
            <Grid item xs={1} sm={1}>
              <Tooltip title="Apagar questionário">
                <Button
                  fullWidth
                  onClick={handleDeleteQuiz}
                  autoFocus
                >
                  <DeleteIcon />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                sm={2}
                xs={2}
              >
                <Typography>
                  Titulo:
                </Typography>
              </Grid>
              <Grid
                item
                sm={10}
                xs={10}
              >
                <TextField
                  label="Titulo"
                  placeholder="Titulo"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={editQuizz.title}
                  onChange={(event) => setEditQuizz({ ...editQuizz, title: event.target.value })}
                />
              </Grid>
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              backgroundColor: '#f00',
              color: '#fff'
            }}
            onClick={handleCloseDialog('editNewQuiz')}
            autoFocus
          >
            Cancelar
          </Button>
          <Button
            style={{
              backgroundColor: 'rgb(56, 242, 5)'
            }}
            onClick={handleUpdateNewQuiz}
            autoFocus
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={dialogs.editQuestion}
        fullWidth
        onClose={handleCloseDialog('editQuestion')}
      >
        <DialogTitle>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={9} sm={9} >
              <Typography>
                {editQuestionModal.name}
              </Typography>
            </Grid>
            <Grid
              item
              sm={1}
              xs={1}
            >
              <Tooltip title="Adicionar alternativa">
                <Button
                  variant="text"
                  fullWidth
                  style={{
                    // backgroundColor: theme.primary_color,
                    color: theme.secondary_color
                  }}
                  onClick={() => setDialogs({ ...dialogs, newAlternative: true })}
                >
                  <AddIcon style={{ fontSize: 30 }} />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={1} sm={1} >
              <Tooltip title="Editar questão">
                <Button
                  fullWidth
                  // onClick={() => setDialogs({...dialogs, editQuestionName: true})}
                  onClick={handleEditQuestionModal}
                  autoFocus
                >
                  <CreateIcon />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={1} sm={1} >
              <Tooltip title="Apagar questão">
                <Button
                  fullWidth
                  onClick={handleDeleteQuestionModal}
                  autoFocus
                >
                  <DeleteIcon />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
          >
            <Grid
              item
              xs={12}
              container
              direction="column"
            >
              {questions.length ?
                (questions[indexQuestion] && questions[indexQuestion].alternatives ?
                  questions[indexQuestion].alternatives.map((item, index) => (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      sm={12}
                      container
                      direction="row"
                    >
                      <Grid
                        item
                        sm={9}
                        xs={9}
                      >
                        <Typography>
                          {item.name}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        xs={1}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                              checked={item.gap}
                              color="primary"
                            />
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        xs={1}
                      >
                        <Tooltip title="Excluir">
                        <Button
                          variant="text"
                          style={{
                            fontSize: '15px',
                            color: theme.secondary_color
                          }}
                          onClick={() => handleDeleteAlternativeChecked(indexQuestion, index)}
                        >
                          <DeleteIcon style={{ fontSize: 30 }} />
                        </Button>
                        </Tooltip>

                      </Grid>
                      <Grid item xs={1} sm={1} >
                        <Tooltip title="Editar">
                        <Button
                          fullWidth
                          onClick={() => handleEditAlternative(index)}
                          autoFocus
                        >
                          <CreateIcon />
                        </Button>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )) :
                  <h1>Sem alternativas</h1>
                ) :
                null
              }
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              backgroundColor: '#f00',
              color: '#fff'
            }}
            onClick={handleCloseDialog('editQuestion')}
            autoFocus
          >
            Cancelar
          </Button>
          <Button
            style={{
              backgroundColor: 'rgb(56, 242, 5)'
            }}
            onClick={handleUpdateAlternatives}
            autoFocus
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={dialogs.newAlternative}
        fullWidth
        onClose={handleCloseDialog('newAlternative')}
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Grid
            container
            direction="row"
            alignItems="center"
          >
            <Grid item>
              <Typography>
                Adicionando alternativas
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                sm={2}
                xs={2}
              >
                <Typography>
                  Titulo:
                </Typography>
              </Grid>
              <Grid
                item
                sm={10}
                xs={10}
              >
                <TextField
                  label="Titulo"
                  placeholder="Titulo"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={newAlternativeModal.name}
                  onChange={(event) => setNewAlternativeModal({ ...newAlternativeModal, name: event.target.value })}
                />
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                sm={2}
                xs={2}
              >
                <Typography>
                  Gap:
                </Typography>
              </Grid>
              <Grid
                item
                sm={10}
                xs={10}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={newAlternativeModal.gap}
                      onChange={(event) => setNewAlternativeModal({ ...newAlternativeModal, gap: event.target.checked })}
                      color="primary"
                    />
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{
                display: newAlternativeModal.gap === false ? 'none' : 'block'
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
              >
                <TextField
                  label="Qual GAP"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={newAlternativeModal.gapTitle}
                  onChange={(event) => setNewAlternativeModal({ ...newAlternativeModal, gapTitle: event.target.value })}
                >
                </TextField>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{
                display: newAlternativeModal.gap === false ? 'none' : 'block'
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
              >
                <TextField
                  label="Qual o plano de ação para o GAP"
                  variant="outlined"
                  fullWidth
                  multiline
                  minRows={4}
                  margin="normal"
                  value={newAlternativeModal.gapPlanoAcao}
                  onChange={(event) => setNewAlternativeModal({ ...newAlternativeModal, gapPlanoAcao: event.target.value })}
                >
                </TextField>
              </Grid>
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              backgroundColor: '#f00',
              color: '#fff'
            }}
            onClick={handleCloseDialog('newAlternative')}
            autoFocus
          >
            Cancelar
          </Button>
          <Button
            style={{
              backgroundColor: 'rgb(56, 242, 5)'
            }}
            onClick={handleAddNewAlternative}
            autoFocus
            disabled={newAlternativeModal.gap === false ? false : newAlternativeModal.gap === true && newAlternativeModal.gapPlanoTitle !== '' && newAlternativeModal.gapPlanoAcao !== '' ? false : true}
          >
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogs.editQuestionName}
        fullWidth
        onClose={handleCloseDialog('editQuestionName')}
      >
        <DialogTitle>
          <Grid
            container
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <Typography>
                Editando nome da peregunta
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
          >
            <Grid
              item
              xs={12}
            >
              <TextField
                variant="outlined"
                fullWidth
                margin="normal"
                value={editQuestionName}
                onChange={(event) => setEditQuestionName(event.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              backgroundColor: '#f00',
              color: '#fff'
            }}
            onClick={handleCloseDialog('editQuestionName')}
            autoFocus
          >
            Cancelar
          </Button>
          <Button
            style={{
              backgroundColor: 'rgb(56, 242, 5)'
            }}
            onClick={handleEditQuestionName}
            autoFocus
          >
            Alterar nome
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogs.editAlternative}
        fullWidth
        onClose={handleCloseDialog('editAlternative')}
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Grid
            container
            direction="row"
            alignItems="center"
          >
            <Grid item>
              <Typography>
                Editando alternativa
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                sm={2}
                xs={2}
              >
                <Typography>
                  Titulo:
                </Typography>
              </Grid>
              <Grid
                item
                sm={10}
                xs={10}
              >
                <TextField
                  label="Titulo"
                  placeholder="Titulo"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={editAlternativeState.name}
                  onChange={(event) => setEditAlternativeState({ ...editAlternativeState, name: event.target.value })}
                />
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                sm={2}
                xs={2}
              >
                <Typography>
                  Gap:
                </Typography>
              </Grid>
              <Grid
                item
                sm={10}
                xs={10}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={editAlternativeState.gap}
                      onChange={(event) => setEditAlternativeState({ ...editAlternativeState, gap: event.target.checked })}
                      color="primary"
                    />
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{
                display: editAlternativeState.gap === false ? 'none' : 'block'
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
              >
                <TextField
                  label="Qual GAP"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={editAlternativeState.gapTitle}
                  onChange={(event) => setEditAlternativeState({ ...editAlternativeState, gapTitle: event.target.value })}
                >
                </TextField>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{
                display: editAlternativeState.gap === false ? 'none' : 'block'
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
              >
                <TextField
                  label="Qual o plano de ação para o GAP"
                  variant="outlined"
                  fullWidth
                  multiline
                  minRows={4}
                  margin="normal"
                  value={editAlternativeState.gapPlanoAcao}
                  onChange={(event) => setEditAlternativeState({ ...editAlternativeState, gapPlanoAcao: event.target.value })}
                >
                </TextField>
              </Grid>
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              backgroundColor: '#f00',
              color: '#fff'
            }}
            onClick={handleCloseDialog('editAlternative')}
            autoFocus
          >
            Cancelar
          </Button>
          <Button
            style={{
              backgroundColor: 'rgb(56, 242, 5)'
            }}
            onClick={handleSaveEditAlternative}
            autoFocus
          >
            Alterar alternativa
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogs.deleteQuestion}
        fullWidth
        onClose={handleCloseDialog('deleteQuestion')}
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Grid
            container
            direction="row"
            alignItems="center"
          >
            <Grid item>
              <Typography>
                deletando pergunta
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h6" component="div">
                Tem certeza que dejesa apagar essa questão do questionário?<br />
                essa ação é irreversível!!!
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              backgroundColor: '#f00',
              color: '#fff'
            }}
            onClick={handleCloseDialog('deleteQuestion')}
            autoFocus
          >
            Cancelar
          </Button>
          <Button
            style={{
              backgroundColor: 'rgb(56, 242, 5)'
            }}
            onClick={() => {
              try {
                setDialogs({
                  ...dialogs,
                  editQuestion: false,
                  deleteQuestion: false
                });
                console.log('apagando questão', questions[indexQuestion]);
                let aux = questions.slice();
                aux.splice(indexQuestion, 1);
                setQuestions(aux);
                myApp.firestore().collection('newQuizz').doc(props.quiz_id).update({
                  questions: aux
                }).then(() => {
                  handleMountingPage();
                });
              } catch (error) {
                console.log('Erro ao apagar questão', error);
              }
            }}
            autoFocus
          >
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>

    </Grid >
  );
}

// export default EditNewQuiz;

const mapStateToProps = (state) => {
  return {
    quiz_id: state.quizReducer.quiz_id
  };
}

export default connect(mapStateToProps, {})(EditNewQuiz);