import { ROPA_STYLES } from "../utils/constants";

export async function generateStyles(workSheet, qtdProcesses) {
  let ws = workSheet;

  // first row
  ws["A1"].s = ROPA_STYLES.firstPrimaryHeader;
  ws["B1"].s = ROPA_STYLES.firstPrimaryHeader;
  ws["C1"].s = ROPA_STYLES.firstPrimaryHeader;
  ws["D1"].s = ROPA_STYLES.firstPrimaryHeader;
  ws["E1"].s = ROPA_STYLES.firstPrimaryHeader;
  ws["F1"].s = ROPA_STYLES.firstPrimaryHeader;
  ws["G1"].s = ROPA_STYLES.firstPrimaryHeader;
  ws["H1"].s = ROPA_STYLES.secondPrimaryHeader;
  ws["I1"].s = ROPA_STYLES.secondPrimaryHeader;
  ws["J1"].s = ROPA_STYLES.secondPrimaryHeader;
  ws["K1"].s = ROPA_STYLES.secondPrimaryHeader;
  ws["L1"].s = ROPA_STYLES.secondPrimaryHeader;
  ws["M1"].s = ROPA_STYLES.secondPrimaryHeader;
  ws["N1"].s = ROPA_STYLES.secondPrimaryHeader;
  ws["O1"].s = ROPA_STYLES.secondPrimaryHeader;
  ws["P1"].s = ROPA_STYLES.secondPrimaryHeader;
  ws["Q1"].s = ROPA_STYLES.thirdPrimaryHeader;
  ws["R1"].s = ROPA_STYLES.thirdPrimaryHeader;
  ws["S1"].s = ROPA_STYLES.thirdPrimaryHeader;
  ws["T1"].s = ROPA_STYLES.thirdPrimaryHeader;
  ws["U1"].s = ROPA_STYLES.thirdPrimaryHeader;
  ws["V1"].s = ROPA_STYLES.thirdPrimaryHeader;
  ws["W1"].s = ROPA_STYLES.thirdPrimaryHeader;
  ws["X1"].s = ROPA_STYLES.thirdPrimaryHeader;
  ws["Y1"].s = ROPA_STYLES.thirdPrimaryHeader;
  ws["Z1"].s = ROPA_STYLES.thirdPrimaryHeader;
  ws["AA1"].s = ROPA_STYLES.thirdPrimaryHeader;
  ws["AB1"].s = ROPA_STYLES.fourthPrimaryHeader;
  ws["AC1"].s = ROPA_STYLES.fourthPrimaryHeader;
  ws["AD1"].s = ROPA_STYLES.fourthPrimaryHeader;
  ws["AE1"].s = ROPA_STYLES.fourthPrimaryHeader;
  ws["AF1"].s = ROPA_STYLES.fourthPrimaryHeader;
  ws["AG1"].s = ROPA_STYLES.fourthPrimaryHeader;
  ws["AH1"].s = ROPA_STYLES.fourthPrimaryHeader;
  ws["AI1"].s = ROPA_STYLES.fifthPrimaryHeader;
  ws["AJ1"].s = ROPA_STYLES.fifthPrimaryHeader;
  ws["AK1"].s = ROPA_STYLES.fifthPrimaryHeader;
  ws["AL1"].s = ROPA_STYLES.fifthPrimaryHeader;
  ws["AM1"].s = ROPA_STYLES.fifthPrimaryHeader;
  ws["AN1"].s = ROPA_STYLES.fifthPrimaryHeader;
  ws["AO1"].s = ROPA_STYLES.sixthPrimaryHeader;
  ws["AP1"].s = ROPA_STYLES.sixthPrimaryHeader;
  ws["AQ1"].s = ROPA_STYLES.sixthPrimaryHeader;
  ws["AR1"].s = ROPA_STYLES.sixthPrimaryHeader;
  ws["AS1"].s = ROPA_STYLES.sixthPrimaryHeader;
  ws["AT1"].s = ROPA_STYLES.sixthPrimaryHeader;
  ws["AU1"].s = ROPA_STYLES.sixthPrimaryHeader;
  ws["AV1"].s = ROPA_STYLES.sixthPrimaryHeader;
  ws["AW1"].s = ROPA_STYLES.sixthPrimaryHeader;
  ws["AX1"].s = ROPA_STYLES.seventhPrimaryHeader;
  ws["AY1"].s = ROPA_STYLES.seventhPrimaryHeader;
  ws["AZ1"].s = ROPA_STYLES.seventhPrimaryHeader;
  ws["BA1"].s = ROPA_STYLES.seventhPrimaryHeader;

  // second row
  ws["A2"].s = ROPA_STYLES.firstSecondaryHeader;
  ws["B2"].s = ROPA_STYLES.firstSecondaryHeader;
  ws["C2"].s = ROPA_STYLES.firstSecondaryHeader;
  ws["D2"].s = ROPA_STYLES.firstSecondaryHeader;
  ws["E2"].s = ROPA_STYLES.firstSecondaryHeader;
  ws["F2"].s = ROPA_STYLES.firstSecondaryHeader;
  ws["G2"].s = ROPA_STYLES.firstSecondaryHeader;
  ws["H2"].s = ROPA_STYLES.secondSecondaryHeader;
  ws["I2"].s = ROPA_STYLES.secondSecondaryHeader;
  ws["J2"].s = ROPA_STYLES.secondSecondaryHeader;
  ws["K2"].s = ROPA_STYLES.secondSecondaryHeader;
  ws["L2"].s = ROPA_STYLES.secondSecondaryHeader;
  ws["M2"].s = ROPA_STYLES.secondSecondaryHeader;
  ws["N2"].s = ROPA_STYLES.secondSecondaryHeader;
  ws["O2"].s = ROPA_STYLES.secondSecondaryHeader;
  ws["P2"].s = ROPA_STYLES.secondSecondaryHeader;
  ws["Q2"].s = ROPA_STYLES.thirdSecondaryHeader;
  ws["R2"].s = ROPA_STYLES.thirdSecondaryHeader;
  ws["S2"].s = ROPA_STYLES.thirdSecondaryHeader;
  ws["T2"].s = ROPA_STYLES.thirdSecondaryHeader;
  ws["U2"].s = ROPA_STYLES.thirdSecondaryHeader;
  ws["V2"].s = ROPA_STYLES.thirdSecondaryHeader;
  ws["W2"].s = ROPA_STYLES.thirdSecondaryHeader;
  ws["X2"].s = ROPA_STYLES.thirdSecondaryHeader;
  ws["Y2"].s = ROPA_STYLES.thirdSecondaryHeader;
  ws["Z2"].s = ROPA_STYLES.thirdSecondaryHeader;
  ws["AA2"].s = ROPA_STYLES.thirdSecondaryHeader;
  ws["AB2"].s = ROPA_STYLES.fourthSecondaryHeader;
  ws["AC2"].s = ROPA_STYLES.fourthSecondaryHeader;
  ws["AD2"].s = ROPA_STYLES.fourthSecondaryHeader;
  ws["AE2"].s = ROPA_STYLES.fourthSecondaryHeader;
  ws["AF2"].s = ROPA_STYLES.fourthSecondaryHeader;
  ws["AG2"].s = ROPA_STYLES.fourthSecondaryHeader;
  ws["AH2"].s = ROPA_STYLES.fourthSecondaryHeader;
  ws["AI2"].s = ROPA_STYLES.fifthSecondaryHeader;
  ws["AJ2"].s = ROPA_STYLES.fifthSecondaryHeader;
  ws["AK2"].s = ROPA_STYLES.fifthSecondaryHeader;
  ws["AL2"].s = ROPA_STYLES.fifthSecondaryHeader;
  ws["AM2"].s = ROPA_STYLES.fifthSecondaryHeader;
  ws["AN2"].s = ROPA_STYLES.fifthSecondaryHeader;
  ws["AO2"].s = ROPA_STYLES.sixthSecondaryHeader;
  ws["AP2"].s = ROPA_STYLES.sixthSecondaryHeader;
  ws["AQ2"].s = ROPA_STYLES.sixthSecondaryHeader;
  ws["AR2"].s = ROPA_STYLES.sixthSecondaryHeader;
  ws["AS2"].s = ROPA_STYLES.sixthSecondaryHeader;
  ws["AT2"].s = ROPA_STYLES.sixthSecondaryHeader;
  ws["AU2"].s = ROPA_STYLES.sixthSecondaryHeader;
  ws["AV2"].s = ROPA_STYLES.sixthSecondaryHeader;
  ws["AW2"].s = ROPA_STYLES.sixthSecondaryHeader;
  ws["AX2"].s = ROPA_STYLES.seventhSecondaryHeader;
  ws["AY2"].s = ROPA_STYLES.seventhSecondaryHeader;
  ws["AZ2"].s = ROPA_STYLES.seventhSecondaryHeader;
  ws["BA2"].s = ROPA_STYLES.seventhSecondaryHeader;

  // third row
  ws["A3"].s = ROPA_STYLES.tertiaryHeader;
  ws["B3"].s = ROPA_STYLES.tertiaryHeader;
  ws["C3"].s = ROPA_STYLES.tertiaryHeader;
  ws["D3"].s = ROPA_STYLES.tertiaryHeader;
  ws["E3"].s = ROPA_STYLES.tertiaryHeader;
  ws["F3"].s = ROPA_STYLES.tertiaryHeader;
  ws["G3"].s = ROPA_STYLES.tertiaryHeader;
  ws["H3"].s = ROPA_STYLES.tertiaryHeader;
  ws["I3"].s = ROPA_STYLES.tertiaryHeader;
  ws["J3"].s = ROPA_STYLES.tertiaryHeader;
  ws["K3"].s = ROPA_STYLES.tertiaryHeader;
  ws["L3"].s = ROPA_STYLES.tertiaryHeader;
  ws["M3"].s = ROPA_STYLES.tertiaryHeader;
  ws["N3"].s = ROPA_STYLES.tertiaryHeader;
  ws["O3"].s = ROPA_STYLES.tertiaryHeader;
  ws["P3"].s = ROPA_STYLES.tertiaryHeader;
  ws["Q3"].s = ROPA_STYLES.tertiaryHeader;
  ws["R3"].s = ROPA_STYLES.tertiaryHeader;
  ws["S3"].s = ROPA_STYLES.tertiaryHeader;
  ws["T3"].s = ROPA_STYLES.tertiaryHeader;
  ws["U3"].s = ROPA_STYLES.tertiaryHeader;
  ws["V3"].s = ROPA_STYLES.tertiaryHeader;
  ws["W3"].s = ROPA_STYLES.tertiaryHeader;
  ws["X3"].s = ROPA_STYLES.tertiaryHeader;
  ws["Y3"].s = ROPA_STYLES.tertiaryHeader;
  ws["Z3"].s = ROPA_STYLES.tertiaryHeader;
  ws["AA3"].s = ROPA_STYLES.tertiaryHeader;
  ws["AB3"].s = ROPA_STYLES.tertiaryHeader;
  ws["AC3"].s = ROPA_STYLES.tertiaryHeader;
  ws["AD3"].s = ROPA_STYLES.tertiaryHeader;
  ws["AE3"].s = ROPA_STYLES.tertiaryHeader;
  ws["AF3"].s = ROPA_STYLES.tertiaryHeader;
  ws["AG3"].s = ROPA_STYLES.tertiaryHeader;
  ws["AH3"].s = ROPA_STYLES.tertiaryHeader;
  ws["AI3"].s = ROPA_STYLES.tertiaryHeader;
  ws["AJ3"].s = ROPA_STYLES.tertiaryHeader;
  ws["AK3"].s = ROPA_STYLES.tertiaryHeader;
  ws["AL3"].s = ROPA_STYLES.tertiaryHeader;
  ws["AM3"].s = ROPA_STYLES.tertiaryHeader;
  ws["AN3"].s = ROPA_STYLES.tertiaryHeader;
  ws["AO3"].s = ROPA_STYLES.tertiaryHeader;
  ws["AP3"].s = ROPA_STYLES.tertiaryHeader;
  ws["AQ3"].s = ROPA_STYLES.tertiaryHeader;
  ws["AR3"].s = ROPA_STYLES.tertiaryHeader;
  ws["AS3"].s = ROPA_STYLES.tertiaryHeader;
  ws["AT3"].s = ROPA_STYLES.tertiaryHeader;
  ws["AU3"].s = ROPA_STYLES.tertiaryHeader;
  ws["AV3"].s = ROPA_STYLES.tertiaryHeader;
  ws["AW3"].s = ROPA_STYLES.tertiaryHeader;
  ws["AX3"].s = ROPA_STYLES.tertiaryHeader;
  ws["AY3"].s = ROPA_STYLES.tertiaryHeader;
  ws["AZ3"].s = ROPA_STYLES.tertiaryHeader;
  ws["BA3"].s = ROPA_STYLES.tertiaryHeader;

  for (let i = 0; i < qtdProcesses; i++) {
    ws["A" + (i + 4)].s = ROPA_STYLES.data;
    ws["B" + (i + 4)].s = ROPA_STYLES.data;
    ws["C" + (i + 4)].s = ROPA_STYLES.data;
    ws["D" + (i + 4)].s = ROPA_STYLES.data;
    ws["E" + (i + 4)].s = ROPA_STYLES.data;
    ws["F" + (i + 4)].s = ROPA_STYLES.data;
    ws["G" + (i + 4)].s = ROPA_STYLES.data;
    ws["H" + (i + 4)].s = ROPA_STYLES.data;
    ws["I" + (i + 4)].s = ROPA_STYLES.data;
    ws["J" + (i + 4)].s = ROPA_STYLES.data;
    ws["K" + (i + 4)].s = ROPA_STYLES.data;
    ws["L" + (i + 4)].s = ROPA_STYLES.data;
    ws["M" + (i + 4)].s = ROPA_STYLES.data;
    ws["N" + (i + 4)].s = ROPA_STYLES.data;
    ws["O" + (i + 4)].s = ROPA_STYLES.data;
    ws["P" + (i + 4)].s = ROPA_STYLES.data;
    ws["Q" + (i + 4)].s = ROPA_STYLES.data;
    ws["R" + (i + 4)].s = ROPA_STYLES.data;
    ws["S" + (i + 4)].s = ROPA_STYLES.data;
    ws["T" + (i + 4)].s = ROPA_STYLES.data;
    ws["U" + (i + 4)].s = ROPA_STYLES.data;
    ws["V" + (i + 4)].s = ROPA_STYLES.data;
    ws["W" + (i + 4)].s = ROPA_STYLES.data;
    ws["X" + (i + 4)].s = ROPA_STYLES.data;
    ws["Y" + (i + 4)].s = ROPA_STYLES.data;
    ws["Z" + (i + 4)].s = ROPA_STYLES.data;
    ws["AA" + (i + 4)].s = ROPA_STYLES.data;
    ws["AB" + (i + 4)].s = ROPA_STYLES.data;
    ws["AC" + (i + 4)].s = ROPA_STYLES.data;
    ws["AD" + (i + 4)].s = ROPA_STYLES.data;
    ws["AE" + (i + 4)].s = ROPA_STYLES.data;
    ws["AF" + (i + 4)].s = ROPA_STYLES.data;
    ws["AG" + (i + 4)].s = ROPA_STYLES.data;
    ws["AH" + (i + 4)].s = ROPA_STYLES.data;
    ws["AI" + (i + 4)].s = ROPA_STYLES.data;
    ws["AJ" + (i + 4)].s = ROPA_STYLES.data;
    ws["AK" + (i + 4)].s = ROPA_STYLES.data;
    ws["AL" + (i + 4)].s = ROPA_STYLES.data;
    ws["AM" + (i + 4)].s = ROPA_STYLES.data;
    ws["AN" + (i + 4)].s = ROPA_STYLES.data;
    ws["AO" + (i + 4)].s = ROPA_STYLES.data;
    ws["AP" + (i + 4)].s = ROPA_STYLES.data;
    ws["AQ" + (i + 4)].s = ROPA_STYLES.data;
    ws["AR" + (i + 4)].s = ROPA_STYLES.data;
    ws["AS" + (i + 4)].s = ROPA_STYLES.data;
    ws["AT" + (i + 4)].s = ROPA_STYLES.data;
    ws["AU" + (i + 4)].s = ROPA_STYLES.data;
    ws["AV" + (i + 4)].s = ROPA_STYLES.data;
    ws["AW" + (i + 4)].s = ROPA_STYLES.data;
    ws["AX" + (i + 4)].s = ROPA_STYLES.data;
    ws["AY" + (i + 4)].s = ROPA_STYLES.data;
    ws["AZ" + (i + 4)].s = ROPA_STYLES.data;
    ws["BA" + (i + 4)].s = ROPA_STYLES.data;
  }

  return ws;
}