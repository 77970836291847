//definindo states iniciais caso não receba
const initialState = {
  Logged: false,
  token: '',
  userId: 0,
  masterProcessId: '',
  masterProcessName: '',
};

//definindo os tipos de cada função
const SET_LOGGED = "SET_LOGGED";
const SET_TOKEN = "SET_TOKEN";
const SET_USER_ID = "SET_USER_ID";
const SET_MASTER_PROCESS_ID = "SET_MASTER_PROCESS_ID";
const SET_MASTER_PROCESS_NAME = "SET_MASTER_PROCESS_NAME";

//função recebe e altera o nome
export const setLogged = data => ({
  type: SET_LOGGED,
  data
});

export const setToken = data => ({
  type: SET_TOKEN,
  data
});

export const setUserId = data => ({
  type: SET_USER_ID,
  data
});

export const setMasterProcessId = data => ({
  type: SET_MASTER_PROCESS_ID,
  data
});

export const setMasterProcessName = data => ({
  type: SET_MASTER_PROCESS_NAME,
  data
});

//criando o reducer para o combine
const newEtapasEPMReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGGED:
      return { ...state, Logged: action.data };
    case SET_TOKEN:
      return { ...state, token: action.data };
    case SET_USER_ID:
      return { ...state, userId: action.data };
    case SET_MASTER_PROCESS_ID:
      return { ...state, masterProcessId: action.data };
    case SET_MASTER_PROCESS_NAME:
      return { ...state, masterProcessName: action.data };
  }
  return state;
}

export default newEtapasEPMReducer;