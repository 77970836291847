import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";

import { isDefined } from "../../../../shared/commands/verifyDefinition";

const database = myApp.firestore();

export const updateAnswers = async (idQuestion, answers) => {
  try {
    await database.collection("questions").doc(idQuestion).update({
      answers
    });
  } catch (error) {
    console.error("Error updateAnswers!", error);
  }
}

export const updateGap = async (idGap, gap, quizId) => {
  try {
    let registeredAnswersSnapshot = await database.collection("quiz_registered_answers")
      .where("quiz_id", "==", quizId)
      .get();

    for (let registeredAnswersDoc of registeredAnswersSnapshot.docs) {
      let quizRegisteredAnswer = registeredAnswersDoc.data();

      for (let registeredAnswer of quizRegisteredAnswer.registered_answers) {
        if (isDefined(registeredAnswer.gap) && registeredAnswer.gap !== null && registeredAnswer.gap.gapId === idGap) {
          registeredAnswer.gap.name = gap.name;
          registeredAnswer.gap.actionPlan = gap.actionPlan;
        }
      }

      await database.collection("quiz_registered_answers").doc(registeredAnswersDoc.id).update({
        registered_answers: quizRegisteredAnswer.registered_answers
      });
    }

    await database.collection("gaps").doc(idGap).update(gap);
  } catch (error) {
    console.error("Error update gap!", error);
  }
}