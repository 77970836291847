import React, { Component } from 'react';

import { connect } from "react-redux";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Typography,
  Grid,
  TextField,
  Button,
  IconButton,
  TableContainer,
  Card
} from '@material-ui/core';
import myApp from '../../../core/infrastructure/firebaseConfig';
import "firebase/compat/firestore";
import LegalBasisList from './legalBasisList';
import { Close } from '@material-ui/icons';
import ArrowBackIosTwoToneIcon from "@material-ui/icons/ArrowBackIosTwoTone";
import { changePage } from "../redux/actions";


import theme from '../../../core/theme.json'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class LegalBasis extends Component {

  constructor(props) {
    super(props);

    this.state = {
      database: myApp.firestore(),
      name: '',
      description: '',
      legalJustification: '',
      legalBasisIndex: 0,
      legalBasisList: [],
      openDialog: false,
      editDialog: false
    }
  }

  componentDidMount() {
    let self = this
    this.getLegalBasis();
    setTimeout(() => {
      console.log(self.state);
    }, 2000);
  }

  async getLegalBasis() {
    let legalBasisData = await this.state.database.collection('legal_basis').orderBy('name', 'asc').get();
    let legalBasisList = [];
    legalBasisData.docs.forEach(doc => {
      let lb = doc.data()
      lb["id"] = doc.id;
      legalBasisList.push(lb)
    })

    this.setState({ legalBasisList })
  }

  async createLegalBasis() {
    let name = this.state.legalBasisName;
    let description = this.state.legalBasisDescription;
    let legalJustification = this.state.legalBasisLegalJustification;
    let fk_company = localStorage.getItem('company');
    let self = this;

    let legalBasis = {
      name,
      description,
      legalJustification,
      fk_company
    }

    await this.state.database.collection('legal_basis').add({
      name,
      description,
      legalJustification,
      fk_company
    })
      .then((docRef) => {
        console.log("legalBasis added with ID: ", docRef.id);
        legalBasis.id = docRef.id;
        let aux = self.state.legalBasis;
        aux.push(legalBasis);
        self.setState({
          legalBasis: aux
        })
      })
      .catch((error) => {
        console.error("Error adding legalBasis: ", error);
      });
  }

  async updateLegalBasis(index) {
    console.log('updateLegalBasis index', index);
    let name = this.state.name;
    let description = this.state.description;
    let legalJustification = this.state.legalJustification;
    let legalBasisList = this.state.legalBasisList;

    let id = legalBasisList[index].id;
    legalBasisList[index].name = name;
    legalBasisList[index].description = description;
    legalBasisList[index].legalJustification = legalJustification;


    if (name && id) {
      await this.state.database.collection('legal_basis').doc(id).update({
        name,
        description,
        legalJustification
      })
        .then((docRef) => {
          console.log("legalBasis updated with ID: " + docRef.id);
        })
        .catch((error) => {
          console.error("Error adding legalBasis: ", error);
        });
      this.setState({
        legalBasisList
      })
      console.log(this.state);
    } else {
      console.log('error updating legalBasis');
      console.log(name, description, legalJustification, id);
    }
  }

  async deleteLegalBasis(id) {
    if (id) {
      this.state.database.collection("legal_basis").doc(id).delete().then(() => {
        console.log("legalBasis successfully deleted!");
      }).catch((error) => {
        console.error("Error removing legalBasis: ", error);
      });
    }
  }

  handleEditLegalBasis = () => {

  }

  handleAddLegalBasis = () => {
    this.setState({
      openDialog: true
    })
  }

  handleName = (value) => {
    this.setState({
      name: value
    })
  }

  handleDescription = (value) => {
    this.setState({
      description: value
    })
  }

  handleLegalJustification = (value) => {
    this.setState({
      legalJustification: value
    })
  }

  handleClose = () => {
    this.setState({
      openDialog: false,
      editDialog: false,
      name: '',
      description: '',
      legalJustification: ''
    })
  }

  handleSaveLegalBasis = () => {
    if (!this.state.editDialog) {
      if (this.state.name) {
        this.addLegalBasis();
      }
    } else {

    }
    console.log(this.state);
  }

  handleUpdateLegalBasis = () => {
    console.log('handleUpdateLegalBasis');
    this.updateLegalBasis(this.state.legalBasisIndex);
    this.handleClose();
  }

  async addLegalBasis() {

    if (this.state.name) {
      let name = this.state.name;
      let description = this.state.description;
      let legalJustification = this.state.legalJustification;
      let legalBasisList = this.state.legalBasisList;
      await this.state.database.collection('legal_basis').add({
        name,
        description,
        legalJustification
      })
        .then((docRef) => {
          console.log("legal_basis added with ID: ", docRef.id);
          let legalBasis = {
            id: docRef.id,
            name,
            description,
            legalJustification
          }

          legalBasisList.push(legalBasis);
          this.setState({
            legalBasisList
          })
          this.handleClose();
        })
        .catch((error) => {
          console.error("Error adding legal_basis: ", error);
        });
    }
  }

  handleEdit = (index) => {
    let legalBasis = this.state.legalBasisList[index];
    console.log('handleEdit index', index);


    this.setState({
      name: legalBasis.name,
      description: legalBasis.description,
      legalJustification: legalBasis.legalJustification,
      legalBasisIndex: index,
      editDialog: true,
      openDialog: true
    })

  }

  handleDelete = (index) => {
    let legalBasisList = this.state.legalBasisList;

    console.log('before legalBasisList', legalBasisList);
    this.deleteLegalBasis(legalBasisList[index].id);
    legalBasisList = legalBasisList.splice(index, 1);
    this.setState({ legalBasisList });
    setTimeout(() => {
      console.log('after legalBasisList', legalBasisList);
    }, 1500);
  }

  handleNameValue() {
    if (this.state.editDialog) {
      return this.state.name;
    }
  }

  handleDescriptionValue() {
    if (this.state.editDialog) {
      return this.state.description;
    }
  }

  handleLegalJustificationValue() {
    if (this.state.editDialog) {
      return this.state.legalJustification;
    }
  }

  render() {
    const { openDialog, legalBasisList } = this.state;
    return (
      <>
        <Grid
          item
          style={{
            width: "100%"
          }}
        >
          <Card
            style={{
              padding: "16px"
            }}
          >
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              <Grid
                item
                style={{
                  width: "5%"
                }}
              >
                <IconButton
                  size="small"
                  style={{
                    marginLeft: "10px"
                  }}
                  onClick={() => this.props.changePage(14)}
                  >
                  <ArrowBackIosTwoToneIcon />
                </IconButton>
              </Grid>
              <Grid
                item
                style={{
                  width: "95%"
                }}
              >
                <Typography
                  variant="h5"
                  component="h5"
                >
                  Bases legais
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {/* <Button
					style={{ backgroundColor: theme.primary_color, color: theme.secondary_color }}
					onClick={this.handleAddLegalBasis}
				>
					Adicionar
				</Button> */}
        <Card style={{ marginTop: "1%", padding: "8px", marginBottom: "16px" }}>
          <TableContainer >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>Descrição</TableCell>
                  <TableCell>Justificativa</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <LegalBasisList
                  legalBasisList={legalBasisList}
                  handleEdit={this.handleEdit}
                  handleDelete={this.handleDelete}
                />
              </TableBody>
            </Table>
          </TableContainer>
        </Card>

        <Dialog
          open={openDialog}
          TransitionComponent={Transition}
          maxWidth="sm"
          fullWidth
          onClose={() => this.handleClose()}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          contentStyle={{
            width: '80%',
            maxWidth: 'none',
          }}
        >
          <DialogTitle id="alert-dialog-slide-title" style={{ backgroundColor: theme.secondary_color }} >
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-between"
            >
              <Grid item>
                <Typography variant="h6" style={{ fontFamily: "Arial", color: "white", position: "absolute", left: "220px" }}>Adicionar base legal</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={() => this.handleClose()}>
                  <Close style={{ color: "white" }} />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="center"
                spacing={2}
              >
                <Grid item xs={12}>
                  <TextField
                    placeholder="Nome"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    onChange={e => this.handleName(e.target.value)}
                    value={this.handleNameValue()}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField

                    placeholder="Descrição"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    onChange={e => this.handleDescription(e.target.value)}
                    value={this.handleDescriptionValue()}
                    InputLabelProps={{
                      shrink: true,
                    }}

                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField

                    placeholder="Justificativa Legal"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    onChange={e => this.handleLegalJustification(e.target.value)}
                    value={this.handleLegalJustificationValue()}
                    InputLabelProps={{
                      shrink: true,
                    }}

                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={this.state.editDialog ? () => this.handleUpdateLegalBasis() : () => this.handleSaveLegalBasis()}
                    style={{ color: theme.secondary_color, backgroundColor: theme.primary_color, borderColor: theme.primary_color }}
                  >
                    Salvar
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => this.handleClose()}
                    style={{ color: theme.primary_color, backgroundColor: "white", borderColor: theme.primary_color }}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </>
    );
  }

}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changePage: (page) => dispatch(changePage(page)),
});
//export default LegalBasis;
export default connect(mapStateToProps, mapDispatchToProps)(LegalBasis);
//export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LegalBasis));