import { useEffect ,useState} from "react";
import { useParams } from "react-router-dom";
import myApp from "../../../../core/infrastructure/firebaseConfig";
import { Typography, Grid, Zoom } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";

export const Conclusation = ({questData}) => {
    const { companyGroupId } = useParams();
    const email = localStorage.getItem("verificationEmail")
  const [sucssess, setSucssess] = useState(true);
  const [myAnswers,setMyAnswers] = useState([])

  useEffect(() =>{
    getdados()
  },[])

console.log(questData)
  const getdados = async () => {
    try {
      let get = await myApp
        .firestore()
        .collection("AnswerModuloVideo")
        .where("fk_company", "==", companyGroupId)
        .where("email","==",email)
        .get();
      let response = get.docs.map((item) => item.data());

      setMyAnswers(response);
    console.log("achou", get.docs.map(item => item.data()))
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Grid
      style={{ height: "100vh", flexDirection: "column" }}
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        style={{ width: "50%", flexDirection: "column" }}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h5"> Seu teste foi salvo com sucesso!</Typography>

        <Zoom style={{marginTop:"10%"}} in={sucssess} timeout={800}>
          <CheckCircle style={{ fontSize: 100, color: "green" }} />
        </Zoom>
        <Typography style={{marginTop:"10%"}} variant="h6">
         Pontuação : {myAnswers.map(item => item.point[myAnswers.map(item => item.point.length) - 1])} / {questData.questions.length}
        </Typography>

        <Typography style={{marginTop:"10%"}} variant="h6">
          {" "}
          O teste foi salvo e computado no nosso banco de dados , mas se prefere
          fazer um novo teste é só clicar no link abaixo.
        </Typography>
        <span 
        onClick={() => window.location.reload()}
        style={{marginTop:"10%", color:"#4682B4", cursor:"pointer"}}> fazer um novo teste</span>
      </Grid>
    </Grid>
  );
};
