import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Typography,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ButtonBase,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import theme from "../../../../core/theme.json";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Title from "../../../../shared/components/title";
import myApp from '../../../../core/infrastructure/firebaseConfig';
import ArrowBackIosTwoToneIcon from "@material-ui/icons/ArrowBackIosTwoTone";
import { useDispatch } from "react-redux";
import { changePage } from '../redux/actions';
import { idModuleName, idQuizModule } from '../redux/actions';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';

const TrainingVideo = () => {
  const dispatch = useDispatch();
  const companyId = localStorage.getItem("currentCompany");
  const [video, setVideo] = useState({
    title: '',
    url: '',
    fk_company: companyId
  });
  const [loading, setLoading] = useState({
    getModules: true,
    getVideos: true,
    getQuiz: true
  });
  const [myModules, setMyModules] = useState([]);
  const [moduleNameState, setModuleName] = useState('');
  const [myVideos, setMyVideos] = useState([]);
  const [dialogs, setDialogs] = useState({
    deleteModuleVideo: false,
    editModuleVideo: false
  });
  const [deleteM, setDeleteM] = useState({
    name: '',
    id: ''
  });

  const [newQuiz, setNewQuiz] = useState({
    name: '',
    nameModule:'',
    idModule: '',
    videoId:'',
    indexVideo: '',
    questions: [],
    fk_company: localStorage.getItem('currentCompany'),
  });

  const [listVideosModule, setListVideosModule] = useState([]);
  const [quizzes, setQuizzes] = useState([]);
  
  const location = useLocation();

  const delay = (n) => new Promise(r => setTimeout(r, n * 1000));

  useEffect(() => {
    handlemountingPage();
    console.log('Rota atual:', location.pathname);
  }, []);

  const handlemountingPage = async () => {
    await getModules();
    await getVideos();
    await getQuizzes();
  }

  const getModules = async () => {
    let aux = [];
    setLoading({
      ...loading,
      getModules: true
    });

    try {
      let get = await myApp.firestore().collection('moduleName').where("fk_company", "==", companyId).get();
      if (get.docs) {
        for (let item of get.docs) {
          aux.push({
            id: item.id,
            name: item.data().name,
            // questId : item.data().videos
          });
        }
      }
    } catch (errorGetModules) {
      console.error('errorGetModules', errorGetModules)
    }

    setMyModules(aux);

    setLoading({
      ...loading,
      getModules: false
    });
  }

  const getVideos = async () => {
    setLoading({
      ...loading,
      getVideos: true
    });
    let aux = [];
    try {
      let get = await myApp.firestore().collection('video').where("fk_company", "==", companyId).get();
      if (get.docs) {
        for (let item of get.docs) {
          aux.push({
            id: item.id,
            name: item.data().moduleName,
            videos: item.data().videos,
          });
        }
      }
    } catch (errorgetVideos) {
      console.error('errorgetVideos', errorgetVideos);
    }
    setMyVideos(aux);
    console.log(aux);
    setLoading({
      ...loading,
      getVideos: false
    });
  }

  const getQuizzes = async () => {
    setLoading({
      ...loading,
      getQuiz: true
    });
    let aux = [];
    try {
      let get = await myApp.firestore().collection('quizModuleVideo').where("fk_company", "==", companyId).get();
      if (get.docs) {
        for (let item of get.docs) {
          aux.push({
            id: item.id,
            name: item.data().name,
          });
        }
      }
      console.log('o que vem do quiz',get.docs.map(item => item.data()));
    } catch (errorGetQuiz) {
      console.error('errorGetQuiz', errorGetQuiz);
    }
    setQuizzes(aux);
    setLoading({
      ...loading,
      getQuiz: false
    });
  }

  const clearData = () => {
    setVideo({
      title: '',
      url: '',
      fk_company: companyId
    });
  }

  const handleAddNewModule = async () => {
    try {
      myApp.firestore().collection('moduleName').add({
        name: moduleNameState,
        fk_company: companyId,
        videos: [],
      });
    } catch (errorAddNewModule) {
      console.error('errorAddNewModule', errorAddNewModule);
    }
    setModuleName('');
    getModules();
  }

  const handleChangeSelectedVideo = (item) => {
    dispatch(idModuleName(item.id));
    dispatch(changePage(36));
  }

  const handleChangeSelectedQuiz = (item) => {
    dispatch(idQuizModule(item.id));
    dispatch(changePage(37));
  }

  const handleDeleteSelectedVideo = (item) => {
    setDeleteM({
      name: item.name,
      id: item.id
    });
    setDialogs({
      ...dialogs,
      deleteModuleVideo: true
    });
  }

  const handleEditSelectedVideo = (item) => {
    setDeleteM({
      name: item.name,
      id: item.id
    });
    setDialogs({
      ...dialogs,
      editModuleVideo: true
    });
  }

  const handleCloseDialog = (item) => (value) => {
    setDialogs({
      ...dialogs,
      [item]: false
    });
  }

  const handleSelectedModuleVideo = async (event) => {
     
    let auxModule = {}
    for(let item of myModules){
      if(event.target.value == item.id){
          auxModule ={
            nameModule: item.name
          }
      }
    }


    setNewQuiz({ ...newQuiz, idModule: event.target.value, nameModule: auxModule.nameModule })
    let get = await myApp.firestore().collection('moduleName').doc(event.target.value).get();
    let aux = [];
    for (let i = 0; i < get.data().videos.length; i++) {
      aux.push({
        name: get.data().videos[i].name,
        indexVideo: i,
        videoId : get.data().videos[i].videoId
      });
    }
    setListVideosModule(aux);
  }

  const handleSelectedVideoModule = (event) => {
    let tempId = listVideosModule.map(item => item.videoId)[event.target.value]
    console.log(listVideosModule.map(item => item.videoId)[event.target.value])
    //newQuiz.indexVideo
    setNewQuiz({
      ...newQuiz,
      indexVideo: event.target.value,
      videoId:tempId,
    });
  }

  const handleAddNewQuizModule = () => {
    console.log('new quiz',newQuiz)

    if(newQuiz.indexVideo === ""){
      Swal.fire({
        icon: "error",
        title: 'deve ter pelo menos 1 vídeo selecionado',
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
  
    myApp.firestore().collection('quizModuleVideo').add(newQuiz)
      .then(() => {
        getQuizzes();
        setNewQuiz({
          name: '',
          idModule: '',
          indexVideo: '',
          videoId:'',
          questions: [],
          fk_company: localStorage.getItem('currentCompany'),
        });
      })
      .catch((e) => console.error(e))
  }

  return (
    <Grid
      container
      direction='column'
    >
      <Title>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          
          <Grid item>Vídeos de treinamento</Grid>
        </Grid>
      </Title>

      <Grid
        container
        item
        direction="row"
        style={{
          padding: 10
        }}
      >
        <Accordion
          style={{
            width: '100%',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography >Cadastro módulos de vídeo</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              spacing={2}
              justifyContent='space-between'
              alignItems='center'
            >
              <Grid
                item
                xs={6}
                sm={6}
              >
                <TextField
                  type="text"
                  fullWidth
                  label="Nome do módulo"
                  variant="outlined"
                  value={moduleNameState}
                  onChange={(event) => setModuleName(event.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={4}
                sm={4}
              >
                <Button
                  fullWidth
                  variant="contained"
                  style={{
                    backgroundColor: theme.primary_color,
                    color: theme.secondary_color,
                    fontWeight: 'bold',
                  }}
                  onClick={handleAddNewModule}
                >
                  Cadastrar
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid
        container
        item
        direction="row"
        style={{
          padding: 10
        }}
      >
        <Accordion
          style={{
            width: '100%',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography >Módulos de vídeo</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              spacing={2}
              justifyContent='space-around'
              alignItems='center'
            >

              {
                myModules.length > 0 ?
                  myModules.map((item, index) => {
                   return (
                      <Grid
                        key={index}
                        item
                        alignItems="flex-start"
                        direction="column"
                        xs={12} sm={12} md={3} lg={3} xl={3}
                      >
                        <ButtonBase
                          style={{ margin: '10px', width: '100%', height: '250px' }}
                        >
                          <Paper
                            onClick={() => handleChangeSelectedVideo(item)}
                            elevation={5}
                            style={{ margin: '10px', width: '100%', height: '250px' }}
                          >
                            <Grid
                              container
                              item
                              direction="column"
                              justifyContent="center"
                              alignItems="center"
                              style={{
                                height: '100%',
                              }}
                            >
                              <OndemandVideoIcon style={{ fontSize: 75, color: 'rgb(80, 93, 109)' }} />
                              <span
                                style={{
                                  fontSize: '22px',
                                  color: '#909090',
                                  margin: '10px'
                                }}
                              >
                                {item.name}
                              </span>
                            </Grid>
                          </Paper>
                        </ButtonBase>
                        <Grid
                          container
                          item
                          justifyContent='center'
                          alignItems='center'
                          style={{ paddingLeft: 15 }}
                        >
                          <Tooltip title="Excluir">
                          <ButtonBase
                            onClick={() => handleDeleteSelectedVideo(item)}
                          >
                            <CloseIcon style={{ fontSize: 33, color: theme.secondary_color, }} />
                          </ButtonBase>
                          </Tooltip>
                          <Tooltip title="Editar">
                          <ButtonBase
                            onClick={() => handleEditSelectedVideo(item)}
                            style={{
                              marginLeft: 15
                            }}
                          >
                            <EditIcon style={{ fontSize: 30, color: theme.secondary_color, }} />
                          </ButtonBase>
                          </Tooltip>
                        </Grid>
  
                      </Grid>
                    )
                  }
                  )
                  :
                  <Grid
                    item
                    alignItems="flex-start"
                    direction="column"
                    xs={12} sm={12} md={3} lg={3} xl={3}
                  >
                    <ButtonBase
                      style={{ margin: '10px', width: '100%', height: '250px', }}
                      disabled={true}
                    >
                      <Paper
                        elevation={5}
                        style={{ width: '100%', height: '100%', backgroundColor: '#ccc' }}
                      >
                        <Grid
                          container
                          item
                          direction="column"
                          justify="center"
                          alignItems="center"
                          style={{
                            height: '100%',
                          }}
                        >
                          <WarningIcon style={{ fontSize: 75, color: theme.secondary_color }} />
                          <span
                            style={{
                              fontSize: '22px',
                              color: theme.secondary_color,
                              margin: '10px'
                            }}
                          >
                            Sem módulos cadastrados...
                          </span>
                        </Grid>
                      </Paper>
                    </ButtonBase>
                  </Grid>
              }
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid
        container
        item
        direction="row"
        style={{
          padding: 10
        }}
      >
        <Accordion
          style={{
            width: '100%',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography >Cadastro questionário módulos de vídeo</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              spacing={2}
              justifyContent='space-between'
              alignItems='center'
            >
              <Grid
                item
                xs={12}
                sm={12}
              >
                <TextField
                  type="text"
                  style={{ width: '25%' }}
                  label="Nome do questinário"
                  variant="outlined"
                  value={newQuiz.name}
                  onChange={(event) => setNewQuiz({ ...newQuiz, name: event.target.value })}
                />
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
              >
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                >
                  <InputLabel>Qual módulo? </InputLabel>
                  <Select
                    value={newQuiz.idModule}
                    onChange={(event) => handleSelectedModuleVideo(event)}
                    label="Qual módulo"
                  >
                    <MenuItem value="">{myModules > 0 ? 'Selecione módulo de vídeo' : 'Sem módulos cadastrados'}</MenuItem>
                    {
                      myModules.length > 0 ?
                        myModules.map((item, index) =>{
                          return(
                            <MenuItem key={index}  value={item.id}>{item.name}</MenuItem>
                          )
                        } 
                        )
                       
                        :
                        <></>
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
              >
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                >
                  <InputLabel>{newQuiz.idModule === '' ? 'Escolha um módulo antes...' : 'Selecione o vídeo'}</InputLabel>
                  <Select
                    disabled={newQuiz.idModule === '' ? true : false}
                    value={newQuiz.indexVideo}
                    // onChange={(event) => setNewQuiz({ ...newQuiz, name: event.target.value })}
                    onChange={(event) => handleSelectedVideoModule(event)}
                    label="Qual módulo"
                    style={{
                      backgroundColor: newQuiz.idModule === '' ? '#ccc' : '#fff',
                    }}
                  >
                    <MenuItem value="">Escolha o Video...</MenuItem>
                    {
                      listVideosModule.length > 0 ?
                        listVideosModule.map((item, index) => (
                          <MenuItem key={index} value={item.indexVideo}>{item.name}</MenuItem>
                        ))
                        :
                        <></>
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
              >
                <Button
                  fullWidth
                  variant="contained"
                  style={{
                    backgroundColor: theme.primary_color,
                    color: theme.secondary_color,
                    fontWeight: 'bold',
                  }}
                  // onClick={handleAddNewModule}
                  onClick={handleAddNewQuizModule}
                >
                  Cadastrar
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid
        container
        item
        direction="row"
        style={{
          padding: 10
        }}
      >
        <Accordion
          style={{
            width: '100%',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography >Questionários cadastrados</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              spacing={2}
              justifyContent='space-around'
              alignItems='center'
            >

              {
                quizzes.length > 0 ?
                  quizzes.map((item, index) => (
                    <Grid
                      key={index}
                      item
                      alignItems="flex-start"
                      direction="column"
                      xs={12} sm={12} md={3} lg={3} xl={3}
                    >
                      <ButtonBase
                        style={{ margin: '10px', width: '100%', height: '250px' }}
                      >
                        <Paper
                          onClick={() => handleChangeSelectedQuiz(item)}
                          elevation={5}
                          style={{ margin: '10px', width: '100%', height: '250px' }}
                        >
                          <Grid
                            container
                            item
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            style={{
                              height: '100%',
                            }}
                          >
                            <QuestionAnswerIcon style={{ fontSize: 75, color: 'rgb(80, 93, 109)' }} />
                            <span
                              style={{
                                fontSize: '22px',
                                color: '#909090',
                                margin: '10px'
                              }}
                            >
                              {item.name}
                            </span>
                          </Grid>
                        </Paper>
                      </ButtonBase>
                    </Grid>
                  ))
                  :
                  <Grid
                    item
                    alignItems="flex-start"
                    direction="column"
                    xs={12} sm={12} md={3} lg={3} xl={3}
                  >
                    <ButtonBase
                      style={{ margin: '10px', width: '100%', height: '250px', }}
                      disabled={true}
                    >
                      <Paper
                        elevation={5}
                        style={{ width: '100%', height: '100%', backgroundColor: '#ccc' }}
                      >
                        <Grid
                          container
                          item
                          direction="column"
                          justify="center"
                          alignItems="center"
                          style={{
                            height: '100%',
                          }}
                        >
                          <QuestionAnswerIcon style={{ fontSize: 75, color: theme.secondary_color }} />
                          <span
                            style={{
                              fontSize: '22px',
                              color: theme.secondary_color,
                              margin: '10px'
                            }}
                          >
                            Sem questionários cadastrados...
                          </span>
                        </Grid>
                      </Paper>
                    </ButtonBase>
                  </Grid>
              }
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Dialog
        fullWidth
        open={dialogs.deleteModuleVideo}
        onClose={handleCloseDialog('deleteModuleVideo')}
      >
        <DialogTitle
        >
          ATENÇÃO!
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              textAlign="center"
            >
              <Typography
                variant='h4'
                component='div'
              >
                Tem certeza que deseja excluir módulo: <span style={{ color: '#f00' }} >{deleteM.name}</span>
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              backgroundColor: '#f00',
              color: '#fff'
            }}
            onClick={handleCloseDialog('deleteModuleVideo')}
            autoFocus
          >
            Cancelar
          </Button>
          <Button
            style={{
              backgroundColor: '#ccc'
            }}
            onClick={() => {
              myApp.firestore().collection('moduleName').doc(deleteM.id).delete().then(() => {
                handlemountingPage();
                setDeleteM({
                  name: '',
                  id: ''
                });
                setDialogs({
                  ...dialogs,
                  deleteModuleVideo: false
                });
              });
            }}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        open={dialogs.editModuleVideo}
        onClose={handleCloseDialog('editModuleVideo')}
      >
        <DialogTitle
        >
          Alterar nome do módulo de vídeo
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              textAlign="center"
              style={{
                width: '100%',
              }}
            >
              <TextField
                autoFocus
                fullWidth
                label="Novo nome"
                variant="outlined"
                value={deleteM.name}
                onChange={(event) => {
                  setDeleteM({
                    ...deleteM,
                    name: event.target.value
                  });
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              backgroundColor: '#f00',
              color: '#fff'
            }}
            onClick={handleCloseDialog('editModuleVideo')}
            autoFocus
          >
            Cancelar
          </Button>
          <Button
            style={{
              backgroundColor: '#ccc'
            }}
            onClick={() => {
              myApp.firestore().collection('moduleName').doc(deleteM.id).update({
                name: deleteM.name
              }).then(() => {
                handlemountingPage();
                setDeleteM({
                  name: '',
                  id: ''
                });
                setDialogs({
                  ...dialogs,
                  editModuleVideo: false
                });
              });
            }}
            autoFocus
          >
            Alterar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default TrainingVideo;