import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";

import { Grid, Typography, Button, Dialog, DialogActions, DialogContent, Slide, Paper, IconButton, CircularProgress } from "@material-ui/core";
import { Flowpoint, Flowspace } from "flowpoints";
import { withStyles } from "@material-ui/core/styles";

import { getQuestionDatabaseId, setIdQuestionsInserted, setQuiz, setLoadingQuiz } from "../redux/actions";
import { saveQuestions, saveAnswers, saveGap } from "../functions/savePoint";
import { deleteQuestion, deleteAnswer, deleteGap } from "../functions/deletePoint";
import { getAnswers } from "../functions/getPoints";
import updateLeadTo from "../functions/updateLeadTo";
import { updateAnswers } from "../functions/updatePoints";
import { changePage } from "../../../presentation/redux/actions";
import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import CardQuiz from "./cardQuiz";
import DrawerQuiz from "./drawerQuiz";

import "./insertQuiz.css"

import EditIcon from "@material-ui/icons/Edit";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import EditQuiz from "./editQuiz";
import { isDefined } from "../../../../shared/commands/verifyDefinition";

import theme from "../../../../core/theme.json";

const styles = theme => ({
  card: {
    padding: "16px",
    fontSize: "24px",
    marginBottom: "10px",
  },
  saveFlowButton: {
    background: "#38F205",
    color: "#192a40",
    "&:hover": {
      background: "#91ff79"
    }
  },
  gridFlowSpace: {
    overflow: "hidden !important"
  },
  breadCrumbItem: {
    color: "#858DA0"
  },
  dialogButtonCancel: {
    padding: "10px",
    color: "#707070",
    border: "1px solid #707070",
    marginTop: "10px",
  },
  dialogButtonDelete: {
    padding: "10px",
    color: "#EA0707E8",
    border: "1px solid #EA0707E8",
    marginTop: "10px",
  },
  button: {
    margin: "0.5%",
    fontSize: "18px",
    backgroundColor: "#38F205",
    padding: "0.5%",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class InsertQuiz extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openDrawer: false,
      selected: null,
      selectedLine: null,
      points: {},
      lastPos: { x: 150, y: 50 },
      lastKey: 0,
      doFocus: false,
      savedFlow: false,
      typeCard: "",
      messageDialog: "",
      disableAddButton: false,
      pointsToDel: [],
      point: {
        cardProps: {
          title: "",
          content: "",
          typeCard: "",
          leadTo: "",
          disableAddButton: false,
        },
        pos: {},
        outputs: {},
      },
      isOption: false,
      saveDialog: false,
      errorSaveDialog: false,
      deleteDialog: false,
      userInformation: JSON.parse(sessionStorage.getItem("@blue-legal/userInformation")),
      dialog: false,
      // drawer controlled states
      question: "",
      DPIA: false,
      ROPA: false,
      answer: "",
      observation: "",
      gap: false,
      gapText: "",
      actionPlan: "",
      typeQuestion: "",
      errorAnswer: false,
      errorQuestion: false,
      gapError: false,
      actionPlanError: false,
    };

    // Helper variables
    this.diagramRef = null;
    this.count = Object.keys(this.state.points).length;

    // Binding class methods
    this.handleClick = this.handleClick.bind(this);
    // this.handleTouch = this.handleTouch.bind(this)
    this.handleAddPoint = this.handleAddPoint.bind(this);
    this.addCard = this.addCard.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.handleQuestion = this.handleQuestion.bind(this);
    this.handleDPIA = this.handleDPIA.bind(this);
    this.handleROPA = this.handleROPA.bind(this);
    this.handleAnswer = this.handleAnswer.bind(this);
    this.handleObservation = this.handleObservation.bind(this);
    this.handleGap = this.handleGap.bind(this);
    this.handleGapText = this.handleGapText.bind(this);
    this.handleActionPlan = this.handleActionPlan.bind(this);
    this.handleErrorQuestion = this.handleErrorQuestion.bind(this);
    this.handleErrorAnswer = this.handleErrorAnswer.bind(this);
    this.handleTypeQuestion = this.handleTypeQuestion.bind(this);
    this.updatePointQuestion = this.updatePointQuestion.bind(this);
    this.updatePointAnswer = this.updatePointAnswer.bind(this);

    // Adding dash
    Object.keys(this.state.points).map(p_key => {
      Object.keys(this.state.points[p_key].outputs).map(o_key => {
        if (!("dash" in this.state.points[p_key].outputs[o_key])) {
          this.state.points[p_key].outputs[o_key].dash = 0;
        }
      });
    });
  }

  async componentDidMount() {
    const { getQuizReducer, setLoadingQuiz } = this.props;
    const { points } = getQuizReducer;

    var keys = Object.keys(points);
    this.count = parseInt(keys[keys.length - 1]) + 1;

    this.setState({
      points,
      selected: "" + (this.count - 1)
    });

    setLoadingQuiz(false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState, this.state)) {
      this.saveQuizLocal(false);
    }
  }

  async handleAddPoint() {
    const { getQuestionDatabaseId, setIdQuestionsInserted } = this.props;
    const { points, lastKey, lastPos, selected, question, DPIA, ROPA, typeQuestion, answer, observation, gap, gapText, actionPlan } = this.state;
    const typeCardPoint = points[lastKey].cardProps.typeCard;

    var newPoint = {};
    var idQuestion = "";

    if (typeCardPoint === "primary" || typeCardPoint === "answer") {
      try {
        idQuestion = await saveQuestions(question, DPIA, ROPA, typeQuestion);
        getQuestionDatabaseId(idQuestion);
        setIdQuestionsInserted(idQuestion);
      } catch (error) {
        console.error("Error save question!", error);
      }

      newPoint = {
        cardProps: {
          id: idQuestion,
          title: question,
          content: "",
          typeCard: "question",
          disableAddButton: false,
          DPIA: DPIA,
          ROPA: ROPA,
          typeQuestion: typeQuestion
        },
        pos: { x: lastPos.x + 100, y: lastPos.y + 100 },
        outputs: {}
      };

      this.setState({
        lastPos: { x: this.state.lastPos.x + 100, y: this.state.lastPos.y + 100 }
      });
    } else {
      const id = points[selected].cardProps.id;
      var gapId = "";

      if (gap) {
        gapId = await saveGap(gapText, actionPlan);
      }

      newPoint = {
        cardProps: {
          idQuestion: id,
          title: answer,
          content: observation,
          typeCard: "answer",
          leadTo: "",
          responseOf: lastKey,
          disableAddButton: false,
          gapId: gapId,
          gap: gap,
          gapText: gapText,
          actionPlan: actionPlan
        },
        pos: { x: lastPos.x + 100, y: lastPos.y + 100 },
        outputs: {}
      };

      this.setState({
        lastPos: { x: this.state.lastPos.x + 100, y: this.state.lastPos.y + 100 }
      });

      try {
        await saveAnswers(newPoint, id, gapId, gap);
      } catch (error) {
        console.error("error save answer", error);
      }
    }

    points[selected].outputs = {
      ...points[selected].outputs,
      [this.count]: {
        output: "auto",
        input: "auto"
      }
    }

    let pointsAux = points;

    if (typeCardPoint === "primary" || typeCardPoint === "answer") {
      pointsAux[selected].cardProps.disableAddButton = true;
    }

    pointsAux["" + this.count] = newPoint;

    if (typeCardPoint === "answer") {
      await updateLeadTo(points[selected], idQuestion);
      points[selected].cardProps.leadTo = this.count;
    }

    this.count += 1;

    this.setState({
      points: pointsAux,
      selected: "" + (this.count - 1),
      point: newPoint
    }, () => {
      this.closeDrawer();
    });
  }

  updatePointQuestion() {
    let pointsAux = this.state.points;
    pointsAux[this.state.selected].cardProps.title = this.state.question;
    pointsAux[this.state.selected].cardProps.DPIA = this.state.DPIA;
    pointsAux[this.state.selected].cardProps.ROPA = this.state.ROPA;
    pointsAux[this.state.selected].cardProps.typeQuestion = this.state.typeQuestion;

    this.setState({
      points: pointsAux
    }, () => {
      this.closeDrawer();
    });
  }

  updatePointAnswer() {
    let pointsAux = this.state.points;
    pointsAux[this.state.selected].cardProps.title = this.state.answer;
    pointsAux[this.state.selected].cardProps.content = this.state.observation;
    pointsAux[this.state.selected].cardProps.gap = this.state.gap;
    pointsAux[this.state.selected].cardProps.gapText = this.state.gapText;
    pointsAux[this.state.selected].cardProps.actionPlan = this.state.actionPlan;

    this.setState({
      points: pointsAux
    }, () => {
      this.closeDrawer();
    });
  }

  openDrawer() {
    this.setState({
      openDrawer: true
    });
  }

  closeDrawer() {
    this.setState({
      errorAnswer: false,
      errorQuestion: false,
      gapError: false,
      actionPlanError: false,
      openDrawer: false
    });
  }

  addCard(id, point) {
    this.doFocus = true;
    let selected = id;

    this.setState({
      selected,
      lastKey: id,
      point,
      lastPos: point.pos,
      isOption: false,
      question: "",
      DPIA: false,
      ROPA: false,
      answer: "",
      observation: "",
      gap: false,
      gapText: "",
      actionPlan: "",
      typeQuestion: ""
    }, () => this.openDrawer());
  }

  async handleClick(id, e) {
    let selected = this.state.selected;
    const points = this.state.points;
    const { questionDatabaseId } = this.props;

    if (e.shiftKey) {
      var p1 = points[selected];
      const validPointToLeadTo = points[id].cardProps.typeCard === "question"
        && p1.cardProps.typeCard === "answer"
        && p1.cardProps.responseOf !== id
        && !(selected in points[id].outputs);

      if (validPointToLeadTo) {
        if (selected === null) {
          selected = id;
        } else {
          if (selected !== id) {
            if (id in p1.outputs) {
              points[selected].cardProps.disableAddButton = false;
              delete p1.outputs[id];
            } else {
              p1.outputs[id] = {
                output: "auto",
                input: "auto",
              };

              points[selected].cardProps.disableAddButton = true;
            }

            await updateLeadTo(points[selected], questionDatabaseId);
          }
        }
      }
    } else {
      selected = id;
    }

    this.setState({ selected, points });
  }

  onDrag(position, key) {
    var points = this.state.points;

    points[key].pos = position;

    this.setState({
      points,
      lastPos: position
    });
  }

  optionCard(key, point) {
    let selected = key;

    if (point.cardProps.typeCard === "question") {
      this.setState({
        question: point.cardProps.title,
        DPIA: point.cardProps.DPIA,
        ROPA: point.cardProps.ROPA,
        answer: "",
        observation: "",
        gap: false,
        gapText: "",
        actionPlan: "",
        typeQuestion: point.cardProps.typeQuestion
      });
    } else {
      this.setState({
        question: "",
        DPIA: false,
        ROPA: false,
        answer: point.cardProps.title,
        observation: point.cardProps.content,
        gap: point.cardProps.gap,
        gapText: point.cardProps.gapText,
        actionPlan: point.cardProps.actionPlan,
        typeQuestion: ""
      });
    }

    this.setState({
      isOption: true,
      selected,
      point,
      lastKey: key
    }, () => this.openDrawer());
  }

  handleDeleteDialog() {
    this.setState({
      deleteDialog: !this.state.deleteDialog
    });
  }

  deleteRecursive(id) {
    const arrayDel = this.state.pointsToDel;

    id = parseInt(id);

    if (!(id in arrayDel)) {
      arrayDel.push(id);
    }

    if (Object.keys(this.state.points[id].outputs).length > 0) {
      for (var i = 0; i < Object.keys(this.state.points[id].outputs).length; i++) {
        const saidas = Object.keys(this.state.points[id].outputs).filter(output => output);
        this.deleteRecursive(parseInt(saidas[i]));
      }
    } else {
      return;
    }
  }

  async deleteAnswersInBase(point) {
    try {
      const answers = await getAnswers(point.cardProps.idQuestion);
      const delAnswers = answers.filter(a => a.text !== point.cardProps.title);
      deleteAnswer(point.cardProps.idQuestion, delAnswers);
      deleteGap(point.cardProps.gapId, this.props.getQuizReducer.id);
    } catch (error) {
      console.error("Error delete answers in delete!", error);
    }
  }

  async updateLeadToInDelete(point) {
    try {
      const answers = await getAnswers(point.cardProps.idQuestion);
      
      for (let answer of answers) {
        answer.leadTo = "";
      };

      await updateAnswers(point.cardProps.idQuestion, answers);
    } catch (error) {
      console.error("Error update leadTo in delete!", error);
    }
  }

  async deletePoint() {
    var selected = this.state.selected;
    let points = this.state.points;
    const pointsToDel = this.state.pointsToDel;

    this.deleteRecursive(selected);

    const reversePointsToDel = pointsToDel.reverse();

    for (let p of reversePointsToDel) {
      if (points[p]) {
        const outPutOfPoints = Object.keys(points).filter(o => points[o].outputs[p]);

        if (points[p].cardProps.typeCard === "question") {
          await deleteQuestion(points[p]);
        } else {
          await this.deleteAnswersInBase(points[p]);
        }

        for (let id of outPutOfPoints) {
          if (points[id].cardProps.typeCard === "primary") {
            points[id].cardProps.disableAddButton = false;
          }

          if (points[id].cardProps.typeCard === "answer") {
            points[id].cardProps.disableAddButton = false;
            points[id].cardProps.leadTo = "";
            await this.updateLeadToInDelete(points[id]);
          }

          delete points[id].outputs[p];
        };

        delete points[p];
      }
    };

    this.setState({
      selected: null,
      points,
      lastkey: null,
      pointsToDel: []
    }, () => {
      this.closeDrawer();
      this.handleDeleteDialog();
    });
  }

  handleSaveDialog() {
    this.setState({
      saveDialog: !this.state.saveDialog
    });
  }

  handleUpdateDialog() {
    this.setState({
      errorSaveDialog: !this.state.errorSaveDialog
    });
  }

  async saveQuizLocal(showMessage = true) {
    const { getQuizReducer } = this.props;
    const { points } = this.state;
    const database = myApp.firestore();

    const primary = Object.keys(points).find(p => points[p].cardProps.typeCard === "primary");

    if (typeof (primary) === "undefined") {
      return;
    }

    const { title } = points[primary].cardProps;

    let keys = Object.keys(points);

    for (let keyIndex of keys) {
      if (isDefined(points[keyIndex].outputs) && points[keyIndex].cardProps.typeCard === "answer") {
        let outputKeys = Object.keys(points[keyIndex].outputs);

        if (outputKeys.length > 0) {
          points[keyIndex].cardProps.leadTo = parseInt(outputKeys[0]);
          await updateLeadTo(points[keyIndex], points[outputKeys[0]].cardProps.id);
        }
      }
    }

    try {
      await database.collection("quiz").doc(getQuizReducer.id).update({
        name: title,
        points
      });
    } catch (error) {
      console.error("Error update quiz!", error);
    }

    if (showMessage) {
      this.setState({
        messageDialog: "Questionário atualizado com sucesso!"
      });
      this.handleSaveDialog();
    }

    this.setState({
      savedFlow: true
    });
  }

  saveOK() {
    this.props.changePage(3);
    this.handleSaveDialog();
  }

  renderCardQuiz() {
    const { points, selected } = this.state;
    const { classes } = this.props;

    return (
      <TransformWrapper
        onZoomChange={(zoom) => { console.log(zoom) }}
        initialScale={1}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
            <Grid className="tools" container direction="row" justify="flex-start" alignItems="center" style={{ width: "100%" }}>
              <Button className={classes.button} onClick={() => zoomIn()}>
                <ZoomInIcon style={{ color: theme.secondary_color }} />
              </Button>
              <Button className={classes.button} onClick={() => zoomOut()}>
                <ZoomOutIcon style={{ color: theme.secondary_color }} />
              </Button>
              <Button className={classes.button} onClick={() => resetTransform()}>
                <HighlightOffIcon style={{ color: theme.secondary_color }} />
              </Button>
            </Grid>
            <TransformComponent>
              <Flowspace
                getDiagramRef={ref => { this.diagramRef = ref }}
                selected={selected}
              >
                {
                  Object.keys(points).map((key) => {
                    let p = points[key];

                    return (
                      <Flowpoint
                        variant="outlined"
                        key={key}
                        startPosition={p.pos}
                        outputs={p.outputs}
                        onClick={e => { this.handleClick(key, e) }}
                        onDrag={position => this.onDrag(position, key)}
                        width={160}
                        height={80}
                        style={{ display: "inline-table" }}
                      >
                        <CardQuiz
                          cardProps={p.cardProps}
                          addCard={() => this.addCard(key, p)}
                          optionCard={() => this.optionCard(key, p)}
                        />
                      </Flowpoint>
                    )
                  })
                }
              </Flowspace>
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>
    )
  }

  handleDialog() {
    this.setState({
      dialog: !this.state.dialog,
    })
  }

  editQuizName(name) {
    var _points = this.state.points;

    _points[0].cardProps.title = name;

    this.setState({
      points: _points
    });

    this.props.getQuizReducer.name = name;

    this.saveQuizLocal(false);
  }

  async deleteQuiz() {
    const database = myApp.firestore();

    try {
      await database.collection("quiz").doc(this.props.getQuizReducer.id).update({
        active: false,
      });

    } catch (error) {
      console.error("Error delete quiz!", error);
    }
  }

  handleQuestion(event) {
    this.setState({
      question: event.target.value
    });

    if (event.target.value.trim().length > 0) {
      this.setState({
        errorQuestion: false
      });
    } else {
      this.setState({
        errorQuestion: true
      });
    }
  }

  handleTypeQuestion(event) {
    this.setState({
      typeQuestion: event.target.value
    });
  }

  handleDPIA() {
    this.setState({
      DPIA: !this.state.DPIA
    });
  }

  handleROPA() {
    this.setState({
      ROPA: !this.state.ROPA
    });
  }

  handleAnswer(event) {
    this.setState({
      answer: event.target.value
    });

    if (event.target.value.trim().length > 0) {
      this.setState({
        errorAnswer: false,
      });
    } else {
      this.setState({
        errorAnswer: true,
      });
    }
  }

  handleObservation(event) {
    this.setState({
      observation: event.target.value
    });
  }

  handleGap(event) {
    this.setState({
      gap: !this.state.gap
    });

    if (event.target.checked) {
      this.setState({
        gapError: false,
        actionPlanError: false,
      });
    } else {
      this.setState({
        gapText: "",
        actionPlan: "",
        gapError: false,
        actionPlanError: false,
      });
    }
  }

  handleGapText(event) {
    this.setState({
      gapText: event.target.value
    });

    if (event.target.value.trim().length > 0 && this.state.gap) {
      this.setState({
        gapError: false
      });
    }
  }

  handleActionPlan(event) {
    this.setState({
      actionPlan: event.target.value
    });

    if (event.target.value.trim().length > 0 && this.state.gap) {
      this.setState({
        actionPlanError: false,
      });
    }
  }

  handleErrorQuestion() {
    this.setState({
      errorQuestion: true,
    });
  }

  handleErrorAnswer() {
    this.setState({
      errorAnswer: true,
    });
  }

  render() {
    const { classes, getQuizReducer, loadingQuiz } = this.props;
    const { openDrawer, isOption, point, saveDialog, deleteDialog, messageDialog } = this.state;

    return (
      <div>
        {loadingQuiz ?
          <Grid container direction="column" alignItems="center" justify="center" style={{ height: "85vh" }}>
            <Grid item>
              <CircularProgress size={60} color="#193993" />
            </Grid>
          </Grid>
          :
          <>
            <Grid container direction="column" >
              <Grid item>
                <Paper className="paper">
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="h5" component="h5" style={{ fontWeight: "500" }}>
                        {getQuizReducer.name}
                      </Typography>
                    </Grid>
                    <div style={{ flexGrow: 1 }} />
                    <Grid item>
                      <IconButton onClick={() => { this.handleDialog() }}>
                        <EditIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} container direction="row" style={{ height: "80vh" }}>
                <Grid item xs={12} style={{ width: "100%", height: "100%" }}>
                  <Paper style={{ width: "100%", height: "100%" }} className="dashboardPaper">
                    <Grid container direction="row" justify="center" alignItems="center" style={{ width: "100%", height: "100%" }}>
                      {this.renderCardQuiz()}
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>

              <DrawerQuiz
                openDrawer={openDrawer}
                closeDrawer={this.closeDrawer}
                saveQuestion={() => this.handleAddPoint()}
                point={point}
                isOption={isOption}
                deletePoint={() => this.handleDeleteDialog()}
                quizId={getQuizReducer.id}

                handleQuestion={this.handleQuestion}
                handleDPIA={this.handleDPIA}
                handleROPA={this.handleROPA}
                handleAnswer={this.handleAnswer}
                handleObservation={this.handleObservation}
                handleGap={this.handleGap}
                handleGapText={this.handleGapText}
                handleActionPlan={this.handleActionPlan}
                handleErrorQuestion={this.handleErrorQuestion}
                handleErrorAnswer={this.handleErrorAnswer}
                handleTypeQuestion={this.handleTypeQuestion}
                updatePointQuestion={this.updatePointQuestion}
                updatePointAnswer={this.updatePointAnswer}

                question={this.state.question}
                DPIA={this.state.DPIA}
                ROPA={this.state.ROPA}
                answer={this.state.answer}
                observation={this.state.observation}
                gap={this.state.gap}
                gapText={this.state.gapText}
                actionPlan={this.state.actionPlan}
                typeQuestion={this.state.typeQuestion}
                errorAnswer={this.state.errorAnswer}
                errorQuestion={this.state.errorQuestion}
                gapError={this.state.gapError}
                actionPlanError={this.state.actionPlanError}
              />

              <Dialog
                open={saveDialog}
                TransitionComponent={Transition}
                maxWidth="xs"
                onClose={() => this.handleSaveDialog()}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogContent id="alert-dialog-slide-title">
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                  >
                    <Grid item xs={12} style={{ marginBottom: "20px" }}>
                      <Typography align="center">
                        {messageDialog}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        onClick={() => this.saveOK()}
                        className={classes.saveFlowButton}
                      >
                        OK
                      </Button>
                    </Grid>
                  </Grid>
                </DialogContent>
              </Dialog>

              <Dialog
                open={deleteDialog}
                TransitionComponent={Transition}
                onClose={() => this.handleDeleteDialog()}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogContent id="alert-dialog-slide-title">
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                  >
                    <Typography>
                      Este cartão e todos os outros criados após ele serão removidos.
                      <br />
                      Deseja continuar ?
                    </Typography>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="space-between"
                  >
                    <Grid item xs={5}>
                      <Button
                        fullWidth
                        onClick={() => this.handleDeleteDialog()}
                        className={classes.dialogButtonCancel}
                      >
                        Não
                      </Button>
                    </Grid>
                    <Grid item xs={5}>
                      <Button
                        fullWidth
                        onClick={() => this.deletePoint()}
                        className={classes.dialogButtonDelete}
                      >
                        Sim
                      </Button>
                    </Grid>
                  </Grid>
                </DialogActions>
              </Dialog>
            </Grid>

            <EditQuiz dialog={this.state.dialog} editQuizName={(name) => { this.editQuizName(name) }} quizName={getQuizReducer.name} deleteQuiz={() => { this.deleteQuiz() }} />
          </>
        }
      </div>
    );
  }
}

InsertQuiz.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  name: state.quizReducer.name,
  type: state.quizReducer.type,
  questions: state.quizReducer.questions,
  answers: state.quizReducer.answers,
  quiz: state.quizReducer.quiz,
  questionDatabaseId: state.quizReducer.questionDatabaseId,
  openQuiz: state.quizReducer.openQuiz,
  getQuizReducer: state.quizReducer.getQuizReducer,
  idQuestionsInserted: state.quizReducer.idQuestionsInserted,
  loadingQuiz: state.quizReducer.loadingQuiz,
});

const mapDispatchToProps = (dispatch) => ({
  setQuiz: (quiz) => dispatch(setQuiz(quiz)),
  changePage: (page) => dispatch(changePage(page)),
  getQuestionDatabaseId: (id) => dispatch(getQuestionDatabaseId(id)),
  setIdQuestionsInserted: (id) => dispatch(setIdQuestionsInserted(id)),
  setLoadingQuiz: (loadingQuiz) => dispatch(setLoadingQuiz(loadingQuiz)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(InsertQuiz));