import React, { useEffect, useRef } from 'react'
import useIsOverflow from '../hooks/useIsOverflow'
import { Container } from '@material-ui/core'

/**
 * Container que detecta overflow do conteúdo interno
 * @param {any} children
 * @param {Function|undefined} onScrollBottom Executado sempre que o scroll chega até o último elemento
 * @param {Function|undefined} populate Executado sempre que o elemento não possuir overflow de conteúdo
 * @param {string} maxHeight Limite para se obter o overflow
 * @returns {JSX.Element}
 * @constructor
 */
const ScrollWatcher = (
  {
    children,
    onScrollBottom = undefined,
    populate = undefined,
    maxHeight = '80vh',
  }) => {
  const ref = useRef()
  const isOverflow = useIsOverflow(ref)

  const handleOverflow = () => {
    if (ref.current) {
      const { scrollTop, scrollHeight, clientHeight } = ref.current
      // Verifica se o scroll chegou até o último elemento
      if ((scrollTop + clientHeight) === scrollHeight && onScrollBottom) {
        onScrollBottom()
      }
    }
  }

  useEffect(() => {
    // Caso não possua overflow executa a população de conteúdo interno até se obter o overflow
    if (!isOverflow) {
      populate()
    }
  }, [isOverflow])

  return (
    <Container
      ref={ref}
      onScroll={handleOverflow}
      style={{ maxHeight, overflow: 'auto' }}
    >
      {children}
    </Container>
  )
}

export default ScrollWatcher
    