import {
  ADD_QUIZ,
  SAVE_QUIZ,
  ADD_QUESTION,
  ADD_ANSWER,
  QUIZ_DATABASE_ID,
  OPEN_QUIZ,
  SET_QUIZ,
  IDS_QUESTIONS_INSERTED,
  SET_LOADING_QUIZ,
  QUIZ_ID
} from "./types";

const INITIAL_STATE = {
  name: "",
  type: "",
  question: "",
  questions: [],
  answers: [{}],
  quiz: [],
  idQuestionsInserted: [],
  getQuizReducer: {},
  questionDatabaseId: "",
  openQuiz: false,
  loadingQuiz: true,
  quiz_id: ''
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_QUIZ:
      return {
        ...state,
        name: action.data.name,
        type: action.data.type
      }
    case SAVE_QUIZ:
      return {
        ...state,
        quiz: [
          ...state.quiz,
          action.data,
        ]
      }
    case ADD_QUESTION:
      return {
        ...state,
        questions: [
          ...state.questions,
          action.data,
        ],
      }
    case ADD_ANSWER:
      return {
        ...state,
        answers: [
          ...state.answers,
          action.data
        ]
      }
    case QUIZ_DATABASE_ID:
      return {
        ...state,
        questionDatabaseId: action.data
      }
    case OPEN_QUIZ:
      return {
        ...state,
        openQuiz: action.data,
      }
    case SET_QUIZ:
      return {
        ...state,
        getQuizReducer: action.data,
      }
    case IDS_QUESTIONS_INSERTED:
      return {
        ...state,
        idQuestionsInserted: [
          ...state.idQuestionsInserted,
          action.data
        ]
      }
    case SET_LOADING_QUIZ:
      return {
        ...state,
        loadingQuiz: action.data
      }
    case QUIZ_ID:
      return {
        ...state,
        quiz_id: action.data
      }
    default:
      return state;
  }
}