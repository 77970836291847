import React, { Component } from 'react';
import myApp from '../../../../core/infrastructure/firebaseConfig';
import "firebase/compat/firestore";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';

import { Close } from '@material-ui/icons';

import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Dialog, DialogContent, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";

import { connect } from 'react-redux';
import { addActivities } from '../redux/actions';

import { Skeleton } from '@material-ui/lab';

import theme from '../../../../core/theme.json'

class PlanObject {
  planName = "";
  isChecked = false;
  fkPlanType = "";
  planActivities = [];
}

class ActivyObject {
  id = "";
  name = "";
  owner = "";
  date = null;
  description = "";
  isChecked = false;
  selected = false;
  fkPlanType = "";
  fkAreas = "";
  fkInitialActivities = "";
  done = false;
}

class NewPlansActivitiesList extends Component {
  constructor(props) {
    super(props);

    this.db = myApp.firestore();

    this.state = {
      plans: [],
      topicDialog: false,
      topicEditDialog: false,
      topicIndex: -1,
      planTypeId: "",
      topicName: "",
      topicDescription: "",
      topicDialogError: false,
      planActivityIndex: -1,
      loading: false,
      addTopicDialogButtonDisabled: true,
      saveTopicDialogButtonDisabled: false,
      oldTopicName: ""
    }
  }

  componentDidMount() {
    this.setState({
      loading: true
    });

    this.loadPlans();
  }

  async loadPlans() {
    let database = myApp.firestore();
    let _companyId = localStorage.getItem('currentCompany');

    let _plans = [];
    let _initialActivitiesSelected = [];

    try {
      var planObject = new PlanObject();

      planObject.planName = await this.getPlanName();
      planObject.isChecked = false;
      planObject.fkPlanType = this.props.plan;

      let dataActivy = await database.collection('activities').where('fk_plan_type', '==', this.props.plan).where('fk_companies', '==', _companyId).get();
      let activy = dataActivy.docs;

      for (var i = 0; i < activy.length; i++) {
        try {
          var activyObject = new ActivyObject();
          console.log("plans", activy[i].data())

          activyObject.id = activy[i].id;
          activyObject.name = activy[i].data().name;
          activyObject.description = activy[i].data().description;
          activyObject.owner = activy[i].data().owner
          
          if (activy[i].data().date !== null) {
            activyObject.date = new Date(activy[i].data().date.seconds ? activy[i].data().date.seconds * 1000 : activy[i].data().date );

          }

          activyObject.fkInitialActivities = activy[i].data().fk_initial_activities;
          activyObject.fkPlanType = this.props.plan;
          activyObject.isChecked = true;
          activyObject.selected = true;
          activyObject.done = activy[i].data().done;

          if (activy[i].data().fk_initial_activities !== "") {
            _initialActivitiesSelected.push(activy[i].data().fk_initial_activities);
          }

          planObject.planActivities.push(activyObject);
        }
        catch (error) {
          console.error(error);
        }
      }

      let dataInitialActivy = await database.collection('initial_activities').where('fk_plan_type', '==', this.props.plan).get();
      let initialActivy = dataInitialActivy.docs;

      for (var i = 0; i < initialActivy.length; i++) {
        try {
          if (_initialActivitiesSelected.includes(initialActivy[i].id) === false) {
            var activyObject = new ActivyObject();

            activyObject.fkPlanType = this.props.plan;
            activyObject.name = initialActivy[i].data().name;
            activyObject.description = initialActivy[i].data().description;
            activyObject.fkInitialActivities = initialActivy[i].id;

            planObject.planActivities.push(activyObject);
          }
        }
        catch (error) {
          console.error(error);
        }
      }

      _plans.push(planObject);
    }
    catch (error) {
      console.error(error);
    }
    this.setState({
      plans: _plans,
      loading: false
    }, () => { this.handleReducer() });


    
  }

  async getPlanName() {
    let database = myApp.firestore();

    try {
      let data = await database.collection('plan_type').doc(this.props.plan).get();
      let plan = data.data();

      return plan.name;
    }
    catch (error) {
      console.error(error);
    }
  }

  handleCheckAll(event, index) {
    let _plans = this.state.plans;

    _plans[index].isChecked = event.target.checked;
    _plans[index].planActivities.forEach(activy => {
      if (!activy.selected) {
        activy.isChecked = event.target.checked
      }
    });

    this.setState({
      plans: _plans,
    }, () => { this.handleReducer() })
  }

  handleCheckbox(event, planIndex, activyIndex) {
    let _plans = this.state.plans;

    _plans[planIndex].isChecked = false;
    _plans[planIndex].planActivities[activyIndex].isChecked = event.target.checked;

    this.setState({
      plans: _plans,
    }, () => { this.handleReducer() })
  }

  handleOwner(event, planIndex, activyIndex) {
    let _plans = this.state.plans;

    _plans[planIndex].planActivities[activyIndex].owner = event.target.value;

    this.setState({
      plans: _plans,
    }, () => { this.handleReducer() })
  }

  handleDate(value, planIndex, activyIndex) {
    let _plans = this.state.plans;

    _plans[planIndex].planActivities[activyIndex].date = value;

    this.setState({
      plans: _plans,
    }, () => { this.handleReducer() })
  }

  handleTopicDialog(index, planTypeId, name, description) {
    this.setState({
      topicDialog: !this.state.topicDialog,
      topicIndex: index,
      topicName: name,
      topicDescription: description,
      planTypeId: planTypeId,
      addTopicDialogButtonDisabled: true,
      topicDialogError: false
    })
  }

  handleEditTopicDialog(index, planTypeId, planActivityIndex, name, description) {
    if (index > -1 && (name === "" || description === "")) {
      this.setState({
        saveTopicDialogButtonDisabled: true
      });
    }
    else {
      this.setState({
        saveTopicDialogButtonDisabled: false
      });
    }

    if (index > -1 && name !== "") {
      this.setState({
        oldTopicName: name
      });
    }

    this.setState({
      topicEditDialog: !this.state.topicEditDialog,
      topicIndex: index,
      topicName: name,
      topicDescription: description,
      planTypeId: planTypeId,
      planActivityIndex: planActivityIndex,
      topicDialogError: false
    })
  }

  handleTopicName(event) {
    this.setState({
      topicName: event.target.value
    });

    if (event.target.value !== "" && this.state.topicDescription !== "") {
      if (this.state.topicDialog) {
        this.setState({
          addTopicDialogButtonDisabled: false
        });
      }
      else {
        this.setState({
          saveTopicDialogButtonDisabled: false
        });
      }
    }
    else {
      if (this.state.topicDialog) {
        this.setState({
          addTopicDialogButtonDisabled: true
        });
      }
      else {
        this.setState({
          saveTopicDialogButtonDisabled: true
        });
      }
    }
  }

  handleTopicDescription(event) {
    this.setState({
      topicDescription: event.target.value
    });

    if (event.target.value !== "" && this.state.topicName !== "") {
      if (this.state.topicDialog) {
        this.setState({
          addTopicDialogButtonDisabled: false
        });
      }
      else {
        this.setState({
          saveTopicDialogButtonDisabled: false
        });
      }
    }
    else {
      if (this.state.topicDialog) {
        this.setState({
          addTopicDialogButtonDisabled: true
        });
      }
      else {
        this.setState({
          saveTopicDialogButtonDisabled: true
        });
      }
    }
  }

  addTopic() {
    var _plans = this.state.plans;

    var activyObject = new ActivyObject();

    activyObject.name = this.state.topicName;
    activyObject.description = this.state.topicDescription;
    activyObject.fkPlanType = this.props.plan;
    activyObject.fkAreas = "";

    if (!_plans[this.state.topicIndex].planActivities.some(pa => pa.name === this.state.topicName)) {
      _plans[this.state.topicIndex].planActivities.push(activyObject);

      this.setState({
        plans: _plans,
        topicDialog: !this.state.topicDialog,
        topicDialogError: false,
        topicDescription: "",
        topicName: "",
        topicIndex: -1,
        planTypeId: "",
        addTopicDialogButtonDisabled: true
      }, () => { this.handleReducer() });
    }
    else {
      this.setState({
        topicDialogError: true
      })
    }
  }

  updateTopic() {
    var _plans = this.state.plans;

    if (this.state.oldTopicName === this.state.topicName) {
      _plans[this.state.topicIndex].planActivities[this.state.planActivityIndex].name = this.state.topicName;
      _plans[this.state.topicIndex].planActivities[this.state.planActivityIndex].description = this.state.topicDescription;

      this.setState({
        plans: _plans,
        topicDialogError: false,
        oldTopicName: ""
      });

      this.handleEditTopicDialog(-1, "", -1, "", "");
    }
    else if (!_plans[this.state.topicIndex].planActivities.some(pa => pa.name === this.state.topicName)) {
      _plans[this.state.topicIndex].planActivities[this.state.planActivityIndex].name = this.state.topicName;
      _plans[this.state.topicIndex].planActivities[this.state.planActivityIndex].description = this.state.topicDescription;

      this.setState({
        plans: _plans,
        topicDialogError: false,
        oldTopicName: ""
      });

      this.handleEditTopicDialog(-1, "", -1, "", "");
    }
    else {
      this.setState({
        topicDialogError: true
      });
    }
  }

  handleReducer() {
    this.props.addActivities(this.state.plans);
  }
 
  render() {
    return (
      <div>
        {this.state.loading ?
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item style={{ width: "100%" }}>
              <Skeleton variant="rect" width={"100%"} height={220} />
            </Grid>
          </Grid>
          :
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            {this.state.plans.map((plan, planIndex) => 
            {
              // console.log("teste", plan)
              return(
                <Grid item style={{ width: "100%" }}>
                  <Accordion
                    defaultExpanded={planIndex === 0}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ fontSize: "48px" }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{ background: "#D9D9D9", borderRadius: "4px 4px 0px 0px" }}
                    >
                      <Typography >{plan.planName}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableContainer>
                        <Table>
                          <colgroup>
                            <col style={{ width: "5%" }} />
                            <col style={{ width: "30%" }} />
                            <col style={{ width: "20%" }} />
                            <col style={{ width: "20%" }} />
                            <col style={{ width: "5%" }} />
                            <col style={{ width: "5%" }} />
                          </colgroup>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Checkbox
                                  checked={plan.isChecked}
                                  onChange={(event) => this.handleCheckAll(event, planIndex)}
                                />
                              </TableCell>
                              <TableCell align="left">
                                Atividade
                                <IconButton onClick={() => { this.handleTopicDialog(planIndex, plan.fkPlanType, "", "") }}>
                                  <AddCircleIcon style={{ color: theme.primary_color }} />
                                </IconButton>
                              </TableCell>
                              <TableCell align="center">
                                Responsável
                              </TableCell>
                              <TableCell align="center">
                                Previsão de Entrega
                              </TableCell>
                              <TableCell />
                              <TableCell />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {plan.planActivities.map((activy, activyIndex) => (
                              <TableRow>
                                <TableCell>
                                  <Checkbox
                                    checked={activy.isChecked}
                                    disabled={activy.selected}
                                    onChange={(event) => this.handleCheckbox(event, planIndex, activyIndex)}
                                  />
                                </TableCell>
                                <TableCell align="left">
                                  {activy.name}
                                </TableCell>
                                <TableCell align="center">
                                  <TextField
                                    label="Responsável"
                                    value={activy.owner}
                                    disabled={activy.selected && activy.done}
                                    onChange={(event) => { this.handleOwner(event, planIndex, activyIndex) }}
                                    style={{ marginBottom: "8px" }}
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                      disableToolbar
                                      variant="inline"
                                      format="dd/MM/yyyy"
                                      margin="normal"
                                      invalidDateMessage="Data em formato inválido"
                                      minDateMessage={activy.date !== "" ? "" : "Data não deve ser retroativa"}
                                      minDate={Date.now()}
                                      disabled={activy.selected && activy.done}
                                      onChange={(value) => { this.handleDate(value, planIndex, activyIndex) }}
                                      value={activy.date}
                                      autoOk={true}
                                    />
                                  </MuiPickersUtilsProvider>
                                </TableCell>
                                <TableCell align="center">
                                  <Tooltip placement="left" title={activy.description}>
                                    <IconButton>
                                      <InfoIcon />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                                <TableCell align="center">
                                  <Tooltip placement="left" title="Editar atividade">
                                    <IconButton
                                      disabled={activy.selected && activy.done} 
                                      onClick={() => { this.handleEditTopicDialog(planIndex, plan.fkPlanType, activyIndex, activy.name, activy.description) }}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              )
            }
            )}
          </Grid>
        }

        {/* Nova atividade */}
        <Dialog
          open={this.state.topicDialog}
          fullWidth={true}
          onClose={() => { this.handleTopicDialog(-1, "", "", "") }}
        >
          <DialogContent>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="flex-end"
              spacing={4}
            >
              <Grid item container direction="row">
                <Grid item style={{ width: "100%" }} xs={10}>
                  <Typography variant="h5" component="h5" >
                    Nova Atividade
                  </Typography>
                </Grid>
                <Grid item container xs={2} justify="flex-end">
                  <IconButton onClick={() => this.handleTopicDialog(-1, "", "", "")}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item style={{ width: "100%" }}>
                <TextField
                  label="Nome do Tópico"
                  variant="outlined"
                  style={{ width: "100%" }}
                  color={theme.secondary_color}
                  value={this.state.topicName}
                  onChange={(event) => { this.handleTopicName(event) }}
                />
              </Grid>
              <Grid item style={{ width: "100%" }}>
                <TextField
                  label="Descrição do Tópico"
                  variant="outlined"
                  style={{ width: "100%" }}
                  color={theme.secondary_color}
                  multiline
                  rows={4}
                  value={this.state.topicDescription}
                  onChange={(event) => { this.handleTopicDescription(event) }}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  style={{ background: this.state.addTopicDialogButtonDisabled ? "#91ff79" : theme.primary_color, color: theme.secondary_color, fontSize: theme.font_button, fontWeight: 300 }}
                  onClick={() => { this.addTopic() }}
                  disabled={this.state.addTopicDialogButtonDisabled}
                >
                  Adicionar
                </Button>
              </Grid>
              <Grid item >
                <Typography style={{ color: "red", display: this.state.topicDialogError ? "inherit" : "none" }}
                >
                  Já existe uma atividade com este nome
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        {/* Editar atividade */}
        <Dialog
          open={this.state.topicEditDialog}
          fullWidth={true}
          onClose={() => { this.handleEditTopicDialog(-1, "", -1, "", "") }}
        >
          <DialogContent>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="flex-end"
              spacing={4}
            >
              <Grid item container direction="row">
                <Grid item style={{ width: "100%" }} xs={10}>
                  <Typography variant="h5" component="h5" >
                    Editar Atividade
                  </Typography>
                </Grid>
                <Grid item container xs={2} justify="flex-end">
                  <IconButton onClick={() => this.handleEditTopicDialog(-1, "", -1, "", "")}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item style={{ width: "100%" }}>
                <TextField
                  label="Nome do Tópico"
                  variant="outlined"
                  style={{ width: "100%" }}
                  color={theme.secondary_color}
                  value={this.state.topicName}
                  onChange={(event) => { this.handleTopicName(event) }}
                />
              </Grid>
              <Grid item style={{ width: "100%" }}>
                <TextField
                  label="Descrição do Tópico"
                  variant="outlined"
                  style={{ width: "100%" }}
                  color={theme.secondary_color}
                  multiline
                  rows={4}
                  value={this.state.topicDescription}
                  onChange={(event) => { this.handleTopicDescription(event) }}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  style={{ background: this.state.saveTopicDialogButtonDisabled ? "#91ff79" : theme.primary_color, color: theme.secondary_color, fontSize: theme.font_button, fontWeight: 300 }}
                  onClick={() => { this.updateTopic() }}
                  disabled={this.state.saveTopicDialogButtonDisabled}
                >
                  Salvar
                </Button>
              </Grid>
              <Grid item >
                <Typography style={{ color: "red", display: this.state.topicDialogError ? "inherit" : "none" }}
                >
                  Já existe uma atividade com este nome
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    plan: state.homeReducer.plan,
  };
}

export default connect(mapStateToProps, { addActivities })(NewPlansActivitiesList);