export const ROPA_MERGES = [
  { s: { c: 0, r: 0 }, e: { c: 4, r: 0 } },
  { s: { c: 5, r: 0 }, e: { c: 11, r: 0 } },
  { s: { c: 12, r: 0 }, e: { c: 22, r: 0 } },
  { s: { c: 23, r: 0 }, e: { c: 28, r: 0 } },
  { s: { c: 29, r: 0 }, e: { c: 34, r: 0 } },
  { s: { c: 35, r: 0 }, e: { c: 43, r: 0 } },
  { s: { c: 44, r: 0 }, e: { c: 46, r: 0 } },
  { s: { c: 0, r: 1 }, e: { c: 2, r: 1 } },
  { s: { c: 3, r: 1 }, e: { c: 4, r: 1 } }
];

export const ROPA_STYLES = {
  firstPrimaryHeader: {
    font: {
      name: "Calibri",
      sz: 16,
      bold: true,
    },
    fill: {
      patternType: "solid",
      fgColor: {
        rgb: "189AD6",
      },
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
      wrapText: true,
    },
    border: {
      top: {
        style: "thin",
      },
      bottom: {
        style: "thin",
      },
      left: {
        style: "thin",
      },
      right: {
        style: "thin",
      },
    },
  },
  secondPrimaryHeader: {
    font: {
      name: "Calibri",
      sz: 16,
      bold: true,
    },
    fill: {
      patternType: "solid",
      fgColor: {
        rgb: "6A6666",
      },
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
      wrapText: true,
    },
    border: {
      top: {
        style: "thin",
      },
      bottom: {
        style: "thin",
      },
      left: {
        style: "thin",
      },
      right: {
        style: "thin",
      },
    },
  },
  thirdPrimaryHeader: {
    font: {
      name: "Calibri",
      sz: 16,
      bold: true,
    },
    fill: {
      patternType: "solid",
      fgColor: {
        rgb: "ED7D31",
      },
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
      wrapText: true,
    },
    border: {
      top: {
        style: "thin",
      },
      bottom: {
        style: "thin",
      },
      left: {
        style: "thin",
      },
      right: {
        style: "thin",
      },
    },
  },
  fourthPrimaryHeader: {
    font: {
      name: "Calibri",
      sz: 16,
      bold: true,
    },
    fill: {
      patternType: "solid",
      fgColor: {
        rgb: "73B04A",
      },
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
      wrapText: true,
    },
    border: {
      top: {
        style: "thin",
      },
      bottom: {
        style: "thin",
      },
      left: {
        style: "thin",
      },
      right: {
        style: "thin",
      },
    },
  },
  fifthPrimaryHeader: {
    font: {
      name: "Calibri",
      sz: 16,
      bold: true,
    },
    fill: {
      patternType: "solid",
      fgColor: {
        rgb: "9752CA",
      },
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
      wrapText: true,
    },
    border: {
      top: {
        style: "thin",
      },
      bottom: {
        style: "thin",
      },
      left: {
        style: "thin",
      },
      right: {
        style: "thin",
      },
    },
  },
  sixthPrimaryHeader: {
    font: {
      name: "Calibri",
      sz: 16,
      bold: true,
    },
    fill: {
      patternType: "solid",
      fgColor: {
        rgb: "5B718F",
      },
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
      wrapText: true,
    },
    border: {
      top: {
        style: "thin",
      },
      bottom: {
        style: "thin",
      },
      left: {
        style: "thin",
      },
      right: {
        style: "thin",
      },
    },
  },
  seventhPrimaryHeader: {
    font: {
      name: "Calibri",
      sz: 16,
      bold: true,
    },
    fill: {
      patternType: "solid",
      fgColor: {
        rgb: "FFFF00",
      },
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
      wrapText: true,
    },
    border: {
      top: {
        style: "thin",
      },
      bottom: {
        style: "thin",
      },
      left: {
        style: "thin",
      },
      right: {
        style: "thin",
      },
    },
  },
  firstSecondaryHeader: {
    font: {
      name: "Calibri",
      sz: 12,
      bold: false,
    },
    fill: {
      patternType: "solid",
      fgColor: {
        rgb: "6BC6EF",
      },
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
      wrapText: true,
    },
    border: {
      top: {
        style: "thin",
      },
      bottom: {
        style: "thin",
      },
      left: {
        style: "thin",
      },
      right: {
        style: "thin",
      },
    },
  },
  secondSecondaryHeader: {
    font: {
      name: "Calibri",
      sz: 12,
      bold: false,
    },
    fill: {
      patternType: "solid",
      fgColor: {
        rgb: "AEAAAA",
      },
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
      wrapText: true,
    },
    border: {
      top: {
        style: "thin",
      },
      bottom: {
        style: "thin",
      },
      left: {
        style: "thin",
      },
      right: {
        style: "thin",
      },
    },
  },
  thirdSecondaryHeader: {
    font: {
      name: "Calibri",
      sz: 12,
      bold: false,
    },
    fill: {
      patternType: "solid",
      fgColor: {
        rgb: "F4B084",
      },
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
      wrapText: true,
    },
    border: {
      top: {
        style: "thin",
      },
      bottom: {
        style: "thin",
      },
      left: {
        style: "thin",
      },
      right: {
        style: "thin",
      },
    },
  },
  fourthSecondaryHeader: {
    font: {
      name: "Calibri",
      sz: 12,
      bold: false,
    },
    fill: {
      patternType: "solid",
      fgColor: {
        rgb: "A9D08E",
      },
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
      wrapText: true,
    },
    border: {
      top: {
        style: "thin",
      },
      bottom: {
        style: "thin",
      },
      left: {
        style: "thin",
      },
      right: {
        style: "thin",
      },
    },
  },
  fifthSecondaryHeader: {
    font: {
      name: "Calibri",
      sz: 12,
      bold: false,
    },
    fill: {
      patternType: "solid",
      fgColor: {
        rgb: "BD92DE",
      },
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
      wrapText: true,
    },
    border: {
      top: {
        style: "thin",
      },
      bottom: {
        style: "thin",
      },
      left: {
        style: "thin",
      },
      right: {
        style: "thin",
      },
    },
  },
  sixthSecondaryHeader: {
    font: {
      name: "Calibri",
      sz: 12,
      bold: false,
    },
    fill: {
      patternType: "solid",
      fgColor: {
        rgb: "8497B0",
      },
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
      wrapText: true,
    },
    border: {
      top: {
        style: "thin",
      },
      bottom: {
        style: "thin",
      },
      left: {
        style: "thin",
      },
      right: {
        style: "thin",
      },
    },
  },
  seventhSecondaryHeader: {
    font: {
      name: "Calibri",
      sz: 12,
      bold: false,
    },
    fill: {
      patternType: "solid",
      fgColor: {
        rgb: "FFFF00",
      },
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
      wrapText: true,
    },
    border: {
      top: {
        style: "thin",
      },
      bottom: {
        style: "thin",
      },
      left: {
        style: "thin",
      },
      right: {
        style: "thin",
      },
    },
  },
  tertiaryHeader: {
    font: {
      name: "Calibri",
      sz: 12,
      bold: true,
      color: {
        rgb: "FFFFFF",
      },
    },
    fill: {
      patternType: "solid",
      fgColor: {
        rgb: "808080",
      },
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
      wrapText: true,
    },
    border: {
      top: {
        style: "thin",
      },
      bottom: {
        style: "thin",
      },
      left: {
        style: "thin",
      },
      right: {
        style: "thin",
      },
    },
  },
  data: {
    font: {
      name: "Calibri",
      sz: 11,
      bold: false,
    },
    alignment: {
      horizontal: "center",
      wrapText: true,
    },
    border: {
      top: {
        style: "thin",
      },
      bottom: {
        style: "thin",
      },
      left: {
        style: "thin",
      },
      right: {
        style: "thin",
      },
    },
  },
}