import React, { Component } from 'react';

import { Paper, Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';


class PaperTitle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",

      dialog: false,
    }
  }

  render() {
    return (
      <div>
        <Paper className='paper'>
          <Grid 
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={4}
          >
            <Grid item>
              <Typography variant="h6" component="h6" style={{fontWeight: "500", margin: "15px"}}>
                {this.props.title}
              </Typography>
            </Grid>
            <div style={{flexGrow: 1}}/>
          </Grid>
        </Paper>
      </div>
    );
  }
}



export default connect()(PaperTitle);