import {
  SET_PROCESS_AREA,
  SET_PROCESS_AREA_NAME,
  SET_PROCESS_ID,
  SET_LOADING_PROCESS
} from './types';

export const setProcessArea = data => ({
  type: SET_PROCESS_AREA,
  data
});

export const setProcessAreaName = data => ({
  type: SET_PROCESS_AREA_NAME,
  data
});

export const setProcessId = data => ({
  type: SET_PROCESS_ID,
  data
});

export const setLoadingProcess = data => ({
  type: SET_LOADING_PROCESS,
  data
});