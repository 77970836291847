import myApp from '../../../../core/infrastructure/firebaseConfig';
import "firebase/compat/firestore";

const database = myApp.firestore();

export const getAnswers = async (idQuestion) => {
  if (idQuestion) {
    try {
      const answers = await database.collection('questions').doc(idQuestion).get();
      return answers.data().answers;
  
    } catch(error) {
      console.error('Error getAnswer!', error);
    }
  }
};

export const getQuestions = async (idQuestion) => {
  if (idQuestion) {
    try {
      const questions = await database.collection('questions').doc(idQuestion).get();
  
      return questions.data();
    } catch(error) {
      console.error('Error getQuestions!', error);
    }
  }
}