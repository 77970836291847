import Cookies from "js-cookie";
import myApp from "../../core/infrastructure/firebaseConfig";
// import { isDefined } from "../../shared/commands/verifyDefinition";
import "firebase/compat/firestore";

export async function getUserInformation() {
  let database = myApp.firestore();
  let _userUid = localStorage.getItem("currentUser");
  try {
    let data = await database
      .collection("users")
      .where("uid", "==", _userUid)
      .get();

    let userInformation = data.docs[0].data();

    userInformation["permissions"] = {
      insert: false,
      update: false,
      delete: false,
    };

    if (userInformation.isAdmin) {
      userInformation.permissions.insert = true;
      userInformation.permissions.update = true;
      userInformation.permissions.delete = true;
    } else {
      var permissionDoc = await database
        .collection("profiles_company_group")
        .doc(userInformation.fk_profile)
        .get();
      userInformation.permissions.insert = true;
      userInformation.permissions.update = true;
      userInformation.permissions.delete = true;
    }

    // userInformation["isAdmin"] = false;

    // var adminSnapshot = await database.collection("admin").where("fk_user_email", "==", userInformation.email).get();

    // if (!adminSnapshot.empty) {
    //   userInformation["permissions"] = { insert: true, update: true, delete: true };
    //   userInformation.isAdmin = true;
    // }
    // else if (isDefined(userInformation.fk_profile_permissions) && userInformation.fk_profile_permissions.length > 0) {
    //   var profileId = userInformation.fk_profile_permissions.filter(pp => pp.fk_company === localStorage.getItem("currentCompany"));

    //   if (isDefined(profileId)) {
    //     var permissionSnapshot = await database.collection("profiles_company_group").where("id", "==", profileId).get();

    //     if (!permissionSnapshot.empty)
    //       userInformation["permissions"] = permissionSnapshot.docs[0].data().permissions;
    //   }
    // }

    return userInformation;
  } catch (error) {
    console.error(error);
  }
}

export async function getUserInformationById(userUid) {
  let database = myApp.firestore();
  try {
    let data = await database
      .collection("users")
      .where("uid", "==", userUid)
      .get();
    let userInformation = { ...data.docs[0].data(), id: data.docs[0].id };
    let userTasting = data.docs[0].data().userTasting ? 'sim' : 'não';
    let userAdmin = data.docs[0].data().isAdmin ? 'sim' : 'não';
    localStorage.setItem('userTasting', userTasting);
    localStorage.setItem('userAdmin', userAdmin);


   
    userInformation["permissions"] = {
      insert: false,
      update: false,
      delete: false,
    };
    if (userInformation.isAdmin) {
      userInformation.permissions.insert = true;
      userInformation.permissions.update = true;
      userInformation.permissions.delete = true;
    } else {
      var permissionDoc = await database
        .collection("profiles_company_group")
        .doc(userInformation.fk_profile)
        .get();
      userInformation.permissions = permissionDoc.data().permissions;
    }
    return userInformation ;
  } catch (error) {
    console.error(error);
  }
}
