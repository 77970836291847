import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  TextField,
  Card,
  ButtonBase,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import theme from "../../../../core/theme.json";
import Title from "../../../../shared/components/title";
import myApp from '../../../../core/infrastructure/firebaseConfig';
import ArrowBackIosTwoToneIcon from "@material-ui/icons/ArrowBackIosTwoTone";
import { useDispatch } from "react-redux";
import { changePage } from '../redux/actions';
import DeleteIcon from '@material-ui/icons/Delete';
import ReactPlayer from "react-player";
import { connect } from "react-redux";
import TvOffIcon from '@material-ui/icons/TvOff';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';

const AnswerPagrModuleVideo = (props) => {
  const [nomeQuestionario, setNomeQuestionario] = useState('Carregando...');
  const [nomeDoVideo, setNomeDoVideo] = useState('Carregando...');
  const [questioes, setQuestioes] = useState([]);
  let valida = [];

  useEffect(() => {
    montingPage();
  }, []);

  const montingPage = async () => {
    await getModuleVideos();
  }

  const getModuleVideos = async () => {
    let aux = [];
    try {
      let getDadosQuestionario = await myApp.firestore().collection('quizModuleVideo').doc(props.match.params.quizModuleVideoId).get();
      for (let item of getDadosQuestionario.data().questions) {
        aux.push({
          name: item.name,
          alternatives: item.alternatives
        });
      }
      let getDadosModulo = await myApp.firestore().collection('moduleName').doc(getDadosQuestionario.data().idModule).get();
      setNomeQuestionario(getDadosQuestionario.data().name);
      setNomeDoVideo(getDadosModulo.data().videos[getDadosQuestionario.data().indexVideo].name);

    } catch (e) { console.error(e) }
    setQuestioes(aux);
    console.log("Lista de perguntas", aux);
  }

  const handleValidaData = () => {
    let count = 0;
    for(let item of valida){
      if(item.correct === true){
        count++
      }
    }
    alert('Acertou: '+count);
  }

  return (
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
      style={{
        fontFamily: 'Roboto'
      }}
    >
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={12}
        >
          <Typography
            component='span'
            variant='span'
            style={{
              fontSize: 22,
              fontWeight: 'bold'
            }}
          >
            Esté é o questionário:
            <span style={{ marginLeft: 10 }}>
              {nomeQuestionario}
            </span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
        >
          <Typography
            component='span'
            variant='span'
            style={{
              fontSize: 22,
              fontWeight: 'bold'
            }}
          >
            Referente ao vídeo:
            <span style={{ marginLeft: 10 }}>
              {nomeDoVideo}
            </span>
          </Typography>
        </Grid>
      </Grid>
      <Paper
        style={{
          width: '80%',
          marginTop: 20
        }}
      >
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={12}
          >
            <h1>Questionário</h1>
          </Grid>
          <Grid
            // key={index}
            item
            xs={12}
            sm={12}
            container
            justifyContent='center'
            alignItems='center'
          >
            {
              questioes.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  style={{
                    paddingLeft: 10,
                    marginBottom: 20
                  }}
                >
                  <Typography variant="h4" component="h4" style={{ fontFamily: "Roboto", paddingTop: "2%" }} >
                    {item.name}
                  </Typography>
                  <FormControl variant="outlined" style={{ width: "95%" }}>
                    <Select
                      onChange={(event, index) => valida.push({ correct: event.target.value })}
                    >
                      {
                        item.alternatives.map((alternatives, indexAlternatives) => (
                          <MenuItem key={indexAlternatives} value={alternatives.correct === true ? true : 'false' + indexAlternatives}>{alternatives.name}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
              ))
            }
          </Grid>
        </Grid>
        <Grid
          item
          container
          justifyContent='center'
          alignItems='center'
          xs={12}
          sm={12}
          style={{
            marginTop: 20,
            marginBottom: 20
          }}
        >
          <Grid
            item
            xs={4}
            sm={4}
          >
            <Button
              fullWidth
              variant="contained"
              //disabled={this.state.answerButtonDisabled}
              style={{
                background: theme.primary_color,
                color: theme.secondary_color,
                fontWeight: 'bold'
              }}
              onClick={handleValidaData}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Paper >

      <Dialog
        fullWidth
        open={false}
      // onClose={handleCloseDialog('editVideo')}
      >
        <DialogTitle
        >
          Editando vídeo
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
          >
            <Grid
              item
              xs={6}
              sm={6}
            >
              <TextField
                type="text"
                fullWidth
                label="Nome"
                variant="outlined"
              // value={editVideoSelected.name}
              // onChange={handleEditVideo('name')}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
            >
              <TextField
                type="text"
                fullWidth
                label="URL"
                variant="outlined"
              // value={editVideoSelected.url}
              // onChange={handleEditVideo('url')}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              backgroundColor: '#f00',
              color: '#fff'
            }}
            // onClick={handleCloseDialog('editVideo')}
            autoFocus
          >
            Cancelar
          </Button>
          <Button
            style={{
              backgroundColor: theme.primary_color,
              color: theme.secondary_color
            }}
            onClick={() => {
              // console.log(editVideoSelected);
              // let nlv = videos;
              // nlv[editVideoSelected.index].name = editVideoSelected.name;
              // nlv[editVideoSelected.index].url = editVideoSelected.url;
              // myApp.firestore().collection('moduleName').doc(props.idModuleName).update({
              //   videos: nlv
              // }).then(() => {
              //   getModuleVideos();
              //   setDialogs({
              //     ...dialogs,
              //     editVideo: false
              //   });
              //   setEditVideoSelected({
              //     name: '',
              //     url: '',
              //     index: 0
              //   });
              // });
            }}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Grid >
  );
};

export default AnswerPagrModuleVideo;