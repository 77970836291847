import React, { Component } from 'react'
import { withRouter } from 'react-router'
import 'firebase/compat/functions'
import myApp from '../../../core/infrastructure/firebaseConfig'
import {
  Card, CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  Typography,
  Zoom
} from '@material-ui/core'
import green from '@material-ui/core/colors/green'
import { CheckCircle } from '@material-ui/icons'
import Log from '../../../shared/commands/log'

const checkEmailVerification = myApp
  .functions()
  .httpsCallable('checkEmailVerification')

class EmailVerified extends Component {
  constructor (props) {
    super(props)

    this.state = {
      token: this.props.match.params.token,
      database: myApp.firestore(),
      storage: myApp.storage(),
      loading: true,
      isVerified: true,
    }
  }

  async verifyToken () {
    try {
      let resp = await checkEmailVerification({ token: this.props.match.params.token })

      if (resp) {
        this.setState({
          isVerified: true,
        })
      }
    } catch (error) {
      console.log(error)
      Log().error({
        trace: error.trace,
        message: error.message,
        info: this.props.match.params.token
      })
    }
  }

  async componentDidMount () {
    await this.verifyToken();
    this.setState({
      loading: false,
    })
  }

  render () {
    return (
      <Container maxWidth="md" style={{height: '100vh'}}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          style={{height: '100vh'}}
        >
          <CssBaseline/>
          {this.state.isVerified
            ?
            <Zoom in={this.state.isVerified} timeout={500}>
              <Grid
                container
                direction="column"
                alignItems="center"
                justify="center"
                spacing={3}
              >
                <Grid item>
                  <Card>
                    <CardHeader
                      title={
                        <Typography
                          variant="h4"
                          component="h4"
                          align="center"
                          style={{ width: '100%' }}
                        >
                          E-mail Verificado
                        </Typography>
                      }
                    />
                    <CardContent>
                      <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                          <Zoom in={this.state.isVerified} timeout={800}>
                            <CheckCircle style={{ fontSize: 100, color: green[500] }}/>
                          </Zoom>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions>
                      <Typography
                        variant="body1"
                        component="body1"
                        align="center"
                        style={{ width: '100%', marginTop: '20px', display: 'block' }}
                      >
                        Você já pode fechar esta janela.
                      </Typography>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </Zoom>
            :
            <Grid item>
              <CircularProgress size={60} color="#193993"/>
            </Grid>
          }
        </Grid>
      </Container>
    )
  }
}

export default withRouter(EmailVerified)
