import React, { Component } from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Chart from "react-google-charts";
import myApp from "../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import "./paineldpoStyle.css";
import theme from "../../../core/theme.json";

import { Grid } from "@material-ui/core";

import { changePage } from "../../../dashboard/presentation/redux/actions";

const pieOptions = {
  title: "",
  pieHole: 0.6,
  slices: [
    {
      color: theme.secondary_color,
    },
    {
      color: theme.primary_color,
    },
  ],
  legend: "none",
  tooltip: {
    showColorCode: true,
  },
  chartArea: {
    left: 0,
    top: 0,
    width: "95%",
    height: "80%",
  },
  fontName: "Roboto",
};

class PainelDPO extends Component {
  constructor(props) {
    super(props);

    this.state = {
      database: myApp.firestore(),
      checked: false,
      openBox: true,
      data: [{ value: 50 }, { value: 10 - 50 }],
      processList: [],
      companyId: localStorage.getItem("currentCompany"),
      criticality: { alta: 0, media: 0, baixa: 0 },
      totalCriticalities: 0,
      role: { operador: 0, controlador: 0 },
      totalRoles: 0,
      holders: [],
      gaps: [],
      customGaps: [],
      gapGraph: [
        [
          "Location",
          "Parent",
          "Market trade volume (size)",
          "Market increase/decrease (color)",
        ],
        ["Global", null, 0, 0],
      ],
      barGraph: [],
      gapRisks: {
        baixa: 0,
        media: 0,
        alta: 0,
      },
      testeGraficGaps: [],
      testeGraficAreas: [],
    };

    this.getProcessesFromCompany = this.getProcessesFromCompany.bind(this);
    this.getQuizesFromCompany = this.getQuizesFromCompany.bind(this);
  }

  componentDidMount() {
    this.getProcessesFromCompany().then(() => {
      this.getQuizesFromCompany();
    });
  }

  async getQuizesFromCompany() {
    let quizesRef = await this.state.database
      .collection("quiz")
      .where("fk_company", "==", this.state.companyId)
      .get();

    let quizes = quizesRef.docs;
    let gaps = this.state.gaps;
    let gapGraph = this.state.gapGraph;
    let gapRisks = this.state.gapRisks;

    for (const q of quizes) {
      for (let p in q.data().points) {
        if (q.data().points[p].cardProps.gap) {
          gaps.push({
            id: q.data().points[p].cardProps.gapId,
            name: q.data().points[p].cardProps.gapText,
            risk: q.data().points[p].cardProps.risk,
            riskChance: q.data().points[p].cardProps.riskChance,
            riskGap: q.data().points[p].cardProps.riskGap,
            riskGapChance: q.data().points[p].cardProps.riskGapChance,
            riskGapImpact: q.data().points[p].cardProps.riskGapImpact,
            riskImpact: q.data().points[p].cardProps.riskImpact,
          });
        }
      }
    }

    let totalGaps = gaps.length + this.state.customGaps.length;

    for (const gap of gaps) {
      gapGraph.push([
        gap.name,
        "Global",
        100 / totalGaps,
        (((gap.riskGap + gap.risk) / 2) * 200) / 25 - 100,
      ]);

      let riskSum = (gap.risk + gap.riskGap) / 2;

      if (riskSum >= 1 && riskSum <= 8) {
        gapRisks.baixa++;
      } else if (riskSum >= 9 && riskSum <= 16) {
        gapRisks.media++;
      } else if (riskSum >= 17 && riskSum <= 25) {
        gapRisks.alta++;
      }
    }

    this.state.customGaps.forEach((customGap, customGapIndex) => {
      gapGraph.push([
        customGap.name,
        "Global",
        100 / totalGaps,
        (((customGap.riskGap + customGap.risk) / 2) * 200) / 25 - 100,
      ]);

      let riskSum = (customGap.risk + customGap.riskGap) / 2;

      if (riskSum >= 1 && riskSum <= 8) {
        gapRisks.baixa++;
      } else if (riskSum >= 9 && riskSum <= 16) {
        gapRisks.media++;
      } else if (riskSum >= 17 && riskSum <= 25) {
        gapRisks.alta++;
      }
    });

    this.setState({
      gaps,
      gapRisks,
    });
  }

  async getProcessesFromCompany() {
    let areasSnapshot = await this.state.database
      .collection("areas")
      .where("fk_companies", "==", this.state.companyId)
      .where("active", "==", true)
      .get();

    let areas = areasSnapshot.docs;
    let areaList = [];
    let processList = [];
    let criticality = this.state.criticality;
    let role = this.state.role;
    let customGaps = this.state.customGaps;
    let pieChartColors = [];
    let aux = [["Tipo de titular", "Quantidade"]];

    for (const el of areas) {
      let area = el.data();
      area.id = el.id;
      areaList.push(area);
    }

    for (const area of areaList) {
      let processesRef = await this.state.database
        .collection("processes")
        .where("fk_areas", "==", area.id)
        .get();

      let processes = processesRef.docs;

      let holders = {};

      for (const doc of processes) {
        let process = doc.data();
        process.id = doc.id;

        if (process.customGaps !== undefined && process.customGaps.length > 0) {
          for (const el of process.customGaps) {
            customGaps.push(el);
          }
        }

        process.areaName = area.name;

        if (process.legalBases.length > 0) {
          process.legalBasisName = process.legalBases[0];
        }

        criticality[process.criticality] += 1;
        role[process.operator.toLowerCase()] += 1;

        try {
          let stages = JSON.parse(process.json);
          process.json = stages;

          try {
            for (let prop in process.json) {
              if (
                Array.isArray(process.json[prop].attributes) &&
                process.json[prop].attributes.length > 0
              ) {
                for (let n of process.json[prop].attributes) {
                  if (holders[n.name] === undefined) {
                    holders[n.name] = 1;
                  } else {
                    holders[n.name] += 1;
                  }
                }
              }
            }
          } catch (e) {
            console.log("erro: ", e);
          }
        } catch (e) {
          console.log("erro", e);
        }

        processList.push(process);
      }

      let areasGrafico = [["Areas", "Areas ativas"]];

      let concluidoGrafico = [
        ["Concluidos e não concluídos", "Qtde"],
        ["Concluido", 0],
        ["Não concluído", 0],
      ];

      //Preenchendo grafico de areas
      for (let i in processList) {
        if (areasGrafico.some((a) => a[0] === processList[i].areaName)) {
          for (let a in areasGrafico) {
            if (areasGrafico[a][0] === processList[i].areaName) {
              areasGrafico[a][1] +=
                processList[i].customGaps.length + processList[i].gaps.length;
            }
          }
        } else {
          areasGrafico.push([
            processList[i].areaName,
            processList[i].customGaps.length + processList[i].gaps.length,
          ]);
        }
      }

      //Preenchando grafico de concluidos com customGaps
      for (let i in processList) {
        for (let o in processList[i].customGaps) {
          if (processList[i].customGaps[o].done === true) {
            concluidoGrafico[1][1] += 1;
          } else {
            concluidoGrafico[2][1] += 1;
          }
        }
      }

      //Preenchando grafico de concluidos com gaps
      for (let i in processList) {
        for (let o in processList[i].gaps) {
          if (processList[i].gaps[o].done === true) {
            concluidoGrafico[1][1] += 1;
          } else {
            concluidoGrafico[2][1] += 1;
          }
        }
      }

      this.setState({
        testeGraficGaps: concluidoGrafico,
        testeGraficAreas: areasGrafico,
      });

      for (let prop in holders) {
        if (!aux.some((h) => h[0] === prop)) {
          aux.push([prop, holders[prop]]);
          pieChartColors.push(
            this.ColorLuminance(
              Math.floor(Math.random() * 16777215).toString(16),
              -0.2
            )
          );
        } else {
          for (let holder of aux) {
            if (holder[0] === prop) {
              holder[1] += holders[prop];
            }
          }
        }
      }
    }

    this.setState({
      processList,
      criticality,
      role,
      holders: aux,
      pieChartColors,
    });

    setTimeout(() => {
      console.log(this.state);
    }, 2000);
  }

  handleChangePage() {
    this.props.changePage(8);
  }

  ColorLuminance(hex, lum) {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, "");

    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    lum = lum || 0;

    // convert to decimal and change luminosity
    var rgb = "#",
      c,
      i;

    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
      rgb += ("00" + c).substr(c.length);
    }

    return rgb;
  }

  render() {
    return (
      <>
        <Grid container spacing={3} style={{ paddingLeft: 12, marginRight: 0 }}>
          {/* Total de titulares */}
          <Grid item md={4} className="pieChartStyle" style={{ marginTop: 12 }}>
            <p className="headerPainelStyle">
              Total de titulares :{" "}
              {" " + Object.keys(this.state.holders).length - 1}
            </p>
            <Chart
              width={"100%"}
              height={"300px"}
              chartType="PieChart"
              loader={<div>Loading Chart</div>}
              data={this.state.holders}
              options={{
                pieStartAngle: 100,
                colors: this.state.pieChartColors,
              }}
              rootProps={{ "data-testid": "4" }}
            />
          </Grid>
          {/* Criticidade */}
          <Grid item md={4} className="pieChartStyle" style={{ marginTop: 12 }}>
            <p className="headerPainelStyle">
              Criticidade : {" " + this.state.processList.length}
            </p>
            <Chart
              width={"100%"}
              height={"300px"}
              chartType="BarChart"
              loader={<div>Loading Chart</div>}
              data={[
                [
                  "Element",
                  "Quantidade de Processos",
                  { role: "style" },
                  {
                    sourceColumn: 0,
                    role: "annotation",
                    type: "string",
                    calc: "stringify",
                  },
                ],
                ["Baixa", this.state.criticality.baixa, "#FFF103", null],
                ["Média", this.state.criticality.media, "#E5541C", null],
                ["Alta", this.state.criticality.alta, "#C91E1E", null],
              ]}
              options={{
                title: "",
                bar: { groupWidth: "50%" },
                legend: { position: "none" },
              }}
              // For tests
              rootProps={{ "data-testid": "6" }}
            />
          </Grid>
          {/* Total de dados pessoais */}
          <Grid item md={4} className="pieChartStyle" style={{ marginTop: 12 }}>
            <p className="headerPainelStyle">
              Riscos: {" " + Object.keys(this.state.gapRisks).length}
            </p>
            <Chart
              width={"100%"}
              height={"300px"}
              chartType="BarChart"
              loader={<div>Loading Chart</div>}
              data={[
                [
                  "Element",
                  "Quantidade de Processos",
                  { role: "style" },
                  {
                    sourceColumn: 0,
                    role: "annotation",
                    type: "string",
                    calc: "stringify",
                  },
                ],
                ["Risco Baixo", this.state.gapRisks.baixa, "#FFF103", null],
                ["Risco Aceitável", this.state.gapRisks.media, "#E5541C", null],
                ["Risco Altíssimo", this.state.gapRisks.alta, "#C91E1E", null],
              ]}
              options={{
                title: "",
                bar: { groupWidth: "50%" },
                legend: { position: "none" },
              }}
              // For tests
              rootProps={{ "data-testid": "6" }}
            />
          </Grid>
        </Grid>
        <div style={{ marginTop: 20 }} />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          style={{ paddingLeft: 12, marginRight: 0 }}
        >
          <Grid item md={4} className="pieChartStyle" style={{ marginTop: 12 }}>
            <p className="headerPainelStyle">Gaps </p>
            <Chart
              width={"100%"}
              height={"300px"}
              chartType="PieChart"
              loader={<div>Loading Chart</div>}
              data={this.state.testeGraficGaps}
              chartEvents={[
                {
                  eventName: "select",
                  callback: () => {
                    this.handleChangePage();
                  },
                },
              ]}
              options={{
                minColor: "#0d0",
                midColor: "#ff0",
                maxColor: "#f00",
                headerHeight: 15,
                fontColor: "black",
                showScale: true,
              }}
              rootProps={{ "data-testid": "1" }}
            />
          </Grid>
          <Grid item md={4} className="pieChartStyle" style={{ marginTop: 12 }}>
            <p className="headerPainelStyle"> GAPS por área </p>
            <Chart
              width={"100%"}
              height={"300px"}
              chartType="PieChart"
              loader={<div>Loading Chart</div>}
              data={this.state.testeGraficAreas}
              chartEvents={[
                {
                  eventName: "select",
                  callback: () => {
                    this.handleChangePage();
                  },
                },
              ]}
              options={{
                minColor: "#0d0",
                midColor: "#ff0",
                maxColor: "#f00",
                headerHeight: 15,
                fontColor: "black",
                showScale: true,
              }}
              rootProps={{ "data-testid": "1" }}
            />
          </Grid>
          <Grid item md={4} style={{ paddingBottom: 0 }}>
            <Paper className="containerProcessosStyle">
              <p className="headerPainelStyle">
                Atuação {": " + this.state.processList.length}
              </p>
              <Chart
                chartType="PieChart"
                data={[
                  ["Operador", "Controlador"],
                  ["Operador", this.state.role.operador],
                  ["Controlador", this.state.role.controlador],
                ]}
                options={pieOptions}
                graph_id="PieChart"
                width={"100%"}
                height={"300px"}
              />
            </Paper>
          </Grid>
        </Grid>
        <div style={{ marginTop: 20 }} />
        <Grid
          container
          spacing={3}
          style={{ paddingLeft: 12, marginRight: 0 }}
        ></Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changePage: (page) => dispatch(changePage(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PainelDPO);
