import {
  
  Grid,
  Box,
  MenuItem,
  TextField,
  Typography,

  FormControl,
  InputLabel,
  Select,
  Paper,
  Checkbox,
  FormControlLabel,
 
} from "@material-ui/core";
import ReactInputMask from "react-input-mask";

const TextMaskCustomCPF = (props) => {
  const { inputRef, ...other } = props;
  return (

  
    
    <ReactInputMask
      {...other}
      ref={inputRef}
      mask="999.999.999-99"
    />
  );
};

export const ApurationForm = ({
  denunce,
  status,
  verificationConclusation,
  setVerificationConclusation,
  cpf,
  setCpf,
  name , 
  setName,
  company_relation_user, 
  setCompany_relation_user, 
  email , 
  setEmail,
  verificationCheckBox,
  setVerificationCheckBox
}) => {




  return (
    <>
      {denunce.status == "Concluído" ? (
        <Paper
          style={{
            padding: "8% 0",
            height: "15%",
            marginBottom: "10%",
          }}
        >
          <Typography variant="h6" component="h6">
            Conclusão da apuração :{" "}
            <span>{denunce.verificationConclusation}</span>
          </Typography>
        </Paper>
      ) : status == "Concluído" ? (
        <FormControl style={{ width: "20rem", margin: "8% 0" }} fullWidth>
          <InputLabel variant="outlined" id="demo-simple-select-conclusao">
            Conclusão da Apuração
          </InputLabel>
          <Select
            variant="outlined"
            labelId="demo-simple-select-label-conclusao"
            id="demo-simple-select-conclusao"
            label="Conclusão da Apuração"
            value={verificationConclusation}
            onChange={(e) => setVerificationConclusation(e.target.value)}
          >
            <MenuItem value={"Procedente"}>Procedente</MenuItem>
            <MenuItem value={"Não Procedente"}>Não Procedente</MenuItem>
            <MenuItem value={"inconclusiva"}>inconclusiva</MenuItem>
          </Select>
        </FormControl>
      ) : (
        ""
      )}

      {(denunce.email == "" && status == "Concluído") ||
      (denunce.email == undefined && status == "Concluído") ? (
        <Grid item style={{ height: "auto" }}>
          <Grid
            container
            direction="column"
            alignItems="stretch"
            justifyContent="center"
          >
            <Grid item>
              <Typography variant="h6" component="h6" style={{ width: "100%" }}>
                responsável pela apuração
              </Typography>
              <Box
                component="form"
                style={{ display: "flex", justifyContent: "space-around" }}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  margin="normal"
                  variant="outlined"
                  id="Nome"
                  label="Nome"
                  name="Nome"
                  autoComplete="name"
                  style={{ width: "45%" }}
                />
                <TextField
                  value={company_relation_user}
                  onChange={(e) =>
                    setCompany_relation_user(e.target.value)
                  
                  }
                  margin="normal"
                  variant="outlined"
                  name="Relação com a empresa"
                  label="Relação com a empresa"
                  type="text"
                  style={{ width: "45%" }}
                />
              </Box>

              <Box
                component="form"
                style={{ display: "flex", justifyContent: "space-around" }}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  value={cpf}
                  onChange={(event) =>  setCpf(event.target.value)}
                  InputProps={{
                    inputComponent: TextMaskCustomCPF ,
                  }}
                  margin="normal"
                  variant="outlined"
                  required
                  id="CPF"
                  label="CPF"
                  name="CPF"
                  type="text"
                  style={{ width: "45%" }}
                />
                <TextField
                  value={email}
                  onChange={(e) => setEmail(e.target.value )}
                  margin="normal"
                  variant="outlined"
                  required
                  name="Email"
                  label="Email"
                  type="text"
                  id="Email"
                  autoComplete="email"
                  style={{ width: "45%" }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ) : (denunce.email !== "" && status == "Concluído") ||
        (denunce.email !== undefined && denunce.status == "Concluído") ? (
        <Grid>
          <Grid item>
            <Typography variant="h5" component="h5" style={{ width: "100%" }}>
              responsável pela apuração
            </Typography>
          </Grid>
          <Grid>
            <Grid
              style={{
                display: "flex",
                justifyContent: "space-around",
                padding: "15px",
              }}
            >
              <Typography
                variant="h6"
                component="h6"
                style={{ width: "100%", color: "#555" }}
              >
                {"email: " + denunce.email}
              </Typography>

              <Typography
                variant="h6"
                component="h6"
                style={{ width: "100%", color: "#555" }}
              >
                {"Relação : " + denunce.company_user_relation}
              </Typography>
            </Grid>

            <Grid
              style={{
                display: "flex",
                justifyContent: "space-around",
                padding: "15px",
              }}
            >
              <Typography
                variant="h6"
                component="h6"
                style={{ width: "100%", color: "#555" }}
              >
                {"name: " + denunce.name}
              </Typography>

              <Typography
                variant="h6"
                component="h6"
                style={{ width: "100%", color: "#555" }}
              >
                {"CPf: " + denunce.cpf}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        ""
      )}

{status == "Concluído" && (
          <Grid item>
            <InputLabel
              style={{
                maxWidth: "80%",
                margin: "5% 0 0 5%",
                fontSize: "18px",
                lineHeight: "20px",
              }}
            >
              Eu comprometo-me a respeitar o sigilo das informações
              confidenciais deste canal de denúncia, ou das demais informações a
              que tive acesso através deste, por minha relação de participante
              no processo de apuração de denúncias em curso. Reconheço que por
              informações confidenciais se entende qualquer projeto, ideia,
              fórmula, método, invenção, modelo de utilidade, obra artística,
              literária ou cultural, programa de computador, dado, algoritmo,
              planilha, documento ou informação ao qual eu venha a ter acesso,
              contidas e/ou transmitidas por qualquer meio, eletrônico, impresso
              ou oralmente, independentemente de aviso ou tarja advertindo
              tratar-se de informações confidenciais. Assumo, outrossim, o dever
              de manter tais informações em sigilo, guardando-as com grau de
              zelo não inferior ao que emprego para salvaguardar informações
              confidenciais das empresas para as quais trabalho. Estou ciente,
              por fim, de que a violação do sigilo ora pactuado pode acarretar
              responsabilidade pessoal, civil, criminal e/ou trabalhista, pelas
              perdas e danos, inclusive lucros cessantes e danos morais. E, para
              os devidos fins de direito, firmo a presente
            </InputLabel>
            <FormControlLabel
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              control={
                <Checkbox
                  checked={verificationCheckBox}
                  onChange={(event) =>
                    setVerificationCheckBox(event.target.checked)
                  }
                  name="send-email"
                />
              }
              label="eu aceito"
            />
          </Grid>
        )} 

    </>
  );
};
