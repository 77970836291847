import myApp from "../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { insertInFirebaseCollection } from "../../shared/commands/insertInFirebaseCollection";
import { isDefined } from "../../shared/commands/verifyDefinition";

export async function checkUser(callback) {
  let database = myApp.firestore();
  var user = myApp.auth().currentUser;
      console.log('nao sei')
  try {
    if (user) {
      localStorage.setItem("currentUser", user.uid);
      let data = await database.collection("users").where("uid", "==", user.uid).get();
      let userInformation = data.docs[0].data();
      localStorage.setItem("@blue-legal/companyGroup", userInformation.fk_company_group);
      saveUserLog()
      // await userExistsInDB(user).then(function () {
        // return callback();
      // });
    }
  } catch (error) {
    console.log(error);
  } finally {
    return callback();
  }
}
   const saveUserLog =async()=> {
        let get = await myApp.firestore().collection("users").where("uid","==", localStorage.getItem('currentUser')).get()
              
        for(let item of get.docs){
         let userlogged ={
           name: item.data().displayName,
           email: item.data().email, 
           fk_company: localStorage.getItem("currentCompany"),
           loggedAt: Date.now() ,
           timeLoggedAt: new Date().toLocaleTimeString() ,
           
         }
         let create = await myApp.firestore().collection("userLogs").add(userlogged)
         console.log('criado com sucesso', create)
        }
     }

async function userExistsInDB(user) {
  let database = myApp.firestore();

  try {
    let data = await database
      .collection("users")
      .where("uid", "==", user.uid)
      .get();
         console.log(data.docs.map(item => item))
    if (data.size === 0) {
      await registerUserInDB(user);
    } else {
      var userData = data.docs[0].data();
      userData["id"] = data.docs[0].id;
      await setUserLocalStorage(userData);
    }
  } catch (error) {
    console.error(error);
  }
}

async function setUserLocalStorage(user) {
  let database = myApp.firestore();
  let isAdmin = false;
  let profilePermissions = [];
  let companyGroup = "";

  if (isDefined(user.fk_company_group)) {
    companyGroup = user.fk_company_group;
    localStorage.setItem("@blue-legal/companyGroup", companyGroup);
    isAdmin = true;
  } else if (isDefined(user.fk_profile_permissions)) {
    profilePermissions = user.fk_profile_permissions;
    var id = user.id;
    delete user.id;
    await database.collection("users").doc(id).set(user);
  } else {
    //Verificação de conta Admin
    let companyGroupData = await database
      .collection("admin")
      .where("fk_user_email", "==", user.email)
      .get();

    if (companyGroupData.size !== 0) {
      companyGroup = companyGroupData.docs[0].data().fk_company_group.trim();
      localStorage.setItem("@blue-legal/companyGroup", companyGroup);
      isAdmin = true;
    }
  }

  user = {
    displayName: user.displayName,
    email: user.email,
    photoURL: user.photoURL,
    uid: user.uid,
  };

  if (isAdmin) {
    user["fk_company_group"] = companyGroup;
  } else {
    user["fk_profile_permissions"] = await verifyInvites(
      user,
      profilePermissions
    );
  }

  return user;
}

async function verifyInvites(user, profilePermissions) {
  var database = myApp.firestore();
  //Verificação de convite
  let invitesData = await database
    .collection("invites")
    .where("fk_user_email", "==", user.email)
    .where("claimed", "==", false)
    .get();
  
  if (invitesData.size !== 0) {
    let batch = database.batch();

    invitesData.docs.forEach((doc) => {
      var data = doc.data();
      let company = data.fk_company.trim();
      let profile = data.fk_profile.trim();
      profilePermissions.push({ fk_company: company, fk_profile: profile });
      database.collection("invites").doc(doc.id).update({
        claimed: true,
      });
    });

    localStorage.setItem("@blue-legal/profilePermissions", profilePermissions);
    batch.commit();
  }

  return profilePermissions;
}

async function registerUserInDB(user) {
  await setUserLocalStorage(user).then(
    async (user) => await insertInFirebaseCollection("users", user)
  );
}