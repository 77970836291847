import React, { useState, useEffect } from 'react';

//imports mui
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  CircularProgress,
  TableHead,
  TableRow,
  Switch,
  TextField,
  Button,
  Paper,
  Typography,
  Avatar
} from '@material-ui/core';

//import firebase
import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";

//import formatar data
import { format } from 'date-fns';

//import export excell
import ReactExport from "react-export-excel";

//import google chart
import Chart from "react-google-charts";

//import cores do tema mui
import theme from '../../../../core/theme.json';

//import redux
import { useDispatch } from "react-redux";
import { changePage } from '../../../presentation/redux/actions';
import { setProcessId, setLoadingProcess, setProcessArea } from '../../process/redux/actions';

import { isUndefined } from "lodash";
import ScrollWatcher from '../../../../shared/components/scrollWatcher';

const GapsRelatorio = () => {
  //variaveis auxiliares
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const dispatch = useDispatch();

  // states
  const [stateConclu, setstateConclu] = useState('');
  const [stateArea, setstateArea] = useState('');
  const [stateGap, setstateGap] = useState('');
  const [rowsDb, setRowsDb] = useState([]);
  const [areasDoGrafico, setareasDoGrafico] = useState([]);
  const [concluDoGrafico, setconcluDoGrafico] = useState([]);

  // função responsável por formatar a data
  const toDateTime = (date) => {
    if (isUndefined(date.seconds)) {
      return format(date, "dd/MM/yyyy");
    } else {
      return format(new Date(date.seconds * 1000), "dd/MM/yyyy");
    }
  }

  // função responsável pela interação com o banco
  const loadingDb = async () => {
    const db = myApp.firestore();
    const minhaLista = [];
    const meusProcessos = [];
    const meusProcessosCompletos = [];

    // carregando dados do firebase
    let areasSnapshot = await db
      .collection("areas")
      .where("fk_companies", "==", localStorage.getItem('currentCompany'))
      .where("active", "==", true)
      .get();

    // preenchendo lista auxiliar areas para carregar o state
    for (let doc of areasSnapshot.docs) {
      minhaLista.push({
        dados: doc.data(),
        id: doc.id
      });
    }

    // preenchendo lista auxiliar processos para carregar o state
    for (let area of minhaLista) {
      let querySnapshot = await db.collection("processes").where("fk_areas", "==", area.id).get();
      for (let doc of querySnapshot.docs) {
        meusProcessos.push({
          ...doc.data(),
          id: doc.id,
          areaName: area.dados.name,
        });
      }
    }

    // preenchendo lista auxiliar com customGaps
    for (let processo of meusProcessos) {
      // console.log("Quem é vc?", processo)
      for (let customGap of processo.customGaps) {
        meusProcessosCompletos.push({
          areaId: processo.fk_areas,
          processId: processo.id,
          gaps: customGap.name ? customGap.name : '',
          riscoGaps: customGap.riskGap ? customGap.riskGap : '',
          planoDeAcao: customGap.actionPlan ? customGap.actionPlan : '',
          riscoPlanAcao: customGap.risk ? customGap.risk : '',
          dateConclu: customGap.date ? toDateTime(customGap.date) : '',
          etapa: customGap.owner ? customGap.owner : '',
          email: customGap.ownerEmail ? customGap.ownerEmail : '',
          concluido: customGap.done ? customGap.done : false,
          areaName: processo.areaName ? processo.areaName : '',
          processo: processo.name ? processo.name : '',
        });
      }

      let getGapsForNewAnswersQuiz = await db.collection("newQuizAnswers")
        .where("processId", "==", processo.id)
        .where("fk_company", "==", localStorage.getItem("currentCompany"))
        .get()

      if (getGapsForNewAnswersQuiz.docs[0] && getGapsForNewAnswersQuiz.docs[0].data() != undefined) {
        let questions = getGapsForNewAnswersQuiz.docs[0].data().questions;
        for (let question of questions) {
          if (question && question.alternativesList != undefined && question.alternativesList.length > 0) {
            let alternativesList = question.alternativesList;
            for (let alternativeGap of alternativesList) {
              if (alternativeGap.gap == true) {
                // console.log("Meu gap", alternativeGap)
                meusProcessosCompletos.push({
                  areaId: processo.fk_areas,
                  processId: processo.id,
                  gaps: alternativeGap.name,
                  riscoGaps: '1',
                  planoDeAcao: alternativeGap.gapPlanoAcao,
                  riscoPlanAcao: '1',
                  dateConclu: '',
                  etapa: ' ',
                  email: '',
                  concluido: false,
                  areaName: processo.areaName ? processo.areaName : '',
                  processo: processo.name ? processo.name : '',
                });
              }
            }
          }
        }
      }

      //preenchendo lista auxiliar com gaps
      for (let gap of processo.gaps) {
        meusProcessosCompletos.push({
          areaId: processo.fk_areas,
          processId: processo.id,
          gaps: gap.name ? gap.name : '',
          riscoGaps: gap.riskGap ? gap.riskGap : '',
          planoDeAcao: gap.actionPlan ? gap.actionPlan : '',
          riscoPlanAcao: gap.risk ? gap.risk : '',
          dateConclu: gap.date ? toDateTime(gap.date) : '',
          etapa: gap.processStageName ? gap.processStageName : '',
          email: gap.ownerEmail ? gap.ownerEmail : '',
          concluido: gap.done ? gap.done : false,
          areaName: processo.areaName ? processo.areaName : ' ',
          processo: processo.name ? processo.name : '',
        });
      }
    }

    // filtrando tabela
    let testeArrayFiltrado = meusProcessosCompletos.sort((a, b) => b.riscoGaps - a.riscoGaps);
    let testeArrayFiltrado2 = testeArrayFiltrado.sort((a, b) => a.concluido - b.concluido);

    let areasGrafico = [
      ['Áreas', 'Áreas ativas']
    ];

    let concluidoGrafico = [
      ['Concluídos e não concluídos', 'Qtde'],
      ['Concluído', 0],
      ['Não concluído', 0]
    ];

    // Preenchendo grafico de areas
    for (let i in meusProcessos) {
      if (areasGrafico.some(a => a[0] === meusProcessos[i].areaName)) {
        for (let a in areasGrafico) {
          if (areasGrafico[a][0] === meusProcessos[i].areaName) {
            areasGrafico[a][1] += meusProcessos[i].customGaps.length + meusProcessos[i].gaps.length
          }
        }
      } else {
        areasGrafico.push([
          meusProcessos[i].areaName,
          meusProcessos[i].customGaps.length + meusProcessos[i].gaps.length
        ])
      }
    }

    // Preenchando grafico de concluidos com customGaps
    for (let i in meusProcessos) {
      for (let o in meusProcessos[i].customGaps) {
        if (meusProcessos[i].customGaps[o].done === true) {
          concluidoGrafico[1][1] += 1
        } else {
          concluidoGrafico[2][1] += 1
        }
      }
    }

    // Preenchando grafico de concluidos com gaps
    for (let i in meusProcessos) {
      for (let o in meusProcessos[i].gaps) {
        if (meusProcessos[i].gaps[o].done === true) {
          concluidoGrafico[1][1] += 1
        } else {
          concluidoGrafico[2][1] += 1
        }
      }
    }

    //mostrando gaps com erros
    for (let i = 0; i < testeArrayFiltrado2.length; i++) {
      if (testeArrayFiltrado2[i].etapa === '') {
        console.log('Gap com erro', testeArrayFiltrado2[i])
      }
    }

    // preenchando meus states
    setRowsDb(testeArrayFiltrado2);
    setareasDoGrafico(areasGrafico);
    setconcluDoGrafico(concluidoGrafico);
  }

  // filtrando a cor do item de acordo com o risco
  const handleColorRowTable = (value) => {
    if (value < 7) {
      return { background: "#45ad18", fontWeight: "bold" }
    }
    else if (value < 13) {
      return { background: "#f7ce10", fontWeight: "bold" }
    }
    else {
      return { background: "#e20000", fontWeight: "bold" }
    }
  }

  // Filtrando por items concluídos
  const handleConcluFilter = (item) => {
    switch (stateConclu) {
      case 'Todos':
        return (item);
      case 'Concluído':
        return (item.concluido);
      case 'Não concluído':
        return (item.concluido === false);
      default:
        return (item);
    }
  }

  // Filtrando por áreas
  const handlerAreaFilter = (item) => {
    if (stateArea !== '') {
      return item.areaName === stateArea;
    } else {
      return item;
    }
  }

  // Filtrando por gap
  const handleFilterGap = (item) => {
    if (stateGap !== '') {
      return item.gaps.toUpperCase().includes(stateGap.toUpperCase());
    } else {
      return item;
    }
  }

  // filtro para remover gaps sem etapas
  const handleRemoveGapsWithError = (item) => {
    if (item.etapa !== '') {
      return item;
    }
  }

  // mudando pagina com redux
  const handleChangePage = (item) => {
    dispatch(setLoadingProcess(true))
    dispatch(setProcessArea(item.areaId))
    dispatch(setProcessId(item.processId));
    dispatch(changePage(7));
  }

  // executando busca no banco
  useEffect(() => {
    loadingDb();
  }, []);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        style={{ width: "100%", marginTop: 10 }}
      >
        <Grid
          item
          container
          component={Paper}
          direction="column"
          alignItems="center"
          style={{ width: "45%", }}
        >
          <Grid item>
            <Typography variant="h6" component="h6" style={{ marginTop: 10 }}>Condição</Typography>
          </Grid>
          <Grid item style={{ width: "100%" }}>
            <Chart
              wrap
              onPress={() => alert('Clicou')}
              width={'90%'}
              height={'200px'}
              chartType="PieChart"
              loader={<div>Loading Chart</div>}
              data={concluDoGrafico}
              chartEvents={[
                {
                  eventName: 'select',
                  callback: ({ chartWrapper }) => {
                    const chart = chartWrapper.getChart()
                    const selection = chart.getSelection()
                    let dadosParaFiltro = selection.map(item => [item.row])
                    for (let i in dadosParaFiltro) {
                      if (dadosParaFiltro[i][i] === 0) {
                        setstateConclu('Concluído');
                      } else if (dadosParaFiltro[i][i] === 1) {
                        setstateConclu('Não concluído');
                      }
                    }
                    if (selection.length === 0) {
                      setstateConclu('');
                    }
                  },
                },
              ]}
              options={{
                title: 'GAPS concluídos'
              }}
              rootProps={{ 'data-testid': '1' }}
            />

          </Grid>
        </Grid>

        <Grid
          item
          container
          component={Paper}
          direction="column"
          alignItems="center"
          style={{ width: "45%" }}
        >
          <Grid item>
            <Typography variant="h6" component="h6" style={{ marginTop: 10 }}>Áreas</Typography>
          </Grid>
          <Grid item style={{ width: "100%" }}>
            <Chart
              wrap
              onPress={() => alert('Clicou')}
              width={'100%'}
              height={'200px'}
              chartType="PieChart"
              loader={<div>Loading Chart</div>}
              data={areasDoGrafico}
              chartEvents={[
                {
                  eventName: 'select',
                  callback: ({ chartWrapper }) => {
                    const chart = chartWrapper.getChart()
                    const selection = chart.getSelection()
                    let dadosParaFiltro = selection.map((item) => [item.row])
                    if (dadosParaFiltro.length > 0) {
                      setstateArea(areasDoGrafico[dadosParaFiltro[0][0] + 1][0])
                    } else {
                      setstateArea('')
                    }
                  },
                },
              ]}
              options={{ title: 'Áreas ativas' }}
              rootProps={{ 'data-testid': '1' }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        component={Paper}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ width: "100%", marginTop: 10 }}
      >
        <Grid item style={{ marginLeft: '30px', padding: 10 }}>
          <TextField
            id="select-gap"
            label="Busca por nome do GAP"
            variant="outlined"
            size="small"
            style={{ width: '350px' }}
            onChange={(e) => setstateGap(e.target.value)}
          />
        </Grid>
        <Grid item style={{ marginRight: '30px', padding: 10 }}>
          <Button
            style={{
              background: theme.primary_color,
              color: theme.secondary_color,
            }}
          >
            <ExcelFile element={"Download"} filename="Report_GAP">
              <ExcelSheet
                data={rowsDb.filter(handleConcluFilter).filter(handlerAreaFilter).filter(handleFilterGap).filter(handleRemoveGapsWithError)}
                name="Relatório de GAPS"
              >
                <ExcelColumn
                  style={{ margin: "0 20px 0 0" }}
                  label="ÁREAS"
                  value="areaName"
                />
                <ExcelColumn
                  style={{ margin: "0 20px 0 0" }}
                  label="PROCESSO"
                  value="processo"
                />
                {/* <ExcelColumn
                  style={{ margin: "0 20px 0 0" }}
                  label="ETAPA"
                  value="etapa"
                /> */}
                <ExcelColumn
                  style={{ margin: "0 20px 0 0" }}
                  label="GAPS"
                  value="gaps"
                />
                <ExcelColumn
                  style={{ margin: "0 20px 0 0" }}
                  label="RISCO DO GAP"
                  value="riscoGaps"
                />
                <ExcelColumn
                  style={{ margin: "0 20px 0 0" }}
                  label="PLANO DE AÇÃO"
                  value="planoDeAcao"
                />
                <ExcelColumn
                  style={{ margin: "0 20px 0 0" }}
                  label="RISCO DO PLANO DE AÇÃO"
                  value="riscoPlanAcao"
                />
                <ExcelColumn
                  style={{ margin: "0 20px 0 0" }}
                  label="EMAIL DO RESPONSÁVEL"
                  value="email"
                />
                <ExcelColumn
                  style={{ margin: "0 20px 0 0" }}
                  label="DATA DE CONCLUSÃO"
                  value="dateConclu"
                />
                <ExcelColumn
                  style={{ margin: "0 20px 0 0" }}
                  label="CONCLUÍDO"
                  value="concluido"
                />
              </ExcelSheet>
            </ExcelFile>
          </Button>
        </Grid>
      </Grid>
      {rowsDb.length === 0 ?
        <Grid
          container
          direction="column"
          alignItems="center"
          style={{ width: "100%", marginTop: 40 }}
        >
          <Grid item>
            <CircularProgress size={50} color={theme.secondary_color} />
          </Grid>
        </Grid>
        :
        <Grid
          component={Paper}
          container
          direction="column"
          style={{ width: "100%", marginTop: 10, height: "82vh" }}
        >
          <Grid item style={{ width: "100%", height: "100%" }}>
            <ScrollWatcher onScrollBottom={loadingDb} populate={loadingDb} >
            <TableContainer style={{ maxHeight: '80vh' }}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'Bold' }}>
                      ÁREA
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                      PROCESSO
                    </TableCell>
                    {/* <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                      ETAPA
                    </TableCell> */}
                    <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                      GAP
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                      RISCO DO GAP
                    </TableCell>

                    <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                      PLANO DE AÇÃO
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                      RISCO DO PLANO DE AÇÃO
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                      EMAIL DO RESPONSÁVEL
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                      DATA DE CONCLUSÃO
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                      CONCLUÍDO
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsDb.filter(handleConcluFilter).filter(handlerAreaFilter).filter(handleFilterGap).filter(handleRemoveGapsWithError).map((item, k) => (
                    <TableRow
                      key={k}
                      onClick={() => handleChangePage(item)}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      style={k % 2 ? { background: "white" } : { background: "#e3e3e3" }}
                    >
                      <TableCell style={{ fontWeight: 'Bold' }}>
                        {item.areaName}
                      </TableCell>
                      <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                        {item.processo}
                      </TableCell>
                      {/* <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                        {item.etapa}
                      </TableCell> */}
                      <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                        {item.gaps}
                      </TableCell>
                      <TableCell align="center">
                        <Grid container direction="column" alignItems="center">
                          <Grid item>
                            <Avatar style={handleColorRowTable(item.riscoGaps)}>
                              {item.riscoGaps}
                            </Avatar>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell style={{ fontWeight: 'Bold' }}>
                        {item.planoDeAcao}
                      </TableCell>
                      <TableCell align="center">
                        <Grid container direction="column" alignItems="center">
                          <Grid item>
                            <Avatar style={handleColorRowTable(item.riscoPlanAcao)}>
                              {item.riscoPlanAcao}
                            </Avatar>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                        {item.email}
                      </TableCell>
                      <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                        {item.dateConclu !== '' ? item.dateConclu : ''}
                      </TableCell>
                      <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                        <Switch align="center" color='primary' checked={item.concluido} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            </ScrollWatcher>
          </Grid>
        </Grid>
      }
    </>
  );
}

export default GapsRelatorio;