import React from 'react';

const closeButtonIcon = (props) => { 
    const transform = props.iconType === "close" ? "translate(-1.591 20.683) rotate(-45)" : "rotate(0)" ;
    const color = props.color ? props.color : "#fafafa";
    const width = props.width ? props.width : 44.548;
    const height = props.height ? props.height : 44.548;
    const margin = props.margin ? props.margin : 'auto'
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} style={{margin: margin.toString()}} viewBox="0 0 44.548 44.548">
            <path id="Icon_awesome-plus" data-name="Icon awesome-plus" d="M29.25,14.625H19.125V4.5a2.25,2.25,0,0,0-2.25-2.25h-2.25a2.25,2.25,0,0,0-2.25,2.25V14.625H2.25A2.25,2.25,0,0,0,0,16.875v2.25a2.25,2.25,0,0,0,2.25,2.25H12.375V31.5a2.25,2.25,0,0,0,2.25,2.25h2.25a2.25,2.25,0,0,0,2.25-2.25V21.375H29.25a2.25,2.25,0,0,0,2.25-2.25v-2.25A2.25,2.25,0,0,0,29.25,14.625Z" transform={transform.toString()} fill={color}/>
        </svg>
    );
}

export default closeButtonIcon