import React, { Component } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Switch,
  Box,
  MenuItem,
  TextField,
  Typography,
  Zoom,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Paper,
  ButtonBase,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import { formatDate, formatHour } from "../../../../shared/commands/formatDate";
import { changePage } from "../../../presentation/redux/actions";
import theme from "../../../../core/theme.json";
import InputFile from "../../../../shared/components/InputFile";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { grey } from "@material-ui/core/colors";
import Title from "../../../../shared/components/title";
import {
  ArrowBackIosTwoTone,
  DeleteForever,
  Label,
  SaveTwoTone,
} from "@material-ui/icons";
import Swal from "sweetalert2";
import DenunceTimeLine from "../../../../shared/components/DenunceTimeline";
import AWSS3UploadAsh from "aws-s3-upload-ash";
import { setDenunceObj } from "../redux/ViewsPdfDenunceReducer";
import validateCPF from "../../../../shared/commands/validations/validateCPF";
import InputMask from "react-input-mask";

const sendSolicitationChannelEmail = myApp
  .functions()
  .httpsCallable("sendSolicitationChannelEmail");

const config = {
  bucketName: process.env.REACT_APP_AWS_BUCKET_NAME,
  accessKeyId: process.env.REACT_APP_AWS_ACESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  s3Url: process.env.REACT_APP_AWS_LINK_PRIVACY,
  region: process.env.REACT_APP_AWS_REGION,
};

const S3CustomClient = new AWSS3UploadAsh(config);

class DenunceDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      database: myApp.firestore(),
      solicitationData: {},
      denunceData: {},
      personType: "",
      solicitationType: "",
      companyId: "",
      handleStatus: "",
      verificationConclusation: "",
      verificationCheckBox: false,
      name: "",
      Cpf: "",
      email: "",
      company_relation_user: "",
      document: "",
      phone: "",
      additionalInformation: "",
      formInternalDocumentsFiles: null,
      documentProofFile: null,
      responseFile: null,
      formResponseFile: { type: "", name: "" },
      list_document_Response: [],
      birthDate: "",
      createDate: "",
      openDialog: false,
      acept: false,
      limitDate: "",
      conclusionDate: null,
      conclusionDateFormat: "",
      status: "",
      protocol: "",
      handleSensitive: "",
      loading: true,
      done: false,
      response: "",
      userInformation: JSON.parse(
        sessionStorage.getItem("@blue-legal/userInformation")
      ),
      denunceDataParams: [],
      companyGroupData: {},
      previousStateDone: false,
      responseFileProgress: 100,
      internalDocumentsFilesProgress: 100,
      loadingSend: false,
      sendEmail: true,
      teste: [],
    };
  }

  async componentDidMount() {
    await this.getSolicitationData();
    this.setState({
      loading: false,
    });
    this.handleScrollHistoryToBottom();
  }

  async getSolicitationData() {
    try {
      let solicitationData = await this.state.database
        .collection("denunce")
        .doc(this.props.solicitationId)
        .get();

      let solicitation = solicitationData.data();

      this.setState({
        denunceData: solicitation,
      });

      let getDateParamers = await myApp
        .firestore()
        .collection("denunceParameters")
        .where("fk_company_group", "==", localStorage.getItem("currentCompany"))
        .get();
      const dateParamers = getDateParamers.docs.map((item) => item.data());

      this.setState({
        denunceDataParams: dateParamers.map((item) => item),
      });
      console.log("dados", this.state.denunceData);
    } catch (err) {
      console.log(err);
    }
  }

  handleSendEMailCheckbox(event) {
    this.setState({
      sendEmail: !!event.target.checked,
    });
  }

  handleResponse(event) {
    this.setState({
      response: event.target.value,
    });
  }

  handleResponseFile(file) {
    this.setState({
      formResponseFile: file,
    });
  }

  handleInternalDocumentsFiles(files) {
    this.setState({
      formInternalDocumentsFiles: files,
    });
  }

  handleScrollHistoryToBottom = () => {
    const { timeline } = this.refs;
    timeline.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  async saveSolicitation() {
    if (
      this.state.handleSensitive == "" &&
      this.state.denunceData.Sensitive == undefined
    ) {
      Swal.fire({
        icon: "error",
        title: 'Campo "Grau de sensibilidade" não pode ser vazio',
        showConfirmButton: false,
        timer: 1500,
      });
      this.setState({
        loadingSend: false,
      });
      return;
    }
    if (
      this.state.handleStatus == "Concluído" &&
      this.state.verificationCheckBox == false
    ) {
      Swal.fire({
        icon: "error",
        title: "Aceite os termos para continuar",
        showConfirmButton: false,
        timer: 1500,
      });
      this.setState({
        loadingSend: false,
      });
      return;
    }

    if (this.state.handleStatus == "Concluído") {
      if (this.state.Cpf == "") {
        Swal.fire({
          icon: "error",
          title: 'Campo "Cpf" não pode ser vazio',
          showConfirmButton: false,
          timer: 1500,
        });
        this.setState({
          loadingSend: false,
        });
        return;
      }

      if (this.state.Cpf.replace(/\D/gim, "").length < 11) {
        Swal.fire({
          icon: "error",
          title: 'Preencha o campo "CPF"',
          showConfirmButton: false,
          timer: 1500,
        });
        this.setState({
          loadingSend: false,
        });
        return;
      }
      if (this.state.name == "") {
        Swal.fire({
          icon: "error",
          title: 'Campo "Nome" não pode ser vazio',
          showConfirmButton: false,
          timer: 1500,
        });
        this.setState({
          loadingSend: false,
        });
        return;
      }

      if (this.state.email == "") {
        Swal.fire({
          icon: "error",
          title: 'Campo "Email" não pode ser vazio',
          showConfirmButton: false,
          timer: 1500,
        });
        this.setState({
          loadingSend: false,
        });
        return;
      }
      if (this.state.Cpf == "") {
        Swal.fire({
          icon: "error",
          title: 'Campo "Cpf" não pode ser vazio',
          showConfirmButton: false,
          timer: 1500,
        });
        this.setState({
          loadingSend: false,
        });
        return;
      }
      if (
        this.state.response == "" &&
        this.state.denunceData.response == undefined
      ) {
        Swal.fire({
          icon: "error",
          title: 'Campo "Resposta" não pode ser vazio',
          showConfirmButton: false,
          timer: 1500,
        });
        this.setState({
          loadingSend: false,
        });
        return;
      }
      if (this.state.verificationConclusation == "") {
        Swal.fire({
          icon: "error",
          title: "Conclusão de apuração não pode ser vazio",
          showConfirmButton: false,
          timer: 1500,
        });
        this.setState({
          loadingSend: false,
        });
        return;
      }
      if (this.state.company_relation_user == "") {
        Swal.fire({
          icon: "error",
          title: 'Campo "Relação com a empresa" não pode ser vazio',
          showConfirmButton: false,
          timer: 1500,
        });
        this.setState({
          loadingSend: false,
        });
        return;
      }
      if (this.state.handleStatus == "Concluído" && this.state.acept == false) {
        this.setState({
          openDialog: true,
        });
        return;
      }
    }

    this.setState({
      loadingSend: true,
    });
    this.setState({
      handleStatus: "",
    });

    let responseHistory = this.state.denunceData?.responseHistory ?? [];

    try {
      let responseFile = null;
      let internalDocumentsFiles = null;

      try {
        if (this.state.formInternalDocumentsFiles) {
          internalDocumentsFiles = await this.uploadInternalDocumentsFile(
            this.props.solicitationId,
            this.state.formInternalDocumentsFiles
          );
        }
      } catch (e) {
        console.error(e);
      }

      let response = {
        response:
          this.state.denunceData.response == undefined
            ? this.state.response
            : this.state.denunceData.response,
        responseDate: new Date(Date.now()),
        status: this.state.handleStatus !== '' ? this.state.handleStatus : this.state.status,
        conclusionDate: this.state.conclusionDate,
        done: this.state.handleStatus == "Concluído" ? true : false,
      };

      const currentUser = JSON.parse(
        sessionStorage.getItem("@blue-legal/userInformation")
      );

      responseHistory.push({
        ...response,
        ...{
          responseFile,
          internalDocumentsFiles,
          userName: currentUser.displayName,
          uid: currentUser.uid,
        },
      });

      let auxfiles = [];

      if (this.state.denunceData.files_document_response) {
        for (let i of this.state.denunceData.files_document_response) {
          auxfiles.push(i);
        }
      }
      for (let i of this.state.list_document_Response) {
        auxfiles.push(i);
      }

      console.log(auxfiles);

      this.state.denunceData.email == null 
        ? await this.state.database
            .collection("denunce")
            .doc(this.props.solicitationId)
            .update({
              done: this.state.handleStatus == "Concluído" ? true : false,
              status:this.state.handleStatus,
              verificationConclusation: this.state.verificationConclusation,
              email: this.state.email,
              company_user_relation: this.state.company_relation_user,
              cpf: this.state.Cpf,
              name: this.state.name,
              Sensitive: this.state.handleSensitive,
              responseHistory,
              ...response,
              files_document_response: auxfiles,
            }).then(() =>{
            console.log('foi esse', )

            })
        : await this.state.database
            .collection("denunce")
            .doc(this.props.solicitationId)
            .update({
              done: this.state.handleStatus == "Concluído" ? true : false,
              status:this.state.handleStatus,
              verificationConclusation: this.state.verificationConclusation,
              Sensitive: this.state.denunceData.Sensitive,
              files_document_response: auxfiles,
              response:
                this.state.denunceData.response == undefined ||  this.state.denunceData.response == "" ?
                   this.state.response
                  : this.state.denunceData.response,
              cpf: this.state.denunceData.Cpf
                ? this.state.denunceData.Cpf
                : this.state.Cpf,
              name: this.state.denunceData.name
                ? this.state.denunceData.name
                : this.state.name,
              email: this.state.denunceData.email
                ? this.state.denunceData.email
                : this.state.email,
              company_user_relation: this.state.denunceData
                .company_relation_user
                ? this.state.denunceData.company_relation_user
                : this.state.company_relation_user,
            }).then(()=>{
              console.log('agora foi o outro', this.props.solicitationId)
            });

      this.setState({
        list_document_Response: [],
      });

      if (this.state.sendEmail) {
        await this.sendMailToClient(responseFile);
      }

      await this.getSolicitationData();

      this.setState({
        loadingSend: false,
        responseFile: null,
        internalDocumentsFiles: null,
      });

      Swal.fire({
        icon: "success",
        title: "Alteração salva",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        loadingSend: false,
      });

      Swal.fire({
        icon: "error",
        title: "Erro ao salvar a solicitação",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  async sendMailToClient(responseFile) {}

  handleGoBack() {
    this.props.changePage(42);
  }

  async deleteDocument(props, index) {
    console.log(index);
    try {
      let list = this.state.list_document_Response;

      list.splice(index, 1);

      this.setState({
        list_document: list,
      });
    } catch (err) {
      console.log(err);
    }
  }

  async handleFile() {
    let aux = [];

    let aux2 = [];
    try {
      let response = await S3CustomClient.uploadFile(
        this.state.formResponseFile,
        this.state.formResponseFile.type,
        undefined,
        this.state.formResponseFile.name,
        "public-read"
      );
      // aux.push(...this.state.list_document_Response,{
      //   name: response.key ,
      //   url: response.location ,

      // }  )
      aux.push(
        {
          name: response.key,
          url: response.location,
        },
        ...this.state.list_document_Response
      );
      // aux2.push(this.state.denunceData?.files_document_response ?? [] ,);

      this.setState({
        list_document_Response: aux,
      });

      console.log("deu certo", this.state.list_document_Response);
    } catch (error) {
      console.log("erro ao subir img", error);
    }
  }

  handleviewQuest() {
    let aux = this.state.denunceData;
    this.props.setDenunceObj(aux);
    this.props.changePage(44);
  }

  mascCpf(event) {
    let doc = event.target.value;

    this.setState({
      Cpf: event.target.value,
    });
    console.log(this.state.Cpf.replace(/\D/gim, ""));
  }
  TextMaskCustomCPF = (props) => {
    const { inputRef, ...other } = props;
    return (
      <InputMask
        {...other}
        ref={inputRef}
        mask={[
          /\d/,
          /\d/,
          /\d/,
          ".",
          /\d/,
          /\d/,
          /\d/,
          ".",
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
        ]}
      />
    );
  };

  renderLoading() {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{ paddingTop: "50px" }}
      >
        <CircularProgress size={60} color={theme.secondary_color} />
      </Grid>
    );
  }

  renderForm() {
    return (
      <form
        onSubmit={(event) => {
          event.preventDefault();
          this.saveSolicitation();
          return false;
        }}
      >
        <Paper variant="outlined">
          <Grid item style={{ margin: "20px 5px" }}>
            <Grid style={{ width: "100%" }} container direction="column">
              {this.state.denunceData.response == undefined  || this.state.denunceData.response == ''? (
                <Grid style={{ width: "100%" }}>
                  <Grid container justifyContent="center">
                    <Typography variant="h6">Resposta :</Typography>
                  </Grid>
                  <TextField
                    style={{ padding: "0 10px" }}
                    multiline
                    rows={4}
                    fullWidth
                    variant="outlined"
                    color="primary"
                    InputLabelProps={{ shrink: false }}
                    value={this.state.response}
                    onChange={(event) => this.handleResponse(event)}
                    disabled={!this.state.userInformation.permissions.update}
                  />
                </Grid>
              ) : (
                <Grid>
                  <Grid container justifyContent="center">
                    <Typography variant="h6">Resposta:</Typography>
                  </Grid>
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{
                      width: "100%",
                      paddingTop: "10px",
                      color: "#808080",
                    }}
                  >
                    {this.state.denunceData.response}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Paper>

        <Grid item>
          <Grid
            container
            direction="column"
            alignItems="stretch"
            justifyContent="center"
          >
            <Grid item style={{ marginTop: "10%" }}>
              <Typography variant="h6" component="h6" style={{ width: "100%" }}>
                Documento de resposta :
              </Typography>

              {this.state.denunceData.status !== "Concluído" && (
                <Grid>
                  <InputFile
                    denunce
                    onAcceptFiles={(files) => {
                      if (files.length) this.handleResponseFile(files[0]);
                    }}
                  />

                  <ButtonBase
                    onClick={() => this.handleFile()}
                    style={{
                      background: theme.primary_color,
                      color: theme.quaternary_color,
                      padding: "15px",
                    }}
                  >
                    adicionar arquivo
                  </ButtonBase>
                </Grid>
              )}
            </Grid>

            <Grid style={{ margin: "5% 0px " }}>
              {this.state.denunceData.responsedenunce !== ""
                ? this.state.list_document_Response.map((item, index) => {
                    return (
                      <Paper
                        style={{
                          padding: "8px",
                          margin: "15px 0",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <DeleteForever
                          onClick={() => this.deleteDocument(item, index)}
                          style={{ cursor: "pointer" }}
                        />

                        <span
                          style={{
                            fontSize: "1rem",
                            color: "#72bcd4",
                          }}
                        >
                          {item.name}
                        </span>

                        <ButtonBase
                          onClick={() => window.open(item.url)}
                          style={{
                            background: theme.primary_color,
                            color: theme.secondary_color,
                            padding: "5px",
                          }}
                        >
                          {" "}
                          visualizar arquivo
                        </ButtonBase>
                      </Paper>
                    );
                  })
                : ""}

              {
                this.state.denunceData.files_document_response
                  ? this.state.denunceData.files_document_response.map(
                      (item) => {
                        return (
                          <Paper
                            style={{
                              padding: "8px",
                              margin: "15px 0",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "1rem",
                                color: "#72bcd4",
                              }}
                            >
                              {item.name}
                            </span>

                            <ButtonBase
                              onClick={() => window.open(item.url)}
                              style={{
                                background: theme.primary_color,
                                color: theme.secondary_color,
                                padding: "5px",
                              }}
                            >
                              {" "}
                              visualizar arquivo
                            </ButtonBase>
                          </Paper>
                        );
                      }
                    )
                  : ""
                //   <Grid container justifyContent="center">
                //   <Typography variant="body2">
                //     Nenhum Arquivo Fornecido
                //   </Typography>
                // </Grid>
              }
            </Grid>
          </Grid>
        </Grid>

        {this.state.denunceData.status == "Concluído" ? (
          <Paper
            style={{
              padding: "8px",
              height: "15%",
              marginBottom: "10%",
            }}
          >
            <Typography variant="h6" component="h6">
              Conclusão da apuração :{" "}
              <span>{this.state.denunceData.verificationConclusation}</span>
            </Typography>
          </Paper>
        ) : this.state.handleStatus == "Concluído" ? (
          <FormControl style={{ width: "20rem", margin: "8% 0" }} fullWidth>
            <InputLabel variant="outlined" id="demo-simple-select-conclusao">
              Conclusão da Apuração
            </InputLabel>
            <Select
              variant="outlined"
              labelId="demo-simple-select-label-conclusao"
              id="demo-simple-select-conclusao"
              label="Conclusão da Apuração"
              value={this.state.verificationConclusation}
              onChange={(e) =>
                this.setState({ verificationConclusation: e.target.value })
              }
            >
              <MenuItem value={"Procedente"}>Procedente</MenuItem>
              <MenuItem value={"Não Procedente"}>Não Procedente</MenuItem>
              <MenuItem value={"inconclusiva"}>inconclusiva</MenuItem>
            </Select>
          </FormControl>
        ) : (
          ""
        )}

        {(this.state.denunceData.email == "" &&
          this.state.handleStatus == "Concluído") ||
        (this.state.denunceData.email == undefined &&
          this.state.handleStatus == "Concluído") ? (
          <Grid item style={{ height: "auto" }}>
            <Grid
              container
              direction="column"
              alignItems="stretch"
              justifyContent="center"
            >
              <Grid item>
                <Typography
                  variant="h6"
                  component="h6"
                  style={{ width: "100%" }}
                >
                  responsável pela apuração
                </Typography>
                <Box
                  component="form"
                  style={{ display: "flex", justifyContent: "space-around" }}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  <TextField
                    value={this.state.name}
                    onChange={(e) => this.setState({ name: e.target.value })}
                    margin="normal"
                    variant="outlined"
                    required
                    id="Nome"
                    label="Nome"
                    name="Nome"
                    autoComplete="name"
                    autoFocus
                    style={{ width: "45%" }}
                  />
                  <TextField
                    value={this.state.company_relation_user}
                    onChange={(e) =>
                      this.setState({ company_relation_user: e.target.value })
                    }
                    margin="normal"
                    variant="outlined"
                    required
                    name="Relação com a empresa"
                    label="Relação com a empresa"
                    type="text"
                    id="Relação com a empresa"
                    style={{ width: "45%" }}
                  />
                </Box>

                <Box
                  component="form"
                  style={{ display: "flex", justifyContent: "space-around" }}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  <TextField
                    value={this.state.Cpf}
                    onChange={(event) => this.mascCpf(event)}
                    InputProps={{
                      inputComponent: this.TextMaskCustomCPF,
                    }}
                    margin="normal"
                    variant="outlined"
                    required
                    id="CPF"
                    label="CPF"
                    name="CPF"
                    type="text"
                    autoFocus
                    style={{ width: "45%" }}
                  />
                  <TextField
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                    margin="normal"
                    variant="outlined"
                    required
                    name="Email"
                    label="Email"
                    type="text"
                    id="Email"
                    autoComplete="email"
                    style={{ width: "45%" }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        ) : (this.state.denunceData.email !== "" &&
            this.state.handleStatus == "Concluído") ||
          (this.state.denunceData.email !== undefined &&
            this.state.denunceData.status == "Concluído") ? (
          <Grid>
            <Grid item>
              <Typography variant="h5" component="h5" style={{ width: "100%" }}>
                responsável pela apuração
              </Typography>
            </Grid>
            <Grid>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  padding: "15px",
                }}
              >
                <Typography
                  variant="h6"
                  component="h6"
                  style={{ width: "100%", color: "#555" }}
                >
                  {"email: " + this.state.denunceData.email}
                </Typography>

                <Typography
                  variant="h6"
                  component="h6"
                  style={{ width: "100%", color: "#555" }}
                >
                  {"Relação : " + this.state.denunceData.company_user_relation}
                </Typography>
              </Grid>

              <Grid
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  padding: "15px",
                }}
              >
                <Typography
                  variant="h6"
                  component="h6"
                  style={{ width: "100%", color: "#555" }}
                >
                  {"name: " + this.state.denunceData.name}
                </Typography>

                <Typography
                  variant="h6"
                  component="h6"
                  style={{ width: "100%", color: "#555" }}
                >
                  {"CPf: " + this.state.denunceData.cpf}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          ""
        )}

        {this.state.handleStatus == "Concluído" && (
          <Grid item>
            <InputLabel
              style={{
                maxWidth: "80%",
                margin: "5% 0 0 5%",
                fontSize: "18px",
                lineHeight: "20px",
              }}
            >
              Eu comprometo-me a respeitar o sigilo das informações
              confidenciais deste canal de denúncia, ou das demais informações a
              que tive acesso através deste, por minha relação de participante
              no processo de apuração de denúncias em curso. Reconheço que por
              informações confidenciais se entende qualquer projeto, ideia,
              fórmula, método, invenção, modelo de utilidade, obra artística,
              literária ou cultural, programa de computador, dado, algoritmo,
              planilha, documento ou informação ao qual eu venha a ter acesso,
              contidas e/ou transmitidas por qualquer meio, eletrônico, impresso
              ou oralmente, independentemente de aviso ou tarja advertindo
              tratar-se de informações confidenciais. Assumo, outrossim, o dever
              de manter tais informações em sigilo, guardando-as com grau de
              zelo não inferior ao que emprego para salvaguardar informações
              confidenciais das empresas para as quais trabalho. Estou ciente,
              por fim, de que a violação do sigilo ora pactuado pode acarretar
              responsabilidade pessoal, civil, criminal e/ou trabalhista, pelas
              perdas e danos, inclusive lucros cessantes e danos morais. E, para
              os devidos fins de direito, firmo a presente
            </InputLabel>
            <FormControlLabel
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              control={
                <Checkbox
                  checked={this.state.verificationCheckBox}
                  onChange={(event) =>
                    this.setState({
                      verificationCheckBox: event.target.checked,
                    })
                  }
                  name="send-email"
                />
              }
              label="eu aceito"
            />
          </Grid>
        )}

        <Grid
          item
          style={
            this.state.denunceData.email == null
              ? { paddingTop: "5rem", paddingBottom: "25rem" }
              : { paddingTop: "5rem", paddingBottom: "25rem" }
          }
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              {this.state.denunceData.status != "Concluído" && (
                <Button
                  variant="contained"
                  style={
                    this.state.handleStatus !== ""
                      ? {
                          background: !this.state.userInformation.permissions
                            .update
                            ? theme.opacity
                            : theme.primary_color,
                          color: theme.secondary_color,
                          fontSize: "20px",
                        }
                      : {
                          background: theme.opacity,
                          color: theme.secondary_color,
                          fontSize: "20px",
                        }
                  }
                  type="submit"
                  disabled={this.state.handleStatus == ""}
                  startIcon={!this.state.loadingSend && <SaveTwoTone />}
                >
                  {this.state.loadingSend ? <CircularProgress /> : "Salvar"}
                </Button>
              )}
            </Grid>
            <Grid item>
              {this.state.denunceData.status == "Concluído" && (
                <Button
                  variant="contained"
                  style={{
                    background: !this.state.userInformation.permissions.update
                      ? theme.opacity
                      : theme.primary_color,
                    color: theme.secondary_color,
                    fontSize: "20px",
                  }}
                  onClick={() => this.handleviewQuest(this.state.denunceData)}
                  startIcon={<PictureAsPdfIcon />}
                >
                  PDF
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }

  render() {
    return (
      <div style={{ paddingBottom: "20px" }}>
        <Title>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Button
                variant="text"
                style={{
                  fontSize: "15px",
                }}
                onClick={() => {
                  this.handleGoBack();
                }}
              >
                <ArrowBackIosTwoTone />
              </Button>
            </Grid>
            <Grid item>Detalhes da denúncia</Grid>
          </Grid>
        </Title>
        {this.state.loading ? (
          this.renderLoading()
        ) : (
          <>
            <Card style={{ marginTop: 10 }}>
              <CardContent>
                <Grid
                  container
                  direction="column"
                  alignItems="stretch"
                  justifyContent="center"
                >
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      spacing={3}
                    >
                      <Grid item>
                        <Typography
                          variant="h4"
                          component="h4"
                          style={{ width: "100%", marginBottom: "20px" }}
                        >
                           n° de protocolo : {this.state.denunceData.protocol}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      spacing={3}
                    >
                      <Grid item>
                        <Grid container direction="column" alignItems="center">
                          <Grid item>
                            <Typography variant="h6" component="h6">
                              Tipo de denunciante
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="body1"
                              component="p"
                              color="textSecondary"
                            >
                              {this.state.denunceData.company_relation}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item>
                        <Grid container direction="column" alignItems="center">
                          <Grid item>
                            <Typography variant="h6" component="h6">
                              Tipo de denúncia
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="body1"
                              component="p"
                              color="textSecondary"
                            >
                              {this.state.denunceData.relate_Type}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item>
                        <Grid container direction="column" alignItems="center">
                          <Grid item>
                            <Typography variant="h6" component="h6">
                              Data de criação
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="body1"
                              component="p"
                              color="textSecondary"
                            >
                              {Intl.DateTimeFormat("pt-br").format(
                                this.state.denunceData.date
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item>
                        <Grid container direction="column" alignItems="center">
                          <Grid item>
                            <Typography variant="h6" component="h6">
                              Data prevista
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="body1"
                              component="p"
                              color="textSecondary"
                            >
                              {this.state.denunceDataParams.map(
                                (item) => item.expectedDate
                              ) == ""
                                ? "15 Dias"
                                : this.state.denunceData.Sensitive ==
                                  "Muito sensível"
                                ? this.state.denunceDataParams.map(
                                    (item) => item.expectedDAteSensive
                                  ) + " Dias"
                                : this.state.denunceDataParams.map(
                                    (item) => item.expectedDate
                                  ) + " Dias"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={3}
                      >
                        {this.state.documentProofFile != null && (
                          <Grid item>
                            <Grid
                              container
                              direction="column"
                              alignItems="center"
                            >
                              <Grid item>
                                <Typography variant="h6" component="h6">
                                  Comprovante
                                </Typography>
                              </Grid>
                              <Grid item>
                                <a
                                  href={this.state.documentProofFile.fileUrl}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <Typography
                                    variant="body1"
                                    component="p"
                                    color="textSecondary"
                                  >
                                    Anexo
                                  </Typography>
                                </a>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}

                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                          >
                            <Grid item>
                              <Typography
                                variant="body1"
                                component="p"
                                color="textSecondary"
                              >
                                {this.state.birthDate ?? "Não informado"}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={3}
                      >
                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                          >
                            <Grid item>
                              <Typography variant="h6" component="h6">
                                Status
                              </Typography>
                            </Grid>
                            {this.state.denunceData.status !== "Concluído" && (
                              <Grid item>
                                <Typography
                                  variant="body1"
                                  component="p"
                                  color="textSecondary"
                                >
                                  {this.state.denunceData.status}
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>

                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                          >
                            {this.state.denunceData.status !== "Concluído" ? (
                              <Grid item>
                                <FormControl
                                  style={{ width: "20rem" }}
                                  fullWidth
                                >
                                  <InputLabel
                                    variant="outlined"
                                    id="demo-simple-select-label"
                                  >
                                    Selecione o status
                                  </InputLabel>
                                  <Select
                                    variant="outlined"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Selecione o status"
                                    value={this.state.handleStatus}
                                    onChange={(e) =>
                                      this.setState({
                                        handleStatus: e.target.value,
                                      })
                                    }
                                  >
                                    <MenuItem value={"Aberto"}>Aberto</MenuItem>
                                    <MenuItem value={"Em andamento"}>
                                      Em andamento
                                    </MenuItem>
                                    <MenuItem value={"Concluído"}>
                                      Concluído
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                            ) : (
                              <Typography
                                variant="body1"
                                component="p"
                                color="textSecondary"
                              >
                                {"" + this.state.denunceData.status}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>

                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                          >
                            <Grid item>
                              <Typography variant="h6" component="h6">
                                Sensibilidade
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item>
                          {this.state.denunceData.Sensitive == undefined ? (
                            <Grid
                              container
                              direction="column"
                              alignItems="center"
                            >
                              <Grid item>
                                <FormControl
                                  style={{ width: "20rem" }}
                                  fullWidth
                                >
                                  <InputLabel
                                    variant="outlined"
                                    id="demo-simple-select-label"
                                  >
                                    Selecione o grau de sensibilidade
                                  </InputLabel>
                                  <Select
                                    variant="outlined"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Selecione o grau de sensibilidade"
                                    value={this.state.handleSensitive}
                                    onChange={(e) =>
                                      this.setState({
                                        handleSensitive: e.target.value,
                                      })
                                    }
                                  >
                                    <MenuItem value={"sensível"}>
                                      sensível
                                    </MenuItem>
                                    <MenuItem value={"Muito sensível"}>
                                      Muito sensível
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>
                          ) : (
                            <Typography
                              variant="body1"
                              component="p"
                              color="textSecondary"
                            >
                              : {"" + this.state.denunceData.Sensitive}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item style={{ margin: "20px 0" }}>
                    <Grid
                      container
                      direction="column"
                      alignItems="stretch"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Typography variant="h6" component="h6">
                          Histórico
                        </Typography>
                        <Grid item style={{ padding: "0 10px" }}>
                          <Container
                            maxWidth="xl"
                            style={{
                              backgroundColor: grey[100],
                              overflow: "scroll",
                              maxHeight: "400px",
                            }}
                          >
                            <div ref="timeline">
                              <DenunceTimeLine data={this.state.denunceData} />
                            </div>
                          </Container>
                        </Grid>
                      </Grid>

                      <Paper variant="outlined">
                        <Grid style={{ marginTop: "20px" }}>
                          <Grid container justifyContent="center">
                            <Typography variant="h6">
                              Texto da denúncia :
                            </Typography>
                          </Grid>

                          <Grid>
                            <Typography
                              variant="h6"
                              component="h6"
                              style={{ padding: "10px", color: "#808080" }}
                            >
                              {this.state.denunceData.additionalInformation}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>

                      <Grid style={{ margin: "50px 0" }}>
                        <Typography
                          variant="h6"
                          component="h6"
                          style={{ margin: "2% 0px " }}
                        >
                          Anexos da denúncia :
                        </Typography>

                        <Grid style={{ margin: "5% 0px " }}>
                          {this.state.denunceData.responsedenunce.length !==
                          0 ? (
                            this.state.denunceData.responsedenunce.map(
                              (item) => {
                                return (
                                  <Paper
                                    style={{
                                      padding: "8px",
                                      margin: "15px 0",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "1rem",
                                        color: "#72bcd4",
                                      }}
                                    >
                                      {item.name}
                                    </span>

                                    <ButtonBase
                                      onClick={() => window.open(item.url)}
                                      style={{
                                        background: theme.primary_color,
                                        color: theme.secondary_color,
                                        padding: "5px",
                                      }}
                                    >
                                      {" "}
                                      vizualizar arquivo
                                    </ButtonBase>
                                  </Paper>
                                );
                              }
                            )
                          ) : (
                            <Grid container justifyContent="center">
                              <Typography variant="body2">
                                Nenhum arquivo fornecido
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {!this.state.done && (
                    <Zoom
                      in={!this.state.done}
                      style={{
                        transitionDelay: !this.state.done ? "500ms" : "0ms",
                      }}
                    >
                      {this.renderForm()}
                    </Zoom>
                  )}
                </Grid>
              </CardContent>
            </Card>
            <br />

            <Dialog open={this.state.openDialog}>
              <DialogTitle
                style={{
                  color: theme.quaternary_color,
                  backgroundColor: theme.secondary_color,
                  textAlign: "center",
                }}
              >
                Aviso!
              </DialogTitle>
              <DialogContent>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid
                    item
                    textAlign="center"
                    direction="row"
                    container
                    justifyContent="space-between"
                  >
                    <DialogContentText>
                      <Typography variant="h5">
                        Você não poderá mais editar nenhum campo tem certeza que
                        quer continuar ?
                      </Typography>
                    </DialogContentText>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button
                  onClick={() => {
                    this.saveSolicitation();
                    this.setState({ acept: true, openDialog: false });
                  }}
                  style={{
                    backgroundColor: theme.primary_color,
                  }}
                >
                  Aceitar
                </Button>

                <Button
                  onClick={() => this.setState({ openDialog: false })}
                  style={{
                    backgroundColor: theme.warning_color,
                  }}
                >
                  Cancelar
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  solicitationId: state.solicitationReducer.solicitationId,
});

// const mapDispatchToProps = (dispatch) => ({
//   changePage: (page) => dispatch(changePage(page)),
//   setSolicitationId: (solicitationId) =>
//     dispatch(setSolicitationId(solicitationId)),
// });

export default connect(mapStateToProps, {
  changePage,
  setDenunceObj,
})(DenunceDetails);
