import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  TextField,
  Card,
  ButtonBase,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import theme from "../../../../core/theme.json";
import Title from "../../../../shared/components/title";
import myApp from '../../../../core/infrastructure/firebaseConfig';
import ArrowBackIosTwoToneIcon from "@material-ui/icons/ArrowBackIosTwoTone";
import { useDispatch } from "react-redux";
import { changePage } from '../redux/actions';
import DeleteIcon from '@material-ui/icons/Delete';
import ReactPlayer from "react-player";
import { connect } from "react-redux";
import TvOffIcon from '@material-ui/icons/TvOff';
import EditIcon from '@material-ui/icons/Edit';
import "./style.css";

const Movies = (props) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState('Carregando...');
  const [loading, setLoading] = useState({
    getVideos: true,
  });
  const [videos, setVideos] = useState([]);
  const [newVideo, setNewVideo] = useState({
    name: '',
    url: '',
  });

  const [dialogs, setDialogs] = useState({
    editVideo: false,
  });

  const handleCloseDialog = (item) => (value) => {
    setDialogs({
      ...dialogs,
      [item]: false
    });
  }

  const [editVideoSelected, setEditVideoSelected] = useState({
    name: '',
    url: '',
    index: 0,
  });

  useEffect(() => {
    montingPage();
  }, []);

  const montingPage = async () => {
    await getModuleVideos();
  }

  const getModuleVideos = async () => {
    let aux = [];
    try {
      let get = await myApp.firestore().collection('moduleName').doc(props.idModuleName).get();
      setTitle(get.data() === undefined ? 'Módulo não encontrado' : get.data().name);
      if (get.data() !== undefined) {
        for (let item of get.data().videos) {
          aux.push(item);
        }
      }
      console.log(get.data())
    } catch (e) { console.error(e) }

    console.log('Lista', aux)
    setVideos(aux);
    setLoading({
      ...loading,
      getVideos: false,
    });
  }

  const handleAddNewVideo = () => {
    let newVideos = [];
    let tempId = myApp.database().ref().push().key
    newVideos = videos;
    newVideos.push({
      questId: Math.random(1005500 * 100).toString(16).slice(2),
      videoId: tempId,
      name: newVideo.name,
      order: (videos.length + 1).toString(),
      url: newVideo.url,
    });

    myApp.firestore().collection('moduleName').doc(props.idModuleName).update({
      videos: newVideos
    }).then(() => {
      setNewVideo({
        name: '',
        url: ''
      });
      getModuleVideos();
      console.log('Video adicionado')
    });
  }

  const handleSetNewVideo = (item) => (event) => {
    setNewVideo({
      ...newVideo,
      [item]: event.target.value,
    });
  }

  const handleEditSelectedVideo = (item, index) => {
    console.log(item);
    setEditVideoSelected({
      name: item.name,
      url: item.url,
      index,
    });
    setDialogs({
      ...dialogs,
      editVideo: true
    });
  }

  const handleEditVideo = (item) => (event) => {
    setEditVideoSelected({
      ...editVideoSelected,
      [item]: event.target.value,
    });
  }

  const handleDeleteSelectedVideo = (item, index) => {
    console.log('Video', item);
    let nlv = videos;
    nlv.splice(index, 1);
    console.log(nlv);
    myApp.firestore().collection('moduleName').doc(props.idModuleName).update({
      videos: nlv
    }).then(() => {
      getModuleVideos();
      console.log('Video Removido');
    });
  }

  return (
    <Grid
      container
      direction='column'
    >
      <Title>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Button
              variant="text"
              style={{
                fontSize: '15px',
              }}
              onClick={() => {
                dispatch(changePage(35));
              }}
            >
              <ArrowBackIosTwoToneIcon />
            </Button>
          </Grid>
          <Grid item>{title}</Grid>
        </Grid>
      </Title>
      <Grid
        container
        justify="flex-start"
        alignItems="center"
        spacing={4}
        style={{ marginTop: 5 }}
      >
        <Grid item style={{ width: "100%" }}>
          <Card style={{ padding: "16px" }}>
            <Grid
              container
              justifyContent='space-between'
              alignItems='center'
              spacing={2}
            >
              <Grid
                item
                xs={3}
                sm={3}
              >
                <TextField
                  type="text"
                  fullWidth
                  label="Nome"
                  variant="outlined"
                  value={newVideo.name}
                  onChange={handleSetNewVideo('name')}
                />
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
              >
                <TextField
                  type="text"
                  fullWidth
                  label="URL"
                  variant="outlined"
                  value={newVideo.url}
                  onChange={handleSetNewVideo('url')}
                />
              </Grid>
              <Grid
                item
                xs={3}
                sm={3}
              >
                <Button
                  fullWidth
                  variant="contained"
                  style={{
                    backgroundColor: theme.primary_color,
                    color: theme.secondary_color,
                    fontWeight: 'bold',
                  }}
                  onClick={handleAddNewVideo}
                >
                  Adicionar
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        style={{
          marginTop: 10
        }}
      >
        {
          videos.length > 0 ?
            videos.map((item, index) => (
              <Grid
                key={index}
                item
                xs={12} sm={12} md={4} lg={4} xl={4}
                style={{ marginTop: 5 }}
              >
                <Paper
                  elevation={5}
                  style={{ width: '100%', height: '100%' }}
                >
                  <Grid
                    container
                    item
                    direction="column"
                    justify="center"
                    alignItems="center"
                    style={{
                      height: '100%',
                    }}
                  >
                    <Grid
                      item
                      style={{ textAlign: 'center', padding: 10 }}
                    >
                      <span
                        style={{
                          fontSize: '22px',
                          color: theme.secondary_color,
                          margin: '10px',
                        }}
                      >
                        {item.name}
                      </span>
                    </Grid>
                    <Grid item>
                      <ReactPlayer
                        url={item.url}
                        width="100%"
                        height="100%"
                        controls
                      />
                    </Grid>
                    <Grid item>
                      <div style={{ height: 50 }} />
                      <EditIcon
                        onClick={() => handleEditSelectedVideo(item, index)}
                        style={{
                          fontSize: 30,
                          color: theme.secondary_color,
                          cursor: 'pointer',
                          margin: 15,
                        }}
                      />
                      <DeleteIcon
                        onClick={() => handleDeleteSelectedVideo(item, index)}
                        style={{
                          fontSize: 30,
                          color: theme.secondary_color,
                          cursor: 'pointer',
                          margin: 15,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))
            :
            <Grid
              item
              alignItems="flex-start"
              direction="column"
              xs={12} sm={12} md={3} lg={3} xl={3}
            >
              <ButtonBase
                style={{ margin: '10px', width: '100%', height: '250px', }}
                disabled={true}
              >
                <Paper
                  elevation={5}
                  style={{ width: '100%', height: '100%', backgroundColor: '#ccc' }}
                >
                  <Grid
                    container
                    item
                    direction="column"
                    justify="center"
                    alignItems="center"
                    style={{
                      height: '100%',
                    }}
                  >
                    <TvOffIcon style={{ fontSize: 75, color: theme.secondary_color }} />
                    <span
                      style={{
                        fontSize: '22px',
                        color: theme.secondary_color,
                        margin: '10px'
                      }}
                    >
                      Sem vídeos cadastrados...
                    </span>
                  </Grid>
                </Paper>
              </ButtonBase>
            </Grid>
        }
      </Grid>

      <Dialog
        fullWidth
        open={dialogs.editVideo}
        onClose={handleCloseDialog('editVideo')}
      >
        <DialogTitle
        >
          Editando vídeo <span style={{ color: '#f00' }}>{editVideoSelected.name}</span>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
          >
            <Grid
              item
              xs={6}
              sm={6}
            >
              <TextField
                type="text"
                fullWidth
                label="Nome"
                variant="outlined"
                value={editVideoSelected.name}
                onChange={handleEditVideo('name')}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
            >
              <TextField
                type="text"
                fullWidth
                label="URL"
                variant="outlined"
                value={editVideoSelected.url}
                onChange={handleEditVideo('url')}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              backgroundColor: '#f00',
              color: '#fff'
            }}
            onClick={handleCloseDialog('editVideo')}
            autoFocus
          >
            Cancelar
          </Button>
          <Button
            style={{
              backgroundColor: theme.primary_color,
              color: theme.secondary_color
            }}
            onClick={() => {
              console.log(editVideoSelected);
              let nlv = videos;
              nlv[editVideoSelected.index].name = editVideoSelected.name;
              nlv[editVideoSelected.index].url = editVideoSelected.url;
              myApp.firestore().collection('moduleName').doc(props.idModuleName).update({
                videos: nlv
              }).then(() => {
                getModuleVideos();
                setDialogs({
                  ...dialogs,
                  editVideo: false
                });
                setEditVideoSelected({
                  name: '',
                  url: '',
                  index: 0
                });
              });
            }}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Grid >
  );
};

const mapStateToProps = (state) => {
  return {
    idModuleName: state.parameterizationReducer.idModuleName
  };
}

export default connect(mapStateToProps, {})(Movies);