import React, { Component } from 'react';
import myApp from '../../../../core/infrastructure/firebaseConfig';
import "firebase/compat/firestore";

import {
  Paper,
  Grid,
  Typography,
  ButtonBase,
  Dialog,
  DialogContent,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import { Close } from '@material-ui/icons';

import { connect } from 'react-redux';

import { changePage } from '../../../presentation/redux/actions';
import { setArea } from '../redux/actions';
import areaIconList from '../../../../shared/assets/areaIconList';
import theme from '../../../../core/theme.json';

class NewArea extends Component {
  constructor(props) {
    super(props);

    this.db = myApp.firestore();

    this.state = {
      dialog: false,

      name: "",
      owner: "",
      icon: "finances"
    }
  }

  handleDialog() {
    this.setState({
      dialog: !this.state.dialog,
    })
  }

  handleName(event) {
    this.setState({
      name: event.target.value
    })
  }

  handleOwner(event) {
    this.setState({
      owner: event.target.value
    })
  }


  handleIcon(event) {
    this.setState({
      icon: event.target.value
    })
  }

  async addArea() {
    let database = myApp.firestore();
    let _companyId = localStorage.getItem('currentCompany');

    try {
      var area = await database.collection('areas').add(
        {
          name: this.state.name,
          owner: this.state.owner,
          icon: this.state.icon,
          fk_companies: _companyId,
          active: true,
        }
      );

      this.props.setArea(area.id);
      this.props.changePage(1);
    }
    catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <div>
        <Grid
          container
          direction="row"
          spacing={4}
          style={{ height: "320px" }}
        >
          <Grid item style={{ width: "100%", height: "100%" }}>
            <ButtonBase onClick={() => { this.handleDialog() }} style={{ width: "100%", height: "100%", borderRadius: "8px" }}>
              <Paper style={{ width: "100%", height: "100%", borderRadius: "8px" }}>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                  style={{ width: "100%", height: "100%" }}
                >
                  <Grid item container justify="center" alignItems="center" style={{ width: "100%", height: "50%" }}>
                    <Grid item>
                      <AddIcon style={{ fontSize: 76, color: "#6A6A6A" }} />
                    </Grid>
                  </Grid>
                  <Grid item container justify="center" style={{ width: "100%", height: "50%" }}>
                    <Grid
                      item
                      container
                      justify="center"
                      alignItems="center"
                      style={{ width: "100%", height: "90%", overflowY: "hidden", textOverflow: "ellipsis", margin: "0 10px 0 10px" }}
                    >
                      <Typography variant="h6" component="h6" style={{ color: "#6A6A6A", overflowWrap: "anywhere" }}>
                        Adicionar nova Área
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </ButtonBase>
          </Grid>
        </Grid>

        <Dialog
          open={this.state.dialog}
          fullWidth={true}
          maxWidth={"md"}
          onClose={() => { this.handleDialog() }}
        >
          <DialogContent>
            <Grid
              container
              direction="column"
              justify="center"
            >
              <Grid item container direction="row">
                <Grid item style={{ width: "100%" }} xs={10}>
                  <Typography variant="h5" component="h5" >
                    Nova Área
                  </Typography>
                </Grid>
                <Grid item container xs={2} justify="flex-end">
                  <IconButton onClick={() => this.handleDialog()}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid style={{ height: "24px" }} />
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={4}
              >
                <Grid item style={{ width: "100%" }} xs={8}>
                  <TextField
                    inputProps={{
                      maxLength: 50,
                    }}
                    label="Nome da Área"
                    variant="outlined"
                    style={{ width: "100%" }}
                    color={theme.secondary_color}
                    value={this.state.name}
                    onChange={(event) => { this.handleName(event) }}
                  />
                </Grid>
                <Grid item style={{ width: "100%" }} xs={4}>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <InputLabel>
                      Ícone
                    </InputLabel>
                    <Select
                      value={this.state.icon}
                      onChange={(event) => { this.handleIcon(event) }}
                      label="Ícone"
                    >
                      {areaIconList.map((icon) => (
                        <MenuItem value={icon.value}>
                          <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            spacing={2}
                            style={{ marginLeft: "4px" }}
                          >
                            <Grid item style={{ padding: "0px" }}>
                              <icon.icon />
                            </Grid>
                            <Grid item>
                              {icon.name}
                            </Grid>
                          </Grid>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item style={{ width: "100%", marginTop: "20px" }} xs={8}>
                <TextField
                  inputProps={{
                    maxLength: 50,
                  }}
                  label="Responsável"
                  variant="outlined"
                  style={{ width: "100%" }}
                  color={theme.secondary_color}
                  value={this.state.owner}
                  onChange={(event) => { this.handleOwner(event) }}
                />
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item style={{ flexGrow: 1 }} />
                  <Grid item style={{ width: "100%" }} xs={2}>
                    <Button
                      variant="contained"
                      style={{ background: this.state.name.length === 0 ? "#687394" : "#193993", color: "white", width: "100%", height: "56px" }}
                      onClick={() => { this.addArea() }}
                      disabled={this.state.name.length === 0}
                    >
                      Adicionar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid style={{ height: "16px" }} />
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, { changePage, setArea })(NewArea);