import { combineReducers } from "redux";
import companyReducer from "../../../company/redux/companyReducer";
import homeReducer from "../../../dashboard/features/home/redux/homeReducer";
import dashboardReducer from "../../../dashboard/presentation/redux/dashboardReducer";
import areasReducer from '../../../dashboard/features/areas/redux/areasReducer';
import plansReducer from '../../../dashboard/features/areas/redux/areasReducer';
import quizReducer from '../../../dashboard/features/quiz/redux/quizReducer';
import processReducer from '../../../dashboard/features/process/redux/processReducer';
import viewQuestionnaireReducer from '../../../dashboard/features/quizAnswerReports/redux/viewQuestionnaireReducer';
import solicitationReducer from "../../../solicitationChannel/redux/solicitationReducer";
import parameterizationReducer from '../../../dashboard/features/parameterization/redux/parameterizationReducer';
import newEtapasEPMReducer from '../../../dashboard/features/epmSteps/redux/newEtapasEPMReducer';
import incidentesReducer from "../../../dashboard/features/incidentes/redux/incidestesReducer";
import ViewsPdfDenunceReducer from "../../../dashboard/features/denuncias/redux/ViewsPdfDenunceReducer";
import ViewsPdfReportVideoReducer from '../../../dashboard/features/videoReports/Redux/ViewsPdfReportVideoReducer'

export default combineReducers({ 
  homeReducer: homeReducer,
  companyReducer: companyReducer,
  dashboardReducer: dashboardReducer,
  areasReducer: areasReducer,
  plansReducer: plansReducer,
  quizReducer: quizReducer,
  processReducer: processReducer,
  questionnaireReducer: viewQuestionnaireReducer,
  solicitationReducer: solicitationReducer,
  parameterizationReducer: parameterizationReducer,
  newEtapasEPMReducer: newEtapasEPMReducer,
  incidentesReducer: incidentesReducer,
  ViewsPdfDenunceReducer:ViewsPdfDenunceReducer,
  ViewsPdfReportVideoReducer:ViewsPdfReportVideoReducer,
});