//imports react
import React, { useState, useEffect } from 'react';

//imports material
import {
  Grid,
  ButtonBase,
  Paper,
  Switch
} from "@material-ui/core";
import SettingsIcon from '@material-ui/icons/Settings';

//import do tema
import theme from "../../../core/theme.json";

//imports redux
import { useDispatch } from "react-redux";
import { changePage } from "../redux/actions";

const ListBasis = () => {
  const dispatch = useDispatch();

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        alignItems="flex-start"
        direction="column"
        xs={12} sm={12} md={4} lg={4} xl={4}
      >
        <ButtonBase
          onClick={() => dispatch(changePage(31))}
          style={{ margin: '10px', width: '100%', height: '250px' }}
        >
          <Paper
            elevation={5}
            style={{ width: '100%', height: '100%' }}
          >
            <Grid
              container
              item
              direction="column"
              justify="center"
              alignItems="center"
              style={{
                height: '100%',
              }}
            >
              <SettingsIcon fontSize="large" />
              <span
                style={{
                  fontSize: '22px',
                  color: '#909090',
                  margin: '10px'
                }}
              >
                Base legal
              </span>
            </Grid>
          </Paper>
        </ButtonBase>
      </Grid>

      <Grid
        item
        alignItems="flex-start"
        direction="column"
        xs={12} sm={12} md={4} lg={4} xl={4}
      >
        <ButtonBase
          onClick={() => dispatch(changePage(32))}
          style={{ margin: '10px', width: '100%', height: '250px' }}
        >
          <Paper
            elevation={5}
            style={{ width: '100%', height: '100%' }}
          >
            <Grid
              container
              item
              direction="column"
              justify="center"
              alignItems="center"
              style={{
                height: '100%',
              }}
            >
              <SettingsIcon fontSize="large" />
              <span
                style={{
                  fontSize: '22px',
                  color: '#909090',
                  margin: '10px'
                }}
              >
                Legislação
              </span>
            </Grid>
          </Paper>
        </ButtonBase>
      </Grid>
    </Grid>
  );
}

export default ListBasis;