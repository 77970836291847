import React, { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { getCompanyInfo } from "./functions";

const ReportPDF = ({ process = {}, questions }) => {
  const [companyInfo, setCompanyInfo] = useState({});

  useEffect(() => {
    async function loadCompanyInfo() {
      const companyInfo = await getCompanyInfo(process.fk_areas);

      setCompanyInfo(companyInfo);
    }

    loadCompanyInfo();
  }, [process.fk_areas]);

  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "#FFF",
      padding: "10%",
    },
    cover: {
      flexDirection: "column",
      backgroundColor: "#000",
      color: "#FFF",
    },
    processInfoContainer: {
      flexDirection: "row",
      margin: "4% 0 0 12%",
      width: "68%",
    },
    processInfoTitle: {
      fontWeight: "bold",
      marginRight: "4px",
      fontSize: "15px",
      color: "#FAFAFA",
      letterSpacing: "1.5px",
    },
    processInfo: {
      fontSize: "15px",
      color: "#FAFAFA",
    },
    companyInfo: {
      fontSize: "22px",
      color: "#b2b2b2",
      marginLeft: "5px",
    },
    companyInfoData: {
      fontSize: "22px",
      color: "#FAFAFA",
      fontWeight: "bold",
      marginLeft: "5px",
    },
    infoContainer: {
      flexDirection: "row",
      marginBottom: "1%",
    },
  });

  const renderQuestion = useCallback(
    (question) => {
      if (!process.answers) return;
      const answer = process.answers[question.questionOrder - 1];

      return (
        <View>
          <View style={{ marginTop: "4%" }}>
            <Text style={{ fontSize: "18px", color: "#202020" }}>
              <Text style={{ fontSize: "20px", fontWeight: "bold" }}>
                {question.questionOrder}.{" "}
              </Text>
              {question.text}
            </Text>
          </View>

          <View style={{ margin: "10px 20px" }}>
            {typeof answer === "boolean" ? (
              <Text style={{ fontSize: "15px", color: "#606060" }}>
                {answer ? "Sim" : "Não"}
              </Text>
            ) : (
              <Text style={{ fontSize: "15px", color: "#606060" }}>
                {answer}
              </Text>
            )}
          </View>
        </View>
      );
    },
    [process.answers]
  );

  const renderAnswerPages = useCallback(() => {
    return (
      <Page size="A4" style={styles.page} wrap>
        {questions.map(renderQuestion)}
      </Page>
    );
  }, [questions, renderQuestion, styles.page]);

  return (
    <Document>
      {/* cover */}
      <Page size="A4" style={styles.cover} wrap>
        <View style={{ margin: "8% 40% 0 12%" }}>
          <Text style={{ color: "#FAFAFA", fontSize: "13px" }}>
            {format(new Date(), "dd 'de' MMMM 'de' yyyy", { locale: ptBR })}
          </Text>
        </View>
        <View style={{ marginTop: "auto" }}>
          <View style={{ margin: "8% 0 0 12%", width: "60%" }}>
            <Text style={{ fontSize: "38px", color: "#FAFAFA" }}>
              RELATÓRIO
            </Text>
            <View style={{ margin: "4% 0" }}>
              <Text style={{ fontSize: "24px", color: "#FAFAFA" }}>
                LEGÍTIMO INTERESSE
              </Text>
            </View>
            <View style={{ margin: "4% 0" }}>
              <Text style={{ fontSize: "20px", color: "#FAFAFA" }}>
                ART. 7, INCISO IX
              </Text>
            </View>
            <View style={{ margin: "4% 0" }}>
              <Text style={{ fontSize: "20px", color: "#FAFAFA" }}>
                {companyInfo.nickname}
              </Text>
            </View>
          </View>
        </View>
        <View style={{ marginTop: "auto", marginBottom: "20%" }}>
          <View style={styles.processInfoContainer}>
            <Text style={styles.processInfoTitle}>Processo:</Text>
            <Text style={styles.processInfo}>{process.name}</Text>
          </View>
          <View style={styles.processInfoContainer}>
            <Text style={styles.processInfoTitle}>Finalidade:</Text>
            <Text style={styles.processInfo}>{process.goal}</Text>
          </View>
          <View style={styles.processInfoContainer}>
            <Text style={styles.processInfoTitle}>Responsável:</Text>
            <Text style={styles.processInfo}>{process.owner}</Text>
          </View>
        </View>
      </Page>

      {/* page 1 */}
      <Page size="A4" style={styles.cover} wrap>
        <View style={{ margin: "8% 12% 0 12%" }}>
          <View style={{ margin: "4% 0" }}>
            <Text style={{ fontSize: "20px", color: "#FAFAFA" }}>
              ART. 7, INCISO IX
            </Text>
          </View>
          <View style={{ margin: "4% 0" }}>
            <Text style={{ fontSize: "18px", color: "#FAFAFA" }}>
              LEGÍTIMO INTERESSE
            </Text>
          </View>

          <View style={{ marginTop: "25px" }}>
            <Text style={styles.processInfo}>
              O legítimo interesse do controlador somente poderá fundamentar
              tratamento de dados pessoais para finalidades legítimas,
              consideradas a partir de situações concretas, que incluem, mas não
              se limitam a:
            </Text>
          </View>

          <View style={{ marginTop: "25px" }}>
            <Text style={styles.processInfo}>
              I – Apoio e promoção de atividades do controlador; e
            </Text>
          </View>
          <View style={{ marginTop: "25px" }}>
            <Text style={styles.processInfo}>
              II – Proteção, em relação ao titular, do exercício regular de
              seus direitos ou prestação de serviços que o beneficiem,
              respeitadas as legítimas expectativas dele e
              os direitos e liberdades fundamentais, nos termos desta Lei.
            </Text>
          </View>
          <View style={{ marginTop: "25px" }}>
            <Text style={styles.processInfo}>
              § 1º Quando o tratamento for baseado no legítimo interesse do
              controlador, somente os dados pessoais estritamente
              necessários para a finalidade pretendida poderão ser tratados.
            </Text>
          </View>
          <View style={{ marginTop: "25px" }}>
            <Text style={styles.processInfo}>
              § 2º O controlador deverá adotar medidas para garantir a
              transparência do tratamento de dados baseado em seu legítimo
              interesse.
            </Text>
          </View>
          <View style={{ marginTop: "25px" }}>
            <Text italic style={styles.processInfo}>
              § 3º A autoridade nacional poderá solicitar ao
              controlador relatório de impacto à proteção de dados pessoais,
              quando o tratamento tiver como fundamento seu interesse legítimo,
              observados os segredos comercial e industrial.
            </Text>
          </View>
        </View>
      </Page>

      {renderAnswerPages()}
    </Document>
  );
};

export default ReportPDF;
