import { CHANGE_PAGE } from "./types";

const INITIAL_STATE = {
  page: 0
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_PAGE:
      return {
        ...state,
        page: action.data,
      }
    default:
      return state;
  }
}