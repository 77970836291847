import myApp from "../../core/infrastructure/firebaseConfig";
import "firebase/compat/functions";

const sendEmail = myApp.functions().httpsCallable('sendEmail');

export async function sendMailToNewUser(username, email, password) {
	try {
		let requestObject = {
			from: `${process.env.REACT_APP_FROM_NAME} <${process.env.REACT_APP_FROM_EMAIL}>`,
			email: email,
			name: username,
			subject: "Usuário Beeprivacy",
			message:
				`Olá, ${username}!<br><br>` +
				`Um novo cadastro foi criado na plataforma Beeprivacy para seu e-mail. Segue dados de acesso:<br><br>` +
				`Login: ${email}<br>` +
				`Senha: ${password}<br><br>` +
				`No seu primeiro acesso, será necessário redefinir a senha.<br><br>` +
				`Acesse https://apps.beeprivacy.com.br${process.env.PUBLIC_URL}/<br><br>` +
				`<b>Não responda a esta mensagem. E-mails enviados para este endereço não podem ser respondidos.</b>`,
		}

		await sendEmail(requestObject);
	} catch (error) {
		console.error("Error sending mail to new user:", error);
	}
}