import React, { Component } from 'react';
import myApp from '../../core/infrastructure/firebaseConfig';
import "firebase/compat/firestore";

import { Paper, Grid, Typography, ButtonBase, Tooltip, Card, CardActionArea } from '@material-ui/core';
import routerHistory from '../../core/infrastructure/routerHistory';

import theme from '../../core/theme.json'


class CompanyItem extends Component {
  constructor(props) {
    super(props);

    this.db = myApp.firestore();

    this.state = {

    }
  }

  setCompany() {
    localStorage.setItem('currentCompany', this.props.id);
    routerHistory.push("/");
  }

  render() {
    return (
      <Grid
        container
        direction="row"
        spacing={4}
        style={{ height: "180px" }}
      >
        <Grid item style={{ width: "100%", height: "100%" }}>
          <ButtonBase onClick={() => { this.setCompany() }} style={{ width: "100%", height: "100%", borderRadius: "8px" }}>
            <Card style={{ width: "100%", height: "100%", borderRadius: "8px", boxShadow: "0 8px 10px -6px rgba(0,0,0,0.3)",
          "&:hover": {
            boxShadow: "0 6px 20px -6.125px rgba(0,0,0,0.3)"
          }, }}>
              <CardActionArea style={{height: '100%', width: '100%'}}>
              <div style={{position: 'absolute', height: '100%', width: '10px', backgroundColor: theme.primary_color}}></div>
              <Grid container direction="column" alignItems="center"  style={{ width: "100%", height: "100%", justifyContent: 'center' }}>
                
                <Grid
                  item
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                  style={{ width: "100%" }}
                >
                  <Grid item style={{ width: "100%" }}>
                    <Tooltip placement="bottom" title={this.props.nome}>
                      <Typography variant="h6" component="h6" style={{ padding: "8px", textOverflow: "ellipsis", overflow: "hidden", color:theme.secondary_color }}>
                        {this.props.nome}
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item container justify="center" alignItems="center" style={{ width: "100%" }}>
                    <Typography variant="caption" component="caption" style={{ color: theme.tertiary_color }}>
                      {"CNPJ:" + this.props.cnpj}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              </CardActionArea>
            </Card>
          </ButtonBase>
        </Grid>
      </Grid>
    );
  }
}

export default CompanyItem;