import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import myApp from "../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import {
  Button,
  CircularProgress,
  FormControl,
  Paper,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

import PaperTitle from "../../../shared/components/paperTitle";
import Title from "../../../shared/components/title";
import theme from "../../../core/theme.json";
import { getProcessReportIsAvailable } from "./functions";

const LIATest = () => {
  const database = useMemo(() => myApp.firestore(), []);

  const { processId } = useSelector((state) => state.processReducer);

  const [processInfo, setProcessInfo] = useState({ answers: [] });
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [option, setOption] = useState(null);

  const onGetProcessSnapshot = useCallback((snapshot) => {
    setProcessInfo({ ...snapshot.data() });
    setLoading(false);
  }, []);

  const onGetQuestionsSnapshot = useCallback((snapshot) => {
    const filteredQuestions = [];
    snapshot.forEach((question) =>
      filteredQuestions.push({ id: question.id, ...question.data() })
    );

    filteredQuestions.sort((a, b) => {
      if (a.questionOrder > b.questionOrder) return 1;
      if (a.questionOrder < b.questionOrder) return -1;
      return 0;
    });

    setQuestions(filteredQuestions);
    setLoading(false);
  }, []);

  const onError = useCallback((error) => {
    console.log("error", error);
    setLoading(false);
  }, []);

  const answersHaveChanges = useCallback(() => {
    let lastAnswerIndex = answers.length === 0 ? 0 : answers.length - 1;

    if (lastAnswerIndex === 0 && !!answers[0]) return true;

    const hasValidUnsavedAnswers =
      answers.length > processInfo.answers?.length &&
      !!answers[lastAnswerIndex];

    if (typeof answers[lastAnswerIndex] === "boolean") return true;
    if (hasValidUnsavedAnswers) return true;

    const processHasAnswers = answers.length > 0;

    let changedAnswers = [];

    if (processHasAnswers) {
      changedAnswers = processInfo.answers?.filter((existingAnswer, index) => {
        return existingAnswer !== answers[index];
      });
    }

    return changedAnswers?.length > 0;
  }, [answers, processInfo.answers]);

  const answersAreFilled = useCallback(() => {
    const unanswered = answers.filter((answer) => {
      if (typeof answer === "boolean") return false;
      return !answer;
    });

    return unanswered.length === 0;
  }, [answers]);

  const shouldEnableSaveButton = useCallback(() => {
    const shouldDisable = answersAreFilled() && answersHaveChanges();
    return shouldDisable;
  }, [answersHaveChanges, answersAreFilled]);

  const onSubmit = useCallback(async () => {
    setSaving(true);
    await database.collection("processes").doc(processId).update({
      answers: answers,
    });

    setSaving(false);
    // setHasChanges(false);
  }, [answers, database, processId]);

  const handleChange = useCallback(
    (event, index) => {
      const newAnswers = [...answers];
      newAnswers[index] = event.target.value;

      setAnswers(newAnswers);
    },
    [answers]
  );

  const handleOptionChange = useCallback(
    (event, index) => {
      const parsedValue = event.target.value === "true" ? true : false;
      const newAnswers = [...answers];
      newAnswers[index] = parsedValue;

      setAnswers(newAnswers);
      setOption(parsedValue);
    },
    [answers]
  );

  const getOptionAnswered = useCallback(() => {
    const lastAnswer = answers.slice(-1);

    if (typeof lastAnswer[0] === "boolean") {
      return lastAnswer[0];
    }
  }, [answers]);

  const displayWarningLabel = useCallback(() => {
    let shouldDisplay =
      questions.length &&
      answers.length &&
      questions.length === answers.length &&
      !answers[questions.length - 1];

    return shouldDisplay;
  }, [answers, questions.length]);
  // ---------------------------------------------------------------------------
  useEffect(() => {
    const unsubscribeToProcess = database
      .collection("processes")
      .doc(processId)
      .onSnapshot(onGetProcessSnapshot, onError);

    return () => {
      unsubscribeToProcess();
    };
  }, [database, onError, onGetProcessSnapshot, processId]);
  // ---------------------------------------------------------------------------
  useEffect(() => {
    const unsubscribeToQuestions = database
      .collection("questions")
      .where("LIA", "==", true)
      .onSnapshot(onGetQuestionsSnapshot, onError);

    return () => {
      unsubscribeToQuestions();
    };
  }, [database, onError, onGetQuestionsSnapshot]);
  // ---------------------------------------------------------------------------
  useEffect(() => {
    if (processInfo.answers) {
      setAnswers(processInfo.answers);
    } else setAnswers([]);
  }, [processInfo]);
  // ---------------------------------------------------------------------------
  const renderQuestion = useCallback(
    (question, index) => {
      const { answers: processAnswers } = processInfo;
      const renderUpTo = processAnswers?.length || 0;

      const shouldRenderQuestion = index <= renderUpTo;
      const hasOptions = !!question.options;

      if (shouldRenderQuestion) {
        return (
          <div key={question.id} style={{ marginTop: "30px" }}>
            <Typography variant="h5">{question.text}</Typography>
            {hasOptions ? (
              <RadioGroup
                row
                aria-label="option"
                name="row-radio-buttons-group"
                value={option}
                onChange={(event) => handleOptionChange(event, index)}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Sim"
                  checked={getOptionAnswered() === true}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Não"
                  checked={getOptionAnswered() === false}
                />
              </RadioGroup>
            ) : (
              <TextField
                fullWidth
                placeholder="Digite sua resposta"
                margin="dense"
                onChange={(event) => handleChange(event, index)}
                defaultValue={answers[index]}
              />
            )}
          </div>
        );
      }
    },
    [
      answers,
      getOptionAnswered,
      handleChange,
      handleOptionChange,
      option,
      processInfo,
    ]
  );
  // ---------------------------------------------------------------------------
  return (
    <>
      {!loading ? (
        <>
          <Title title={processInfo.name} />
          <Paper style={{ padding: "20px", marginTop: "20px" }}>
            <Typography variant="h4">
              Respondidas: {processInfo.answers?.length || 0}/{questions.length}
            </Typography>
            <FormControl style={{ width: "100%", padding: "10px" }}>
              {questions.map((question, index) =>
                renderQuestion(question, index)
              )}
              {displayWarningLabel() === true && (
                <Typography
                  variant="h5"
                  style={{
                    color: theme.warning_color,
                    margin: "20px 0",
                    justifySelf: "flex-start",
                  }}
                >
                  Se o interesse não é considerado legítimo, revise a base legal
                  do processo.
                </Typography>
              )}

              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "space-between",
                }}
              >
                <Button
                  type="submit"
                  color="primary"
                  backgroundColor="black"
                  onClick={onSubmit}
                  disabled={!shouldEnableSaveButton()}
                  style={{
                    marginTop: "20px",
                    padding: "10px 30px",
                    background: !shouldEnableSaveButton()
                      ? theme.disabled_color
                      : theme.primary_color,
                    color: !shouldEnableSaveButton()
                      ? "#0009"
                      : theme.secondary_color,
                  }}
                >
                  {saving ? (
                    <CircularProgress color={theme.secondary_color} size={24} />
                  ) : (
                    <>SALVAR RESPOSTAS</>
                  )}
                </Button>
              </div>
            </FormControl>
          </Paper>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "50px",
          }}
        >
          <CircularProgress color={theme.secondary_color} />
        </div>
      )}
    </>
  );
};

export default LIATest;
