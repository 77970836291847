import { Grid, Paper, Typography } from "@material-ui/core"
import { useEffect, useState } from "react"
import Chart from "react-google-charts"
import myApp from "../../../../../core/infrastructure/firebaseConfig"





export const Charts = () =>{
 const [state, setState] = useState({
  TypeDenunceChart: [],
  SolicitationsChart: [],
  statusChart: [],
  statusData:[],
  SolicitantData: [],
  TypeDenunce: [],
  TypeDenunceChart: [],
  SolicitationsChart: [],
  statusChart: [],
  listaDenuncias: []
 })

 useEffect( ()=>{
  getDados()
 
},[state.TypeDenunce?.length == 0])


 const handleFilterByDateAsc = () =>{
  let aux = state.listaDenuncias;
  aux.sort(handleOrderDataAsc);
  setState({
    listaDenuncias: aux,
  });
}

const handleOrderDataAsc =(a, b) => {
  return b.date - a.date;
}



 const getDados = async () => {
  let aux = [];

  try {
    let get = await myApp
    .firestore()
    .collection("denunce")
    .where("fk_company", "==", localStorage.getItem("currentCompany"))
    .get();
    for (let doc of get.docs) {
      let denunce = {
        id: doc.ref.id,
        protocol: doc.data().protocol,
        date: doc.data().date,
        status: doc.data().status,
        solicitant: doc.data().company_relation,
        relate_Type: doc.data().relate_Type,
        sensitive: doc.data().Sensitive,
        additionalInformation: doc.data().additionalInformation,
        email: doc.data().email,
        name: doc.data().name,
        verificationConclusation: doc.data().verificationConclusation,
        response: doc.data().response,
        responsedenunce: doc.data().responsedenunce,
        company_user_relation: doc.data().company_user_relation,
        responseHistory: doc.data().responseHistory,
        files_document_response: doc.data().files_document_response,
      };

      aux.push(denunce);
      aux.slice(-1, [0]);
    }
    let statusData = [
      { name: "Aberto", value: 0 },
      { name: "Em andamento", value: 0 },
      { name: "Concluído", value: 0 },
    ];

    let SolicitantData = [
      { name: "Cliente", value: 0 },
      { name: "Colaborador", value: 0 },
      { name: "Fornecedor", value: 0 },
      { name: "Parceiros", value: 0 },
      { name: "Outros", value: 0 },
    ];
    let TypeDenunce = [
      { name: "Assédio", value: 0 },
      { name: "Fraude", value: 0 },
      { name: "Agressão", value: 0 },
      { name: "Discriminação", value: 0 },
      { name: "Violação de lei trabalhista", value: 0 },
      { name: "Violação de Lei ambiental", value: 0 },
      { name: "Violação de Lei Penal", value: 0 },
      { name: "Uso indevido de informações", value: 0 },
      { name: "Corrupção ou outros", value: 0 },
    ];

    let statusChart = [["Tipo", "valor"]];
    let SolicitationsChart = [["Tipo", "valor"]];
    let TypeDenunceChart = [["Tipo", "valor"]];

    let objIndex = 0;

    for (let i = 0; i < aux.length; i++) {
      if (aux[i].status === "Aberto") {
        objIndex = statusData.findIndex((obj) => obj.name === "Aberto");
        statusData[objIndex].value++;
      }

      if (aux[i].status === "Em andamento") {
        objIndex = statusData.findIndex((obj) => obj.name === "Em andamento");
        statusData[objIndex].value++;
      }

      if (aux[i].status === "Concluído") {
        objIndex = statusData.findIndex((obj) => obj.name === "Concluído");
        statusData[objIndex].value++;
      }
      if (aux[i].solicitant === "Cliente") {
        objIndex = SolicitantData.findIndex((obj) => obj.name === "Cliente");
        SolicitantData[objIndex].value++;
      }
      if (aux[i].solicitant === "Colaborador") {
        objIndex = SolicitantData.findIndex(
          (obj) => obj.name === "Colaborador"
        );
        SolicitantData[objIndex].value++;
      }
      if (aux[i].solicitant === "Fornecedor") {
        objIndex = SolicitantData.findIndex(
          (obj) => obj.name === "Fornecedor"
        );
        SolicitantData[objIndex].value++;
      }
      if (aux[i].solicitant === "Parceiros") {
        objIndex = SolicitantData.findIndex(
          (obj) => obj.name === "Parceiros"
        );
        SolicitantData[objIndex].value++;
      }
      if (aux[i].solicitant === "Outros") {
        objIndex = SolicitantData.findIndex((obj) => obj.name === "Outros");
        SolicitantData[objIndex].value++;
      }

      if (aux[i].relate_Type === "Assédio") {
        objIndex = TypeDenunce.findIndex((obj) => obj.name === "Assédio");
        TypeDenunce[objIndex].value++;
      }

      if (aux[i].relate_Type === "Discriminação") {
        objIndex = TypeDenunce.findIndex(
          (obj) => obj.name === "Discriminação"
        );
        TypeDenunce[objIndex].value++;
      }
      if (aux[i].relate_Type === "Fraude") {
        objIndex = TypeDenunce.findIndex((obj) => obj.name === "Fraude");
        TypeDenunce[objIndex].value++;
      }
      if (aux[i].relate_Type === "Violação de lei trabalhista") {
        objIndex = TypeDenunce.findIndex(
          (obj) => obj.name === "Violação de lei trabalhista"
        );
        TypeDenunce[objIndex].value++;
      }
      if (aux[i].relate_Type === "Violação de Lei ambiental") {
        objIndex = TypeDenunce.findIndex(
          (obj) => obj.name === "Violação de Lei ambiental"
        );
        TypeDenunce[objIndex].value++;
      }
      if (aux[i].relate_Type === "Violação de Lei Penal") {
        objIndex = TypeDenunce.findIndex(
          (obj) => obj.name === "Violação de Lei Penal"
        );
        TypeDenunce[objIndex].value++;
      }
      if (aux[i].relate_Type === "Uso indevido de informações") {
        objIndex = TypeDenunce.findIndex(
          (obj) => obj.name === "Uso indevido de informações"
        );
        TypeDenunce[objIndex].value++;
      }
      if (aux[i].relate_Type === "Corrupção ou outros") {
        objIndex = TypeDenunce.findIndex(
          (obj) => obj.name === "Corrupção ou outros"
        );
        TypeDenunce[objIndex].value++;
      }
    }

    setState({
      SolicitantData,
      statusData,
      TypeDenunce,
    });

    for (let doc of state.statusData) {
      statusChart.push([doc.name, doc.value - 1]);
    }

    for (let i = 0; i < state.statusData.length; i++) {
      if (statusChart.some((a) => a[0] === state.statusData[i].name)) {
        for (let t = 0; t < statusChart.length; t++) {
          if (statusChart[t][0] === state.statusData[i].name) {
            statusChart[t][1] += 1;
          }
        }
      }
    }

    for (let doc of state.SolicitantData) {
      SolicitationsChart.push([doc.name, 0]);
    }

    for (let i = 0; i < aux.length; i++) {
      if (SolicitationsChart.some((a) => a[0] === aux[i].solicitant)) {
        for (let t = 0; t < SolicitationsChart.length; t++) {
          if (SolicitationsChart[t][0] === aux[i].solicitant) {
            SolicitationsChart[t][1] += 1;
          }
        }
      }
    }

    for (let doc of state.TypeDenunce) {
      TypeDenunceChart.push([doc.name, 0]);
    }

    for (let i = 0; i < aux.length; i++) {
      if (TypeDenunceChart.some((a) => a[0] === aux[i].relate_Type)) {
        for (let t = 0; t < TypeDenunceChart.length; t++) {
          if (TypeDenunceChart[t][0] === aux[i].relate_Type) {
            TypeDenunceChart[t][1] += 1;
          }
        }
      }
    }

  setState({
      statusChart,
      SolicitationsChart,
      TypeDenunceChart,
    });
  } catch (errorGetTickets) {
    console.error("ao gerar graphics", errorGetTickets);
  }

}



  return(
    <Grid
    component={Paper}
    container
    direction="row"
    alignItems="center"
    style={{
      padding: "10px",
      marginTop: 5,
    }}
    spacing={2}
  >
    <Grid
      item
      container
      component={Paper}
      direction="column"
      alignItems="center"
      style={{ width: "45%", margin: 10 }}
    >
      <Grid item>
        <Typography
          variant="h6"
          component="h6"
          style={{ marginTop: 10 }}
        >
          Tipos de denunciantes
        </Typography>
      </Grid>
      <Grid item style={{ width: "100%" }}>
        <Chart
          wrap
          width={"100%"}
          height={"200px"}
          chartType="PieChart"
          loader={<div>Loading Chart</div>}
          data={state.SolicitationsChart}
          options={{ title: "Tipos de denunciantes" }}
          rootProps={{ "data-testid": "1" }}
        />
      </Grid>
    </Grid>

    <Grid
      item
      container
      component={Paper}
      direction="column"
      alignItems="center"
      style={{ width: "45%", margin: 10 }}
    >
      <Grid item>
        <Typography
          variant="h6"
          component="h6"
          style={{ marginTop: 10 }}
        >
          Tipos de denúncias
        </Typography>
      </Grid>
      <Grid item style={{ width: "100%" }}>
        <Chart
          wrap
          width={"100%"}
          height={"200px"}
          chartType="PieChart"
          loader={<div>Loading Chart</div>}
          data={state.TypeDenunceChart}
          options={{ title: "Tipos de denúncias" }}
          rootProps={{ "data-testid": "1" }}
        />
      </Grid>
    </Grid>

    <Grid
      item
      container
      component={Paper}
      direction="column"
      alignItems="center"
      style={{ width: "45%", margin: 10 }}
    >
      <Grid item>
        <Typography
          variant="h6"
          component="h6"
          style={{ marginTop: 10 }}
        >
          Status
        </Typography>
      </Grid>
      <Grid item style={{ width: "100%" }}>
        <Chart
          wrap
          width={"100%"}
          height={"200px"}
          chartType="PieChart"
          loader={<div>Loading Chart</div>}
          data={state.statusChart}
          options={{ title: "status" }}
          rootProps={{ "data-testid": "1" }}
        />
      </Grid>
    </Grid>
  </Grid>
  )
}
