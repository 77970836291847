import React, { Component } from "react";
import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import { getUserInformation } from "../../../../dashboard/command/getUserInformation";
import { Grid } from "@material-ui/core";
import AreaItem from "./areaItem";
import NewArea from "./newArea";

class AreaObject {
  id = "";
  name = "";
  icon = "";
  percentage = 0;
}

class AreaList extends Component {
  constructor(props) {
    super(props);

    this.db = myApp.firestore();

    this.state = {
      //meu state
      testeLista: [],
      //states antigos
      areas: [],
      listaAreas: [],
      userInformation: null,
      components: {},
      dataLoaded: false,
    };
  }

  async getItemList() {
    let database = myApp.firestore();
    let _companyId = localStorage.getItem("currentCompany");

    let _areas = [];

    try {
      let data = await database
        .collection("areas")
        .where("fk_companies", "==", _companyId)
        .where("active", "==", true)
        .get();
      let areas = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

      for (var i = 0; i < areas.length; i++) {
        try {
          let activyData = await database
            .collection("activities")
            .where("fk_areas", "==", areas[i].id)
            .get();
          let activy = activyData.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          var area = new AreaObject();

          area.id = areas[i].id;
          area.name = areas[i].name;
          area.icon = areas[i].icon;

          var count = 0;
          var done = 0;

          for (var j = 0; j < activy.length; j++) {
            count++;

            if (activy[j].done) {
              done++;
            }
          }

          area.percentage = (done * 100) / count;

          _areas.push(area);
        } catch (error) {
          console.error(error);
        }
      }

      _areas.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }

        if (a.name < b.name) {
          return -1;
        }

        return 0;
      });

      this.setState({
        areas: _areas,
      });
    } catch (error) {
      console.error(error);
    }
  }

  async componentDidMount() {
    await this.getItemList();
    await this._getUserInformation();
  }

  async _getUserInformation() {
    this.setState({
      userInformation: await getUserInformation(),
    });
  }

  renderPageComponents() {
    var components = {
      addAreaButton: this.state.userInformation.permissions.insert ? (
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <NewArea />
        </Grid>
      ) : (
        ""
      ),
    };

    this.setState({ components });
  }

  render() {
    if (this.state.userInformation !== null) {
      if (!this.state.dataLoaded) {
        this.setState({ dataLoaded: true });
        this.renderPageComponents();
      }
    }

    return (
      <Grid container spacing={4}>
        {this.state.areas.map((area) => (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AreaItem
              id={area.id}
              name={area.name}
              icon={area.icon}
              percentage={area.percentage}
            />
          </Grid>
        ))}
        {this.state.components.addAreaButton}
      </Grid>
    );
  }
}

export default AreaList;
