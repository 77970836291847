import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import CreateIcon from "@material-ui/icons/Create";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import SettingsIcon from "@material-ui/icons/Settings";
import ClearIcon from "@material-ui/icons/Clear";
import SaveIcon from "@material-ui/icons/Save";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import EditIcon from "@material-ui/icons/Edit";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import QuestionAnswerRoundedIcon from "@material-ui/icons/QuestionAnswerRounded";
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';


import CheckCircleSharpIcon from '@material-ui/icons/CheckCircleSharp';
import ErrorSharpIcon from '@material-ui/icons/ErrorSharp';


import { Flowpoint, Flowspace } from "flowpoints";
import {
  Grid,
  Paper,
  Typography,
  Fab,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Tabs,
  AppBar,
  Tab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  Tooltip,
  Avatar,
  Slider,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Autocomplete } from "@material-ui/lab";

import { Link } from "react-router-dom";

//imports de serviços do firebase
import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import "firebase/compat/functions";


import { connect } from "react-redux";
import { changePage } from "../../../presentation/redux/actions";
import { setLoadingProcess } from "../redux/actions";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";
import { getQuiz, getQuizById } from "../../quiz/functions/getQuiz";
import {
  isDefined,
  isUndefined,
} from "../../../../shared/commands/verifyDefinition";

import theme from "../../../../core/theme.json";
import { GridCheckCircleIcon } from "@material-ui/data-grid";
import VisibilityIcon from '@material-ui/icons/Visibility';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

class ProcessDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actualAtive: [],
      actualAtributes: [],
      sendQuestByEmail: false,
      emailInput: '',
      emailInputModal: '',
      dialogViewQuizz: false,
      dialogViewSendMails: false,
      getQuizInfoName: '',
      getQuizInfoStatus: '',
      clone: false,
      titularesDB: [],
      assetsDB: [],
      eventAttribute: '',
      legislation: '',
      disposalTerm: '',
      automaticReturnMessage: '',
      monitoringService: '',
      deleteService: '',
      disposalMethod: '',
      listLegislation: [],
      titularesVal: "",
      selected: null,
      selectedLine: null,
      points: {},
      lastPos: { x: 150, y: 50 },
      doFocus: false,
      dialog: false,
      dialogKey: -1,
      tabIndex: 0,
      saved: true,
      exitDialog: false,
      deleteDialog: false,
      savedDialog: false,
      attribute: "",
      assets: "",
      attributeDialog: false,
      attributeDialogKey: -1,
      attributeField: "",
      quizes: [],
      newQuizes: [],
      riskDialog: false,
      riskGapIndex: -1,
      riskGapType: "",
      riskChance: 1,
      riskImpact: 1,
      risk: 1,
      gapDialog: false,
      gaps: [],
      customGaps: [],
      quizUrl: "",
      quizRegisteredAnswers: [],
      userInformation: JSON.parse(
        sessionStorage.getItem("@blue-legal/userInformation")
      ),
      addAttributeButtonDisabled: true,
      addAttributeFieldButtonDisabled: true,
      answerQuizButtonDisabled: true,
      processName: "",
      processGoal: "",
      processCriticality: "",
      processOperator: "",
      processOwner: "",
      processBaseName: "",
      processDescription: "",
      processQuizId: "",
      legalBases: [],
      defaultLegalBases: [],
      editProcessDialog: false,
      updateProcessButtonDisabled: true,
      deleteProcessDialog: false,
      sharingSource: "",
      sharingSourceSector: "",
      sharingSourceOwner: "",
      sharingDestiny: "",
      sharingDestinySector: "",
      sharingDestinyOwner: "",
      sharingDialog: false,
      addSharingButtonDisabled: true,
      sharingIndex: -1,
      sharingEditDialog: false,
      saveSharingButtonDisabled: true,
      errorDialog: false,
      errorMessage: "",
      complementaryLegalBase: "",
      answerStageQuizButtonDisabled: true,
      viewListEmail: [],
      createActiveModalOpen: false,
      createHoldersModal: false,
      assetsModal: {
        name: '',
        type: '',
        place: '',
        country: '',
        cep: '',
        street: '',
        city: '',
        neighborhood: '',
        state: '',
        number: '',
        complement: '',
        lgpd: '',
        observation: '',
        nameOfResponsible: '',
        numberOfResponsible: '',
        emailOfResponsible: '',
        provider: '',
        in_company: localStorage.getItem('currentCompany')
      },
      dialogs: {
        addAssets: false,
        assetSegisteredSuccessfully: false,
        assetEditSuccessfully: false
      },
      countrys: [],
      assetsDb: [],
      suppliers: [],
      nameHolder: "",
      dataDb: [],
      newListHolders: [],
      assets:[]
    };

    // Helper variables
    this.diagramRef = null;
    this.count = Object.keys(this.state.points).length;
    this.lista = []
    this.options = []

    // Binding class methods
    this.handleClick = this.handleClick.bind(this);
    this.handleTouch = this.handleTouch.bind(this);
    this.handleAddPoint = this.handleAddPoint.bind(this);
    this.handleSetHolder = this.handleSetHolder.bind(this);
    this.addAssets = this.addAssets.bind(this);
    this.handleViewListEmail = this.handleViewListEmail.bind(this);

    //função para fechar o dialogo
    this.handleClose = this.handleClose.bind(this);

    // Adding dash
    Object.keys(this.state.points).map((p_key) => {
      Object.keys(this.state.points[p_key].outputs).map((o_key) => {
        if (!("dash" in this.state.points[p_key].outputs[o_key])) {
          this.state.points[p_key].outputs[o_key].dash = 0;
        }
      });
    });
  }

  componentDidMount() {
    window.history.pushState(null, null, window.location.pathname);
    this.getCountrys()
    window.addEventListener("popstate", (ev) => {
      if (!this.state.saved) {
        ev.preventDefault();

        return (ev.returnValue = "Você tem certeza que quer fechar a pagina?");
      }
    });
    //variaveis auxiliares
    let db = myApp.firestore();
    let in_company = localStorage.getItem("currentCompany");
    let listHolders = [];
    let listAssets = [];
    let listLegislation = [];

    //Pegando titulares do firebase
    db.collection("holders")
      .where("in_company", "==", in_company)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          listHolders.push(doc.data());
        });
        this.setState({
          titularesDB: listHolders,
        });
      });

    db.collection("assets")
      .where("in_company", "==", in_company)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          listAssets.push({
            id: doc.id,
            name: doc.data().name,
            cep: doc.data().cep,
            city: doc.data().city,
            complement: doc.data().complement,
            country: doc.data().country,
            lgpd: doc.data().lgpd,
            neighborhood: doc.data().neighborhood,
            number: doc.data().number,
            observation: doc.data().observation,
            place: doc.data().place,
            state: doc.data().state,
            street: doc.data().street,
            type: doc.data().type
          });
        });
        this.setState({
          assetsDB: listAssets,
        });
      });

    db.collection('complementaryLegislation')
      .where("in_company", "==", in_company)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          listLegislation.push({
            id: doc.id,
            law: doc.data().Law,
            content: doc.data().Content,
            identification: doc.data().Identification,
          });
        })
        this.setState({
          listLegislation
        });
      });

    this.loadComponents();

  }

  async loadComponents() {
    await this.getNewQuizes();
    await this.loadJson();
    await this.loadCustomGapsQuiz();
    await this.loadGaps();
    await this.getQuizInDatabase();
    await this.getLegalBases();
    await this.getProcess();

    this.props.setLoadingProcess(false);
  }

  async getNewQuizes() {
    let database = myApp.firestore();
    try {
      let data = await database.collection("newQuizz")
        .where("fk_company", "==", localStorage.getItem("currentCompany"))
        .get();
      let newQuizes = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));

      newQuizes.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }

        if (a.name < b.name) {
          return -1;
        }

        return 0;
      });
      //  console.log(newQuizes)
      this.setState({
        newQuizes
      });
    } catch (error) {
      console.error(error);
    }
  }

  // eslint-disable-next-line no-dupe-class-members
  async getNewQuizes() {
    let database = myApp.firestore();
    try {
      let data = await database.collection("newQuizz")
        .where("fk_company", "==", localStorage.getItem("currentCompany"))
        .get();
      let newQuizes = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));

      newQuizes.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }

        if (a.name < b.name) {
          return -1;
        }

        return 0;
      });

      this.setState({
        newQuizes
      });

    } catch (error) {
      console.error(error);
    }
  }

  componentDidUpdate() {
    window.addEventListener("beforeunload", (ev) => {
      if (!this.state.saved) {
        ev.preventDefault();
        return (ev.returnValue = "Você tem certeza que quer fechar a pagina?");
      }
    });
  }

  async loadJson() {
    let database = myApp.firestore();

    try {
      let data = await database
        .collection("processes")
        .doc(this.props.processId)
        .get();
      let process = data.data();
      var processJson = await JSON.parse(process.json);
      var size = 0;

      for (var key in processJson) {
        if (processJson.hasOwnProperty(key)) {
          size++;
        }
      }
      //  console.log("id", this.props.processId)
      this.count = size;

      delete processJson.gaps;

      this.setState({
        points: processJson,
        // customGaps: uniqueCustomGaps,
      });

    } catch (error) {
      console.error(error);
    }
  }

  async loadCustomGapsQuiz() {
    let data = await
      myApp.firestore()
        .collection("processes")
        .doc(this.props.processId)
        .get();
    let process = data.data();
    let auxGapsNewQuiz = process.customGaps.slice();

    let getGapsNewQuiz = await myApp.firestore().collection("newQuizAnswers")
      .where("fk_company", "==", localStorage.getItem("currentCompany"))
      .where("processId", "==", this.props.processId)
      .get();

    // let idDocNewAnswersQuiz = getGapsNewQuiz.docs[0].id;
    // let questionsNewAnswersQuiz = getGapsNewQuiz.docs[0].data().questions;
    let questionsNewAnswersQuiz = getGapsNewQuiz.docs[0] ? getGapsNewQuiz.docs[0].data().questions : null;

    if (questionsNewAnswersQuiz !== null) {
      for (let item of questionsNewAnswersQuiz) {
        if (item.alternativesList.length > 0) {
          for (let itemAlternative of item.alternativesList) {
            if (itemAlternative.gap === true) {
              auxGapsNewQuiz.push(
                {
                  actionPlan: itemAlternative.gapPlanoAcao,
                  date: null,
                  owner: "Manual",
                  riskChance: 1,
                  done: false,
                  fk_process: this.props.processId,
                  ownerEmail: "",
                  name: itemAlternative.gapTitle,
                  riskImpact: 1,
                  risk: 1,
                  riskGapImpact: 1,
                  riskGapChance: 1,
                  riskGap: 1,
                  epmId: 0
                }
              );
            }
          }
        }
      }

      const uniqueCustomGaps = auxGapsNewQuiz.filter((gap, index, self) => {
        return index === self.findIndex((g) => g.name === gap.name);
      });

      this.setState({
        customGaps: uniqueCustomGaps,
      });
    }
  }

  async handleSetHolder(event) {
    this.setState({
      titularesVal: event.target.value,
    });
  }

  async handleAddPoint() {
    var newpoint = {
      name: "",
      type: "",
      questions: [],
      gaps: [],
      customGaps: [],
      gap: false,
      attributes: [],
      assets: [],
      transfers: [],
      quizId: "",
      sharings: [],
      observation: "",
      goal: "",
      pos: { x: this.state.lastPos.x + 10, y: this.state.lastPos.y + 10 },
      outputs: {},
      emails: [],
    };

    var points = this.state.points;

 
    points["" + this.count] = newpoint;

    this.count += 1;

    this.setState({
      points,
      selected: "" + (this.count - 1),
      lastPos: { x: this.state.lastPos.x + 10, y: this.state.lastPos.y + 10 },
      saved: false,
    });

    console.log("testepointssssss", this.state.points[this.state.dialogKey].assets)
  }



  handleClick(id, e) {
    this.doFocus = true;
    var selected = this.state.selected;
    var points = this.state.points;

    if (e.shiftKey) {
      if (selected === null) {
        selected = id;
      } else {
        if (selected !== id) {
          var p1 = points[selected];
          if (id in p1.outputs) {
            delete p1.outputs[id];
          } else {
            p1.outputs[id] = {
              output: "auto",
              input: "auto",
              dash: 0,
            };
          }
        }
      }
    } else {
      selected = selected === null ? id : selected === id ? null : id;
    }
    this.setState({ selected, points, saved: false });
  }

  handleTouch(id) {
    this.doFocus = false;
    var selected = this.state.selected;
    var points = this.state.points;
    if (selected === null) {
      selected = id;
    } else {
      if (selected !== id) {
        var p1 = points[selected];
        if (id in p1.outputs) {
          delete p1.outputs[id];
        } else {
          p1.outputs[id] = {
            output: "auto",
            input: "auto",
            dash: 0,
          };
        }
      }
    }
    this.setState({ selected, points, saved: false });
  }

  handlePointName(event) {
    var points = this.state.points;
    var point = this.state.points[this.state.dialogKey];

    point.name = event.target.value;

    points[this.state.dialogKey] = point;

    this.setState({
      points: points,
      saved: false,
    });
  }

  handleStorageType(event) {
    var points = this.state.points;
    var point = this.state.points[this.state.dialogKey];

    point.storageType = event.target.value;

    points[this.state.dialogKey] = point;

    this.setState({
      points: points,
      saved: false,
    });
  }

  handlePointType(event) {
    var points = this.state.points;
    var point = this.state.points[this.state.dialogKey];

    point.type = event.target.value;

    points[this.state.dialogKey] = point;

    this.setState({
      points: points,
      saved: false,
    });
  }

  handleQuizId(event) {
    var points = this.state.points;
    var point = this.state.points[this.state.dialogKey];

    point.quizId = event.target.value;

    this.handleNewQuestionSet(event.target.value);

    points[this.state.dialogKey] = point;

    var quizUrl = "/quiz/" + point.quizId;

    this.setState({
      points: points,
      quizUrl,
      saved: false,
    });

    if (event.target.value !== "") {
      this.setState({
        answerStageQuizButtonDisabled: false,
      });
    } else {
      this.setState({
        answerStageQuizButtonDisabled: true,
      });
    }
  }

  async handleNewQuestionSet(id) {
    var points = this.state.points;
    var point = this.state.points[this.state.dialogKey];

    var newQuestions = [];

    if (id !== "" && id !== null) {
      var quiz = await getQuizById(id);
      var dbQuestion = await this.getQuestion(quiz.points[1].cardProps.id);

      newQuestions.push(dbQuestion);
    }

    point.questions = newQuestions;

    points[this.state.dialogKey] = point;

    this.setState({
      points: points,
      saved: false,
    });
  }

  deletePoint() {
    var selected = this.state.selected;
    var points = {};

    Object.keys(this.state.points).map((testkey) => {
      if (testkey !== selected) {
        points[testkey] = this.state.points[testkey];
      }
    });

    if (Object.keys(points).length === 0) {
      this.count = 0;
    }

    this.setState({ selected: null, points, saved: false });
  }

  handleQuestionObservation(event, index) {
    var points = this.state.points;
    var point = this.state.points[this.state.dialogKey];

    point.questions[index].observation = event.target.value;

    points[this.state.dialogKey] = point;

    this.setState({
      points: points,
      saved: false,
    });
  }

  async handleQuestionAnswer(index, answer) {
    var points = this.state.points;
    var point = this.state.points[this.state.dialogKey];
    var questions = this.state.points[this.state.dialogKey].questions;

    var gaps = this.state.points[this.state.dialogKey].gaps;

    questions[index].answer = answer;
    questions[index].gap = questions[index].answers[answer].gap;

    var newGap = await this.getGap(questions[index].answers[answer].fk_gaps);


    if (questions.length - 1 === index) {
      if (questions[index].gap) {
        gaps.push(newGap);
      }
    } else {
      gaps.splice(index - 1, questions.length - index - 1);

      if (questions[index].gap) {
        gaps.push(newGap);
      }
    }

    point.gaps = gaps;

    if (questions[index].answers[answer].leadTo !== "") {
      var newQuestion = await this.getQuestion(
        questions[index].answers[answer].leadTo
      );

      if (questions.length - 1 === index) {
        questions.push(newQuestion);
      } else {
        questions.splice(index + 1, questions.length - index - 1);

        questions.push(newQuestion);
      }

      point.questions = questions;
    }

    point.gap = this.getPointGap();

    points[this.state.dialogKey] = point;

    this.setState({
      points: points,
      saved: false,
    });
  }

  handleDialogCloseProcesosConfigEdit() {
    this.setState({
      dialog: !this.state.dialog,
      dialogKey: -1,
    });
  }

  handleDialogCreateActiveModal() {
    this.setState({
      createActiveModalOpen: !this.state.createActiveModalOpen,
    });
  }

  handleDialogCreateHoldersModal() {
    this.setState({
      createHoldersModal: !this.state.createHoldersModal,
    });
  }

  handleDialog(key, gap = false) {
    this.setState({
      dialog: !this.state.dialog,
      dialogKey: key,
    });

    if (key !== -1) {
      if (
        this.state.points[key].quizId !== undefined &&
        this.state.points[key].quizId !== ""
      ) {
        this.setState({
          answerStageQuizButtonDisabled: false,
        });
      } else {
        this.setState({
          answerStageQuizButtonDisabled: true,
        });
      }
    } else {
      this.setState({
        answerStageQuizButtonDisabled: true,
      });
    }

    if (gap) {
      this.setState({
        tabIndex: 2,
      });
    } else {
      this.setState({
        tabIndex: 0,
      });
    }
  }

  handleTab = (event, tabIndex) => {
    this.setState({ tabIndex });
  };

  async getQuestion(id) {
    let database = myApp.firestore();

    try {
      let data = await database.collection("questions").doc(id).get();
      let question = data.data();

      var questionObject = {
        description: question.text,
        observation: "",
        answers: question.answers,
        answer: -1,
        gap: false,
        id: id,
      };

      return questionObject;
    } catch (error) {
      console.error(error);
    }
  }

  getPointGap() {
    var questions = this.state.points[this.state.dialogKey].questions;

    for (var i = 0; i < questions.length; i++) {
      if (questions[i].gap) {
        return true;
      }
    }

    return false;
  }

  async getGap(id) {
    let database = myApp.firestore();

    try {
      let data = await database.collection("gaps").doc(id).get();
      let gap = data.data();

      var gapObject = {
        actionPlan: gap.actionPlan,
        date: null,
        done: false,
        fk_process: this.props.processId,
        name: gap.name,
        owner: "",
        ownerEmail: "",
        risk: 1,
        riskImpact: 1,
        riskChance: 1,
        riskGap: 1,
        riskGapImpact: 1,
        riskGapChance: 1,
      };

      return gapObject;
    } catch (error) {
      console.error(error);
    }
  }

  async saveProcess() {
    let points = this.state.points

     let listAssest = this.state.actualAtive.concat(this.state.points[0]?.assets)

    for (let item of this.state.actualAtive) {

      let assetss = {
        asset: item.asset,
        fields: [],
      };

      if (points[this.state.dialogKey].assets) {
        points[this.state.dialogKey].assets.push(assetss);
      } else {
        points[this.state.dialogKey].assets = [];
        points[this.state.dialogKey].assets.push(assetss);
      }

      this.setState({
        assets: "",
        points: points,
        actualAtive: []
      });
    }



    if (Object.keys(points).length) {
      // console.log("json", points)

      let processJson = JSON.stringify(points);
      let database = myApp.firestore();
      let batch = database.batch();
      let epmUser = {};

      try {
        let obj = {
          username: "admin",
          password: "Blue@2020",
        };

        let response = await fetch(
          // "https://apps.blueprojects.com.br/beeprivacy/Security/Login",
          "https://apps.beelegal.com.br/beeprivacy/Security/Login",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(obj),
          }
        );

        let respJson = await response.json();

        if (respJson.data == null) {
        } else {
          epmUser = respJson.data;
        }
      } catch (error) {
        console.log(error);
      }

      let customGaps = this.state.customGaps;

      try {
        for (let i in customGaps) {
          let gapObject = {
            tid: "VF9CRUVfR0FQUzowNzc3MzU=",
            fid: 170,
            key: {
              id: customGaps[i].epmId,
            },
            data: {
              ID_PROCESSO: customGaps[i].fk_process,
              NOME_PROCESSO: this.state.processName,
              ID_ETAPA: "",
              NOME_ETAPA: "",
              ID_GAP: "",
              GAP: customGaps[i].name,
              PLANO_ACAO: customGaps[i].actionPlan,
            },
          };

          let response = await fetch(
            // "https://apps.blueprojects.com.br/beeprivacy/Integration/Save",
            "https://apps.beelegal.com.br/beeprivacy/Security/Login",
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                auth: epmUser.auth_ticket,
              },
              body: JSON.stringify(gapObject),
            }
          );

          let respJson = await response.json();

          customGaps[i].epmId = respJson.data.result.id;
        }
      } catch (error) {
        console.error(error);
      }

      let gapsSemNome = 0;

      for (let vld of customGaps) {
        if (vld.name === "") {
          gapsSemNome++;
        }
      }

      let errorMessageValidation = `Existe${gapsSemNome < 2 ? "" : "m"
        } ${gapsSemNome} gap${gapsSemNome < 2 ? "" : "s"} sem nome`;

      if (gapsSemNome === 0) {
        try {
          await database
            .collection("processes")
            .doc(this.props.processId)
            .update({
              json: processJson,
              customGaps: customGaps,
              gaps: this.state.gaps,
            });

          let quizRegisteredAnswers = this.state.quizRegisteredAnswers;
          let gaps = this.state.gaps;

          for (let qra of quizRegisteredAnswers) {
            for (let gap of gaps) {
              if (qra.id === gap.quizRegisteredAnswerId) {
                if (gap.date !== null) {
                  gap.date = new Date(gap.date).toLocaleDateString();
                }
                qra.registered_answers[gap.answerIndex].gap = gap;
              }
            }

            let docRef = database
              .collection("quiz_registered_answers")
              .doc(qra.id);
            batch.set(docRef, qra);
          }

          batch.commit();

          try {
            let quizes = this.state.quizes;
            quizes.forEach((q, quizIndex) => {
              for (let p in q.points) {
                if (q.points[p].cardProps.gap) {
                  gaps.forEach((g, gapIndex) => {
                    if (q.points[p].cardProps.gapId === g.gapId) {
                      q.points[p].cardProps.risk = g.risk;
                      q.points[p].cardProps.riskChance = g.riskChance;
                      q.points[p].cardProps.riskGap = g.riskGap;
                      q.points[p].cardProps.riskGapChance = g.riskGapChance;
                      q.points[p].cardProps.riskGapImpact = g.riskGapImpact;
                      q.points[p].cardProps.riskImpact = g.riskImpact;
                    }
                  });
                }
              }
              database.collection("quiz").doc(q.id).set(q);
            });
          } catch (e) {
            console.log("erro", e);
          }

          delete processJson.gaps;

          this.setState({
            json: processJson,
            quizRegisteredAnswers,
            saved: true,
          });

          this.handleSavedDialog();
        } catch (error) {
          console.error("Erro ao salvar processo" + error);
        }
      } else {
        this.handleErrorDialog(errorMessageValidation);
      }
    } else {
      this.handleErrorDialog(
        "Não é possível salvar o processo sem etapas cadastradas"
      );
    }
  }

  exitDashboard() {
    this.setState({
      saved: true,
    });

    this.props.changePage(6);
  }

  handleExitDialog() {
    this.setState({
      exitDialog: !this.state.exitDialog,
    });
  }

  handleErrorDialog(msg) {
    this.setState({
      errorDialog: !this.state.errorDialog,
      errorMessage: msg,
    });
  }

  handleExit() {
    if (!this.state.saved) {
      this.handleExitDialog();
    } else {
      this.exitDashboard();
    }
  }

  async handleSaveExit() {
    await this.saveProcess();
    if (!this.state.errorDialog) this.exitDashboard();
  }

  handleGapDate(value, gapIndex) {
    var gaps = this.state.gaps;

    gaps[gapIndex].date = value;

    this.setState({
      gaps,
      saved: false,
    });
  }

  handleGapOwner(event, gapIndex) {
    var gaps = this.state.gaps;

    gaps[gapIndex].owner = event.target.value;

    this.setState({
      gaps,
    });
  }

  handleGapOwnerEmail(event, gapIndex) {
    var gaps = this.state.gaps;

    gaps[gapIndex].ownerEmail = event.target.value;

    this.setState({
      gaps,
      saved: false,
    });
  }

  handleGapDone(gapIndex) {
    var gaps = this.state.gaps;

    gaps[gapIndex].done = !gaps[gapIndex].done;

    if (gaps[gapIndex].done) {
      gaps[gapIndex].date = Date.now();
    } else {
      gaps[gapIndex].date = null;
    }

    this.setState({
      gaps,
      saved: false,
    });
  }

  handleGapActionPlan(event, gapIndex) {
    var gaps = this.state.gaps;

    gaps[gapIndex].actionPlan = event.target.value;

    this.setState({
      gaps,
      saved: false,
    });
  }

  removeGap(gapIndex) {
    var gaps = this.state.gaps;

    gaps[gapIndex].active = false;

    this.setState({
      gaps,
      saved: false,
    });
  }

  handleDeleteDialog() {
    this.setState({
      deleteDialog: !this.state.deleteDialog,
    });
  }

  handleSavedDialog() {
    this.setState({
      savedDialog: !this.state.savedDialog,
    });
  }

  async getProcess() {
    let database = myApp.firestore();

    try {
      let data = await database
        .collection("processes")
        .doc(this.props.processId)
        .get();

      if (
        data.data().name !== "" &&
        data.data().goal !== "" &&
        data.data().owner !== "" &&
        data.data().criticality !== "" &&
        data.data().operator !== "" &&
        data.data().legalBases &&
        data.data().legalBases[0] !== ""
      ) {
        this.setState({
          updateProcessButtonDisabled: false,
        });
      }

      if (
        isDefined(data.data().quizId) &&
        data.data().quizId !== null &&
        data.data().quizId !== ""
      ) {
        this.setState({
          answerQuizButtonDisabled: false,
        });
      } else {
        this.setState({
          answerQuizButtonDisabled: true,
        });
      }

      this.setState({
        processName: data.data().name,
        processGoal: data.data().goal,
        processOwner: data.data().owner,
        processCriticality: data.data().criticality,
        processOperator: data.data().operator,
        processBaseName: data.data().legalBases[0],
        processDescription: isUndefined(data.data().description)
          ? ""
          : data.data().description,
        legalBases: data.data().legalBases,
        processQuizId: isUndefined(data.data().quizId)
          ? ""
          : data.data().quizId,
        complementaryLegalBase: isUndefined(data.data().complementaryLegalBase)
          ? ""
          : data.data().complementaryLegalBase,
      });

      if (data.data().quizId.includes("quiz2.0")) {
        let getNewQuiz = await database
          .collection("newQuizAnswers")
          .where("processId", "==", this.props.processId)
          .get();
        // console.log(getNewQuiz.docs.map(item => item.data()))
        for (let item of getNewQuiz.docs) {
          if (item.data() != undefined) {
            this.setState({
              getQuizInfoName: item.data().name,
              getQuizInfoStatus: item.data().dateReplay ? 'Concluído' : 'Pendente'
            });
          }
        }
      } else {
        if (data.data().quizId) {
          let getQuizInfo = await database.collection("quiz").doc(data.data().quizId).get();
          let getAnswers = await database
            .collection("quiz_registered_answers")
            .where("quiz_id", "==", data.data().quizId)
            .where("process_id", "==", this.props.processId)
            .get();

          let qtdeAnswers = getAnswers.docs.length;

          this.setState({
            getQuizInfoName: getQuizInfo.data().name,
            getQuizInfoStatus: this.handleSetStatus(getQuizInfo.data(), getAnswers.docs[0].data())
          });
        } else {
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  handleSetStatus(item, answers) {
    if (item.points) {
      if (item.questionAnswers && item.questionAnswers.length > 0) {
        const totalQuestions = item.questionAnswers.length;
        const lastQuestionIndex = totalQuestions - 1;
        const lastQuestionAnswered = item.questionAnswers[lastQuestionIndex].answerIndex !== -1;
        if (lastQuestionAnswered) {
          return 'Concluído';
        } else {
          return 'Pendente';
        }
      }
    } else {
      return item.status
    }
  }

  async getLegalBases() {
    let database = myApp.firestore();

    try {
      let data = await database
        .collection("legal_basis")
        .orderBy("name", "asc")
        .get();
      let defaultLegalBases = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      this.setState({
        defaultLegalBases: defaultLegalBases,
      });
    } catch (error) {
      console.error(error);
    }
  }

  async updateProcess() {
    let database = myApp.firestore();

    try {
      await database.collection("processes").doc(this.props.processId).update({
        name: this.state.processName,
        goal: this.state.processGoal,
        owner: this.state.processOwner,
        criticality: this.state.processCriticality,
        operator: this.state.processOperator,
        legalBases: this.state.legalBases,
        description: this.state.processDescription,
        quizId: this.state.processQuizId,
        complementaryLegalBase: this.state.complementaryLegalBase,
      });

      await this.getProcess();
    } catch (error) {
      console.error(error);
    }

    this.handleEditProcessDialog();
    this.handleSavedDialog();
  }

  async removeProcess() {
    let database = myApp.firestore();

    try {
      let quizRegisteredAnswers = await database
        .collection("quiz_registered_answers")
        .where("process_id", "==", this.props.processId)
        .get();

      for (let doc in quizRegisteredAnswers.docs) {
        quizRegisteredAnswers.docs[doc].ref.delete();
      }

      await database.collection("processes").doc(this.props.processId).delete();
    } catch (error) {
      console.error(error);
    }

    this.setState({
      saved: true,
    });

    this.handleDeleteProcessDialog();
    this.handleEditProcessDialog();
    this.handleExit();
  }

  handleDeleteProcessDialog() {
    this.setState({
      deleteProcessDialog: !this.state.deleteProcessDialog,
    });
  }

  handleEditProcessDialog() {
    this.setState({
      editProcessDialog: !this.state.editProcessDialog,
    });
  }

  handleProcessName(event) {
    this.setState({
      processName: event.target.value,
    });

    if (
      event.target.value !== "" &&
      this.state.processOwner !== "" &&
      this.state.processGoal !== "" &&
      this.state.processCriticality !== "" &&
      this.state.processOperator !== "" &&
      this.state.processBaseName !== ""
    ) {
      this.setState({
        updateProcessButtonDisabled: false,
      });
    } else {
      this.setState({
        updateProcessButtonDisabled: true,
      });
    }
  }

  handleProcessOwner(event) {
    this.setState({
      processOwner: event.target.value,
    });

    if (
      event.target.value !== "" &&
      this.state.processName &&
      this.state.processGoal !== "" &&
      this.state.processCriticality !== "" &&
      this.state.processOperator !== "" &&
      this.state.processBaseName !== ""
    ) {
      this.setState({
        updateProcessButtonDisabled: false,
      });
    } else {
      this.setState({
        updateProcessButtonDisabled: true,
      });
    }
  }

  handleProcessGoal(event) {
    this.setState({
      processGoal: event.target.value,
    });

    if (
      event.target.value !== "" &&
      this.state.processName &&
      this.state.processOwner !== "" &&
      this.state.processCriticality !== "" &&
      this.state.processOperator !== "" &&
      this.state.processBaseName !== ""
    ) {
      this.setState({
        updateProcessButtonDisabled: false,
      });
    } else {
      this.setState({
        updateProcessButtonDisabled: true,
      });
    }
  }

  handleProcessCriticality(event) {
    this.setState({
      processCriticality: event.target.value,
    });

    if (
      event.target.value !== "" &&
      this.state.processName &&
      this.state.processGoal !== "" &&
      this.state.processOwner !== "" &&
      this.state.processOperator !== "" &&
      this.state.processBaseName !== ""
    ) {
      this.setState({
        updateProcessButtonDisabled: false,
      });
    } else {
      this.setState({
        updateProcessButtonDisabled: true,
      });
    }
  }

  handleProcessOperator(event) {
    this.setState({
      processOperator: event.target.value,
    });

    if (
      event.target.value !== "" &&
      this.state.processName &&
      this.state.processGoal !== "" &&
      this.state.processCriticality !== "" &&
      this.state.processOwner !== "" &&
      this.state.processBaseName !== ""
    ) {
      this.setState({
        updateProcessButtonDisabled: false,
      });
    } else {
      this.setState({
        updateProcessButtonDisabled: true,
      });
    }
  }

  handleProcessBaseName(value) {
    if (
      value !== "" &&
      this.state.processName &&
      this.state.processGoal !== "" &&
      this.state.processCriticality !== "" &&
      this.state.processOperator !== "" &&
      this.state.processOwner !== ""
    ) {
      this.setState({
        updateProcessButtonDisabled: false,
      });
    } else {
      this.setState({
        updateProcessButtonDisabled: true,
      });
    }

    let legalBases = [];

    if (value !== "") {
      legalBases.push(value);
    }

    this.setState({
      processBaseName: value,
      legalBases: legalBases,
    });
  }

  handleProcessDescription(event) {
    this.setState({
      processDescription: event.target.value,
    });
  }

  handleProcessQuiz(event) {
    this.setState({
      processQuizId: event.target.value,
    });

    if (event.target.value !== null && event.target.value !== "") {
      this.setState({
        answerQuizButtonDisabled: false,
      });
    } else {
      this.setState({
        answerQuizButtonDisabled: true,
      });
    }
  }

  handleAttribute(value) {
    this.setState({
      attribute: value,
    });
    // console.log(this.state.attribute)

    if (value !== "") {
      this.setState({
        addAttributeButtonDisabled: false,
      });
    } else {
      this.setState({
        addAttributeButtonDisabled: true,
      });
    }
  }

  addAttribute(value) {
    let aux = []
    aux.push({ name: value }, ...this.state.actualAtributes)

    this.setState({
      actualAtributes: aux
    })
    // console.log(this.state.actualAtributes)
    var points = this.state.points;
    var attribute = {
      name: value,
      fields: [],
    };

    points[this.state.dialogKey].attributes.push(attribute);

    this.setState({
      attribute: "",
      points: points,
      addAttributeButtonDisabled: true,
    });
  }

  addAssets(value) {
    // console.log(value)
    let aux = []
    aux.push({ asset: value }, ...this.state.actualAtive)
    this.setState({
      actualAtive: aux
    })

    // console.log(this.state.actualAtive)

    this.setState({
      assets: ''
    })


  }

  handleAddAsset(value) {
    this.setState({
      assets: value,
    });

  }

  handleAttributeDialog(index) {
    this.setState({
      attributeDialog: !this.state.attributeDialog,
      attributeDialogKey: index,
    });
  }

  handleAttributeField(value) {
    this.setState({
      attributeField: value,
    });

    if (value !== "") {
      this.setState({
        addAttributeFieldButtonDisabled: false,
      });
    } else {
      this.setState({
        addAttributeFieldButtonDisabled: true,
      });
    }
  }

  handleComplementaryLegalBase(event) {
    this.setState({
      complementaryLegalBase: event.target.value,
    });
  }

  addAttributeField() {
    var points = this.state.points;
    var attributeField = {
      name: this.state.attributeField,
      sensitive: false,
    };

    points[this.state.dialogKey].attributes[
      this.state.attributeDialogKey
    ].fields.push(attributeField);

    this.setState({
      points: points,
      attributeField: "",
      addAttributeFieldButtonDisabled: true,
    });
  }

  handleSensitiveAttributeField(index) {
    var points = this.state.points;

    points[this.state.dialogKey].attributes[
      this.state.attributeDialogKey
    ].fields[index].sensitive =
      !points[this.state.dialogKey].attributes[this.state.attributeDialogKey]
        .fields[index].sensitive;

    this.setState({
      points: points,
    });
  }

  removeAttributeField(index) {
    var points = this.state.points;

    points[this.state.dialogKey].attributes[
      this.state.attributeDialogKey
    ].fields.splice(index, 1);

    this.setState({
      points: points,
    });
  }

  removeAttribute(index) {
    var points = this.state.points;

    points[this.state.dialogKey].attributes.splice(index, 1);

    this.setState({
      points: points,
    });
  }

  handleRemoveAsset(index) {
    console.log('index', index)

    let actual = this.state.actualAtive

    actual.splice(index, 1);

    this.setState({
      actualAtive: actual,
    });

  }

  async handleRemoveAssetDb(assets,index){
    let listAssest = this.state.points[this.state.dialogKey]?.assets
    listAssest.splice(index, 1)
    this.setState({
      assets: listAssest,
    })
    console.log(listAssest)
  //   let in_company = localStorage.getItem("currentCompany");

  //  const get = await myApp.firestore().collection("assets")
  //   .where("in_company", "==", in_company)
  //   .get()
     
  //   console.log(get.docs.map(item => item.data()))
  }

  handleAddFieldsAsset(indexEtapa, indexAsset) {
    let points = this.state.points;
    let legislation = this.state.legislation
    let disposalTerm = this.state.disposalTerm
    let automaticReturnMessage = this.state.automaticReturnMessage
    let monitoringService = this.state.monitoringService
    let deleteService = this.state.deleteService
    let disposalMethod = this.state.disposalMethod
    let eventAttribute = this.state.eventAttribute

    let fields = {
      legislation,
      disposalTerm,
      automaticReturnMessage,
      monitoringService,
      deleteService,
      disposalMethod,
      eventAttribute,
    }

    points[indexEtapa].assets[indexAsset].fields.push(fields);
    this.setState({
      points,
      legislation: '',
      disposalTerm: '',
      automaticReturnMessage: '',
      monitoringService: '',
      deleteService: '',
      disposalMethod: '',
      eventAttribute: '',
    });
  }

  removeFieldAsset(indexEtapa, indexAsset, indexFields) {
    let points = this.state.points;
    points[indexEtapa].assets[indexAsset].fields.splice(indexFields, 1);
    this.setState({
      points
    });
  }

  handleCustomGapName(event, customGapIndex) {
    var customGaps = this.state.customGaps;

    customGaps[customGapIndex].name = event.target.value;

    this.setState({
      customGaps,
    });
  }

  handleCustomGapActionPlan(event, customGapIndex) {
    var customGaps = this.state.customGaps;

    customGaps[customGapIndex].actionPlan = event.target.value;

    this.setState({
      customGaps,
    });
  }

  handleCustomGapDate(value, customGapIndex) {
    var customGaps = this.state.customGaps;

    customGaps[customGapIndex].date = value;

    this.setState({
      customGaps,
    });
  }

  handleCustomGapOwner(event, customGapIndex) {
    var customGaps = this.state.customGaps;

    customGaps[customGapIndex].owner = event.target.value;

    this.setState({
      customGaps,
    });
  }

  handleCustomGapOwnerEmail(event, customGapIndex) {
    var customGaps = this.state.customGaps;

    customGaps[customGapIndex].ownerEmail = event.target.value;

    this.setState({
      customGaps,
    });
  }

  handleCustomGapDone(customGapIndex) {
    var customGaps = this.state.customGaps;

    customGaps[customGapIndex].done = !customGaps[customGapIndex].done;

    if (customGaps[customGapIndex].done) {
      customGaps[customGapIndex].date = Date.now();
    } else {
      customGaps[customGapIndex].date = null;
    }

    this.setState({
      customGaps,
    });
  }

  addCustomGap() {
    var customGaps = this.state.customGaps;

    var gapObject = {
      actionPlan: "",
      date: null,
      done: false,
      fk_process: this.props.processId,
      name: "",
      owner: "Manual",
      ownerEmail: "",
      risk: this.state.risk,
      riskImpact: this.state.riskImpact,
      riskChance: this.state.riskChance,
      riskGap: this.state.risk,
      riskGapImpact: this.state.riskImpact,
      riskGapChance: this.state.riskChance,
      epmId: 0,
    };

    customGaps.push(gapObject);

    this.setState({
      customGaps,
      saved: false,
    });
  }

  removeCustomGap(customGapIndex) {
    var customGaps = this.state.customGaps;

    customGaps.splice(customGapIndex, 1);

    this.setState({
      customGaps,
      saved: false,
    });
  }

  handleSharingTextField(event, field) {
    var points = this.state.points;
    var point = this.state.points[this.state.dialogKey];

    point.sharing[field] = event.target.value;

    points[this.state.dialogKey] = point;

    this.setState({
      points: points,
      saved: false,
    });
  }

  handleRiskDialog(index, type, chance, impact, ris) {
    this.setState({
      riskGapIndex: index,
      riskGapType: type,
      riskDialog: !this.state.riskDialog,
      riskChance: chance,
      riskImpact: impact,
      risk: ris,
    });
  }

  handleRiskChance(value) {
    this.setState(
      {
        riskChance: value,
      },
      () => {
        this.calculateRisk();
      }
    );
  }

  handleRiskImpact(value) {
    this.setState(
      {
        riskImpact: value,
      },
      () => {
        this.calculateRisk();
      }
    );
  }

  calculateRisk() {
    this.setState({
      risk: this.state.riskChance * this.state.riskImpact,
    });
  }

  riskColor(value) {
    if (value < 7) {
      return { height: "80px", width: "80px", background: "#45ad18" };
    } else if (value < 13) {
      return { height: "80px", width: "80px", background: "#f7ce10" };
    } else {
      return { height: "80px", width: "80px", background: "#e20000" };
    }
  }

  riskCellColor(value) {
    if (value < 7) {
      return { background: "#45ad18" };
    } else if (value < 13) {
      return { background: "#f7ce10" };
    } else {
      return { background: "#e20000" };
    }
  }

  saveRisk() {
    var gaps = this.state.gaps;
    var customGaps = this.state.customGaps;

    if (this.state.riskGapIndex >= 0) {
      if (this.state.riskGapType === "n") {
        gaps[this.state.riskGapIndex].risk = this.state.risk;
        gaps[this.state.riskGapIndex].riskImpact = this.state.riskImpact;
        gaps[this.state.riskGapIndex].riskChance = this.state.riskChance;

        this.setState(
          {
            gaps,
          },
          () => {
            this.handleRiskDialog(-1, "", 1, 1, 1);
          }
        );
      } else if (this.state.riskGapType === "ng") {
        gaps[this.state.riskGapIndex].riskGap = this.state.risk;
        gaps[this.state.riskGapIndex].riskGapImpact = this.state.riskImpact;
        gaps[this.state.riskGapIndex].riskGapChance = this.state.riskChance;

        this.setState(
          {
            gaps,
          },
          () => {
            this.handleRiskDialog(-1, "", 1, 1, 1);
          }
        );
      } else if (this.state.riskGapType === "c") {
        customGaps[this.state.riskGapIndex].risk = this.state.risk;
        customGaps[this.state.riskGapIndex].riskImpact = this.state.riskImpact;
        customGaps[this.state.riskGapIndex].riskChance = this.state.riskChance;

        this.setState(
          {
            customGaps,
          },
          () => {
            this.handleRiskDialog(-1, "", 1, 1, 1);
          }
        );
      } else {
        customGaps[this.state.riskGapIndex].riskGap = this.state.risk;
        customGaps[this.state.riskGapIndex].riskGapImpact =
          this.state.riskImpact;
        customGaps[this.state.riskGapIndex].riskGapChance =
          this.state.riskChance;

        this.setState(
          {
            customGaps,
          },
          () => {
            this.handleRiskDialog(-1, "", 1, 1, 1);
          }
        );
      }
    } else {
      this.handleRiskDialog(-1, "", 1, 1, 1);
    }
  }

  async getQuizInDatabase() {
    const quizes = await getQuiz();

    this.setState({
      quizes,
    });
  }

  labelFormater(value) {
    switch (value) {
      case 5:
        return "Muito Grande";
      case 4:
        return "Grande";
      case 3:
        return "Médio";
      case 2:
        return "Pequeno";
      case 1:
        return "Mínimo";
      default:
        return "";
    }
  }

  handleEmail(value, index) {
    var points = this.state.points;
    points[this.state.dialogKey].emails[index].text = value;
    points[this.state.dialogKey].emails[index].valid =
      value.includes(".") && value.includes("@") && value;
    this.setState({ points });

    this.verifyAnsweredEmails(
      points[this.state.dialogKey],
      this.state.dialogKey
    );

    this.setState({ emailInput: value });
  }

  addEmail(point) {
    var newPoint = point;
    newPoint.emails.push({
      sent: false,
      valid: true,
      text: "",
      answered: false,
    });
    var points = this.state.points;
    points[this.state.dialogKey] = newPoint;
    this.setState({ points });
  }

  //Função resonsáel por enviar o questionário
  async sendQuestionForMail() {

    if (Object.keys(this.state.points).length === 0) {
    } else {
      let emails = this.state.emailInput.split(';');

      let id = this.state.points[this.state.dialogKey].quizId;
      let linkDeResposta = "https://" + window.location.host + '/#/quiz/' + id + '/' + this.props.processId;
      const sendEmail = myApp.functions().httpsCallable('sendEmail');

      try {
        let requestObject = {
          from: `${process.env.REACT_APP_FROM_NAME} <${process.env.REACT_APP_FROM_EMAIL}>`,
          email: emails,
          subject: "Teste envio do questionário por email",
          message:
            `Olá, você está recebendo o link para responder um questionário referente ao mapeamento de sua área para atendimento da lei geral de proteção de dados. <br />` +
            ` Caso tenha alguma dúvida procure seu DPO. <br />` +
            `<a href="${linkDeResposta}" >Clique para responder</a> <br /> <br />` +
            `<b>Não responda a esta mensagem. E-mails enviados para este endereço não podem ser respondidos.</b>`
          ,
        }

        await sendEmail(requestObject);
      }
      catch (error) {
        console.log(error);
      }
      this.setState({ emailInput: '' })
      this.handleClose();
    }
  }

  async sendQuestionForMailModal() {
    if (Object.keys(this.state.points).length === 0) {
    } else {
      let emails = this.state.emailInputModal;

      let id = this.state.processQuizId;
      let linkDeResposta = "https://" + window.location.host + '/#/quiz/' + id + '/' + this.props.processId;
      const sendEmail = myApp.functions().httpsCallable('sendEmail');

      try {
        let requestObject = {
          from: `${process.env.REACT_APP_FROM_NAME} <${process.env.REACT_APP_FROM_EMAIL}>`,
          email: emails,
          subject: "Questionário Beeprivacy",
          message:
            `Olá, você está recebendo o link para responder um questionário referente ao mapeamento a área ${this.props.processArea} sobre o processo ${this.state.processName} para atendimento da lei geral de proteção de dados. <br />` +
            ` Caso tenha alguma dúvida procure seu DPO no telefone: ${localStorage.getItem('dpo_phone')} <br />` +
            `<a href="${linkDeResposta}" >Clique para responder</a> <br /> <br />` +
            `<b>Não responda a esta mensagem. E-mails enviados para este endereço não podem ser respondidos.</b>`
          ,
        }

        await sendEmail(requestObject);

        let parts = this.state.processQuizId.split(" ");
        let quizId = parts[1];
        let d = new Date();

        myApp.firestore().collection("questionnairesSentByEmail").add({
          email: emails,
          processId: this.props.processId,
          quizId: quizId,
          date: d.getTime(),
          reply: false
        });
      }
      catch (error) {
        console.log(error);
      }
      this.setState({ emailInputModal: '', dialogViewQuizz: false });
    }
  }

  //função para fechar dialogo do envio do questionário
  handleClose() {
    this.setState({ sendQuestByEmail: false })
  }

  async getAssets() {
    let assetsAux = [];
    let querySnapshot = await myApp.firestore().collection("assets").where("in_company", "==", localStorage.getItem('currentCompany')).get()
    for (let doc of querySnapshot.docs) {
      assetsAux.push({
        id: doc.id,
        cep: doc.data().cep,
        city: doc.data().city,
        complement: doc.data().complement,
        country: doc.data().country,
        in_company: doc.data().in_company,
        lgpd: doc.data().lgpd,
        name: doc.data().name,
        neighborhood: doc.data().neighborhood,
        number: doc.data().number,
        observation: doc.data().observation,
        nameOfResponsible: doc.data().nameOfResponsible,
        numberOfResponsible: doc.data().numberOfResponsible,
        emailOfResponsible: doc.data().emailOfResponsible,
        provider: doc.data().provider,
        place: doc.data().place,
        state: doc.data().state,
        street: doc.data().street,
        type: doc.data().type,
      });
    }
    this.setState({ assetsDb: { assetsAux } })
  }

  async handleRegisteringActive() {
    await myApp.firestore().collection("assets").add({
      cep: this.state.assetsModal.cep,
      city: this.state.assetsModal.city,
      complement: this.state.assetsModal.complement,
      country: this.state.assetsModal.country,
      in_company: localStorage.getItem('currentCompany'),
      lgpd: this.state.assetsModal.lgpd,
      name: this.state.assetsModal.name,
      neighborhood: this.state.assetsModal.neighborhood,
      number: this.state.assetsModal.number,
      observation: this.state.assetsModal.observation,
      nameOfResponsible: this.state.assetsModal.nameOfResponsible,
      numberOfResponsible: this.state.assetsModal.numberOfResponsible,
      emailOfResponsible: this.state.assetsModal.emailOfResponsible,
      provider: this.state.assetsModal.provider,
      place: this.state.assetsModal.place,
      state: this.state.assetsModal.state,
      street: this.state.assetsModal.street,
      type: this.state.assetsModal.type,
    }).then(() => {
      this.setState({
        assetsModal: {
          ...this.state.assetsModal,
          cep: '',
          city: '',
          complement: '',
          country: '',
          in_company: this.state.assetsModal.in_company,
          lgpd: '',
          name: '',
          neighborhood: '',
          number: '',
          observation: '',
          nameOfResponsible: '',
          numberOfResponsible: '',
          emailOfResponsible: '',
          provider: '',
          place: '',
          state: '',
          street: '',
          type: '',
        }
      })
      this.setState({
        dialogs: {
          ...this.state.dialogs,
          addAssets: false,
          assetSegisteredSuccessfully: true
        }
      })
      this.getAssets()
    });
  }

  async handleRegisterHolder() {
    if (this.state.nameHolder !== '') {
      await myApp.firestore().collection("holders").doc().set({
        name: this.state.nameHolder,
        in_company: localStorage.getItem("currentCompany"),
      })
        .then(() => {
          this.setState({
            nameHolder: ""
          })

          let listHolders = [];
          myApp.firestore().collection("holders")
            .where("in_company", "==", localStorage.getItem("currentCompany"))
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                listHolders.push(doc.data());
              });
              this.setState({
                titularesDB: listHolders,
              });
            });
        })
        .catch((error) => {
          console.error("Error ao registrar titular ", error);
        }).finally(() => {
          this.handleDialogCreateHoldersModal()
        })
    }
  }

  sendEmail(index) {
    var points = this.state.points;
    points[this.state.dialogKey].emails[index].sent = true;
    this.setState({ points });

    this.sendQuestionForMail();
  }

  async verifyAnsweredEmails(point, key) {
    var points = this.state.points;
    var emails = point.emails;
    var database = myApp.firestore();
    var qraRef = database.collection("quiz_registered_answers");
    var qraResult = await qraRef
      .where("process_id", "==", this.props.processId)
      .where("process_stage", "==", key)
      .get();

    if (!qraResult.empty) {
      emails.forEach((email) => {
        var qraEmail = qraResult.docs.filter(
          (qra) => qra.data().user_email === email.text
        );

        if (qraEmail.length > 0) email["answered"] = true;
        else email["answered"] = false;
      });

      points[key] = point;

      this.setState({ points });
    }
  }

  verifyQuestionsLength() {
    if (Array.isArray(this.state.points[this.state.dialogKey].questions)) {
      return this.state.points[this.state.dialogKey].questions.length === 0;
    }
  }

  renderEmails(point) {
    return point.emails.map((email, i) => (
      <div
        style={{
          width: "100%",
          height: "60px",
          marginTop: "25px",
          display: "flex",
        }}
      >
        <FormControl variant="outlined" style={{ width: "70%" }}>
          <TextField
            value={email.text}
            variant="outlined"
            onChange={(event) => {
              this.handleEmail(event.target.value, i);
            }}
            label="E-mail"
            disabled={email.sent || email.answered}
            error={!email.valid}
            helperText={!email.valid ? "E-mail inválido." : ""}
            style={{ marginRight: "20px" }}
          />
        </FormControl>
        <FormControl
          style={{ display: !email.answered ? "inline-block" : "none" }}
        >
          <Button
            variant="contained"
            disabled={!email.valid || email.text === ""}
            style={{
              background:
                email.valid && email.text !== ""
                  ? theme.secondary_color
                  : "#D5D5D5",
              color: "white",
              width: "30%",
              marginTop: "10px",
              display: email.sent ? "none" : "flex",
              textAlign: "center",
            }}
            onClick={() => {
              this.sendEmail(i);
            }}
          >
            Enviar
          </Button>
        </FormControl>

        <FormControl
          style={{ display: email.answered ? "inline-block" : "none" }}
        >
          <Button
            variant="contained"
            disabled={!email.valid || email.text === ""}
            style={{
              background:
                email.valid && email.text !== ""
                  ? theme.primary_color
                  : "#D5D5D5",
              color: theme.secondary_color,
              marginTop: "10px",
              display: email.sent ? "none" : "flex",
            }}
          >
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={{
                pathname:
                  "/quiz/" + this.state.points[this.state.dialogKey].quizId,
                state: { loggedUser: "true" },
              }}
              target="_blank"
              onClick={() => this.setQuizLocalStorage(true, false)}
            >
              Ver Respostas
            </Link>
          </Button>
        </FormControl>

        <FormControl
          style={{ display: !email.answered ? "inline-block" : "none" }}
        >
          <div
            style={{
              borderRadius: "5px",
              background: theme.primary_color,
              display: "flex",
              justifyContent: "center",
              height: "60%",
              alignItems: "center",
              marginTop: "6%",
            }}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                color: theme.secondary_color,
                display: email.sent ? "flex" : "none",
                margin: "5px",
                fontSize: "smaller",
              }}
            >
              AGUARDANDO RESPOSTA
            </Typography>
          </div>
        </FormControl>
      </div>
    ));
  }

  handleGapDialog() {
    this.loadGaps();
    this.setState({ gapDialog: !this.state.gapDialog });
  }

  async loadGaps() {
    let database = myApp.firestore();
    var points = this.state.points;
    var gaps = this.state.gaps;

    if (gaps.length === 0) {
      let values = Object.values(points);
      for (const point of values) {
        gaps = gaps.concat(point.gaps);
      }
    }

    const quizRegisteredAnswersRef = database.collection(
      "quiz_registered_answers"
    );
    const snapshot = await quizRegisteredAnswersRef
      .where("process_id", "==", this.props.processId)
      .get();
    let quizRegisteredAnswers = [];

    for (let doc of snapshot.docs) {
      var data = doc.data();
      data["id"] = doc.id;

      if (
        parseInt(data.process_stage) === -1 ||
        (isDefined(points[data.process_stage]) &&
          data.quiz_id === points[data.process_stage].quizId)
      ) {
        quizRegisteredAnswers.push(data);

        var docGaps = data.registered_answers
          .map((ra, index) => {
            var gap = ra.gap;

            if (gap.hasOwnProperty("name")) {
              gap["questionId"] = ra.question_id;
              gap["answerIndex"] = index;
              gap["quizRegisteredAnswerId"] = doc.id;
            }
            return gap;
          })
          .filter((value) => Object.keys(value).length !== 0);

        if (docGaps && docGaps.length !== 0 && docGaps.constructor !== Object) {
          var pointGaps = [];

          if (parseInt(data.process_stage) > -1) {
            pointGaps = points[data.process_stage].gaps;
          }

          for (let gap of docGaps) {
            if (
              gap.hasOwnProperty("name") &&
              !gaps.some(
                (g) =>
                  g.quizRegisteredAnswerId === gap.quizRegisteredAnswerId &&
                  g.answerIndex === gap.answerIndex
              )
            ) {
              gap["processStage"] = data.process_stage;

              if (parseInt(data.process_stage) > -1) {
                gap["processStageName"] = points[data.process_stage].name;
                pointGaps.push(gap);
              } else {
                gap["processStageName"] = gap.owner;
              }

              gaps.push(gap);
              
            }
          }

          if (parseInt(data.process_stage) > -1) {
            points[data.process_stage].gaps = pointGaps;
          }
        }

        if (parseInt(data.process_stage) > -1) {
          points[data.process_stage].gap = points[data.process_stage].gaps.some(
            (g) => g.active
          );
        }
      }
    }

    let auxCustomGAPS;
    try {
      let getCustomGAPS = await database.collection("processes").doc(this.props.processId).get()
      console.log("getCustomGAPS", getCustomGAPS.data().customGaps)
      auxCustomGAPS = getCustomGAPS.data().customGaps
    } catch (e) {
      console.log('Erro customGAPS', e)
    }

    this.setState({
      points,
      gaps,
      customGaps: auxCustomGAPS,
      quizRegisteredAnswers,
    });


  }

  setQuizLocalStorage(isStage, loggedUser = true) {
    localStorage.setItem("@blue-legal/quizLoggedUser", loggedUser);
    localStorage.setItem("@blue-legal/processId", this.props.processId);
    localStorage.setItem("processIdForQuiz", this.props.processId);

    if (isStage) {
      localStorage.setItem("@blue-legal/processStage", this.state.dialogKey);
    } else {
      localStorage.setItem("@blue-legal/processStage", -1);
    }
  }

  handleTransferCountry(event, transferIndex) {
    var points = this.state.points;

    points[this.state.dialogKey].transfers[transferIndex].country =
      event.target.value;

    this.setState({
      points,
    });
  }

  handleTransferHolder(event) {
    let value = event.target.value;

    this.setState({
      nameHolder: value,
    });
  }

  handleTransferHasLGPD(transferIndex) {
    var points = this.state.points;

    points[this.state.dialogKey].transfers[transferIndex].hasLGPD =
      !points[this.state.dialogKey].transfers[transferIndex].hasLGPD;

    this.setState({
      points,
    });
  }

  addTransfer() {
    var points = this.state.points;
    var transferItem = {
      country: "",
      hasLGPD: false,
    };

    points[this.state.dialogKey].transfers.push(transferItem);

    this.setState({
      points,
    });
  }

  removeTransfer(transferIndex) {
    var points = this.state.points;

    points[this.state.dialogKey].transfers.splice(transferIndex, 1);

    this.setState({
      points,
    });
  }

  handleSharingSource(event) {
    this.setState(
      {
        sharingSource: event.target.value,
      },
      () => {
        this.handleAddSharingButton();
      }
    );
  }

  handleSharingDestiny(event) {
    this.setState(
      {
        sharingDestiny: event.target.value,
      },
      () => {
        this.handleAddSharingButton();
      }
    );
  }

  handleSharingSourceSector(event) {
    this.setState(
      {
        sharingSourceSector: event.target.value,
      },
      () => {
        this.handleAddSharingButton();
      }
    );
  }

  handleSharingDestinySector(event) {
    this.setState(
      {
        sharingDestinySector: event.target.value,
      },
      () => {
        this.handleAddSharingButton();
      }
    );
  }

  handleSharingSourceOwner(event) {
    this.setState(
      {
        sharingSourceOwner: event.target.value,
      },
      () => {
        this.handleAddSharingButton();
      }
    );
  }

  handleSharingDestinyOwner(event) {
    this.setState(
      {
        sharingDestinyOwner: event.target.value,
      },
      () => {
        this.handleAddSharingButton();
      }
    );
  }

  handleAddSharingButton() {
    if (
      this.state.sharingSource !== "" &&
      this.state.sharingDestiny !== "" &&
      this.state.sharingSourceSector !== "" &&
      this.state.sharingDestinySector !== "" &&
      this.state.sharingSourceOwner !== "" &&
      this.state.sharingDestinyOwner !== ""
    ) {
      this.setState({
        addSharingButtonDisabled: false,
        saveSharingButtonDisabled: false,
      });
    } else {
      this.setState({
        addSharingButtonDisabled: true,
        saveSharingButtonDisabled: true,
      });
    }
  }

  handleAddSharingDialog() {
    this.setState({
      sharingDialog: !this.state.sharingDialog,
      sharingSource: "",
      sharingSourceSector: "",
      sharingSourceOwner: "",
      sharingDestiny: "",
      sharingDestinySector: "",
      sharingDestinyOwner: "",
      addSharingButtonDisabled: true,
    });
  }

  addSharing() {
    var points = this.state.points;
    var sharingItem = {
      source: this.state.sharingSource,
      sourceSector: this.state.sharingSourceSector,
      sourceOwner: this.state.sharingSourceOwner,
      destiny: this.state.sharingDestiny,
      destinySector: this.state.sharingDestinySector,
      destinyOwner: this.state.sharingDestinyOwner,
    };

    points[this.state.dialogKey].sharings.push(sharingItem);

    this.setState(
      {
        points,
      },
      () => {
        this.handleAddSharingDialog();
      }
    );
  }

  removeSharing(sharingIndex) {
    var points = this.state.points;

    points[this.state.dialogKey].sharings.splice(sharingIndex, 1);

    this.setState({
      points,
    });
  }

  handleEditSharingDialog(sharingIndex) {
    if (sharingIndex > -1) {
      var point = this.state.points[this.state.dialogKey];
      this.setState({
        sharingEditDialog: !this.state.sharingEditDialog,
        sharingSource: point.sharings[sharingIndex].source,
        sharingSourceSector: point.sharings[sharingIndex].sourceSector,
        sharingSourceOwner: point.sharings[sharingIndex].sourceOwner,
        sharingDestiny: point.sharings[sharingIndex].destiny,
        sharingDestinySector: point.sharings[sharingIndex].destinySector,
        sharingDestinyOwner: point.sharings[sharingIndex].destinyOwner,
        saveSharingButtonDisabled: false,
        sharingIndex,
      });
    } else {
      this.setState({
        sharingEditDialog: !this.state.sharingEditDialog,
        sharingSource: "",
        sharingSourceSector: "",
        sharingSourceOwner: "",
        sharingDestiny: "",
        sharingDestinySector: "",
        sharingDestinyOwner: "",
        saveSharingButtonDisabled: true,
        sharingIndex,
      });
    }
  }

  saveSharing() {
    var points = this.state.points;
    points[this.state.dialogKey].sharings[this.state.sharingIndex].source =
      this.state.sharingSource;
    points[this.state.dialogKey].sharings[
      this.state.sharingIndex
    ].sourceSector = this.state.sharingSourceSector;
    points[this.state.dialogKey].sharings[this.state.sharingIndex].sourceOwner =
      this.state.sharingSourceOwner;
    points[this.state.dialogKey].sharings[this.state.sharingIndex].destiny =
      this.state.sharingDestiny;
    points[this.state.dialogKey].sharings[
      this.state.sharingIndex
    ].destinySector = this.state.sharingDestinySector;
    points[this.state.dialogKey].sharings[
      this.state.sharingIndex
    ].destinyOwner = this.state.sharingDestinyOwner;

    this.setState(
      {
        points,
      },
      () => {
        this.handleEditSharingDialog(-1);
      }
    );
  }

  handleObservation(event) {
    var points = this.state.points;

    points[this.state.dialogKey].observation = event.target.value;

    this.setState({
      points,
    });
  }


  async handleViewListEmail() {
    let aux = [];
    let parts = this.state.processQuizId.split(" ");
    let quizId = parts[1];

    let get = await myApp.firestore().collection("questionnairesSentByEmail")
      .where("processId", "==", this.props.processId)
      .where("quizId", "==", quizId)
      .get();

    if (get.docs.length > 0) {
      for (let item of get.docs) {
        aux.push(item.data());
      }
    }

    this.setState({
      dialogViewSendMails: true,
      viewListEmail: aux
    });
  }

  async getCountrys() {
    let arrayCountrys = [];
    let querySnapshot = await myApp.firestore().collection("countrys").get();
    for (let doc of querySnapshot.docs) {
      arrayCountrys.push({ title: doc.data().title });
    }
    this.setState({
      countrys: arrayCountrys,
    });

    this.options = this.state.countrys.map((option) => {
      const firstLetter = option.title[0].toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
        ...option,
      };
    });
  }

  getSuppliers = async () => {
    let aux = [];
    try {
      let get = await myApp.firestore().collection("supplier").where("in_company", "==", localStorage.getItem('currentCompany')).get();
      for (let item of get.docs) {
        aux.push(item.data());
      }
    } catch (errorGetSuppliers) {
      console.log(errorGetSuppliers);
    }

    this.setState({
      suppliers: aux,
    })
  }

  getAssets = async () => {
    let assetsAux = [];
    let querySnapshot = await myApp.firestore().collection("assets").where("in_company", "==", localStorage.getItem("currentCompany")).get()
    for (let doc of querySnapshot.docs) {
      assetsAux.push({
        id: doc.id,
        cep: doc.data().cep,
        city: doc.data().city,
        complement: doc.data().complement,
        country: doc.data().country,
        in_company: doc.data().in_company,
        lgpd: doc.data().lgpd,
        name: doc.data().name,
        neighborhood: doc.data().neighborhood,
        number: doc.data().number,
        observation: doc.data().observation,
        nameOfResponsible: doc.data().nameOfResponsible,
        numberOfResponsible: doc.data().numberOfResponsible,
        emailOfResponsible: doc.data().emailOfResponsible,
        provider: doc.data().provider,
        place: doc.data().place,
        state: doc.data().state,
        street: doc.data().street,
        type: doc.data().type,
      });
    }
    this.setState({
      assetsDb: assetsAux
    })
  }

  //função responsável por preencher as informações do ativo
  handleChange = (prop) => (event) => {
    this.setState({
      assetsModal: { ...this.state.assetsModal, [prop]: event.target.value },
    })
  };

  //função responsável por preencher o pais
  handleSetCountry = (c) => {
    if (c) {
      this.setState({ assetsModal: { ...this.state.assetsModal, country: c.title } });
      // console.log(c.title)
    }
  }

  //função responsável por preencher as informações do ativo
  handleChangeLgpd = (event) => {
    this.setState({ assetsModal: { ...this.state.assetsModal, lgpd: event.target.value } });
  };



  //função responsável por fechar os dialogos
  handleCloseDialogs = (type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case 'addAssets':
        this.setState({
          dialogs: {
            ...this.state.dialogs,
            addAssets: false
          }
        })
        break;
      case 'assetSegisteredSuccessfully':
        this.setState({
          dialogs: {
            ...this.state.dialogs,
            assetSegisteredSuccessfully: false
          }
        })
        break;
      case 'addAssetsEdit':
        this.setState({
          dialogs: {
            ...this.state.dialogs,
            addAssetsEdit: false
          }
        })
        break;
      case 'assetEditSuccessfully':
        this.setState({
          dialogs: {
            ...this.state.dialogs,
            assetEditSuccessfully: false
          }
        })
        break;
    }
  };

  //função para registrar o ativo
  handleRegisteringActive = async () => {
    await myApp.firestore().collection("assets").add({
      cep: this.state.assetsModal.cep,
      city: this.state.assetsModal.city,
      complement: this.state.assetsModal.complement,
      country: this.state.assetsModal.country,
      in_company: localStorage.getItem("currentCompany"),
      lgpd: this.state.assetsModal.lgpd,
      name: this.state.assetsModal.name,
      neighborhood: this.state.assetsModal.neighborhood,
      number: this.state.assetsModal.number,
      observation: this.state.assetsModal.observation,
      nameOfResponsible: this.state.assetsModal.nameOfResponsible,
      numberOfResponsible: this.state.assetsModal.numberOfResponsible,
      emailOfResponsible: this.state.assetsModal.emailOfResponsible,
      provider: this.state.assetsModal.provider,
      place: this.state.assetsModal.place,
      state: this.state.assetsModal.state,
      street: this.state.assetsModal.street,
      type: this.state.assetsModal.type,
    }).then(() => {
      this.setState({
        assetsModal: {
          ...this.state.assetsModal,
          cep: '',
          city: '',
          complement: '',
          country: '',
          in_company: this.state.assetsModal.in_company,
          lgpd: '',
          name: '',
          neighborhood: '',
          number: '',
          observation: '',
          nameOfResponsible: '',
          numberOfResponsible: '',
          emailOfResponsible: '',
          provider: '',
          place: '',
          state: '',
          street: '',
          type: '',
        }
      })
      this.setState({
        dialogs: {
          ...this.state.dialogs,
          addAssets: false,
          assetSegisteredSuccessfully: true
        }
      })
      this.getAssets()
    });


  }

  //verificando e buscando CEP
  handleCheckZipCode = async (e) => {

    if (e === '') {
      this.useState({ assetsModal: { ...this.state.assetsModal, cep: '' } });
      // console.log(e.length)
    } else {
      this.useState({ assetsModal: { ...this.state.assetsModal, cep: e } });
      // console.log(e.length)
    }

    if (e.length === 8) {
      const req = await fetch("https://viacep.com.br/ws/" + e + "/json/");
      const json = await req.json();

      if (json) {
        this.useState({
          assetsModal: {
            ...this.state.assetsModal,
            street: json.logradouro,
            city: json.localidade,
            neighborhood: json.bairro,
            state: json.uf
          }
        })
      }
    }
  }

  handleSetDateToSendQuiz = (item) => {
    if (item.date != "/") {
      let data = new Date(item.date);
      let dia = String(data.getDate()).padStart(2, "0");
      let mes = String(data.getMonth() + 1).padStart(2, "0");
      let ano = data.getFullYear();
      let dataAtual = dia + "/" + mes + "/" + ano;
      return dataAtual;
    }
    else {
      return 'Data de envio com erro, contatar suporte!';
    }
  };


  render() {
    return (
      <div>
        {this.props.loadingProcess ? (
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            style={{ height: "85vh" }}
          >
            <Grid item>
              <CircularProgress size={60} color="#193993" />
            </Grid>
          </Grid>
        ) : (
          <>
            <Paper
              elevation={4}
              style={{
                position: "fixed",
                zIndex: 10,
                padding: "16px",
                borderRadius: "40px",
                marginTop: "16px",
                marginLeft: "4px",
              }}
            >
              <Grid
                container
                direction="column"
                style={{ width: "auto" }}
                spacing={2}
              >
                <Grid item>
                  <Tooltip title="Adicionar" placement="right" arrow>
                    <Fab
                      onClick={() => {
                        this.handleAddPoint();
                      }}
                      disabled={!this.state.userInformation.permissions.insert}
                      style={{ background: theme.secondary_color }}
                    >
                      <AddIcon style={{ color: theme.primary_color }} />
                    </Fab>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Deletar etapa" placement="right" arrow>
                    <Fab
                      onClick={() => {
                        this.handleDeleteDialog();
                      }}
                      disabled={!this.state.userInformation.permissions.delete}
                      style={{ background: theme.secondary_color }}
                    >
                      <DeleteIcon style={{ color: theme.primary_color }} />
                    </Fab>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Salvar" placement="right" arrow>
                    <Fab
                      onClick={() => {
                        this.saveProcess();
                      }}
                      disabled={!this.state.userInformation.permissions.update}
                      style={{ background: theme.secondary_color }}
                    >
                      <SaveIcon style={{ color: theme.primary_color }} />
                    </Fab>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Gaps" placement="right" arrow>
                    <Fab
                      onClick={() => {
                        this.handleGapDialog();
                      }}
                      style={{ background: theme.secondary_color }}
                    >
                      <ErrorOutlineIcon
                        style={{ color: theme.primary_color }}
                      />
                    </Fab>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip
                    title="Responder/Visualizar questionário"
                    placement="right"
                    arrow
                  >
                    <Fab
                      onClick={() => this.setQuizLocalStorage(false)}
                      disabled={
                        !this.state.userInformation.permissions.update ||
                        this.state.answerQuizButtonDisabled
                      }
                      style={{ background: theme.secondary_color }}
                    >
                      <QuestionAnswerRoundedIcon
                        style={{ color: theme.primary_color }}
                        onClick={() => { this.setState({ dialogViewQuizz: true }) }}
                      />

                      <Dialog
                        onClose={() => {
                          this.setState({ dialogViewQuizz: false });
                        }}
                        fullWidth
                        open={this.state.dialogViewQuizz}
                        PaperProps={{
                          style: {
                            width: "auto",
                            padding: "16px",
                          },
                        }}
                      >
                        <DialogTitle>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                          >
                            <Grid
                              item
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item >
                                Questionário: {this.state.getQuizInfoName}
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                              style={{ marginBottom: '15px' }}
                            >
                              <Grid item style={{ marginRight: '5px' }} >
                                Status: {this.state.getQuizInfoStatus ? 'Concluído' : 'Pendente'}
                              </Grid>
                              <Grid item >
                                {
                                  this.state.getQuizInfoStatus
                                    ? <CheckCircleSharpIcon
                                      style={{ marginTop: '10px', color: theme.primary_color }}
                                    />
                                    : <ErrorSharpIcon style={{ marginTop: '10px', color: '#f00' }} />
                                }
                              </Grid>
                            </Grid>
                          </Grid>
                        </DialogTitle>
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                          spacing={4}
                        >
                          <Grid container style={{ backgroundColor: '#fff', width: '90%' }} >
                            <Grid item xs={9} >
                              <TextField
                                onChange={(e) => this.setState({ emailInputModal: e.target.value })}
                                variant="filled"
                                label="Digite um email para enviar o questionário"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={3} >
                              <Button
                                variant="contained"
                                style={{
                                  background: theme.primary_color,
                                  color: theme.secondary_color,
                                  margin: '20px'
                                }}
                                onClick={() => this.sendQuestionForMailModal()}
                              >
                                Enviar
                              </Button>
                            </Grid>
                          </Grid>
                          {/* <Grid item>
                            <Link
                              to={{
                                pathname: "/quiz/" + this.state.processQuizId,
                                state: { loggedUser: "true" },
                              }}
                              target="_blank"
                              disabled={
                                !this.state.userInformation.permissions.update ||
                                !this.state.userInformation.permissions.insert ||
                                this.state.answerQuizButtonDisabled
                              }
                            >
                              <Button
                                variant="contained"
                                style={{
                                  background: theme.secondary_color,
                                  color: "white",
                                  width: "100%",
                                  height: "56px",
                                }}
                              >
                                Visualizar ou responder o questionário
                              </Button>
                            </Link>
                          </Grid> */}

                          <Grid item>
                            {Object.keys(this.state.points).length === 0 ? (
                              <Button
                                variant="contained"
                                style={{
                                  background: "#ccc",
                                  color: "white",
                                  width: "100%",
                                  height: "56px",
                                  cursor: "not-allowed",
                                }}
                                disabled
                              >
                                Não é possível responder o questionário antes de criar uma etapa
                              </Button>
                            ) : (
                              <Link
                                to={{
                                  pathname: "/quiz/" + this.state.processQuizId + '/' + this.props.processId,
                                  state: { loggedUser: "true" },
                                }}
                                target="_blank"
                                disabled={
                                  !this.state.userInformation.permissions.update ||
                                  !this.state.userInformation.permissions.insert ||
                                  this.state.answerQuizButtonDisabled
                                }
                              >
                                <Button
                                  variant="contained"
                                  style={{
                                    background: theme.secondary_color,
                                    color: "white",
                                    width: "100%",
                                    height: "56px",
                                  }}
                                >
                                  Visualizar ou responder o questionário
                                </Button>
                              </Link>
                            )}
                          </Grid>

                          <Grid item>
                            <Button
                              variant="contained"
                              style={{
                                background: theme.secondary_color,
                                color: "white",
                                width: "100%",
                                height: "56px",
                              }}
                              onClick={() => this.handleViewListEmail()}
                            >
                              <Tooltip title="Visualizar lista de emails que já foram enviados">
                                <VisibilityIcon />
                              </Tooltip>
                            </Button>
                          </Grid>

                          <Grid item>
                            <Button
                              variant="contained"
                              style={{
                                background: theme.warning_color,
                                color: "white",
                                width: "100%",
                                height: "56px",
                              }}
                              onClick={() => {
                                this.setState({ dialogViewQuizz: false });
                              }}
                            >
                              Sair
                            </Button>
                          </Grid>
                        </Grid>
                      </Dialog>

                      <Dialog
                        open={this.state.dialogViewSendMails}
                        onClose={() => {
                          this.setState({ dialogViewSendMails: false });
                        }}
                      >
                        <DialogTitle>Lista de email enviados</DialogTitle>
                        <DialogContent>
                          <TableContainer style={{ maxHeight: "80vh" }}>
                            <Table stickyHeader >
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    colSpan={6}
                                    style={{
                                      fontWeight: "bold",
                                      backgroundColor: "#fff",
                                      textAlign: "center",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Email
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    colSpan={6}
                                    style={{
                                      fontWeight: "bold",
                                      backgroundColor: "#fff",
                                      textAlign: "center",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Data
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {
                                  this.state.viewListEmail.length > 0
                                    ?
                                    this.state.viewListEmail.map((item, index) => (
                                      <TableRow
                                        key={index}
                                        sx={{
                                          "&:last-child td, &:last-child th": { border: 0 },
                                        }}
                                        style={
                                          index % 2
                                            ? { background: "white" }
                                            : { background: "#e3e3e3" }
                                        }
                                      >
                                        <TableCell align="center" colSpan={6}>{item.email}</TableCell>
                                        <TableCell align="center" colSpan={6}>{this.handleSetDateToSendQuiz(item)}</TableCell>
                                      </TableRow>
                                    ))
                                    :
                                    <TableRow
                                      style={{
                                        background: "#e3e3e3"
                                      }}
                                    >
                                      <TableCell align="center" colSpan={11}>Esse questionário ainda não foi enviado</TableCell>
                                      <TableCell align="center" colSpan={1}></TableCell>
                                    </TableRow>
                                }
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={() => this.setState({ dialogViewSendMails: false })}
                            variant="contained"
                            style={{
                              background: theme.secondary_color,
                              color: "white",
                              width: "100%",
                              height: "56px",
                            }}
                          >
                            Ok
                          </Button>
                        </DialogActions>
                      </Dialog>

                      {/* <Link
                        to={{
                          pathname: "/quiz/" + this.state.processQuizId,
                          state: { loggedUser: "true" },
                        }}
                        target="_blank"
                        disabled={
                          !this.state.userInformation.permissions.update ||
                          !this.state.userInformation.permissions.insert ||
                          this.state.answerQuizButtonDisabled
                        }
                      >
                        <QuestionAnswerRoundedIcon
                          style={{ color: theme.primary_color }}
                        />
                      </Link>
                      */}
                    </Fab>
                  </Tooltip>
                </Grid>

                <Grid item>
                  <Tooltip title="Editar processo" placement="right" arrow>
                    <Fab
                      onClick={() => {
                        this.handleEditProcessDialog();
                      }}
                      style={{ background: theme.secondary_color }}
                    >
                      <EditIcon style={{ color: theme.primary_color }} />
                    </Fab>
                  </Tooltip>
                </Grid>


                <Grid item>
                  <Tooltip title="Sair" placement="right" arrow>
                    <Fab
                      onClick={() => {
                        this.handleExit();
                      }}
                      style={{ background: theme.secondary_color }}
                    >
                      <ExitToAppIcon style={{ color: theme.primary_color }} />
                    </Fab>
                  </Tooltip>
                </Grid>
              </Grid>
            </Paper>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={4}
            >
              <Grid item>
                <Flowspace
                  arrowStart={false}
                  arrowEnd={true}
                  getDiagramRef={(ref) => {
                    this.diagramRef = ref;
                  }}
                  selected={this.state.selected}
                  selectedLine={this.state.selectedLine}
                  onClick={() => {
                    this.setState({ selected: null, selectedLine: null });
                  }}
                  onLineClick={(key_a, key_b) => {
                    this.setState({ selectedLine: { a: key_a, b: key_b } });
                  }}
                  style={{ overflow: "hidden" }}
                >
                  {Object.keys(this.state.points).map((key) => {
                    var point = this.state.points[key];
                    return (
                      <Flowpoint
                        theme="green"
                        variant="outlined"
                        key={key}
                        startPosition={point.pos}
                        outputs={point.outputs}
                        onClick={(e) => {
                          this.handleClick(key, e);
                        }}
                        onTouch={(e) => {
                          this.handleTouch(key);
                        }}
                        onDrag={(pos) => {
                          var points = this.state.points;

                          points[key].pos = pos;

                          this.setState({ points, lastPos: pos, saved: false });
                        }}
                        style={{
                          display: "inline-table",
                          width: "220px",
                        }}
                      >
                        <Grid
                          container
                          direction="column"
                          style={{ padding: "4px" }}
                        >
                          <Grid item>
                            <Grid
                              container
                              direction="row"
                              justify="center"
                              alignItems="center"
                            >
                              <Grid style={{ flexGrow: 1 }} />
                              <Grid item>
                                <Tooltip title="Configurar etapa" placement="right" arrow>
                                  <Button
                                    style={{ minWidth: "0px" }}
                                    onClick={() => {
                                      this.handleDialog(key);
                                    }}

                                  >
                                    <SettingsIcon
                                      fontSize="inherit"
                                      style={{ color: theme.primary_color }}
                                    />
                                  </Button>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid style={{ height: "8px" }} />
                          <Grid item>
                            <Grid
                              container
                              direction="column"
                              justify="center"
                              alignItems="center"
                            >
                              <Grid item>
                                {point.name !== "" ? (
                                  <Typography
                                    variant="subtitle2"
                                    component="subtitle2"
                                    style={{
                                      color: theme.secondary_color,
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    {point.name}
                                  </Typography>
                                ) : (
                                  <Typography
                                    variant="subtitle2"
                                    component="subtitle2"
                                    style={{
                                      color: theme.secondary_color,
                                      opacity: "0.6",
                                    }}
                                  >
                                    {"Nome da Etapa.."}
                                  </Typography>
                                )}
                              </Grid>
                              <Grid item>
                                {point.type !== "" ? (
                                  <div>
                                    <Typography
                                      variant="body1"
                                      component="body1"
                                      style={{
                                        color: theme.secondary_color,
                                        fontSize: "10px",
                                      }}
                                    >
                                      {"Tipo de Tarefa: "}
                                    </Typography>
                                    <Typography
                                      variant="body1"
                                      component="body1"
                                      style={{
                                        color: theme.secondary_color,
                                        fontWeight: "bolder",
                                        fontSize: "10px",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {point.type}
                                    </Typography>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid style={{ height: "8px" }} />
                          <Grid item>
                            <Grid container direction="row" justify="flex-end">
                              <Grid item>
                                {point.gap ? (
                                  <Button
                                    onClick={() => {
                                      this.handleDialog(key, true);
                                    }}
                                    style={{ minWidth: "0px" }}
                                  >
                                    <ErrorOutlineIcon
                                      fontSize="inherit"
                                      style={{ color: "#EA1C1C" }}
                                    />
                                  </Button>
                                ) : (
                                  <div />
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Flowpoint>
                    );
                  })}
                </Flowspace>
              </Grid>
            </Grid>

            <Dialog
              onClose={() => {
                this.handleDialog(-1);
              }}
              fullWidth={true}
              maxWidth={"xl"}
              open={this.state.dialog}
              style={{ padding: "0px !important" }}
            >
              <DialogTitle>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item style={{ color: theme.secondary_color }}>
                    Configurações
                  </Grid>
                  <Grid style={{ flexGrow: 1 }} />
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        this.handleDialogCloseProcesosConfigEdit();
                      }}
                      style={{ color: theme.secondary_color }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </DialogTitle>
              {this.state.dialogKey !== -1 ? (
                <div>
                  <AppBar position="static">
                    <Tabs
                      centered
                      variant="fullWidth"
                      TabIndicatorProps={{
                        style: { background: theme.primary_color },
                      }}
                      value={this.state.tabIndex}
                      onChange={this.handleTab}
                    >
                      <Tab label="Informações" icon={<CreateIcon />} />
                    </Tabs>
                  </AppBar>
                  <div
                    value={this.state.tabIndex}
                    hidden={this.state.tabIndex !== 0}
                    style={{ maxHeight: "70vh", overflowY: "scroll" }}
                  >
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="flex-end"
                      style={{ padding: "16px" }}
                    >
                      <Grid item style={{ width: "100%" }}>
                        <TextField
                          label="Nome da Etapa"
                          variant="outlined"
                          value={this.state.points[this.state.dialogKey].name}
                          onChange={(event) => this.handlePointName(event)}
                          disabled={
                            !this.state.userInformation.permissions.update
                          }
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item style={{ height: "16px" }} />
                      <Grid item style={{ width: "100%" }}>
                        <FormControl
                          variant="outlined"
                          style={{ width: "100%" }}
                        >
                          <InputLabel>Tipo de Tarefa</InputLabel>
                          <Select
                            value={this.state.points[this.state.dialogKey].type}
                            onChange={(event) => {
                              this.handlePointType(event);
                            }}
                            disabled={
                              !this.state.userInformation.permissions.update
                            }
                            label="Tipo de Tarefa"
                          >
                            <MenuItem value={""}></MenuItem>
                            {/* <MenuItem value={"dadosGerais"}>Dados Gerais</MenuItem> */}
                            <MenuItem value={"coleta"}>Coleta</MenuItem>
                            <MenuItem value={"coletaConsentimento"}>
                              Coleta de Consentimento
                            </MenuItem>
                            <MenuItem value={"origemDestinoDado"}>
                              Origem e Destino do Dado
                            </MenuItem>
                            <MenuItem value={"tratamento"}>Tratamento</MenuItem>
                            <MenuItem value={"armazenamento"}>
                              Armazenamento
                            </MenuItem>
                            <MenuItem value={"compartilhamentoInterno"}>
                              Compartilhamento Interno
                            </MenuItem>
                            <MenuItem value={"compartilhamentoExterno"}>
                              Compartilhamento Externo
                            </MenuItem>
                            <MenuItem value={"transferenciaInter"}>
                              Transferência Internacional
                            </MenuItem>
                            <MenuItem value={"descarte"}>Descarte</MenuItem>
                            <MenuItem value={"controleAcesso"}>
                              Controle de Acesso
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {this.state.points[this.state.dialogKey].type ===
                        "armazenamento" && (
                          <>
                            <Grid item style={{ height: "16px" }} />
                            <Grid item style={{ width: "100%" }}>
                              <TextField
                                label="Tipo de armazenamento"
                                variant="outlined"
                                value={
                                  this.state.points[this.state.dialogKey]
                                    .storageType
                                }
                                onChange={(event) =>
                                  this.handleStorageType(event)
                                }
                                disabled={
                                  !this.state.userInformation.permissions.update
                                }
                                style={{ width: "100%" }}
                              />
                            </Grid>
                          </>
                        )}
                      <Grid item style={{ height: "16px" }} />
                      {/*<Grid item style={{ width: "100%" }}>
                        <FormControl
                          variant="outlined"
                          style={{ width: "100%" }}
                        >
                          <InputLabel>Questionário</InputLabel>
                          <Select
                            value={
                              this.state.points[this.state.dialogKey].quizId
                            }
                            onChange={(event) => {
                              this.handleQuizId(event);
                            }}
                            disabled={
                              !this.state.userInformation.permissions.update
                            }
                            label="Questionário"
                          >
                            <MenuItem value={""}></MenuItem>
                            {this.state.quizes.map((quiz, quizIndex) => (
                              <MenuItem value={quiz.id}>{quiz.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                            </Grid>*/
                      }
                      <Grid item style={{ height: "16px" }} />
                      <Grid item style={{ width: "100%" }}>
                        <TextField
                          multiline
                          rows={3}
                          label="Observações"
                          variant="outlined"
                          value={
                            this.state.points[this.state.dialogKey].observation
                          }
                          onChange={(event) => this.handleObservation(event)}
                          disabled={
                            !this.state.userInformation.permissions.update
                          }
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      {/**lista ed ativos */}
                      <Grid
                        container
                        direction="row"
                      >
                        <Grid
                          item
                          style={{
                            width: "100%",
                            paddingTop: "16px",
                            paddingBottom: "16px",
                          }}
                        >
                          <Typography
                            variant="h6"
                            component="h6"
                            style={{ color: "#6A6A6A", textAlign: "center" }}
                          >
                            {"Lista de ativos"}
                          </Typography>
                        </Grid>
                        <Grid item style={{ width: "100%" }}>
                          <Grid
                            item
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            spacing={4}
                          >
                            <Grid item xs={10}>
                              <FormControl
                                variant="outlined"
                                style={{ width: "100%" }}
                              >
                                <InputLabel>Ativos</InputLabel>
                                <Select
                                  value={this.state.assets}
                                  onChange={(event) => {
                                    this.handleAddAsset(event.target.value);
                                    this.addAssets(event.target.value);
                                  }}
                                  /*disabled={
                                    !this.state.userInformation.permissions
                                      .update
                                  }*/
                                  label="Ativos"
                                >
                                  <MenuItem value={""}></MenuItem>
                                  {this.state.assetsDB.map((i) => (
                                    <MenuItem value={'Ativo: ' + i.name + `${i.type !== '' ? ' Tipo: ' : ''}` + i.type} >
                                      Ativo: {i.name} {i.type !== '' && 'Tipo:'} {i.type}
                                    </MenuItem>

                                    
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid item xs={2}>
                              <Button
                                variant="contained"
                                style={{
                                  background: theme.secondary_color,
                                  color: "white",
                                  width: "100%",
                                  height: "56px",
                                }}
                                //  disabled={
                                //     !this.state.userInformation.permissions
                                //       .insert ||
                                //     this.state.addAttributeButtonDisabled
                                //   }
                                onClick={() => {
                                  this.handleDialogCreateActiveModal();
                                  // this.addAssets();
                                }}
                              >
                                Cadastrar Ativo
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          direction="column"
                        >
                          {/* Dialogo de cadastro do ativo */}
                          <Dialog
                            open={this.state.createActiveModalOpen}
                            onClose={() => this.handleCloseDialogs('addAssets')}
                            fullWidth
                          >
                            <DialogTitle
                              style={{
                                color: theme.quaternary_color,
                                backgroundColor: theme.secondary_color,
                                textAlign: "center",
                              }}
                            >
                              Cadastro de ativo
                            </DialogTitle>
                            <DialogContent>
                              <Grid
                                container
                                direction="column"
                              >
                                <Grid
                                  item
                                  style={{
                                    marginTop: 10
                                  }}
                                >
                                  <TextField
                                    label="Nome do ativo"
                                    variant="outlined"
                                    value={this.state.assetsModal.name}
                                    fullWidth
                                    style={{
                                      width: "100%",
                                      marginRight: '10px'
                                    }}
                                    onChange={this.handleChange('name')}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  hidden={this.state.assetsModal.name.length < 3 ? true : false}
                                  style={{
                                    marginTop: 10
                                  }}
                                >
                                  <TextField
                                    label="Tipo de ativo"
                                    value={this.state.assetsModal.type}
                                    variant="outlined"
                                    fullWidth
                                    style={{
                                      width: "100%",
                                      marginRight: '10px'
                                    }}
                                    onChange={this.handleChange('type')}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  hidden={this.state.assetsModal.type.length < 3 ? true : false}
                                  style={{
                                    marginTop: 10
                                  }}
                                >
                                  <TextField
                                    label="Local"
                                    value={this.state.assetsModal.place}
                                    variant="outlined"
                                    fullWidth
                                    style={{
                                      width: "100%",
                                      marginRight: '10px'
                                    }}
                                    onChange={this.handleChange('place')}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  hidden={this.state.assetsModal.place.length < 3 ? true : false}
                                  style={{
                                    marginTop: 10
                                  }}
                                >
                                  <Autocomplete
                                    options={this.options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                    groupBy={(option) => option.firstLetter}
                                    getOptionLabel={(option) => option.title}
                                    fullWidth
                                    onChange={(e, a) => this.handleSetCountry(a)}
                                    renderInput={(params) => <TextField {...params} label="Países" variant="outlined" />}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  hidden={this.state.assetsModal.country.toUpperCase() === 'BRASIL' ? false : true}
                                  style={{
                                    marginTop: 10
                                  }}
                                >
                                  <TextField
                                    label="cep"
                                    value={this.state.assetsModal.cep}
                                    variant="outlined"
                                    fullWidth
                                    style={{
                                      width: "100%",
                                      marginRight: '10px'
                                    }}
                                    onChange={(e) => this.handleCheckZipCode(e.target.value)}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  hidden={this.state.assetsModal.country.toUpperCase() === 'BRASIL' ? false : true}
                                  style={{
                                    marginTop: 10
                                  }}
                                  disabled
                                >
                                  <TextField
                                    label="Rua"
                                    value={this.state.assetsModal.street}
                                    variant="outlined"
                                    fullWidth
                                    disabled
                                    style={{
                                      width: "100%",
                                      marginRight: '10px',
                                      backgroundColor: '#ccc'
                                    }}
                                    onChange={this.handleChange('street')}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  hidden={this.state.assetsModal.country.toUpperCase() === 'BRASIL' ? false : true}
                                  style={{
                                    marginTop: 10
                                  }}
                                >
                                  <TextField
                                    label="Numero"
                                    value={this.state.assetsModal.number}
                                    variant="outlined"
                                    fullWidth
                                    style={{
                                      width: "100%",
                                      marginRight: '10px',
                                    }}
                                    onChange={this.handleChange('number')}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  hidden={this.state.assetsModal.country.toUpperCase() === 'BRASIL' ? false : true}
                                  style={{
                                    marginTop: 10
                                  }}
                                  disabled
                                >
                                  <TextField
                                    label="Bairro"
                                    value={this.state.assetsModal.neighborhood}
                                    variant="outlined"
                                    fullWidth
                                    disabled
                                    style={{
                                      width: "100%",
                                      marginRight: '10px',
                                      backgroundColor: '#ccc'
                                    }}
                                    onChange={this.handleChange('neighborhood')}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  hidden={this.state.assetsModal.country.toUpperCase() === 'BRASIL' ? false : true}
                                  style={{
                                    marginTop: 10
                                  }}
                                  disabled
                                >
                                  <TextField
                                    label="Estado"
                                    value={this.state.assetsModal.state}
                                    variant="outlined"
                                    fullWidth
                                    disabled
                                    style={{
                                      width: "100%",
                                      marginRight: '10px',
                                      backgroundColor: '#ccc'
                                    }}
                                    onChange={this.handleChange('state')}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  hidden={this.state.assetsModal.country.toUpperCase() === 'BRASIL' ? false : true}
                                  style={{
                                    marginTop: 10
                                  }}
                                  disabled
                                >
                                  <TextField
                                    label="Cidade"
                                    value={this.state.assetsModal.city}
                                    variant="outlined"
                                    fullWidth
                                    disabled
                                    style={{
                                      width: "100%",
                                      marginRight: '10px',
                                      backgroundColor: '#ccc'
                                    }}
                                    onChange={this.handleChange('city')}
                                  />
                                </Grid>


                                <Grid
                                  item
                                  hidden={this.state.assetsModal.country.toUpperCase() === 'BRASIL' ? false : true}
                                  style={{
                                    marginTop: 10
                                  }}
                                >
                                  <TextField
                                    label="Complemento"
                                    value={this.state.assetsModal.complement}
                                    variant="outlined"
                                    fullWidth
                                    style={{
                                      width: "100%",
                                      marginRight: '10px',
                                    }}
                                    onChange={this.handleChange('complement')}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  hidden={this.state.assetsModal.country.toUpperCase() !== 'BRASIL' && this.state.assetsModal.country.length > 3 ? false : true}
                                  style={{
                                    marginTop: 10
                                  }}
                                >
                                  <TextField
                                    label="Observação"
                                    value={this.state.assetsModal.observation}
                                    variant="outlined"
                                    fullWidth
                                    style={{
                                      width: "100%",
                                      marginRight: '10px',
                                    }}
                                    onChange={this.handleChange('observation')}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  hidden={this.state.assetsModal.observation.length < 3 ? true : false}
                                  style={{
                                    marginTop: 10
                                  }}
                                >
                                  <FormControl component="fieldset">
                                    <FormLabel component="legend">Possui LGPD</FormLabel>
                                    <RadioGroup aria-label="gender" name="gender1" value={this.state.assetsModal.lgpd} onChange={this.handleChangeLgpd}>
                                      <FormControlLabel value={'Sim'} control={
                                        <Radio style={{
                                          color: "#38f205"
                                        }}
                                        />
                                      } label="Sim" />
                                      <FormControlLabel value={'Não'} control={
                                        <Radio
                                          style={{
                                            color: "#f00"
                                          }}
                                        />
                                      } label="Não" />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>

                                <Grid
                                  item
                                  hidden={this.state.assetsModal.country.length > 3 ? false : true}
                                  style={{
                                    marginTop: 10
                                  }}
                                >
                                  <TextField
                                    label="Nome do responsável"
                                    value={this.state.assetsModal.nameOfResponsible}
                                    variant="outlined"
                                    fullWidth
                                    style={{
                                      width: "100%",
                                      marginRight: '10px',
                                    }}
                                    onChange={this.handleChange('nameOfResponsible')}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  hidden={this.state.assetsModal.country.length > 3 ? false : true}
                                  style={{
                                    marginTop: 10
                                  }}
                                >
                                  <TextField
                                    label="Telefone do responsável"
                                    value={this.state.assetsModal.numberOfResponsible}
                                    variant="outlined"
                                    fullWidth
                                    style={{
                                      width: "100%",
                                      marginRight: '10px',
                                    }}
                                    onChange={this.handleChange('numberOfResponsible')}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  hidden={this.state.assetsModal.country.length > 3 ? false : true}
                                  style={{
                                    marginTop: 10
                                  }}
                                >
                                  <TextField
                                    label="Email do responsável"
                                    value={this.state.assetsModal.emailOfResponsible}
                                    variant="outlined"
                                    fullWidth
                                    style={{
                                      width: "100%",
                                      marginRight: '10px',
                                    }}
                                    onChange={this.handleChange('emailOfResponsible')}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  hidden={this.state.assetsModal.country.length > 3 ? false : true}
                                  style={{
                                    marginTop: 10
                                  }}
                                >
                                  <FormControl
                                    fullWidth
                                  >
                                    <InputLabel>Fornecedor</InputLabel>
                                    <Select
                                      value={this.state.assetsModal.provider}
                                      onChange={this.handleChange('provider')}
                                    >
                                      {
                                        this.state.suppliers.length === 0 ?
                                          <MenuItem value="">SEM FORNECEDOR CADASTRADO</MenuItem> :
                                          this.state.suppliers.map((item, index) => (
                                            <MenuItem key={index} value={item.nome}>{item.nome}</MenuItem>
                                          ))
                                      }
                                    </Select>
                                  </FormControl>
                                </Grid>



                              </Grid>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={() => this.handleDialogCreateActiveModal()}
                                autoFocus
                              >
                                Cancelar
                              </Button>
                              <Button
                                onClick={() => this.handleRegisteringActive()}
                                style={{
                                  color: '#192a40',
                                  backgroundColor: '#38f205',
                                }}
                              >
                                Cadastrar
                              </Button>
                            </DialogActions>
                          </Dialog>

                          {/* Dialogo para informar que 
                              ativo foi cadastrado com sucesso
                            */}
                          <Dialog open={this.state.dialogs.assetSegisteredSuccessfully} onClose={() => this.handleCloseDialogs('assetSegisteredSuccessfully')}>
                            <DialogTitle
                              style={{
                                color: theme.quaternary_color,
                                backgroundColor: theme.secondary_color,
                                textAlign: "center",
                              }}
                            >
                              ATENÇÃO!
                            </DialogTitle>
                            <DialogContent>
                              <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Grid
                                  item
                                  textAlign="center"
                                >
                                  <DialogContentText>
                                    <Typography
                                      variant="h6"
                                      component="h6"
                                    >
                                      Ativo cadastrado com sucesso!
                                    </Typography>
                                  </DialogContentText>
                                </Grid>
                              </Grid>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={() => this.handleCloseDialogs('assetSegisteredSuccessfully')}
                                autoFocus
                              >
                                Ok
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </Grid>
                        <Grid item style={{ width: "100%" }}>
                          <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            spacing={4}
                          >
                            <Grid item style={{ height: "16px" }} />
                            {this.state.actualAtive?.map((assets, index) => (
                              <Grid item style={{ width: "100%" }}>
                                <Paper
                                  variant="contained"
                                  style={{
                                    width: "100%",
                                    background: "white",
                                    height: 50
                                  }}
                                >
                                  <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                  >
                                    <Grid item style={{ marginLeft: "8px" }}>
                                      <Typography>
                                        {assets.asset}
                                      </Typography>
                                    </Grid>
                                    <Grid style={{ flexGrow: 1 }} />
                                    <Grid item style={{ marginLeft: "8px" }}>
                                      <IconButton
                                        onClick={() => {
                                          this.handleRemoveAsset(index);
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                </Paper>
                              </Grid>
                            ))}
                          
                            {this.state.points[0]?.assets !== 0 &&
                              this.state.points[this.state.dialogKey].assets?.map((assets,index) => (
                                <Grid item style={{ width: "100%" }}>
                                  <Paper
                                    variant="contained"
                                    style={{
                                      width: "100%",
                                      background: "white",
                                      height: 50
                                    }}
                                  >
                                    <Grid
                                      container
                                      direction="row"
                                      alignItems="center"
                                    >
                                      <Grid item style={{ marginLeft: "8px" }}>
                                        <Typography>
                                          {assets.asset}
                                        </Typography>
                                      </Grid>
                                      <Grid style={{ flexGrow: 1 }} />
                                      <IconButton
                                        onClick={() => {
                                          this.handleRemoveAssetDb(assets,index);
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Grid>
                                  </Paper>
                                </Grid>
                              ))
                            }

                          
                          </Grid>
                        </Grid>
                      </Grid>
                      {this.state.points[this.state.dialogKey].type ===
                        "descarte" && (
                          <>
                            {
                              Object.values(this.state.points).map((item, indexEtapa) => (
                                <Grid
                                  container
                                  item
                                  style={{ marginTop: 10 }}
                                  key={indexEtapa}
                                >
                                  <Accordion style={{ width: '100%' }}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                    >
                                      <Typography>Etapa: {item.type}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>

                                      <Grid
                                        container
                                        direction="column"
                                        item
                                      >

                                        {
                                          item.assets ?
                                            item.assets.map((itemAsset, indexAsset) => (
                                              <Grid
                                                item
                                                key={indexAsset}
                                              >
                                                <Accordion style={{ width: '100%' }}>
                                                  <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                  >
                                                    <Typography>{itemAsset.asset}</Typography>
                                                  </AccordionSummary>
                                                  <AccordionDetails>
                                                    <Grid
                                                      container
                                                      direction="column"
                                                      style={{ height: 850 }}
                                                    >
                                                      <Grid
                                                        container
                                                        item
                                                        direction="column"
                                                      >
                                                        <Grid
                                                          container
                                                          item
                                                          direction="row"
                                                          spacing={2}
                                                        >
                                                          <Grid
                                                            item
                                                            style={{ width: '33%' }}
                                                          >
                                                            <FormControl
                                                              variant="outlined"
                                                              fullWidth
                                                            >
                                                              <InputLabel>Evento</InputLabel>
                                                              <Select
                                                                label="Evento"
                                                                value={this.state.eventAttribute}
                                                                onChange={(event) => this.setState({ eventAttribute: event.target.value })}
                                                              >
                                                                <MenuItem value=""></MenuItem>
                                                                <MenuItem value="Rescisão">Rescisão</MenuItem>
                                                                <MenuItem value="Desligamento">Desligamento</MenuItem>
                                                                <MenuItem value="Solicitação">Solicitação</MenuItem>
                                                                <MenuItem value="Tempo">Tempo</MenuItem>
                                                              </Select>
                                                            </FormControl>
                                                          </Grid>
                                                          <Grid
                                                            item
                                                            style={{ width: '33%' }}
                                                          >
                                                            <TextField
                                                              label="Prazo de Descarte  (dias)"
                                                              variant="outlined"
                                                              fullWidth
                                                              type="number"
                                                              value={this.state.disposalTerm}
                                                              onChange={(event) => this.setState({ disposalTerm: event.target.value })}
                                                            />
                                                          </Grid>
                                                          <Grid
                                                            item
                                                            style={{ width: '34%' }}
                                                          >
                                                            <FormControl
                                                              variant="outlined"
                                                              fullWidth
                                                            >
                                                              <InputLabel>Método de Descarte</InputLabel>
                                                              <Select
                                                                label="Método de Descarte"
                                                                value={this.state.disposalMethod}
                                                                onChange={(event) => this.setState({ disposalMethod: event.target.value })}
                                                              >
                                                                <MenuItem value=""></MenuItem>
                                                                <MenuItem value="Anonimização">Anonimização</MenuItem>
                                                                <MenuItem value="Despersonificação ">Despersonificação </MenuItem>
                                                                <MenuItem value="Exclusão">Exclusão </MenuItem>
                                                                <MenuItem value="Transferencia Arquivo morto">Transferencia Arquivo morto</MenuItem>
                                                              </Select>
                                                            </FormControl>
                                                          </Grid>
                                                        </Grid>
                                                        <Grid
                                                          container
                                                          item
                                                          direction="row"
                                                          style={{ marginTop: 10 }}
                                                        >
                                                          <Grid
                                                            item
                                                            style={{ width: '100%' }}
                                                          >
                                                            <FormControl
                                                              variant="outlined"
                                                              fullWidth
                                                            >
                                                              <InputLabel>Legislação</InputLabel>
                                                              <Select
                                                                label="Legislação"
                                                                value={this.state.legislation}
                                                                onChange={(event) => this.setState({ legislation: event.target.value })}
                                                              >
                                                                <MenuItem value=""></MenuItem>
                                                                {
                                                                  this.state.listLegislation.length > 0
                                                                    ?
                                                                    this.state.listLegislation.map((item, index) => (
                                                                      <MenuItem value={item.law}>{item.law}</MenuItem>
                                                                    ))
                                                                    :
                                                                    <MenuItem value="">Sem bases cadastradas</MenuItem>
                                                                }
                                                              </Select>
                                                            </FormControl>
                                                          </Grid>
                                                        </Grid>
                                                        <Grid
                                                          container
                                                          item
                                                          direction="row"
                                                          style={{ marginTop: 10 }}
                                                        >
                                                          <Grid
                                                            item
                                                            style={{ width: '100%' }}
                                                          >
                                                            <TextField
                                                              label="Mensagem de Retorno Automática"
                                                              variant="outlined"
                                                              fullWidth
                                                              multiline
                                                              minRows={6}
                                                              type="text"
                                                              value={this.state.automaticReturnMessage}
                                                              onChange={(event) => this.setState({ automaticReturnMessage: event.target.value })}
                                                            />
                                                          </Grid>
                                                        </Grid>
                                                        <Grid
                                                          container
                                                          item
                                                          direction="row"
                                                          style={{ marginTop: 10 }}
                                                        >
                                                          <Grid
                                                            item
                                                            style={{ width: '100%' }}
                                                          >
                                                            <TextField
                                                              label="Serviço de monitoramento"
                                                              variant="outlined"
                                                              fullWidth
                                                              multiline
                                                              minRows={6}
                                                              type="text"
                                                              value={this.state.monitoringService}
                                                              onChange={(event) => this.setState({ monitoringService: event.target.value })}
                                                            />
                                                          </Grid>
                                                        </Grid>
                                                        <Grid
                                                          container
                                                          item
                                                          direction="row"
                                                          style={{ marginTop: 10 }}
                                                        >
                                                          <Grid
                                                            item
                                                            style={{ width: '100%' }}
                                                          >
                                                            <TextField
                                                              label="Serviço de execução"
                                                              variant="outlined"
                                                              fullWidth
                                                              multiline
                                                              minRows={6}
                                                              type="text"
                                                              value={this.state.deleteService}
                                                              onChange={(event) => this.setState({ deleteService: event.target.value })}
                                                            />
                                                          </Grid>
                                                        </Grid>
                                                        <Grid
                                                          container
                                                          item
                                                          direction="row"
                                                          justifyContent="center"
                                                          alignItems="center"
                                                          style={{ marginTop: 10 }}
                                                        >
                                                          <Grid
                                                            item
                                                            style={{ width: '30%' }}
                                                          >
                                                            <Button
                                                              variant="contained"
                                                              fullWidth
                                                              style={{
                                                                background: theme.secondary_color,
                                                                color: "white",
                                                                height: "56px",
                                                              }}
                                                              onClick={() => this.handleAddFieldsAsset(indexEtapa, indexAsset)}
                                                            >
                                                              Adicionar Evento
                                                            </Button>
                                                          </Grid>
                                                        </Grid>
                                                      </Grid>

                                                      <Grid
                                                        container
                                                        item
                                                        direction="column"
                                                      >
                                                        {
                                                          itemAsset.fields.length > 0 ?
                                                            <Grid
                                                              component={Paper}
                                                              container
                                                              direction="column"
                                                              style={{ width: "100%", marginTop: 10 }}
                                                            >
                                                              <Grid item style={{ width: "100%", height: "100%" }}>
                                                                <TableContainer style={{ maxHeight: '80vh' }}>
                                                                  <Table stickyHeader size="small">
                                                                    <TableHead>
                                                                      <TableRow>
                                                                        <TableCell style={{ fontWeight: 'Bold' }}>
                                                                          Evento
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'Bold' }}>
                                                                          Prazo de Descarte dias*
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'Bold' }}>
                                                                          Método de Descarte
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'Bold' }}>
                                                                          Legislação
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'Bold' }}>
                                                                          Mensagem de Retorno Automática
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'Bold' }}>
                                                                          Serviço de monitoramento
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'Bold' }}>
                                                                          Serviço de execusão
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'Bold' }}>
                                                                          Excluir
                                                                        </TableCell>
                                                                      </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                      {itemAsset.fields.map((item, indexFields) => (
                                                                        <TableRow
                                                                          key={indexFields}
                                                                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                          style={indexFields % 2 ? { background: "white" } : { background: "#e3e3e3" }}
                                                                        >
                                                                          <TableCell style={{ fontWeight: 'Bold' }}>
                                                                            {item.eventAttribute}
                                                                          </TableCell>
                                                                          <TableCell style={{ fontWeight: 'Bold' }}>
                                                                            {item.disposalTerm}
                                                                          </TableCell>
                                                                          <TableCell style={{ fontWeight: 'Bold' }}>
                                                                            {item.disposalMethod}
                                                                          </TableCell>
                                                                          <TableCell style={{ fontWeight: 'Bold' }}>
                                                                            {item.legislation}
                                                                          </TableCell>
                                                                          <TableCell style={{ fontWeight: 'Bold' }}>
                                                                            {item.automaticReturnMessage}
                                                                          </TableCell>
                                                                          <TableCell style={{ fontWeight: 'Bold' }}>
                                                                            {item.monitoringService}
                                                                          </TableCell>
                                                                          <TableCell style={{ fontWeight: 'Bold' }}>
                                                                            {item.deleteService}
                                                                          </TableCell>
                                                                          <TableCell style={{ fontWeight: 'Bold' }}>
                                                                            <IconButton
                                                                              onClick={() => {
                                                                                this.removeFieldAsset(
                                                                                  indexEtapa,
                                                                                  indexAsset,
                                                                                  indexFields,
                                                                                );
                                                                              }}
                                                                            >
                                                                              <DeleteIcon />
                                                                            </IconButton>
                                                                          </TableCell>
                                                                        </TableRow>
                                                                      ))}
                                                                    </TableBody>
                                                                  </Table>
                                                                </TableContainer>
                                                              </Grid>
                                                            </Grid>
                                                            :
                                                            <h1>Sem eventos cadastrados</h1>
                                                        }
                                                      </Grid>
                                                    </Grid>
                                                  </AccordionDetails>
                                                </Accordion>
                                              </Grid>
                                            ))
                                            :
                                            <></>
                                        }

                                      </Grid>

                                    </AccordionDetails>
                                  </Accordion>
                                </Grid>
                              ))
                            }
                          </>
                        )}
                      {this.state.points[this.state.dialogKey].type ===
                        "coleta" ||
                        this.state.points[this.state.dialogKey].type ===
                        "coletaConsentimento" ? (
                        <>
                          {/* <Grid item style={{ height: "16px" }} />
                            <Grid item style={{ width: "100%" }}>
                              <TextField
                                multiline
                                rows={2}
                                label="Finalidade da coleta"
                                variant="outlined"
                                value={this.state.points[this.state.dialogKey].goal}
                                onChange={(event) => this.handlePointGoal(event)}
                                disabled={!this.state.userInformation.permissions.update}
                                style={{ width: "100%" }}
                              />
                            </Grid> */}
                          <Grid
                            item
                            style={{
                              width: "100%",
                              paddingTop: "16px",
                              paddingBottom: "16px",
                            }}
                          >
                            <Typography
                              variant="h6"
                              component="h6"
                              style={{ color: "#6A6A6A", textAlign: "center" }}
                            >
                              {"Titular"}
                            </Typography>
                          </Grid>
                          <Grid item style={{ width: "100%" }}>
                            <Grid
                              container
                              direction="row"
                              justify="center"
                              alignItems="center"
                              spacing={4}
                            >
                              <Grid item xs={10}>
                                <FormControl
                                  variant="outlined"
                                  style={{ width: "100%" }}
                                >
                                  <InputLabel>Titulares</InputLabel>
                                  <Select
                                    value={this.state.attribute}
                                    onChange={(event) => {
                                      this.handleAttribute(event.target.value);
                                      this.addAttribute(event.target.value);

                                    }}
                                    disabled={
                                      !this.state.userInformation.permissions
                                        .update
                                    }
                                    label="Titúlares"
                                  >
                                    <MenuItem value={""}></MenuItem>
                                    <MenuItem value={"Colaborador"}>Colaborador</MenuItem>
                                    <MenuItem value={"Consumidor"}>Consumidor</MenuItem>
                                    <MenuItem value={"Fornecedores / Parceiros"}>Fornecedores / Parceiros</MenuItem>
                                    {this.state.titularesDB.map((i) => (
                                      <MenuItem value={i.name}>
                                        {i.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={2}>
                                <Button
                                  variant="contained"
                                  style={{
                                    background: theme.secondary_color,
                                    color: "white",
                                    width: "100%",
                                    height: "56px",
                                  }}

                                  onClick={() => {
                                    this.handleDialogCreateHoldersModal();
                                  }}
                                >
                                  Cadastrar tiltular
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item style={{ width: "100%" }}>
                            <Grid
                              container
                              direction="column"
                              justify="center"
                              alignItems="center"
                              spacing={4}
                            >
                              <Grid item style={{ height: "16px" }} />
                              {this.state.points[this.state.dialogKey].attributes?.map((attribute, attributeIndex) => (
                                <Grid item style={{ width: "100%" }}>
                                  <Paper
                                    variant="contained"
                                    style={{
                                      width: "100%",
                                      background: "white",
                                    }}
                                  >
                                    <Grid
                                      container
                                      direction="row"
                                      alignItems="center"
                                    >
                                      <Grid item style={{ marginLeft: "8px" }}>
                                        <Typography>
                                          {attribute.name}
                                        </Typography>
                                      </Grid>
                                      <Grid style={{ flexGrow: 1 }} />
                                      <Grid item>
                                        <IconButton
                                          onClick={() => {
                                            this.handleAttributeDialog(
                                              attributeIndex
                                            );
                                          }}
                                          disabled={
                                            !this.state.userInformation
                                              .permissions.update
                                          }
                                        >
                                          {attribute.fields.length === 0 ? (
                                            <EditIcon />
                                          ) : (
                                            <CheckCircleIcon
                                              style={{ color: "#45ad18" }}
                                            />
                                          )}
                                        </IconButton>
                                      </Grid>
                                      <Grid item style={{ marginLeft: "8px" }}>
                                        <IconButton
                                          onClick={() => {
                                            this.removeAttribute(
                                              attributeIndex
                                            );
                                          }}
                                          disabled={
                                            !this.state.userInformation
                                              .permissions.delete
                                          }
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  </Paper>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            direction="column"
                          >
                            <Dialog
                              open={this.state.createHoldersModal}
                              onClose={() => this.handleCloseDialogs('addAssets')}
                              fullWidth
                            >
                              <DialogTitle
                                style={{
                                  color: theme.quaternary_color,
                                  backgroundColor: theme.secondary_color,
                                  textAlign: "center",
                                }}
                              >
                                Cadastro de titular
                              </DialogTitle>
                              <DialogContent>
                                <Grid
                                  container
                                  direction="column"
                                >
                                  <Grid
                                    container
                                    item
                                    direction="row"
                                    style={{
                                      padding: '10px',
                                      flexWrap: 'nowrap'
                                    }}
                                  >
                                    <TextField
                                      id="outlined-basic"
                                      label="Cadastre um novo titular"
                                      variant="outlined"
                                      style={{
                                        width: "80%",
                                        marginRight: '10px',
                                      }}
                                      onChange={(event) => this.handleTransferHolder(event)}
                                      value={this.state.nameHolder}
                                    />

                                  </Grid>

                                </Grid>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={() => this.handleDialogCreateHoldersModal()}
                                  autoFocus
                                >
                                  Cancelar
                                </Button>
                                <Button
                                  style={{
                                    color: '#192a40',
                                    backgroundColor: '#38f205'
                                  }}
                                  onClick={() => this.handleRegisterHolder()}
                                  disabled={this.state.nameHolder === '' ? true : false}
                                >
                                  Cadastrar
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </Grid>
                        </>
                      ) : (
                        <div />
                      )}
                      {this.state.points[this.state.dialogKey].type ===
                        "compartilhamentoInterno" ||
                        this.state.points[this.state.dialogKey].type ===
                        "compartilhamentoExterno" ? (
                        <>
                          <Grid
                            item
                            style={{
                              width: "100%",
                              paddingTop: "16px",
                              paddingBottom: "16px",
                            }}
                          >
                            <Typography
                              variant="h6"
                              component="h6"
                              style={{ color: "#6A6A6A", textAlign: "center" }}
                            >
                              {"Compartilhamento"}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            style={{ width: "100%", paddingBottom: "10px" }}
                          >
                            <TableContainer>
                              <Table>
                                <colgroup>
                                  <col style={{ width: "15%" }} />
                                  <col style={{ width: "15%" }} />
                                  <col style={{ width: "15%" }} />
                                  <col style={{ width: "15%" }} />
                                  <col style={{ width: "15%" }} />
                                  <col style={{ width: "15%" }} />
                                  <col style={{ width: "5%" }} />
                                  <col style={{ width: "5%" }} />
                                </colgroup>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Origem</TableCell>
                                    <TableCell>Destino</TableCell>
                                    <TableCell>
                                      Setor Responsável Origem
                                    </TableCell>
                                    <TableCell>
                                      Setor Responsável Destino
                                    </TableCell>
                                    <TableCell>Responsável Origem</TableCell>
                                    <TableCell>Responsável Destino</TableCell>
                                    <TableCell />
                                    <TableCell />
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {this.state.points[this.state.dialogKey]
                                    .sharings &&
                                    this.state.points[
                                      this.state.dialogKey
                                    ].sharings.map((sharing, sharingIndex) => (
                                      <TableRow>
                                        <TableCell>{sharing.source}</TableCell>
                                        <TableCell>{sharing.destiny}</TableCell>
                                        <TableCell>
                                          {sharing.sourceSector}
                                        </TableCell>
                                        <TableCell>
                                          {sharing.destinySector}
                                        </TableCell>
                                        <TableCell>
                                          {sharing.sourceOwner}
                                        </TableCell>
                                        <TableCell>
                                          {sharing.destinyOwner}
                                        </TableCell>
                                        <TableCell align="center">
                                          <Tooltip
                                            placement="left"
                                            title="Editar compartilhamento"
                                          >
                                            <IconButton
                                              disabled={
                                                !this.state.userInformation
                                                  .permissions.update
                                              }
                                              onClick={() => {
                                                this.handleEditSharingDialog(
                                                  sharingIndex
                                                );
                                              }}
                                            >
                                              <EditIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </TableCell>
                                        <TableCell align="center">
                                          <Tooltip
                                            placement="left"
                                            title="Excluir compartilhamento"
                                          >
                                            <IconButton
                                              disabled={
                                                !this.state.userInformation
                                                  .permissions.delete
                                              }
                                              onClick={() => {
                                                this.removeSharing(
                                                  sharingIndex
                                                );
                                              }}
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </Table>
                            </TableContainer>

                          </Grid>
                          <Grid
                            item
                            container
                            direction="column"
                            alignItems="center"
                            justify="center"
                            style={{ width: "100%" }}
                          >
                            <Button
                              variant="contained"
                              style={{
                                background: !this.state.userInformation
                                  .permissions.insert
                                  ? "#91aaee"
                                  : theme.secondary_color,
                                color: "white",
                                fontSize: "15px",
                              }}
                              disabled={
                                !this.state.userInformation.permissions.insert
                              }
                              onClick={() => {
                                this.handleAddSharingDialog();
                              }}
                            >
                              Adicionar
                            </Button>
                          </Grid>
                        </>
                      ) : (
                        <div />
                      )}
                      {this.state.points[this.state.dialogKey].type ===
                        "transferenciaInter" ? (
                        <>
                          <Grid
                            item
                            style={{
                              width: "100%",
                              paddingTop: "16px",
                              paddingBottom: "16px",
                            }}
                          >
                            <Typography
                              variant="h6"
                              component="h6"
                              style={{ textAlign: "center" }}
                            >
                              {"Transferências"}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            spacing={2}
                            style={{ width: "100%", paddingBottom: "16px" }}
                          >
                            <Grid
                              item
                              container
                              direction="column"
                              alignItems="center"
                              justify="center"
                              spacing={1}
                              style={{ width: "100%", paddingBottom: "10px" }}
                            >
                              {this.state.points[
                                this.state.dialogKey
                              ].transfers?.map(
                                (transferItem, transferIndex) => (
                                  <Grid item style={{ width: "100%" }}>
                                    <Paper
                                      variant="contained"
                                      style={{
                                        width: "100%",
                                        background: "white",
                                      }}
                                    >
                                      <Grid
                                        item
                                        container
                                        direction="row"
                                        alignItems="center"
                                      >
                                        <Grid
                                          item
                                          container
                                          direction="column"
                                          alignItems="center"
                                          justify="center"
                                          style={{ width: "20%" }}
                                        >
                                          <Grid
                                            item
                                            container
                                            direction="row"
                                            justify="flex-end"
                                          >
                                            <Typography>País: </Typography>
                                          </Grid>
                                        </Grid>
                                        <Grid
                                          item
                                          container
                                          direction="column"
                                          alignItems="center"
                                          justify="center"
                                          style={{ width: "40%" }}
                                        >
                                          <TextField
                                            variant="outlined"
                                            style={{ width: "80%" }}
                                            size="small"
                                            color={theme.secondary_color}
                                            value={transferItem.country}
                                            onChange={(event) => {
                                              this.handleTransferCountry(
                                                event,
                                                transferIndex
                                              );
                                            }}
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          container
                                          direction="column"
                                          alignItems="center"
                                          style={{ width: "20%" }}
                                        >
                                          <Typography>
                                            Lei de proteção de dados pessoais{" "}
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          container
                                          direction="column"
                                          alignItems="center"
                                          style={{ width: "10%" }}
                                        >
                                          <Switch
                                            checked={transferItem.hasLGPD}
                                            onChange={(event) => {
                                              this.handleTransferHasLGPD(
                                                transferIndex
                                              );
                                            }}
                                            color="primary"
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          container
                                          direction="column"
                                          alignItems="center"
                                          style={{ width: "10%" }}
                                        >
                                          <IconButton
                                            onClick={() => {
                                              this.removeTransfer(
                                                transferIndex
                                              );
                                            }}
                                            disabled={
                                              !this.state.userInformation
                                                .permissions.delete
                                            }
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </Grid>
                                      </Grid>
                                    </Paper>
                                  </Grid>
                                )
                              )}
                            </Grid>
                            <Grid
                              item
                              container
                              direction="column"
                              alignItems="center"
                              justify="center"
                              style={{ width: "100%" }}
                            >
                              <Button
                                variant="contained"
                                style={{
                                  background: !this.state.userInformation
                                    .permissions.insert
                                    ? "#91aaee"
                                    : theme.secondary_color,
                                  color: "white",
                                  fontSize: "15px",
                                }}
                                disabled={
                                  !this.state.userInformation.permissions.insert
                                }
                                onClick={() => {
                                  this.addTransfer();
                                }}
                              >
                                Adicionar
                              </Button>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <div />
                      )}
                    </Grid>
                  </div>
                  <div
                    value={this.state.tabIndex}
                    hidden={this.state.tabIndex !== 1}
                  >
                    <Grid
                      container
                      direction="column"
                      style={{
                        paddingLeft: "16px",
                        paddingRight: "16px",
                        width: "100%",
                      }}
                    >
                      {this.renderEmails(
                        this.state.points[this.state.dialogKey]
                      )}
                      <Grid
                        item
                        style={{
                          display: 'flex',
                          flex: '1',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <FormControl variant="outlined" style={{ flex: '1' }}>
                          <Button
                            variant="contained"
                            style={{
                              background: theme.secondary_color,
                              color: "white",
                              width: "100%",
                              margin: '1px',
                            }}
                            onClick={() => {
                              this.addEmail(
                                this.state.points[this.state.dialogKey]
                              );
                            }}
                            disabled={
                              !this.state.userInformation.permissions.update ||
                              !this.state.userInformation.permissions.insert
                            }
                          >
                            Adicionar E-Mail
                          </Button>
                        </FormControl>
                        <Button
                          variant="contained"
                          style={{
                            flex: '1',
                            background: this.state.answerStageQuizButtonDisabled
                              ? "#91aaee"
                              : theme.secondary_color,
                            color: "white",
                            margin: '1px',
                          }}
                        >
                          <Link
                            style={{ textDecoration: "none", color: "white" }}
                            to={{
                              pathname:
                                "/quiz/" +
                                this.state.points[this.state.dialogKey].quizId,
                              state: { loggedUser: "true" },
                            }}
                            target="_blank"
                            onClick={() => this.setQuizLocalStorage(true)}
                            disabled={
                              !this.state.userInformation.permissions.update ||
                              !this.state.userInformation.permissions.insert ||
                              this.state.answerStageQuizButtonDisabled
                            }
                          >
                            Responder / Visualizar Questionário
                          </Link>
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                  <Grid item>
                    <Grid container direction="row" justify="flex-end">
                      <Button
                        variant="contained"
                        style={{
                          background: "#2e7d32",
                          color: "white",
                          width: "15rem",
                          height: "56px",
                          margin: "0 1.5rem 1rem"
                        }}
                        onClick={() => {
                          this.saveProcess();
                          this.handleDialog(-1);
                        }
                        }
                      >
                        Salvar
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              ) : (
                <div />
              )}
            </Dialog>

            <Dialog
              onClose={() => {
                this.handleExitDialog();
              }}
              fullWidth
              open={this.state.exitDialog}
              PaperProps={{
                style: {
                  width: "auto",
                  padding: "16px",
                },
              }}
            >
              <DialogTitle>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>Você tem certeza que quer sair dessa tela?</Grid>
                </Grid>
              </DialogTitle>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={4}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    style={{
                      background: theme.secondary_color,
                      color: "white",
                      width: "100%",
                      height: "56px",
                    }}
                    onClick={() => {
                      this.handleSaveExit();
                    }}
                    disabled={!this.state.userInformation.permissions.update}
                  >
                    Salvar e Sair
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    style={{
                      background: "#687394",
                      color: "white",
                      width: "100%",
                      height: "56px",
                    }}
                    onClick={() => {
                      this.exitDashboard();
                    }}
                  >
                    Não Salvar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    style={{
                      background: "#687394",
                      color: "white",
                      width: "100%",
                      height: "56px",
                    }}
                    onClick={() => {
                      this.handleExitDialog();
                    }}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Dialog>

            <Dialog
              onClose={() => {
                this.handleDeleteDialog();
              }}
              open={this.state.deleteDialog}
              PaperProps={{
                style: {
                  width: "auto",
                  padding: "16px",
                },
              }}
            >
              <DialogTitle>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    Você tem certeza que deseja excluir essa etapa?
                  </Grid>
                </Grid>
              </DialogTitle>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={4}
              >
                <Grid item xs={5}>
                  <Button
                    variant="contained"
                    style={{
                      background: theme.secondary_color,
                      color: "white",
                      width: "100%",
                      height: "56px",
                    }}
                    onClick={() => {
                      this.deletePoint();
                      this.handleDeleteDialog();
                    }}
                    disabled={!this.state.userInformation.permissions.delete}
                  >
                    Sim
                  </Button>
                </Grid>
                <Grid item xs={5}>
                  <Button
                    variant="contained"
                    style={{
                      background: "#687394",
                      color: "white",
                      width: "100%",
                      height: "56px",
                    }}
                    onClick={() => {
                      this.handleDeleteDialog();
                    }}
                  >
                    Não
                  </Button>
                </Grid>
              </Grid>
            </Dialog>

            <Dialog
              onClose={() => {
                this.handleSavedDialog();
              }}
              open={this.state.savedDialog}
              PaperProps={{
                style: {
                  width: "auto",
                  padding: "16px",
                },
              }}
            >
              <DialogTitle>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>Processo salvo com sucesso!</Grid>
                </Grid>
              </DialogTitle>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={4}
              >
                <Grid item>
                  <CheckCircleIcon
                    style={{ color: "#45ad18", fontSize: "80px" }}
                  />
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <Button
                    variant="contained"
                    style={{
                      background: "#45ad18",
                      color: "white",
                      width: "100%",
                      height: "56px",
                    }}
                    onClick={() => {
                      this.handleSavedDialog();
                    }}
                  >
                    Ok
                  </Button>
                </Grid>
              </Grid>
            </Dialog>

            <Dialog
              onClose={() => {
                this.handleAttributeDialog(-1);
              }}
              open={this.state.attributeDialog}
              fullWidth={true}
              maxWidth={"md"}
              PaperProps={{
                style: {
                  padding: "16px",
                },
              }}
            >
              <DialogTitle>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item container justify="flex-end">
                    <IconButton
                      onClick={() => {
                        this.handleAttributeDialog(-1);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Grid>
                  <Grid item>Atributos</Grid>
                </Grid>
              </DialogTitle>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={4}
              >
                <Grid item style={{ width: "100%" }}>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={4}
                  >
                    <Grid item xs={10}>
                      <TextField
                        label="Campo"
                        variant="outlined"
                        style={{ width: "100%" }}
                        color={theme.secondary_color}
                        value={this.state.attributeField}
                        onChange={(event) => {
                          this.handleAttributeField(event.target.value);
                        }}
                        disabled={
                          !this.state.userInformation.permissions.update
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        variant="contained"
                        style={{
                          background: this.state.addAttributeFieldButtonDisabled
                            ? "#91aaee"
                            : theme.secondary_color,
                          color: "white",
                          width: "100%",
                          height: "56px",
                        }}
                        onClick={() => {
                          this.addAttributeField();
                        }}
                        disabled={
                          !this.state.userInformation.permissions.insert ||
                          this.state.addAttributeFieldButtonDisabled
                        }
                      >
                        Adicionar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <TableContainer component={Paper}>
                    <Table>
                      <colgroup>
                        <col style={{ width: "70%" }} />
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "15%" }} />
                      </colgroup>
                      <TableHead style={{ background: theme.primary_color }}>
                        <TableRow>
                          <TableCell style={{ color: theme.secondary_color }}>
                            NOME
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ color: theme.secondary_color }}
                          >
                            SENSIVEL
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ color: theme.secondary_color }}
                          >
                            REMOVER
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {this.state.dialogKey !== -1 &&
                        this.state.attributeDialogKey !== -1 ? (
                        <TableBody>
                          {this.state.points[this.state.dialogKey].attributes[
                            this.state.attributeDialogKey
                          ].fields.map((field, fieldIndex) => (
                            <TableRow key={fieldIndex}>
                              <TableCell>
                                <Typography>{field.name}</Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Switch
                                  checked={field.sensitive}
                                  onChange={() => {
                                    this.handleSensitiveAttributeField(
                                      fieldIndex
                                    );
                                  }}
                                  color="primary"
                                  disabled={
                                    !this.state.userInformation.permissions
                                      .update
                                  }
                                />
                              </TableCell>
                              <TableCell align="center">
                                <IconButton
                                  onClick={() => {
                                    this.removeAttributeField(fieldIndex);
                                  }}
                                  disabled={
                                    !this.state.userInformation.permissions
                                      .delete
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : (
                        <div />
                      )}
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Dialog>

            <Dialog
              onClose={() => {
                this.handleRiskDialog(-1, "", 1, 1, 1);
              }}
              open={this.state.riskDialog}
              maxWidth={"xs"}
              PaperProps={{
                style: {
                  width: "100%",
                  padding: "16px",
                },
              }}
            >
              <DialogTitle>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>Risco</Grid>
                </Grid>
              </DialogTitle>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={4}
              >
                <Grid item style={{ width: "100%" }}>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{
                      width: "100%",
                      paddingLeft: "16px",
                      paddingRight: "16px",
                    }}
                  >
                    <Grid item xs={9} style={{ width: "100%" }}>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography>Probabilidade</Typography>
                        </Grid>
                        <Grid item>
                          <Slider
                            defaultValue={1}
                            valueLabelDisplay="auto"
                            valueLabelFormat={(value) => (
                              <div
                                style={{
                                  color: theme.secondary_color,
                                  background: theme.primary_color,
                                  fontSize: "16px",
                                  padding: "4px",
                                }}
                              >
                                {this.labelFormater(value)}
                              </div>
                            )}
                            value={this.state.riskChance}
                            labe
                            step={1}
                            marks
                            min={1}
                            max={5}
                            onChangeCommitted={(event, value) => {
                              this.handleRiskChance(value);
                            }}
                            disabled={
                              !this.state.userInformation.permissions.update ||
                              !this.state.userInformation.permissions.insert
                            }
                          />
                        </Grid>
                        <Grid item>
                          <Typography>Impacto</Typography>
                        </Grid>
                        <Grid item>
                          <Slider
                            defaultValue={1}
                            valueLabelDisplay="auto"
                            valueLabelFormat={(value) => (
                              <div
                                style={{
                                  color: theme.secondary_color,
                                  background: theme.primary_color,
                                  fontSize: "16px",
                                  padding: "4px",
                                }}
                              >
                                {this.labelFormater(value)}
                              </div>
                            )}
                            value={this.state.riskImpact}
                            step={1}
                            marks
                            min={1}
                            max={5}
                            onChangeCommitted={(event, value) => {
                              this.handleRiskImpact(value);
                            }}
                            disabled={
                              !this.state.userInformation.permissions.update ||
                              !this.state.userInformation.permissions.insert
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={3} style={{ width: "100%" }}>
                      <Grid container justify="flex-end" alignItems="center">
                        <Grid item>
                          <Avatar style={this.riskColor(this.state.risk)}>
                            {this.state.risk}
                          </Avatar>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={4}
                  >
                    <Grid item xs={5}>
                      <Button
                        variant="contained"
                        style={{
                          background: theme.secondary_color,
                          color: "white",
                          width: "100%",
                          height: "56px",
                        }}
                        onClick={() => {
                          this.saveRisk();
                        }}
                      >
                        Ok
                      </Button>
                    </Grid>
                    <Grid item xs={5}>
                      <Button
                        variant="contained"
                        style={{
                          background: "#687394",
                          color: "white",
                          width: "100%",
                          height: "56px",
                        }}
                        onClick={() => {
                          this.handleRiskDialog(-1, "", 1, 1, 1);
                        }}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Dialog>




            <Dialog maxWidth={"xl"} open={this.state.gapDialog} fullWidth>
              <DialogTitle>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item style={{ color: theme.secondary_color }}>
                    Gaps
                  </Grid>
                  <Grid style={{ flexGrow: 1 }} />
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        this.handleGapDialog();
                      }}
                      style={{ color: theme.secondary_color }}
                      disabled={
                        !this.state.userInformation.permissions.update ||
                        !this.state.userInformation.permissions.insert
                      }
                    >
                      <ClearIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </DialogTitle>
              <div>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="flex-end"
                >
                  <Grid item style={{ width: "100%", padding: "8px" }}>
                    <TableContainer
                      component={Paper}
                      style={{ maxHeight: "72vh" }}
                    >
                      <Table stickyHeader>
                        <colgroup>
                          <col style={{ width: "20%" }} />
                          <col style={{ width: "5%" }} />
                          <col style={{ width: "20%" }} />
                          <col style={{ width: "5%" }} />
                          <col style={{ width: "18%" }} />
                          <col style={{ width: "10%" }} />
                          <col style={{ width: "14%" }} />
                          <col style={{ width: "4%" }} />
                          <col style={{ width: "4%" }} />
                        </colgroup>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{
                                backgroundColor: theme.primary_color,
                                color: theme.secondary_color,
                              }}
                            >
                              GAPS
                            </TableCell>
                            <TableCell
                              style={{
                                backgroundColor: theme.primary_color,
                                color: theme.secondary_color,
                              }}
                              align="center"
                            >
                              RISCO GAPS
                            </TableCell>
                            <TableCell
                              style={{
                                backgroundColor: theme.primary_color,
                                color: theme.secondary_color,
                              }}
                            >
                              PLANO DE AÇÃO
                            </TableCell>
                            <TableCell
                              style={{
                                backgroundColor: theme.primary_color,
                                color: theme.secondary_color,
                              }}
                              align="center"
                            >
                              RISCO P. AÇÃO
                            </TableCell>
                            <TableCell
                              style={{
                                backgroundColor: theme.primary_color,
                                color: theme.secondary_color,
                              }}
                            >
                              DATA DE CONCLUSÃO
                            </TableCell>
                            {/* <TableCell
                              style={{
                                backgroundColor: theme.primary_color,
                                color: theme.secondary_color,
                              }}
                            >
                              ETAPA
                            </TableCell> */}
                            <TableCell
                              style={{
                                backgroundColor: theme.primary_color,
                                color: theme.secondary_color,
                              }}
                            >
                              E-MAIL DO RESPONSÁVEL
                            </TableCell>
                            <TableCell
                              style={{
                                backgroundColor: theme.primary_color,
                                color: theme.secondary_color,
                              }}
                              align="center"
                            >
                              CONCLUÍDO
                            </TableCell>
                            <TableCell
                              style={{
                                backgroundColor: theme.primary_color,
                                color: theme.secondary_color,
                              }}
                              align="center"
                            >
                              REMOVER
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.gaps?.map((gap, gapIndex) =>
                            gap.active ? (
                              <TableRow key={gapIndex}>
                                <TableCell>{gap.name}</TableCell>
                                <TableCell align="center">
                                  <HtmlTooltip
                                    title={
                                      <React.Fragment>
                                        <Typography color="inherit">
                                          Impacto:{" "}
                                          {this.labelFormater(
                                            gap.riskGapImpact
                                          )}
                                        </Typography>
                                        <Typography color="inherit">
                                          Probabilidade:{" "}
                                          {this.labelFormater(
                                            gap.riskGapChance
                                          )}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                  >
                                    <IconButton
                                      onClick={() => {
                                        this.handleRiskDialog(
                                          gapIndex,
                                          "ng",
                                          gap.riskGapChance,
                                          gap.riskGapImpact,
                                          gap.riskGap
                                        );
                                      }}
                                      disabled={
                                        !this.state.userInformation.permissions
                                          .update ||
                                        !this.state.userInformation.permissions
                                          .insert ||
                                        gap.done
                                      }
                                    >
                                      <Avatar
                                        style={this.riskCellColor(gap.riskGap)}
                                      >
                                        {gap.riskGap}
                                      </Avatar>
                                    </IconButton>
                                  </HtmlTooltip>
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    multiline
                                    value={gap.actionPlan}
                                    onChange={(event) => {
                                      this.handleGapActionPlan(event, gapIndex);
                                    }}
                                    style={{ marginTop: "7px", width: "100%" }}
                                    disabled={
                                      !this.state.userInformation.permissions
                                        .update ||
                                      !this.state.userInformation.permissions
                                        .insert ||
                                      gap.done
                                    }
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <HtmlTooltip
                                    title={
                                      <React.Fragment>
                                        <Typography color="inherit">
                                          Impacto:{" "}
                                          {this.labelFormater(gap.riskImpact)}
                                        </Typography>
                                        <Typography color="inherit">
                                          Probabilidade:{" "}
                                          {this.labelFormater(gap.riskChance)}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                  >
                                    <IconButton
                                      onClick={() => {
                                        this.handleRiskDialog(
                                          gapIndex,
                                          "n",
                                          gap.riskChance,
                                          gap.riskImpact,
                                          gap.risk
                                        );
                                      }}
                                      disabled={
                                        !this.state.userInformation.permissions
                                          .update ||
                                        !this.state.userInformation.permissions
                                          .insert ||
                                        gap.done
                                      }
                                    >
                                      <Avatar
                                        style={this.riskCellColor(gap.risk)}
                                      >
                                        {gap.risk}
                                      </Avatar>
                                    </IconButton>
                                  </HtmlTooltip>
                                </TableCell>
                                <TableCell>
                                  <MuiPickersUtilsProvider
                                    locale={ptBR}
                                    utils={DateFnsUtils}
                                  >
                                    <KeyboardDatePicker
                                      disableToolbar
                                      variant="inline"
                                      format="dd/MM/yyyy"
                                      margin="normal"
                                      invalidDateMessage={
                                        !gap.done
                                          ? ""
                                          : "Data em formato inválido"
                                      }
                                      onChange={(value) => {
                                        this.handleGapDate(value, gapIndex);
                                      }}
                                      value={gap.date}
                                      disabled={
                                        !this.state.userInformation.permissions
                                          .update ||
                                        !this.state.userInformation.permissions
                                          .insert ||
                                        !gap.done
                                      }
                                      autoOk
                                    />
                                  </MuiPickersUtilsProvider>
                                </TableCell>
                                {/* <TableCell>{gap.processStageName}</TableCell> */}
                                <TableCell></TableCell>
                                <TableCell>
                                  <TextField
                                    multiline
                                    value={gap.ownerEmail}
                                    onChange={(event) => {
                                      this.handleGapOwnerEmail(event, gapIndex);
                                    }}
                                    style={{ marginTop: "7px" }}
                                    disabled={
                                      !this.state.userInformation.permissions
                                        .update ||
                                      !this.state.userInformation.permissions
                                        .insert ||
                                      gap.done
                                    }
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <Switch
                                    checked={gap.done}
                                    onChange={() => {
                                      this.handleGapDone(gapIndex);
                                    }}
                                    color="primary"
                                    disabled={
                                      !this.state.userInformation.permissions
                                        .update ||
                                      !this.state.userInformation.permissions
                                        .insert
                                    }
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <Tooltip title="Excluir">
                                    <IconButton
                                      onClick={() => {
                                        this.removeGap(gapIndex);
                                      }}
                                      disabled={
                                        !this.state.userInformation.permissions
                                          .delete ||
                                        !this.state.userInformation.permissions
                                          .update ||
                                        !this.state.userInformation.permissions
                                          .insert
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ) : (
                              <div />
                            )
                          )}
                          {this.state.customGaps.map(
                            (customGap, customGapIndex) => (
                              <TableRow key={customGapIndex}>
                                <TableCell>
                                  <TextField
                                    multiline
                                    value={customGap.name}
                                    onChange={(event) => {
                                      this.handleCustomGapName(
                                        event,
                                        customGapIndex
                                      );
                                    }}
                                    style={{ marginTop: "7px", width: "100%" }}
                                    disabled={
                                      !this.state.userInformation.permissions
                                        .update ||
                                      !this.state.userInformation.permissions
                                        .insert ||
                                      customGap.done
                                    }
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <HtmlTooltip
                                    title={
                                      <React.Fragment>
                                        <Typography color="inherit">
                                          Impacto:{" "}
                                          {this.labelFormater(
                                            customGap.riskGapImpact
                                          )}
                                        </Typography>
                                        <Typography color="inherit">
                                          Probabilidade:{" "}
                                          {this.labelFormater(
                                            customGap.riskGapChance
                                          )}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                  >
                                    <IconButton
                                      onClick={() => {
                                        this.handleRiskDialog(
                                          customGapIndex,
                                          "cg",
                                          customGap.riskGapChance,
                                          customGap.riskGapImpact,
                                          customGap.riskGap
                                        );
                                      }}
                                      disabled={
                                        !this.state.userInformation.permissions
                                          .update ||
                                        !this.state.userInformation.permissions
                                          .insert ||
                                        customGap.done
                                      }
                                    >
                                      <Avatar
                                        style={this.riskCellColor(
                                          customGap.riskGap
                                        )}
                                      >
                                        {customGap.riskGap}
                                      </Avatar>
                                    </IconButton>
                                  </HtmlTooltip>
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    multiline
                                    value={customGap.actionPlan}
                                    onChange={(event) => {
                                      this.handleCustomGapActionPlan(
                                        event,
                                        customGapIndex
                                      );
                                    }}
                                    style={{ marginTop: "7px", width: "100%" }}
                                    disabled={
                                      !this.state.userInformation.permissions
                                        .update ||
                                      !this.state.userInformation.permissions
                                        .insert ||
                                      customGap.done
                                    }
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <HtmlTooltip
                                    title={
                                      <React.Fragment>
                                        <Typography color="inherit">
                                          Impacto:{" "}
                                          {this.labelFormater(
                                            customGap.riskImpact
                                          )}
                                        </Typography>
                                        <Typography color="inherit">
                                          Probabilidade:{" "}
                                          {this.labelFormater(
                                            customGap.riskChance
                                          )}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                  >
                                    <IconButton
                                      onClick={() => {
                                        this.handleRiskDialog(
                                          customGapIndex,
                                          "c",
                                          customGap.riskChance,
                                          customGap.riskImpact,
                                          customGap.risk
                                        );
                                      }}
                                      disabled={
                                        !this.state.userInformation.permissions
                                          .update ||
                                        !this.state.userInformation.permissions
                                          .insert ||
                                        customGap.done
                                      }
                                    >
                                      <Avatar
                                        style={this.riskCellColor(
                                          customGap.risk
                                        )}
                                      >
                                        {customGap.risk}
                                      </Avatar>
                                    </IconButton>
                                  </HtmlTooltip>
                                </TableCell>
                                <TableCell>
                                  <MuiPickersUtilsProvider
                                    locale={ptBR}
                                    utils={DateFnsUtils}
                                  >
                                    <KeyboardDatePicker
                                      disableToolbar
                                      variant="inline"
                                      format="dd/MM/yyyy"
                                      margin="normal"
                                      invalidDateMessage={
                                        !customGap.done
                                          ? ""
                                          : "Data em formato inválido"
                                      }
                                      onChange={(value) => {
                                        this.handleCustomGapDate(
                                          value,
                                          customGapIndex
                                        );
                                      }}
                                      value={customGap.date}
                                      disabled={
                                        !this.state.userInformation.permissions
                                          .update ||
                                        !this.state.userInformation.permissions
                                          .insert ||
                                        !customGap.done
                                      }
                                      autoOk={true}
                                    />
                                  </MuiPickersUtilsProvider>
                                </TableCell>
                                {/* <TableCell>
                                  <TextField
                                    multiline
                                    value={customGap.owner}
                                    onChange={(event) => {
                                      this.handleCustomGapOwner(
                                        event,
                                        customGapIndex
                                      );
                                    }}
                                    style={{ marginTop: "7px", width: "100%" }}
                                    disabled={
                                      !this.state.userInformation.permissions
                                        .update ||
                                      !this.state.userInformation.permissions
                                        .insert ||
                                      customGap.done
                                    }
                                  />
                                </TableCell> */}
                                <TableCell>
                                  <TextField
                                    multiline
                                    value={customGap.ownerEmail}
                                    onChange={(event) => {
                                      this.handleCustomGapOwnerEmail(
                                        event,
                                        customGapIndex
                                      );
                                    }}
                                    style={{ marginTop: "7px", width: "100%" }}
                                    disabled={
                                      !this.state.userInformation.permissions
                                        .update ||
                                      !this.state.userInformation.permissions
                                        .insert ||
                                      customGap.done
                                    }
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <Switch
                                    checked={customGap.done}
                                    onChange={() => {
                                      this.handleCustomGapDone(customGapIndex);
                                    }}
                                    color="primary"
                                    disabled={
                                      !this.state.userInformation.permissions
                                        .update ||
                                      !this.state.userInformation.permissions
                                        .insert
                                    }
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <Tooltip title="Excluir">
                                    <IconButton
                                      onClick={() => {
                                        this.removeCustomGap(customGapIndex);
                                      }}
                                      disabled={
                                        !this.state.userInformation.permissions
                                          .update ||
                                        !this.state.userInformation.permissions
                                          .insert ||
                                        !this.state.userInformation.permissions
                                          .delete
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid
                    item
                    style={{ paddingBottom: "8px", paddingRight: "8px" }}
                  >
                    <Button
                      variant="contained"
                      style={{
                        background: theme.secondary_color,
                        color: "white",
                        width: "100%",
                      }}
                      onClick={() => {
                        this.addCustomGap();
                      }}
                      disabled={
                        !this.state.userInformation.permissions.update ||
                        !this.state.userInformation.permissions.insert
                      }
                    >
                      Adicionar GAP
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Dialog>

            <Dialog
              open={this.state.editProcessDialog}
              onClose={() => {
                this.handleEditProcessDialog();
              }}
              fullWidth={true}
              maxWidth={"md"}
            >
              <DialogTitle>
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <Typography variant="h5" component="h5" align="center">
                      Editar Processo
                    </Typography>
                  </Grid>
                  <Grid style={{ flexGrow: 1 }} />
                  <Grid item>
                    <IconButton onClick={() => this.handleEditProcessDialog()}>
                      <ClearIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogContent>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="flex-start"
                >
                  <Grid item style={{ height: "16px" }} />
                  <Grid item style={{ width: "100%" }}>
                    <TextField
                      label="Nome"
                      variant="outlined"
                      value={this.state.processName}
                      onChange={(event) => {
                        this.handleProcessName(event);
                      }}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item style={{ height: "16px" }} />
                  <Grid item style={{ width: "100%" }}>
                    <Grid item style={{ width: "100%" }}>
                      <TextField
                        label="Responsável"
                        variant="outlined"
                        value={this.state.processOwner}
                        onChange={(event) => {
                          this.handleProcessOwner(event);
                        }}
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item style={{ height: "16px" }} />
                    <Grid item style={{ width: "100%" }}>
                      <TextField
                        label="Finalidade"
                        variant="outlined"
                        value={this.state.processGoal}
                        onChange={(event) => {
                          this.handleProcessGoal(event);
                        }}
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item style={{ height: "16px" }} />
                    <Grid item style={{ width: "100%" }}>
                      <TextField
                        multiline
                        rows={3}
                        label="Detalhe"
                        variant="outlined"
                        value={this.state.processDescription}
                        onChange={(event) => {
                          this.handleProcessDescription(event);
                        }}
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item style={{ height: "16px" }} />
                    <Grid item style={{ width: "100%" }}>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <InputLabel>Criticidade</InputLabel>
                        <Select
                          value={this.state.processCriticality}
                          onChange={(event) => {
                            this.handleProcessCriticality(event);
                          }}
                          label="Criticidade"
                        >
                          <MenuItem value={""}></MenuItem>
                          <MenuItem value={"alta"}>Alta</MenuItem>
                          <MenuItem value={"media"}>Média</MenuItem>
                          <MenuItem value={"baixa"}>Baixa</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item style={{ height: "16px" }} />
                    <Grid item style={{ width: "100%" }}>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <InputLabel>Atuação</InputLabel>
                        <Select
                          value={this.state.processOperator}
                          onChange={(event) => {
                            this.handleProcessOperator(event);
                          }}
                          label="Atuação"
                        >
                          <MenuItem value={""}></MenuItem>
                          <MenuItem value={"controlador"}>Controlador</MenuItem>
                          <MenuItem value={"Operador"}>Operador</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item style={{ height: "16px" }} />
                    <Grid item style={{ width: "100%" }}>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={4}
                      >
                        <Grid item style={{ width: "100%" }}>
                          <FormControl
                            variant="outlined"
                            style={{ width: "100%" }}
                          >
                            <Autocomplete
                              options={this.state.defaultLegalBases.map(
                                (lb) => lb.name
                              )}
                              value={this.state.processBaseName}
                              onChange={(event, newValue) => {
                                this.handleProcessBaseName(newValue);
                              }}
                              getOptionLabel={(option) => option}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Base Legal"
                                  variant="outlined"
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item style={{ height: "16px" }} />
                    <Grid item style={{ width: "100%" }}>
                      <TextField
                        label="Legislação Complementar Envolvida"
                        variant="outlined"
                        value={this.state.complementaryLegalBase}
                        onChange={(event) => {
                          this.handleComplementaryLegalBase(event);
                        }}
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item style={{ height: "16px" }} />
                    <Grid item style={{ width: "100%" }}>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <InputLabel>Questionário</InputLabel>
                        <Select
                          value={this.state.processQuizId}
                          onChange={(event) => {
                            this.handleProcessQuiz(event);
                          }}
                          label="Questionário"
                        >
                          <MenuItem value={""}>2.0</MenuItem>
                          {this.state.newQuizes.map((quiz) => (
                            <MenuItem value={'quiz2.0 ' + quiz.id}>{quiz.name}</MenuItem>
                          ))}
                          {/* <MenuItem value={""}>1.0</MenuItem>
                          {this.state.quizes.map((quiz) => (
                            <MenuItem value={quiz.id}>{quiz.name}</MenuItem>
                          ))} */}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item style={{ height: "16px" }} />
                    <Grid item>
                      <Typography variant="subtitle2" component="div">
                        As informações constarão no relatório DPIA
                      </Typography>
                    </Grid>
                    <Grid item style={{ height: "16px" }} />
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      spacing={4}
                    >
                      <Grid item style={{ width: "100%" }} xs={2}>
                        <Button
                          variant="contained"
                          style={{
                            background: "red",
                            color: "white",
                            width: "100%",
                            height: "50px",
                          }}
                          onClick={() => {
                            this.handleDeleteProcessDialog();
                          }}
                          disabled={
                            !this.state.userInformation.permissions.delete
                          }
                        >
                          Remover
                        </Button>
                      </Grid>
                      <Grid item style={{ flexGrow: 1 }} />
                      <Grid item style={{ width: "100%" }} xs={2}>
                        <Button
                          variant="contained"
                          style={{
                            background: this.state.updateProcessButtonDisabled
                              ? "#91ff79"
                              : theme.primary_color,
                            color: theme.secondary_color,
                            width: "100%",
                            height: "50px",
                          }}
                          onClick={() => {
                            this.updateProcess();
                          }}
                          disabled={
                            !this.state.userInformation.permissions.update ||
                            this.state.updateProcessButtonDisabled
                          }
                        >
                          Salvar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>

            <Dialog
              onClose={() => {
                this.handleDeleteProcessDialog();
              }}
              open={this.state.deleteProcessDialog}
              PaperProps={{
                style: {
                  width: "auto",
                  padding: "16px",
                },
              }}
            >
              <DialogTitle>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    Você tem certeza que deseja excluir esse processo?
                  </Grid>
                </Grid>
              </DialogTitle>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={4}
              >
                <Grid item xs={5}>
                  <Button
                    variant="contained"
                    style={{
                      background: theme.secondary_color,
                      color: "white",
                      width: "100%",
                      height: "56px",
                    }}
                    onClick={() => {
                      this.removeProcess();
                    }}
                    disabled={!this.state.userInformation.permissions.delete}
                  >
                    Sim
                  </Button>
                </Grid>
                <Grid item xs={5}>
                  <Button
                    variant="contained"
                    style={{
                      background: "#687394",
                      color: "white",
                      width: "100%",
                      height: "56px",
                    }}
                    onClick={() => {
                      this.handleDeleteProcessDialog();
                    }}
                  >
                    Não
                  </Button>
                </Grid>
              </Grid>
            </Dialog>

            <Dialog
              onClose={() => {
                this.handleAddSharingDialog();
              }}
              open={this.state.sharingDialog}
              fullWidth={true}
              maxWidth={"md"}
              PaperProps={{ style: { padding: "16px" } }}
            >
              <DialogTitle>
                <Grid container direction="row" alignItems="center">
                  <Grid item>Adicionar Compartilhamento</Grid>
                  <Grid item container justify="flex-end">
                    <IconButton
                      onClick={() => {
                        this.handleAddSharingDialog();
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </DialogTitle>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={2}
                style={{ paddingBottom: "10px" }}
              >
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  spacing={3}
                  style={{ width: "100%" }}
                >
                  <Grid item xs={5}>
                    <TextField
                      label="Origem"
                      variant="outlined"
                      style={{ width: "100%" }}
                      color={theme.secondary_color}
                      value={this.state.sharingSource}
                      onChange={(event) => {
                        this.handleSharingSource(event);
                      }}
                      disabled={!this.state.userInformation.permissions.insert}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <ArrowForwardIcon style={{ width: "100%" }} />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      label="Destino"
                      variant="outlined"
                      style={{ width: "100%" }}
                      color={theme.secondary_color}
                      value={this.state.sharingDestiny}
                      onChange={(event) => {
                        this.handleSharingDestiny(event);
                      }}
                      disabled={!this.state.userInformation.permissions.insert}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  spacing={3}
                  style={{ width: "100%" }}
                >
                  <Grid item xs={5}>
                    <TextField
                      label="Setor Responsável - Origem"
                      variant="outlined"
                      style={{ width: "100%" }}
                      color={theme.secondary_color}
                      value={this.state.sharingSourceSector}
                      onChange={(event) => {
                        this.handleSharingSourceSector(event);
                      }}
                      disabled={!this.state.userInformation.permissions.insert}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <ArrowForwardIcon style={{ width: "100%" }} />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      label="Setor Responsável - Destino"
                      variant="outlined"
                      style={{ width: "100%" }}
                      color={theme.secondary_color}
                      value={this.state.sharingDestinySector}
                      onChange={(event) => {
                        this.handleSharingDestinySector(event);
                      }}
                      disabled={!this.state.userInformation.permissions.insert}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  spacing={3}
                  style={{ width: "100%" }}
                >
                  <Grid item xs={5}>
                    <TextField
                      label="Responsável - Origem"
                      variant="outlined"
                      style={{ width: "100%" }}
                      color={theme.secondary_color}
                      value={this.state.sharingSourceOwner}
                      onChange={(event) => {
                        this.handleSharingSourceOwner(event);
                      }}
                      disabled={!this.state.userInformation.permissions.insert}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <ArrowForwardIcon style={{ width: "100%" }} />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      label="Responsável - Destino"
                      variant="outlined"
                      style={{ width: "100%" }}
                      color={theme.secondary_color}
                      value={this.state.sharingDestinyOwner}
                      onChange={(event) => {
                        this.handleSharingDestinyOwner(event);
                      }}
                      disabled={!this.state.userInformation.permissions.insert}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                direction="column"
                alignItems="center"
                justify="center"
                style={{ width: "100%" }}
              >
                <Button
                  variant="contained"
                  style={{
                    background: this.state.addSharingButtonDisabled
                      ? "#91aaee"
                      : theme.secondary_color,
                    color: "white",
                    fontSize: "15px",
                  }}
                  onClick={() => {
                    this.addSharing();
                  }}
                  disabled={
                    !this.state.userInformation.permissions.insert ||
                    this.state.addSharingButtonDisabled
                  }
                >
                  Adicionar
                </Button>
              </Grid>
            </Dialog>

            <Dialog
              onClose={() => {
                this.handleEditSharingDialog(-1);
              }}
              open={this.state.sharingEditDialog}
              fullWidth={true}
              maxWidth={"md"}
              PaperProps={{ style: { padding: "16px" } }}
            >
              <DialogTitle>
                <Grid container direction="row" alignItems="center">
                  <Grid item>Editar Compartilhamento</Grid>
                  <Grid item container justify="flex-end">
                    <IconButton
                      onClick={() => {
                        this.handleEditSharingDialog(-1);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </DialogTitle>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={2}
                style={{ paddingBottom: "10px" }}
              >
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  spacing={3}
                  style={{ width: "100%" }}
                >
                  <Grid item xs={5}>
                    <TextField
                      label="Origem"
                      variant="outlined"
                      style={{ width: "100%" }}
                      color={theme.secondary_color}
                      value={this.state.sharingSource}
                      onChange={(event) => {
                        this.handleSharingSource(event);
                      }}
                      disabled={!this.state.userInformation.permissions.update}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <ArrowForwardIcon style={{ width: "100%" }} />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      label="Destino"
                      variant="outlined"
                      style={{ width: "100%" }}
                      color={theme.secondary_color}
                      value={this.state.sharingDestiny}
                      onChange={(event) => {
                        this.handleSharingDestiny(event);
                      }}
                      disabled={!this.state.userInformation.permissions.update}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  spacing={3}
                  style={{ width: "100%" }}
                >
                  <Grid item xs={5}>
                    <TextField
                      label="Setor Responsável - Origem"
                      variant="outlined"
                      style={{ width: "100%" }}
                      color={theme.secondary_color}
                      value={this.state.sharingSourceSector}
                      onChange={(event) => {
                        this.handleSharingSourceSector(event);
                      }}
                      disabled={!this.state.userInformation.permissions.update}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <ArrowForwardIcon style={{ width: "100%" }} />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      label="Setor Responsável - Destino"
                      variant="outlined"
                      style={{ width: "100%" }}
                      color={theme.secondary_color}
                      value={this.state.sharingDestinySector}
                      onChange={(event) => {
                        this.handleSharingDestinySector(event);
                      }}
                      disabled={!this.state.userInformation.permissions.update}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  spacing={3}
                  style={{ width: "100%" }}
                >
                  <Grid item xs={5}>
                    <TextField
                      label="Responsável - Origem"
                      variant="outlined"
                      style={{ width: "100%" }}
                      color={theme.secondary_color}
                      value={this.state.sharingSourceOwner}
                      onChange={(event) => {
                        this.handleSharingSourceOwner(event);
                      }}
                      disabled={!this.state.userInformation.permissions.update}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <ArrowForwardIcon style={{ width: "100%" }} />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      label="Responsável - Destino"
                      variant="outlined"
                      style={{ width: "100%" }}
                      color={theme.secondary_color}
                      value={this.state.sharingDestinyOwner}
                      onChange={(event) => {
                        this.handleSharingDestinyOwner(event);
                      }}
                      disabled={!this.state.userInformation.permissions.update}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                direction="column"
                alignItems="center"
                justify="center"
                style={{ width: "100%" }}
              >
                <Button
                  variant="contained"
                  style={{
                    background: this.state.saveSharingButtonDisabled
                      ? "#91aaee"
                      : theme.secondary_color,
                    color: "white",
                    fontSize: "15px",
                  }}
                  onClick={() => {
                    this.saveSharing();
                  }}
                  disabled={
                    !this.state.userInformation.permissions.update ||
                    this.state.saveSharingButtonDisabled
                  }
                >
                  Salvar
                </Button>
              </Grid>
            </Dialog>

            <Dialog
              onClose={() => {
                this.handleErrorDialog("");
              }}
              open={this.state.errorDialog}
              PaperProps={{
                style: {
                  width: "auto",
                  padding: "16px",
                },
              }}
            >
              <DialogTitle>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>{this.state.errorMessage}</Grid>
                </Grid>
              </DialogTitle>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={4}
              >
                <Grid item>
                  <HighlightOffIcon
                    style={{ color: "#EA1C1C", fontSize: "80px" }}
                  />
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <Button
                    variant="contained"
                    style={{
                      background: "#EA1C1C",
                      color: "white",
                      width: "100%",
                      height: "56px",
                    }}
                    onClick={() => {
                      this.handleErrorDialog("");
                    }}
                  >
                    Ok
                  </Button>
                </Grid>
              </Grid>
            </Dialog>
            {/* Dialogo para enviar questionário por email*/}
            <Dialog sx={{ m: 0, p: 2 }} open={this.state.sendQuestByEmail} onClose={this.handleClose}>
              <DialogTitle
                style={{
                  color: theme.quaternary_color,
                  backgroundColor: theme.secondary_color,
                  textAlign: "center",
                }}
              >
                Enviar questionário
              </DialogTitle>
              <DialogContent>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item textAlign="center">
                    <DialogContentText>
                      Para mais de um destinatario<br />
                      Separe os e-mails por virgulra<br />
                      Ex: <strond>teste@teste.com;testedois@teste.com</strond><br /> <br />
                      <TextField style={{ width: '100%' }} label="Email" variant="outlined" onChange={(e) => this.setState({ emailInput: e.target.value })} />
                    </DialogContentText>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} autoFocus>
                  Cancelar
                </Button>
                <Button
                  onClick={() => this.sendQuestionForMail()}
                  style={{
                    color: theme.secondary_color,
                    backgroundColor: theme.primary_color,
                  }}
                >
                  Enviar
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    processId: state.processReducer.processId,
    loadingProcess: state.processReducer.loadingProcess,
    processArea: state.processReducer.processAreaName,
  };
}

export default connect(mapStateToProps, { changePage, setLoadingProcess })(
  ProcessDashboard
);
