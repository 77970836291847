import myApp from "../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";

const ART_NAME = "Art 07 - Inciso IX";
const database = myApp.firestore();

async function getLIAProcesses() {
  const companyAreas = await database
    .collection("areas")
    .where("fk_companies", "==", localStorage.getItem("currentCompany"))
    .where("active", "==", true)
    .get();

  const areas = companyAreas.docs.map((area) => {
    let a = {...area.data(), id: area.id};
    return a;
  });

  const processesPromises = areas.map((area) =>
    database.collection("processes").where("fk_areas", "==", area.id).get()
  );

  const areaProcesses = await Promise.all(processesPromises);

  const filteredArt7Processes = [];

  areaProcesses.forEach((area) => {
    area.docs.forEach((process) => {
      const { legalBases } = process.data();
      if (legalBases) {
        legalBases.forEach((art) => {
          if (art.includes(ART_NAME)) {
            filteredArt7Processes.push({
              id: process.id,
              ...process.data(),
              areaName: areas.find((a) => a.id === process.data().fk_areas).name,
            });
          }
        });
      }
    });
  });

  return filteredArt7Processes;
}

async function getLIAQuestions() {
  const filteredQuestions = [];
  const LIAQuestionsSnapshot = await database
    .collection("questions")
    .where("LIA", "==", true)
    .get();

  LIAQuestionsSnapshot.forEach((question) =>
    filteredQuestions.push({ id: question.id, ...question.data() })
  );

  filteredQuestions.sort((a, b) => {
    if (a.questionOrder > b.questionOrder) {
      return 1;
    }

    if (a.questionOrder < b.questionOrder) {
      return -1;
    }

    return 0;
  });

  return filteredQuestions;
}

function getProcessAnswersAreValid(process) {
  if (!process) {
    return false;
  }

  const answers = process.answers || [];
  let allAnswersValid = true;

  answers.forEach((answer) => {
    if (!answer) {
      allAnswersValid = false;
    }
  });

  return allAnswersValid;
}

function getProcessReportIsAvailable(process, questionsAmount) {
  if (!process) {
    return false;
  }

  const answers = process.answers || [];

  const allQuestionsAnswered = questionsAmount === answers.length;
  const allAnswersValid = getProcessAnswersAreValid(process);

  return allQuestionsAnswered && allAnswersValid;
}

async function getAreasFromProcesses(processList) {
  let areas = [];

  for (const process of processList) {
    if (!areas.some((a) => a === process.areaName)) {
      areas.push(process.areaName);
    }
  }

  return areas;
}

async function getCompanyInfo(fk_area) {
  const areaSnapshot = await database.collection("areas").doc(fk_area).get();
  const fk_companies = areaSnapshot.data().fk_companies;
  const companySnaphost = await database
    .collection("companies")
    .doc(fk_companies)
    .get();

  return { id: companySnaphost.id, ...companySnaphost.data() };
}

function makeQuestion(text, questionOrder) {
  const question = { text, questionOrder, LIA: true };

  return question;
}

async function addLIAQuestions() {
  const questions = [
    "Por que você deseja processar os dados?",
    "Qual benefício você espera obter do tratamento?",
    "Algum terceiro se beneficia do tratamento?",
    "Existe algum benefício público mais amplo para o tratamento?",
    "Quão importantes são os benefícios que você identificou?",
    "Qual seria o impacto se você não pudesse prosseguir com o tratamento?",
    "Você está cumprindo outras leis relevantes?",
    "Você está cumprindo as diretrizes do Programa de Governança e Boas Práticas por você/sua emrpesa adotado?",
    "Existem outras questões éticas com o tratamento?",
    "Esse tratamento realmente ajudará você a atingir seu propósito? ",
    "O tratamento é proporcional a esse propósito? ",
    "Você pode atingir o mesmo objetivo sem o tratamento de dados? ",
    "Você pode atingir o mesmo objetivo tratando menos dados ou tratando os dados de outra forma mais óbvia ou menos intrusiva?",
    "São dados de categoria especial ou dados sensíveis? ",
    'São dados que as pessoas provavelmente consideram particularmente "privados"? ',
    "Você está processando dados de crianças ou dados relacionados a outras pessoas vulneráveis? ",
    "Os dados são sobre pessoas a título pessoal ou profissional?",
    "Você tem um relacionamento existente com o titular de dados? ",
    "Qual é a natureza do relacionamento e como você usou os dados no passado? ",
    "Você coletou os dados diretamente do indivíduo? ",
    "O que você disse a eles na hora? ",
    "Se você obteve os dados de terceiros, o que eles disseram aos indivíduos sobre a reutilização por terceiros para outros fins e isso cobre você? ",
    "Há quanto tempo você coleta os dados? ",
    "Houve alguma mudança na tecnologia ou no contexto desde então que afetaria as expectativas? ",
    "O propósito e método pretendidos são amplamente compreendidos? ",
    "Você pretende fazer algo novo ou inovador? ",
    "Você tem alguma evidência sobre as expectativas - por exemplo, de pesquisa de mercado, grupos de foco ou outras formas de consulta?",
    "Quais são os possíveis impactos do tratamento de dados nas pessoas? ",
    "Os indivíduos perderão qualquer controle sobre o uso de seus dados pessoais? ",
    "Qual é a probabilidade e gravidade de qualquer impacto potencial? ",
    "Algumas pessoas têm probabilidade de se opor ao tratamento ou considerá-lo intrusivo? ",
    "Você pode adotar alguma proteção para minimizar o impacto?",
    "Você oferece ou pode oferecer a opção de o titular se opor ao tratamento?",
    "Após a análise, você considera o interesse legítimo?",
  ];

  questions.forEach((questionText, index) => {
    const madeQuestion = makeQuestion(questionText, index + 1);
    console.log(madeQuestion);
    database.collection("questions").add(madeQuestion);
  });
}

async function deleteLIAQuestions() {
  const questions = await database
    .collection("questions")
    .where("LIA", "==", true)
    .get();

  questions.forEach((question) => {
    question.ref.delete();
  });
}

export {
  deleteLIAQuestions,
  addLIAQuestions,
  getLIAProcesses,
  getLIAQuestions,
  getProcessAnswersAreValid,
  getProcessReportIsAvailable,
  getCompanyInfo,
  getAreasFromProcesses,
};
