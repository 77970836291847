import React, { useState, useEffect } from 'react';

//imports mui
import {
  Paper,
  Grid,
  Typography,
  ButtonBase,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';

//icones
import FolderIcon from '@material-ui/icons/Folder';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';

//import cores do tema mui
import theme from '../../../../core/theme.json'

//import redux
import { useDispatch, connect } from 'react-redux'
import { Link } from "react-router-dom";
import { setMasterProcessId, setMasterProcessName } from '../redux/newEtapasEPMReducer';
import Login from './login';

const App = (props) => {

  const [processMasterList, setProcessMasterList] = useState([]);
  const [userId, setUserId] = useState(props.userId);
  const [newMasterProcessName, setNewMasterProcessName] = useState('');
  const [dialogs, setDialogs] = useState({
    newMasProcess: false
  });
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setUserId(props.userId)
    getProcessMaster();
  }, [props.Logged]);

  const getProcessMaster = async () => {
    try {
      let obj = {
        qid: "PROCESSOS:PROCESSMASTER",
        conditions: [
          {
            filterid: "USER_ID",
            values: [props.userId],
          },
        ],
      };
      let getProcessMaster = await fetch('https://apps.beelegal.com.br/epm_rayel/Integration/Query', {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(obj),
      });
      let resGetProcessMaster = await getProcessMaster.json();

      let list = [];
      for (let item of resGetProcessMaster.list) {
        list.push(item);
      }
      setProcessMasterList(list)

      console.log('Lista de processos master', resGetProcessMaster);

    } catch (errorGetProcessMaster) {
      console.log('errorGetProcessMaster', errorGetProcessMaster)
    }
  }

  const setMasterProcessData = (item) => {
    dispatch(setMasterProcessId(item.ID));
    dispatch(setMasterProcessName(item.PROCESSO_MASTER));
    console.log('Obj', item)
  }

  const handleAddMasterProcess = async () => {
    setOpen(true)
    try {
      let obj = {
        tid: "VF9CRUVMRUdBTF9QUk9DRVNTT19NQVNURVI6MjAxMjM2",
        fid: 196,
        data: {
          USER_ID: userId,
          PROCESSO_MASTER: newMasterProcessName
        }
      };

      let addMasterProcess = await fetch('https://apps.beelegal.com.br/epm_rayel/Integration/Save', {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          auth: props.token,
        },
        body: JSON.stringify(obj),
      });

      let resAddMasterProcess = await addMasterProcess.json();

      if (resAddMasterProcess.error) {
        console.log('errorAddStaps', resAddMasterProcess.error)
      } else {
        console.log('Resposta da requisição', resAddMasterProcess)
      }


    } catch (erroAddMasterProcess) {
      console.log('erroAddMasterProcess', erroAddMasterProcess)
    }
    getProcessMaster()
    setDialogs({
      ...dialogs,
      newMasProcess: false
    });
    setOpen(false)
  }

  const handleCloseDialogs = (dialog) => (value) => {
    setDialogs({
      ...dialogs,
      [dialog]: false
    });
  }

  const handleOpenDialogs = (dialog) => (value) => {
    setDialogs({
      ...dialogs,
      [dialog]: true
    });
  }

  const handleClose = async () => {
    setOpen(false);
  };

  return (
    props.Logged ?
      <Grid
        container
        style={{
          padding: 10
        }}
      >
        {/*Botão para criar novo processo master */}
        <Grid
          item
          style={{
            marginRight: 20,
            width: "250px",
            height: '200px'
          }}
        >
          <ButtonBase
            onClick={handleOpenDialogs('newMasProcess')}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "8px"
            }}
          >
            <Paper
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "8px"
              }}
            >
              <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
                style={{
                  height: "100%"
                }}
              >

                <Grid
                  item
                  style={{
                    height: "85%",
                    width: "100%"
                  }}
                >
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    style={{ height: "100%" }}
                  >
                    <Grid
                      item
                      container
                      justify="center"
                      alignItems="center"
                      style={{
                        height: "50%"
                      }}
                    >
                      <CreateNewFolderIcon
                        style={{
                          fontSize: theme.icon_size,
                          color: theme.tertiary_color
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      justify="center"
                      style={{
                        width: "100%",
                        height: "50%"
                      }}
                    >
                      <Grid
                        item
                        container
                        justify="center"
                        style={{
                          width: "100%",
                          height: "90%",
                          overflowY: "hidden",
                          textOverflow: "ellipsis",
                          margin: "0 10px 0 10px"
                        }}
                      >
                        <Typography
                          variant="h5"
                          component="h5"
                          style={{
                            overflowWrap: "anywhere",
                            color: "#6A6A6A"
                          }}
                        >
                          Novo processo Master
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </ButtonBase>
        </Grid>

        {
          processMasterList ?
            processMasterList.map((item, index) => (
              <Link
                key={index}
                to='/new-epm-etapas/'
                style={{ textDecoration: "none", color: "white" }}
              >
                <Grid
                  item
                  style={{
                    marginRight: 20,
                    width: "250px",
                    height: '200px'
                  }}
                >
                  <ButtonBase
                    onClick={() => setMasterProcessData(item)}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "8px"
                    }}
                  >
                    <Paper
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "8px"
                      }}
                    >
                      <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="center"
                        style={{
                          height: "100%"
                        }}
                      >

                        <Grid
                          item
                          style={{
                            height: "85%",
                            width: "100%"
                          }}
                        >
                          <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            style={{ height: "100%" }}
                          >
                            <Grid
                              item
                              container
                              justify="center"
                              alignItems="center"
                              style={{
                                height: "50%"
                              }}
                            >
                              <FolderIcon
                                style={{
                                  fontSize: theme.icon_size,
                                  color: theme.tertiary_color
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              container
                              justify="center"
                              style={{
                                width: "100%",
                                height: "50%"
                              }}
                            >
                              <Grid
                                item
                                container
                                justify="center"
                                style={{
                                  width: "100%",
                                  height: "90%",
                                  overflowY: "hidden",
                                  textOverflow: "ellipsis",
                                  margin: "0 10px 0 10px"
                                }}
                              >
                                <Typography
                                  variant="h5"
                                  component="h5"
                                  style={{
                                    overflowWrap: "anywhere",
                                    color: "#6A6A6A"
                                  }}
                                >
                                  {item.PROCESSO_MASTER}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </ButtonBase>
                </Grid>
              </Link>
            ))
            :
            <></>
        }

        <Dialog
          open={dialogs.newMasProcess}
          onClose={handleCloseDialogs('newMasProcess')}
        >
          <DialogTitle
            style={{
              color: theme.quaternary_color,
              backgroundColor: theme.secondary_color,
              textAlign: "center",
            }}
          >
            CADASTRO DE NOVO PROCESSO MASTER
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                textAlign="center"
              >
                <DialogContentText>

                  <Grid
                    container
                    style={{
                      width: '600px',
                    }}
                  >
                    <Grid
                      item
                      container
                      justifyContent='center'
                      alignItems='center'
                      style={{
                        height: '80px'
                      }}
                    >
                      <TextField
                        label="Nome do processo master"
                        variant="outlined"
                        style={{
                          width: "80%",
                        }}
                        value={newMasterProcessName}
                        onChange={ (e) => {setNewMasterProcessName(e.target.value)} }
                      />
                    </Grid>
                  </Grid>
                </DialogContentText>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseDialogs('newMasProcess')}
              autoFocus
            >
              Cancelar
            </Button>
            <Button
              onClick={handleAddMasterProcess}
              style={{
                color: '#fff',
                backgroundColor: theme.primary_color,
              }}
            >
              Adicionar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={open} onClose={handleClose} >
          <Backdrop open={open} onClick={handleClose}>
            <Grid
              direction='column'
              justifyContent='center'
              alignItems='center'
            >
              <h1 style={{ color: '#fff' }} >Criando processo master</h1>
              <CircularProgress color="primary" />
            </Grid>
          </Backdrop>
        </Dialog>
      </Grid>
      :
      <Login />
  );
}

const mapStateToProps = (state) => {
  return {
    Logged: state.newEtapasEPMReducer.Logged,
    token: state.newEtapasEPMReducer.token,
    userId: state.newEtapasEPMReducer.userId
  };
}

export default connect(mapStateToProps, {})(App);