//definindo states iniciais caso não receba
const initialState = {
    name: '',
    area: '',
    process: '',
    status: '',
    dateToReply: '',
    listQuestionAndAnswer: [],
    newQuiz: false,
    newQuizId: '',
    newQuizQuestions: []
};

//definindo os tipos de cada função
const SET_NAME = "SET_NAME";
const SET_AREA = "SET_AREA";
const SET_PROCESS = "SET_PROCESS";
const SET_STATUS = "SET_STATUS";
const SET_DATE_TO_REPLY = "SET_DATE_TO_REPLY";
const SET_LIST_QUESTION_ANSWERS = "SET_LIST_QUESTION_ANSWERS";
const SET_NEW_QUIZ = "SET_NEW_QUIZ";
const SET_NEW_QUIZ_ID = "SET_NEW_QUIZ_ID";
const SET_NEW_QUIZ_QUESTIONS = "SET_NEW_QUIZ_QUESTIONS";

//função recebe e altera o nome
export const setName = data => ({
    type: SET_NAME,
    data
});

//função recebe e altera a area
export const setArea = data => ({
    type: SET_AREA,
    data
});

//função recebe e altera o processo
export const setProcess = data => ({
    type: SET_PROCESS,
    data
});

//função recebe e altera o status
export const setStatus = data => ({
    type: SET_STATUS,
    data
});

//função recebe e altera a data de resposta
export const setDateToReply = data => ({
    type: SET_DATE_TO_REPLY,
    data
});

//função recebe e altera a perguntas e respostas
export const setQuestionsAndAnswers = data => ({
    type: SET_LIST_QUESTION_ANSWERS,
    data
});

export const setNewQuiz = data => ({
    type: SET_NEW_QUIZ,
    data
});

export const setNewQuizId = data => ({
    type: SET_NEW_QUIZ_ID,
    data
});

export const setNewQuizQuestions = data => ({
    type: SET_NEW_QUIZ_QUESTIONS,
    data
});

//criando o reducer para o combine
const viewQuestionnaireReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NAME:
            return { ...state, name: action.data };
        case SET_AREA:
            return { ...state, area: action.data };
        case SET_PROCESS:
            return { ...state, process: action.data };
        case SET_STATUS:
            return { ...state, status: action.data };
        case SET_DATE_TO_REPLY:
            return { ...state, dateToReply: action.data };
        case SET_LIST_QUESTION_ANSWERS:
            return { ...state, listQuestionAndAnswer: action.data };
        case SET_NEW_QUIZ:
            return { ...state, newQuiz: action.data };
        case SET_NEW_QUIZ_ID:
            return { ...state, newQuizId: action.data };
        case SET_NEW_QUIZ_QUESTIONS:
            return { ...state, newQuizQuestions: action.data };
    }
    return state;
}

export default viewQuestionnaireReducer;