import { Button, Grid, TextField, Typography } from "@material-ui/core";
import theme from "../../../../core/theme.json";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import myApp from "../../../../core/infrastructure/firebaseConfig";
import Swal from "sweetalert2";

export const VerificationEmail = ({
  userData,
  questData,
  setVerificationEmail,
  setActiveStepIndicator,
  noneValue,
}) => {
  const [email, setEmail] = useState("");
  const currentCompanyId = localStorage.getItem("currentCompany");
  const { companyGroupId } = useParams();

  const saveEmail = async () => {
    let aux = [];
    let verfication = await myApp
      .firestore()
      .collection("AnswerModuloVideo")
      .get();
    if (verfication.docs.length !== 0) {
      for (let item of verfication.docs) {
        if (
          item.data().email == email &&
          item.data().nameOfQuestion == questData.name
        ) {
          let setTentative = myApp
            .firestore()
            .collection("AnswerModuloVideo")
            .doc(item.id)
            .update({
              tentatives: item.data().tentatives + 1,
            });
          localStorage.setItem("verificationEmail", email);
          console.log("foi");
          setVerificationEmail(false);
          setActiveStepIndicator(1);
          return;
        }
      }

      if (
        verfication.docs.map(
          (item) => item.data().nameOfQuestion == questData.name
        ) &&
        verfication.docs.map((item) => item.data().email !== email)
      ) {
        console.log("não é igual");
        let save = myApp.firestore().collection("AnswerModuloVideo").doc().set({
          email: email,
          nameOfQuestion: questData.name,
          idModule: questData.id,
          fk_company: currentCompanyId,
          date: Date.now(),
          tentatives: 1,
        });

        localStorage.setItem("verificationEmail", email);
        setVerificationEmail(false);
        setActiveStepIndicator(1);
        return;
      }
    } else {
      let save = myApp.firestore().collection("AnswerModuloVideo").doc().set({
        email: email,
        nameOfQuestion: questData.name,
        idModule: questData.id,
        fk_company: currentCompanyId,
        date: new Date(),
        tentatives: 1,
      });

      localStorage.setItem("verificationEmail", email);
      setVerificationEmail(false);
      setActiveStepIndicator(1);
      return;
    }
  };

  console.log(questData);

  return (
    <>
      {noneValue == true ? (
        Swal.fire({
          icon: "error",
          title: "Por favor , crie um questionário primeiro e tente novamente!",
          showConfirmButton: "Ok",
          confirmButtonColor: "#38f205",
        }).then(() => {
          window.close();
        })
      ) : (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ marginTop: "5%", width: "100%", marginLeft: "15%" }}
        >
          <Typography variant="h5">
            Insira seu e-mail para continuar:
          </Typography>

          <TextField
            style={{ width: "50%", marginTop: "6%", marginBottom: "15%" }}
            label="Insira seu e-mail"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <Button
            onClick={saveEmail}
            style={{
              cursor: "pointer",
              width: "300px",
              height: "40px",
              background: theme.primary_color,
              color: theme.secondary_color,
            }}
          >
            Iniciar teste
          </Button>
        </Grid>
      )}
    </>
  );
};
