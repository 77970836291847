import React, { Component } from 'react';
import myApp from '../../../../core/infrastructure/firebaseConfig';
import "firebase/compat/firestore";

import { Grid, Typography, Dialog, DialogContent, Button, TextField, FormControl, InputLabel, Select, MenuItem, IconButton } from '@material-ui/core';

import { Close } from '@material-ui/icons';

import { connect } from 'react-redux';
import areaIconList from '../../../../shared/assets/areaIconList';

import { changePage } from '../../../presentation/redux/actions';
import theme from '../../../../core/theme.json'
class EditArea extends Component {
  constructor(props) {
    super(props);

    this.db = myApp.firestore();

    this.state = {
      editDialog: false,

      name: "",
      owner: "",
      icon: "",
      userInformation: JSON.parse(sessionStorage.getItem('@blue-legal/userInformation'))
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.editDialog !== this.props.editDialog) {
      this.handleDialog();
    }
  }

  componentDidMount() {
    this.getAreaInfo();
  }

  handleDialog() {
    this.setState({
      editDialog: !this.state.editDialog,
    })
  }

  async getAreaInfo() {
    let database = myApp.firestore();

    try {
      let data = await database.collection('areas').doc(this.props.area).get();
      let area = data.data();

      this.setState({
        name: area.name,
        owner: area.owner,
        icon: area.icon
      })
    }
    catch (error) {
      console.error(error);
    }
  }

  handleName(event) {
    this.setState({
      name: event.target.value
    })
  }

  handleIcon(event) {
    this.setState({
      icon: event.target.value
    })
  }

  handleOwner(event) {
    this.setState({
      owner: event.target.value
    })
  }

  async editArea() {
    let database = myApp.firestore();

    await database.collection('areas').doc(this.props.area).update({
      name: this.state.name,
      owner: this.state.owner,
      icon: this.state.icon
    });

    this.props.setAreaName(this.state.name)

    this.handleDialog();
  }

  async removeArea() {
    let database = myApp.firestore();

    await database.collection('areas').doc(this.props.area).update({
      active: false
    });

    this.props.changePage(0);
    this.handleDialog();
  }

  render() {
    return (
      <Dialog
        open={this.state.editDialog}
        fullWidth={true}
        maxWidth={"md"}
        onClose={() => { this.handleDialog() }}
      >
        <DialogContent>
          <Grid
            container
            direction="column"
            justify="center"
          >
            <Grid item container direction="row">
              <Grid item style={{ width: "100%" }} xs={10}>
                <Typography variant="h5" component="h5" >
                  Editar Área
                </Typography>
              </Grid>
              <Grid item container xs={2} justify="flex-end">
                <IconButton onClick={() => this.handleDialog()}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
            <Grid style={{ height: "24px" }} />
            <Grid item>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={4}
              >
                <Grid item style={{ width: "100%" }} xs={8}>
                  <TextField
                    inputProps={{
                      maxLength: 50,
                    }}
                    label="Nome da Área"
                    variant="outlined"
                    style={{ width: "100%" }}
                    color={theme.secondary_color}
                    value={this.state.name}
                    onChange={(event) => { this.handleName(event) }}
                    disabled={!this.state.userInformation.permissions.update}
                  />
                </Grid>
                <Grid item style={{ width: "100%" }} xs={4}>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <InputLabel>
                      Ícone
                    </InputLabel>
                    <Select
                      value={this.state.icon}
                      onChange={(event) => { this.handleIcon(event) }}
                      label="Ícone"
                      disabled={!this.state.userInformation.permissions.update}
                    >
                      {areaIconList.map((icon) => (
                        <MenuItem value={icon.value}>
                          <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            spacing={2}
                            style={{ marginLeft: "4px" }}
                          >
                            <Grid item style={{ padding: "0px" }}>
                              <icon.icon />
                            </Grid>
                            <Grid item>
                              {icon.name}
                            </Grid>
                          </Grid>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item style={{ width: "100%" }} xs={8}>
                <TextField
                  inputProps={{
                    maxLength: 50,
                  }}
                  label="Responsável"
                  variant="outlined"
                  style={{ width: "100%" }}
                  color={theme.secondary_color}
                  value={this.state.owner}
                  onChange={(event) => { this.handleOwner(event) }}
                  disabled={!this.state.userInformation.permissions.update}
                />
              </Grid>
            </Grid>
            <Grid style={{ height: "15px" }} />
            <Grid item>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={4}
              >
                <Grid item style={{ width: "100%" }} xs={2}>
                  <Button
                    variant="contained"
                    style={{ background: theme.secondary_color, color: theme.tertiary_color, width: "100%", height: "56px" }}
                    onClick={() => { this.removeArea() }}
                    disabled={this.state.name.length === 0 || !this.state.userInformation.permissions.delete}
                  >
                    Remover
                  </Button>
                </Grid>
                <Grid item style={{ flexGrow: 1 }} />
                <Grid item style={{ width: "100%" }} xs={2}>
                  <Button
                    variant="contained"
                    style={{ background: this.state.name.length === 0 ? "#687394" : "#193993", color: "white", width: "100%", height: "56px" }}
                    onClick={() => { this.editArea() }}
                    disabled={this.state.name.length === 0 || !this.state.userInformation.permissions.update}
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid style={{ height: "16px" }} />
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    activities: state.areasReducer.activities,

    area: state.homeReducer.area,
  };
}

export default connect(mapStateToProps, { changePage })(EditArea);