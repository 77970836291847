import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";

const database = myApp.firestore();

export const getQuiz = async () => {
  const quizes = [];
  let companyId = localStorage.getItem("currentCompany")
  await database.collection("quiz").where("active", "==", true).where("fk_company", "==", companyId).get()
    .then((collection) => {
      collection.docs.forEach(response => {
        if (response.exists) {
          quizes.push(response.data());
        }
      });
    })
    .catch((error) => console.error(error));

  quizes.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }

    if (a.name < b.name) {
      return -1;
    }

    return 0;
  });

  return quizes;
}

export const getQuizByType = async (type) => {
  let quizes = [];
  let companyId = localStorage.getItem("currentCompany");

  try {
    const quizesSnapshot = await database
      .collection("quiz")
      .where("active", "==", true)
      .where("fk_company", "==", companyId)
      .where("type", "==", type)
      .get();

    quizesSnapshot.docs.forEach(doc => {
      quizes.push(doc.data());
    });

    quizes.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }

      if (a.name < b.name) {
        return -1;
      }

      return 0;
    });
  } catch (error) {
    console.error(error)
  }

  return quizes;
}

export const getQuizById = async (id) => {
  let quizId = '';
  let quiz = null;
  if (typeof id == 'string') {
    quizId = id;
    try {
      quiz = await database.collection("quiz").doc(quizId).get()
    } catch (error) {
      console.error(error);
    }
  } else {
    quizId = id[1];
    try {
      quiz = await database.collection("newQuizz").doc(quizId).get()
    } catch (error) {
      console.error(error);
    }
  }
  return quiz.data();
}

