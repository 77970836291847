import React, { useCallback, useEffect, useState } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Button,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TextField,
  Typography
} from "@material-ui/core";

import ReportPDF from "./ReportPDF";
import theme from "../../../core/theme.json";
import {
  getLIAProcesses,
  getLIAQuestions,
  getProcessReportIsAvailable,
  getAreasFromProcesses,
} from "./functions";

const ReportsLIA = () => {
  const [processViewIndex, setProcessViewIndex] = useState(0);
  const [answeredArt7Processes, setAnsweredArt7Processes] = useState([]);
  const [questions, setQuestions] = useState([]);

  const [areaList, setAreaList] = useState([]);

  const [loading, setLoading] = useState(true);

  const [areaFilter, setAreaFilter] = useState("");
  const [processFilter, setProcessFilter] = useState("");

  useEffect(() => {
    async function loadLIAProcessesData() {
      try {
        const LIAProcesses = await getLIAProcesses();
        const LIAQuestions = await getLIAQuestions();

        const filteredArt7Processes = LIAProcesses.filter((process) => {
          const isProcessAvailable = getProcessReportIsAvailable(
            process,
            LIAQuestions.length
          );
          return isProcessAvailable;
        });

        const areas = await getAreasFromProcesses(filteredArt7Processes);

        setAreaList(areas);
        setAnsweredArt7Processes(filteredArt7Processes);
        setQuestions(LIAQuestions);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }

    loadLIAProcessesData();
  }, []);

  const handleSelectProcessView = useCallback((index) => {
    setProcessViewIndex(index);
  }, []);

  const handleFilterAreaChange = (event) => {
    setProcessViewIndex(0);
    setAreaFilter(event.target.value);
  }

  const handleFilterProcessChange = (event) => {
    setProcessViewIndex(0);
    setProcessFilter(event.target.value);
  };

  const filterProcessList = (process) => {
    if (
      areaFilter !== "" &&
      processFilter !== "" &&
      process.areaName === areaFilter &&
      process.name.toUpperCase().includes(processFilter.toUpperCase())
    ) {
      return process;
    } else if (
      areaFilter !== "" &&
      processFilter === "" &&
      process.areaName === areaFilter &&
      process.name !== ""
    ) {
      return process;
    } else if (
      areaFilter === "" &&
      processFilter !== "" &&
      process.areaName !== "" &&
      process.name.toUpperCase().includes(processFilter.toUpperCase())
    ) {
      return process;
    } else if (
      areaFilter === "" &&
      processFilter === "" &&
      process.areaName !== "" &&
      process.name !== ""
    ) {
      return process;
    }
  };

  const renderCells = useCallback(
    (process, index) => {
      return (
        <TableRow key={process.id} style={index % 2 ? { background: "white" } : { background: "#e3e3e3" }}>
          <TableCell>{process.areaName}</TableCell>
          <TableCell>{process.name}</TableCell>
          <TableCell>{process.description}</TableCell>
          <TableCell>
            <Button
              color="primary"
              backgroundColor="black"
              style={{
                background: theme.primary_color,
                color: theme.secondary_color,
              }}
              onClick={() => handleSelectProcessView(index)}
            >
              VISUALIZAR
            </Button>
          </TableCell>
        </TableRow>
      );
    },
    [handleSelectProcessView]
  );

  return (
    <div>
      <Grid
        component={Paper}
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        rowSpacing={2}
        style={{ width: "100%", height: "7vh", marginTop: 10 }}
      >
        <Grid item xs={2}>
          <Typography variant="h6" component="h6" style={{ textAlign: "center" }}>
            Filtros
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <FormControl size="small" variant="outlined" style={{ width: "70%" }}>
            <InputLabel>Área</InputLabel>
            <Select
              label="Área"
              value={areaFilter}
              onChange={(event) => handleFilterAreaChange(event)}
            >
              <MenuItem value={""}>Todos</MenuItem>
              {areaList && areaList.map((area) => (
                <MenuItem value={area}>{area}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <TextField
            size="small"
            label="Processo"
            variant="outlined"
            style={{ width: "70%" }}
            color={theme.secondary_color}
            value={processFilter}
            onChange={(event) => handleFilterProcessChange(event)}
          />
        </Grid>
      </Grid>
      {loading ? (
        <Grid container direction="column" alignItems="center" style={{ width: "100%", height: "100%" }}>
          <Grid item style={{ paddingTop: "10vh" }}>
            <CircularProgress size={70} color={theme.secondary_color} />
          </Grid>
        </Grid>
      ) : (
        <>
          {answeredArt7Processes !== null && answeredArt7Processes.length > 0 ? (
            <Grid container direction="row" justifyContent="flex-start" style={{ width: "100%", marginTop: 10 }}>
              <Grid item xs={5}>
                <TableContainer component={Paper} style={{ maxHeight: "70vh" }}>
                  <Table stickyHeader>
                    <colgroup>
                      <col style={{ width: "25%" }} />
                      <col style={{ width: "25%" }} />
                      <col style={{ width: "40%" }} />
                      <col style={{ width: "10%" }} />
                    </colgroup>
                    <TableHead>
                      <TableRow>
                        <TableCell>ÁREA</TableCell>
                        <TableCell>PROCESSO</TableCell>
                        <TableCell>DESCRIÇÃO PROCESSO</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>{answeredArt7Processes.filter(filterProcessList).map(renderCells)}</TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              {answeredArt7Processes.filter(filterProcessList).length > 0 && (
                <Grid item xs={7}>
                  <PDFViewer width="100%" height="1000px">
                    <ReportPDF
                      questions={questions}
                      process={answeredArt7Processes.filter(filterProcessList)[processViewIndex]}
                    />
                  </PDFViewer>
                </Grid>
              )}
            </Grid>
          ) : (
            <Typography variant="h5" component="h5" style={{ textAlign: "center", padding: "10px", marginTop: 10 }}>
              Não há processos LIA para a empresa
            </Typography>
          )}
        </>
      )}
    </div>
  );
};

export default ReportsLIA;
