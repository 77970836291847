import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Grid, Button, Fab } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import SettingsIcon from '@material-ui/icons/Settings';
import { withStyles } from '@material-ui/core/styles';

import theme from '../../../../core/theme.json'

const styles = themes => ({
  card: {
    minWidth: '400px',
    padding: '25px 0',
  },
  cardPrimary: {
    background: theme.secondary_color,
    color: theme.quaternary_color,
  },
  cardPrimarySettings: {
    background: theme.tertiary_color,
    color: 'transparent',
  },
  cardQuestion: {
    background: '#FAFAFA',
    color: '#111C39',
  },
  cardAnswer: {
    background: '#3e4760',
    color: '#FAFAFA',
  },
  cardOptionButton: {
    color: '#BFBFBF',
  },
  titleButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: 'none',
    top: 0
  },
  divider: {
    background: '#BFBFBF',
    margin: '12px 0'
  },
  addButton: {
    background: theme.primary_color,
    color: theme.secondary_color,
    width: '38px',
    height: '38px',
    '&.Mui-disabled': {
      backgroundColor: theme.opacity,
    }
  },
  gridAddButton: {
    position: 'absolute',
  },
  addIcon: {
  }
});

class CardQuiz extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userInformation: JSON.parse(sessionStorage.getItem('@blue-legal/userInformation'))
    };
  }

  render() {
    const { classes, addCard, cardProps } = this.props;

    const isPrimaryCard = cardProps.typeCard === 'primary';
    const isQuestionCard = cardProps.typeCard === 'question';
    const isAnswerCard = cardProps.typeCard === 'answer';

    return (
      <Grid
        container
        direction="column"
        style={{ height: "100%", borderRadius: "3px" }}
        className={
          `${isPrimaryCard ? classes.cardPrimary
            : isQuestionCard ? classes.cardQuestion
              : isAnswerCard ? classes.cardAnswer : ''}`
        }
      >
        <Grid item>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid style={{ flexGrow: 1 }} />
            <Grid item>
              <Button style={{ minWidth: "0px" }} onClick={() => { this.props.optionCard() }} disabled={isPrimaryCard}>
                <SettingsIcon
                  fontSize="inherit"
                  className={
                    `${isPrimaryCard ? classes.cardPrimarySettings
                      : isQuestionCard ? classes.cardQuestion
                        : isAnswerCard ? classes.cardAnswer : ''}`
                  }
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid style={{ height: "4px" }} />
        <Grid item style={{ paddingLeft: "4px", paddingRight: "4px" }}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid
              item
              style={{ width: "100%", textAlign: "center" }}
              className={
                `${isPrimaryCard ? classes.cardPrimary
                  : isQuestionCard ? classes.cardQuestion
                    : isAnswerCard ? classes.cardAnswer : ''}`
              }
            >
              {cardProps.title}
            </Grid>
          </Grid>
        </Grid>
        <Grid style={{ height: "24px" }} />
        <Grid item>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            {!cardProps.disableAddButton && this.state.userInformation.permissions.insert ?
              <Grid item className={classes.gridAddButton}>
                <Fab disabled={cardProps.disableAddButton && !this.state.userInformation.permissions.insert} className={classes.addButton} onClick={() => addCard()}>
                  {/* {!cardProps.disableAddButton && this.state.userInformation.permissions.insert && ( */}
                  <Add className={classes.addIcon} />
                  {/* )} */}
                </Fab>
              </Grid>
              :
              <div />
            }
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

CardQuiz.propTypes = {
  classes: PropTypes.object,
  addCard: PropTypes.func.isRequired,
  optionCard: PropTypes.func.isRequired,
  cardProps: PropTypes.object.isRequired
};

export default withStyles(styles)(CardQuiz);