import React, { Component } from "react";
import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";

import {
  Paper,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  DialogContent,
  Tooltip,
} from "@material-ui/core";

import { connect } from "react-redux";
import PhaseTable from "./phaseTable";
import { isUndefined } from "../../../../shared/commands/verifyDefinition";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import theme from "../../../../core/theme.json";
import { Dialog, Typography } from "@mui/material";
import { Close } from "@material-ui/icons";

class ActionPlans extends Component {
  constructor(props) {
    super(props);

    let currentYear = new Date().getFullYear();

    this.state = {
      planTypes: [],
      database: myApp.firestore(),
      areas: [],
      months: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      openDialogEvent: false,
      years: [currentYear - 1, currentYear, currentYear + 1],

      weeks: [
        {
          firstDay: null,
          lastDay: null,
          label: null,
        },
        {
          firstDay: null,
          lastDay: null,
          label: null,
        },
        {
          firstDay: null,
          lastDay: null,
          label: null,
        },
        {
          firstDay: null,
          lastDay: null,
          label: null,
        },
      ],
      currentYear: currentYear,
      currentMonth: new Date().getMonth(),
      dadosList: [],
      dataMapList:[],
      dialogInfos: {},
      owner: "",
      status: "",
    };

    this.handleEventClick = this.handleEventClick.bind(this);
    this.filterEvents = this.filterEvents.bind(this);
    this.filterEventsStatus = this.filterEventsStatus.bind(this);
  }

  async componentDidMount() {
    await this.setWeekLabels();
    await this.getPlanTypes();
    await this.getAreas();
  }

  async getPlanTypes(areaId = null) {
    let aux = []
    var planTypesSnapshot = await this.state.database
      .collection("plan_type")
      .get();
    var planTypes = [];

    let i = 0;
    for (let doc of planTypesSnapshot.docs) {
      let planType = doc.data();
      planType["id"] = doc.id;
      planType["activities"] = await this.getActivities(planType, areaId);
      planType["color"] = this.getColor(i);
      i = i === 3 ? 0 : i + 1;

      if (planType.activities.length > 0) planTypes.push(planType );

     
    }

    // aux.push(this.state.planTypes.map((item) =>
    // item.activities.map((item) => item)),)

    this.setState({ planTypes });


    this.setState({
      dadosList: this.state.planTypes.map((item) =>
        item.activities.concat(this.state.dataMapList)
      ),
    });
    aux.push(this.state.dadosList[1])
   
       
    if(aux[0] != undefined ){
     this.setState({
        dadosList: this.state.planTypes.map((item) =>
          item.activities.concat(aux[0])
        ),
      });
    
    }
    console.log(this.state.dadosList)

  }

  async getActivities(planType, areaId = null) {
    let activities = [];
    let activitiesSnapshot = [];

    if (areaId === null)
      activitiesSnapshot = await this.state.database
        .collection("activities")
        .where("fk_plan_type", "==", planType.id)
        .where("fk_companies", "==", localStorage.getItem("currentCompany"))
        .where("companyActivity", "==", true)
        .get();
    else
      activitiesSnapshot = await this.state.database
        .collection("activities")
        .where("fk_plan_type", "==", planType.id)
        .where("fk_companies", "==", localStorage.getItem("currentCompany"))
        .where("fk_areas", "==", areaId)
        .get();

    for (let doc of activitiesSnapshot.docs) {
      let activity = doc.data();
      activity["id"] = doc.id;

      if (isUndefined(activity.subtasks)) activity.subtasks = [];

      activity.subtasks.forEach((subtask) => {
        if (subtask.date.hasOwnProperty("seconds"))
          subtask.date = subtask.date.toDate();
      });

      activity["weekColors"] = ["#DFDEE4", "#DFDEE4", "#DFDEE4", "#DFDEE4"];
      activities.push(activity);
    }
    this.setState({
      dataMapList :activities
    })
    return activities;
  }

  getColor(i) {
    let colors = [
      theme.primary_color,
      theme.secondary_color,
      theme.tertiary_color,
    ];

    return colors[i];
  }

  async getAreas() {
    let areas = [];

    var areasSnapshot = await this.state.database
      .collection("areas")
      .where("fk_companies", "==", localStorage.getItem("currentCompany"))
      .where("active", "==", true)
      .get();

    for (let doc of areasSnapshot.docs) {
      let area = doc.data();
      area["id"] = doc.id;
      areas.push(area);
    }

    this.setState({
      areas,
    });
  }

  setMonth(value) {
    this.setState(
      {
        currentMonth: value,
      },
      () => this.setWeekLabels()
    );
  }

  setYear(value) {
    this.setState(
      {
        currentYear: value,
      },
      () => this.setWeekLabels()
    );
  }

  filterPlanType(planType) {
    return planType;
  }

  filterEvents(filter) {
    if (this.state.owner != "") {
      if (filter.owner == this.state.owner) {
        return filter;
      }
    } else {
      return filter;
    }
  }

  filterEventsStatus(filter) {
    let timeofondeDay = 1000 * 60 * 60 * 24;
    let calculateTime =
      Math.abs(new Date() - new Date(filter.date)) / timeofondeDay;

    if (this.state.status != "") {
      if (filter.done == true && this.state.status == "Concluído") {
        return filter;
      }

      if (
        filter.done == false &&
        this.state.status == "Vencido" &&
        new Date() > new Date(filter.date)
      ) {
        return filter;
      }
      if (
        filter.done == false &&
        this.state.status == "Pendente" &&
        Math.trunc(calculateTime) >= 1
      ) {
        return filter;
      }
    } else {
      return filter;
    }
  }

  renderTables() {
    const events =
      this.state.dadosList.length != 0 &&
      this.state.dadosList.map((item) => {
        let aux = [];
        for (let time of item.map((item) => item)) {
          let date = time.date?.seconds ? time.date?.seconds * 1000 : time.date;

          aux.push({
            title: time.name,
            date: date,
            done: time.done,
            owner: time.owner,
            doneDate: time.doneDate,
          });
        }
        console.log( this.state.dadosList )
        return aux.map((item) => {
          return {
            title: item.title,
            start: new Date(new Intl.DateTimeFormat("en-US").format(item.date)),
            done: item.done,
            owner: item.owner,
            doneDate: item.doneDate,
          };
        });
      });

    return (
      <Grid style={{ display: "flex" }}>
        <Grid style={{ width: "30%" }}>
          {this.state.planTypes.length !== 0
            ? this.state.planTypes.map((planType) => (
                <>
                  <PhaseTable
                    title={planType.name}
                    color={planType.color}
                    activities={planType.activities}
                    weeks={this.state.weeks}
                    currentMonth={this.state.currentMonth}
                    currentYear={this.state.currentYear}
                  />
                  <Grid item style={{ width: "100%" }}>
                    {/* {this.eventModal(planType)} */}
                  </Grid>
                </>
              ))
            : null}
        </Grid>
        <Grid style={{ width: "100%", height: "120%" }}>
          <Grid
            style={{
              color: theme.secondary_color,
              borderBottom: "none",
              padding: "0px",
            }}
          >
            <h2 style={{ marginLeft: "7.5%" }}>CRONOGRAMA</h2>
          </Grid>
          <FullCalendar
            selectable={true}
            FixedWeekCount={false}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            weekends={true}
            events={events[0]}
            eventContent={this.renderEventContent}
            eventClick={this.handleEventClick}
            headerToolbar={{
              start: "today prev,next",
              center: "title",
              end: "dayGridMonth timeGridWeek timeGridDay",
            }}
            height={"32rem"}
            dayMaxEvents={1}
            locale={"pt-BR"}
            buttonText={{
              today: "Hoje",
              month: "Mês",
              week: "Semana",
              day: "Dia",
            }}
          />
        </Grid>
      </Grid>
    );
  }

  setArea(value) {
    this.setState({ planTypes: [] });
    if (value === "Empresa") this.getPlanTypes();
    else this.getPlanTypes(value);
  }

  async setWeekLabels() {
    let lastMonthDay = new Date(
      this.state.currentYear,
      this.state.currentMonth,
      0
    ).getDate();
    let weeks = this.state.weeks;
    let planTypes = this.state.planTypes;

    let i = 1;
    let j = 0;

    for (i; i <= lastMonthDay; i++) {
      if (j > 3) break;

      var dayOfMonth = new Date(
        this.state.currentYear,
        this.state.currentMonth,
        i
      ).getDay();

      if (dayOfMonth === 1) {
        weeks[j].firstDay = i;
        weeks[j].lastDay = i + 4;

        if (weeks[j].lastDay > lastMonthDay) weeks[j].lastDay = lastMonthDay;

        weeks[j].label =
          weeks[j].firstDay.toString().padStart(2, "0") +
          "/" +
          (this.state.currentMonth + 1).toString().padStart(2, "0") +
          " - " +
          weeks[j].lastDay.toString().padStart(2, "0") +
          "/" +
          (this.state.currentMonth + 1).toString().padStart(2, "0");
        j++;
      }
    }

    this.setState({
      weeks,
    });

    for (let planType of planTypes) {
      for (let activity of planType.activities) {
        let weekNewColors = activity.weekColors;
        let today = new Date();
        let tasks = activity.subtasks.sort((t) => t.date);
        let currentMonth = this.state.currentMonth;
        let currentYear = this.state.currentYear;

        let tasksWeek1 = tasks.filter(
          (t) =>
            new Date(t.date) >=
              new Date(currentYear, currentMonth, weeks[0].firstDay) &&
            new Date(t.date) <=
              new Date(currentYear, currentMonth, weeks[0].lastDay)
        );
        let tasksWeek2 = tasks.filter(
          (t) =>
            new Date(t.date) >=
              new Date(currentYear, currentMonth, weeks[1].firstDay) &&
            new Date(t.date) <=
              new Date(currentYear, currentMonth, weeks[1].lastDay)
        );
        let tasksWeek3 = tasks.filter(
          (t) =>
            new Date(t.date) >=
              new Date(currentYear, currentMonth, weeks[2].firstDay) &&
            new Date(t.date) <=
              new Date(currentYear, currentMonth, weeks[2].lastDay)
        );
        let tasksWeek4 = tasks.filter(
          (t) =>
            new Date(t.date) >=
              new Date(currentYear, currentMonth, weeks[3].firstDay) &&
            new Date(t.date) <=
              new Date(currentYear, currentMonth, weeks[3].lastDay)
        );

        weekNewColors[0] = tasksWeek1.some((t) => !t.completed)
          ? today > new Date(currentYear, currentMonth, weeks[0].lastDay)
            ? "#E5541C"
            : "#122148"
          : tasksWeek1.some((t) => t)
          ? "#38B832"
          : "#DFDEE4";

        weekNewColors[1] = tasksWeek2.some((t) => !t.completed)
          ? today > new Date(currentYear, currentMonth, weeks[1].lastDay)
            ? "#E5541C"
            : "#122148"
          : tasksWeek2.some((t) => t)
          ? "#38B832"
          : "#DFDEE4";

        weekNewColors[2] = tasksWeek3.some((t) => !t.completed)
          ? today > new Date(currentYear, currentMonth, weeks[2].lastDay)
            ? "#E5541C"
            : "#122148"
          : tasksWeek3.some((t) => t)
          ? "#38B832"
          : "#DFDEE4";

        weekNewColors[3] = tasksWeek4.some((t) => !t.completed)
          ? today > new Date(currentYear, currentMonth, weeks[3].lastDay)
            ? "#E5541C"
            : "#122148"
          : tasksWeek4.some((t) => t)
          ? "#38B832"
          : "#DFDEE4";
        activity.weekColors = weekNewColors;
      }
    }
  }

  renderEventContent(eventInfo) {

    let timeofondeDay = 1000 * 60 * 60 * 24;
    let calculateTime =
      Math.abs(new Date() - new Date(eventInfo.event.start)) / timeofondeDay + 1;


    return (
      <>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
            cursor: "pointer",
          }}
        >
          <Tooltip title={`${eventInfo.event.title}`}>
            <div
              style={
                eventInfo.event.extendedProps.done == true
                  ? {
                      width: 20,
                      height: 20,
                      marginBottom: "5%",
                      borderRadius: "50%",
                      backgroundColor: "green",
                    }
                  : new Date() > new Date(eventInfo.event.start) - 1 ||
                    Math.trunc(calculateTime) <= 0
                  ? {
                      width: 20,
                      height: 20,
                      marginBottom: "5%",
                      borderRadius: "50%",
                      backgroundColor: "red",
                    }
                  : Math.trunc(calculateTime) >= 1
                  ? {
                      width: 20,
                      height: 20,
                      marginBottom: "5%",
                      borderRadius: "50%",
                      backgroundColor: "yellow",
                    }
                  : ""
              }
            ></div>
          </Tooltip>
        </div>
      </>
    );
  }

  handleEventClick(info) {
    console.log("teste", info.event.extendedProps.doneDate);

    let timeofondeDay = 1000 * 60 * 60 * 24;
    let calculateTime =
      Math.abs(new Date() - new Date(info.event.start)) / timeofondeDay;
    let aux = {
      title: info.event.title,
      date: info.event.start,
      status: `${
        info.event.extendedProps.done
          ? "Concluído"
          : new Date() > new Date(info.event.start) ||
            Math.trunc(calculateTime) <= 0
          ? "Vencido"
          : "Pendente"
      }`,
      owner: info.event.extendedProps.owner,
      doneDate: info.event.extendedProps.doneDate,
    };
    this.setState({
      dialogInfos: aux,
    });
    this.setState({
      openDialogEvent: !this.openDialogEvent,
    });
  }
  render() {
    return (
      <Paper className="paper">
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="flex-start"
          spacing={2}
          style={{ width: "100%" }}
        >
          <Grid item style={{ width: "100%" }}>
            <FormControl
              variant="outlined"
              style={{ width: "15%", marginLeft: "1%" }}
            >
              <InputLabel>Área</InputLabel>
              <Select
                label="Área"
                defaultValue="Empresa"
                onChange={(e) => this.setArea(e.target.value)}
              >
                <MenuItem value="Empresa">Selecione</MenuItem>
                {this.state.areas.map((area, index) => (
                  <MenuItem value={area.id}>{area.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item style={{ width: "100%" }}>
            <div>{this.renderTables()}</div>
          </Grid>
        </Grid>

        <Dialog
          onClose={() => {
            this.setState({ openDialogEvent: !this.state.openDialogEvent });
          }}
          open={this.state.openDialogEvent}
        >
          <DialogContent>
            <Grid
              style={{ width: 350 }}
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item container direction="row">
                <Grid item container justify="flex-end">
                  <IconButton
                    onClick={() =>
                      this.setState({
                        openDialogEvent: !this.state.openDialogEvent,
                      })
                    }
                  >
                    <Close />
                  </IconButton>
                </Grid>
                <Grid item container justify="center" direction="column">
                  <Typography
                    variant="h5"
                    component="h5"
                    style={{
                      paddingTop: "8px",
                      marginTop: "16px",
                      textAlign: "center",
                    }}
                  >
                    Detalhe da agenda
                  </Typography>

                  <Typography
                    variant="h6"
                    style={{
                      paddingTop: "8px",
                      marginTop: "16px",
                      color: "#6A6A6A",
                    }}
                  >
                    Titulo: {this.state.dialogInfos.title}
                  </Typography>

                  <Typography
                    variant="h6"
                    style={{
                      paddingTop: "8px",
                      marginTop: "16px",
                      color: "#6A6A6A",
                    }}
                  >
                    Status:{" "}
                    <span
                      style={{
                        color: `${
                          this.state.dialogInfos.status == "Concluído"
                            ? "green"
                            : this.state.dialogInfos.status == "Pendente"
                            ? "yellow"
                            : "red"
                        }`,
                      }}
                    >
                      {this.state.dialogInfos.status}
                    </span>
                  </Typography>

                  <Typography
                    variant="h6"
                    style={{
                      paddingTop: "8px",
                      marginTop: "16px",
                      color: "#6A6A6A",
                    }}
                  >
                    Data limite:{" "}
                    {new Intl.DateTimeFormat("pt-BR").format(
                      this.state.dialogInfos.date
                    )}
                  </Typography>

                  {this.state.dialogInfos.doneDate != null && (
                    <Typography
                      variant="h6"
                      style={{
                        paddingTop: "8px",
                        marginTop: "16px",
                        color: "#6A6A6A",
                      }}
                    >
                      Data de conclusão:{" "}
                      {new Intl.DateTimeFormat("pt-BR").format(
                        this.state.dialogInfos.doneDate.seconds * 1000
                      )}
                    </Typography>
                  )}
                  <Typography
                    variant="h6"
                    style={{
                      paddingTop: "8px",
                      marginTop: "16px",
                      color: "#6A6A6A",
                    }}
                  >
                    Responsável: {this.state.dialogInfos.owner}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item style={{ height: "20px" }} />
              <Grid item style={{ height: "20px" }} />
            </Grid>
          </DialogContent>
        </Dialog>
      </Paper>
    );
  }
}

export default connect()(ActionPlans);
