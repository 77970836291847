import React, { Component } from "react";
// material core components
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
// material icons
import CloseIcon from "@material-ui/icons/Close";
// internal imports
import theme from "../../core/theme.json";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Dialog
        maxWidth="sm"
        open={this.props.openForgotPasswordDialog}
        fullWidth
        onClose={() => {
          this.props.handleCloseForgotPasswordDialog();
        }}
      >
        <DialogTitle style={{ backgroundColor: theme.secondary_color }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography
                variant="h6"
                style={{
                  color: "white",
                  position: "absolute",
                  left: "220px",
                }}
              >
                Redefinir senha
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => {
                  this.props.handleCloseForgotPasswordDialog();
                }}
              >
                <CloseIcon style={{ color: "white" }} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {this.props.loadingDialog ? (
            <Grid
              container
              direction="column"
              alignItems="center"
            >
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                <CircularProgress size={70} color={theme.secondary_color} />
              </Grid>
            </Grid>
          ) : (
            <Grid container direction="column" alignItems="center">
              <Grid item style={{ width: "90%", marginTop: 5 }}>
                <Typography variant="body1" component="body1">
                  Um e-mail será enviado para o endereço abaixo com as instruções de redefinição de senha
                </Typography>
              </Grid>
              <Grid item style={{ width: "90%", marginBottom: 5, marginTop: 10 }}>
                <TextField
                  label="E-mail"
                  variant="outlined"
                  value={this.props.forgotPasswordEmail}
                  fullWidth
                  onChange={(event) =>
                    this.props.handleForgotPasswordEmail(event)
                  }
                  error={this.props.errorMail}
                />
              </Grid>
              {this.props.errorMail ? (
                <Grid
                  item
                  style={{ width: "90%", marginBottom: 5, marginTop: 5 }}
                >
                  <Typography
                    variant="body1"
                    component="body1"
                    style={{ color: theme.warning_color }}
                  >
                    {this.props.errorMailMessage}
                  </Typography>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <Button
                variant="outlined"
                fullWidth
                style={{
                  backgroundColor: this.props.sendMailButtonDisabled
                    ? theme.opacity
                    : theme.primary_color,
                  color: theme.secondary_color,
                  borderColor: theme.secondary_color,
                }}
                onClick={() => {
                  this.props.sendResetPasswordEmail();
                }}
                disabled={this.props.sendMailButtonDisabled}
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ForgotPassword;
