import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Switch,
  Box,
  TextField,
  Typography, Zoom, FormControlLabel, Checkbox,
} from '@material-ui/core'
import myApp from '../../../core/infrastructure/firebaseConfig'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import { formatDate, formatHour } from '../../../shared/commands/formatDate'
import { changePage } from '../../../dashboard/presentation/redux/actions'
import { setSolicitationId } from '../../redux/actions'
import theme from '../../../core/theme.json'
import InputFile from '../../../shared/components/InputFile'
import SolicitationTimeline from '../../../shared/components/solicitationTimeline'
import { grey } from '@material-ui/core/colors'
import Title from '../../../shared/components/title'
import { ArrowBackIosTwoTone, SaveTwoTone } from '@material-ui/icons'
import Swal from 'sweetalert2'

const sendSolicitationChannelEmail = myApp.functions().httpsCallable('sendSolicitationChannelEmail')

class SolicitationFormEdit extends Component {
  constructor(props) {
    super(props)

    this.state = {
      database: myApp.firestore(),
      solicitationData: {},
      personType: '',
      solicitationType: '',
      companyId: '',
      name: '',
      lastName: '',
      email: '',
      document: '',
      phone: '',
      additionalInformation: '',
      formInternalDocumentsFiles: null,
      documentProofFile: null,
      responseFile: null,
      formResponseFile: null,
      birthDate: '',
      createDate: '',
      limitDate: '',
      conclusionDate: null,
      conclusionDateFormat: '',
      status: '',
      protocol: '',
      loading: true,
      done: false,
      response: '',
      userInformation: JSON.parse(
        sessionStorage.getItem('@blue-legal/userInformation')
      ),
      companyGroupData: {},
      previousStateDone: false,
      responseFileProgress: 100,
      internalDocumentsFilesProgress: 100,
      loadingSend: false,
      sendEmail: true,
    }
  }

  async componentDidMount() {
    await this.getSolicitationData()
    await this.loadCompanyGroup()
    this.setState({
      loading: false,
    })
    this.handleScrollHistoryToBottom()
  }

  async getSolicitationData() {
    try {
      let solicitationData = await this.state.database
        .collection('solicitations')
        .doc(this.props.solicitationId)
        .get()
      let solicitation = solicitationData.data()

      let birthDate = solicitation?.birthDate ? new Date(solicitation?.birthDate?.seconds * 1000) : null
      let createDate = new Date(solicitation.createDate.seconds * 1000)
      let limitDate = new Date(solicitation.limitDate.seconds * 1000)
      let conclusionDate = solicitation.conclusionDate
        ? new Date(solicitation.conclusionDate.seconds * 1000)
        : null

      let personTypeData = await this.state.database
        .collection('personTypes')
        .doc(solicitation.fk_personType)
        .get()
      let personType = '';
      //let personType = personTypeData.data().type
      if (personTypeData.data() == undefined) {
        let personTypeDataH = await this.state.database
          .collection('holders')
          .doc(solicitation.fk_personType)
          .get()
        personType = personTypeDataH.data().name
      } else {
        personType = personTypeData.data().type
      }

      let solicitationTypeData = await this.state.database
        .collection('solicitationTypes')
        .doc(solicitation.fk_solicitationType)
        .get()
      let solicitationType = solicitationTypeData.data().type

      this.setState({
        solicitationData: solicitation,
        personType: personType,
        solicitationType: solicitationType,
        name: solicitation.name,
        lastName: solicitation.lastName,
        email: solicitation.email,
        document: solicitation.document,
        phone: solicitation.phone,
        additionalInformation: solicitation.additionalInformation,
        documentProofFile: solicitation.documentProofFile,
        internalDocumentsFiles: solicitation.internalDocumentsFiles,
        responseFile: solicitation.responseFile,
        birthDate: solicitation?.birthDate ? formatDate(birthDate) : null,
        createDate: formatDate(createDate) + ' ' + formatHour(createDate),
        limitDate: formatDate(limitDate) + ' ' + formatHour(limitDate),
        status:
          solicitation.status === 'Aberto'
            ? 'Em andamento'
            : solicitation.status,
        protocol: solicitation.protocol,
        done: solicitation.done,
        // response: solicitation.response,
        conclusionDate: conclusionDate,
        conclusionDateFormat:
          conclusionDate !== null
            ? formatDate(conclusionDate) + ' ' + formatHour(conclusionDate)
            : '',
        previousStateDone:
          solicitation.done,
        loadingConclusion: false,
        loadingSend: false,
      })
    } catch (err) {
      console.log(err)
    }
  }

  async loadCompanyGroup() {
    try {
      const companyGroupSolicitationSnapshot = await this.state.database
        .collection('solicitationParameters')
        .where(
          'fk_company_group',
          '==',
          localStorage.getItem('@blue-legal/companyGroup')
        )
        .get()

      const parametersData = companyGroupSolicitationSnapshot.docs[0].data()

      this.setState({
        companyGroupData: parametersData,
      })
    } catch (error) {
      console.error('Error loading company group data: ' + error)
    }
  }

  async handleSolicitationDone(event) {
    this.setState({
      done: !this.state.done,
      loadingConclusion: true,
    })

    if (event.target.checked) {
      let date = new Date(Date.now())
      let conclusionDateFormat = formatDate(date) + ' ' + formatHour(date)

      await this.getSolicitationData()

      this.setState({
        status: 'Concluído',
        conclusionDate: date,
        conclusionDateFormat,
        loadingConclusion: false,
      })

      console.log("data de conclusão: ", this.state.conclusionDate)

      await this.state.database
        .collection('solicitations')
        .doc(this.props.solicitationId)
        .update({
          status: 'Concluído',
          conclusionDate: this.state.conclusionDate,
          done: true,
        })

    } else {
      await this.state.database
        .collection('solicitations')
        .doc(this.props.solicitationId)
        .update({
          status: 'Em andamento',
          conclusionDate: null,
          done: false,
        })

      await this.getSolicitationData()

      this.setState({
        status: 'Em andamento',
        conclusionDate: null,
        conclusionDateFormat: '',
        loadingConclusion: false,
      })
    }
  }

  handleSendEMailCheckbox(event) {
    this.setState({
      sendEmail: !!event.target.checked
    })
  }

  handleResponse(event) {
    this.setState({
      response: event.target.value,
    })
  }

  handleResponseFile(file) {
    this.setState({
      formResponseFile: file
    })
  }

  handleInternalDocumentsFiles(files) {
    this.setState({
      formInternalDocumentsFiles: files
    })
  }

  handleScrollHistoryToBottom = () => {
    const { timeline } = this.refs
    timeline.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
  }

  async saveSolicitation() {
    this.setState({
      loadingSend: true,
    })

    let responseHistory = this.state.solicitationData?.responseHistory ?? []

    try {
      let responseFile = null
      let internalDocumentsFiles = null

      try {
        if (this.state.formResponseFile) {
          responseFile = await this.uploadResponseFile(this.props.solicitationId, this.state.formResponseFile)
        }

        if (this.state.formInternalDocumentsFiles) {
          internalDocumentsFiles = await this.uploadInternalDocumentsFile(
            this.props.solicitationId, this.state.formInternalDocumentsFiles
          )
        }
      } catch (e) {
        Swal.fire({
          icon: 'error',
          title: 'Erro no upload dos arquivos',
          showConfirmButton: false,
          timer: 1500
        })
        console.error(e)
      }

      let response = {
        response: this.state.response,
        responseDate: new Date(Date.now()),
        status: this.state.status,
        conclusionDate: this.state.conclusionDate,
        done: this.state.done,
      }

      const currentUser = JSON.parse(sessionStorage.getItem('@blue-legal/userInformation'))

      responseHistory.push({
        ...response, ...{
          responseFile,
          internalDocumentsFiles,
          userName: currentUser.displayName,
          uid: currentUser.uid,
        }
      })

      await this.state.database
        .collection('solicitations')
        .doc(this.props.solicitationId)
        .update({
          ...response,
          responseHistory
        })

      if (this.state.sendEmail) {
        await this.sendMailToClient(responseFile)
      }

      await this.getSolicitationData()

      this.setState({
        loadingSend: false,
        responseFile: null,
        internalDocumentsFiles: null,
      })

      Swal.fire({
        icon: 'success',
        title: 'Solicitação salva',
        showConfirmButton: false,
        timer: 1500
      })
    } catch (err) {
      console.log(err)
      this.setState({
        loadingSend: false,
      })

      Swal.fire({
        icon: 'error',
        title: 'Erro ao salvar a solicitação',
        showConfirmButton: false,
        timer: 1500
      })
    }
  }

  uploadResponseFile(solicitationId, file) {
    //pegando norme do arquivo
    let fileName = file.name

    //separando nome do arquivo em nome e extensão
    let fileDotsArray = fileName.split('.')

    //.shift() para pegar o primeiro item do array criado pelo split, que é o nome do arquivo
    let firstName = fileDotsArray.shift()

    //.pop() para pegar o ultimo item do array criado pelo split, que é a extensão do arquivo
    let extension = fileDotsArray.pop()

    //pegando a data em mili segundos
    let dateAdded = file.lastModified

    //criando chave de identificação unica para o arquivo, caso o usuario envie dois arquivos com mesmo nome
    let fileNameDb = myApp.database().ref().push().key + '-' + firstName

    //caminho para o Storage
    let filePath =
      'solicitations/' +
      solicitationId +
      '/' +
      fileNameDb

    //criando referencia de arquivo
    let storage = myApp.storage().ref(filePath)

    //enviando arquivo
    let upload = storage.put(file)

    return new Promise((resolve, reject) => {
      this.uploadProgress(upload)
        .then(() => {
          storage
            .getDownloadURL()
            .then(async (downloadUrl) => {
              const responseFile = {
                dateAdded: dateAdded,
                extension: extension,
                fileName: firstName,
                filePathName: filePath,
                fileUrl: downloadUrl,
              }
              myApp.firestore()
                .collection('solicitations')
                .doc(solicitationId)
                .update({
                  responseFile,
                })
                .then(res => resolve(responseFile))
                .catch(reject)
            })
            .catch(reject)
        })
    })
  }

  uploadInternalDocumentsFile(solicitationId, files) {
    this.setState({
      internalDocumentsFilesProgress: 0,
    })
    return new Promise(async (resolve, reject) => {
      try {
        let uploadedFiles = []
        let totalBytesSize = 0
        let bytesTransferred = 0

        files.map(file => totalBytesSize += file.size)

        files.map(async (file) => {

          //pegando norme do arquivo
          let fileName = file.name

          //separando nome do arquivo em nome e extensão
          let fileDotsArray = fileName.split('.')

          //.shift() para pegar o primeiro item do array criado pelo split, que é o nome do arquivo
          let firstName = fileDotsArray.shift()

          //.pop() para pegar o ultimo item do array criado pelo split, que é a extensão do arquivo
          let extension = fileDotsArray.pop()

          //pegando a data em mili segundos
          let dateAdded = file.lastModified

          //criando chave de identificação unica para o arquivo, caso o usuario envie dois arquivos com mesmo nome
          let fileNameDb = myApp.database().ref().push().key + '-' + firstName

          //caminho para o Storage
          let filePath =
            'solicitations/' +
            solicitationId +
            '/' +
            fileNameDb

          //criando referencia de arquivo
          let storage = myApp.storage().ref(filePath)

          //enviando arquivo
          storage.put(file).on(
            'state_changed',
            (snapShot) => {
              bytesTransferred += snapShot.bytesTransferred
              this.setState({
                internalDocumentsFilesProgress:
                  (bytesTransferred / totalBytesSize) * 100,
              })
            },
            (error) => {
              console.error(error)
            },
            async () => {
              const downloadUrl = await storage.getDownloadURL()

              this.setState({
                internalDocumentsFilesProgress: 100,
              })

              uploadedFiles.push({
                dateAdded: dateAdded,
                extension: extension,
                fileName: firstName,
                filePathName: filePath,
                fileUrl: downloadUrl,
              })

              if (uploadedFiles.length === files.length) {
                await myApp.firestore()
                  .collection('solicitations')
                  .doc(solicitationId)
                  .update({
                    internalDocumentsFiles: uploadedFiles,
                  })
                resolve(uploadedFiles)
              }
            }
          )
        })
      } catch (e) {
        reject(e)
      }
    })
  }

  uploadProgress(upload) {
    return new Promise((resolve, reject) => {
      upload.on(
        'state_changed',
        (snapShot) => {
          this.setState({
            responseFileProgress:
              (snapShot.bytesTransferred / snapShot.totalBytes) * 100,
          })
        },
        (error) => {
          reject(error)
        },
        () => {
          resolve()
        }
      )
    })
  }

  async sendMailToClient(responseFile) {
    try {
      const companyDoc = await this.state.database
        .collection('companies')
        .doc(localStorage.getItem('currentCompany'))
        .get()

      let requestObject = {
        from: `${this.state.companyGroupData.from_name} <${this.state.companyGroupData.from_email}>`,
        email: this.state.email,
        name: this.state.name + ' ' + this.state.lastName,
        subject: `Resposta solicitação nº ${this.state.protocol}`,
        message:
          `Olá, <b>${this.state.name}</b>!<br><br>` +
          `Sua solicitação foi respondida e concluída. Segue resposta:<br>` +
          `<p>${this.state.response}</p><br>` +
          (responseFile ? `<p>Anexo: <a href="${responseFile.fileUrl}">visualizar</a></p><br>` : '') +
          `Segue um resumo de sua solicitação:<br><br>` +
          `- Você é: <b>${this.state.personType}</b><br>` +
          `- Tipo de solicitação: <b>${this.state.solicitationType}</b><br>` +
          `- Empresa: <b>${companyDoc.data().nome}</b><br>` +
          `- Nome e sobrenome: <b>${this.state.name} ${this.state.lastName}</b><br>` +
          `- Documento: <b>${this.state.document}</b><br>` +
          `- Data de nascimento: <b>${this.state.birthDate ?? "Não informado"}</b><br>` +
          `- Telefone: <b>${this.state.phone}</b><br>` +
          `- Informações Complementares: <b>${this.state.additionalInformation}</b><br>` +
          `- Número de protocolo: <b>${this.state.protocol}</b><br>` +
          `- Data de criação: <b>${this.state.createDate}</b><br>` +
          `- Data limite de resposta: <b>${this.state.limitDate}</b><br><br>` +
          `<b>Não responda a esta mensagem. E-mails enviados para este endereço não podem ser respondidos.</b>`,
        company: this.state.companyGroupData.fk_company_group,
      }

      await sendSolicitationChannelEmail(requestObject)
    } catch (error) {
      console.log(error)
    }
  }

  handleGoBack() {
    this.props.setSolicitationId('')
    this.props.changePage(16)
  }

  renderLoading() {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{ paddingTop: '50px' }}
      >
        <CircularProgress size={60} color={theme.secondary_color} />
      </Grid>
    )
  }

  renderForm() {
    return (
      <form onSubmit={event => {
        event.preventDefault()
        this.saveSolicitation()
        return false
      }}>
        <Grid item style={{ marginBottom: '20px' }}>
          <Grid
            container
            direction="column"
            alignItems="stretch"
            justifyContent="center"
          >
            <Grid item>
              <Typography
                variant="h6"
                component="h6"
                style={{ width: '100%' }}
              >
                Documentos internos
              </Typography>
              <InputFile maxFiles={5} onAcceptFiles={(files) => {
                if (files.length) this.handleInternalDocumentsFiles(files)
              }} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item style={{ marginBottom: '20px' }}>
          <Grid
            container
            direction="column"
            alignItems="stretch"
            justifyContent="center"
          >
            <Grid item>
              <Typography
                variant="h6"
                component="h6"
                style={{ width: '100%' }}
              >
                Resposta
              </Typography>
              <TextField
                style={{ padding: '0 10px' }}
                multiline
                rows={4}
                fullWidth
                required
                variant="outlined"
                color="primary"
                InputLabelProps={{ shrink: false }}
                value={this.state.response}
                onChange={(event) => this.handleResponse(event)}
                disabled={!this.state.userInformation.permissions.update}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid
            container
            direction="column"
            alignItems="stretch"
            justifyContent="center"
          >
            <Grid item>
              <Typography
                variant="h6"
                component="h6"
                style={{ width: '100%' }}
              >
                Documento de resposta
              </Typography>
              <InputFile onAcceptFiles={(files) => {
                if (files.length) this.handleResponseFile(files[0])
              }} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.sendEmail}
                onChange={event => this.handleSendEMailCheckbox(event)}
                name="send-email"
              />
            }
            label="Notificar solicitante por e-mail"
          />
        </Grid>

        <Grid item style={{ marginTop: '20px' }}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Button
                variant="contained"
                style={{
                  background: !this.state.userInformation.permissions.update
                    ? theme.opacity
                    : theme.primary_color,
                  color: theme.secondary_color,
                  fontSize: '20px',
                }}
                type="submit"
                disabled={!this.state.userInformation.permissions.update}
                startIcon={!this.state.loadingSend && <SaveTwoTone />}
              >
                {this.state.loadingSend ? <CircularProgress /> : 'Salvar'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    )
  }

  render() {
    return (
      <>
        <Title>
          <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
            <Grid item>
              <Button
                variant="text"
                style={{
                  fontSize: '15px',
                }}
                onClick={() => {
                  this.handleGoBack()
                }}
              >
                <ArrowBackIosTwoTone />
              </Button>
            </Grid>
            <Grid item>Editar Solicitação</Grid>
          </Grid>
        </Title>
        {this.state.loading ? (
          this.renderLoading()
        ) : (
          <>
            <Card style={{ marginTop: 10 }}>
              <CardContent>
                <Grid
                  container
                  direction="column"
                  alignItems="stretch"
                  justifyContent="center"

                >
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      spacing={3}
                    >
                      <Grid item>
                        <Typography
                          variant="h4"
                          component="h4"
                          style={{ width: '100%', marginBottom: '20px' }}
                        >
                          Solicitação n° {this.state.protocol}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={5}
                      >
                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                          >
                            <Grid item>
                              <Typography variant="h6" component="h6">
                                Nome
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body1" component="p" color="textSecondary">
                                {this.state.name}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                          >
                            <Grid item>
                              <Typography variant="h6" component="h6">
                                Sobrenome
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body1" component="p" color="textSecondary">
                                {this.state.lastName}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      spacing={3}
                    >
                      <Grid item>
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography variant="h6" component="h6">
                              Tipo de solicitante
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="body1" component="p" color="textSecondary">
                              {this.state.personType}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item>
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography variant="h6" component="h6">
                              Tipo de solicitação
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="body1" component="p" color="textSecondary">
                              {this.state.solicitationType}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item>
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography variant="h6" component="h6">
                              Data de criação
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="body1" component="p" color="textSecondary">
                              {this.state.createDate}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item>
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography variant="h6" component="h6">
                              Data prevista
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="body1" component="p" color="textSecondary">
                              {this.state.limitDate}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={3}
                      >
                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                          >
                            <Grid item>
                              <Typography variant="h6" component="h6">
                                E-mail
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body1" component="p" color="textSecondary">
                                {this.state.email}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                          >
                            <Grid item>
                              <Typography variant="h6" component="h6">
                                Documento
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body1" component="p" color="textSecondary">
                                {this.state.document}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                          >
                            <Grid item>
                              <Typography variant="h6" component="h6">
                                Telefone
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body1" component="p" color="textSecondary">
                                {this.state.phone}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                          >
                            <Grid item>
                              <Typography variant="h6" component="h6">
                                Informações Complementares
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body1" component="p" color="textSecondary">
                                {this.state.additionalInformation}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        {
                          (this.state.documentProofFile != null) &&
                          <Grid item>
                            <Grid
                              container
                              direction="column"
                              alignItems="center"
                            >
                              <Grid item>
                                <Typography variant="h6" component="h6">
                                  Comprovante
                                </Typography>
                              </Grid>
                              <Grid item>
                                <a href={this.state.documentProofFile.fileUrl} target="_blank" rel="noreferrer">
                                  <Typography variant="body1" component="p" color="textSecondary">
                                    Anexo
                                  </Typography>
                                </a>
                              </Grid>
                            </Grid>
                          </Grid>
                        }

                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                          >
                            <Grid item>
                              <Typography variant="h6" component="h6">
                                Data de nascimento
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body1" component="p" color="textSecondary">
                                {this.state.birthDate ?? "Não informado"}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={3}
                      >
                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                          >
                            <Grid item>
                              <Typography variant="h6" component="h6">
                                Status
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body1" component="p" color="textSecondary">
                                {this.state.status}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        {
                          this.state.conclusionDateFormat &&
                          <Grid item>
                            <Grid
                              container
                              direction="column"
                              alignItems="center"
                            >
                              <Grid item>
                                <Typography variant="h6" component="h6">
                                  Data de conclusão
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="body1" component="p" color="textSecondary">
                                  {this.state.conclusionDateFormat}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        }

                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                          >
                            {
                              !this.state.loadingConclusion &&
                              <Grid item>
                                <Typography variant="h6" component="h6">
                                  Concluído
                                </Typography>
                              </Grid>
                            }

                            <Grid item>
                              {
                                this.state.loadingConclusion ? (
                                  <CircularProgress />
                                ) : (
                                  <Switch
                                    size="small"
                                    checked={this.state.done}
                                    onChange={(event) => {
                                      this.handleSolicitationDone(event)
                                    }}
                                    color="primary"
                                    disabled={!this.state.userInformation.permissions.update}
                                  />
                                )
                              }
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item style={{ marginBottom: '20px' }}>
                    <Grid
                      container
                      direction="column"
                      alignItems="stretch"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Typography
                          variant="h6"
                          component="h6"
                        >
                          Histórico
                        </Typography>
                        <Grid item style={{ padding: '0 10px' }}>
                          <Container
                            maxWidth="xl"
                            style={{ backgroundColor: grey[100], overflow: 'scroll', maxHeight: '400px' }}
                          >
                            <div ref="timeline">
                              <SolicitationTimeline data={this.state.solicitationData} />
                            </div>
                          </Container>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {
                    !this.state.done &&
                    <Zoom in={!this.state.done} style={{ transitionDelay: !this.state.done ? '500ms' : '0ms' }}>
                      {this.renderForm()}
                    </Zoom>
                  }
                </Grid>
              </CardContent>
            </Card>
            <br />
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  solicitationId: state.solicitationReducer.solicitationId,
})

const mapDispatchToProps = (dispatch) => ({
  changePage: (page) => dispatch(changePage(page)),
  setSolicitationId: (solicitationId) =>
    dispatch(setSolicitationId(solicitationId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SolicitationFormEdit)
