import { CHANGE_PAGE, PARAMETERIZATION_TYPE, COMPANY_NAME, ID_MODULE_VIDE, ID_QUIZ_MODULE } from './types';

export const changePage = data => ({
  type: CHANGE_PAGE,
  data
});

export const parameterizationType = data => ({
  type: PARAMETERIZATION_TYPE,
  data
});

export const companyName = data => ({
  type: COMPANY_NAME,
  data
});

export const idModuleName = data => ({
  type: ID_MODULE_VIDE,
  data
});

export const idQuizModule = data => ({
  type: ID_QUIZ_MODULE,
  data
});