import React, { Component } from 'react';
import myApp from '../../../../core/infrastructure/firebaseConfig';
import "firebase/compat/firestore";

import { Paper, Grid, Typography, Button, IconButton, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';

import EditIcon from '@material-ui/icons/Edit';

import NewAreaActivy from './newAreaActivy';
import EditArea from './editArea';

import theme from '../../../../core/theme.json'

import { changePage } from '../../../presentation/redux/actions';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

class AreaTitle extends Component {
  constructor(props) {
    super(props);

    this.db = myApp.firestore();

    this.state = {
      name: "",
      icon: "",

      dialog: false,
      editDialog: false,
      userInformation: JSON.parse(sessionStorage.getItem('@blue-legal/userInformation'))
    }
  }

  handleDialog() {
    this.setState({
      dialog: !this.state.dialog,
    })
  }

  handleEditDialog() {
    this.setState({
      editDialog: !this.state.editDialog,
    })
  }

  componentDidMount() {
    this.getAreaName();
    console.log('Carregou')
  }

  async getAreaName() {
    let database = myApp.firestore();
    try {
      let data = await database.collection('areas').doc(this.props.area).get();
      let area = data.data();
      this.setState({
        name: area.name,
        icon: area.icon
      })
    }
    catch (error) {
      console.error(error);
    }
  }

  setAreaName(name) {
    this.setState({
      name: name
    })
  }

  render() {
    return (
      <div>
        <Paper className='paper'>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Typography variant="h4" component="h4" style={{ fontWeight: "500" }}>
              <Tooltip title="Voltar">
                <Button
                  variant="contained"
                  style={{ background: 'none', marginRigth: '10px' }}
                  onClick={() => { this.props.changePage(0) }}
                >
                  <ArrowBackIcon />
                </Button>
              </Tooltip>
                {this.state.name}
              </Typography>
            </Grid>
            <div style={{ flexGrow: 1 }} />
            <Grid item>
            <Tooltip title="Editar">
            <IconButton onClick={() => { this.handleEditDialog() }}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                style={{ background: theme.primary_color, color: theme.secondary_color }}
                onClick={() => { this.handleDialog() }}
                disabled={!this.state.userInformation.permissions.insert}
              >
                Adicionar Atividade
              </Button>
            </Grid>
          </Grid>
        </Paper>

        <NewAreaActivy dialog={this.state.dialog} />
        <EditArea editDialog={this.state.editDialog} setAreaName={(name) => { this.setAreaName(name) }} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    area: state.homeReducer.area,
  };
}

export default connect(mapStateToProps, { changePage })(AreaTitle);