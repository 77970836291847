//definindo states iniciais caso não receba
const initialState = {
 denunceObj:{}
};

//definindo os tipos de cada função

const SET_DENUNCE_OBJ = "SET_DENUNCE_OBJ";


//função recebe e altera o nome
export const setDenunceObj = (data) => ({
  type: SET_DENUNCE_OBJ,
  data,
});


//criando o reducer para o combine
const ViewsPdfDenunceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DENUNCE_OBJ:
      return { ...state, denunceObj: action.data };
   
  }
  return state;
};




export default ViewsPdfDenunceReducer;
