import { useEffect, useState } from "react";
import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import {
    Grid,
    Paper,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress,
} from "@material-ui/core";

import XLSX from "xlsx-js-style";

import theme from "../../../../core/theme.json";

import { generateData } from "../functions/excelData";
import { generateStyles } from "../functions/excelStyles";
import { ROPA_MERGES } from "../utils/constants";

import ROPADialog from "./ROPAanswers.jsx";





export const NewReportRopa = () => {
    const database = myApp.firestore();

    const [loading, setLoading] = useState(false)
    const [companyId, setCompanyId] = useState(localStorage.getItem("currentCompany"))
    const [company, setCompany] = useState(null)
    const [processList, setProcessList] = useState([])
    const [questions, setQuestions] = useState([])
    const [processIndex, setProcessIndex] = useState(-1)
    const [openDialog, setOpenDialog] = useState(false)

    useEffect(()=>{
      loadCompany()
      loadQuestions()
      getProcessesFromCompany()
    },[])

    const loadCompany = async() =>{
        setLoading(true)

		let companyAux = {};
		try {
			let companySnapshot = await database
				.collection("companies")
				.doc(companyId)
				.get();
                companyAux = companySnapshot.data();
		} catch (error) {
			console.error("Loading company: ", error);
		}
        setCompany(companyAux)
        setLoading(false)
	}

	const loadQuestions = async() => {
        setLoading(true)

		let questions = [];

		try {
			let questionsSnapshot = await database
				.collection("questions")
				.where("ROPAreport", "==", true)
				.get();

			for (const doc of questionsSnapshot.docs) {
				questions.push({ ...doc.data() });
			}

			questions.sort((a, b) => {
				if (a.questionOrder > b.questionOrder) {
					return 1;
				}

				if (a.questionOrder < b.questionOrder) {
					return -1;
				}

				return 0;
			});
		} catch (error) {
			console.error("Loading questions: ", error);
		}

        setQuestions(questions)
		  setLoading(false)
	}

	const getProcessesFromCompany = async() => {
        setLoading(true)

		let processList = [];

		try {
			let areasSnapshot = await database
				.collection("areas")
				.where("fk_companies", "==", companyId)
				.where("active", "==", true)
				.get();

			for (const areaDoc of areasSnapshot.docs) {
				let processesSnapshot = await database
					.collection("processes")
					.where("fk_areas", "==", areaDoc.id)
					.get();

				for (const processDoc of processesSnapshot.docs) {
					let process = processDoc.data();
					process.id = processDoc.id;
					process.areaName = areaDoc.data().name;

					if (process.legalBases.length > 0) {
						process.legalBasisName = process.legalBases[0];
					} else {
						process.legalBasisName = "";
					}

					processList.push(process);
				}
			}
		} catch (error) {
			console.error("Loading processes: ", error);
		}
        let aux =  processList.sort((a, b) => a.areaName.toLowerCase() > b.areaName.toLowerCase() ? 1 : -1)
        setProcessList(aux)
        setLoading(false)
    }

    const generateExcel = async() => {
		try {
			let data = await generateData(processList);

			let ws = XLSX.utils.aoa_to_sheet(data);
			ws["!merges"] = ROPA_MERGES;
			ws = await generateStyles(ws, processList.length);

			const wb = XLSX.utils.book_new();

			XLSX.utils.book_append_sheet(wb, ws, "ROPA");

			XLSX.writeFile(wb, "ROPA " + company.nome + ".xlsx");
		} catch (error) {
			console.error("Generating excel: ", error);
		}
	}

	const handleAnswer = (event, index)  =>{
		let processList = processList;
		processList[processIndex].ROPAanswers[index] = event.target.value;

  setProcessList(processList)

	}

	const handleDialog =(index) => {
        setOpenDialog(!openDialog)
        setProcessIndex(index)
	
	}


	const saveAnswers = async() => {
		try {
			await database.collection("processes")
				.doc(processList[processIndex].id)
				.update({
					ROPAanswers: processList[processIndex].ROPAanswers,
				});
		} catch (error) {
			console.error("Saving answers: ", error);
		}

		handleDialog(-1);
	}

    return (
        <>
            {loading ? (
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    style={{ width: "100%", marginTop: 10 }}
                >
                    <Grid item style={{ paddingTop: "10vh" }}>
                        <CircularProgress size={70} color={theme.secondary_color} />
                    </Grid>
                </Grid>
            ) : (
                <>
                    <Grid
                        component={Paper}

                        direction="column"
                        alignItems="flex-start"
                        style={{ width: "100%", height: "78vh", marginTop: 10 }}
                    >
                        <Grid item component={Paper} style={{ width: "100%", backgroundColor: theme.secondary_color }}>
                            <Button
                                onClick={() => generateExcel()}
                                style={{
                                    background: theme.primary_color,
                                    color: theme.secondary_color,
                                    margin: 10,
                                }}
                            >
                                Download
                            </Button>
                        </Grid>
                        <Grid item style={{ width: "100%" }}>
                            <TableContainer style={{ maxHeight: "70vh" }}>
                                <Table stickyHeader>
                                    <colgroup>
                                        <col style={{ width: "15%" }} />
                                        <col style={{ width: "15%" }} />
                                        <col style={{ width: "10%" }} />
                                        <col style={{ width: "10%" }} />
                                        <col style={{ width: "10%" }} />
                                        <col style={{ width: "10%" }} />
                                        <col style={{ width: "20%" }} />
                                        <col style={{ width: "10%" }} />
                                    </colgroup>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ÁREA DE NEGÓCIO</TableCell>
                                            <TableCell>PROCESSO</TableCell>
                                            <TableCell>RESPONSÁVEL</TableCell>
                                            <TableCell>CRITICIDADE</TableCell>
                                            <TableCell>FINALIDADE</TableCell>
                                            <TableCell>CONTROLADOR OU OPERADOR</TableCell>
                                            <TableCell>BASE LEGAL</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {processList?.map((process, index) => {
                                            return (
                                                <TableRow style={index % 2 ? { background: "white" } : { background: "#e3e3e3" }}>
                                                    <TableCell>{process.areaName}</TableCell>
                                                    <TableCell>{process.name}</TableCell>
                                                    <TableCell>{process.owner}</TableCell>
                                                    <TableCell>{process.criticality}</TableCell>
                                                    <TableCell>{process.goal}</TableCell>
                                                    <TableCell>{process.operator}</TableCell>
                                                    <TableCell>{process.legalBasisName}</TableCell>
                                                    <TableCell align="center">
                                                        <Button
                                                            style={{
                                                                background: theme.primary_color,
                                                                color: theme.secondary_color,
                                                            }}
                                                        onClick={() =>handleDialog(index)}
                                                        >
                                                            VISUALIZAR RESPOSTAS
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>

                    {processList.length > 0 ?
                        <ROPADialog
                            handleDialog={handleDialog}
                            handleAnswer={handleAnswer}
                            saveAnswers={() => saveAnswers()}
                            answers={processIndex > -1 ? processList[processIndex].ROPAanswers : []}
                            questions={questions}
                            openDialog={openDialog}
                        />
                        :
                        <></>
                    }
                </>
            )}
        </>
    );
}