import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";

const updateLeadTo = async (point, idOutput) => {
  const database = myApp.firestore();
  const lastQuestionId = point.cardProps.idQuestion;

  try {
    let questionDoc = await database.collection("questions").doc(lastQuestionId).get();
    let answers = questionDoc.data().answers;

    for (let answer of answers) {
      if (answer.text === point.cardProps.title) {
        answer.leadTo = idOutput;
      }
    }

    try {
      await database.collection("questions").doc(lastQuestionId).update({
        answers: answers
      });
    } catch(error) {
      console.error("Error update answer in updateLeadTo", error);
    }
  } catch (error) {
    console.error("Error getting question from database in updateLeadTo", error);
  }
}

export default updateLeadTo;