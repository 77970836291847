import React, { Component } from "react";

import firebase from "../../../../core/infrastructure/firebaseConfig";

import {
  Grid,
  Typography,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Button,
  Paper,
  Tab,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";

import { TabContext, TabList, TabPanel } from "@material-ui/lab";

import ReactPlayer from "react-player";

import theme from "../../../../core/theme.json";
import VisibilityIcon from "@material-ui/icons/Visibility";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";

import youtubeFalid from '../../../../core/assets/youtubeFalid.png'

class Videos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      moduleList: [],
      Questions :[],
      loading: true,
      activeVideoIndex: 0,
      companyId: localStorage.getItem("currentCompany"),
      tabValue: "",
    };

    this.handleVideoViewIndex = this.handleVideoViewIndex.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  async componentDidMount() {
    await this.loadVideos();
     await this.loadQuestions();
    this.setState({
      loading: false,
    });
  }

  async loadVideos() {
    const database = firebase.firestore();
    const moduleList = [];

    try {
      //quizModuleVideo
      // const videosSnapshot = await database
      //   .collection("videos")
      //   .where("fk_company", "==", this.state.companyId)
      //   .get();
      const videosSnapshot = await database
        .collection("moduleName")
        .where("fk_company", "==", this.state.companyId)
        .get();

      if (!videosSnapshot.empty) {
        for (const doc of videosSnapshot.docs) {
          moduleList.push({
            ...doc.data(),
            id: doc.id,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }

    this.setState({
      moduleList,
      tabValue: moduleList.length > 0 ? moduleList[0].id : "",
    });


  }

  async loadQuestions(){
    const database = firebase.firestore();
    const aux = []
    try{
   let gets = await database.collection("quizModuleVideo")
   .where("fk_company", "==", this.state.companyId)
   .get()
       aux.push(gets.docs.map(item => item.data()))
   this.setState({
    Questions : aux
   })
    }catch(err){
      console.log("nao deu pra pegar as questions" , err)
    }
  
    console.log('o que vem do load',this.state.Questions.map(item => item.map(item => item ))[0])
  }

  handleVideoViewIndex(index) {
    this.setState({
      activeVideoIndex: index,
    });
  }

  handleTabChange(event, newValue) {
    this.setState({
      tabValue: newValue,
      activeVideoIndex: 0,
    });
  }

  async sendQuestionForMailModal() {
    let emails = this.state.emailInputModal;

    let id = this.state.processQuizId;
    let linkDeResposta =
      "https://" +
      window.location.host +
      "/quizModuleVideo/XhZmYFKg0cIQlfgAlIu3";
    const sendEmail = await firebase
      .firestore()
      .functions()
      .httpsCallable("sendEmail");

    console.log("id questionário", id);
    console.log("id processo", this.props.processId);
    console.log("Nome da area", this.props.processArea);
    console.log("Nome do processo", this.state.processName);

    try {
      let requestObject = {
        from: `${process.env.REACT_APP_FROM_NAME} <${process.env.REACT_APP_FROM_EMAIL}>`,
        email: emails,
        subject: "Questionário módulo de vídeo",
        message: `Teste... ${linkDeResposta}`,
      };

      await sendEmail(requestObject);
    } catch (error) {
      console.log(error);
    }
    this.setState({ emailInputModal: "", dialogViewQuizz: false });
  }

  HandleQuestVideo(video,modulo){
    let [fist] = this.state.Questions.map(item => item.map(item => item))
 console.log(video.videoId, fist.map(item => item.videoId))

 if( fist.find(item => item.videoId  == video.videoId )){
  return(
    <Tooltip title="Responder teste">
    <Button
      style={{
        background: theme.primary_color,
        color: theme.secondary_color,
      }}
      onClick={() =>
        window.open(
          `https://${window.location.host}/#/video/Quest/${modulo.id}/${video.videoId}/${this.state.companyId}`
        )
      }
    >
      <QuestionAnswerIcon />
    </Button>
  </Tooltip> 
   )
 }else {
     return(
      <Tooltip title="Sem teste associado">
      <Button
        style={{
          background: theme.tertiary_color,
          color: theme.secondary_color,
        }}
      >
        <QuestionAnswerIcon />
      </Button>
    </Tooltip> 
     )
 }

  for ( let item of fist){
        console.log(item.videoId)
  
  }

  
    
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <Grid
            container
            direction="column"
            alignItems="center"
            style={{ width: "100%", height: "90vh", marginTop: 10 }}
          >
            <Grid item style={{ paddingTop: "15vh" }}>
              <CircularProgress size={70} color={theme.secondary_color} />
            </Grid>
          </Grid>
        ) : (
          <>
            {this.state.moduleList.length > 0 ? (
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="flex-start"
                style={{ width: "100%", height: "78vh", marginTop: 10 }}
              >
                <Grid item style={{ width: "40%", height: "100%" }}>
                  <TabContext value={this.state.tabValue}>
                    <TabList
                      onChange={this.handleTabChange}
                      aria-label="Módulos"
                      indicatorColor="primary"
                    >
                      {this.state.moduleList.map((modulo) => (
                        <Tab label={modulo.name} value={modulo.id} wrapped />
                      ))}
                    </TabList>
                    {this.state.moduleList.map((modulo) => {
                      // console.log(modulo)
                      return(
                        <TabPanel value={modulo.id} style={{ padding: 0 }}>
                        <TableContainer
                          component={Paper}
                          style={{ maxHeight: "70vh" }}
                        >
                          <Table stickyHeader>
                            <TableHead>
                              <TableRow>
                                <TableCell>ORDEM</TableCell>
                                <TableCell>NOME</TableCell>
                                <TableCell />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {modulo.videos &&
                                modulo.videos.map((video, index) => {
                                  return (
                                    <TableRow key={index}>
                                      <TableCell>{video.order}</TableCell>
                                      <TableCell>{video.name}</TableCell>
                                      <TableCell>
                                        <Tooltip title="Visualizar">
                                          <Button
                                            style={{
                                              background: theme.primary_color,
                                              color: theme.secondary_color,
                                            }}
                                            onClick={() =>
                                              this.handleVideoViewIndex(index)
                                            }
                                          >
                                            <VisibilityIcon />
                                          </Button>
                                        </Tooltip>
                                      </TableCell>
                                      <TableCell>
                                        {this.HandleQuestVideo(video, modulo)}
                                    
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TabPanel>
                      )
                    })}
                  </TabContext>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  style={{ width: "55%", height: "100%" }}
                >
                  <Grid
                    component={Paper}
                    item
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ width: "100%", height: "60%", marginTop: "10vh" }}
                  >
                    {this.state.moduleList.find(
                      (modulo) => modulo.id === this.state.tabValue
                    ).videos && (
                      <Grid item container justifyContent="center" alignItems="center" direction="column" style={{ width: "95%", height: "90%" }}>

                        {this.state.moduleList.find(
                              (modulo) => modulo.id === this.state.tabValue
                            ).videos[this.state.activeVideoIndex]?.url ?<ReactPlayer
                            url={
                              this.state.moduleList.find(
                                (modulo) => modulo.id === this.state.tabValue
                              ).videos[this.state.activeVideoIndex]?.url
                            }
                            width="100%"
                            height="100%"
                            controls
                          /> : <>
                          <img src={youtubeFalid} width={'50%'} />
                          <Typography>sem video cadastrado</Typography>
                          </>}
                        
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                direction="column"
                alignItems="center"
                style={{ width: "100%", height: "90vh", marginTop: 10 }}
              >
                <Grid item style={{ paddingTop: "15vh" }}>
                  <Typography variant="h6" component="h6">
                    Não há vídeos cadastrados para essa empresa
                  </Typography>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </div>
    );
  }
}

export default Videos;
