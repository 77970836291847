//import react
import React, { useState, useEffect } from 'react';

//inport material 
import {
  Grid,
  Card,
  Typography,
  IconButton,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  CircularProgress,
  TableHead,
  TableRow,

} from "@material-ui/core";
import ArrowBackIosTwoToneIcon from "@material-ui/icons/ArrowBackIosTwoTone";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

//import redux
import { useDispatch } from "react-redux";
import { changePage } from "../redux/actions"

//import firebase
import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";

//import do tema
import theme from "../../../../core/theme.json";
import Swal from 'sweetalert2';


const AssetManagement = () => {
  //states  
  const [company, setCompany] = useState(localStorage.getItem('currentCompany'));
  const [assets, setAssets] = useState({
    name: '',
    type: '',
    place: '',
    country: '',
    cep: '',
    street: '',
    city: '',
    neighborhood: '',
    state: '',
    number: '',
    state: '',
    complement: '',
    lgpd: '',
    observation: '',
    nameOfResponsible: '',
    numberOfResponsible: '',
    emailOfResponsible: '',
    provider: '',


    in_company: localStorage.getItem('currentCompany')
  });
  const [assetsEdit, setAssetsEdit] = useState({
    id: '',
    name: '',
    type: '',
    place: '',
    city: '',
    country: '',
    cep: '',
    street: '',
    neighborhood: '',
    state: '',
    number: '',
    state: '',
    complement: '',
    lgpd: '',
    observation: '',
    nameOfResponsible: '',
    numberOfResponsible: '',
    emailOfResponsible: '',
    provider: '',
  });
  const [dialogs, setDialogs] = useState({
    addAssets: false,
    assetSegisteredSuccessfully: false,
    addAssetsEdit: false,
    assetEditSuccessfully: false
  });

  /*const [countrys, setCountrys] = useState([
    { title: 'Afeganistão'},
    { title: 'África do Sul'},
    { title: 'Albânia'},
    { title: 'Alemanha'},
    { title: 'Andorra'},
    { title: 'Angola'},
    { title: 'Antiga e Barbuda'},
    { title: 'Arábia Saudita'},
    { title: 'Argélia'},
    { title: 'Argentina'},
    { title: 'Arménia'},
    { title: 'Austrália'},
    { title: 'Áustria'},
    { title: 'Azerbaijão'},
    { title: 'Bahamas'},
    { title: 'Bangladexe'},
    { title: 'Barbados'},
    { title: 'Barém'},
    { title: 'Bélgica'},
    { title: 'Belize'},
    { title: 'Benim'},
    { title: 'Bielorrússia'},
    { title: 'Bolívia'},
    { title: 'Bósnia e Herzegovina'},
    { title: 'Botsuana'},
    { title: 'Brasil'},
    { title: 'Brunei'},
    { title: 'Bulgária'},
    { title: 'Burquina Faso'},
    { title: 'Burúndi'},
    { title: 'Butão'},
    { title: 'Cabo Verde'},
    { title: 'Camarões'},
    { title: 'Camboja'},
    { title: 'Canadá'},
    { title: 'Catar'},
    { title: 'Cazaquistão'},
    { title: 'Chade'},
    { title: 'Chile'},
    { title: 'China'},
    {title: 'Chipre'},
    {title: 'Colômbia'},
    {title: 'Comores'},
    {title: 'Congo-Brazzaville'},
    {title: 'Coreia do Norte'},
    {title: 'Coreia do Sul'},
    {title: 'Cosovo'},
    {title: 'Costa do Marfim'},
    {title: 'Costa Rica'},
    {title: 'Croácia'},
    {title: 'Cuaite'},
    {title: 'Cuba'},
    {title: 'Dinamarca'},
    {title: 'Dominica'},
    {title: 'Egito'},
    {title: 'Emirados Árabes Unidos'},
    {title: 'Equador'},
    {title: 'Eritreia'},
    {title: 'Eslováquia'},
    {title: 'Eslovénia'},
    {title: 'Espanha'},
    {title: 'Essuatíni'},
    {title: 'Estado da Palestina'},
    {title: 'Estados Unidos'},
    {title: 'Estónia'},
    {title: 'Etiópia'},
    {title: 'Fiji'},
    {title: 'Filipinas'},
    {title: 'Finlândia'},
    {title: 'França'},
    {title: 'Gabão'},
    {title: 'Gâmbia'},
    {title: 'Gana'},
    {title: 'Geórgia'},
    {title: 'Granada'},
    {title: 'Grécia'},
    {title: 'Guatemala'},
    {title: 'Guiana'},
    {title: 'Guiné'},
    {title: 'Guiné Equatorial'},
    {title: 'Guiné-Bissau'},
    {title: 'Haiti'},
    {title: 'Honduras'},
    {title: 'Hungria'},
    {title: 'Iémen'},
    {title: 'Ilhas Marechal'},
    {title: 'Índia'},
    {title: 'Indonésia'},
    {title: 'Irão'},
    {title: 'Iraque'},
    {title: 'Irlanda'},
    {title: 'Irlanda'},
    {title: 'Israel'},
    {title: 'Itália'},
    {title: 'Jamaica'},
    {title: 'Japão'},
    {title: 'Jibuti'},
    {title: 'Jordânia'},
    {title: 'Laus'},
    {title: 'Lesoto'},
    {title: 'Letónia'},
    {title: 'Líbano'},
    {title: 'Libéria'},
    {title: 'Líbia'},
    {title: 'Listenstaine'},
    {title: 'Lituânia'},
    {title: 'Luxemburgo'},
    {title: 'Macedónia do Norte'},
    {title: 'Madagáscar'},
    {title: 'Malásia'},
    {title: 'Maláui'},
    {title: 'Maldivas'},
    {title: 'Mali'},
    {title: 'Malta'},
    {title: 'Marrocos'},
    {title: 'Maurícia'},
    {title: 'Mauritânia'},
    {title: 'México'},
    {title: 'Mianmar'},
    {title: 'Micronésia'},
    {title: 'Moçambique'},
    {title: 'Moldávia'},
    {title: 'Mónaco'},
    {title: 'Mongólia'},
    {title: 'Montenegro'},
    {title: 'Namíbia'},
    {title: 'Nauru'},
    {title: 'Nepal'},
    {title: 'Nicarágua'},
    {title: 'Níger'},
    {title: 'Nigéria'},
    {title: 'Noruega'},
    {title: 'Nova Zelândia'},
    {title: 'Omã'},
    {title: 'Países Baixos'},
    {title: 'Palau'},
    {title: 'Panamá'},
    {title: 'Papua Nova Guiné'},
    {title: 'Paquistão'},
    {title: 'Paraguai'},
    {title: 'Peru'},
    {title: 'Polónia'},
    {title: 'Portugal'},
    {title: 'Quénia'},
    {title: 'Quirguistão'},
    {title: 'Quiribáti'},
    {title: 'Reino Unido'},
    {title: 'República Centro-Africana'},
    {title: 'República Checa'},
    {title: 'República Democrática do Congo'},
    {title: 'República Dominicana'},
    {title: 'Roménia'},
    {title: 'Ruanda'},
    {title: 'Rússia'},
    {title: 'Salomão'},
    {title: 'Salvador'},
    {title: 'Samoa'},
    {title: 'Santa Lúcia'},
    {title: 'São Cristóvão e Neves'},
    {title: 'São Marinho'},
    {title: 'São Tomé e Príncipe'},
    {title: 'São Vicente e Granadinas'},
    {title: 'Seicheles'},
    {title: 'Senegal'},
    {title: 'Serra Leoa'},
    {title: 'Sérvia'},
    {title: 'Singapura'},
    {title: 'Síria'},
    {title: 'Somália'},
    {title: 'Sri Lanca'},
    {title: 'Sudão'},
    {title: 'Sudão do Sul'},
    {title: 'Suécia'},
    {title: 'Suíça'},
    {title: 'Suriname'},
    {title: 'Tailândia'},
    {title: 'Taiuã'},
    {title: 'Tajiquistão'},
    {title: 'Tanzânia'},
    {title: 'Timor-Leste'},
    {title: 'Togo'},
    {title: 'Tonga'},
    {title: 'Trindade e Tobago'},
    {title: 'Tunísia'},
    {title: 'Turcomenistão'},
    {title: 'Turquia'},
    {title: 'Tuvalu'},
    {title: 'Ucrânia'},
    {title: 'Uganda'},
    {title: 'Uruguai'},
    {title: 'Usbequistão'},
    {title: 'Vanuatu'},
    {title: 'Vaticano'},
    {title: 'Venezuela'},
    {title: 'Vietname'},
    {title: 'Zâmbia'},
    {title: 'Zimbábue'},
  ]);*/
  const [countrys, setCountrys] = useState([]);
  const [assetsDb, setAssetsDb] = useState([]);
  const [suppliers, setSuppliers] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    getCountrys();
    getAssets();
    getSuppliers();
  }, []);

  const getCountrys = async () => {
    //cadastrando todos os paises no firebase
    // const delay = (n) => new Promise( r => setTimeout(r, n*1000));
    /*for(let item of countrys){
      await myApp.firestore().collection("countrys").add({
        title: item.title
    })  
    console.log(item.title)
    await delay(5);
    }*/

    let countrys = [];
    let querySnapshot = await myApp.firestore().collection("countrys").get();
    for (let doc of querySnapshot.docs) {
      countrys.push({ title: doc.data().title });
    }
    setCountrys(countrys)
  }

  const getSuppliers = async () => {
    let aux = [];
    try {
      let get = await myApp.firestore().collection("supplier").where("in_company", "==", localStorage.getItem('currentCompany')).get();
      for (let item of get.docs) {
        aux.push(item.data());
      }
    } catch (errorGetSuppliers) {
      console.log(errorGetSuppliers);
    }
    setSuppliers(aux);
  }

  const getAssets = async () => {
    let assetsAux = [];
    let querySnapshot = await myApp.firestore().collection("assets").where("in_company", "==", company).get()
    for (let doc of querySnapshot.docs) {
      assetsAux.push({
        id: doc.id,
        cep: doc.data().cep,
        city: doc.data().city,
        complement: doc.data().complement,
        country: doc.data().country,
        in_company: doc.data().in_company,
        lgpd: doc.data().lgpd,
        name: doc.data().name,
        neighborhood: doc.data().neighborhood,
        number: doc.data().number,
        observation: doc.data().observation,
        nameOfResponsible: doc.data().nameOfResponsible,
        numberOfResponsible: doc.data().numberOfResponsible,
        emailOfResponsible: doc.data().emailOfResponsible,
        provider: doc.data().provider,
        place: doc.data().place,
        state: doc.data().state,
        street: doc.data().street,
        type: doc.data().type,
      });
    }
    setAssetsDb(assetsAux)
    console.log('Lista de ativos', assetsAux)
  }

  //função responsável por preencher as informações do ativo
  const handleChange = (prop) => (event) => {
    setAssets({ ...assets, [prop]: event.target.value });
  };

  //função responsável por preencher o pais
  const handleSetCountry = (c) => {
    if (c) {
      setAssets({ ...assets, country: c.title });
      console.log(c.title)
    } else {

    }
  }

  //função responsável por preencher as informações do ativo
  const handleChangeLgpd = (event) => {
    setAssets({ ...assets, lgpd: event.target.value });
  };

  //verificando e buscando CEP
  const handleCheckZipCode = async (e) => {

    if (e === '') {
      setAssets({ ...assets, cep: '' });
      console.log(e.length)
    } else {
      setAssets({ ...assets, cep: e });
      console.log(e.length)
    }

    if (e.length === 8) {
      const req = await fetch("https://viacep.com.br/ws/" + e + "/json/");
      const json = await req.json();

      if (json) {
        setAssets({
          ...assets,
          street: json.logradouro,
          city: json.localidade,
          neighborhood: json.bairro,
          state: json.uf
        })
        console.log(json)
      }
    }
  }

  //função responsável por preencher novas informações do ativo
  const handleChangeEdit = (prop) => (event) => {
    setAssetsEdit({ ...assetsEdit, [prop]: event.target.value });
  };


  //função responsável por preencher o pais
  const handleSetCountryEdit = (c) => {
    if (c) {
      setAssetsEdit({ ...assetsEdit, country: c.title });
      console.log(c.title)
    } else {

    }
  }

  //função responsável por preencher as informações do ativo
  const handleChangeLgpdEdit = (event) => {
    setAssetsEdit({ ...assetsEdit, lgpd: event.target.value });
  };

  //verificando e buscando CEP
  const handleCheckZipCodeEdit = async (e) => {

    if (e === '') {
      setAssetsEdit({ ...assetsEdit, cep: '' });
      console.log(e.length)
    } else {
      setAssetsEdit({ ...assetsEdit, cep: e });
      console.log(e.length)
    }

    if (e.length === 8) {
      const req = await fetch("https://viacep.com.br/ws/" + e + "/json/");
      const json = await req.json();

      if (json) {
        setAssetsEdit({
          ...assetsEdit,
          street: json.logradouro,
          city: json.localidade,
          neighborhood: json.bairro,
          state: json.uf
        })
        console.log(json)
      }
    }
  }

  //opções do campo de pais
  const options = countrys.map((option) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  //abre o modal de cadastro de ativos
  const handleAddAsset = () => {
    setDialogs({
      ...dialogs,
      addAssets: true
    })
  }

  //função responsável por fechar os dialogos
  const handleCloseDialogs = (type) => {
    switch (type) {
      case 'addAssets':
        setDialogs({
          ...dialogs,
          addAssets: false
        })
        break;
      case 'assetSegisteredSuccessfully':
        setDialogs({
          ...dialogs,
          assetSegisteredSuccessfully: false
        })
        break;
      case 'addAssetsEdit':
        setDialogs({
          ...dialogs,
          addAssetsEdit: false
        })
        break;
      case 'assetEditSuccessfully':
        setDialogs({
          ...dialogs,
          assetEditSuccessfully: false
        })
        break;
    }
  };

  //função para registrar o ativo
  const handleRegisteringActive = async () => {
    await myApp.firestore().collection("assets").add({
      cep: assets.cep,
      city: assets.city,
      complement: assets.complement,
      country: assets.country,
      in_company: company,
      lgpd: assets.lgpd,
      name: assets.name,
      neighborhood: assets.neighborhood,
      number: assets.number,
      observation: assets.observation,
      nameOfResponsible: assets.nameOfResponsible,
      numberOfResponsible: assets.numberOfResponsible,
      emailOfResponsible: assets.emailOfResponsible,
      provider: assets.provider,
      place: assets.place,
      state: assets.state,
      street: assets.street,
      type: assets.type,
    }).then(() => {
      setAssets({
        ...assets,
        cep: '',
        city: '',
        complement: '',
        country: '',
        in_company: assets.in_company,
        lgpd: '',
        name: '',
        neighborhood: '',
        number: '',
        observation: '',
        nameOfResponsible: '',
        numberOfResponsible: '',
        emailOfResponsible: '',
        provider: '',
        place: '',
        state: '',
        street: '',
        type: '',
      })
      setDialogs({
        ...dialogs,
        addAssets: false,
        assetSegisteredSuccessfully: true
      })
      getAssets()
    });
  }

  const handleChangeEditDb = async () => {
    await myApp.firestore().collection("assets").doc(assetsEdit.id).update({
      cep: assetsEdit.cep,
      city: assetsEdit.city,
      complement: assetsEdit.complement,
      country: assetsEdit.country,
      in_company: company,
      lgpd: assetsEdit.lgpd,
      name: assetsEdit.name,
      neighborhood: assetsEdit.neighborhood,
      number: assetsEdit.number,
      observation: assetsEdit.observation,
      nameOfResponsible: assetsEdit.nameOfResponsible,
      numberOfResponsible: assetsEdit.numberOfResponsible,
      emailOfResponsible: assetsEdit.emailOfResponsible,
      provider: assetsEdit.provider,
      place: assetsEdit.place,
      state: assetsEdit.state,
      street: assetsEdit.street,
      type: assetsEdit.type,
    }).then(() => {
      setAssetsEdit({
        ...assetsEdit,
        cep: '',
        city: '',
        complement: '',
        country: '',
        in_company: company,
        lgpd: '',
        name: '',
        neighborhood: '',
        number: '',
        observation: '',
        nameOfResponsible: '',
        numberOfResponsible: '',
        emailOfResponsible: '',
        provider: '',
        place: '',
        state: '',
        street: '',
        type: '',
      })
      setDialogs({
        ...dialogs,
        addAssetsEdit: false,
        assetEditSuccessfully: true
      })
      getAssets()
    });
  }

  //função para editar o ativo
  const handleEditAsset = async (item) => {

    console.log(item)
    setDialogs({
      ...dialogs,
      addAssetsEdit: true
    })
    /**
     * const [assetsEdit, setAssetsEdit] = useState({
    name: '',
    type: '',
    place: '',
    country: '',
    cep: '',
    street: '',
    neighborhood: '',
    state: '',
    number: '',
    state: '',
    complement: '',
    lgpd: 'Sim',
    observation: '',
  });
     */
    setAssetsEdit({
      ...assetsEdit,
      id: item.id,
      name: item.name,
      type: item.type,
      place: item.place,
      country: item.country,
      cep: item.cep,
      street: item.street,
      neighborhood: item.neighborhood,
      state: item.state,
      number: item.number,
      complement: item.complement,
      lgpd: item.lgpd,
      observation: item.observation,
      nameOfResponsible: item.nameOfResponsible,
      numberOfResponsible: item.numberOfResponsible,
      emailOfResponsible: item.emailOfResponsible,
      provider: '',
    });
  }

  //função para deletar o ativo
  const  handledeleteAssetsDb = async (item) =>{
    console.log(item)

    let deleteDb =  await myApp.firestore().collection("assets").doc(item.id).delete().then(()=>{
      Swal.fire({
        icon: "success",
        title: "Ativo  excluído",
        showConfirmButton: "ok",
      });
      getAssets()
    })
  }

  return (
    <Grid
      container
      direction="column"
    >
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={4}
      >
        {/**cabeçalho */}
        <Grid
          item
          style={{
            width: "100%"
          }}
        >
          <Card
            style={{
              padding: "16px"
            }}
          >
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              <Grid
                item
                style={{
                  width: "5%"
                }}
              >
                <IconButton
                  size="small"
                  style={{
                    marginLeft: "10px"
                  }}
                  onClick={() => dispatch(changePage(25))}>
                  <ArrowBackIosTwoToneIcon />
                </IconButton>
              </Grid>
              <Grid
                item
                style={{
                  width: "95%"
                }}
              >
                <Typography
                  variant="h5"
                  component="h5"
                >
                  Gestão de ativos
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        {/*Cadastro de ativos */}
        <Grid
          item
          style={{
            width: "100%"
          }}
        >
          <Card
            style={{
              padding: "16px"
            }}
          >
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              <Tooltip
                title="Cadastro de ativo"
                aria-label="add"
                onClick={handleAddAsset}
              >
                <Fab
                  style={{
                    backgroundColor: "#38f205",
                    color: "#192a40"
                  }}
                >
                  <AddIcon />
                </Fab>
              </Tooltip>
            </Grid>
          </Card>
        </Grid>

        {/*Lisa de ativos cadastrados */}
        <Grid
          container
          item
          direction="row"
          style={{
            padding: '10px'
          }}
        >
          <Accordion
            style={{
              width: '100%'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography >Ativos cadastrados</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                direction="column"
                style={{
                  padding: '10px'
                }}
              >
                {
                  assetsDb.length === 0 ?
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      style={{ width: "100%", marginTop: 40 }}
                    >
                      <Grid item>
                        <CircularProgress size={50} color={theme.secondary_color} />
                      </Grid>
                    </Grid>
                    :
                    <Grid
                      item
                      container
                      direction="column"
                      style={{ height: 400, width: '100%' }}
                    >
                      <TableContainer style={{ maxHeight: '80vh' }}>
                        <Table stickyHeader size="large">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                Nome
                              </TableCell>
                              <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                Tipo
                              </TableCell>
                              <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                Local
                              </TableCell>
                              <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                Pais
                              </TableCell>
                              <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                Rua
                              </TableCell>
                              <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                Numero
                              </TableCell>
                              <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                Bairro
                              </TableCell>
                              <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                Estado
                              </TableCell>
                              <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                Complemento
                              </TableCell>
                              <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                Observação
                              </TableCell>
                              <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                Nome do responsável
                              </TableCell>
                              <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                Telefone do responsável
                              </TableCell>
                              <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                Email do responsável
                              </TableCell>
                              <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                Fornecedor
                              </TableCell>
                              {/* <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                LGPD
                              </TableCell> */}
                              <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                Ações
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              assetsDb.map((item, k) => (
                                <TableRow
                                  key={k}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  style={k % 2 ? { background: "white" } : { background: "#e3e3e3" }}
                                >
                                  <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                    {item.name}
                                  </TableCell>
                                  <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                    {item.type}
                                  </TableCell>
                                  <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                    {item.place}
                                  </TableCell>
                                  <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                    {item.country}
                                  </TableCell>
                                  <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                    {item.street === '' ? '' : item.street}
                                  </TableCell>
                                  <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                    {item.number === '' ? '' : item.number}
                                  </TableCell>
                                  <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                    {item.neighborhood === '' ? '' : item.neighborhood}
                                  </TableCell>
                                  <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                    {item.state === '' ? '' : item.state}
                                  </TableCell>
                                  <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                    {item.complement === '' ? '' : item.complement}
                                  </TableCell>
                                  <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                    {item.observation === '' ? '' : item.observation}
                                  </TableCell>
                                  <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                    {item.nameOfResponsible}
                                  </TableCell>
                                  <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                    {item.numberOfResponsible}
                                  </TableCell>
                                  <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                    {item.emailOfResponsible}
                                  </TableCell>
                                  <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                    {item.provider}
                                  </TableCell>
                                  {/* <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                    {item.lgpd}
                                  </TableCell> */}
                                  <TableCell align="center" style={{ fontWeight: 'Bold' }}>
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="center"
                                    >
                                      <Button
                                        onClick={() => handleEditAsset(item)}
                                        variant="contained"
                                        style={{
                                          backgroundColor: '#38f205',
                                          color: '#192a40'
                                        }}
                                      >
                                        Editar
                                      </Button>
                                      <Button
                                        onClick={() =>handledeleteAssetsDb(item)}
                                        style={{
                                          backgroundColor: '#f00',
                                          color: '#192a40',
                                          margin: 5
                                        }}
                                      >
                                        Excluir
                                      </Button>
                                    </Grid>
                                  </TableCell>
                                </TableRow>
                              ))
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                }

              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>

      {/* Dialogo de cadastro do ativo */}
      <Dialog
        open={dialogs.addAssets}
        onClose={() => handleCloseDialogs('addAssets')}
        fullWidth
      >
        <DialogTitle
          style={{
            color: theme.quaternary_color,
            backgroundColor: theme.secondary_color,
            textAlign: "center",
          }}
        >
          Cadastro de ativo
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
          >
            <Grid
              item
              style={{
                marginTop: 10
              }}
            >
              <TextField
                label="Nome do ativo"
                variant="outlined"
                value={assets.name}
                fullWidth
                style={{
                  width: "100%",
                  marginRight: '10px'
                }}
                onChange={handleChange('name')}
              />
            </Grid>

            <Grid
              item
              hidden={assets.name.length < 3 ? true : false}
              style={{
                marginTop: 10
              }}
            >
              <TextField
                label="Tipo de ativo"
                value={assets.type}
                variant="outlined"
                fullWidth
                style={{
                  width: "100%",
                  marginRight: '10px'
                }}
                onChange={handleChange('type')}
              />
            </Grid>

            <Grid
              item
              hidden={assets.type.length < 3 ? true : false}
              style={{
                marginTop: 10
              }}
            >
              <TextField
                label="Local"
                value={assets.place}
                variant="outlined"
                fullWidth
                style={{
                  width: "100%",
                  marginRight: '10px'
                }}
                onChange={handleChange('place')}
              />
            </Grid>

            <Grid
              item
              hidden={assets.place.length < 3 ? true : false}
              style={{
                marginTop: 10
              }}
            >
              <Autocomplete
                options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                groupBy={(option) => option.firstLetter}
                getOptionLabel={(option) => option.title}
                fullWidth
                onChange={(e, a) => handleSetCountry(a)}
                renderInput={(params) => <TextField {...params} label="Países" variant="outlined" />}
              />
            </Grid>

            <Grid
              item
              hidden={assets.country.toUpperCase() === 'BRASIL' ? false : true}
              style={{
                marginTop: 10
              }}
            >
              <TextField
                label="cep"
                value={assets.cep}
                variant="outlined"
                fullWidth
                style={{
                  width: "100%",
                  marginRight: '10px'
                }}
                onChange={(e) => handleCheckZipCode(e.target.value)}
              />
            </Grid>

            <Grid
              item
              hidden={assets.country.toUpperCase() === 'BRASIL' ? false : true}
              style={{
                marginTop: 10
              }}
              disabled
            >
              <TextField
                label="Rua"
                value={assets.street}
                variant="outlined"
                fullWidth
                disabled
                style={{
                  width: "100%",
                  marginRight: '10px',
                  backgroundColor: '#ccc'
                }}
                onChange={handleChange('street')}
              />
            </Grid>

            <Grid
              item
              hidden={assets.country.toUpperCase() === 'BRASIL' ? false : true}
              style={{
                marginTop: 10
              }}
            >
              <TextField
                label="Numero"
                value={assets.number}
                variant="outlined"
                fullWidth
                style={{
                  width: "100%",
                  marginRight: '10px',
                }}
                onChange={handleChange('number')}
              />
            </Grid>

            <Grid
              item
              hidden={assets.country.toUpperCase() === 'BRASIL' ? false : true}
              style={{
                marginTop: 10
              }}
              disabled
            >
              <TextField
                label="Bairro"
                value={assets.neighborhood}
                variant="outlined"
                fullWidth
                disabled
                style={{
                  width: "100%",
                  marginRight: '10px',
                  backgroundColor: '#ccc'
                }}
                onChange={handleChange('neighborhood')}
              />
            </Grid>

            <Grid
              item
              hidden={assets.country.toUpperCase() === 'BRASIL' ? false : true}
              style={{
                marginTop: 10
              }}
              disabled
            >
              <TextField
                label="Estado"
                value={assets.state}
                variant="outlined"
                fullWidth
                disabled
                style={{
                  width: "100%",
                  marginRight: '10px',
                  backgroundColor: '#ccc'
                }}
                onChange={handleChange('state')}
              />
            </Grid>

            <Grid
              item
              hidden={assets.country.toUpperCase() === 'BRASIL' ? false : true}
              style={{
                marginTop: 10
              }}
              disabled
            >
              <TextField
                label="Cidade"
                value={assets.city}
                variant="outlined"
                fullWidth
                disabled
                style={{
                  width: "100%",
                  marginRight: '10px',
                  backgroundColor: '#ccc'
                }}
                onChange={handleChange('city')}
              />
            </Grid>


            <Grid
              item
              hidden={assets.country.toUpperCase() === 'BRASIL' ? false : true}
              style={{
                marginTop: 10
              }}
            >
              <TextField
                label="Complemento"
                value={assets.complement}
                variant="outlined"
                fullWidth
                style={{
                  width: "100%",
                  marginRight: '10px',
                }}
                onChange={handleChange('complement')}
              />
            </Grid>

            <Grid
              item
              hidden={assets.country.toUpperCase() !== 'BRASIL' && assets.country.length > 3 ? false : true}
              style={{
                marginTop: 10
              }}
            >
              <TextField
                label="Observação"
                value={assets.observation}
                variant="outlined"
                fullWidth
                style={{
                  width: "100%",
                  marginRight: '10px',
                }}
                onChange={handleChange('observation')}
              />
            </Grid>

            <Grid
              item
              hidden={assets.observation.length < 3 ? true : false}
              style={{
                marginTop: 10
              }}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend">Possui LGPD</FormLabel>
                <RadioGroup aria-label="gender" name="gender1" value={assets.lgpd} onChange={handleChangeLgpd}>
                  <FormControlLabel value={'Sim'} control={
                    <Radio style={{
                      color: "#38f205"
                    }}
                    />
                  } label="Sim" />
                  <FormControlLabel value={'Não'} control={
                    <Radio
                      style={{
                        color: "#f00"
                      }}
                    />
                  } label="Não" />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid
              item
              hidden={assets.country.length > 3 ? false : true}
              style={{
                marginTop: 10
              }}
            >
              <TextField
                label="Nome do responsável"
                value={assets.nameOfResponsible}
                variant="outlined"
                fullWidth
                style={{
                  width: "100%",
                  marginRight: '10px',
                }}
                onChange={handleChange('nameOfResponsible')}
              />
            </Grid>

            <Grid
              item
              hidden={assets.country.length > 3 ? false : true}
              style={{
                marginTop: 10
              }}
            >
              <TextField
                label="Telefone do responsável"
                value={assets.numberOfResponsible}
                variant="outlined"
                fullWidth
                style={{
                  width: "100%",
                  marginRight: '10px',
                }}
                onChange={handleChange('numberOfResponsible')}
              />
            </Grid>

            <Grid
              item
              hidden={assets.country.length > 3 ? false : true}
              style={{
                marginTop: 10
              }}
            >
              <TextField
                label="Email do responsável"
                value={assets.emailOfResponsible}
                variant="outlined"
                fullWidth
                style={{
                  width: "100%",
                  marginRight: '10px',
                }}
                onChange={handleChange('emailOfResponsible')}
              />
            </Grid>

            <Grid
              item
              hidden={assets.country.length > 3 ? false : true}
              style={{
                marginTop: 10
              }}
            >
              <FormControl
                fullWidth
              >
                <InputLabel>Fornecedor</InputLabel>
                <Select
                  value={assets.provider}
                  onChange={handleChange('provider')}
                >
                  {/* <MenuItem value="Amazon AWS">Amazon AWS</MenuItem>
                  <MenuItem value="BlueProjects">BlueProjects</MenuItem>
                  <MenuItem value="TOTVS">TOTVS</MenuItem> */}
                  {
                    suppliers.length === 0 ?
                    <MenuItem value="">SEM FORNECEDOR CADASTRADO</MenuItem> :
                    suppliers.map( (item, index) => (
                      <MenuItem key={index} value={item.nome}>{item.nome}</MenuItem>
                    ) )
                  }
                </Select>
              </FormControl>
            </Grid>



          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseDialogs('addAssets')}
            autoFocus
          >
            Cancelar
          </Button>
          <Button
            onClick={handleRegisteringActive}
            style={{
              color: '#192a40',
              backgroundColor: '#38f205',
            }}
          >
            Cadastrar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo de editar do titular */}
      <Dialog
        open={dialogs.addAssetsEdit}
        onClose={() => handleCloseDialogs('addAssetsEdit')}
        fullWidth
      >
        <DialogTitle
          style={{
            color: theme.quaternary_color,
            backgroundColor: theme.secondary_color,
            textAlign: "center",
          }}
        >
          Edição do ativo
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            style={{
              paddingBottom: 40
            }}
          >
            <Grid
              item
              style={{
                marginTop: 10
              }}
            >
              <TextField
                label="Nome do ativo"
                variant="outlined"
                value={assetsEdit.name}
                fullWidth
                style={{
                  width: "100%",
                  marginRight: '10px'
                }}
                onChange={handleChangeEdit('name')}
              />
            </Grid>

            <Grid
              item
              hidden={assetsEdit.name.length < 3 ? true : false}
              style={{
                marginTop: 10
              }}
            >
              <TextField
                label="Tipo do ativo"
                value={assetsEdit.type}
                variant="outlined"
                fullWidth
                style={{
                  width: "100%",
                  marginRight: '10px'
                }}
                onChange={handleChangeEdit('type')}
              />
            </Grid>

            <Grid
              item
              hidden={assetsEdit.type.length < 3 ? true : false}
              style={{
                marginTop: 10
              }}
            >
              <TextField
                label="Local"
                value={assetsEdit.place}
                variant="outlined"
                fullWidth
                style={{
                  width: "100%",
                  marginRight: '10px'
                }}
                onChange={handleChangeEdit('place')}
              />
            </Grid>

            <Grid
              item
              hidden={assetsEdit.place.length < 3 ? true : false}
              style={{
                marginTop: 10
              }}
            >
              <Autocomplete
                options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                groupBy={(option) => option.firstLetter}
                getOptionLabel={(option) => option.title}
                fullWidth
                onChange={(e, a) => handleSetCountryEdit(a)}
                renderInput={(params) => <TextField {...params} label="Países" variant="outlined" />}
              />
            </Grid>

            <Grid
              item
              hidden={assetsEdit.country.toUpperCase() === 'BRASIL' ? false : true}
              style={{
                marginTop: 10
              }}
            >
              <TextField
                label="cep"
                value={assetsEdit.cep}
                variant="outlined"
                fullWidth
                style={{
                  width: "100%",
                  marginRight: '10px'
                }}
                onChange={(e) => handleCheckZipCodeEdit(e.target.value)}
              />
            </Grid>

            <Grid
              item
              hidden={assetsEdit.country.toUpperCase() === 'BRASIL' ? false : true}
              style={{
                marginTop: 10
              }}
              disabled
            >
              <TextField
                label="Rua"
                value={assetsEdit.street}
                variant="outlined"
                fullWidth
                disabled
                style={{
                  width: "100%",
                  marginRight: '10px',
                  backgroundColor: '#ccc'
                }}
                onChange={handleChangeEdit('street')}
              />
            </Grid>

            <Grid
              item
              hidden={assetsEdit.country.toUpperCase() === 'BRASIL' ? false : true}
              style={{
                marginTop: 10
              }}
            >
              <TextField
                label="Numero"
                value={assetsEdit.number}
                variant="outlined"
                fullWidth
                style={{
                  width: "100%",
                  marginRight: '10px',
                }}
                onChange={handleChangeEdit('number')}
              />
            </Grid>

            <Grid
              item
              hidden={assetsEdit.country.toUpperCase() === 'BRASIL' ? false : true}
              style={{
                marginTop: 10
              }}
              disabled
            >
              <TextField
                label="Bairro"
                value={assetsEdit.neighborhood}
                variant="outlined"
                fullWidth
                disabled
                style={{
                  width: "100%",
                  marginRight: '10px',
                  backgroundColor: '#ccc'
                }}
                onChange={handleChangeEdit('neighborhood')}
              />
            </Grid>

            <Grid
              item
              hidden={assetsEdit.country.toUpperCase() === 'BRASIL' ? false : true}
              style={{
                marginTop: 10
              }}
              disabled
            >
              <TextField
                label="Estado"
                value={assetsEdit.state}
                variant="outlined"
                fullWidth
                disabled
                style={{
                  width: "100%",
                  marginRight: '10px',
                  backgroundColor: '#ccc'
                }}
                onChange={handleChangeEdit('state')}
              />
            </Grid>

            <Grid
              item
              hidden={assetsEdit.country.toUpperCase() === 'BRASIL' ? false : true}
              style={{
                marginTop: 10
              }}
              disabled
            >
              <TextField
                label="Estado"
                value={assetsEdit.city}
                variant="outlined"
                fullWidth
                disabled
                style={{
                  width: "100%",
                  marginRight: '10px',
                  backgroundColor: '#ccc'
                }}
                onChange={handleChangeEdit('city')}
              />
            </Grid>

            <Grid
              item
              hidden={assetsEdit.country.toUpperCase() === 'BRASIL' ? false : true}
              style={{
                marginTop: 10
              }}
            >
              <TextField
                label="Complemento"
                value={assetsEdit.complement}
                variant="outlined"
                fullWidth
                style={{
                  width: "100%",
                  marginRight: '10px',
                }}
                onChange={handleChangeEdit('complement')}
              />
            </Grid>

            <Grid
              item
              hidden={assetsEdit.country.toUpperCase() !== 'BRASIL' && assetsEdit.country.length > 3 ? false : true}
              style={{
                marginTop: 10
              }}
            >
              <TextField
                label="Observação"
                value={assetsEdit.observation}
                variant="outlined"
                fullWidth
                style={{
                  width: "100%",
                  marginRight: '10px',
                }}
                onChange={handleChangeEdit('observation')}
              />
            </Grid>

            <Grid
              item
              hidden={assetsEdit.country.length > 3 ? false : true}
              style={{
                marginTop: 10
              }}
            >
              <TextField
                label="Nome do responsável"
                value={assetsEdit.nameOfResponsible}
                variant="outlined"
                fullWidth
                style={{
                  width: "100%",
                  marginRight: '10px',
                }}
                onChange={handleChangeEdit('nameOfResponsible')}
              />
            </Grid>

            <Grid
              item
              hidden={assetsEdit.country.length > 3 ? false : true}
              style={{
                marginTop: 10
              }}
            >
              <TextField
                label="Numero do responsável"
                value={assetsEdit.numberOfResponsible}
                variant="outlined"
                fullWidth
                style={{
                  width: "100%",
                  marginRight: '10px',
                }}
                onChange={handleChangeEdit('numberOfResponsible')}
              />
            </Grid>

            <Grid
              item
              hidden={assetsEdit.country.length > 3 ? false : true}
              style={{
                marginTop: 10
              }}
            >
              <TextField
                label="Email do responsável"
                value={assetsEdit.emailOfResponsible}
                variant="outlined"
                fullWidth
                style={{
                  width: "100%",
                  marginRight: '10px',
                }}
                onChange={handleChangeEdit('emailOfResponsible')}
              />
            </Grid>

            <Grid
              item
              hidden={assetsEdit.country.length > 3 ? false : true}
              style={{
                marginTop: 10
              }}
            >
              <FormControl
                fullWidth
              >
                <InputLabel>Fornecedor</InputLabel>
                <Select
                  value={assetsEdit.provider}
                  onChange={handleChangeEdit('provider')}
                >
                  {/* <MenuItem value="Amazon AWS">Amazon AWS</MenuItem>
                  <MenuItem value="BlueProjects">BlueProjects</MenuItem>
                  <MenuItem value="TOTVS">TOTVS</MenuItem> */}
                  {
                    suppliers.length === 0 ?
                    <MenuItem value="">SEM FORNECEDOR CADASTRADO</MenuItem> :
                    suppliers.map( (item, index) => (
                      <MenuItem key={index} value={item.nome}>{item.nome}</MenuItem>
                    ) )
                  }
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              hidden={assetsEdit.observation.length < 3 ? true : false}
              style={{
                marginTop: 10
              }}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend">Possui LGPD</FormLabel>
                <RadioGroup aria-label="gender" name="gender1" value={assetsEdit.lgpd} onChange={handleChangeLgpdEdit}>
                  <FormControlLabel value={'Sim'} control={<Radio />} label="Sim" />
                  <FormControlLabel value={'Não'} control={<Radio />} label="Não" />
                </RadioGroup>
              </FormControl>
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseDialogs('addAssetsEdit')}
            autoFocus
          >
            Cancelar
          </Button>
          <Button
            onClick={handleChangeEditDb}
            style={{
              color: '#192a40',
              backgroundColor: '#38f205',
            }}
          >
            Editar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo para informar que 
					ativo foi cadastrado com sucesso
				*/}
      <Dialog open={dialogs.assetSegisteredSuccessfully} onClose={() => handleCloseDialogs('assetSegisteredSuccessfully')}>
        <DialogTitle
          style={{
            color: theme.quaternary_color,
            backgroundColor: theme.secondary_color,
            textAlign: "center",
          }}
        >
          ATENÇÃO!
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              textAlign="center"
            >
              <DialogContentText>
                <Typography
                  variant="h6"
                  component="h6"
                >
                  Ativo cadastrado com sucesso!
                </Typography>
              </DialogContentText>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseDialogs('assetSegisteredSuccessfully')}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo para informar que 
					ativo foi editado com sucesso
				*/}
      <Dialog open={dialogs.assetEditSuccessfully} onClose={() => handleCloseDialogs('assetEditSuccessfully')}>
        <DialogTitle
          style={{
            color: theme.quaternary_color,
            backgroundColor: theme.secondary_color,
            textAlign: "center",
          }}
        >
          ATENÇÃO!
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              textAlign="center"
            >
              <DialogContentText>
                <Typography
                  variant="h6"
                  component="h6"
                >
                  Ativo editado com sucesso!
                </Typography>
              </DialogContentText>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseDialogs('assetEditSuccessfully')}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default AssetManagement;