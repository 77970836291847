import React, { Component } from "react";

import {
	Grid,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Typography,
	IconButton,
	TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import theme from "../../../../core/theme.json";

class ROPADialog extends Component {
	constructor(props) {
		super(props);

		this.state = {

		}
	}



	visualizarDados() {
		console.log('answers', this.props.answers);
		console.log('questions', this.props.questions);
	}

	setAlternativeList(alternativesList){
		let alternativesListValue = "";
		for(let item of alternativesList){
			alternativesListValue += "/ "+item.name;
		}
		return(alternativesListValue);
	}

	render() {
		return (
			<Dialog
				maxWidth={"xl"}
				open={this.props.openDialog}
				fullWidth
				onClose={() => { this.props.handleDialog(-1) }}
			>
				<DialogTitle>
					<Grid
						container
						direction="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Grid item>
							<Typography>Editar respostas</Typography>
						</Grid>
						<Grid item>
							<IconButton onClick={() => { this.props.handleDialog(-1) }}>
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent>
					<Grid
						container
						direction="column"
						alignItems="center"
					>
						{
							this.props.answers.length == 53 ?
								this.props.answers.map((question, index) => (
									<Grid item container direction='column' style={{ width: "100%", marginBottom: "16px" }}>
										<Grid style={{ width: "100%", marginBottom: "16px" }}>
											<TextField
												label={question.oq}
												variant="outlined"
												value={question.dado}
												fullWidth
												// onChange={(event) => this.props.handleAnswer(event, index)}
											/>
										</Grid>
										{
											question.alternativesList && question.alternativesList.length > 0 ?
												<Grid style={{ width: "100%", marginBottom: "16px" }}>
													<TextField
														label="Alternativas"
														multiline
														minRows={4}
														value={this.setAlternativeList(question.alternativesList)}
														variant="outlined"
														fullWidth
													/>
												</Grid>
												:
												<></>
										}
									</Grid>
								))
								:
								this.props.questions.map((question, index) => (
									<Grid item style={{ width: "100%", marginBottom: "16px" }}>
										<TextField
											label={question.text}
											variant="outlined"
											value={this.props.answers[index]}
											fullWidth
											onChange={(event) => this.props.handleAnswer(event, index)}
										/>
									</Grid>
								))

						}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid
						container
						direction="row"
						justifyContent="flex-end"
						alignItems="center"
					>
						<Grid item>
							<Button
								variant="contained"
								style={{
									background: theme.primary_color,
									color: theme.secondary_color,
									width: "100%",
									height: "50px"
								}}
								// onClick={() => { this.props.saveAnswers() }}
								onClick={() => { this.visualizarDados() }}
							>
								Salvar
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		);
	}
}

export default ROPADialog;