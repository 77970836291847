import React, { useEffect, useState } from 'react';

//imports mui
import { Grid, Button, Typography } from '@material-ui/core';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { changePage } from '../../../presentation/redux/actions';
import { useDispatch } from "react-redux";

import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    PDFViewer,
} from "@react-pdf/renderer";

//import redux
import { connect } from "react-redux";

//import cores do tema mui
import theme from '../../../../core/theme.json';

import myApp from '../../../../core/infrastructure/firebaseConfig'
import 'firebase/compat/firestore'
import { Opacity } from '@material-ui/icons';

const handleFormatDate = (date) => {
    if (date) {
        let data = new Date(date);
        let dia = String(data.getDate()).padStart(2, '0');
        let mes = String(data.getMonth() + 1).padStart(2, '0');
        let ano = data.getFullYear();
        let dataAtual = dia + '/' + mes + '/' + ano;
        return dataAtual
    } else {
        return ''
    }
}

const ViewQuestionnaireReport = (props) => {


    //variaveis auxiliares
    const dispatch = useDispatch();

   

    //gerando pdf
    const pdfGenerator = () => {

        if (props.newQuiz) {
            console.log('2.0');

            console.log(props.newQuizQuestions);
            const styles = StyleSheet.create({
                page: {
                    flexDirection: 'column',
                },
                title: {
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: '20px',
                    marginBottom: '50px',
                },
                titleText: {
                    fontSize: '50px',
                    fontWeight: 'bold',
                    maxWidth: '70%',
                    textAlign: 'center',
                },
                infoLine: {
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: '20px',
                    textAlign: 'center'
                },
                infoText: {
                    flex: '1',
                    textAlign: '',
                    marginLeft: '30px',
                },
                infoTextTest: {
                    textAlign: 'center',
                },
                questionView: {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    marginTop: '20px',
                    textAlign: 'center',
                    width: '100%'
                },
                questionText: {
                    fontSize: '18px',
                    color: '#777'
                },
                viewQuiz: {
                    flexDirection: 'column',
                    marginTop: '15px'
                },
                quizItem: {
                    flexDirection: 'row',
                    maxWidth: '70%',
                    margin: '10px 0px 0px 30px',
                    fontWeight:"900"
                    
                    
                },
                answerItem: {
                    flexDirection: 'column',
                    maxWidth: '70%',
                    margin: '10px 0px 0px 50px',
                    color:'#555'
                },
                answer: {
                    color: '#555'
                },
                answerGaps:{
                    flexDirection: 'column',
                    maxWidth: '70%',
                    margin: '10px 0px 0px 50px',
                    color:'red'
                },
                gapText:{
                    color:'#ff6961',
                    
                }
            });

            //renderizando pdf
            return (
                <Document>
                    <Page size="A4" style={styles.page}>
                        <View style={styles.title}>
                            <Text style={styles.titleText} > {props.name} </Text>
                        </View>
                        <View style={styles.infoLine} >
                            <View style={styles.infoText} >
                                <Text>Área: </Text>
                            </View>
                            <View style={[styles.infoText, styles.infoTextTest]} >
                                <Text> {props.area} </Text>
                            </View>
                        </View>
                        <View style={styles.infoLine} >
                            <View style={styles.infoText} >
                                <Text>Processo: </Text>
                            </View>
                            <View style={[styles.infoText, styles.infoTextTest]} >
                                <Text> {props.process} </Text>
                            </View>
                        </View>
                        <View style={styles.infoLine} >
                            <View style={styles.infoText} >
                                <Text>Status: </Text>
                            </View>
                            <View style={[styles.infoText, styles.infoTextTest]} >
                                <Text style={{ color: props.status === 'Concluído' ? theme.primary_color : '#f00' }} > {props.status} </Text>
                            </View>
                        </View>
                        {
                            props.dateToReply ?
                                <View style={styles.infoLine} >
                                    <View style={styles.infoText} >
                                        <Text>Data de resposta: </Text>
                                    </View>
                                    <View style={[styles.infoText, styles.infoTextTest]} >
                                        <Text> {handleFormatDate(props.dateToReply)} </Text>
                                    </View>
                                </View>
                                :
                                <></>
                        }
                        {
                            /**
                             * {
                            props.listQuestionAndAnswer.map((item, k) => (
                                <View key={k} style={styles.viewQuiz} >
                                    {
                                        item.answer ?
                                            <View style={styles.quizItem} >
                                                <Text>{k + 1}. </Text>
                                                <Text> {item.questionName} </Text>
                                            </View>
                                            :
                                            <></>
                                    }
                                    <View key={k} style={styles.answerItem} >
                                        <Text style={styles.answer} > {item.answer} </Text>
                                        {
                                            item.gapName ?
                                                <Text style={{ color: '#f55', marginTop: '5px' }} > {item.gapName} ! </Text>
                                                :
                                                <></>
                                        }


                                    </View>

                                </View>
                            ))
                        }
                        esse é o certo
                             */
                        }
                        {props.newQuizQuestions.map((question, index) => (
                            <View key={index} style={styles.viewQuiz}>
                                <Text style={styles.quizItem}>{index + 1}. {question.name}</Text>
                                <View style={styles.answerContainer}>
                                    <Text style={styles.answerItem}>{question.answer}  </Text>
                                </View>
                                {question.alternativesList.map((alternative, altIndex) => (
                                    <View key={altIndex} style={{display:'flex', paddingLeft:'5%', maxWidth:'80%'}}>
                                        <Text style={alternative.gap == true ? {color:'red'} : {color:'#555'}}>{alternative.name}</Text>
                                        {alternative.gap ? (
                                            <Text style={styles.gapText}>{alternative.gapPlanoAcao}</Text>
                                        ) : null}
                                    </View>
                                ))}
                            </View>
                        ))
                        }
                    </Page>
                </Document>
            );

        } else {
            console.log('1.0');
            //estilos do pdf
            const styles = StyleSheet.create({
                page: {
                    flexDirection: 'column',
                },
                title: {
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: '20px',
                    marginBottom: '50px',
                },
                titleText: {
                    fontSize: '50px',
                    fontWeight: 'bold',
                    maxWidth: '70%',
                    textAlign: 'center',
                },
                infoLine: {
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: '20px',
                    textAlign: 'center'
                },
                infoText: {
                    flex: '1',
                    textAlign: '',
                    marginLeft: '30px',
                },
                infoTextTest: {
                    textAlign: 'center',
                },
                questionView: {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    marginTop: '20px',
                    textAlign: 'center',
                    width: '100%'
                },
                questionText: {
                    fontSize: '18px',
                    color: '#777'
                    
                },
                viewQuiz: {
                    flexDirection: 'column',
                    marginTop: '15px'
                },
                quizItem: {
                    flexDirection: 'row',
                    maxWidth: '70%',
                    margin: '10px 0px 0px 30px',
                    
                },
                answerItem: {
                    flexDirection: 'column',
                    maxWidth: '70%',
                    margin: '10px 0px 0px 50px',
                    
                },
                answer: {
                    color: '#555'
                },
            });

            //renderizando pdf
            return (
                <Document>
                    <Page size="A4" style={styles.page}>
                        <View style={styles.title}>
                            <Text style={styles.titleText} > {props.name} </Text>
                        </View>
                        <View style={styles.infoLine} >
                            <View style={styles.infoText} >
                                <Text>Área: </Text>
                            </View>
                            <View style={[styles.infoText, styles.infoTextTest]} >
                                <Text> {props.area} </Text>
                            </View>
                        </View>
                        <View style={styles.infoLine} >
                            <View style={styles.infoText} >
                                <Text>Processo: </Text>
                            </View>
                            <View style={[styles.infoText, styles.infoTextTest]} >
                                <Text> {props.process} </Text>
                            </View>
                        </View>
                        <View style={styles.infoLine} >
                            <View style={styles.infoText} >
                                <Text>Status: </Text>
                            </View>
                            <View style={[styles.infoText, styles.infoTextTest]} >
                                <Text style={{ color: props.status === 'Concluído' ? theme.primary_color : '#f00' }} > {props.status} </Text>
                            </View>
                        </View>
                        {
                            props.dateToReply ?
                                <View style={styles.infoLine} >
                                    <View style={styles.infoText} >
                                        <Text>Data de resposta: </Text>
                                    </View>
                                    <View style={[styles.infoText, styles.infoTextTest]} >
                                        <Text> {handleFormatDate(props.dateToReply)} </Text>
                                    </View>
                                </View>
                                :
                                <></>
                        }
                        {
                            props.listQuestionAndAnswer.map((item, k) => (
                                <View key={k} style={styles.viewQuiz} >
                                    {
                                        item.answer ?
                                            <View style={styles.quizItem} >
                                                <Text>{k + 1}. </Text>
                                                <Text> {item.questionName} </Text>
                                            </View>
                                            :
                                            <></>
                                    }
                                    <View key={k} style={styles.answerItem} >
                                        <Text style={styles.answer} > {item.answer} </Text>
                                        {
                                            item.gapName ?
                                                <Text style={{ color: '#f55', marginTop: '5px' }} > {item.gapName} ! </Text>
                                                :
                                                <></>
                                        }


                                    </View>

                                </View>
                            ))
                        }
                    </Page>
                </Document>
            );
        }

    }

    // mudando pagina com redux
    const handleChangePage = () => {
        //voltando para o relatório de respostas
        dispatch(changePage(9));
    }

    return (
        <Grid container>
            <Grid container style={{ backgroundColor: '#fff', padding: '10px', marginBottom: '10px', borderRadius: '5px' }} >
                <Grid item>
                    <Button
                        variant="contained"
                        style={{ background: 'none', marginRight: '50px' }}
                        onClick={() => handleChangePage()}
                    >
                        <ArrowBackIcon />
                    </Button>
                </Grid>
                <Grid item>
                    <Typography variant="h4" component="h4" style={{ fontWeight: "500" }}>
                        Visualizar / baixar em PDF
                    </Typography>
                </Grid>
            </Grid>
            {

                <PDFViewer width="100%" height="800px" >
                    {
                        pdfGenerator()
                    }
                </PDFViewer>

            }
        </Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        name: state.questionnaireReducer.name,
        area: state.questionnaireReducer.area,
        process: state.questionnaireReducer.process,
        status: state.questionnaireReducer.status,
        dateToReply: state.questionnaireReducer.dateToReply,
        listQuestionAndAnswer: state.questionnaireReducer.listQuestionAndAnswer,
        newQuiz: state.questionnaireReducer.newQuiz,
        newQuizId: state.questionnaireReducer.newQuizId,
        newQuizQuestions: state.questionnaireReducer.newQuizQuestions
    };
}

export default connect(mapStateToProps, { changePage })(ViewQuestionnaireReport);