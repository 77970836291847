import React, { Component } from 'react';

import {
	TableCell,
	TableRow,
} from '@material-ui/core';

class LegalBasisList extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<>
				{this.props.legalBasisList.map((lb, index) => {
					return (
						<TableRow>
							<TableCell>{lb.name}</TableCell>
							<TableCell>{lb.description}</TableCell>
							<TableCell>{lb.legalJustification}</TableCell>
						</TableRow>
					)
				})
				}
			</>
		)
	}
}

export default LegalBasisList;
//export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LegalBasis));