import React, { Component } from "react";
import { connect } from "react-redux";
import myApp from '../../../../core/infrastructure/firebaseConfig';
import "firebase/compat/firestore";
import {
  Grid,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  IconButton,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Snackbar,
  MenuItem,
  FormControl,
  Select
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIosTwoToneIcon from "@material-ui/icons/ArrowBackIosTwoTone";
import CloseIcon from "@material-ui/icons/Close";
import Alert from '@material-ui/lab/Alert';
import { changePage } from "../../../../dashboard/presentation/redux/actions";
import theme from "../../../../core/theme.json";

class ChatBotParameters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      intents: [],
      frases: [],
      parametros: [],
      mensagens: [],
      authToken: "",
      cnpjEmpresa: "",
      loading: true,
      selectedIntentId: -1,
      selectedIntentIndex: -1,
      selectedFraseId: -1,
      selectedFraseIndex: -1,
      selectedParametroId: -1,
      selectedParametroIndex: -1,
      selectedMensagemId: -1,
      selectedMensagemIndex: -1,
      openIntentDialog: false,
      openFraseDialog: false,
      openParametroDialog: false,
      openMensagemDialog: false,
      intentName: "",
      showError: false,
      errorMessage: "",
      frase: "",
      tipoMensagem: -1,
      mensagem: "",
      botao: "",
      link: "",
      parametro: "",
      obrigatorio: "",
      entidade: "",
      valor: "",
      prompt: ""
    }

    this.filterFrasesList = this.filterFrasesList.bind(this);
    this.filterParametrosList = this.filterParametrosList.bind(this);
    this.filterMensagensList = this.filterMensagensList.bind(this);
  }

  async componentDidMount() {
    await this.getCNPJ();
    await this.getAuthToken();
    await this.getData();
  }

  async getCNPJ() {
    let cnpj = "";

    try {
      const db = myApp.firestore();
      const companySnapshot = await db.collection('companies').doc(localStorage.getItem('currentCompany')).get();
      cnpj = companySnapshot.data().cnpj;
    } catch (error) {
      console.error("Erro ao buscar CNPJ: " + error);
      cnpj = "";
    }

    this.setState({
      cnpjEmpresa: cnpj
    });
  }

  async getAuthToken() {
    let token = "";

    try {
      const response = await fetch("https://disc.beelegal.com.br/beelegal/Security/Login", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fb_id: process.env.REACT_APP_EPM_BEELEGAL_INTEGRACAO_FB_ID
        }),
        mode: "cors",
      });

      const responseJson = await response.json();

      token = responseJson.data.auth_ticket;
    } catch (error) {
      console.error("Erro ao gerar token de autenticação: " + error);
      token = "";
    }

    this.setState({
      authToken: token
    });
  }

  async getData() {
    let intents = [];
    let frases = [];
    let parametros = [];
    let mensagens = [];

    try {
      var obj = {
        qid: "BEEPRIVACY_PARAMETROS_DPOBOT:INTENTS",
        conditions: [
          {
            filterid: "EMPRESA",
            values: [this.state.cnpjEmpresa],
          },
        ],
      };

      const response = await fetch("https://disc.beelegal.com.br/beelegal/Integration/Query", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          auth: this.state.authToken,
        },
        body: JSON.stringify(obj),
      });

      const responseJson = await response.json();

      for (const row of responseJson.list) {
        let intent = {
          id: row.ID,
          name: row.INTENT_NAME,
          dialogId: row.ID_DIALOG
        };

        intents.push(intent);
      }
    } catch (error) {
      console.error("Erro ao buscar intents: " + error);
      intents = [];
    }

    try {
      var obj = {
        qid: "BEEPRIVACY_PARAMETROS_DPOBOT:FRASES",
        conditions: [
          {
            filterid: "EMPRESA",
            values: [this.state.cnpjEmpresa],
          },
        ],
      };

      const response = await fetch("https://disc.beelegal.com.br/beelegal/Integration/Query", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          auth: this.state.authToken,
        },
        body: JSON.stringify(obj),
      });

      const responseJson = await response.json();

      for (const row of responseJson.list) {
        let frase = {
          id: row.ID,
          frase: row.FRASE,
          dialogId: row.DIALOG_ID,
          intentId: row.INTENT_ID
        };

        frases.push(frase);
      }
    } catch (error) {
      console.error("Erro ao buscar frases: " + error);
      frases = [];
    }

    try {
      var obj = {
        qid: "BEEPRIVACY_PARAMETROS_DPOBOT:PARAMETROS",
        conditions: [
          {
            filterid: "EMPRESA",
            values: [this.state.cnpjEmpresa],
          },
        ],
      };

      const response = await fetch("https://disc.beelegal.com.br/beelegal/Integration/Query", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          auth: this.state.authToken,
        },
        body: JSON.stringify(obj),
      });

      const responseJson = await response.json();

      for (const row of responseJson.list) {
        let parametro = {
          id: row.ID,
          obrigatorio: row.REQUIRED,
          param: row.PARAMETRO,
          entidade: row.ENTITY,
          valor: row.VALUE,
          prompt: row.PROMPT,
          dialogId: row.DIALOG_ID,
          intentId: row.INTENT_ID
        };

        parametros.push(parametro);
      }
    } catch (error) {
      console.error("Erro ao buscar parametros: " + error);
      parametros = [];
    }

    try {
      var obj = {
        qid: "BEEPRIVACY_PARAMETROS_DPOBOT:MENSAGENS",
        conditions: [
          {
            filterid: "EMPRESA",
            values: [this.state.cnpjEmpresa],
          },
        ],
      };

      const response = await fetch("https://disc.beelegal.com.br/beelegal/Integration/Query", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          auth: this.state.authToken,
        },
        body: JSON.stringify(obj),
      });

      const responseJson = await response.json();

      for (const row of responseJson.list) {
        let mensagem = {
          id: row.ID,
          msg: row.MENSAGEM,
          dialogId: row.DIALOG_ID,
          intentId: row.INTENT_ID,
          tipoMensagem: row.FK_TIPO_MENSAGEM,
          botao: row.BOTAO,
          link: row.LINK
        };

        mensagens.push(mensagem);
      }
    } catch (error) {
      console.error("Erro ao buscar mensagens: " + error);
      mensagens = [];
    }

    this.setState({
      intents,
      frases,
      parametros,
      mensagens,
      loading: false
    });
  }

  handleSelectIntentRow(intentId) {
    this.setState({
      selectedIntentId: intentId
    });
  }

  filterFrasesList(frase) {
    if (this.state.selectedIntentId === frase.intentId) {
      return frase;
    }
  }

  filterParametrosList(parametro) {
    if (this.state.selectedIntentId === parametro.intentId) {
      return parametro;
    }
  }

  filterMensagensList(mensagem) {
    if (this.state.selectedIntentId === mensagem.intentId) {
      return mensagem;
    }
  }

  openIntentDialog(intent, newIntent, intentIndex) {
    if (newIntent) {
      this.setState({
        openIntentDialog: true,
        selectedIntentId: intentIndex,
        intentName: "",
        selectedIntentIndex: intentIndex
      });
    } else {
      this.setState({
        openIntentDialog: true,
        intentName: intent.name,
        selectedIntentIndex: intentIndex
      });
    }
  }

  closeIntentDialog(newIntentId = -1) {
    if (newIntentId > -1) {
      this.setState({
        openIntentDialog: false,
        intentName: "",
        selectedIntentIndex: -1,
        selectedIntentId: newIntentId
      });
    } else {
      this.setState({
        openIntentDialog: false,
        intentName: "",
        selectedIntentIndex: -1
      });
    }
  }

  handleIntent(event) {
    this.setState({
      intentName: event.target.value
    });
  }

  async saveIntent() {
    if (this.state.intentName === "") {
      this.setState({
        errorMessage: "Campo intenção não pode ser vazio",
        showError: true
      });

      return;
    }

    let intentList = this.state.intents;

    if (this.state.selectedIntentIndex === -1) { // Novo
      try {
        let obj = {
          tid: "VF9ESUFMT0dfSU5URU5UOjExNzI2Nw==",
          fid: 144,
          data: {
            INTENT_NAME: this.state.intentName,
            EMPRESA: this.state.cnpjEmpresa
          }
        }

        const response = await fetch("https://disc.beelegal.com.br/beelegal/Integration/Save", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            auth: this.state.authToken,
          },
          body: JSON.stringify(obj),
        });

        const responseJson = await response.json();

        if (responseJson.error) {
          this.setState({
            errorMessage: "Erro ao salvar intenção: " + responseJson.error.message,
            showError: true,
          });
        } else {
          let intent = {
            id: responseJson.data.result.id,
            name: this.state.intentName,
            dialogId: ""
          }

          intentList.push(intent);

          this.setState({
            intents: intentList
          });

          this.closeIntentDialog(intent.id);
        }
      } catch (error) {
        console.error("Erro ao salvar intenção: " + error.message);
        this.setState({
          errorMessage: "Erro ao salvar intenção: " + error.message,
          showError: true,
        });
      }
    } else { // Edição
      intentList[this.state.selectedIntentIndex].name = this.state.intentName;

      try {
        let obj = {
          tid: "VF9ESUFMT0dfSU5URU5UOjExNzI2Nw==",
          fid: 144,
          data: {
            INTENT_NAME: this.state.intentName,
            EMPRESA: this.state.cnpjEmpresa
          },
          key: {
            id: this.state.selectedIntentId
          }
        }

        const response = await fetch("https://disc.beelegal.com.br/beelegal/Integration/Save", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            auth: this.state.authToken,
          },
          body: JSON.stringify(obj),
        });

        const responseJson = await response.json();

        if (responseJson.error) {
          this.setState({
            errorMessage: "Erro ao salvar intenção: " + responseJson.error.message,
            showError: true,
          });
        } else {
          this.setState({
            intents: intentList
          });

          this.closeIntentDialog();
        }
      } catch (error) {
        console.error("Erro ao salvar intenção: " + error.message);
        this.setState({
          errorMessage: "Erro ao salvar intenção: " + error.message,
          showError: true,
        });
      }
    }
  }

  openFrasesDialog(frase, newFrase, fraseIndex) {
    if (newFrase) {
      if (this.state.selectedIntentId === -1) {
        this.setState({
          errorMessage: "Selecione uma intenção antes de cadastrar uma nova frase",
          showError: true,
        });
        return;
      }

      this.setState({
        openFraseDialog: true,
        selectedFraseId: fraseIndex,
        frase: "",
        selectedFraseIndex: fraseIndex
      });
    } else {
      this.setState({
        openFraseDialog: true,
        frase: frase.frase,
        selectedFraseId: frase.id,
        selectedFraseIndex: fraseIndex
      });
    }
  }

  closeFrasesDialog() {
    this.setState({
      openFraseDialog: false,
      frase: "",
      selectedFraseIndex: -1,
      selectedFraseId: -1
    });
  }

  handleFrase(event) {
    this.setState({
      frase: event.target.value
    });
  }

  async saveFrase() {
    if (this.state.frase === "") {
      this.setState({
        errorMessage: "Campo frase não pode ser vazio",
        showError: true
      });

      return;
    }

    let fraseList = this.state.frases;

    if (this.state.selectedFraseIndex === -1) { // Novo
      try {
        let obj = {
          tid: "VF9ESUFMT0dfRlJBU0VTOjExNTg1Nw==",
          fid: 145,
          data: {
            FRASE: this.state.frase,
            EMPRESA: this.state.cnpjEmpresa,
            INTENT_ID: this.state.selectedIntentId
          }
        }

        const response = await fetch("https://disc.beelegal.com.br/beelegal/Integration/Save", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            auth: this.state.authToken,
          },
          body: JSON.stringify(obj),
        });

        const responseJson = await response.json();

        if (responseJson.error) {
          this.setState({
            errorMessage: "Erro ao salvar frase: " + responseJson.error.message,
            showError: true,
          });
        } else {
          let frase = {
            id: responseJson.data.result.id,
            frase: this.state.frase,
            dialogId: "",
            intentId: this.state.selectedIntentId
          }

          fraseList.push(frase);

          this.setState({
            frases: fraseList
          }, () => this.closeFrasesDialog());
        }
      } catch (error) {
        console.error("Erro ao salvar frase: " + error.message);
        this.setState({
          errorMessage: "Erro ao salvar frase: " + error.message,
          showError: true,
        });
      }
    } else { // Edição
      for (let i = 0; i < fraseList.length; i++) {
        if (fraseList[i].id === this.state.selectedFraseId) {
          fraseList[i].frase = this.state.frase;
          break;
        }
      }

      try {
        let obj = {
          tid: "VF9ESUFMT0dfRlJBU0VTOjExNTg1Nw==",
          fid: 145,
          data: {
            FRASE: this.state.frase,
            EMPRESA: this.state.cnpjEmpresa,
            INTENT_ID: this.state.selectedIntentId
          },
          key: {
            id: this.state.selectedFraseId
          }
        }

        const response = await fetch("https://disc.beelegal.com.br/beelegal/Integration/Save", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            auth: this.state.authToken,
          },
          body: JSON.stringify(obj),
        });

        const responseJson = await response.json();

        if (responseJson.error) {
          this.setState({
            errorMessage: "Erro ao salvar frase: " + responseJson.error.message,
            showError: true,
          });
        } else {
          this.setState({
            frases: fraseList
          });

          this.closeFrasesDialog();
        }
      } catch (error) {
        console.error("Erro ao salvar frase: " + error.message);
        this.setState({
          errorMessage: "Erro ao salvar frase: " + error.message,
          showError: true,
        });
      }
    }
  }

  openParametrosDialog(parametro, newParametro, parametroIndex) {
    if (newParametro) {
      if (this.state.selectedIntentId === -1) {
        this.setState({
          errorMessage: "Selecione uma intenção antes de cadastrar um novo parâmetro",
          showError: true,
        });
        return;
      }

      this.setState({
        openParametroDialog: true,
        selectedParametroId: parametroIndex,
        parametro: "",
        obrigatorio: "",
        entidade: "",
        valor: "",
        prompt: "",
        selectedParametroIndex: parametroIndex
      });
    } else {
      this.setState({
        openParametroDialog: true,
        parametro: parametro.param,
        obrigatorio: parametro.obrigatorio,
        entidade: parametro.entidade,
        valor: parametro.valor,
        prompt: parametro.prompt,
        selectedParametroId: parametro.id,
        selectedParametroIndex: parametroIndex
      });
    }
  }

  closeParametroDialog() {
    this.setState({
      openParametroDialog: false,
      parametro: "",
      obrigatorio: "",
      entidade: "",
      valor: "",
      prompt: "",
      selectedParametroIndex: -1,
      selectedParametroId: -1
    });
  }

  handleParametro(event) {
    this.setState({
      parametro: event.target.value
    });
  }

  handleObrigatorio(event) {
    this.setState({
      obrigatorio: event.target.value
    });
  }

  handleEntidade(event) {
    this.setState({
      entidade: event.target.value
    });
  }

  handleValor(event) {
    this.setState({
      valor: event.target.value
    });
  }

  handlePrompt(event) {
    this.setState({
      prompt: event.target.value
    });
  }

  async saveParametro() {
    if (this.state.parametro === "") {
      this.setState({
        errorMessage: "Campo parâmetro não pode ser vazio",
        showError: true
      });

      return;
    }

    if (this.state.obrigatorio === "") {
      this.setState({
        errorMessage: "Campo obrigatorio não pode ser vazio",
        showError: true
      });

      return;
    }

    if (this.state.entidade === "") {
      this.setState({
        errorMessage: "Campo entidade não pode ser vazio",
        showError: true
      });

      return;
    }

    if (this.state.valor === "") {
      this.setState({
        errorMessage: "Campo valor não pode ser vazio",
        showError: true
      });

      return;
    }

    if (this.state.prompt === "") {
      this.setState({
        errorMessage: "Campo prompt não pode ser vazio",
        showError: true
      });

      return;
    }

    let parametroList = this.state.parametros;

    if (this.state.selectedParametroIndex === -1) { // Novo
      try {
        let obj = {
          tid: "VF9ESUFMT0dfUEFSQU1FVFJPUzoxNDcyMDc=",
          fid: 147,
          data: {
            PARAMETRO: this.state.parametro,
            REQUIRED: this.state.obrigatorio,
            ENTITY: this.state.entidade,
            VALUE: this.state.valor,
            PROMPT: this.state.prompt,
            EMPRESA: this.state.cnpjEmpresa,
            INTENT_ID: this.state.selectedIntentId,
          }
        }

        const response = await fetch("https://disc.beelegal.com.br/beelegal/Integration/Save", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            auth: this.state.authToken,
          },
          body: JSON.stringify(obj),
        });

        const responseJson = await response.json();

        if (responseJson.error) {
          this.setState({
            errorMessage: "Erro ao salvar parâmetro: " + responseJson.error.message,
            showError: true,
          });
        } else {
          let parametro = {
            id: responseJson.data.result.id,
            param: this.state.parametro,
            obrigatorio: this.state.obrigatorio,
            entidade: this.state.entidade,
            valor: this.state.valor,
            prompt: this.state.prompt,
            dialogId: "",
            intentId: this.state.selectedIntentId,
          }

          parametroList.push(parametro);

          this.setState({
            parametros: parametroList
          }, () => this.closeParametroDialog());
        }
      } catch (error) {
        console.error("Erro ao salvar parâmetro: " + error.message);
        this.setState({
          errorMessage: "Erro ao salvar parâmetro: " + error.message,
          showError: true,
        });
      }
    } else { // Edição
      for (let i = 0; i < parametroList.length; i++) {
        if (parametroList[i].id === this.state.selectedParametroId) {
          parametroList[i].param = this.state.parametro;
          parametroList[i].obrigatorio = this.state.obrigatorio;
          parametroList[i].entidade = this.state.entidade;
          parametroList[i].valor = this.state.valor;
          parametroList[i].prompt = this.state.prompt;
          break;
        }
      }

      try {
        let obj = {
          tid: "VF9ESUFMT0dfUEFSQU1FVFJPUzoxNDcyMDc=",
          fid: 147,
          data: {
            PARAMETRO: this.state.parametro,
            REQUIRED: this.state.obrigatorio,
            ENTITY: this.state.entidade,
            VALUE: this.state.valor,
            PROMPT: this.state.prompt,
            EMPRESA: this.state.cnpjEmpresa,
            INTENT_ID: this.state.selectedIntentId,
          },
          key: {
            id: this.state.selectedParametroId
          }
        }

        const response = await fetch("https://disc.beelegal.com.br/beelegal/Integration/Save", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            auth: this.state.authToken,
          },
          body: JSON.stringify(obj),
        });

        const responseJson = await response.json();

        if (responseJson.error) {
          this.setState({
            errorMessage: "Erro ao salvar parâmetro: " + responseJson.error.message,
            showError: true,
          });
        } else {
          this.setState({
            parametros: parametroList
          });

          this.closeParametroDialog();
        }
      } catch (error) {
        console.error("Erro ao salvar parâmetro: " + error.message);
        this.setState({
          errorMessage: "Erro ao salvar parâmetro: " + error.message,
          showError: true,
        });
      }
    }
  }

  renderTipoMensagem(tipoMensagem) {
    switch (tipoMensagem) {
      case 100:
        return "Mensagem";
      case 101:
        return "Botão";
      case 102:
        return "Link";
      default:
        return "";
    }
  }

  openMensagensDialog(mensagem, newMensagem, mensagemIndex) {
    if (newMensagem) {
      if (this.state.selectedIntentId === -1) {
        this.setState({
          errorMessage: "Selecione uma intenção antes de cadastrar uma nova mensagem",
          showError: true,
        });
        return;
      }

      this.setState({
        openMensagemDialog: true,
        selectedMensagemId: mensagemIndex,
        mensagem: "",
        tipoMensagem: -1,
        botao: "",
        link: "",
        selectedMensagemIndex: mensagemIndex
      });
    } else {
      this.setState({
        openMensagemDialog: true,
        mensagem: mensagem.msg,
        tipoMensagem: mensagem.tipoMensagem,
        botao: mensagem.botao,
        link: mensagem.link,
        selectedMensagemId: mensagem.id,
        selectedMensagemIndex: mensagemIndex
      });
    }
  }

  closeMensagemDialog() {
    this.setState({
      openMensagemDialog: false,
      mensagem: "",
      tipoMensagem: -1,
      botao: "",
      link: "",
      selectedMensagemIndex: -1,
      selectedMensagemId: -1
    });
  }

  handleTipoMensagem(event) {
    this.setState({
      tipoMensagem: event.target.value
    });
  }

  handleMensagem(event) {
    this.setState({
      mensagem: event.target.value
    });
  }

  handleBotao(event) {
    this.setState({
      botao: event.target.value
    });
  }

  handleLink(event) {
    this.setState({
      link: event.target.value
    });
  }

  async saveMensagem() {
    if (this.state.tipoMensagem === -1) {
      this.setState({
        errorMessage: "Campo tipo mensagem não pode ser vazio",
        showError: true
      });

      return;
    }

    if (this.state.tipoMensagem === 100 && this.state.mensagem === "") {
      this.setState({
        errorMessage: "Campo mensagem não pode ser vazio",
        showError: true
      });

      return;
    }

    if (this.state.tipoMensagem === 101 && (this.state.botao === "" || this.state.link === "")) {
      this.setState({
        errorMessage: "Campos botão e link não podem ser vazios",
        showError: true
      });

      return;
    }

    let mensagemList = this.state.mensagens;

    if (this.state.selectedMensagemIndex === -1) { // Novo
      try {
        let obj = {
          tid: "VF9ESUFMT0dfTUVOU0FHRU5TOjEzNzk3Ng==",
          fid: 146,
          data: {
            MENSAGEM: this.state.mensagem,
            FK_TIPO_MENSAGEM: this.state.tipoMensagem,
            EMPRESA: this.state.cnpjEmpresa,
            INTENT_ID: this.state.selectedIntentId,
            BOTAO: this.state.botao,
            LINK: this.state.link
          }
        }

        const response = await fetch("https://disc.beelegal.com.br/beelegal/Integration/Save", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            auth: this.state.authToken,
          },
          body: JSON.stringify(obj),
        });

        const responseJson = await response.json();

        if (responseJson.error) {
          this.setState({
            errorMessage: "Erro ao salvar mensagem: " + responseJson.error.message,
            showError: true,
          });
        } else {
          let mensagem = {
            id: responseJson.data.result.id,
            msg: this.state.mensagem,
            dialogId: "",
            intentId: this.state.selectedIntentId,
            tipoMensagem: this.state.tipoMensagem,
            botao: this.state.botao,
            link: this.state.link
          }

          mensagemList.push(mensagem);

          this.setState({
            mensagens: mensagemList
          }, () => this.closeMensagemDialog());
        }
      } catch (error) {
        console.error("Erro ao salvar mensagem: " + error.message);
        this.setState({
          errorMessage: "Erro ao salvar mensagem: " + error.message,
          showError: true,
        });
      }
    } else { // Edição
      for (let i = 0; i < mensagemList.length; i++) {
        if (mensagemList[i].id === this.state.selectedMensagemId) {
          mensagemList[i].msg = this.state.mensagem;
          mensagemList[i].tipoMensagem = this.state.tipoMensagem;
          mensagemList[i].botao = this.state.botao;
          mensagemList[i].link = this.state.link;
          break;
        }
      }

      try {
        let obj = {
          tid: "VF9ESUFMT0dfTUVOU0FHRU5TOjEzNzk3Ng==",
          fid: 146,
          data: {
            MENSAGEM: this.state.mensagem,
            FK_TIPO_MENSAGEM: this.state.tipoMensagem,
            EMPRESA: this.state.cnpjEmpresa,
            INTENT_ID: this.state.selectedIntentId,
            BOTAO: this.state.botao,
            LINK: this.state.link
          },
          key: {
            id: this.state.selectedMensagemId
          }
        }

        const response = await fetch("https://disc.beelegal.com.br/beelegal/Integration/Save", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            auth: this.state.authToken,
          },
          body: JSON.stringify(obj),
        });

        const responseJson = await response.json();

        if (responseJson.error) {
          this.setState({
            errorMessage: "Erro ao salvar mensagem: " + responseJson.error.message,
            showError: true,
          });
        } else {
          this.setState({
            mensagens: mensagemList
          });

          this.closeMensagemDialog();
        }
      } catch (error) {
        console.error("Erro ao salvar mensagem: " + error.message);
        this.setState({
          errorMessage: "Erro ao salvar mensagem: " + error.message,
          showError: true,
        });
      }
    }
  }

  handleCloseSnack() {
    this.setState({
      showError: false
    });
  }

  render() {
    return (
      <div>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={4}
        >
          <Grid item style={{ width: "100%" }}>
            <Card style={{ padding: "16px" }}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item style={{ width: "5%" }}>
                  <IconButton size="small" style={{ marginLeft: "10px" }} onClick={() => this.props.changePage(25)}>
                    <ArrowBackIosTwoToneIcon />
                  </IconButton>
                </Grid>
                <Grid item style={{ width: "95%" }}>
                  <Typography variant="h5" component="h5">
                    Parametrização ChatBot
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        {this.state.loading ? (
          <Grid container direction="column" alignItems="center" style={{ width: "100%", height: "100%" }}>
            <Grid item style={{ paddingTop: "10vh" }}>
              <CircularProgress size={70} color={theme.secondary_color} />
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid
              container
              direction="row"
              columns={2}
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: "10px", width: "100%" }}
            >
              <Grid
                item
                container
                component={Paper}
                direction="column"
                style={{ margin: "5px", width: "48%", height: "40vh" }}
              >
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  style={{ width: "100%", height: "10%", backgroundColor: theme.secondary_color }}
                >
                  <Grid item style={{ width: "90%" }}>
                    <Typography variant="h6" component="span" style={{ marginLeft: "10px", color: theme.quaternary_color }}>
                      Intenções
                    </Typography>
                  </Grid>
                  <Grid item style={{ width: "10%" }}>
                    <IconButton
                      size="small"
                      style={{ marginLeft: "25px" }}
                      onClick={() => this.openIntentDialog(null, true, -1)}
                    >
                      <AddIcon style={{ color: theme.quaternary_color }} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid
                  item
                  component={TableContainer}
                  style={{ width: "100%", height: "90%" }}
                >
                  <Table stickyHeader>
                    <colgroup>
                      <col style={{ width: "10%" }} />
                      <col style={{ width: "80%" }} />
                      <col style={{ width: "10%" }} />
                    </colgroup>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Intenção</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.intents.map((intent, index) => (
                        <TableRow
                          hover
                          key={intent.id}
                          selected={this.state.selectedIntentId === intent.id ? true : false}
                          onClick={() => this.handleSelectIntentRow(intent.id)}
                        >
                          <TableCell>{intent.id}</TableCell>
                          <TableCell>{intent.name}</TableCell>
                          <TableCell>
                            <Button
                              onClick={() => this.openIntentDialog(intent, false, index)}
                              style={{
                                background: theme.primary_color,
                                color: theme.secondary_color,
                              }}
                            >
                              Editar
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
              <Grid
                item
                container
                component={Paper}
                direction="column"
                style={{ margin: "5px", width: "48%", height: "40vh" }}
              >
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  style={{ width: "100%", height: "10%", backgroundColor: theme.secondary_color }}
                >
                  <Grid item style={{ width: "90%" }}>
                    <Typography variant="h6" component="span" style={{ marginLeft: "10px", color: theme.quaternary_color }}>
                      Frases
                    </Typography>
                  </Grid>
                  <Grid item style={{ width: "10%" }}>
                    <IconButton
                      size="small"
                      style={{ marginLeft: "25px" }}
                      onClick={() => this.openFrasesDialog(null, true, -1)}
                    >
                      <AddIcon style={{ color: theme.quaternary_color }} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid
                  item
                  component={TableContainer}
                  style={{ width: "100%", height: "90%" }}
                >
                  <Table stickyHeader>
                    <colgroup>
                      <col style={{ width: "10%" }} />
                      <col style={{ width: "80%" }} />
                      <col style={{ width: "10%" }} />
                    </colgroup>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Frase</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.frases.filter(this.filterFrasesList).map((frase, index) => (
                        <TableRow hover key={frase.id}>
                          <TableCell>{frase.id}</TableCell>
                          <TableCell>{frase.frase}</TableCell>
                          <TableCell>
                            <Button
                              style={{
                                background: theme.primary_color,
                                color: theme.secondary_color,
                              }}
                              onClick={() => this.openFrasesDialog(frase, false, index)}
                            >
                              Editar
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
              <Grid
                item
                container
                component={Paper}
                direction="column"
                style={{ margin: "5px", width: "48%", height: "40vh" }}
              >
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  style={{ width: "100%", height: "10%", backgroundColor: theme.secondary_color }}
                >
                  <Grid item style={{ width: "90%" }}>
                    <Typography variant="h6" component="span" style={{ marginLeft: "10px", color: theme.quaternary_color }}>
                      Parâmetros
                    </Typography>
                  </Grid>
                  <Grid item style={{ width: "10%" }}>
                    <IconButton
                      size="small"
                      style={{ marginLeft: "25px" }}
                      onClick={() => this.openParametrosDialog(null, true, -1)}
                    >
                      <AddIcon style={{ color: theme.quaternary_color }} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid
                  item
                  component={TableContainer}
                  style={{ width: "100%", height: "90%" }}
                >
                  <Table stickyHeader>
                    <colgroup>
                      <col style={{ width: "10%" }} />
                      <col style={{ width: "10%" }} />
                      <col style={{ width: "15%" }} />
                      <col style={{ width: "15%" }} />
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "10%" }} />
                    </colgroup>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Obrigatório</TableCell>
                        <TableCell>Parâmetro</TableCell>
                        <TableCell>Entidade</TableCell>
                        <TableCell>Valor</TableCell>
                        <TableCell>Prompt</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.parametros.filter(this.filterParametrosList).map((parametro, index) => (
                        <TableRow hover key={parametro.id}>
                          <TableCell>{parametro.id}</TableCell>
                          <TableCell>{parametro.obrigatorio}</TableCell>
                          <TableCell>{parametro.param}</TableCell>
                          <TableCell>{parametro.entidade}</TableCell>
                          <TableCell>{parametro.valor}</TableCell>
                          <TableCell>{parametro.prompt}</TableCell>
                          <TableCell>
                            <Button
                              style={{
                                background: theme.primary_color,
                                color: theme.secondary_color,
                              }}
                              onClick={() => this.openParametrosDialog(parametro, false, index)}
                            >
                              Editar
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
              <Grid
                item
                container
                component={Paper}
                direction="column"
                style={{ margin: "5px", width: "48%", height: "40vh" }}
              >
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  style={{ width: "100%", height: "10%", backgroundColor: theme.secondary_color }}
                >
                  <Grid item style={{ width: "90%" }}>
                    <Typography variant="h6" component="span" style={{ marginLeft: "10px", color: theme.quaternary_color }}>
                      Mensagens
                    </Typography>
                  </Grid>
                  <Grid item style={{ width: "10%" }}>
                    <IconButton
                      size="small"
                      style={{ marginLeft: "25px" }}
                      onClick={() => this.openMensagensDialog(null, true, -1)}
                    >
                      <AddIcon style={{ color: theme.quaternary_color }} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid
                  item
                  component={TableContainer}
                  style={{ width: "100%", height: "90%" }}
                >
                  <Table stickyHeader>
                    <colgroup>
                      <col style={{ width: "10%" }} />
                      <col style={{ width: "10%" }} />
                      <col style={{ width: "10%" }} />
                      <col style={{ width: "60%" }} />
                      <col style={{ width: "10%" }} />
                    </colgroup>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Tipo mensagem</TableCell>
                        <TableCell>Botão</TableCell>
                        <TableCell>Mensagem</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.mensagens.filter(this.filterMensagensList).map((mensagem, index) => (
                        <TableRow hover key={mensagem.id}>
                          <TableCell>{mensagem.id}</TableCell>
                          <TableCell>{this.renderTipoMensagem(mensagem.tipoMensagem)}</TableCell>
                          <TableCell>{mensagem.botao}</TableCell>
                          <TableCell>{mensagem.msg}</TableCell>
                          <TableCell>
                            <Button
                              style={{
                                background: theme.primary_color,
                                color: theme.secondary_color,
                              }}
                              onClick={() => this.openMensagensDialog(mensagem, false, index)}
                            >
                              Editar
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        <Snackbar
          open={this.state.showError}
          autoHideDuration={2000}
          onClose={() => this.handleCloseSnack()}
        >
          <Alert
            onClose={() => this.handleCloseSnack()}
            severity="error"
            sx={{ width: "100%" }}
          >
            {this.state.errorMessage}
          </Alert>
        </Snackbar>

        <Dialog
          open={this.state.openIntentDialog}
          fullWidth
          maxWidth={"md"}
          onClose={() => this.closeIntentDialog()}
        >
          <DialogTitle>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography>Intenção</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={() => this.closeIntentDialog()}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="column"
              spacing={4}
              style={{ width: "100%" }}
            >
              <Grid item style={{ width: "100%" }}>
                <Typography variant="body1" component="span">
                  {this.state.selectedIntentId > -1 ? "ID: " + this.state.selectedIntentId : "Nova intenção"}
                </Typography>
              </Grid>
              <Grid item style={{ width: "100%" }}>
                <TextField
                  label="Nome Intenção"
                  variant="outlined"
                  value={this.state.intentName}
                  fullWidth
                  onChange={(event) => this.handleIntent(event)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.closeIntentDialog()}>Cancelar</Button>
            <Button
              onClick={() => this.saveIntent()}
              style={{
                background: theme.primary_color,
                color: theme.secondary_color,
              }}
            >
              Salvar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.openFraseDialog}
          fullWidth
          maxWidth={"md"}
          onClose={() => this.closeFrasesDialog()}
        >
          <DialogTitle>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography>Frase</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={() => this.closeFrasesDialog()}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="column"
              spacing={4}
              style={{ width: "100%" }}
            >
              <Grid item style={{ width: "100%" }}>
                <Typography variant="body1" component="span">
                  {this.state.selectedFraseId > -1 ? "ID: " + this.state.selectedFraseId : "Nova frase"}
                </Typography>
              </Grid>
              <Grid item style={{ width: "100%" }}>
                <TextField
                  label="Frase"
                  variant="outlined"
                  value={this.state.frase}
                  fullWidth
                  onChange={(event) => this.handleFrase(event)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.closeFrasesDialog()}>Cancelar</Button>
            <Button
              onClick={() => this.saveFrase()}
              style={{
                background: theme.primary_color,
                color: theme.secondary_color,
              }}
            >
                Salvar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.openParametroDialog}
          fullWidth
          maxWidth={"md"}
          onClose={() => this.closeParametroDialog()}
        >
          <DialogTitle>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography>Parâmetro</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={() => this.closeParametroDialog()}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="column"
              spacing={4}
              style={{ width: "100%" }}
            >
              <Grid item style={{ width: "100%" }}>
                <Typography variant="body1" component="span">
                  {this.state.selectedParametroId > -1 ? "ID: " + this.state.selectedParametroId : "Novo parâmetro"}
                </Typography>
              </Grid>
              <Grid item style={{ width: "100%" }}>
                <TextField
                  label="Parâmetro"
                  variant="outlined"
                  value={this.state.parametro}
                  fullWidth
                  onChange={(event) => this.handleParametro(event)}
                />
              </Grid>
              <Grid item style={{ width: "100%" }}>
                <TextField
                  label="Obrigatório"
                  variant="outlined"
                  value={this.state.obrigatorio}
                  fullWidth
                  onChange={(event) => this.handleObrigatorio(event)}
                />
              </Grid>
              <Grid item style={{ width: "100%" }}>
                <TextField
                  label="Entidade"
                  variant="outlined"
                  value={this.state.entidade}
                  fullWidth
                  onChange={(event) => this.handleEntidade(event)}
                />
              </Grid>
              <Grid item style={{ width: "100%" }}>
                <TextField
                  label="Valor"
                  variant="outlined"
                  value={this.state.valor}
                  fullWidth
                  onChange={(event) => this.handleValor(event)}
                />
              </Grid>
              <Grid item style={{ width: "100%" }}>
                <TextField
                  label="Prompt"
                  variant="outlined"
                  value={this.state.prompt}
                  fullWidth
                  onChange={(event) => this.handlePrompt(event)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.closeParametroDialog()}>Cancelar</Button>
            <Button
              onClick={() => this.saveParametro()}
              style={{
                background: theme.primary_color,
                color: theme.secondary_color,
              }}
            >
              Salvar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.openMensagemDialog}
          fullWidth
          maxWidth={"md"}
          onClose={() => this.closeMensagemDialog()}
        >
          <DialogTitle>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography>Mensagem</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={() => this.closeMensagemDialog()}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="column"
              spacing={4}
              style={{ width: "100%" }}
            >
              <Grid item style={{ width: "100%" }}>
                <Typography variant="body1" component="span">
                  {this.state.selectedMensagemId > -1 ? "ID: " + this.state.selectedMensagemId : "Nova mensagem"}
                </Typography>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                style={{ width: "100%" }}
              >
                <Grid item style={{ width: "30%" }}>
                  <FormControl
                    variant="outlined"
                    style={{ width: "100%" }}
                  >
                    <Select
                      value={this.state.tipoMensagem}
                      placeholder="Tipo Mensagem"
                      onChange={(event) => {
                        this.handleTipoMensagem(event)
                      }}
                    >
                      <MenuItem value={100}>Mensagem</MenuItem>
                      <MenuItem value={101}>Botão</MenuItem>
                      <MenuItem value={102}>Link</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item style={{ width: "30%" }}>
                  <TextField
                    label="Botão"
                    variant="outlined"
                    value={this.state.botao}
                    fullWidth
                    onChange={(event) => this.handleBotao(event)}
                  />
                </Grid>
                <Grid item style={{ width: "30%" }}>
                  <TextField
                    label="Link"
                    variant="outlined"
                    value={this.state.link}
                    fullWidth
                    onChange={(event) => this.handleLink(event)}
                  />
                </Grid>
              </Grid>
              <Grid item style={{ width: "100%" }}>
                <TextField
                  label="Mensagem"
                  variant="outlined"
                  value={this.state.mensagem}
                  fullWidth
                  onChange={(event) => this.handleMensagem(event)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.closeMensagemDialog()}>Cancelar</Button>
            <Button
              onClick={() => this.saveMensagem()}
              style={{
                background: theme.primary_color,
                color: theme.secondary_color,
              }}
            >
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changePage: (page) => dispatch(changePage(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatBotParameters);