import { useState } from "react";
import {
  Grid,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  Button,
  TextField,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import InputMask from "react-input-mask";
import validateCNPJ from "../../../../shared/commands/validations/validateCNPJ";
import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import routerHistory from "../../../../core/infrastructure/routerHistory";
import theme from "../../../../core/theme.json";
import { useEffect } from "react";

const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <InputMask
      {...other}
      ref={inputRef}
      mask={
        [
          /[0-9]/,
          /\d/,
          ".",
          /\d/,
          /\d/,
          /\d/,
          ".",
          /\d/,
          /\d/,
          /\d/,
          "/",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
        ] || [
          /[0-9]/,
          /\d/,
          /\d/,
          /\d/,
          ".",
          /\d/,
          /\d/,
          /\d/,
          ".",
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
        ]
      }
      showMask
    />
  );
};

export const NewEditCompany = ({ dialogProps, handleDialog }) => {

  
  const [closeDialog, setCloseDialog] = useState(true);
  const [nickName, setNickName] = useState("");
  const [name, setName] = useState("");
  const [porteEmpresa, setPorteEmpresa] = useState("");
  const [document, setDocument] = useState("");
  const [validDocument, setValidDocument] = useState(true);
  const [dpo_isCompanyEmployee, setDpo_isCompanyEmployee] = useState(false);
  const [nickNameDPO, setNickNameDPO] = useState("");
  const [nameDPO, setNameDPO] = useState("");
  const [documentDPO, setDocumentDPO] = useState("");
  const [dpoEmail, setDpoEmail] = useState("");
  const [dpoPhone, setDpoPhone] = useState("");
  const [dpoCompanyCnpj, setDpoCompanyCnpj] = useState("");
  const [dpoCompanyName, setDpoCompanyName] = useState("");
  const [dpoStreet, setDpoStreet] = useState("");
  const [dpoNumber, setDpoNumber] = useState("");
  const [dpoComplement, setDpoComplement] = useState("");
  const [dpoCity, setDpoCity] = useState("");
  const [dpoNeighborhood, setDpoNeighborhood] = useState("");
  const [dpoState, setDpoState] = useState("");
  const [dpoCEP, setDpoCEP] = useState("");
  const [validDocumentDPO, setValidDocumentDPO] = useState(true);
  const [companyObj, setCompanyObj] = useState(null);
  const [disabledButton, setDisabledButton] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const ERROR_MESSAGE_CNPJ = !validDocument ? "Digite um CNPJ válido" : "";

  useEffect(() => {
    getCompanyInfo();
  }, []);

  const handleButton = () => {
    if (
      nickName !== undefined &&
      nickName.length > 0 &&
      name !== undefined &&
      name.length > 0 &&
      validDocument &&
      nameDPO !== undefined &&
      nameDPO.length > 0 &&
      // nickNameDPO !== undefined && nickNameDPO.length > 0 &&
      validDocumentDPO
    ) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  };

  const getCompanyInfo = async () => {
    let database = myApp.firestore();
    let _companyId = localStorage.getItem("currentCompany");

    try {
      let _company = await database
        .collection("companies")
        .doc(_companyId)
        .get();
      console.log(_company.data());
      setNickName(_company.data().nickname);
      setName(_company.data().nome);
      setPorteEmpresa(_company.data().companySize);
      setDocument(_company.data().cnpj);
      setDpo_isCompanyEmployee(_company.data().dpo_isCompanyEmployee);
      setNickNameDPO(_company.data().dpo_nickname);
      setNameDPO(_company.data().dpo);
      setDocumentDPO(_company.data().dpo_cnpj);
      setDpoEmail(_company.data().dpo_email);
      setDpoPhone(_company.data().dpo_phone);
      setDpoCompanyCnpj(_company.data().dpo_dpoCompanyCnpj);
      setDpoCompanyName(_company.data().dpo_dpoCompanyName);
      setDpoStreet(_company.data().dpo_street);
      setDpoNumber(_company.data().dpo_number);
      dpoComplement(_company.data().dpo_complement);
      dpoCity(_company.data().dpo_city);
      setDpoNeighborhood(_company.data().dpo_neighborhood);
      setDpoState(_company.data().dpo_state);
      setDpoCEP(_company.data().dpo_cep);
      setCompanyObj(_company.data());
    } catch (err) {
      console.log(err);
    }
  };

  const handleDocument = (event) => {
    setDocument(event.target.value);
    if (validateDocument(event.target.value)) {
      setValidDocument(true, () => handleButton());
    } else {
      setValidDocument(false, () => handleButton());
    }
  };

  const handleDocumentDPO = (event) => {
    setDocumentDPO(event.target.value);
    if (validateDocument(event.target.value)) {
      setValidDocumentDPO(true, () => handleButton());
    } else {
      setValidDocumentDPO(false, () => handleButton());
    }
  };

  const validateDocument = (document) => {
    return validateCNPJ(document);
  };

  const updateCompany = async () => {
    let database = myApp.firestore();
    let _companyId = localStorage.getItem("currentCompany");

    try {
      let _companyObj = {
        nickname: nickName !== undefined ? nickName : "",
        nome: name !== undefined ? name : "",
        cnpj: document !== undefined ? document : "",
        companySize: porteEmpresa !== undefined ? porteEmpresa : "",
        dpo_nickname: nickNameDPO !== undefined ? nickNameDPO : "",
        dpo: nameDPO !== undefined ? nameDPO : "",
        dpo_cnpj: documentDPO !== undefined ? documentDPO : "",
        companySize: porteEmpresa !== undefined ? porteEmpresa : "",
        dpo_email: dpoEmail !== undefined ? dpoEmail : "",
        dpo_phone: dpoPhone !== undefined ? dpoPhone : "",
        dpo_isCompanyEmployee: dpo_isCompanyEmployee,
        dpo_dpoCompanyCnpj: dpoCompanyCnpj !== undefined ? dpoCompanyCnpj : "",
        dpo_dpoCompanyName: dpoCompanyName !== undefined ? dpoCompanyName : "",
        dpo_street: dpoStreet !== undefined ? dpoStreet : "",
        dpo_number: dpoNumber !== undefined ? dpoNumber : "",
        dpo_complement: dpoComplement !== undefined ? dpoComplement : "",
        dpo_city: dpoCity !== undefined ? dpoCity : "",
        dpo_neighborhood: dpoNeighborhood !== undefined ? dpoNeighborhood : "",
        dpo_state: dpoState !== undefined ? dpoState : "",
        dpo_cep: dpoCEP !== undefined ? dpoCEP : "",
      };

      await database
        .collection("companies")
        .doc(_companyId)
        .update(_companyObj);

      window.location.reload(true);
    } catch (erro) {
      console.log(erro);
    }
  };

  const deleteCompany = async()=> {
    let database = myApp.firestore();
    let _companyId = localStorage.getItem('currentCompany');

    try {
      let _companyObj ={
        active : false
      } 
      await database.collection('companies').doc(_companyId).update(_companyObj);
      localStorage.setItem('currentCompany', "");
      routerHistory.push("/company");
    } catch (err) {
      console.log(err);
    }
  }


  const handlecloseDialog = () =>{
    setCloseDialog(!closeDialog)
  }

  return (
    <div>
      <Dialog
        open={dialogProps === closeDialog ?true : false}
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => handlecloseDialog()}
      >
        <DialogContent>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item style={{ width: "100%", padding: "36px" }}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-end"
                spacing={4}
              >
                <Grid item style={{ width: "100%" }}>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    spacing={4}
                  >
                    <Grid item container direction="row">
                      <Grid item style={{ width: "100%" }} xs={10}>
                        <Typography variant="h5" component="h5">
                          Editar Informações da Empresa
                        </Typography>
                      </Grid>
                      <Grid item container xs={2} justify="flex-end">
                        <IconButton onClick={() => setCloseDialog(!closeDialog)}>
                          <Close />
                        </IconButton>
                      </Grid>
                    </Grid>

                    <Grid item style={{ width: "100%" }}>
                      <TextField
                        inputProps={{
                          maxLength: 50,
                        }}
                        label={"Apelido da empresa"}
                        variant={"outlined"}
                        style={{ width: "100%" }}
                        color={theme.secondary_color}
                        value={nickName}
                        onChange={(event) =>
                          setNickName(event.target.value, () => {
                            handleButton();
                          })
                        }
                      />
                    </Grid>

                    <Grid item style={{ width: "100%" }}>
                      <TextField
                        inputProps={{
                          maxLength: 115,
                        }}
                        label="Razão Social"
                        variant="outlined"
                        style={{ width: "100%" }}
                        color={theme.secondary_color}
                        value={name}
                        onChange={(event) =>
                          setName(event.target.value, () => {
                            handleButton();
                          })
                        }
                      />
                    </Grid>
                    <Grid item style={{ width: "100%" }}>
                      <FormControl fullWidth>
                        <InputLabel
                          variant="outlined"
                          id="demo-simple-select-label"
                        >
                          Porte da empresa
                        </InputLabel>
                        <Select
                          variant="outlined"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={porteEmpresa}
                          label="Porte da empresa"
                          onChange={(event) =>
                            setPorteEmpresa(event.target.value, () => {
                              handleButton();
                            })
                          }
                        >
                          <MenuItem value={"Pequeno"}>Pequeno</MenuItem>
                          <MenuItem value={"Médio"}>Médio</MenuItem>
                          <MenuItem value={"Grande"}>Grande</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item style={{ width: "100%" }}>
                      <TextField
                        label="CNPJ"
                        variant="outlined"
                        style={{ width: "100%" }}
                        color={theme.secondary_color}
                        value={document}
                        error={!validDocument}
                        helperText={ERROR_MESSAGE_CNPJ}
                        onChange={(event) => handleDocument(event)}
                        InputProps={{
                          inputComponent: TextMaskCustom,
                        }}
                      />
                    </Grid>
                    <Grid item style={{ width: "100%" }}>
                      <Divider />
                    </Grid>
                    <Grid item style={{ width: "100%" }}>
                      <Typography variant="h5" component="h5">
                        Editar Informações do DPO
                      </Typography>
                    </Grid>
                    <Grid item style={{ width: "100%" }}>
                      <TextField
                        inputProps={{
                          maxLength: 50,
                        }}
                        label="Nome do DPO"
                        variant="outlined"
                        style={{ width: "100%" }}
                        color={theme.secondary_color}
                        value={nameDPO}
                        onChange={(event) =>
                          setNameDPO(event.target.value, () => handleButton())
                        }
                      />
                    </Grid>
                    <Grid item style={{ width: "100%" }}>
                      <TextField
                        label="CNPJ DPO"
                        variant="outlined"
                        style={{ width: "100%" }}
                        color={theme.secondary_color}
                        value={documentDPO}
                        error={!validDocumentDPO}
                        helperText={ERROR_MESSAGE_CNPJ}
                        onChange={(event) => {
                          handleDocumentDPO(event);
                        }}
                        InputProps={{
                          inputComponent: TextMaskCustom,
                        }}
                      />
                    </Grid>
                    <Grid item style={{ width: "100%" }}>
                      <TextField
                        label="Email do DPO"
                        variant="outlined"
                        style={{ width: "100%" }}
                        color={theme.secondary_color}
                        value={dpoEmail}
                        onChange={(event) => {
                          setDpoEmail(event.target.value, () => handleButton());
                        }}
                      />
                    </Grid>
                    <Grid item style={{ width: "100%" }}>
                      <TextField
                        label="Telefone do DPO"
                        variant="outlined"
                        style={{ width: "100%" }}
                        color={theme.secondary_color}
                        value={dpoPhone}
                        onChange={(event) => {
                          setDpoPhone(event.target.value, () => handleButton());
                        }}
                      />
                    </Grid>
                    <Grid item style={{ width: "100%" }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={dpo_isCompanyEmployee}
                            onChange={(event) => {
                              setDpo_isCompanyEmployee(
                                !dpo_isCompanyEmployee,
                                () => handleButton()
                              );
                            }}
                            color="primary"
                          />
                        }
                        label="É funcionário da empresa"
                      />
                    </Grid>
                    {dpo_isCompanyEmployee === false ? (
                      <>
                        <Grid item style={{ width: "100%" }}>
                          <Typography variant="h5" component="h5">
                            Editar Informações da Empresa do DPO
                          </Typography>
                        </Grid>
                        <Grid item style={{ width: "100%" }}>
                          <TextField
                            label="CNPJ da Empresa do DPO"
                            variant="outlined"
                            style={{ width: "100%" }}
                            color={theme.secondary_color}
                            value={dpoCompanyCnpj}
                            onChange={(event) => {
                              setDpoCompanyCnpj(event.target.value, () =>
                                handleButton()
                              );
                            }}
                            InputProps={{
                              inputComponent: TextMaskCustom,
                            }}
                          />
                        </Grid>
                        <Grid item style={{ width: "100%" }}>
                          <TextField
                            label="Razão Social da Empresa do DPO"
                            variant="outlined"
                            style={{ width: "100%" }}
                            color={theme.secondary_color}
                            value={dpoCompanyName}
                            onChange={(event) => {
                              setDpoCompanyName(event.target.value, () =>
                                handleButton()
                              );
                            }}
                          />
                        </Grid>
                        <Grid item style={{ width: "100%" }}>
                          <TextField
                            label="CEP da Empresa"
                            variant="outlined"
                            style={{ width: "100%" }}
                            color={theme.secondary_color}
                            value={dpoCEP}
                            onChange={(event) => {
                              setDpoCEP(event.target.value, () =>
                                handleButton()
                              );
                            }}
                          />
                        </Grid>
                        <Grid item style={{ width: "100%" }}>
                          <TextField
                            label="Logradouro da Empresa"
                            variant="outlined"
                            style={{ width: "100%" }}
                            color={theme.secondary_color}
                            value={dpoStreet}
                            onChange={(event) => {
                              setDpoStreet(event.target.value, () =>
                                handleButton()
                              );
                            }}
                          />
                        </Grid>
                        <Grid item style={{ width: "100%" }}>
                          <TextField
                            label="Número"
                            variant="outlined"
                            style={{ width: "100%" }}
                            color={theme.secondary_color}
                            value={dpoNumber}
                            onChange={(event) => {
                              setDpoNumber(event.target.value, () =>
                                handleButton()
                              );
                            }}
                          />
                        </Grid>
                        <Grid item style={{ width: "100%" }}>
                          <TextField
                            label="Complemento"
                            variant="outlined"
                            style={{ width: "100%" }}
                            color={theme.secondary_color}
                            value={dpoComplement}
                            onChange={(event) => {
                              setDpoComplement(event.target.value, () =>
                                handleButton()
                              );
                            }}
                          />
                        </Grid>
                        <Grid item style={{ width: "100%" }}>
                          <TextField
                            label="Bairro"
                            variant="outlined"
                            style={{ width: "100%" }}
                            color={theme.secondary_color}
                            value={dpoNeighborhood}
                            onChange={(event) => {
                              setDpoNeighborhood(event.target.value, () =>
                                handleButton()
                              );
                            }}
                          />
                        </Grid>
                        <Grid item style={{ width: "100%" }}>
                          <TextField
                            label="Cidade"
                            variant="outlined"
                            style={{ width: "100%" }}
                            color={theme.secondary_color}
                            value={dpoCity}
                            onChange={(event) => {
                              setDpoCity(event.target.value, () =>
                                handleButton()
                              );
                            }}
                          />
                        </Grid>
                        <Grid item style={{ width: "100%" }}>
                          <TextField
                            label="Estado"
                            variant="outlined"
                            style={{ width: "100%" }}
                            color={theme.secondary_color}
                            value={dpoState}
                            onChange={(event) => {
                              setDpoState(event.target.value, () =>
                                handleButton()
                              );
                            }}
                          />
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <Grid container direction="row">
                    <Grid item style={{ width: "100%" }} xs={2}>
                      <Button
                        variant="contained"
                        style={{
                          background: "red",
                          color: "white",
                          fontSize: theme.font_button,
                        }}
                        onClick={() => setDeleteDialog(!deleteDialog)}
                      >
                        Remover
                      </Button>
                    </Grid>
                    <Grid item style={{ flexGrow: 1 }} />
                    <Grid item>
                      <Button
                        variant="contained"
                        style={{
                          background: disabledButton
                            ? "#91ff79"
                            : theme.primary_color,
                          color: theme.secondary_color,
                          fontSize: theme.font_button,
                        }}
                        onClick={() => updateCompany()}
                        disabled={disabledButton}
                      >
                        Salvar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
          open={deleteDialog}
          fullWidth={true}
          maxWidth={"md"}
          onClose={() =>  setDeleteDialog(!deleteDialog)}
        >
          <DialogContent>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item style={{ width: "100%", padding: "36px" }}>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item style={{ width: "100%" }}>
                    <Typography variant="h5" component="h5" style={{ textAlign: "center" }} >
                      Você realmente deseja apagar essa empresa?
                    </Typography>
                  </Grid>
                  <Grid item style={{ width: "100%" }}>
                    <ErrorOutlineIcon style={{ width: "100%", height: "160px", color: "red" }} />
                  </Grid>
                  <Grid item style={{ width: "100%" }}>
                    <Grid
                      container
                      direction="row"
                    >
                      <Grid item style={{ width: "100%" }} xs={2}>
                        <Button
                          variant="contained"
                          style={{ background: "#193993", color: "white", height: "48px", width: "168px", fontSize: "16px" }}
                          onClick={() => setDeleteDialog(!deleteDialog)}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item style={{ flexGrow: 1 }} />
                      <Grid item>
                        <Button
                          variant="contained"
                          style={{ background: "red", color: "white", height: "48px", width: "168px", fontSize: "16px" }}
                          onClick={() => deleteCompany() }
                        >
                          Confirmar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
    </div>
  );
};
